import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'

import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import ReactSelect from 'react-select'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../layouts/Input/FormikControl'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  CREATE_DELIVERY_USER,
  GET_DELIVERY_USER_LIST,
} from '../../../Types'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  addDeliveryUser,
  updateDeliveryUser,
  getDeliveryUsers,
  getQRorTillDetails,
  deleteDeliveryUser,
} from './DeliverymanUserRegistrationActions'
import QrTillList from '../QrTillList/QrTillList'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'
import axios from '../../../apis/Api'
import QRCode from 'react-qr-code'
import { WithContext as ReactTags } from 'react-tag-input'
import { setTag } from '@sentry/hub'
import { Label } from 'reactstrap'
const DeliverymanUserRegistration = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [currentUser, setCurrentUser] = useState(null)

  const [DistributorsList, setDistributorsList] = useState([])
  const [DistCentersList, setDistCentersList] = useState([])
  const [QRorTillDetailsList, setQRorTillDetailsList] = useState([])
  const [MfiList, setMfiList] = useState([])
  const [tillList, setTillList] = useState([])

  const [selectedDistributorFilter, setSelectedDistributorFilter] =
    useState(null)
  const [selectedDistCenterFilter, setSelectedDistCenterFilter] = useState(null)
  const [distributorNameValue, setDistributorNameValue] = useState(null)
  const [distributionNameValue, setDistributionNameValue] = useState(null)
  const [qrTillValue, setQrTillValue] = useState(null)
  const [mfiValue, setMfiValue] = useState(null)

  const [initialValues, setInitialValues] = useState({
    id: '',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    userType: '',
    cnic: '',
    distributor: '',
    distCenter: '',
    distributorUser: '',
    phoneNumber: '',
    qrTill: '',
    routeNo: '',
    mfi: '',
    qrCode: '',
  })
  const [currentDeliveryManDetails, setCurrentDeliveryManDetails] =
    useState(initialValues)

  const [modalShow, setModalShow] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is Required'),
    userName: Yup.string().required('User Name is Required'),
    email: Yup.string()
      .email('Enter correct Email Required')
      .required('Email  is Required'),
    // password: Yup.string().required('Password is Required'),
    phoneNumber: Yup.string().required('Phone number is Required'),
    routeNo: Yup.string().required('Route Id is Required'),
    distributor: Yup.string().required('Distributor is Required'),
    distCenter: Yup.string().required('Dist Center is Required'),

    // qrTill: Yup.array().min(1, 'Assign at least one Qr/Till').required(),
    qrTill: Yup.string().required('Till is Required'),

    // cnic: Yup.string().required('CNIC is Required'),
    mfi: Yup.string().optional(),
    qrCode: Yup.string().optional(),
  })

  const columns = [
    {
      Header: 'Distribution Center',
      accessor: 'distCenterName',
    },
    {
      Header: 'User Name',
      accessor: 'userName',
    },
    {
      Header: 'Contact Number',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Corresponding E-mail Address',
      accessor: 'email',
    },
    {
      Header: 'User ID',
      accessor: 'userCode',
    },

    {
      Header: 'Action',
      id: 'edit',
      accessor: 'userId',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          {/* <button onClick={() => View(row.value)} className=' btn btn-sm'>
            <i className='fas fa-eye'></i>
          </button>
          &nbsp; */}
          <button onClick={() => Edit(row.value)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button>
          {/* &nbsp;
          <button onClick={() => Delete(row.value)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button> */}
        </div>
      ),
    },
  ]

  const [errFound, setErrFound] = useState(false)

  const handleSubmit = async (data) => {
    // var qrTills = data.qrTill.map((x) => x.value)

    // if (qrTills[0] == undefined) {
    //   qrTills = data.qrTill
    // }
    if (tags.length <= 0) {
      setErrFound(true)
      return
    } else {
      setErrFound(false)
    }
    const qrTills = tags.map((item) => item.id)
    let UserData = {
      UserName: data.userName,
      Email: data.email,
      ContactNumber: data.contactNo ?? null,
      LastName: data.lastName,
      FirstName: data.firstName,
      Password: data.password ? data.password : 'Asdf@123',
      PhoneNumber: data.phoneNumber,
      DistCenterId:
        distributionNameValue?.value != undefined ||
          distributionNameValue?.value != null
          ? distributionNameValue.value
          : data.distCenter,
      RouteNo: data.routeNo,
      assignedQr: qrTills,
    }

    if (!isView) {
      await props.addDeliveryUser(UserData)
    } else {
      await props.updateDeliveryUser(UserData)
    }

    setModalShow(false)

    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDeliveryUsers(requestData)

    // let requestData = {
    //   pageSize: currentPageSize,
    //   pageNo: currentPageNo,
    //   searchText: searchText,
    // }
    // props.getDeliveryUsers(requestData)
  }

  const OpenModelForAddDeliveryMan = () => {
    setReadOnly(false)
    setIsEdit(false)
    setCurrentDeliveryManDetails({ ...initialValues })

    setDistributorNameValue(null)
    setDistributionNameValue(null)

    setModalShow(true)
  }

  const AddUser = () => {
    setCurrentUser({ ...initialValues })
    setIsView(false)
    setIsEdit(false)
    setDistributorNameValue(null)
    setDistributionNameValue(null)
    setQrTillValue(null)
    setMfiValue(null)

    setMfiList([])
    setDistCentersList([])
    setQRorTillDetailsList([])
    setTillList([])
    setTags([])
    setModalShow(true)
  }

  const Edit = (id) => {
    let tempObj = props.DeliverymanUserList.users.find(
      (detail) => detail.userId === id
    )
    if (tempObj == null) {
      return
    }

    let distributor = DistributorsList.find(
      (x) =>
        x.value.toLowerCase() ==
        tempObj.qrTillList[0].distributorId.toLowerCase()
    )

    let distCenter = {
      label: tempObj.distCenterName,
      value: tempObj.distCenterId,
    }

    setDistributorNameValue(distributor)
    setDistributionNameValue(distCenter)

    tempObj = {
      ...tempObj,
      distributor: tempObj.qrTillList[0].distributorId,
      distCenter: tempObj.qrTillList[0].distCenterId,
      // qrTill: tempObj.qrTillList.map((x) => x.tillId),
      // mfi: tempObj.qrTillList[0].mfiMerchantId,
    }

    var assignedTills = tempObj?.qrTillList?.map((t) => {
      return {
        id: t.tillId,
        text: t.mfi + ' | ' + t.tillNo,
      }
    })

    setTags(assignedTills)

    getDistirbutorMfiList(
      tempObj.qrTillList[0].distributorId,
      tempObj.qrTillList[0].mfiMerchantId
    )
    getDistirbutorTillList(
      tempObj.qrTillList[0].mfiMerchantId,
      tempObj.qrTillList[0].tillId
    )

    setCurrentUser({ ...tempObj, qrTill: tempObj.qrTillList[0].tillId })
    setModalShow(true)
    setIsView(true)
  }

  const Delete = async (id) => {
    await props.deleteDeliveryUser(id)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }

    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDeliveryUsers(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDeliveryUsers(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const getDistirbutorMfiList = (id, merchantId = null) => {
    axios
      .get(`api/MfiMerchant/GetMfiMerchantListByDistributor/${id}`)
      .then(({ data }) => {
        setMfiList(
          data?.response?.map(function (item) {
            return {
              value: item.mfiId,
              label: item.mfiName,
            }
          })
        )
        if (merchantId != null) {
          let mfi = data?.response?.find((x) => x.mfiId == merchantId)
          setMfiValue({
            value: mfi.mfiId,
            label: mfi.mfiName,
          })
          currentUser.mfi = mfi.mfiId
        }
      })
      .catch(function (error) { })
  }

  const getDistirbutorTillList = (id, merchantTillId = null) => {
    let distributor = distributorNameValue != null ? distributorNameValue.value : null;
    let distCenter = distributionNameValue != null ? distributionNameValue.value : null;
    axios
      .get(`api/MfiMerchantTill/GetTillByMfiMerchantCenterWise/${id},${distributor},${distCenter}`)
      .then(({ data }) => {
        setTillList(
          data?.map(function (item) {
            return {
              value: item.id,
              label: item.tillNumber,
            }
          })
        )
        if (merchantTillId != null) {
          let till = data?.find((x) => x.id == merchantTillId)
          setQrTillValue({
            value: till.id,
            label: till.tillNumber,
          })
        }
      })
      .catch(function (error) { })
  }

  const resetDropDowns = () => {
    setDistributionNameValue(null)
    setMfiValue(null)
    setQrTillValue(null)
    setTillList([])
    setCurrentUser({ ...currentUser, distCenter: '', mfi: '', qrTill: '' })
  }

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setDistributorsList(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setDistributorsList(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.selectedDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setDistCentersList(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setDistCentersList(
        props.selectedDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }

    if (isView) {
      let center = DistCentersList.find(
        (x) => x.value == currentUser.distCenterId
      )

      setDistributionNameValue(center)
    }
  }, [props.selectedDistCentersddlList])

  useEffect(() => {
    if (isView) {
      let center = DistCentersList.find(
        (x) => x.value == currentUser.distCenterId
      )
      setDistributionNameValue(center)
    }
  }, [DistCentersList])

  useEffect(() => {
    if (isView) {
      return
      let qrtillsList = []
      if (currentUser.qrTillList && currentUser.qrTillList.length > 0)
        currentUser.qrTillList.forEach((x) => {
          if (QRorTillDetailsList && QRorTillDetailsList.length > 0) {
            let qr = QRorTillDetailsList.find((q) => q.value === x.tillId)
            qrtillsList.push(qr)
          }
        })
      setQrTillValue(qrtillsList)
      setCurrentUser({ ...currentUser, qrTill: qrtillsList })
    }
  }, [QRorTillDetailsList])

  useEffect(() => {
    if (distributorNameValue) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${distributorNameValue.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
      // props.getQRorTillDetails(distributorNameValue.value)
    }
  }, [distributorNameValue])

  useEffect(() => {
    if (distributionNameValue) {
      // props.getQRorTillDetails(distributionNameValue.value)
      // setQrTillValue(null)
    }
  }, [distributionNameValue])

  useEffect(() => {
    if (
      props.DeliverymanUserList &&
      props.DeliverymanUserList.users?.length > 0
    ) {
      setTotalRecords(props.DeliverymanUserList.totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.DeliverymanUserList])

  useEffect(() => {
    setQRorTillDetailsList(
      props.qrOrTillDetails.map(function (item) {
        return {
          value: item.id,
          label: item.tillNumber,
        }
      })
    )
  }, [props.qrOrTillDetails])

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDeliveryUsers(requestData)
  }, [searchText])

  const [tags, setTags] = useState([])
  //////
  // const [tags, setTags] = useState([
  //   { id: "Thailand", text: "Thailand" },
  //   { id: "India", text: "India" },
  //   { id: "Vietnam", text: "Vietnam" },
  //   { id: "Turkey", text: "Turkey" },
  // ]);

  const updateTags = (selectedTill) => {
    var newLabel = ''
    var newValue = ''

    if ((selectedTill != null) != '' && mfiValue != null) {
      newLabel = mfiValue.label + ' | ' + selectedTill.label
      newValue = selectedTill.value

      //check if same till number already added or same MFI already added.
      var found = tags.filter((t) => {
        return t.id == newValue || t.mfi == mfiValue.label
      })
      if (found.length <= 0) {
        setTags([
          ...tags,
          { id: newValue, text: newLabel, mfi: mfiValue.label },
        ])
      }
    }
  }

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i))
  }

  useEffect(() => {
    if (tags.length <= 0) {
      setQrTillValue(null)
    } else {
      setErrFound(false)
    }
  }, [tags])

  const handleAddition = (tag) => {
    alert('alert called')
    //if (tags.some(item => item[mfi] === tag.);)
    //setTags([...tags, tag]);
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags].slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    setTags(newTags)
  }

  const handleTagClick = (index) => { }

  // const onTagUpdate = (i: any, newTag: any) => {
  //   const updatedTags = tags.slice();
  //   updatedTags.splice(i, 1, newTag);
  //   setTags(updatedTags);
  // };

  ///
  return (
    <div>
      <VzPageContainer title='Deliveryman Addition Form'>
        <Row>
          <Col style={{ float: 'left' }}>
            <h4>
              <b>Deliveryman Addition Form</b>
            </h4>
            <br />
          </Col>
        </Row>


        {isPermittedPage('Add Delivery User', props.actions, props.userRole) &&
          <Row style={{ marginBottom: 30 }}>
            <Col>
              <div className='form-group'>
                <Button className='form-btn' variant='primary' onClick={AddUser}>
                  Add Delivery User
                </Button>
              </div>
            </Col>
          </Row>
        }

        {/* <Row>
          <Col md={{ span: 3, offset: 4 }} style={{ float: 'right' }}>
            <div className='form-group'>
              <ReactSelect
                id='DistributorFilter'
                name='DistributorFilter'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Distributor'
                isMulti={false}
                isClearable={true}
                handleOnChange={(option) => {
                  if (!option || !option.value) {
                    setSelectedDistributorFilter(null)
                  } else {
                    setSelectedDistributorFilter(option)
                  }
                }}
                options={DistributorsList}
              ></ReactSelect>
            </div>
          </Col>
          <Col md={{ span: 3 }} style={{ float: 'right' }}>
            <div className='form-group'>
              <ReactSelect
                id='DistCenterFilter'
                name='DistCenterFilter'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Distribution Center'
                isMulti={false}
                isClearable={true}
                onChange={null}
                options={DistCentersList}
              ></ReactSelect>
            </div>
          </Col>
          <Col md={{ span: 2 }} style={{ float: 'right' }}>
            <Button
              className='form-btn pull-right'
              variant='primary'
              style={{ float: 'right', margin: '1px 0px 0px 0px' }}
              onClick={OpenModelForAddDeliveryMan}
            >
              <i className='fa fa-plus' /> Add New User
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col xs={12} md={12}>
            <ServerSideDataTable
              data={props.DeliverymanUserList.users ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
            {/* <CustomDataTable
              data={props.DeliverymanUserList ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
            /> */}
          </Col>
        </Row>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Formik
            enableReinitialize
            initialValues={currentUser}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    User Record
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='distributor'
                            label='Distributor'
                            name='distributor'
                            value={distributorNameValue}
                            options={DistributorsList}
                            handleOnChange={(option) => {
                              setDistributorNameValue(option)
                              currentUser.distributor = option.value
                              getDistirbutorMfiList(option.value)
                              resetDropDowns()
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='distCenter'
                            label='Dist Center'
                            name='distCenter'
                            value={distributionNameValue}
                            options={DistCentersList}
                            handleOnChange={(option) => {
                              setDistributionNameValue(option)
                              currentUser.distCenter = option.value
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='mfi'
                            label='Mfi'
                            name='mfi'
                            value={mfiValue}
                            options={MfiList}
                            handleOnChange={(option) => {
                              setMfiValue(option)
                              currentUser.mfi = option.value
                              getDistirbutorTillList(option.value)
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='qrTill'
                            label='Qr/Till Numbers'
                            name='qrTill'
                            value={qrTillValue}
                            options={tillList}
                            handleOnChange={(option) => {
                              updateTags(option)
                              setQrTillValue(option)
                              currentUser.qrTill = option.value
                            }}
                          // isMultiSelect={true}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='firstName'
                            label='First Name'
                            name='firstName'
                            handleOnChange={(e) => (currentUser.firstName = e)}
                            value={currentUser.firstName}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='lastName'
                            label='Last Name'
                            name='lastName'
                            value={currentUser.lastName}
                            handleOnChange={(e) => (currentUser.lastName = e)}
                          />
                        </div>
                      </Col>
                      <Col s={12} md={{ span: 6 }}>
                        <div className='ReactTags'>
                          <ReactTags
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                            // delimiters={delimiters}
                            handleTagClick={handleTagClick}
                            inputFieldPosition='top'
                            // onClearAll={onClearAll}
                            // onTagUpdate={onTagUpdate}
                            // suggestions={[
                            //   { id: "1", text: "Albania" },
                            //   { id: "2", text: "Australia" },
                            //   { id: "3", text: "France" },
                            //   { id: "4", text: "India" },
                            //   { id: "5", text: "Oman" },
                            //   { id: "6", text: "Russia" },
                            //   { id: "7", text: "Serbia" },
                            //   { id: "8", text: "Swaziland" },
                            //   { id: "9", text: "United States of America" },
                            //   { id: "10", text: "Vietnam" }
                            // ]}
                            placeholder='Search...'
                            minQueryLength={2}
                            // maxLength={5}
                            autofocus={false}
                            allowDeleteFromEmptyInput={true}
                            autocomplete={true}
                            readOnly={false}
                            allowUnique={true}
                            allowDragDrop={true}
                            allowAdditionFromPaste={true}
                            // editable={true}
                            // clearAll={true}
                            tags={tags}
                          // classNames={{
                          //   tags: "react-tags__tags",
                          //   tagInput: "react-tags__tag-input",
                          //   tagInputField: "react-tags__tag-input-field",
                          //   selected: "react-tags__selected",
                          //   tag: "react-tags__tag",
                          //   remove: "react-tags__remove",
                          //   suggestions: "react-tags__suggestions",
                          //   activeSuggestion: "react-tags__active-suggestion"
                          // }}
                          />
                          {errFound && (
                            <Label style={{ color: 'red' }}>
                              QR/Till numbers required
                            </Label>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView || isEdit}
                            control='input'
                            type='userName'
                            label='Username'
                            name='userName'
                            value={currentUser.userName}
                            handleOnChange={(e) => (currentUser.userName = e)}
                            autoComplete='off'
                          />
                        </div>
                      </Col>
                      {!isEdit && !isView && (
                        /* <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView || isEdit}
                            control='input'
                            type='cnic'
                            label='CNIC'
                            name='cnic'
                            value={currentUser.cnic}
                            handleOnChange={(e) => (currentUser.cnic = e)}
                          />
                        </div>
                      </Col> */
                        <Col xs={12} md={6}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              readOnly={isView}
                              control='input'
                              type={isView ? 'text' : 'password'}
                              label='Password'
                              name='password'
                              value={currentUser.password}
                              handleOnChange={(e) => (currentUser.password = e)}
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            readOnly={isView}
                            formik={formik}
                            control='input'
                            type='email'
                            label='Email'
                            name='email'
                            value={currentUser.email}
                            handleOnChange={(e) => (currentUser.email = e)}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='phoneNumber'
                            label='Phone No.'
                            name='phoneNumber'
                            value={currentUser.phoneNumber}
                            handleOnChange={(e) =>
                              (currentUser.phoneNumber = e)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='routeNo'
                            label='Route Id'
                            name='routeNo'
                            value={currentUser.routeNo}
                            handleOnChange={(e) => (currentUser.routeNo = e)}
                          />
                        </div>
                      </Col>
                      {(isView || isEdit) && (
                        <Col xs={12} md={6}>
                          <label
                            // style={{ marginBottom: 0 }}
                            className='d-flex justify-space-between'
                          >
                            VL Till No : {currentUser.qrCode}
                            {/* <FormikControl
                              formik={formik}
                              control='input'
                              type='qrCode'
                              // label='VL Till No.'
                              name='qrCode'
                              value={currentUser.qrCode}
                              handleOnChange={(e) => (currentUser.qrCode = e)}
                              readOnly={isView}
                            /> */}
                          </label>
                          <div>
                            <QRCode
                              size={256}
                              style={{
                                height: 'auto',
                                maxWidth: '20%',
                                width: '20%',
                              }}
                              value={currentUser.qrCode}
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  {/* {isView ? null : ( */}
                  <>
                    <input
                      type='submit'
                      className='btn btn-primary'
                      value='Save'
                    />
                    <Button
                      className='btn btn-default'
                      onClick={() => setModalShow(false)}
                    >
                      Close
                    </Button>
                  </>
                  {/* )} */}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    selectedDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    DeliverymanUserList: state.DeliverymanUserRegistration
      ? state.DeliverymanUserRegistration
      : [],
    qrOrTillDetails: state.qrOrTillDetails ? state.qrOrTillDetails : [],
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  updateDeliveryUser,
  getDeliveryUsers,
  getQRorTillDetails,
  addDeliveryUser,
  deleteDeliveryUser,
})(DeliverymanUserRegistration)
