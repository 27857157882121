import {
  GET_PRINCIPALS,
  ADD_PRINCIPALS,
  UPDATE_PRINCIPALS,
  DELETE_PRINCIPALS,
} from "../../Types";

const managePrincipalsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PRINCIPALS:
      return [...action.payload];
    case ADD_PRINCIPALS:
      return [...state, action.payload];
    case UPDATE_PRINCIPALS:
      return [...state, action.payload];
    case DELETE_PRINCIPALS:
      return state.filter(e => e.id !== action.payload);
    default:
      return state;
  }
};
export default managePrincipalsReducer;
