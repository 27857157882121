import axios from '../../apis/Api.js'
import { SIGN_IN, SIGN_OUT } from '../../Types'
import storage from '../../apis/Storage'
import history, { logout } from '../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const requestPasswordRecoveryLink = (username) => async (dispatch) => {
  dispatch(showLoading())

  await axios
    .post('api/Authenticate/Forgotpassword'.concat(`?username=${username}`))
    .then((response) => {
      console.info(JSON.stringify(response))

      if (response.data.statusCode == '200') {
        toast.success('Check your email for password reset link.')
      } else {
        toast.error(
          response.data.message || 'Error while processing the request'
        )
      }
    })
    .catch((error) => {
      console.error(error)
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
    .finally(() => dispatch(hideLoading()))
}

export const resetAccountPassword =
  (code, username, password, confirmPassword, onSuccess, onFailure) =>
  async (dispatch) => {
    dispatch(showLoading())

    await axios
      .post('api/Authenticate/ResetPassword', {
        username,
        password,
        confirmPassword,
        code,
      })
      .then(({ data }) => {
        console.info(data)

        toast.success(JSON.stringify(data.message))

        onSuccess()
      })
      .catch((exception) => {
        console.error(JSON.stringify(exception))

        toast.error(
          exception?.message || 'Error while processing this request...'
        )

        onFailure()
      })

    dispatch(hideLoading())
  }

export const signIn = (username, password) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/Authenticate/Login', {
      username,
      password,
    })
    .then(({ data }) => {
      if (data.isSuccess) {
        storage.set('_appLogged', 'true')
        storage.set('_appToken', data.response.token)
        storage.set('_userRights', JSON.stringify(data.response.userRights))
        storage.set(
          '_userPermissionsList',
          JSON.stringify(data.response.userPermissionsList)
        )
        storage.set('_appUserId', data.response.userId)
        storage.set('_appUserRoleId', data.response.userRoleId)
        storage.set('_appUserRole', data.response.userRole)
        storage.set('_userFullName', data.response.fullName)

        dispatch({
          type: SIGN_IN,
        })
        history.push('/dashboard')
        history.go('/dashboard')
      } else {
        toast.error(data.message)
        dispatch(hideLoading())
        return
      }
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const signOut = () => async (dispatch) => {
  dispatch(showLoading())

  storage.set('_appLogged', 'false')
  storage.set('_appToken', '')
  storage.set('_userRights', '{}')
  storage.set('_userPermissionsList', '[]')
  storage.set('_appUserId', '')
  storage.set('_appUserRoleId', '')

  dispatch({ type: SIGN_OUT })

  dispatch(hideLoading())

  history.push('/login')
  history.go('/login')
}
