import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import ReactSelect from 'react-select'
import { Input } from 'reactstrap'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { useState, useEffect } from 'react'
import {
  GET_AREAS,
  GET_CITIES,
  GET_MFI_DDL,
  GET_MFI_WALLET_LIMIT,
} from '../../../Types'
import { toast } from 'react-toastify'
import {
  addMfiWalletLimit,
  deleteMfiWalletLimit,
  resetMfiWalletList,
} from './MfiWalletRequiredLimitActions'
import { confirmAlert } from 'react-confirm-alert'
import { Formik, Form } from 'formik'

const MfiWalletRequiredLimit = (props) => {
  const [walletLimi, setWalletLimi] = useState('')
  const [selectedMfi, setSelectedMfi] = useState('')
  const [mfiToUpdate, setMfiToUpdate] = useState({})

  const [modalShow, setModalShow] = useState(false)

  const mfiColumns = [
    {
      Header: 'Mfi Name',
      accessor: 'value',
    },
  ]

  const walletColumns = [
    {
      Header: 'Wallet limit ',
      accessor: 'value',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button onClick={() => Edit(row.value)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button>
          &nbsp;
          <button
            onClick={() => Delete(row.value)}
            className='btn btn-danger'
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fa fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  const addMfiWalletLimit = async () => {
    if (selectedMfi == '' || selectedMfi == null) {
      toast.error('Select Mfi ')
      return
    } else if (walletLimi == '' || walletLimi == null) {
      toast.error('Enter Mfi Wallet Limit')
      return
    }

    let checkIsExist = props.mfiWalletList.filter(
      (item) =>
        item.value.toUpperCase() === walletLimi.toUpperCase() ||
        item.value.replace(/ /g, '').trim().toUpperCase() ===
          walletLimi.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist.length > 0) {
      toast.warn('Wallet limit already exist for this MFI')
      return
    }

    let data = {
      Name: walletLimi,
      MfiId: selectedMfi.id,
    }
    await props.addMfiWalletLimit(data)
    getMfiWalletLimit()

    // setSelectedMfi(null)
    setWalletLimi('')
  }

  const getMfiWalletLimit = () => {
    if (selectedMfi != undefined && selectedMfi?.id != undefined) {
      props.getDropdownList(
        `api/Mfi/GetMfiRequiredLimitsDDL/${selectedMfi.id}`,
        GET_MFI_WALLET_LIMIT
      )
    }
  }

  const Edit = (id) => {
    let record = props.mfiWalletList.find((x) => x.id == id)
    setMfiToUpdate(record)
    setModalShow(true)
  }

  const editMfiWalletLimit = async () => {
    if (selectedMfi == '' || selectedMfi == null) {
      toast.error('Select Mfi ')
      return
    } else if (mfiToUpdate.value == '' || mfiToUpdate.value == null) {
      toast.error('Enter Mfi Wallet Limit')
      return
    }

    let checkIsExist = props.mfiWalletList.filter(
      (item) =>
        item.value.toUpperCase() === walletLimi.toUpperCase() ||
        item.value.replace(/ /g, '').trim().toUpperCase() ===
          walletLimi.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist.length > 0) {
      toast.warn('Wallet limit already exist for this MFI')
      return
    }

    let data = {
      id: mfiToUpdate.id,
      Name: mfiToUpdate.value,
      MfiId: selectedMfi.id,
    }
    setModalShow(false)

    await props.addMfiWalletLimit(data)
    setMfiToUpdate({})
    getMfiWalletLimit()
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const DeleteRecord = async (id) => {
    await props.deleteMfiWalletLimit(id)
  }

  useEffect(() => {
    props.resetMfiWalletList()

    props.getDropdownList(`api/Mfi`, GET_MFI_DDL)
  }, [])

  useEffect(() => {
    props.resetMfiWalletList()
    getMfiWalletLimit()
  }, [selectedMfi])
  return (
    <div>
      <VzPageContainer title='MFI Wallet Required Limit'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>MFI Wallet Required Limit</b>
            </h4>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col
            md={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Col md={2}>
              <div className='form-group'>
                <ReactSelect
                  id='mfi'
                  name='mfi'
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Select Mfi'
                  isMulti={false}
                  value={selectedMfi}
                  isClearable={true}
                  onChange={(res) => setSelectedMfi(res)}
                  options={props.mfiList ?? []}
                ></ReactSelect>
              </div>
            </Col>
            <Col md={2}>
              <div className='form-group'>
                <Input
                  value={walletLimi}
                  onChange={(name) => setWalletLimi(name.target.value)}
                  type='text'
                  placeholder='Enter Wallet Limit'
                />
              </div>
            </Col>
            <Col md={1} style={{ marginTop: '10px', display: 'inline-block' }}>
              <div className='form-group'>
                <Button
                  className='form-btn'
                  variant='primary'
                  onClick={addMfiWalletLimit}
                  style={{ fontSize: 14 }}
                >
                  Add Limit
                </Button>
              </div>
            </Col>
            <Col xs={12} md={1}></Col>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col xs={12} md={5}>
            <CustomDataTable
              data={props.mfiList ?? []}
              columns={mfiColumns}
              getTrProps={null}
            />
          </Col>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={5}>
            <CustomDataTable
              data={props.mfiWalletList ?? []}
              columns={walletColumns}
              getTrProps={null}
            />
          </Col>{' '}
          <Col xs={12} md={1}></Col>
        </Row>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik>
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    Update Wallet Limit
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Col md={4}>
                        <div className='form-group'>
                          MFI : {selectedMfi?.label}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className='form-group'>
                          <Input
                            value={mfiToUpdate.value}
                            onChange={(name) =>
                              setMfiToUpdate({
                                id: mfiToUpdate.id,
                                label: name.target.value,
                                value: name.target.value,
                              })
                            }
                            type='text'
                            placeholder='Enter Wallet Limit'
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className='btn btn-primary'
                    onClick={editMfiWalletLimit}
                  >
                    Update
                  </Button>

                  <Button
                    className='btn btn-default'
                    onClick={() => setModalShow(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mfiList: state.ddlList.mfiList,
    mfiWalletList: state.ddlList.mfiWalletList,
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  addMfiWalletLimit,
  deleteMfiWalletLimit,
  resetMfiWalletList,
})(MfiWalletRequiredLimit)
