import { Component } from 'react'
import Routes from './routes/Index'
import storage from './apis/Storage'
import './App.css'
import Footer from './layouts/Footer/Footer'
import Header from './layouts/Header/Header'
import SideNavbar from './layouts/SideNavbar/SideNavbar'
import { connect } from 'react-redux'
import LoadingBar from 'react-redux-loading-bar'
import { ToastContainer } from 'react-toastify'
import DistributorSideNabar from './layouts/SideNavbar/DistributorSideNabar'
import DistributorUserRoutes from './routes/DistributorUserRoutes'
import DefaultRoutes from './routes/DefaultRoutes'
// import history, { logout } from './history'
class App extends Component {
  constructor(props) {
    super(props)
    this.state = { showNavbar: true }
  }
  render() {
    const isLogin = storage.get('_appLogged') === 'true' ? true : false
    const userRights = JSON.parse(storage.get('_userRights'))
    // if (!isLogin) {
    //   logout()
    //   history.push('/login')
    //   history.go('/login')
    // }

    return (
      <>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          limit={3}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <LoadingBar
          updateTime={10}
          maxProgress={100}
          showFastActions={false}
          progressIncrease={10}
          style={{
            position: 'fixed',
            top: 0,
            opacity: 1,
            backgroundColor: '#ea5f2d',
            height: '6px',
            zIndex: '999999',
          }}
        />
        {isLogin ? (
          <div>
            {userRights ? (
              <div>
                <Header
                  showNavbar={this.state.showNavbar}
                  onNavToggle={() => {
                    setTimeout(() => {
                      this.setState({
                        showNavbar: !this.state.showNavbar,
                      })
                    }, [150])
                  }}
                />
                <DistributorSideNabar
                  onClickOutside={() => {
                    setTimeout(() => {
                      this.setState({
                        showNavbar: !this.state.showNavbar,
                      })
                    }, [150])
                  }}
                  isNavbarOpen={this.state.showNavbar}
                />
                <div className='content-wrapper'>
                  <section className='content'>
                    <main className='container-fluid'>
                      <DistributorUserRoutes />
                      {this.props.children}
                    </main>
                  </section>
                </div>
                <Footer />
              </div>
            ) : (
              <>
                <Header
                  showNavbar={this.state.showNavbar}
                  onNavToggle={() => {
                    setTimeout(() => {
                      this.setState({
                        showNavbar: !this.state.showNavbar,
                      })
                    }, [150])
                  }}
                />
                <SideNavbar
                  onClickOutside={() => {
                    setTimeout(() => {
                      this.setState({
                        showNavbar: !this.state.showNavbar,
                      })
                    }, [150])
                  }}
                  isNavbarOpen={this.state.showNavbar}
                />
                <div className='content-wrapper'>
                  <section className='content'>
                    <main className='container-fluid'>
                      <Routes />
                      {this.props.children}
                    </main>
                  </section>
                </div>
                <Footer />
              </>
            )}
          </div>
        ) : (
          <>
            <DefaultRoutes />
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    token: state.auth.token,
    userId: state.auth.userId,
  }
}

export default connect(mapStateToProps)(App)
