import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'

import ReactSelect from 'react-select'
import { Input } from 'reactstrap'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import { getUserRoles } from '../../Users/ManageUsersAction'
import { addNotification, getNotifications } from './NotificationsActions'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, Redirect } from 'react-router-dom'
import Moment from 'react-moment'
import { ElementFlags } from 'typescript'

const Notifications = (props) => {
  const [rolesList, setRolesList] = useState([])
  const [selectedRole, setSelectedRole] = useState()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [filterRole, setFilterRole] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(null)
  const [filterToDate, setFilterToDate] = useState(null)

  // Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterValuesModal, setFilterValuesModal] = useState({
    role: '',
    fromDate: '',
    toDate: '',
  })

  const columns = [
    {
      Header: 'User',
      accessor: 'referenceType',
      Cell: (row) => (
        <div>
          {row.value == 'General'
            ? row.value
            : row.value.replace('General', '')}
        </div>
      ),
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value, row }) => {
        var desc = ""
        const isNewFormat = row.original.isNewFormat;
        if (isNewFormat) {
           const parsedValue = JSON.parse(value);
           console.log('cell json parse', parsedValue);
           desc = parsedValue.Text;
        }
        else {
          desc = value;
        }
        return <span>{desc}</span>;
      }
    },
    {
      Header: 'Date',
      accessor: 'createdDate',
      Cell: (row) => (
        <div>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
  ]

  const AddNotification = async () => {
    if (selectedRole == '' || selectedRole == null) {
      toast.error('Select Role ')
      return
    } else if (title == '' || title == null) {
      toast.error('Enter Title')
      return
    } else if (description == '' || description == null) {
      toast.error('Enter Description')
      return
    }

    let data = {
      Title: title,
      Description: description,
      IsRead: true,
      ReceiverCNIC: '1',
      ReferenceType: 'General' + selectedRole.label,
      ReferenceId: '',
      Role: selectedRole.value,
    }

    await props.addNotification(data)

    setTitle('')
    setDescription('')
    setSelectedRole(null)
    getData(currentPageNo)
  }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterRole(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterValuesModal({
      role: '',
      fromDate: '',
      toDate: '',
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      role: filterRole == null ? null : filterRole.value,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }

    props.getNotifications(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      role: filterRole == null ? null : filterRole.value,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
    }

    props.getNotifications(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)

    let requestData = {
      role: event.role == '' ? null : event.role,
      fromDate:
        event.fromDate == ''
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.fromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        event.toDate == ''
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.toDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    props.getNotifications(requestData)
    setShow(false)
    setFilterFromDate(event.fromDate == '' ? null : event.fromDate)
    setFilterToDate(event.toDate == '' ? null : event.toDate)
  }

  useEffect(() => {
    props.getUserRoles()
    // props.getNotifications()
  }, [])

  useEffect(() => {
    let requestData = {
      role: filterRole == null ? null : filterRole.value,

      fromDate: filterFromDate == null ? null : filterFromDate,
      toDate: filterToDate == null ? null : filterToDate,
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
    }

    props.getNotifications(requestData)
  }, [searchText])

  useEffect(() => {
    let roles = props.Roles.map(function (item) {
      return {
        value: item.id,
        label: item.name,
      }
    })

    setRolesList([{ label: 'All', value: 'All' }, ...roles])
  }, [props.Roles])

  useEffect(() => {
    if (props.Notifications && props.Notifications.notifications?.length > 0) {
      setTotalRecords(props.Notifications.totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.Notifications])

  return (
    <div>
      <VzPageContainer title='Notifications'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Notifications</b>
            </h4>
          </Col>
        </Row>

        <Row>
          <Col md={3} style={{ float: 'left' }}>
            <div className='form-group'>
              <ReactSelect
                id='role'
                name='role'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Role'
                isMulti={false}
                value={selectedRole}
                isClearable={true}
                onChange={(val) => setSelectedRole(val)}
                options={rolesList}
              ></ReactSelect>
            </div>
          </Col>
          <Col md={3}>
            <div className='form-group'>
              <Input
                value={title}
                onChange={(name) => setTitle(name.target.value)}
                type='text'
                placeholder='Enter Title'
              />
            </div>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Col md={6}>
            <div className='form-group'>
              <Input
                value={description}
                onChange={(name) => setDescription(name.target.value)}
                type='text'
                placeholder='Enter Description'
              />
            </div>
          </Col>
          <Col md={2}>
            <Button
              className='form-btn pull-right'
              variant='primary'
              style={{ float: 'right' }}
              onClick={AddNotification}
            >
              <i /> Add Notification
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={3} style={{ float: 'right' }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Role'
                                name='role'
                                options={rolesList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.role = e.value
                                  setFilterRole({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterRole}
                              />
                            </Col>

                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '40px' }}>
          <Col xs={12}>
            {/* <CustomDataTable
              data={props.Notifications ?? []}
              columns={columns}
              getTrProps={null}
            /> */}
            <ServerSideDataTable
              data={props.Notifications.notifications ?? []}
              columns={columns}
              getTrProps={(event, data) => { }}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // banks: state.ddlList.banks,
    Roles: state.ddlList?.userRoles ? state.ddlList?.userRoles : [],
    Notifications: state.Notifications,
  }
}

export default connect(mapStateToProps, {
  getUserRoles,
  addNotification,
  getNotifications,
})(Notifications)
