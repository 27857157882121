import { GET_DISTRIBUTORSLIST, EMPTY_DISTRIBUTOR,DELETE_DISTRIBUTOR } from "../../../Types";

const listofDistributorReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DISTRIBUTORSLIST:
      return [...action.payload];
    case DELETE_DISTRIBUTOR:
      return state.filter((e) => e.id !== action.payload);
    case EMPTY_DISTRIBUTOR:
      return [];
    default:
      return state;
  }
};
export default listofDistributorReducer;
