import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'

import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Moment from 'react-moment'
import FormikControl from '../../../layouts/Input/FormikControl'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import {
  getRsoDetailsReport,
  resetRsoDetailsReport,
  downloadJob,
} from './RsoDetailsReportActions'
import { GET_RSO_USERS } from '../../../Types'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { isPermittedPage } from '../../../apis/Utility'

const RsoDetailsReport = (props) => {
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [filterRSO, setFilterRSO] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(new Date())
  const [filterToDate, setFilterToDate] = useState(
    new Date().setMonth(new Date().getMonth() + 3)
  )
  const [maxToDate, setMaxToDate] = useState(
    new Date().setMonth(new Date().getMonth() + 3)
  )
  const [filterStatus, setFilterStatus] = useState(null)

  const [filterValuesModal, setFilterValuesModal] = useState({
    rso: '',
    fromDate: new Date(),
    toDate: new Date().setMonth(new Date().getMonth() + 3),
    status: '',
  })

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const Columns = [
    {
      Header: 'Sr. No',
      accessor: 'srNo',
    },
    {
      Header: 'RSO Name',
      accessor: 'rsoName',
    },
    {
      Header: 'User Name',
      accessor: 'userName',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Total Active Days',
      accessor: 'totalActiveDays',
    },
    // {
    //   Header: 'Last Active Date & Time ',
    //   accessor: 'lastActiveDateAndTime',
    //   Cell: (row) => (
    //     <div style={{ width: '75px' }}>
    //       <Moment format='DD/MM/YYYY'>{row.value}</Moment>
    //     </div>
    //   ),
    // },
  ]

  const statusColumns = [
    {
      label: 'Active',
      value: 'Active',
    },
    {
      label: 'Offline',
      value: 'Offline',
    },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterRSO(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterStatus(null)
    setFilterValuesModal({
      rso: '',
      fromDate: '',
      toDate: '',
      status: '',
    })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      rsoId: filterRSO == null ? null : filterRSO.value,

      status: filterStatus == null ? null : filterStatus.value,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }
    props.getRsoDetailsReport(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      rsoId: filterRSO == null ? null : filterRSO.value,

      status: filterStatus == null ? null : filterStatus.value,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
    }
    props.getRsoDetailsReport(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)

    let requestData = {
      rsoId: event.rso == '' ? null : event.rso,
      status: filterStatus == null ? null : filterStatus.value,

      fromDate:
        event.fromDate == ''
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.fromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        event.toDate == ''
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.toDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    props.getRsoDetailsReport(requestData)
    setShow(false)
    setFilterFromDate(event.fromDate == '' ? null : event.fromDate)
    setFilterToDate(event.toDate == '' ? null : event.toDate)
  }

  const Download = () => {
    let requestData = {
      rsoId: filterRSO == null ? null : filterRSO.value,

      status: filterStatus == null ? null : filterStatus.value,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],

      searchText: searchText,
    }
    props.downloadJob(requestData)
  }

  const setToDateValueAndMax = (value) => {
    setFilterToDate(new Date(value).setMonth(new Date(value).getMonth() + 3))
    filterValuesModal.toDate = new Date(value).setMonth(
      new Date(value).getMonth() + 3
    )
    setMaxToDate(new Date(value).setMonth(new Date(value).getMonth() + 3))
  }

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      rsoId: filterRSO == null ? null : filterRSO.value,

      status: filterStatus == null ? null : filterStatus.value,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }
    props.getRsoDetailsReport(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.Reports && props.Reports.length > 0) {
      setTotalRecords(props.Reports[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.Reports])

  useEffect(() => {
    props.getDropdownList(`api/User/GetRSODDL`, GET_RSO_USERS)

    return () => {
      props.resetRsoDetailsReport()
    }
  }, [])
  return (
    <VzPageContainer title='Rso Details Report'>
      <div>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Rso Details Report</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='RSO ID'
                                  name='rso'
                                  options={props.rsoUsers}
                                  isClearable={false}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.rso = e.value
                                    setFilterRSO({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterRSO}
                                />
                              </Col>
                            </Row>
                            {/* <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                    setToDateValueAndMax(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  maxDate={maxToDate}
                                  minDate={filterFromDate}
                                />
                              </Col>
                            </Row> */}
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Status'
                                  name='status'
                                  options={statusColumns}
                                  isClearable={false}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(value) => {
                                    filterValuesModal.status = value
                                    setFilterStatus(value)
                                  }}
                                  value={filterValuesModal.status}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          {isPermittedPage('Export', props.actions, props.userRole) &&
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={Download}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-download me-1' />
                Export
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col xs={12}>
            <ServerSideDataTable
              data={props.Reports ?? []}
              columns={Columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </div>
    </VzPageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    Reports: state.RsoDetailsReport,
    rsoUsers: state.ddlList.rsoUsers ? state.ddlList.rsoUsers : [],
  }
}

export default connect(mapStateToProps, {
  getRsoDetailsReport,
  resetRsoDetailsReport,
  getDropdownList,
  downloadJob,
})(RsoDetailsReport)
