import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import storage from '../../../apis/Storage'
import { GET_Shop_wise_Payment_Status_Report } from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const getPaymentStatusReport = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/ShopOrder/GetPaymentStatusReport`, postedData)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_Shop_wise_Payment_Status_Report,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}
