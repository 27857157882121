import { GET_LOAN_DISBURSEMENT_REPORT } from '../../../Types'

const LoanDisbursementReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_LOAN_DISBURSEMENT_REPORT:
      return action.payload

    default:
      return state
  }
}
export default LoanDisbursementReportReducer