import { NOTIFICATION_VISITED, NEW_NOTIFICATION, GET_DISTRIBUTOR_ALL_CATEGORY_INBOX, GET_DISTRIBUTOR_INFO_CATEGORY_INBOX, GET_DISTRIBUTOR_ORDER_CATEGORY_INBOX, GET_DISTRIBUTOR_COMPLAINT_CATEGORY_INBOX, GET_DISTRIBUTOR_OTHER_CATEGORY_INBOX } from '../../../Types'
import _ from 'lodash'
const initialState = {
  handlerNotificationObject: null,
  newNotificationData: null,
  ticketsData: {
    infoTickets: [],
    orderTickets: [],
    complaintTickets: [],
    otherTickets: []
  }
}
const DistributorInboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_VISITED:
      {
        var handlerObject = action.payload;
        if (handlerObject.handlerCategoryCode == "1") {
          var index = -1;
          index = state.ticketsData?.infoTickets?.dataList?.findIndex(h => h.handlerId == handlerObject?.handlerId)
          const updatedInfoTickets = [...state.ticketsData?.infoTickets?.dataList];
          updatedInfoTickets[index] = {
            ...updatedInfoTickets[index],
            notificationVisited: true,
          };

          return {
            ...state,
            ticketsData: {
              ...state.ticketsData,
              infoTickets: {
                ...state.ticketsData.infoTickets,
                dataList: updatedInfoTickets
              }
            }
          }
        }
        else if (handlerObject.handlerCategoryCode == "2") {
          var index = -1;
          index = state.ticketsData?.orderTickets.dataList?.findIndex(h => h.handlerId == handlerObject?.handlerId)

          const updatedOrderTickets = [...state.ticketsData?.orderTickets?.dataList];
          updatedOrderTickets[index] = {
            ...updatedOrderTickets[index],
            notificationVisited: true,
          };

          return {
            ...state,
            ticketsData: {
              ...state.ticketsData,
              orderTickets: {
                ...state.ticketsData.orderTickets,
                dataList: updatedOrderTickets
              }
            }
          }
        }
        else if (handlerObject.handlerCategoryCode == "3") {
          var index = -1;
          index = state.ticketsData?.complaintTickets?.dataList?.findIndex(h => h.handlerId == handlerObject?.handlerId)

          const updatedComplaintTickets = [...state.ticketsData?.complaintTickets?.dataList];
          updatedComplaintTickets[index] = {
            ...updatedComplaintTickets[index],
            notificationVisited: true,
          };

          return {
            ...state,
            ticketsData: {
              ...state.ticketsData,
              complaintTickets: {
                ...state.ticketsData.complaintTickets,
                dataList: updatedComplaintTickets
              }
            }
          }
        }
        else if (handlerObject.handlerCategoryCode == "4") {
          var index = -1;
          index = state.ticketsData?.otherTickets?.dataList?.findIndex(h => h.handlerId == handlerObject?.handlerId)
          const updatedOtherTickets = [...state.ticketsData?.otherTickets?.dataList];
          updatedOtherTickets[index] = {
            ...updatedOtherTickets[index],
            notificationVisited: true,
          };

          return {
            ...state,
            ticketsData: {
              ...state.ticketsData,
              otherTickets: {
                ...state.ticketsData.orderTickets,
                dataList: updatedOtherTickets
              }
            }
          }
        }
        return { ...state }
      }
    case NEW_NOTIFICATION: {
      return { ...state, newNotificationData: action.payload }
    }
    case GET_DISTRIBUTOR_ALL_CATEGORY_INBOX:
      return { ...state, ticketsData: action?.payload }
    case GET_DISTRIBUTOR_INFO_CATEGORY_INBOX:
      return { ...state, ticketsData: { ...state.ticketsData, infoTickets: action?.payload } }
    case GET_DISTRIBUTOR_ORDER_CATEGORY_INBOX:
      return { ...state, ticketsData: { ...state.ticketsData, orderTickets: action?.payload } }
    case GET_DISTRIBUTOR_COMPLAINT_CATEGORY_INBOX:
      return { ...state, ticketsData: { ...state.ticketsData, complaintTickets: action?.payload } }
    case GET_DISTRIBUTOR_OTHER_CATEGORY_INBOX:
      return { ...state, ticketsData: { ...state.ticketsData, otherTickets: action?.payload } }
    default:
      return state
  }
}
export default DistributorInboxReducer
