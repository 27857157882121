import { useEffect, useState, useRef } from 'react'
import Moment from 'react-moment'
import { Link, Redirect } from 'react-router-dom'
import history from '../../../history'
import 'react-confirm-alert/src/react-confirm-alert.css'
import FormikControl from '../../../layouts/Input/FormikControl'
import ExcelIcon from '../../../assets/Images/Distributors/excel-icon.png'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  getJobs,
  addJob,
  downloadJob,
  resetDropdownList,
} from './JobsListAction'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import JobsDataFileTemplate from '../../../assets/Templates/JobTemplate.xlsx'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import {
  GET_DISTRIBUTOR_DDL,
  GET_JOBS_DISTRIBUTOR_DDL,
  GET_DISTRIBUTIONCENTER_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  GET_CITIES,
} from '../../../Types'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'

const JobsList = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))

  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const addJobFormValidationSchema = Yup.object().shape({
    distributorName: Yup.string().required('Distributor Name is required'),
    distributionCenter: Yup.string().required(
      'Distribution Center is required'
    ),
  })
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionCenter, setFilterDistributionCenter] = useState(null)
  const [FilterStatus, setFilterStatus] = useState(null)
  const todayDate = new Date()
  const monthStartDate = new Date(todayDate)
  monthStartDate.setDate(monthStartDate.getDate() - 8)
  const [filterFromDate, setFilterFromDate] = useState(monthStartDate)
  const [filterToDate, setFilterToDate] = useState(todayDate)

  const [cityValue, setCityValue] = useState(null)
  const [dataSheetSelectedFile, setDataSheetSelectedFile] = useState(null)
  const [addJobDistributorName, setAddJobDistributorName] = useState(null)
  const [addJobDistributionCenter, setAddJobDistributionCenter] = useState(null)
  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [
    addOrderDistributionCentersOptions,
    setAddOrderDistributionCentersOptions,
  ] = useState(null)

  const [
    filterDistributionCentersOptions,
    setFilterDistributionCentersOptions,
  ] = useState(null)
  const [jobModalShow, setJobModalShow] = useState(false)
  const [jobsList, setJobsList] = useState([])

  const [filterValuesModal, setFilterValuesModal] = useState({
    distributorName: '',
    distributionCenter: '',
    fromDate: monthStartDate,
    toDate: todayDate,
    status: '',
    city: '',
  })

  const [addJobModalInitialValue, setAddJobModalInitialValue] = useState({
    distributorName: '',
    distributionCenter: '',
    jobDataFile: null,
  })

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId:
        filterDistributionCenter == null
          ? null
          : filterDistributionCenter.value,
      City: cityValue == null ? null : cityValue.value,
      Status: FilterStatus == null ? null : FilterStatus.value,
      FromDate: filterFromDate == null ? null : filterFromDate,
      ToDate: filterToDate == null ? null : filterToDate,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getJobs(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId:
        filterDistributionCenter == null
          ? null
          : filterDistributionCenter.value,
      City: cityValue == null ? null : cityValue.value,
      Status: FilterStatus == null ? null : FilterStatus.value,
      FromDate: filterFromDate == null ? null : filterFromDate,
      ToDate: filterToDate == null ? null : filterToDate,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getJobs(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId:
        filterDistributionCenter == null
          ? null
          : filterDistributionCenter.value,
      City: cityValue == null ? null : cityValue.value,
      Status: FilterStatus == null ? null : FilterStatus.value,
      FromDate: filterFromDate == null ? null : filterFromDate,
      ToDate: filterToDate == null ? null : filterToDate,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getJobs(requestData)
  }, [searchText])
  useEffect(() => {
    if (props.jobsList) {
      setJobsList(props.jobsList)
      if (props.jobsList.length > 0) {
        setTotalRecords(props.jobsList[0].totalRecords)
      } else {
        setTotalRecords(0)
      }
    }
  }, [props.jobsList])

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
    props.getDropdownList(
      `api/DistShopInductionJob/GetDistributorsDDL`,
      GET_JOBS_DISTRIBUTOR_DDL
    )
    if (props.cities.length == 0) {
      props.getDropdownList(`api/City`, GET_CITIES)
    }
  }, [])
  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setFilterDistributionCentersOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          }
        })
      )
    } else {
      setFilterDistributionCentersOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.distCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddOrderDistributionCentersOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddOrderDistributionCentersOptions(
        props.distCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.distCentersddlList])

  useEffect(() => {
    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])
  useEffect(() => {
    if (addJobDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${addJobDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [addJobDistributorName])

  const jobStatusList = [
    {
      value: 'New',
      label: 'New',
    },
    {
      value: 'In Progress',
      label: 'In Progress',
    },
    {
      value: 'Complete',
      label: 'Complete',
    },
  ]
  const columns = [
    {
      Header: 'Job No.',
      accessor: 'jobNo',
      sortType: 'basic',
    },
    {
      Header: 'Distributor',
      accessor: 'distributorName',
      filter: 'includes', // new
    },
    {
      Header: 'Dist. Center',
      accessor: 'distCenterName',
    },
    {
      Header: 'Shops Count',
      accessor: 'numberofShops',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'Job Status',
      accessor: 'jobStatus',
    },
    {
      Header: 'Date Of Request',
      accessor: 'createdDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Matched Shops',
      accessor: 'matchedShops',
    },
    {
      Header: 'Unmatched Shops',
      accessor: 'unmatchedShops',
    },
    {
      Header: 'Progress',
      accessor: 'progress',
    },
    {
      Header: 'Action',
      id: 'download',
      accessor: 'id',
      Cell: (row) => (
        isPermittedPage('Download', props.actions, props.userRole) &&
        <div>
          <Link
            style={{ cursor: 'pointer', width: '106px' }}
            to='#'
            onClick={(event) => {
              download(event, row.value)
            }}
          >
            Download
          </Link>
        </div>
      ),
    },
  ]
  const citiesOptions = [
    { value: 'Lahore', label: 'Lahore' },
    { value: 'Karachi', label: 'Karachi' },
    { value: 'Islamabad', label: 'Islamabad' },
  ]
  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const download = (e, id) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    props.downloadJob(id)
  }
  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)

    let requestData = {
      DistributorId: event.distributorName == '' ? null : event.distributorName,
      DistCenterId:
        event.distributionCenter == '' ? null : event.distributionCenter,
      FromDate: event.fromDate,
      ToDate: event.toDate,
      Status: event.status == '' ? null : event.status,
      City: event.city == '' ? null : event.city,
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }

    props.getJobs(requestData)
    setShow(false)
    setFilterFromDate(event.fromDate)
    setFilterToDate(event.toDate)
  }
  const OpenModelForAddJob = (isOpen) => {
    setJobModalShow(isOpen)
    setAddJobModalInitialValue({
      distributorName: '',
      distributionCenter: '',
    })
    setDataSheetSelectedFile(null)
    setShow(false)
    setAddJobDistributionCenter(null)
    setAddJobDistributorName(null)
  }
  const submitJobData = (data) => {
    if (
      dataSheetSelectedFile != null &&
      addJobDistributorName &&
      addJobDistributionCenter
    ) {
      let requestData = {
        distributorId: addJobDistributorName.value, //data.distributorId,
        DistCenterId: addJobDistributionCenter.value,
        DataFile: dataSheetSelectedFile,
      }

      if (userRights != null && userRights?.userType == 'DistributorUser') {
        requestData = {
          ...requestData,
          assignedDistCenters: userRights?.distCenterIds,
        }
      }

      props.addJob(requestData)
      setJobModalShow(false)
    } else {
      toast.error('Please select Job Data Sheet')
    }
  }

  const validateDataSheetFile = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return
    }
    if (!image.name.match(/\.(xlsx|xls)$/)) {
      toast.error('You can only upload file of type (xlsx, xls)')
      event.target.value = null
      return
    } else {
      setDataSheetSelectedFile(event.target.files[0])
      addJobModalInitialValue.jobDataFile = event.target.files[0]
    }
  }
  const onRowClick = (e, rowData) => {
    history.push(`/JobDetails/${rowData.id}`)
    history.go(`/JobDetails/${rowData.id}`)
  }
  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionCenter(null)
    setCityValue(null)
    setFilterStatus(null)
    setFilterToDate(null)
    setFilterFromDate(null)

    setFilterValuesModal({
      distributorName: '',
      distributionCenter: '',
      fromDate: monthStartDate,
      toDate: todayDate,
      status: '',
      city: '',
    })
    props.resetDropdownList()
  }
  return (
    <div>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <VzPageContainer title='Shops Induction Jobs List'>
          <Row>
            <Col md={{ span: 3 }} style={{ float: 'left' }}>
              <h4>
                <b>Shops Induction Jobs List</b>
              </h4>
            </Col>
            {isPermittedPage('add job', props.actions, props.userRole) && (
              <Col md={{ span: 3, offset: 6 }} style={{ float: 'right' }}>
                <div className='form-group'>
                  <Button
                    className='form-btn pull-left'
                    variant='primary'
                    style={{ float: 'right', margin: '1px 0px 0px 0px' }}
                    onClick={() => OpenModelForAddJob(true)}
                  >
                    <i className='fa fa-tasks' /> Add Job
                  </Button>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12} style={{ float: 'right' }}>
              <Row>
                <Col md={3} style={{ float: 'right' }}>
                  <div ref={ref}>
                    <Button
                      onClick={openFilterModal}
                      className='m-3'
                      variant='outline-primary'
                    >
                      <i className='fa fa-filter' />
                      Filters
                    </Button>

                    <Overlay
                      show={show}
                      target={target}
                      placement='bottom'
                      container={ref}
                      containerPadding={20}
                      width='430px'
                      style={{ width: '430px' }}
                    >
                      <Popover id='popover-contained' width='400'>
                        <Popover.Header as='h3'>Filters</Popover.Header>
                        <Popover.Body>
                          <Formik
                            enableReinitialize
                            initialValues={filterValuesModal}
                            validator={() => ({})}
                            onSubmit={LoadDataByfilter}
                          >
                            {(formik) => (
                              <Form autoComplete='off'>
                                <Row>
                                  <Col xs={12} md={12}>
                                    <FormikControl
                                      formik={formik}
                                      control='react-select'
                                      label='Distributor Name'
                                      name='distributorName'
                                      options={addJobDistributorsOptions}
                                      isSearchable={true}
                                      handleOnChange={(e) => {
                                        if (!e || !e.value) {
                                          return
                                        }
                                        filterValuesModal.distributorName =
                                          e.value
                                        setFilterDistributorName({
                                          label: e.label,
                                          value: e.value,
                                        })
                                        setFilterDistributionCenter(null)
                                      }}
                                      value={filterDistributorName}
                                    />
                                  </Col>
                                  <Col xs={12} md={12}>
                                    <FormikControl
                                      formik={formik}
                                      control='react-select'
                                      label='Distribution Center'
                                      name='distributionCenter'
                                      options={
                                        addOrderDistributionCentersOptions
                                      }
                                      isSearchable={true}
                                      handleOnChange={(e) => {
                                        if (!e || !e.value) {
                                          return
                                        }
                                        filterValuesModal.distributionCenter =
                                          e.value
                                        setFilterDistributionCenter({
                                          label: e.label,
                                          value: e.value,
                                        })
                                      }}
                                      value={filterDistributionCenter}
                                    />
                                  </Col>
                                  <Row style={{ paddingRight: 0 }}>
                                    <Col xs={12} md={6}>
                                      <FormikControl
                                        // formik={formik}
                                        control='date'
                                        label='From Date'
                                        name='fromDate'
                                        handleOnChange={(value) => {
                                          filterValuesModal.fromDate = value
                                          setFilterFromDate(value)
                                        }}
                                        value={filterValuesModal.fromDate}
                                      />
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <FormikControl
                                        // formik={formik}
                                        control='date'
                                        // type='date'
                                        label='To Date'
                                        name='toDate'
                                        handleOnChange={(value) => {
                                          filterValuesModal.toDate = value
                                          setFilterToDate(value)
                                        }}
                                        value={filterValuesModal.toDate}
                                      />
                                    </Col>
                                  </Row>
                                  <Col xs={12} md={12}>
                                    <div className='form-group'>
                                      <FormikControl
                                        // formik={formik}
                                        control='react-select'
                                        label='Status'
                                        name='status'
                                        options={jobStatusList}
                                        handleOnChange={(e) => {
                                          if (!e || !e.value) {
                                            return
                                          }
                                          setFilterStatus({
                                            label: e.value,
                                            value: e.value,
                                          })
                                          filterValuesModal.status = e.value
                                        }}
                                        value={FilterStatus}
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={12}>
                                    <div className='form-group'>
                                      <FormikControl
                                        // formik={formik}
                                        control='react-select'
                                        label='City'
                                        name='city'
                                        options={props.cities}
                                        isSearchable={false}
                                        handleOnChange={(e) => {
                                          if (!e || !e.value) {
                                            return
                                          }
                                          setCityValue({
                                            label: e.value,
                                            value: e.value,
                                          })
                                          filterValuesModal.city = e.value
                                        }}
                                        value={cityValue}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div className='row'>
                                  <div className='form-group'>
                                    <Link
                                      onClick={handleResetFilters.bind(
                                        null,
                                        formik.resetForm
                                      )}
                                      to='#'
                                      className='active'
                                      style={{
                                        padding: '0px',
                                        marginTop: '10px',
                                        float: 'left',
                                      }}
                                    >
                                      Reset
                                    </Link>
                                    <Link
                                      onClick={() => {
                                        setShow(false)
                                        setTarget(null)
                                      }}
                                      to='#'
                                      className='active'
                                      style={{
                                        padding: '0px',
                                        marginTop: '10px',
                                        float: 'right',
                                      }}
                                    >
                                      Cancel
                                    </Link>
                                    <div className='float-right'>
                                      <Button
                                        type='submit'
                                        className='btn btn-primary'
                                        style={{
                                          marginRight: '10px',
                                          float: 'right',
                                        }}
                                      >
                                        Apply
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </div>
                </Col>
              </Row>
              <ServerSideDataTable
                data={jobsList}
                columns={columns}
                getTrProps={(event, data) => onRowClick(event, data)}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={totalRecords}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
            </Col>
          </Row>
          {/* //// Upload Job Data Sheet */}
          <Modal
            show={jobModalShow}
            onHide={() => setJobModalShow(false)}
            backdrop='static'
            keyboard={false}
            size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Add New Job
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize
                initialValues={addJobModalInitialValue}
                validationSchema={addJobFormValidationSchema}
                // validator={() => ({})}
                onSubmit={submitJobData}
              >
                {(formik) => (
                  <Form
                    autoComplete='off'
                    encType='multipart/form-data'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                      }
                    }}
                  >
                    <Container>
                      <Row>
                        <Col md={{ span: 5 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              // isSearchable={true}
                              // isClearable={true}
                              label='Distributor'
                              name='distributorName'
                              options={addJobDistributorsOptions}
                              handleOnChange={(option) => {
                                setAddJobDistributionCenter(null)
                                if (!option || !option.value) {
                                  setAddJobDistributorName(null)
                                  setAddJobModalInitialValue({
                                    ...addJobModalInitialValue,
                                    distributionCenter: '',
                                    distributorName: '',
                                  })
                                } else {
                                  setAddJobDistributorName(option)
                                  setAddJobModalInitialValue({
                                    ...addJobModalInitialValue,
                                    distributionCenter: '',
                                    distributorName: option.label,
                                  })
                                }
                              }}
                              value={addJobDistributorName}
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 7 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              // isSearchable={true}
                              // isClearable={true}
                              label='Distribution Center'
                              name='distributionCenter'
                              options={filterDistributionCentersOptions}
                              handleOnChange={(option) => {
                                if (!option || !option.value) {
                                  setAddJobDistributionCenter(null)
                                  addJobModalInitialValue.distributionCenter =
                                    ''
                                } else {
                                  setAddJobDistributionCenter(option)
                                  addJobModalInitialValue.distributionCenter =
                                    option.value
                                }
                              }}
                              value={addJobDistributionCenter}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={{ span: 5 }}>
                          <label style={{ width: '100%' }}>
                            <b>Principal:</b>
                            {addJobDistributionCenter
                              ? addJobDistributionCenter.principalName
                              : 'Not Selected'}
                          </label>
                          <label style={{ width: '100%' }}>
                            <b>City Name:</b>
                            {addJobDistributionCenter
                              ? addJobDistributionCenter.cityName
                              : 'Not Selected'}
                          </label>
                        </Col>
                        <Col xs={12} md={{ span: 7, offset: 0 }}>
                          <div className='form-group'>
                            <div>
                              <div>
                                Select Job Data Sheet File (Only Excel file)
                              </div>
                              {dataSheetSelectedFile && (
                                <div>
                                  <img
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'100px'}
                                    height={'100px'}
                                    src={ExcelIcon}
                                  />
                                  <br />
                                  <button
                                    className='btn btn-info'
                                    style={{
                                      marginLeft: '10px',
                                      marginTop: '10px',
                                      marginBottom: '20px',
                                    }}
                                    onClick={() => {
                                      addJobModalInitialValue.jobDataFile = null
                                      setDataSheetSelectedFile(null)
                                    }}
                                  >
                                    <i className='fa fa-close' />
                                    Remove
                                  </button>
                                </div>
                              )}
                              {!dataSheetSelectedFile && (
                                <div>
                                  <br />
                                  <input
                                    type='file'
                                    name='dataSheetSelectedFile'
                                    onChange={(event) =>
                                      validateDataSheetFile(event)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={{ span: 5 }}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={12}>
                          <Button
                            className='btn btn-default'
                            style={{ float: 'right', marginTop: '10px' }}
                            onClick={() => setJobModalShow(false)}
                          >
                            Cancel
                          </Button>
                          <input
                            type='submit'
                            className='btn btn-primary'
                            style={{
                              marginRight: '10px',
                              marginTop: '10px',
                              float: 'right',
                            }}
                            value='Save Job'
                          />

                          <a
                            href={`${JobsDataFileTemplate}`}
                            download='JobsTemplateFile'
                            className='active'
                            style={{
                              padding: '0px',
                              marginTop: '20px',
                              float: 'left',
                            }}
                          >
                            Download Template
                          </a>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </VzPageContainer>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    filteredDistributorList: state.ddlList.filteredDistributorList
      ? state.ddlList.filteredDistributorList
      : [],
    distCentersddlList: state.ddlList.selectedDistCentersList
      ? state.ddlList.selectedDistCentersList
      : [],
    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    jobsList: state.jobsList ? state.jobsList : [],
    cities: state.ddlList.cities ? state.ddlList.cities : [],
  }
}
export default connect(mapStateToProps, {
  getJobs,
  addJob,
  downloadJob,
  getDropdownList,
  resetDropdownList,
})(JobsList)
