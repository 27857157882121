import {
  GET_CONVERSATION,
  RESET_CONVERSATION,
  SET_CHAT_DATA,
  MESSAGE_RECEIVED,
  MESSAGES_READ,
} from '../../../Types'

const initialState = {
  messages: [],
  totalRecords: 0,
  chatActive: false,
  shopKeeperDetails: {},
  readMsgs: false,
}

const ConnectConversationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONVERSATION:
      return {
        ...state,
        messages: [...action.payload.messages, ...state.messages],
        totalRecords: action.payload.totalRecords,
        chatActive: action.payload.chatActive,
        readMsgs: false,
      }
    case MESSAGE_RECEIVED:
      let receivedMsgList = state.messages
      if (
        receivedMsgList.length > 0 &&
        receivedMsgList[0].vlCshoperKeeperTicketHandlerId ==
          action.payload.vlCshoperKeeperTicketHandlerId
      ) {
        receivedMsgList.push(action.payload)
      }
      return {
        ...state,
        messages: [...receivedMsgList],
        totalRecords: state.totalRecords + 1,
        readMsgs: false,
      }
    case MESSAGES_READ:
      let readMsgList = state.messages
      if (readMsgList.length > 0) {
        readMsgList.forEach((element) => {
          if (action.payload.includes(element.messageId)) {
            element.isRead = true
          }
        })
      }
      return {
        ...state,
        messages: [...readMsgList],
        readMsgs: true,
      }

    case SET_CHAT_DATA:
      return {
        ...state,

        shopKeeperDetails: action.payload,
      }
    case RESET_CONVERSATION:
      return initialState
    default:
      return state
  }
}
export default ConnectConversationReducer
