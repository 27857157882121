import { connect } from 'react-redux';
import { VzPageContainer } from '../../../layouts/VzPageContainer';
import {useState, useEffect} from 'react';
import FormikControl from '../../../layouts/Input/FormikControl';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getNotificationSchedularName } from '../../../utilities/FUNCTIONS';
import { Modal, Col, Row, Overlay, Button, Popover, Container } from 'react-bootstrap';
import { getUserRoles  } from '../../Users/ManageUsersAction';
import { addNotificationScheduler, delNotificationScheduler, getNotificationScheduler, updateNotificationScheduler } from './NotificationSchedulerAction';
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable';
import { confirmAlert } from 'react-confirm-alert'

const NotificationsScheduler = (props) => {
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [roleIdValue, setRoleIdValue] = useState(null);
    const [sendNotification, setSendNotification] = useState(null);
    const [currentPageSize, setCurrentPageSize] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [currentPageNo, setCurrentPageNo] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalTitleText, setModalTitleText] = useState('');
    

    const sendNotificationOptions =[
        {label:'1 Hour', value: 1},
        {label:'2 Hours', value: 2},
        {label:'3 Hours', value: 3},
        {label:'4 Hours', value: 4},
        {label:'5 Hours', value: 5},
        {label:'6 Hours', value: 6},
        {label:'7 Hours', value: 7},
        {label:'8 Hours', value: 8},
        {label:'9 Hours', value: 9},
        {label:'10 Hours', value: 10},
        {label:'11 Hours', value: 11},
        {label:'12 Hours', value: 12},
        {label:'13 Hours', value: 13},
        {label:'14 Hours', value: 14},
        {label:'15 Hours', value: 15},
        {label:'16 Hours', value: 16},
        {label:'17 Hours', value: 17},
        {label:'18 Hours', value: 18},
        {label:'19 Hours', value: 19},
        {label:'20 Hours', value: 20},
        {label:'21 Hours', value: 21},
        {label:'22 Hours', value: 22},
        {label:'23 Hours', value: 23},
        {label:'24 Hours', value: 24},
    ]
    const [currentEntity, setCurrentEntity] = useState({
        id: '',
        status: 0,
        startDate: '',
        endDate: '',
        sendNotification: 0,
        shiftTimingStart: '',
        shiftTimingEnd: '',
        shiftDays: '',
        roleId: null,
        title: '',
        description: '',
        createdDate: new Date().toISOString(),
        isCheckSun: false,
        isCheckMon: false,
        isCheckTue: false,
        isCheckWed: false,
        isCheckThu: false,
        isCheckFri: false,
        isCheckSat: false,
    });

    
  const columns = [
    {
      Header: 'Notification Title',
      accessor: 'title',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'ModuleType',
      accessor: 'roleId',
      Cell:({row:{original}})=>(
        <div>
            {getRoleNameById(original.roleId)}
        </div>
      )
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell:({row:{original}})=>(
        <div>
            {convertUtcDateTimeIntoLocalDate(original.startDate)}
        </div>
      )
    },
    {
      Header: 'End Date',
      accessor: 'endDate',      
      Cell:({row:{original}})=>(
        <div>
            {convertUtcDateTimeIntoLocalDate(original.endDate)}
        </div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell:({row:{original}})=>(
        <div>
            {getNotificationSchedularName(original.status)}
        </div>
      )
    },
    {
      Header: 'Actions',
      id: 'edit',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div>
          <button
            title='View'
            onClick={() => View(original)}
            className=' btn btn-sm'
          >
            <i className='fas fa-eye'></i>
          </button>
          <button
            style={{ marginLeft: '10px' }}
            title='Update'
            onClick={() => Edit(original)}
            className=' btn btn-sm'
          >
            <i className='fas fa-edit'></i>
          </button>
          
          <button
            style={{ marginLeft: '10px' }}
            title='Delete'
            onClick={() => Delete(original)}
            className=' btn btn-sm'
          >
            <i className='fas fa-trash-alt'></i>
          </button>
        </div>
      ),
    },
  ]

    function formRequestObject() {
        let requestData = {
            pageSize: currentPageSize,
            pageNo: currentPageNo,
            searchText: searchText,
        }
        return requestData
    }


    const validationSchema = Yup.object().shape({
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required'),
        sendNotification: Yup.string().required('Send Notification is required').nullable(true),
        shiftTimingStart: Yup.string().required('Shift Timing Start is required'),
        shiftTimingEnd: Yup.string().required('Shift Timing End is required'),
        // shiftDays: Yup.string().required('Shift Days is required'),
        roleId: Yup.string().required('Module Type is required').nullable(true),
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('Description is required')
    })

    const getData = (page) => {
        setCurrentPageNo(page)
        var request = { ...formRequestObject(), pageNo: page }
        fetchReportData(request)
    }

    function fetchReportData(request) {
        let finalRequest = request == null ? formRequestObject() : request
        props.getNotificationScheduler(finalRequest)
    }

    const searchChanged = (text) =>{
        setSearchText(text)
        setCurrentPageNo(0)
        var request = { ...formRequestObject(), searchText: text }
        fetchReportData(request)
    }
    
    
    const pageSizeChanged = (pageSize) => {
        setCurrentPageNo(0)
        setCurrentPageSize(pageSize)
        var request = { ...formRequestObject(), pageSize: pageSize }
        fetchReportData(request)
    }

    const convertUtcDateTimeIntoLocalDate = (utcDateTime) => {
        const utcDate = new Date(utcDateTime); 

        const localYear = utcDate.getFullYear();
        const localMonth = utcDate.getMonth() + 1; 
        const localDay = utcDate.getDate();
        return `${localMonth.toString().padStart(2, '0')}/${localDay.toString().padStart(2, '0')}/${localYear}`;
    }

    const getRoleNameById = (roleId) =>{
        let role = rolesList.find(obj => obj.value == roleId);
        return role?.label;
    }

    const AddAdvertisement = () => {
        setModalTitleText('Add')
        setIsView(false);
        setIsEdit(false);
        setShowModal(true);        

        setRoleIdValue(null);
        setSendNotification(null);
        
        setCurrentEntity(prevState => ({...prevState,id: ''}));
        setCurrentEntity(prevState => ({...prevState,status: 0}));
        setCurrentEntity(prevState => ({...prevState,startDate: ''}));
        setCurrentEntity(prevState => ({...prevState,endDate: ''}));
        setCurrentEntity(prevState => ({...prevState,sendNotification: 0}));
        setCurrentEntity(prevState => ({...prevState,shiftTimingStart: ''}));
        setCurrentEntity(prevState => ({...prevState,shiftTimingEnd: ''}));
        setCurrentEntity(prevState => ({...prevState,shiftDays: ''}));
        setCurrentEntity(prevState => ({...prevState,roleId: null}));
        setCurrentEntity(prevState => ({...prevState,title: ''}));
        setCurrentEntity(prevState => ({...prevState,roleId: null}));
        setCurrentEntity(prevState => ({...prevState,description: ''}));
        setCurrentEntity(prevState => ({...prevState,createdDate: new Date().toISOString()}));
        setCurrentEntity(prevState => ({...prevState,isCheckSun: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckSun: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckMon: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckSun: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckTue: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckWed: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckThu: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckFri: false}));
        setCurrentEntity(prevState => ({...prevState,isCheckSat: false}));
    }

    const Delete = async (obj) => {
        
        confirmAlert({
            closeOnEscape: true,
            closeOnClickOutside: true,
            customUI: ({ onClose }) => {
            return (
                <div className='custom-ui'>
                <Modal.Dialog>
                    <Modal.Header id='customized-dialog-title'>
                    <Modal.Title>Confirm to Delete</Modal.Title>
                    <button
                        type='button'
                        onClick={() => onClose()}
                        className='btn-close'
                        aria-label='No'
                    ></button>
                    </Modal.Header>
                    <Modal.Body dividers>
                    <p>Are you sure you want to delete this?</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button
                        onClick={ async () => {
                            await props.delNotificationScheduler(obj);
                            onClose();
                            let requestData = {
                                pageSize: currentPageSize,
                                pageNo: 0,
                                searchText: searchText,
                            }
                            await props.getNotificationScheduler(requestData);
                        }}
                        variant='outline-primary'
                    >
                        Yes, Delete it!
                    </Button>
                    <Button
                        variant='primary'
                        onClick={onClose}
                        className='btn btn-primary'
                    >
                        No
                    </Button>
                    </Modal.Footer>
                </Modal.Dialog>
                </div>
            )
            },
        })
    }

    const View = (obj) => {
        setModalTitleText('View');
        setIsView(true);
        setIsEdit(false);
        setShowModal(true);
        setCurrentEntity(prevState => ({...prevState,id: obj.id}));
        setCurrentEntity(prevState => ({...prevState,status: obj.status}));
        setCurrentEntity(prevState => ({...prevState,startDate: new Date(obj.startDate)}));
        setCurrentEntity(prevState => ({...prevState,endDate: new Date(obj.endDate)}));
        setCurrentEntity(prevState => ({...prevState,sendNotification: obj.sendNotification}));
        let selectedSendNotification = sendNotificationOptions.find(x=>x.value == obj.sendNotification);
        setSendNotification(selectedSendNotification);
        setCurrentEntity(prevState => ({...prevState,shiftTimingStart: new Date(obj.shiftTimingStart)}));
        setCurrentEntity(prevState => ({...prevState,shiftTimingEnd: new Date(obj.shiftTimingEnd)}));
        setCurrentEntity(prevState => ({...prevState,shiftDays: obj.shiftDays}));
        setCurrentEntity(prevState => ({...prevState,roleId: obj.roleId}));
        let selectRole = rolesList.find(x=>x.value == obj.roleId);
        setRoleIdValue(selectRole);
        setCurrentEntity(prevState => ({...prevState,description: obj.description}));
        setCurrentEntity(prevState => ({...prevState,createdDate: obj.createdDate}));
        setCurrentEntity(prevState => ({...prevState,title: obj.title}));

        let selectedDays = obj.shiftDays.split(' ');
        for(let i=0; i<selectedDays.length; i++)
        {
            var item = selectedDays[i];
            if(item == "Sun")
                setCurrentEntity(prevState => ({...prevState,isCheckSun: true}));
            if(item == "Mon")
                setCurrentEntity(prevState => ({...prevState,isCheckMon: true}));
            if(item == "Tue")
                setCurrentEntity(prevState => ({...prevState,isCheckTue: true}));
            if(item == "Wed")
                setCurrentEntity(prevState => ({...prevState,isCheckWed: true}));
            if(item == "Thu")
                setCurrentEntity(prevState => ({...prevState,isCheckThu: true}));
            if(item == "Fri")
                setCurrentEntity(prevState => ({...prevState,isCheckFri: true}));
            if(item == "Sat")
                setCurrentEntity(prevState => ({...prevState,isCheckSat: true}));
        }

    }


    const Edit = (obj) => {
        setModalTitleText('Edit')
        setIsView(false);
        setIsEdit(true);
        setShowModal(true);

        setCurrentEntity(prevState => ({...prevState,id: obj.id}));
        setCurrentEntity(prevState => ({...prevState,status: obj.status}));
        setCurrentEntity(prevState => ({...prevState,startDate: new Date(obj.startDate)}));
        setCurrentEntity(prevState => ({...prevState,endDate: new Date(obj.endDate)}));
        setCurrentEntity(prevState => ({...prevState,sendNotification: obj.sendNotification}));
        let selectedSendNotification = sendNotificationOptions.find(x=>x.value == obj.sendNotification);
        setSendNotification(selectedSendNotification);
        setCurrentEntity(prevState => ({...prevState,shiftTimingStart: new Date(obj.shiftTimingStart)}));
        setCurrentEntity(prevState => ({...prevState,shiftTimingEnd: new Date(obj.shiftTimingEnd)}));
        setCurrentEntity(prevState => ({...prevState,shiftDays: obj.shiftDays}));
        setCurrentEntity(prevState => ({...prevState,roleId: obj.roleId}));
        let selectRole = rolesList.find(x=>x.value == obj.roleId);
        setRoleIdValue(selectRole);
        setCurrentEntity(prevState => ({...prevState,description: obj.description}));
        setCurrentEntity(prevState => ({...prevState,createdDate: obj.createdDate}));
        setCurrentEntity(prevState => ({...prevState,title: obj.title}));

        let selectedDays = obj.shiftDays.split(' ');
        for(let i=0; i<selectedDays.length; i++)
        {
            var item = selectedDays[i];
            if(item == "Sun")
                setCurrentEntity(prevState => ({...prevState,isCheckSun: true}));
            if(item == "Mon")
                setCurrentEntity(prevState => ({...prevState,isCheckMon: true}));
            if(item == "Tue")
                setCurrentEntity(prevState => ({...prevState,isCheckTue: true}));
            if(item == "Wed")
                setCurrentEntity(prevState => ({...prevState,isCheckWed: true}));
            if(item == "Thu")
                setCurrentEntity(prevState => ({...prevState,isCheckThu: true}));
            if(item == "Fri")
                setCurrentEntity(prevState => ({...prevState,isCheckFri: true}));
            if(item == "Sat")
                setCurrentEntity(prevState => ({...prevState,isCheckSat: true}));
        }

    }

    const handleSubmit = async () => {
        let shiftDays = "";
        if(currentEntity.isCheckSun)
            shiftDays = shiftDays + "Sun "
        
        if(currentEntity.isCheckMon)
            shiftDays = shiftDays + "Mon "
        
        if(currentEntity.isCheckTue)
            shiftDays = shiftDays + "Tue "
        
        if(currentEntity.isCheckWed)
            shiftDays = shiftDays + "Wed "
        
        if(currentEntity.isCheckThu)
            shiftDays = shiftDays + "Thu "
        
        if(currentEntity.isCheckFri)
            shiftDays = shiftDays + "Fri "
        
        if(currentEntity.isCheckSat)
            shiftDays = shiftDays + "Sat "

        currentEntity.shiftDays = shiftDays;

        currentEntity.startDate = new Date(
            Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }).format(currentEntity.startDate)
            )
        )
        currentEntity.endDate = new Date(
            Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }).format(currentEntity.endDate)
            )
        )

        if(isEdit)
        {
            await props.updateNotificationScheduler(currentEntity);
        }
        else
        {
            await props.addNotificationScheduler(currentEntity);   
        }     

        setCurrentPageNo(0);
        fetchReportData();
        setShowModal(false);

    }
    const setStatusValue = () => {
        let today = new Date().toLocaleDateString();
        let startDateObj = currentEntity.startDate == '' ? null : currentEntity.startDate;
        let endDateObj = currentEntity.endDate == '' ? null : currentEntity.endDate;
        if (startDateObj != null && endDateObj != null) {
            let startDate = startDateObj.toLocaleDateString();
            let endDate = endDateObj.toLocaleDateString();

            if (new Date(startDate) > new Date(today)) {
                setCurrentEntity(prevEntity => ({
                    ...prevEntity,
                    status: 1
                }));
            }
            else if (new Date(startDate) <= new Date(today) && new Date(endDate) >= new Date(today)) {
                setCurrentEntity(prevEntity => ({
                    ...prevEntity,
                    status: 2
                }));
            }
            else if (new Date(endDate) < new Date(today)) {
                setCurrentEntity(prevEntity => ({
                    ...prevEntity,
                    status: 3
                }));
            } 
        }
    }

    useEffect(()=>{        
        props.getUserRoles();
        setCurrentPageNo(0);
        let requestData = {
          pageSize: currentPageSize,
          pageNo: 0,
          searchText: searchText,
        };
        props.getNotificationScheduler(requestData);
    }, [])

    

    useEffect(() => {
        let roles = props.Roles.map(function (item) {
            return {
              value: item.id,
              label: item.name,
            }
          })
      
          setRolesList([{ label: 'All', value: 'All' }, ...roles])
      }, [props.Roles])

    return (
        <div>
            <VzPageContainer title='Auto Notifications'>
                <Row>
                    <Col md={{ span: 3 }} style={{ float: 'left' }}>
                        <h4>
                            <b>Auto Notifications</b>
                        </h4>
                    </Col>  
                </Row>

                <Row>
                    <Col md={9} style={{ float: 'right' }}>
                    </Col>
                    <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={AddAdvertisement}
                            className='m-3'
                            variant='outline-primary'
                        >
                            <i className='fa fa-plus' /> Add New
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12}>
                        <ServerSideDataTable
                        data={props.NotificationScheduler.data ?? []}
                        columns={columns}
                        getTrProps={(event, data) => null}
                        goToPreviousPage={(val) => getData(val)}
                        goToNextPage={(val) => getData(val)}
                        totalRecords={props.NotificationScheduler.totalRecords}
                        pageSizeChange={(size) => pageSizeChanged(size)}
                        myPageSize={currentPageSize}
                        myIndex={currentIndex}
                        pageNo={currentPageNo}
                        searchChanged={searchChanged}
                        />
                    </Col>
                </Row>

                <Row>
                    <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        backdrop='static'
                        keyboard={false}
                        size='xl'>
                        <Formik
                            enableReinitialize
                            initialValues={currentEntity}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(formik) => (
                                <Form autoComplete='off'>
                                    <Modal.Header closeButton>
                                        <Modal.Title id='contained-modal-title-vcenter'>
                                            {modalTitleText}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row>
                                                <Col xs={12} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            readOnly={true}
                                                            control='input'
                                                            type='text'
                                                            label='Create Date'
                                                            name='createdDate'
                                                            handleOnChange={(e) => (currentEntity.createdDate = e)}
                                                            value={convertUtcDateTimeIntoLocalDate(currentEntity.createdDate)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            readOnly={true}
                                                            control='input'
                                                            type='text'
                                                            label='Status'
                                                            name='status'
                                                            handleOnChange={(e) => (currentEntity.status = e)}
                                                            value={getNotificationSchedularName(currentEntity.status)}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='date'
                                                            type='text'
                                                            label='Start Date'
                                                            name='startDate'
                                                            handleOnChange={(e) => {
                                                                (currentEntity.startDate = e)
                                                                setStatusValue()
                                                            }}
                                                            value={currentEntity.startDate}
                                                            minDate={isEdit ? currentEntity.startDate : new Date()}
                                                            isDisabled={isView}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='date'
                                                            type='text'
                                                            label='End Date'
                                                            name='endDate'
                                                            value={currentEntity.endDate}
                                                            handleOnChange={(e) => {
                                                                (currentEntity.endDate = e)
                                                                setStatusValue()
                                                            }}
                                                            minDate={
                                                                currentEntity.startDate
                                                                    ? currentEntity.startDate
                                                                    : new Date()
                                                            }
                                                            isDisabled={isView}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                               <Col xs={12} md={4}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='react-select'
                                                            type='text'
                                                            label='Send Notification'
                                                            name='sendNotification'
                                                            value={sendNotification}
                                                            options={sendNotificationOptions}
                                                            handleOnChange={(option) => {
                                                                setSendNotification(option)
                                                                currentEntity.sendNotification = option.value
                                                            }}
                                                            isDisabled={isView}
                                                        />
                                                    </div>
                                                </Col>                                                
                                                <Col xs={12} md={4}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='date'
                                                            type='text'
                                                            label='Shift Timing Start'
                                                            name='shiftTimingStart'
                                                            handleOnChange={(e) => {
                                                                (currentEntity.shiftTimingStart = e)
                                                            }}
                                                            value={currentEntity.shiftTimingStart}
                                                            minDate={isEdit ? currentEntity.shiftTimingStart : new Date()}
                                                            isDisabled={isView}
                                                            showTimeSelect
                                                            showTimeSelectOnly            
                                                            timeIntervals={30} 
                                                            dateFormat="h:mm aa" 
                                                            placeholderText="Select time"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='date'
                                                            type='text'
                                                            label='Shift Timing End'
                                                            name='shiftTimingEnd'
                                                            handleOnChange={(e) => {
                                                                (currentEntity.shiftTimingEnd = e)
                                                            }}
                                                            value={currentEntity.shiftTimingEnd}
                                                            minDate={isEdit ? currentEntity.shiftTimingEnd : new Date()}
                                                            isDisabled={isView}
                                                            showTimeSelect
                                                            showTimeSelectOnly            
                                                            timeIntervals={30} 
                                                            dateFormat="h:mm aa" 
                                                            placeholderText="Select time"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={12}>
                                                    <Row>        
                                                    <label className='my-2'>
                                                        Shift Days
                                                    </label>
                                                    </Row>
                                                    <div>
                                                        <div className='form-check form-check-inline'> 
                                                            <input
                                                                type='checkbox'
                                                                checked={currentEntity.isCheckSun}
                                                                onChange={(event) => {
                                                                    const updatedCurrentEntity = { ...currentEntity, isCheckSun: event.target.checked }
                                                                    setCurrentEntity(updatedCurrentEntity)
                                                                }}
                                                                className='mr-2 form-check-input'
                                                                value={currentEntity.isCheckSun}
                                                                disabled={isView}
                                                            />   
                                                            <label className="form-check-label">
                                                                Sun
                                                            </label> 
                                                        </div>
                                                        
                                                        <div className='form-check form-check-inline'> 
                                                            <input
                                                                type='checkbox'
                                                                checked={currentEntity.isCheckMon}
                                                                onChange={(event) => {
                                                                    const updatedCurrentEntity = { ...currentEntity, isCheckMon: event.target.checked }
                                                                    setCurrentEntity(updatedCurrentEntity)
                                                                }}
                                                                className='mr-2 form-check-input'
                                                                value={currentEntity.isCheckMon}
                                                                disabled={isView}
                                                            />   
                                                            <label className="form-check-label">
                                                                Mon
                                                            </label> 
                                                        </div>

                                                        <div className='form-check form-check-inline'> 
                                                            <input
                                                                type='checkbox'
                                                                checked={currentEntity.isCheckTue}
                                                                onChange={(event) => {
                                                                    const updatedCurrentEntity = { ...currentEntity, isCheckTue: event.target.checked }
                                                                    setCurrentEntity(updatedCurrentEntity)
                                                                }}
                                                                className='mr-2 form-check-input'
                                                                value={currentEntity.isCheckTue}
                                                                disabled={isView}
                                                            />   
                                                            <label className="form-check-label">
                                                                Tue
                                                            </label> 
                                                        </div>

                                                        <div className='form-check form-check-inline'> 
                                                            <input
                                                                type='checkbox'
                                                                checked={currentEntity.isCheckWed}
                                                                onChange={(event) => {
                                                                    const updatedCurrentEntity = { ...currentEntity, isCheckWed: event.target.checked }
                                                                    setCurrentEntity(updatedCurrentEntity)
                                                                }}
                                                                className='mr-2 form-check-input'
                                                                value={currentEntity.isCheckWed}
                                                                disabled={isView}
                                                            />   
                                                            <label className="form-check-label">
                                                                Wed
                                                            </label> 
                                                        </div>

                                                        <div className='form-check form-check-inline'> 
                                                            <input
                                                                type='checkbox'
                                                                checked={currentEntity.isCheckThu}
                                                                onChange={(event) => {
                                                                    const updatedCurrentEntity = { ...currentEntity, isCheckThu: event.target.checked }
                                                                    setCurrentEntity(updatedCurrentEntity)
                                                                }}
                                                                className='mr-2 form-check-input'
                                                                value={currentEntity.isCheckThu}
                                                                disabled={isView}
                                                            />   
                                                            <label className="form-check-label">
                                                                Thu
                                                            </label> 
                                                        </div>

                                                        <div className='form-check form-check-inline'> 
                                                            <input
                                                                type='checkbox'
                                                                checked={currentEntity.isCheckFri}
                                                                onChange={(event) => {
                                                                    const updatedCurrentEntity = { ...currentEntity, isCheckFri: event.target.checked }
                                                                    setCurrentEntity(updatedCurrentEntity)
                                                                }}
                                                                className='mr-2 form-check-input'
                                                                value={currentEntity.isCheckFri}
                                                                disabled={isView}
                                                            />   
                                                            <label className="form-check-label">
                                                                Fri
                                                            </label> 
                                                        </div>

                                                        <div className='form-check form-check-inline'> 
                                                            <input
                                                                type='checkbox'
                                                                checked={currentEntity.isCheckSat}
                                                                onChange={(event) => {
                                                                    const updatedCurrentEntity = { ...currentEntity, isCheckSat: event.target.checked }
                                                                    setCurrentEntity(updatedCurrentEntity)
                                                                }}
                                                                className='mr-2 form-check-input'
                                                                value={currentEntity.isCheckSat}
                                                                disabled={isView}
                                                            />   
                                                            <label className="form-check-label">
                                                                Sat
                                                            </label> 
                                                        </div>                                                       
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <div className='form-group'>
                                                    <FormikControl
                                                        formik={formik}
                                                        control='react-select'
                                                        readOnly={isView}
                                                        type='roleId'
                                                        label='Module Type'
                                                        name='roleId'
                                                        value={roleIdValue}
                                                        options={rolesList}
                                                        handleOnChange={(option) => {
                                                            setRoleIdValue(option);
                                                            currentEntity.roleId = option.value
                                                        }}
                                                    />
                                                    </div>
                                                </Col>
                                                
                                                <Col xs={12} md={6}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='input'
                                                            type='text'
                                                            label='Title'
                                                            name='title'
                                                            handleOnChange={(e) => (currentEntity.title = e)}
                                                            value={currentEntity.title}
                                                            readOnly={isView}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                                formik={formik}
                                                                control='input'
                                                                type='text'
                                                                label='Description'
                                                                name='description'
                                                                handleOnChange={(e) => (currentEntity.description = e)}
                                                                value={currentEntity.description}
                                                                readOnly={isView}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    {isView ? null : (
                                        <input
                                            type='submit'
                                            className='btn btn-primary'
                                            value='Save'
                                        />
                                    )}
                                    
                                    <Button
                                            className='btn btn-default'
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Row>
            </VzPageContainer>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        NotificationScheduler: state.NotificationScheduler ? state.NotificationScheduler : [],
        Roles: state.ddlList?.userRoles ? state.ddlList?.userRoles : []
    }
}

export default connect(mapStateToProps, {
    getUserRoles,
    addNotificationScheduler,
    updateNotificationScheduler,
    getNotificationScheduler,
    delNotificationScheduler
  })(NotificationsScheduler)