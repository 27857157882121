import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { GET_SHOPS_DETAIL_REPORT } from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as XLSX from 'xlsx'
import fileSaver from 'file-saver'

export const getShopsDetailReport = (postedData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .post(`api/Reports/GetShopDetailReport`, postedData)
        .then(({ data }) => {
            dispatch({
                type: GET_SHOPS_DETAIL_REPORT,
                payload: data.response,
            })
            dispatch(hideLoading())
        })
        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (
                    error.response.status === 400 &&
                    error.response.data.errors
                ) {
                    let errorMessage = ''
                    var errors = error.response.data.errors
                    var keys = Object.keys(errors)
                    for (let j = 0; j < keys.length; j++) {
                        errorMessage += errors[keys[j]] + ' , \n'
                    }
                    toast.error(errorMessage)
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            } else toast.error('Error while processing the request')
        })
}

export const exportReport = (requestData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .post(`api/Reports/GetShopDetailReport`, requestData)
        .then(({ data }) => {
            const outputFilename = `Shops_Detail_Report${Date.now()}`
            const fileType =
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            const exportToCSV = (csvData, jobNumber, fileName) => {
                const ws = XLSX.utils.json_to_sheet(csvData)
                if (ws) {
                    var range = XLSX.utils.decode_range(ws['!ref'])
                    for (var C = range.s.c; C <= range.e.c; ++C) {
                        var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
                        if (!ws[address]) continue
                        ws[address].v =
                            ws[address].v == 'distCenter'
                                ? 'Dist Center'
                                : ws[address].v == 'vizId'
                                    ? 'Viz ID'
                                    : ws[address].v == 'shopCode'
                                        ? 'Shop Code'
                                        : ws[address].v == 'shopName'
                                            ? 'Shop Name'
                                            : ws[address].v == 'ownerName'
                                                ? 'Owner Name'
                                                : ws[address].v == 'cnic'
                                                    ? 'CNIC'
                                                    : ws[address].v == 'contact'
                                                        ? 'Contact'
                                                        : ws[address].v == 'sectorId'
                                                            ? 'Sector ID'
                                                            : ws[address].v == 'address'
                                                                ? 'Address'
                                                                : ws[address].v == 'mapAddress'
                                                                    ? 'Map Address'
                                                                    : ws[address].v == 'lat_Long'
                                                                        ? 'Lat Long'
                                                                        : ws[address].v == 'sublocality'
                                                                            ? 'Sub-locality'
                                                                            : ws[address].v == 'locality'
                                                                                ? 'Locality'
                                                                                : ws[address].v == 'city'
                                                                                    ? 'City'
                                                                                    : ws[address].v == 'shopCategory'
                                                                                        ? 'Shop Category'
                                                                                        : ws[address].v == 'dateOfInduction'
                                                                                            ? 'Date Of Induction'
                                                                                            : ws[address].v == 'dateOfRegistration'
                                                                                                ? 'Date Of Registration'
                                                                                                : ws[address].v == 'registrationStatus'
                                                                                                    ? 'Registration Status'
                                                                                                    // : ws[address].v == 'applicationStatus'
                                                                                                    //     ? 'Application Status'
                                                                                                    : ws[address].v
                    }
                }
                // var sheetName = `Job_Number_${jobNumber}`
                const wb = {
                    Sheets: { data: ws },
                    SheetNames: [`data`],
                }
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
                const fileData = new Blob([excelBuffer], { type: fileType })
                fileSaver.saveAs(fileData, fileName + fileExtension)
            }
            exportToCSV(data.response?.data, '', outputFilename)
            dispatch(hideLoading())
        })

        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            }
        })
}
