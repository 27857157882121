import axios from '../../apis/Api'
import history, { logout } from '../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const getDropdownList =
  (requestUrl, dispatchType) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .get(requestUrl)
      .then(({ data }) => {
        if (dispatchType == 'GET_DELIVERYMAN_TILL_DDL') {
        }
        dispatch({
          type: dispatchType,
          payload: data.response ? data.response : data,
        })
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }
