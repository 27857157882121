import AsyncSelect from 'react-select/async'

function SelectServerSide(props) {
  const {
    name,
    label,
    options,
    value,
    loadOptions,
    handleValueChange,
    disabled,
    ...rest
  } = props

  return (
    <>
      <label htmlFor={name} style={{ marginBottom: 0 }}>
        {label}
      </label>
      <AsyncSelect
        value={value}
        // inputValue={value}
        // onInputChange={handleValueChange}

        onChange={handleValueChange}
        options={options}
        isClearable={true}
        loadOptions={loadOptions}
        placeholder={`Enter ${label}`}
      />
    </>
  )
}

export default SelectServerSide
