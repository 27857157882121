import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import ReactSelect from 'react-select'
import { Input } from 'reactstrap'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { useState, useEffect } from 'react'
import { GET_AREAS, GET_BUSINESS_ENTITY_TYPE_DDL } from '../../../Types'
import { toast } from 'react-toastify'
import {
  addBusinessEntityType,
  deleteBusinessEntityType,
} from './BusinessEntityTypeActions'
import { confirmAlert } from 'react-confirm-alert'

const BusinessEntityType = (props) => {
  const [newEntity, setNewEntity] = useState('')

  const entityTypeColumns = [
    {
      Header: 'Type Name',
      accessor: 'value',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button
            onClick={() => Delete(row.value)}
            className='btn btn-danger'
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fa fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  const addBusinessEntityType = async () => {
    if (newEntity == '' || newEntity == null) {
      toast.error('Enter Business Entity Type Name')
      return
    }

    let checkIsExist = props.businessEntityTypes.filter(
      (item) =>
        item.value.toUpperCase() === newEntity.toUpperCase() ||
        item.value.replace(/ /g, '').trim().toUpperCase() ===
          newEntity.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist.length > 0) {
      toast.warn('Business entity with the same name already exist')
      return
    }

    let data = {
      TypeName: newEntity,
    }
    await props.addBusinessEntityType(data)
    getEntityTypes()

    setNewEntity('')
  }

  const getEntityTypes = () => {
    props.getDropdownList(
      `api/BusinessEntityType/`,
      GET_BUSINESS_ENTITY_TYPE_DDL
    )
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const DeleteRecord = async (id) => {
    await props.deleteBusinessEntityType(id)
  }

  useEffect(() => {
    getEntityTypes()
  }, [])
  return (
    <div>
      <VzPageContainer title='Business Entity Type'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Business Entity Type</b>
            </h4>
          </Col>
        </Row>
        <Row style={{ marginTop: '40px' }}>
          <Col
            xs={12}
            md={{ span: 4, offset: 1 }}
            style={{ marginTop: '80px' }}
          >
            <Row>
              <Col md={12}>
                <Col md={12}>
                  <div className='form-group'>
                    <Input
                      value={newEntity}
                      onChange={(name) => setNewEntity(name.target.value)}
                      type='text'
                      placeholder='Enter Entity Name'
                    />
                  </div>
                </Col>
                <Col
                  md={12}
                  style={{ marginTop: '10px', display: 'inline-block' }}
                >
                  <div className='form-group'>
                    <Button
                      className='form-btn'
                      variant='primary'
                      onClick={addBusinessEntityType}
                      style={{ fontSize: 13 }}
                    >
                      Add Entity
                    </Button>
                  </div>
                </Col>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={{ span: 6, offset: 1 }}>
            <CustomDataTable
              data={props.businessEntityTypes ?? []}
              columns={entityTypeColumns}
              getTrProps={null}
            />
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    businessEntityTypes: state.ddlList.businessEntityTypesList,
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  addBusinessEntityType,
  deleteBusinessEntityType,
})(BusinessEntityType)
