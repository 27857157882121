import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import storage from '../../../../apis/Storage'
import {
  GET_ORDER_SHOP_DETAILS,
  ADD_ORDER_SHOP_DETAILS,
  GET_SINGLE_ORDER_SHOP_DETAILS,
} from '../../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const getOrderShopDetails = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/ShopOrder/OrderShopDetails`, postedData)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_ORDER_SHOP_DETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response)
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
    })
}

export const getSingleOrderShopDetails = (id) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/ShopOrder/${id}`)
    .then(({ data }) => {
      dispatch({
        type: GET_SINGLE_ORDER_SHOP_DETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const addOrderShopDetails = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/Authenticate/CreateUser', formValues)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: ADD_ORDER_SHOP_DETAILS,
        payload: data.response,
      })
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
