import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { GET_CONVERSATION, RESET_CONVERSATION } from '../../../Types'

export const getChatMessages = (req) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/VLCShopKeeperTicket/GetDistributorChat`, req)
    .then(({ data }) => {
      if (data) {
        const msgs = [...data?.messages]
        const sortedList = msgs.sort(
          (a, b) => new Date(a.messageDate) - new Date(b.messageDate)
        )
        let res = {
          messages: sortedList,
          totalRecords: data?.totalRecords,
          chatActive: data?.chatActive,
        }
        dispatch({
          type: GET_CONVERSATION,
          payload: res,
        })
        dispatch(hideLoading())
      }
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const sendMessage = (req) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/VLCShopKeeperTicket/SendDistributorMessage`, req)
    .then(({ data }) => {
      if (data) {
        dispatch(hideLoading())
      }
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const resetChat = () => async (dispatch) => {
  dispatch({
    type: RESET_CONVERSATION,
    payload: {},
  })
}

// export const sendMessage = (socket, message) => async (dispatch) => {
//   await socket.send('SendMessage', message)
// }

export const markMessagesRead = (socket, msgList) => async (dispatch) => {
  await socket.send('MarkMessagesRead', msgList)
}
