import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { VzPageContainer } from '../../../layouts/VzPageContainer'

import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { Formik, Form } from 'formik'
import FormikControl from '../../../layouts/Input/FormikControl'

import storage from '../../../apis/Storage'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import axios from '../../../apis/Api'
import {
  getDailyCollections,
  exportReport,
} from './DailyCollectionsReportActions'
import { isPermittedPage } from '../../../apis/Utility'

const DailyCollectionsReport = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionID, setFilterDistributionID] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(new Date())
  const [filterToDate, setFilterToDate] = useState(new Date())
  const [filterMfi, setFilterMfi] = useState(null)
  const [filterTillNo, setFilterTillNo] = useState(null)
  const [filterDeliveryUser, setFilterDeliveryUser] = useState(null)
  const [filterOrderStatus, setFilterOrderStatus] = useState([])

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])

  const [qrCodeList, setQrCodeList] = useState([])
  const [deliveryUserList, setDeliveryUserList] = useState([])

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [filterValuesModal, setFilterValuesModal] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    mfi: '',
    tillNo: '',
    deliveryUser: '',
  })

  const orderStatusOptions = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Paid',
      value: 'Paid',
    },
    {
      label: 'Pending',
      value: 'Pending',
    },
    {
      label: 'Failed',
      value: 'Failed',
    },
  ]

  const mfiOptions = [
    {
      label: 'Easypaisa',
      value: 'Easypaisa',
    },
    {
      label: 'JazzCash',
      value: 'JazzCash',
    },
  ]

  const columns = [
    {
      Header: 'Distribution Center',
      accessor: 'distCenter',
    },
    {
      Header: 'User Name',
      accessor: 'username',
    },
    {
      Header: 'Delivery Man',
      accessor: 'deliveryMan',
    },
    {
      Header: 'VL Till No.',
      accessor: 'tillNo',
    },
    {
      Header: 'MFI',
      accessor: 'mfi',
    },
    {
      Header: 'Invoice Number',
      accessor: 'invoiceNo',
    },
    {
      Header: 'Order Status',
      accessor: 'orderStatus',
    },
    {
      Header: 'Order Amount',
      accessor: 'orderAmount',
    },
    {
      Header: 'Date',
      accessor: 'date',
      // Cell: (row) => (
      //   <div>
      //     <Moment format='DD/MM/YYYY'>{row.value}</Moment>
      //   </div>
      // ),
    },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionID(null)
    setFilterFromDate(new Date())
    setFilterToDate(new Date())
    setFilterMfi(null)
    setFilterTillNo(null)
    setFilterDeliveryUser(null)
    setFilterOrderStatus(null)
    setAddJobDistCenterOptions([])
    setDeliveryUserList([])
    setQrCodeList([])
    setFilterValuesModal({
      fromDate: new Date(),
      toDate: new Date(),
    })
  }

  const getCenterDeliveryUsers = (id) => {
    axios
      .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
      .then(({ data }) => {
        if (data?.response) {
          setDeliveryUserList(
            data.response.map(function (item) {
              return {
                value: item.id,
                label: item.fullname,
              }
            })
          )

          setQrCodeList(
            data.response.map(function (item) {
              return {
                value: item.qrCode,
                label: item.qrCode,
              }
            })
          )
        }
      })
      .catch(function (error) { })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      distributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      deliveryMan: filterDeliveryUser == null ? null : filterDeliveryUser.value,
      mfi: filterMfi == null ? null : filterMfi.value,
      tillNo: filterTillNo == null ? null : filterTillNo.value,
      orderStatus:
        filterOrderStatus == null
          ? null
          : filterOrderStatus.value == 'All'
            ? null
            : filterOrderStatus.value,
      fromDate:
        filterFromDate == '' || filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == '' || filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDailyCollections(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      distributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      deliveryMan: filterDeliveryUser == null ? null : filterDeliveryUser.value,
      mfi: filterMfi == null ? null : filterMfi.value,
      tillNo: filterTillNo == null ? null : filterTillNo.value,
      orderStatus:
        filterOrderStatus == null
          ? null
          : filterOrderStatus.value == 'All'
            ? null
            : filterOrderStatus.value,
      fromDate:
        filterFromDate == '' || filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == '' || filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDailyCollections(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    let requestData = {
      distributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == '' || filterDistributionID == null
          ? null
          : filterDistributionID.value,

      deliveryMan: filterDeliveryUser == null ? null : filterDeliveryUser.value,
      mfi: filterMfi == null ? null : filterMfi.value,
      tillNo: filterTillNo == null ? null : filterTillNo.value,
      orderStatus:
        filterOrderStatus == null
          ? null
          : filterOrderStatus.value == 'All'
            ? null
            : filterOrderStatus.value,
      fromDate:
        filterFromDate == '' || filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == '' || filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDailyCollections(requestData)
    // setFilterFromDate(event.fromDate == '' ? null : event.fromDate)
    // setFilterToDate(event.toDate == '' ? null : event.toDate)
    setShow(false)
  }

  const Download = () => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      distributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      deliveryMan: filterDeliveryUser == null ? null : filterDeliveryUser.value,
      mfi: filterMfi == null ? null : filterMfi.value,
      tillNo: filterTillNo == null ? null : filterTillNo.value,
      orderStatus:
        filterOrderStatus == null
          ? null
          : filterOrderStatus.value == 'All'
            ? null
            : filterOrderStatus.value,
      fromDate:
        filterFromDate == '' || filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == '' || filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.exportReport(requestData)
  }

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddJobDistCenterOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistCenterOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])

  useEffect(() => {
    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])

  useEffect(() => {
    if (filterDistributionID) {
      getCenterDeliveryUsers(filterDistributionID.value)
    }
  }, [filterDistributionID])

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])
  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
      distributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      deliveryMan: filterDeliveryUser == null ? null : filterDeliveryUser.value,
      mfi: filterMfi == null ? null : filterMfi.value,
      tillNo: filterTillNo == null ? null : filterTillNo.value,
      orderStatus:
        filterOrderStatus == null
          ? null
          : filterOrderStatus.value == 'All'
            ? null
            : filterOrderStatus.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getDailyCollections(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.dailyCollections && props.dailyCollections.length > 0) {
      setTotalRecords(props.dailyCollections[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.dailyCollections])

  return (
    <div>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <VzPageContainer title='Daily Collections Report'>
          <Row>
            <Col style={{ float: 'left' }}>
              <h4>
                <b>Daily Collections Report</b>
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={10} style={{ float: 'right' }}>
              <div ref={ref}>
                <Button
                  onClick={openFilterModal}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-filter' />
                  Filters
                </Button>

                <Overlay
                  show={show}
                  target={target}
                  placement='bottom'
                  container={ref}
                  containerPadding={20}
                  width='430px'
                  style={{ width: '430px' }}
                >
                  <Popover
                    id='popover-contained'
                    style={{ maxWidth: '360px', width: '360px' }}
                  >
                    <Popover.Header as='h3'>Filters</Popover.Header>
                    <Popover.Body>
                      <Formik
                        enableReinitialize
                        initialValues={{}}
                        validator={() => ({})}
                        onSubmit={(e) => LoadDataByfilter(e)}
                      >
                        {(formik) => (
                          <Form autoComplete='off'>
                            <Row>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor'
                                  name='distributorName'
                                  options={addJobDistributorsOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    setFilterDistributorName({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    setFilterDistributionID(null)
                                    setFilterTillNo(null)
                                  }}
                                  value={filterDistributorName}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Center'
                                  name='distributionID'
                                  options={addJobDistCenterOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    setFilterDistributionID({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionID}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Deliveryman Name'
                                  name='deliveryUser'
                                  options={deliveryUserList}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.deliveryUser = e.value
                                    setFilterDeliveryUser({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDeliveryUser}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Select Mfi'
                                  name='mfi'
                                  options={mfiOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.mfi = e.value
                                    setFilterMfi({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterMfi}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Till No'
                                  name='tillNo'
                                  options={qrCodeList}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.tillNo = e.value
                                    setFilterTillNo({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterTillNo}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Order Status'
                                  name='orderStatus'
                                  options={orderStatusOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.mfi = e.value
                                    setFilterOrderStatus({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterOrderStatus}
                                />
                              </Col>
                            </Row>
                            <div className='row' style={{ marginTop: 20 }}>
                              <div className='form-group'>
                                <Link
                                  onClick={handleResetFilters.bind(
                                    null,
                                    formik.resetForm
                                  )}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'left',
                                  }}
                                >
                                  Reset
                                </Link>
                                <Link
                                  onClick={() => {
                                    setShow(false)
                                    setTarget(null)
                                  }}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Cancel
                                </Link>
                                <div className='float-right'>
                                  <Button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{
                                      marginRight: '10px',
                                      float: 'right',
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </Col>
            {isPermittedPage('Export', props.actions, props.userRole) &&
              <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={Download}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-download me-1' />
                  Export
                </Button>
              </Col>
            }
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <ServerSideDataTable
                data={props.dailyCollections ?? []}
                columns={columns}
                getTrProps={(event, data) => null}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={totalRecords}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                myIndex={currentIndex}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
              {/* <CustomDataTable
              data={props.dailyCollections ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
            /> */}
            </Col>
          </Row>
        </VzPageContainer>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],

    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    dailyCollections: state.DailyCollectionsReport,
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  getDailyCollections,
  exportReport,
})(DailyCollectionsReport)
