import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { GET_DISTRIBUTOR_COIN_PAYMENT_REPORT } from '../../../Types'
import fileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { getCustomCloumns } from '../../../utilities/FUNCTIONS'
import storage from '../../../apis/Storage'

export const getDistributorCoinsPaymentReport =
  (request) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .post('api/VLPLedger/GetDistributorCoinsPaymentReport', request)
      .then(({ data }) => {
        if (data)
          dispatch({
            type: GET_DISTRIBUTOR_COIN_PAYMENT_REPORT,
            payload: data?.response != null ? data?.response : [],
          })
        dispatch(hideLoading())
        // toast.success('Scheme created successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }

const invisibleColumns = ['deliveryManName', 'deliverManTillNo'];
const userRights = JSON.parse(storage.get('_userRights'))
if (userRights != null && userRights?.userType == 'DistributorUser') {
  while (invisibleColumns.length > 0) {
    invisibleColumns.pop();
  }
}

export const exportReport = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/VLPLedger/ExportDistributorCoinsPaymentReport`, requestData)
    .then(({ data }) => {
      const outputFilename = `Distributor_Coin_Payment_Report_${Date.now()}`
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const exportToCSV = (csvData, jobNumber, fileName) => {
        csvData = getCustomCloumns(csvData, invisibleColumns);
        const ws = XLSX.utils.json_to_sheet(csvData)
        if (ws) {
          var range = XLSX.utils.decode_range(ws['!ref'])
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
            if (!ws[address]) continue
            ws[address].v =
              ws[address].v == 'distributioId'
                ? 'Distribution Id'
                : ws[address].v == 'totalShopTransacted'
                  ? 'Total Shops Transacted'
                  : ws[address].v == 'totalTransactions'
                    ? 'Total Transactions'
                    : ws[address].v == 'totalVolumesTransacted'
                      ? 'Total Volumes Transacted'
                      : ws[address].v == 'totalActiveDays'
                        ? 'Total Active Days'
                        : ws[address].v == 'lastActiveDateAndTime'
                          ? 'Last Active Date & Time'
                          : ws[address].v
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        }
        const excelBuffer = XLSX.write(wb, {
          bookType: 'xlsx',
          type: 'array',
        })
        const fileData = new Blob([excelBuffer], { type: fileType })
        fileSaver.saveAs(fileData, fileName + fileExtension)
      }
      exportToCSV(data.response?.report, '', outputFilename)
      dispatch(hideLoading())
    })

    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
