import { useEffect, useState, useRef } from 'react'
import { Col, Row, Overlay, Button, Popover } from 'react-bootstrap'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { connect } from 'react-redux'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import { Link, Redirect } from 'react-router-dom'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  GET_DELIVERYMAN_TILL_DDL,
} from '../../../Types'
import { getDataListing, downloadJob } from './DLMCollectionReportActions'
import Moment from 'react-moment'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'
import { formatDate } from '../../../utilities/FUNCTIONS'
import axios from '../../../apis/Api'
const DLMCollectionReport = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))

  const [target, setTarget] = useState(null)
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  // pagination states
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [
    addOrderDistributionCentersOptions,
    setAddOrderDistributionCentersOptions
  ] = useState(null)
  const [distCenters_select, setDistCenters_select] = useState(null)
  const [deliverymanTill_select, setDeliverymanTill_select] = useState(null)
  const [filter, setfilter] = useState({
    dist: '',
    distCenter: '',
    fromDate: new Date(),
    toDate: new Date(),
    deliveryUser: '',
    pageNo: 0,
    searchText: '',
    isExport: false,
  })

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Dist Center',
      accessor: 'distCenter',
    },
    {
      Header: 'VL Till ID',
      accessor: 'vlTillId',
    },
    {
      Header: 'Invoice No.',
      accessor: 'invoiceNo',
    },
    {
      Header: 'Invoice Value',
      accessor: 'invoiceValue',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Total Payment',
      accessor: 'totalPayment',
    },
    {
      Header: 'Digital Payment(EP + Jazz)',
      accessor: 'walletPayment',
    },
    {
      Header: 'Lending Payment',
      accessor: 'lendingPayment',
    },
    {
      Header: 'Coins Availed',
      accessor: 'coinsAvailed',
    },
    {
      Header: 'Cash Recorded',
      accessor: 'cashRecorded',
    },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const getDistributorTills = (distributorId, distCenterIds) => {
    axios
      .get(`api/Distributor/DistributorInvoiceTills/${distributorId}/${distCenterIds}`)
      .then(({ data }) => {
        setDeliverymanTill_select(data?.response)
      })
      .catch(function (error) { })
  }

  useEffect(() => {
    fetchReportData()
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      getDistributorTills(userRights?.distributorId, userRights?.distCenterIds)
    }
  }, [])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      let userDist = userRights?.distributorId
      if (distributorList?.length > 0) {
        setfilter({
          ...filter,
          dist: {
            label: distributorList[0]?.label,
            value: distributorList[0]?.value,
          },
        })
      }
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      var assignedDistcenterIds = userRights.distCenterIds.split(',');
      let filteredItems = props.selectedAddJobDistCentersList.filter(item => assignedDistcenterIds.includes(item.value));
      setDistCenters_select(
        filteredItems.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setDistCenters_select(
        props.selectedAddJobDistCentersList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
    //setDistCenters_select(props.selectedAddJobDistCentersList)
  }, [props.selectedAddJobDistCentersList])

  useEffect(() => {

    var qrCodes = props.deliverymanTill_ddl?.map(function (item) {
      return {
        value: item.qrCode,
        label: item.qrCode,
      }
    })
    setDeliverymanTill_select(qrCodes)
  }, [props.deliverymanTill_ddl])

  useEffect(() => {
    // if (filter.distCenter && userRights?.userType == 'DistributorUser') {
    //   props.getDropdownList(
    //     `api/DistCenter/GetCenterDeliveryUserDDL/${filter.distCenter.value}`,
    //     GET_DELIVERYMAN_TILL_DDL
    //   )
    // }
    if (filter.distCenter) {
      props.getDropdownList(
        `api/DistCenter/GetCenterDeliveryUserDDL/${filter.distCenter.value}`,
        GET_DELIVERYMAN_TILL_DDL
      )
    }
  }, [filter.distCenter])

  useEffect(() => {
    setAddOrderDistributionCentersOptions(null)
    if (filter.dist) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filter.dist.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filter.dist])

  const handleResetFilters = (resetForm) => {
    resetForm()
    if (userRights == null) {
      setDistCenters_select([])
      setDeliverymanTill_select([])
    }
    setfilter({
      dist:
        userRights != null && userRights?.userType == 'DistributorUser'
          ? filter.dist
          : null,
      distCenter: null,
      fromDate: new Date(),
      toDate: new Date(),
      deliveryUser: null,
      vlTill: null,
      pageNo: 0,
      searchText: '',
      isExport: false,
    })
  }

  function formRequestObject() {
    let requestData = {
      distId: filter.dist?.value,
      distCenterId: filter.distCenter?.value,
      fromDate: filter.fromDate && formatDate(filter.fromDate),
      toDate: filter.fromDate && formatDate(filter.toDate),
      vlTillId: filter.vlTill?.value,
      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
      isExport: filter.isExport,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData.distId = userRights?.distributorId
    }
    return requestData
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setfilter({ ...filter, searchText: text })
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getDataListing(finalRequest)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchReportData(request)
    setShow(false)
  }

  const Download = () => {
    let request = { ...formRequestObject(), isExport: true }
    props.downloadJob(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize }
    fetchReportData(request)
  }

  return (
    <div>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <VzPageContainer title='Deliveryman Wise Collection Report'>
          <Row>
            <Col md={10} style={{ float: 'right' }}>
              <div ref={ref}>
                <Button
                  onClick={openFilterModal}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-filter' />
                  Filters
                </Button>

                <Overlay
                  show={show}
                  target={target}
                  placement='bottom'
                  container={ref}
                  containerPadding={20}
                  width='430px'
                  style={{ width: '430px' }}
                >
                  <Popover
                    id='popover-contained'
                    style={{ maxWidth: '360px', width: '360px' }}
                  >
                    <Popover.Header as='h3'>Filters</Popover.Header>
                    <Popover.Body>
                      <Formik
                        enableReinitialize
                        initialValues={filter}
                        validator={() => ({})}
                        onSubmit={LoadDataByfilter}
                      >
                        {(formik) => (
                          <Form autoComplete='off'>
                            <Row>
                              {/* {userRights == null && ( */}
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor Name'
                                  name='distributorName'
                                  options={addJobDistributorsOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setfilter({
                                        ...filter,
                                        dist: null,
                                      })
                                      return
                                    }
                                    setfilter({
                                      ...filter,
                                      dist: {
                                        label: e.label,
                                        value: e.value,
                                      },
                                    })
                                  }}
                                  value={filter.dist}
                                />
                              </Col>
                              {/* )} */}

                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution ID'
                                  name='distributionID'
                                  options={distCenters_select}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    setfilter({
                                      ...filter,
                                      distCenter: {
                                        label: e.label,
                                        value: e.value,
                                      },
                                    })
                                  }}
                                  value={filter.distCenter}
                                />
                              </Col>
                              <Row style={{ paddingRight: 0 }}>
                                <Col xs={12} md={6}>
                                  <FormikControl
                                    // formik={formik}
                                    control='date'
                                    label='From Date'
                                    name='fromDate'
                                    handleOnChange={(value) => {
                                      setfilter({ ...filter, fromDate: value })
                                    }}
                                    value={filter.fromDate}
                                  />
                                </Col>
                                <Col xs={12} md={6}>
                                  <FormikControl
                                    // formik={formik}
                                    control='date'
                                    label='To Date'
                                    name='toDate'
                                    handleOnChange={(value) => {
                                      setfilter({ ...filter, toDate: value })
                                    }}
                                    value={filter.toDate}
                                  />
                                </Col>
                              </Row>
                              <Row style={{ paddingRight: 0 }}>
                                <Col xs={12} md={12}>
                                  <FormikControl
                                    formik={formik}
                                    control='react-select'
                                    label='VL Till ID'
                                    name='vlTillId'
                                    options={deliverymanTill_select}
                                    isSearchable={true}
                                    handleOnChange={(e) => {
                                      if (!e || !e.value) {
                                        setfilter({ ...filter, vlTill: null })
                                        return
                                      }
                                      setfilter({
                                        ...filter,
                                        vlTill: {
                                          label: e.label,
                                          value: e.value,
                                        },
                                      })
                                    }}
                                    value={filter.vlTill}
                                  />
                                </Col>
                              </Row>
                            </Row>
                            <div className='row' style={{ marginTop: 20 }}>
                              <div className='form-group'>
                                <Link
                                  onClick={handleResetFilters.bind(
                                    null,
                                    formik.resetForm
                                  )}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'left',
                                  }}
                                >
                                  Reset
                                </Link>
                                <Link
                                  onClick={() => {
                                    setShow(false)
                                    setTarget(null)
                                  }}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Cancel
                                </Link>
                                <div className='float-right'>
                                  <Button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{
                                      marginRight: '10px',
                                      float: 'right',
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </Col>
            {isPermittedPage('Export', props.actions, props.userRole) &&
              <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={Download}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-download me-1' />
                  Export
                </Button>
              </Col>
            }
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <ServerSideDataTable
                data={
                  props.DLMCollectionReport?.data
                    ? props.DLMCollectionReport?.data
                    : []
                }
                columns={columns}
                getTrProps={(event, data) => { }}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={props.DLMCollectionReport?.totalRecords ?? 0}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                myIndex={currentIndex}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
            </Col>
          </Row>
        </VzPageContainer>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    filteredDistributorList: state.ddlList.filteredDistributorList
      ? state.ddlList.filteredDistributorList
      : [],
    distCentersddlList: state.ddlList.selectedDistCentersList
      ? state.ddlList.selectedDistCentersList
      : [],
    selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    deliverymanTill_ddl: state.ddlList.deliverymanTills ?? [],
    DLMCollectionReport: state.DLMCollectionReport,
    userRole: storage.get('_appUserRole'),
  }
}
export default connect(mapStateToProps, {
  getDropdownList,
  getDataListing,
  downloadJob,
})(DLMCollectionReport)
