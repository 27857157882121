import { GET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT, RESET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT } from '../../../Types'

const InvoiceWiseDailyCollectionDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case GET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT:
            return action.payload
        case RESET_INVOICEWISE_DAILY_COLLECTION_DETAILS_REPORT:
            return action.payload
        default:
            return state
    }
}
export default InvoiceWiseDailyCollectionDetailsReducer