import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

const TillAssignmentForm = (props) => {
  const [componentRef, setComponentRef] = useState(false)
  const [modalShow, setModalShow] = useState(true)
  const [readOnly, setReadOnly] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [initialValues, setInitialValues] = useState({
    id: '',
    distributor: '',
    distributionName: '',
    mfiName: '',
    resourceName: '',
    msisdn: '',
    tillNumber: '',
    email: '',
    notifier1: '',
    notifier2: '',
    routeNo: '',
    address: '',
    qrImagePath: '',
  })
  const [currentDataDetails, setCurrentDataDetails] = useState(initialValues)

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    distributionName: Yup.string().required('Distribution Center is required'),
    mfiName: Yup.string().required('MFI is required'),
    resourceName: Yup.string().required('Resource Name is Required'),
    msisdn: Yup.string().required('MSISDN is Required'),
    tillNumber: Yup.string().required('Till Number is Required'),
  })

  const columns = [
    {
      Header: 'Distribution Center',
      accessor: 'distCenterName',
    },
    {
      Header: 'User Name',
      accessor: 'userName',
    },
    {
      Header: 'Contact Number',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Deliveryman name',
      accessor: 'deliveryManName',
    },
    {
      Header: 'Route id',
      accessor: 'routeId',
    },
    {
      Header: 'Total Till number',
      accessor: 'totalTillNumber',
    },
    {
      Header: 'MFI',
      accessor: 'MFI',
    },
    {
      Header: 'Till No.',
      accessor: 'tillNo',
    },

    // {
    //   Header: 'Action',
    //   id: 'edit',
    //   accessor: 'id',
    //   Cell: (row) => (
    //     <div style={{ width: '106px' }}>
    //       <button onClick={() => View(row.value)} className=' btn btn-sm'>
    //         <i className='fas fa-eye'></i>
    //       </button>
    //       &nbsp;
    //       <button onClick={() => Edit(row.value)} className='btn btn-sm'>
    //         <i className='fas fa-edit'></i>
    //       </button>
    //       &nbsp;
    //       <button onClick={() => Delete(row.value)} className='btn btn-sm'>
    //         <i className='fas fa-trash'></i>
    //       </button>
    //     </div>
    //   ),
    // },
  ]

  const handleSubmit = async (data) => {}

  return (
    <div>
      <VzPageContainer title='Till Assignment Form'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Till Assignment Form</b>
            </h4>
            <br />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <CustomDataTable columns={columns} data={[]}></CustomDataTable>
          </Col>
        </Row>

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Formik
            enableReinitialize
            initialValues={currentDataDetails}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form
                autoComplete='off'
                encType='multipart/form-data'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    User Allocation Form
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={12}>
                        <Container>
                          <Row>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='resourceName'
                                  label='Deliveryman Name'
                                  readOnly={readOnly}
                                  name='resourceName'
                                  value={null}
                                  handleOnChange={(value) => {}}
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='resourceName'
                                  label='User Name'
                                  readOnly={readOnly}
                                  name='resourceName'
                                  value={null}
                                  handleOnChange={(value) => {}}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='resourceName'
                                  label='Contact No'
                                  readOnly={readOnly}
                                  name='resourceName'
                                  value={null}
                                  handleOnChange={(value) => {}}
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='resourceName'
                                  label='Corresponding E-mail Address'
                                  readOnly={readOnly}
                                  name='resourceName'
                                  value={null}
                                  handleOnChange={(value) => {}}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  readOnly={readOnly}
                                  control='react-select'
                                  type='mfiName'
                                  label='MFI'
                                  name='mfiName'
                                  value={null}
                                  options={[]}
                                  handleOnChange={(option) => {}}
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  readOnly={readOnly}
                                  control='react-select'
                                  type='mfiName'
                                  label='Till No.'
                                  name='mfiName'
                                  value={null}
                                  options={[]}
                                  handleOnChange={(option) => {}}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='resourceName'
                                  label='Route ID'
                                  readOnly={readOnly}
                                  name='resourceName'
                                  value={null}
                                  handleOnChange={(value) => {}}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  {readOnly === false ? (
                    <>
                      <input
                        type='submit'
                        className='btn btn-primary'
                        value={
                          isEdit === false ? 'Add QR/Till' : 'Update QR/Till'
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <Button
                    className='btn btn-default'
                    onClick={() => setModalShow(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo
      ? state.distributorMainInfo
      : [],
  }
}
export default connect(mapStateToProps, {})(TillAssignmentForm)
