import _ from 'lodash'
import {
  GET_MAININFORMATIONS,
  ADD_MAININFORMATIONS,
  UPDATE_MAININFORMATIONS,
  DELETE_MAININFORMATIONS,
  EMPTY_MAININFORMATIONS,
} from '../../../Types'

const mainInformationsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MAININFORMATIONS:
      return { ...action.payload }
    case ADD_MAININFORMATIONS:
      return { ...action.payload }
    case UPDATE_MAININFORMATIONS:
      return { ...state, ...action.payload }
    case DELETE_MAININFORMATIONS:
      return _.omit(...state, action.payload)
    case EMPTY_MAININFORMATIONS:
      return {}
    default:
      return state
  }
}
export default mainInformationsReducer
