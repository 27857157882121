import { GET_VLP_COIN_REPORT } from '../../../Types'

const initialState = {
  totalRecords: 0,
  users: [],
}

const CoinsHistoryReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_VLP_COIN_REPORT:
      return action.payload

    default:
      return state
  }
}
export default CoinsHistoryReportReducer
