import { Tabs, Tab } from 'react-bootstrap'
import { Form as BootstrapForm, FormControl, Badge } from 'react-bootstrap';
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import {
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
  Accordion,
} from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import Moment from 'react-moment'
import FormikControl from '../../../layouts/Input/FormikControl'

import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import {
  getTickets,
  getAllocatedDistCentersByUserId,
} from './DistributorInboxAction'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  GET_VIZ_IDS_DDL,
  GET_DISTRIBUTOR_DDL,
} from '../../../Types'
import storage from '../../../apis/Storage'
import { toast } from 'react-toastify'
import history from '../../../history'
import { isPermittedPage } from '../../../apis/Utility'


const DistributorInbox = (props) => {
  const [filterVizId, setFilterVizId] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(null)
  const [filterToDate, setFilterToDate] = useState(null)
  const [filterDistributorId, setFilterDistributorId] = useState(null)
  const [filterDistCenterId, setFilterDistCenterId] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  const [filterTicketNo, setFilterTicketNo] = useState(null)

  const [selectedDistributorId, setSelectedDistributorId] = useState(null)

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] = useState([])
  const [distCentersDropDown, setDistCentersDropDown] = useState([])
  ///1 = info,  2= Order, 3=Complaints,  4= Other
  const [activeCategoryCode, setActiveCategoryCode] = useState(null)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [searchText, setSearchText] = useState('')

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const DistFilterFormRef = useRef(null)
  const userRights = JSON.parse(storage.get('_userRights'))

  // const [ticketsData, setTicketsData] = useState([])
  const _appUserRole = storage.get('_appUserRole')
  const ticketStatusList = [
    {
      label: 'Pending',
      value: 2,
    },
    {
      label: 'In Progress',
      value: 3,
    },
    {
      label: 'Resolved',
      value: 4,
    }
  ]

  const [filterValuesModal, setFilterValuesModal] = useState({
    vizId: '',
    fromDate: null,
    toDate: null,
    distCenterId: '',
    status: '',
    ticketNo: '',
  })

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorId(null)
    setFilterVizId(null)
    setFilterTicketNo(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterDistCenterId(null)
    setFilterStatus(null)

    setFilterValuesModal({
      vizId: '',
      ticketNo: '',
      distCenterId: null,
      fromDate: '',
      toDate: '',
      status: null,
    })
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchData(request)
    setShow(false)
  }
  function formRequestObject(isExport = false) {
    let requestData = {
      distributorId: selectedDistributorId?.value,
      distCenterId: filterDistCenterId && filterDistCenterId.value,
      vizId: filterVizId,
      fromDate: filterFromDate && formatDate(filterFromDate),
      toDate: filterToDate && formatDate(filterToDate),
      ticketNo: filterTicketNo,
      ticketCategoryCode: activeCategoryCode,
      ticketHandlerStatus: filterStatus && filterStatus.value,
      pageSize: currentPageSize == 0 ? 5 : currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
    }
      if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData.distributorId = userRights?.distributorId
    }
    return requestData
  }

  const getData = (page) => {
    var request = { ...formRequestObject(), pageNo: page }
    fetchData(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize }
    fetchData(request)
  }

  function fetchData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getTickets(finalRequest)
  }

  // get only assigned distcenters assigned to user in product configuration section of distributor induction
  function getfilterDistCentersDropDown() {
    const userId = storage.get('_appUserId')
    props.getAllocatedDistCentersByUserId(
      userId,
      getAllocatedDistCentersByUserId_successCallBack
    )
  }

  function getAllocatedDistCentersByUserId_successCallBack(data) {
    debugger
    setDistCentersDropDown(data)
  }

  // sets in which tab user is currently viewing data
  const handleSelect = (key) => {
    setActiveCategoryCode(key)
    localStorage.setItem('_activeDistInboxTab', key)
  }

  // get category wise data when distrintuor is selected
  function loadDataByDistributor(value) {
    //setSelectedDistributorId(value)
    if (DistFilterFormRef.current) {
      setCurrentPageNo(0)
      setActiveCategoryCode(null)
      var request = {
        ...formRequestObject(),
        pageNo: 0,
        distributorId: value,
        ticketCategoryCode: null,
      }
      fetchData(request)
    }
  }

  function formatDate(dateInput) {
    var res = new Date(
      Date.parse(
        new Intl.DateTimeFormat('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(dateInput))
      )
    )
      .toISOString()
      .split('T')[0]
    return res
  }

  useEffect(() => {
    let activeTab = localStorage.getItem('_activeDistInboxTab')
    if (
      activeTab != null &&
      history?.location?.pathname == '/ConnectConversation'
    ) {
      setActiveCategoryCode(activeTab)
    }
    fetchData()
    // this will get data when distributeruser is logged in, for admin login this will return distcenters by distributor
    // but at another place will bring distcenters by selected distributor
    getfilterDistCentersDropDown()
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  // get all distributors
  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  //get distcenters by distributor
  // useEffect(() => {
  //   debugger
  //   if (filterDistributorId) {
  //     props.getDropdownList(
  //       `api/DistCenter/GetDistCentersDDL/${filterDistributorId?.value}`,
  //       GET_ADDJOB_DISTRIBUTIONCENTER_DDL
  //     )
  //   } else {
  //     setDistCentersDropDown([])
  //   }
  // }, [filterDistributorId])

  // set dist centers dropdown value after getting data by distributorID
  // useEffect(() => {
  //   debugger
  //   if (userRights != null && userRights?.userType == 'DistributorUser') {
  //     let distCenterList = props.addJobDistCentersddlList.filter((x) =>
  //       userRights?.distCenterIds.includes(x.value)
  //     )
  //     setDistCentersDropDown(
  //       distCenterList.map(function (item) {
  //         return {
  //           value: item.value,
  //           label: item.label,
  //         }
  //       })
  //     )
  //   } else {
  //     setDistCentersDropDown(
  //       props.addJobDistCentersddlList.map(function (item) {
  //         return {
  //           value: item.value,
  //           label: item.label,
  //         }
  //       })
  //     )
  //   }

  //   // setDistCentersDropDown(props.addJobDistCentersddlList)
  // }, [props.addJobDistCentersddlList])

  //this useEffect will be called whenever user notification clicks some chat
  useEffect(() => {
    if (props.newNotificationData?.handlerCategoryCode != null) {
      setCurrentPageNo(0)
      var request = {
        ...formRequestObject(),
        pageNo: 0,
        ticketCategoryCode: props.newNotificationData.handlerCategoryCode,
      }
      fetchData(request)
    }
  }, [props.newNotificationData])

  return (
    <>
      <VzPageContainer title='Distributor Inbox'>
        <Row>
          <Col md={10}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  label='Viz ID'
                                  name='vizId'
                                  handleOnChange={(value) => {
                                    filterValuesModal.vizId = value
                                    setFilterVizId(value)
                                  }}
                                  value={filterValuesModal.vizId}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  label='Ticket No'
                                  name='ticketNo'
                                  handleOnChange={(value) => {
                                    filterValuesModal.ticketNo = value
                                    setFilterTicketNo(value)
                                  }}
                                  value={filterValuesModal.ticketNo}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  minDate={filterFromDate}
                                />
                              </Col>
                            </Row>
                            {/* <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor Id'
                                  name='distributorId'
                                  options={addJobDistributorsOptions}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributorId(null)
                                      setFilterDistCenterId([])
                                      setDistCentersDropDown([])
                                      return
                                    }
                                    filterValuesModal.distributorId = e.value
                                    setFilterDistributorId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributorId}
                                />
                              </Col>
                            </Row> */}
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Id'
                                  name='distCenterId'
                                  options={distCentersDropDown}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistCenterId([])
                                      return
                                    }
                                    filterValuesModal.distCenterId = e.value
                                    setFilterDistCenterId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistCenterId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Status'
                                  name='status'
                                  options={ticketStatusList}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterStatus([])
                                      return
                                    }
                                    filterValuesModal.status = e.value
                                    setFilterStatus({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterStatus}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          {_appUserRole == 'Administrator' && (
            <Col>
              <Formik
                innerRef={DistFilterFormRef}
                enableReinitialize
                initialValues={filterValuesModal}
                validator={() => ({})}
                onSubmit={loadDataByDistributor}
              >
                {(formik) => (
                  <Form autoComplete='off'>
                    <Row>
                      <Row style={{ paddingRight: 0 }}>
                        <Col xs={12}>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            label='Distributor'
                            name='distributorId'
                            options={props.allDistributorList}
                            isClearable={true}
                            isSearchable={false}
                            isMulti={false}
                            handleOnChange={(e) => {
                              if (!e || !e.value) {
                                setSelectedDistributorId(null)
                                loadDataByDistributor(null)
                                return
                              }
                              setSelectedDistributorId({
                                label: e.label,
                                value: e.value,
                              })
                              loadDataByDistributor(e.value)
                            }}
                            value={selectedDistributorId}
                          />
                        </Col>
                      </Row>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          )}
        </Row>

        <Tabs
          defaultActiveKey=
          {isPermittedPage('Information', props.actions, props.userRole) ? '1' :
            isPermittedPage('Orders', props.actions, props.userRole) ? '2' :
              isPermittedPage('Complaints', props.actions, props.userRole) ? '3' :
                isPermittedPage('Others', props.actions, props.userRole) ? '4' : '0'
          }
          id='uncontrolled-tab-example'
          className='mb-3'
          onSelect={handleSelect}
          activeKey={activeCategoryCode == null ? 1 : activeCategoryCode}
          fill
        >
          {isPermittedPage('Information', props.actions, props.userRole) &&
            <Tab
              eventKey="1"
              title={
                <>
                  Information
                  {
                    props.ticketsData?.infoTickets?.notVisitedCount > 0
                    &&
                    <Badge className='distributor-inbox-notification-badge'> <h5>{props.ticketsData?.infoTickets?.notVisitedCount}</h5> </Badge>
                  }
                </>
              }
            >
              {
                <TicketsData
                  handlerType='Information'
                  ticketsData={props.ticketsData.infoTickets}
                  getData={getData}
                  pageSizeChanged={pageSizeChanged}
                  currentPageNo={currentPageNo}
                />
              }
            </Tab>
          }
          {isPermittedPage('Orders', props.actions, props.userRole) &&
            <Tab
              eventKey='2'

              title={
                <>
                  Orders

                  {
                    props.ticketsData?.orderTickets?.notVisitedCount > 0
                    &&
                    <Badge className='distributor-inbox-notification-badge'> <h5>{props.ticketsData?.orderTickets?.notVisitedCount}</h5> </Badge>
                  }
                </>
              }
            >
              {
                < TicketsData
                  handlerType='Orders'
                  ticketsData={props.ticketsData.orderTickets}
                  getData={getData}
                  pageSizeChanged={pageSizeChanged}
                />
              }
            </Tab>
          }
          {isPermittedPage('Complaints', props.actions, props.userRole) &&
            <Tab
              eventKey='3'
              title={
                <>
                  Complaints
                  {
                    props.ticketsData?.complaintTickets?.notVisitedCount > 0
                    &&
                    <Badge className='distributor-inbox-notification-badge'> <h5>{props.ticketsData?.complaintTickets?.notVisitedCount}</h5> </Badge>
                  }
                </>
              }
            >
              {
                <TicketsData
                  handlerType='Complaints'
                  ticketsData={props.ticketsData.complaintTickets}
                  getData={getData}
                  pageSizeChanged={pageSizeChanged}
                />
              }
            </Tab>
          }
          {isPermittedPage('Others', props.actions, props.userRole) &&
            <Tab
              eventKey='4'
              title={
                <>
                  Others
                  {
                    props.ticketsData?.otherTickets?.notVisitedCount > 0
                    &&
                    <Badge className='distributor-inbox-notification-badge'> <h5>{props.ticketsData?.otherTickets?.notVisitedCount}</h5> </Badge>
                  }
                </>
              }
            >
              {
                <TicketsData
                  handlerType='Others'
                  ticketsData={props.ticketsData.otherTickets}
                  getData={getData}
                  pageSizeChanged={pageSizeChanged}
                />
              }
            </Tab>
          }
        </Tabs>
      </VzPageContainer>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    ticketsData: state.DistributorInbox?.ticketsData,
    handlerNotificationObject:
      state.DistributorInbox?.handlerNotificationObject,
    newNotificationData: state.DistributorInbox.newNotificationData,
    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    distributorInbox: state.DistributorInbox,
  }
}

export default connect(mapStateToProps, {
  getTickets,
  getDropdownList,
  getAllocatedDistCentersByUserId,
  // downloadJob
})(DistributorInbox)

const TicketsData = (props) => {
  const { handlerType, ticketsData } = props
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [searchText, setSearchText] = useState('')

  const Columns = [
    {
      Header: 'Ticket No',
      accessor: 'ticketSrNo',
    },
    {
      Header: 'Shop Id',
      accessor: 'vizShopCode',
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY HH:mm:ss'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Distribution Center',
      accessor: 'distCenterName',
    },
    {
      Header: 'Status',
      accessor: 'handlerStatusString',
    },
    // {
    //     Header: 'Action',
    //     id: 'edit',
    //     accessor: 'id',
    //     Cell: (row) => (
    //         <div style={{ width: '106px' }}>
    //             {/* <button onClick={() => View(row.value)} className=' btn btn-sm'>
    //       <i className='fas fa-eye'></i>
    //     </button> */}
    //             <button onClick={() => { }} className=' btn btn-sm'>
    //                 <i className='fas fa-eye'></i>
    //             </button>
    //             &nbsp;
    //             <button onClick={() => { }} className='btn btn-sm'>
    //                 <i className='fas fa-edit'></i>
    //             </button>
    //         </div>
    //     ),
    // },
  ]

  const navigateToChat = (row) => {
    // if (row.handlerStatusString == 'Pending') {
    //   toast.error('Ticket is pending ')
    //   return
    // }
    setCurrentPageNo(0);
    props.getData(0)
    localStorage.setItem('_convo', JSON.stringify(row))
    history.push(`/ConnectConversation`)
    history.go(`/ConnectConversation`)
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <ServerSideDataTable
            data={ticketsData?.dataList ?? []}
            columns={Columns}
            getTrProps={(event, data) => navigateToChat(data)}
            goToPreviousPage={(val) => {
              setCurrentPageNo(val)
              props.getData(val)
            }}
            goToNextPage={(val) => {
              setCurrentPageNo(val)
              props.getData(val)
            }}
            totalRecords={ticketsData?.totalRecords}
            pageSizeChange={(size) => {
              setCurrentPageSize(size)
              props.pageSizeChanged(size)
            }}
            myPageSize={currentPageSize}
            pageNo={currentPageNo}
            PageTableName='DistributorInbox'
          //searchChanged={searchChanged}
          />
        </Col>
      </Row>
    </div>
  )
}

export { TicketsData }
