import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import storage from '../../../apis/Storage'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { GET_FINJA_LOAN_REPORT } from '../../../Types'

import fileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export const getFinjaLoanReport = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Reports/GetFinjaShopsLoan`, requestData)
    .then(({ data }) => {
      if (data && data?.response)
        dispatch({
          type: GET_FINJA_LOAN_REPORT,
          payload: data.response,
        })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}


export const downloadJob = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Reports/GetFinjaShopsLoan`, requestData)
    .then(({ data }) => {
      const outputFilename = `SUCCESSFULL_LOAN_DETAILS_Report_${Date.now()}`
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const exportToCSV = (csvData, jobNumber, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData)
        if (ws) {
          var range = XLSX.utils.decode_range(ws['!ref'])
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
            if (!ws[address]) continue
            ws[address].v =
              ws[address].v == 'vizCode'
                ? 'Viz Id'
                : ws[address].v == 'lender'
                  ? 'Lender'
                  : ws[address].v == 'distCenterName'
                    ? 'Distribution Center'
                    : ws[address].v == 'loanDate'
                      ? 'Date'
                      : ws[address].v == 'invoiceNo'
                        ? 'Invoice No.'
                        : ws[address].v == 'invoiceAmount'
                          ? 'Invoice Amount'
                          : ws[address].v == 'loanAmount'
                            ? 'Loan Amount'
                            : ws[address].v == 'profit'
                              ? 'Profit'
                              : ws[address].v == 'totalPayable'
                                ? 'Total Payable'
                                : ws[address].v == 'dueDate'
                                  ? 'Due Date'
                                  : ws[address].v == 'deliverymanTillNo'
                                    ? 'Deliveryman Till No.'
                                    : ws[address].v
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const fileData = new Blob([excelBuffer], { type: fileType })
        fileSaver.saveAs(fileData, fileName + fileExtension)
      }
      exportToCSV(data?.response?.data, '', outputFilename)
      dispatch(hideLoading())
    })

    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}