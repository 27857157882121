import {
  GET_RSO_DETAILS_REPORT,
  RESET_RSO_DETAILS_REPORT,
} from '../../../Types'

const RsoDetailsReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_RSO_DETAILS_REPORT:
      return [...action.payload]
    case RESET_RSO_DETAILS_REPORT:
      return []

    default:
      return state
  }
}
export default RsoDetailsReportReducer
