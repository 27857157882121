import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import Moment from 'react-moment'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import axios from '../../../apis/Api'

const KycVisitRequiredList = (props) => {
  const [kycList, setKycList] = useState([])

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',

      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },

    {
      Header: 'Shop Id',
      accessor: 'shopCode',
    },
  ]

  const getKycList = () => {
    axios
      .get(`api/LenderKYC/GetVisitRequiredList`)
      .then(({ data }) => {
        setKycList(data?.response)
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    getKycList()
  }, [])

  return (
    <>
      <VzPageContainer title='Visit Required List'>
        <Row>
          <Col style={{ float: 'left' }}>
            <h4>
              <b>Visit Required List</b>
            </h4>
            <br />
          </Col>
        </Row>

        <Row>
          <Col>
            <CustomDataTable
              data={kycList ?? []}
              columns={columns}
              getTrProps={() => null}
            />
          </Col>
        </Row>
      </VzPageContainer>
    </>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(KycVisitRequiredList)
