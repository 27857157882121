import axios from 'axios'
import storage from './Storage'

export default axios.create({
  //baseURL: 'http://localhost:5000/',
  //  baseURL: 'https://localhost:44397/',
  // baseURL: 'https://stagingapi.vizlinktech.com/',
  // baseURL: 'https://api.vizlinktech.com/',
  baseURL: 'https://devapi.vizlinktech.com/',
  //http://stagingapi.vizlinktech.com/
  headers: {
    Authorization: `Bearer ${storage.get('_appToken')}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})