import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import fileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { ADD_JOBS, GET_JOBS, RESET_DDL } from '../../../Types'

export const addJob = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  const formData = new FormData()
  formData.append('DistributorId', formValues.distributorId)
  formData.append('DistCenterId', formValues.DistCenterId)
  formData.append('DataFile', formValues.DataFile)
  if (formValues.assignedDistCenters) {
    formData.append('assignedDistCenters', formValues.assignedDistCenters)
  }

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  }
  await axios
    .post('api/DistShopInductionJob/UploadJobDataSheet', formData, config)
    .then(({ data }) => {
      dispatch({
        type: ADD_JOBS,
        payload: data.response,
      })
      dispatch(hideLoading())
      toast.success(data.message)
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const getJobs = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/DistShopInductionJob/GetDistShopInductionJobsList`, postedData)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_JOBS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const downloadJob = (id) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(
      `api/DistShopInductionJob/DownloadDistShopInductionJobDataFile?id=${id}`
    )
    .then(({ data }) => {
      const outputFilename = `JobData_JobNumber_${data.jobNo}_${Date.now()}`
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const exportToCSV = (csvData, jobNumber, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData)
        if (ws) {
          var range = XLSX.utils.decode_range(ws['!ref'])
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
            if (!ws[address]) continue
            ws[address].v =
              ws[address].v == 'distributor'
                ? 'Distributor'
                : ws[address].v == 'distributionCenter'
                ? 'Distribution Center'
                : ws[address].v == 'principal'
                ? 'Principal'
                : ws[address].v == 'vanNo'
                ? 'Van No.'
                : ws[address].v == 'shopID'
                ? 'Shop ID'
                : ws[address].v == 'shopName'
                ? 'Shop Name'
                : ws[address].v == 'cnic'
                ? 'CNIC'
                : ws[address].v == 'shopAddress'
                ? 'Shop Address'
                : ws[address].v == 'shopCategory'
                ? 'Shop Category'
                : ws[address].v == 'paymentMode'
                ? 'Payment Mode'
                : ws[address].v == 'averageMonthlySales'
                ? 'Average Monthly Sales'
                : ws[address].v == 'shopContactNumber'
                ? 'Shop Contact Number'
                : ws[address].v == 'status'
                ? 'Status'
                : ws[address].v == 'id'
                ? 'ID'
                : ws[address].v == 'actionStatus'
                ? 'Action Status'
                : ws[address].v == 'date'
                ? 'Date'
                : ws[address].v == 'rsoId'
                ? 'RSO ID'
                : ws[address].v == 'rsoName'
                ? 'RSO Name'
                : ws[address].v
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const fileData = new Blob([excelBuffer], { type: fileType })
        fileSaver.saveAs(fileData, fileName + fileExtension)
      }
      exportToCSV(data.responseDataList, data.jobNo, outputFilename)
      dispatch(hideLoading())
    })

    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
export const resetDropdownList = () => async (dispatch) => {
  dispatch({
    type: RESET_DDL,
    payload: 'FilterDistCenters',
  })
}
