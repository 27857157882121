import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  finalizeDistributorDetails,
  saveDistributorDetailsForLator,
} from './DistributorSummaryAction'
import DistributionDetailsPrintView from './DistributionDetailsPrintView'
import DistributionDetailsView from './DistributionDetailsView'
// import TestPrintView from './TestPrintView'
import ReactToPrint from 'react-to-print'
import { toast } from 'react-toastify'
class DistributorDetailedSummary extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div>
        <Container fluid className=''>
          {this.props.distributorMainInfo.isFinalize === true ? (
            <div></div>
          ) : (
            <div>
              <Button
                variant='outline-info'
                style={{ position: 'absolute', right: '110px', bottom: '25px' }}
                onClick={() => {
                  if (this.props.distributorMainInfo.id != null) {
                    this.props.saveDistributorDetailsForLator(
                      this.props.distributorMainInfo.id
                    )
                  } else {
                    toast.error('Distributor details not found')
                  }
                }}
              >
                Save for Later
              </Button>
              <Button
                className='form-btn'
                variant='primary'
                style={{ position: 'absolute', right: '20px', bottom: '20px' }}
                onClick={() =>
                  this.props.finalizeDistributorDetails(
                    this.props.distributorMainInfo.id
                  )
                }
              >
                Finalize
              </Button>
            </div>
          )}

          <div>
            {/* button to trigger printing of target component */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant='outline-primary'
                  style={{ position: 'absolute', left: '20px', bottom: '20px' }}
                >
                  Print As PDF
                </Button>
              )}
              content={() => this.componentRef}
            />
            <DistributionDetailsView
              distributorMainInfo={this.props.distributorMainInfo}
              bankDetails={this.props.bankDetails}
              distributionCenters={this.props.distributionCenters}
              mfiDetails={this.props.mfiDetails}
              qrOrTillDetails={this.props.qrOrTillDetails}
              distributorPrincipals={this.props.distributorPrincipals}
            />

            {/* component to be printed */}
            <div style={{ display: 'none' }}>
              <DistributionDetailsPrintView
                distributorMainInfo={this.props.distributorMainInfo}
                bankDetails={this.props.bankDetails}
                distributionCenters={this.props.distributionCenters}
                mfiDetails={this.props.mfiDetails}
                qrOrTillDetails={this.props.qrOrTillDetails}
                distributorPrincipals={this.props.distributorPrincipals}
                ref={(el) => (this.componentRef = el)}
              />
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    distributorMainInfo: state.distributorMainInfo
      ? state.distributorMainInfo
      : [],
    bankDetails: state.bankDetails ? state.bankDetails : [],
    distributionCenters: state.distributionCenters
      ? state.distributionCenters
      : [],
    mfiDetails: state.mfiDetails ? state.mfiDetails : [],
    qrOrTillDetails: state.qrOrTillDetails ? state.qrOrTillDetails : [],
    distributorPrincipals: state.distributorPrincipals
      ? state.distributorPrincipals
      : [],
  }
}
export default connect(mapStateToProps, {
  finalizeDistributorDetails,
  saveDistributorDetailsForLator,
})(DistributorDetailedSummary)
