import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import storage from '../../../../apis/Storage'
import { ADD_PAGE, DELETE_PAGE, GET_PAGES } from '../../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const getPages = () => async (dispatch) => {
  await axios
    .get(`api/Page`)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_PAGES,
        payload: data.response,
      })
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
export const addPage = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/Page', formValues)
    .then(({ data }) => {
      if (data.response)
        dispatch({
          type: ADD_PAGE,
          payload: data.response,
        })
      dispatch(hideLoading())
      toast.success('Page added successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const deletePage = (id) => async (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${storage.get('_appToken')}` },
  }

  await axios
    .delete(`api/Page/${id}`, config)
    .then(() => {
      toast.success('Page deleted successfully')
      dispatch({ type: DELETE_PAGE, payload: id })
    })
    .catch(function (error) {
      dispatch(hideLoading())
      toast.error('Error: ' + error)
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
