import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'

import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../../layouts/DataTables/CustomDataTable'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form } from 'formik'
import FormikControl from '../../../../layouts/Input/FormikControl'
import ServerSideDataTable from '../../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import {
  getCurrentShopwiseTransactionsDetails,
  resetCurrentShopwiseTransactionsDetails,
  getMfis,
  exportReport,
} from './CurrentShopwiseTransactionsDetailsActions'
import Moment from 'react-moment'
import storage from '../../../../apis/Storage'
import { getDropdownList } from '../../../Shared/dropdownListActions'
import { GET_ALL_DISTRIBUTION_CENTERS_DDL } from '../../../../Types'
import { toast } from 'react-toastify'
import axios from '../../../../apis/Api'
import { isPermittedPage } from '../../../../apis/Utility'
const CurrentShopwiseTransactionsDetails = (props) => {
  //Pagination
  const userRights = JSON.parse(storage.get('_userRights'))
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [filterFromDate, setFilterFromDate] = useState(new Date())
  const [filterToDate, setFilterToDate] = useState(new Date())

  const [maxToDate, setMaxToDate] = useState(
    new Date().setMonth(new Date().getMonth() + 3)
  )
  const [filterDistCenter, setFilterDistCenter] = useState(null)
  const [filterPaymentStatus, setFilterPaymentStatus] = useState(null)
  const [filterBank, setFilterBank] = useState(null)
  const [filterShopId, setFilterShopId] = useState(null)

  const [mfiList, setMfiList] = useState([])

  const [filterValuesModal, setFilterValuesModal] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    distCenter: '',
    paymentStatus: '',
    shopId: '',
    bank: '',
  })


  const [filtreredDistCentersList, setFiltreredDistCentersList] = useState([])

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [showModal, setShowModal] = useState(false)
  const [targetModal, setTargetModal] = useState(null)
  const refModal = useRef(null)

  const [transactionsList, setTransactionsList] = useState([])

  const Columns = [
    {
      Header: 'Distribution ID',
      accessor: 'distributionId',
    },
    {
      Header: 'Shop ID',
      accessor: 'shopId',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Invoice Number',
      accessor: 'invoiceNumber',
    },
    {
      Header: ' Order Amount',
      accessor: 'orderAmount',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Paid Amount',
      accessor: 'paidAmount',
      Cell: (row) =>
      (
        <div>
          <Link
            onClick={() => viewTransactionDetails(row.row.original.orderId)}
            style={{ cursor: 'pointer', width: '106px' }}
            to='#'
          >
            {Number(row.value).toLocaleString()}
          </Link>
        </div>
      ),
    },
    {
      Header: 'Payment Status',
      accessor: 'paymentStatus',
    },
    // {
    //   Header: 'Bank',
    //   accessor: 'bank',
    // },
    {
      Header: 'No. of Transactions',
      accessor: 'noOfTransactions',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
  ]

  const statusColumns = [
    {
      label: 'Paid',
      value: 'Paid',
    },
    {
      label: 'Pending',
      value: 'Pending',
    },
    {
      label: 'In Progress',
      value: 'InProgress',
    },
  ]

  const modalColumns = [
    {
      Header: 'Invoice Number',
      accessor: 'invoiceNumber',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },

    {
      Header: 'Transaction Status',
      accessor: 'transactionStatus',
    },
    {
      Header: 'Transaction Date',
      accessor: 'transactionDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MMM/YYYY HH:mm:ss'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Payment Source',
      accessor: 'paymentSource'
    },
    {
      Header: 'Transaction Response',
      accessor: 'transactionResponse'
    },
    {
      Header: 'Sender Contact No',
      accessor: 'senderContactNo'
    },
    {
      Header: 'Delivery Qr Code',
      accessor: 'deliveryQrCode'
    }
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const viewTransactionDetails = (id) => {
    axios
      .get(`api/ShopOrderTransaction/GetOrderTransactions/${id}`)
      .then(({ data }) => {
        setTransactionsList(data)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        }
      })
    setShowModal(true)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterFromDate(new Date())
    setFilterToDate(new Date())
    setFilterDistCenter(null)
    setFilterPaymentStatus(null)
    setFilterBank(null)
    setFilterShopId(null)
    setFilterValuesModal({
      fromDate: new Date(),
      toDate: new Date(),
      distCenter: '',
      paymentStatus: '',
      shopId: '',
      bank: '',
    })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      bank: filterBank == null ? null : filterBank.value,
      shopId:
        filterValuesModal.shopId == null ? null : filterValuesModal.shopId,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (userRights != null && userRights?.userType == 'DistributorUser' && (requestData.distributionId == null || requestData.distributionId == '')) {
      requestData.distributorId = userRights?.distributorId;
    }
    props.getCurrentShopwiseTransactionsDetails(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      bank: filterBank == null ? null : filterBank.value,
      shopId: filterValuesModal.shopId == '' ? null : filterValuesModal.shopId,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (userRights != null && userRights?.userType == 'DistributorUser' && (requestData.distributionId == null || requestData.distributionId == '')) {
      requestData.distributorId = userRights?.distributorId;
    }
    props.getCurrentShopwiseTransactionsDetails(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    let requestData = {
      distributionId:
        event.distCenter == '' || event.distCenter == null
          ? null
          : event.distCenter.value,
      paymentStatus:
        event.paymentStatus == '' || event.paymentStatus == null
          ? null
          : event.paymentStatus.value == null
            ? event.paymentStatus
            : event.paymentStatus.value,
      bank: event.bank == '' || event.bank == null ? null : event.bank.value,
      shopId: event.shopId == '' ? null : event.shopId,
      fromDate:
        event.fromDate == '' || event.fromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.fromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        event.toDate == '' || event.toDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.toDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser' && (requestData.distributionId == null || requestData.distributionId == '')) {
      requestData.distributorId = userRights?.distributorId;
    }
    props.getCurrentShopwiseTransactionsDetails(requestData)
    setShow(false)
    setFilterFromDate(event.fromDate == '' ? null : event.fromDate)
    setFilterToDate(event.toDate == '' ? null : event.toDate)
  }

  const setToDateValueAndMax = (value) => {
    // setFilterToDate(new Date(value).setMonth(new Date(value).getMonth() + 3))
    // filterValuesModal.toDate = new Date(value).setMonth(
    //   new Date(value).getMonth() + 3
    // )
    setMaxToDate(new Date(value).setMonth(new Date(value).getMonth() + 3))
  }

  const Download = () => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      bank: filterBank == null ? null : filterBank.value,
      shopId: filterValuesModal.shopId == '' ? null : filterValuesModal.shopId,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (userRights != null && userRights?.userType == 'DistributorUser' && (requestData.distributionId == null || requestData.distributionId == '')) {
      requestData.distributorId = userRights?.distributorId;
    }
    props.exportReport(requestData)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      bank: filterBank == null ? null : filterBank.value,
      shopId: filterValuesModal.shopId == '' ? null : filterValuesModal.shopId,

      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }

    if (userRights != null && userRights?.userType == 'DistributorUser' && (requestData.distributionId == null || requestData.distributionId == '')) {
      requestData.distributorId = userRights?.distributorId;
    }
    props.getCurrentShopwiseTransactionsDetails(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.Reports && props.Reports.length > 0) {
      setTotalRecords(props.Reports[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.Reports])

  useEffect(() => {
    console.log('this use effect is called');
    props.getMfis()
    props.getDropdownList(`api/DistCenter`, GET_ALL_DISTRIBUTION_CENTERS_DDL)
    return () => {
      props.resetCurrentShopwiseTransactionsDetails()
    }
  }, [])

  useEffect(() => {
    let list = props.mfis.map((x) => {
      return {
        label: x.mfiName,
        value: x.id,
      }
    })
    list = [{ label: 'All', value: '' }, ...list]
    setMfiList(list)
  }, [props.mfis])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
        var assignedDistcenterIds = userRights.distCenterIds.split(',');
        let filteredItems = props.DistCentersList.filter(item => assignedDistcenterIds.includes(item.value.toUpperCase()));
        setFiltreredDistCentersList(filteredItems);
    }
    else {
      setFiltreredDistCentersList(props.DistCentersList)
    }
  }, [props.DistCentersList])

  return (
    <div>
      <Row>
        <Col md={10}>
          <div ref={ref}>
            <Button
              onClick={openFilterModal}
              className='m-3'
              variant='outline-primary'
            >
              <i className='fa fa-filter' />
              Filters
            </Button>

            <Overlay
              show={show}
              target={target}
              placement='bottom'
              container={ref}
              containerPadding={20}
              width='430px'
              style={{ width: '430px' }}
            >
              <Popover
                id='popover-contained'
                style={{ maxWidth: '360px', width: '360px' }}
              >
                <Popover.Header as='h3'>Filters</Popover.Header>
                <Popover.Body>
                  <Formik
                    enableReinitialize
                    initialValues={filterValuesModal}
                    validator={() => ({})}
                    onSubmit={LoadDataByfilter}
                  >
                    {(formik) => (
                      <Form autoComplete='off'>
                        <Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distribution ID'
                                name='distID'
                                options={filtreredDistCentersList ?? []}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.distCenter = option
                                  setFilterDistCenter(option)
                                }}
                                value={filterValuesModal.distCenter}
                              />
                            </Col>
                          </Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={6}>
                              <FormikControl
                                // formik={formik}
                                control='date'
                                label='From Date'
                                name='fromDate'
                                handleOnChange={(value) => {
                                  filterValuesModal.fromDate = value
                                  setFilterFromDate(value)
                                  setToDateValueAndMax(value)
                                }}
                                value={filterValuesModal.fromDate}
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <FormikControl
                                // formik={formik}
                                control='date'
                                label='To Date'
                                name='toDate'
                                handleOnChange={(value) => {
                                  filterValuesModal.toDate = value
                                  setFilterToDate(value)
                                }}
                                value={filterValuesModal.toDate}
                                maxDate={maxToDate}
                                minDate={filterFromDate}
                              />
                            </Col>
                          </Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Payment Status'
                                name='paymentStatus'
                                options={statusColumns}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.paymentStatus = option
                                  setFilterPaymentStatus(option)
                                }}
                                value={filterValuesModal.paymentStatus}
                              />
                            </Col>
                          </Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='input'
                                label='Shop ID'
                                name='ShopID'
                                handleOnChange={(value) =>
                                  (filterValuesModal.shopId = value)
                                }
                                value={filterValuesModal.shopId}
                              />
                            </Col>
                          </Row>
                          {/* <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Bank'
                                name='bank'
                                options={mfiList}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.bank = option
                                  setFilterBank(option)
                                }}
                                value={filterValuesModal.bank}
                              />
                            </Col>
                          </Row> */}
                        </Row>
                        <div className='row' style={{ marginTop: 20 }}>
                          <div className='form-group'>
                            <Link
                              onClick={handleResetFilters.bind(
                                null,
                                formik.resetForm
                              )}
                              to='#'
                              className='active'
                              style={{
                                padding: '0px',
                                marginTop: '10px',
                                float: 'left',
                              }}
                            >
                              Reset
                            </Link>
                            <Link
                              onClick={() => {
                                setShow(false)
                                setTarget(null)
                              }}
                              to='#'
                              className='active'
                              style={{
                                padding: '0px',
                                marginTop: '10px',
                                float: 'right',
                              }}
                            >
                              Cancel
                            </Link>
                            <div className='float-right'>
                              <Button
                                type='submit'
                                className='btn btn-primary'
                                style={{
                                  marginRight: '10px',
                                  float: 'right',
                                }}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </Col>
        {isPermittedPage('Export', props.actions, props.userRole) &&
        <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={Download} className='m-3' variant='outline-primary'>
            <i className='fa fa-download me-1' />
            Export
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <ServerSideDataTable
            data={props.Reports ?? []}
            columns={Columns}
            getTrProps={(event, data) => null}
            goToPreviousPage={(val) => getData(val)}
            goToNextPage={(val) => getData(val)}
            totalRecords={totalRecords}
            pageSizeChange={(size) => pageSizeChanged(size)}
            myPageSize={currentPageSize}
            myIndex={currentIndex}
            pageNo={currentPageNo}
            searchChanged={searchChanged}
          />
        </Col>
      </Row>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        backdrop='static'
        keyboard={false}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Transactions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <CustomDataTable
                IsSearchEnable={false}
                data={transactionsList}
                columns={modalColumns}
                getTrProps={null}
                IsPagingEnable={true}
              />
            </Row>
            <Row>
              <Col>
                <Button
                  className='btn btn-default'
                  style={{ float: 'right', marginTop: '10px' }}
                  onClick={() => setShowModal(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div >
  )
}

const mapStateToProps = (state) => {
  return {
    Reports: state.CurrentShopwiseTransactionsDetails,
    DistCentersList: state.ddlList.distCenters,
    mfis: state.MFIs ? state.MFIs : [],
  }
}

export default connect(mapStateToProps, {
  getCurrentShopwiseTransactionsDetails,
  resetCurrentShopwiseTransactionsDetails,
  getDropdownList,
  getMfis,
  exportReport,
})(CurrentShopwiseTransactionsDetails)
