import ReactCreatable from 'react-select/creatable'
import { useField, ErrorMessage, getIn } from 'formik'
import TextError from './TextError'
import { useState, useEffect } from 'react'
import validater from 'validator'
import { toast } from 'react-toastify'
import _ from 'lodash'

function SelectCreatableWithNoOption(props) {
  const {
    name,
    type,
    label,
    value,
    handleValueChange,
    formik,
    disabled,
    ...rest
  } = props
  const [, , helpers] = useField(name)
  const getStyles = (formik, fieldName) => {
    if (getIn(formik.touched, fieldName) && getIn(formik.errors, fieldName)) {
      return {
        border: '1px solid red',
        borderRadius: '4px',
      }
    } else return {}
  }

  const [tempValue, setTempValue] = useState([])
  const [tempKey, setTempKey] = useState('')
  const components = {
    DropdownIndicator: null,
  }
  useEffect(() => {
    if (value) {
      // handleValueChange([...value])
      // helpers.setValue([...value])
      setTempValue([...value])
      setTempKey('')
    }
  }, [value])

  const handleInputChange = (inputValue) => {
    setTempKey(inputValue)
  }
  const handleKeyDown = (event) => {
    if (!tempKey) return
    var updatedContactNo = tempKey
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (type === 'contacts') {
          if (!validater.isMobilePhone(tempKey)) {
            toast.warn('Only mobile/phone numbers are allowed')
            setTempKey('')
            break
          } else {
            if (tempKey.includes('+92'))
              updatedContactNo = '0' + tempKey.substring(3, tempKey.length)
            if (tempKey.includes('0092'))
              updatedContactNo = '0' + tempKey.substring(4, tempKey.length)
          }
        }
        if (type === 'emails') {
          if (!validater.isEmail(tempKey)) {
            toast.warn('Your email format is not valid')
            setTempKey('')
            break
          }
        }
        if (type) {
          let allValues = _.map(tempValue, 'label').join(', ')
          if (allValues.includes(updatedContactNo)) {
            if (type === 'emails') {
              toast.warn('You already added this email')
            } else if (type === 'contacts') {
              toast.warn('You already added this contact')
            }
            setTempKey('')
            break
          }
        }
        handleValueChange([
          ...tempValue,
          { label: updatedContactNo, value: tempValue },
        ])
        helpers.setValue([
          ...tempValue,
          { label: updatedContactNo, value: tempValue },
        ])
        setTempValue([
          ...tempValue,
          { label: updatedContactNo, value: tempValue },
        ])
        setTempKey('')
        // event.preventDefault()
        break
      default:
        break
    }
  }
  return (
    <div>
      <label htmlFor={name} style={{ marginBottom: 0 }}>
        {label}
      </label>
      <div style={formik ? getStyles(formik, name) : {}}>
        <ReactCreatable
          components={components}
          inputValue={tempKey}
          isClearable
          isMulti
          menuIsOpen={false}
          onInputChange={(e) => {
            handleInputChange(e)
          }}
          onKeyDown={handleKeyDown}
          readOnly={disabled}
          isDisabled={disabled}
          placeholder={`Type ${label} and press enter...`}
          value={value}
          onChange={(value) => {
            handleValueChange([...value])
            helpers.setValue([...value])
            setTempValue([...value])
            setTempKey('')
          }}
          onBlur={(value) => {
            helpers.setTouched(true)
          }}
          {...rest}
        />
      </div>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default SelectCreatableWithNoOption
