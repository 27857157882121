import React, { Component, useEffect, useState, useRef } from 'react'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { connect } from 'react-redux'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, Redirect } from 'react-router-dom'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_JOBS_DISTRIBUTOR_DDL,
  GET_DISTRIBUTIONCENTER_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import history from '../../../history'
import { getPaymentStatusReport } from './PaymentStatusReportActions'
import Moment from 'react-moment'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'

const PaymentStatusReport = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [
    addOrderDistributionCentersOptions,
    setAddOrderDistributionCentersOptions,
  ] = useState(null)
  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionID, setFilterDistributionID] = useState(null)

  const [paymentStatus, setPaymentStatus] = useState(null)
  const [filterOrderDate, setFilterOrderDate] = useState(null)
  const [filterDeliveryDate, setFilterDeliveryDate] = useState(null)

  const todayDate = new Date()

  const [filterValuesModal, setFilterValuesModal] = useState({
    distributorName: '',
    distributionID: '',
    orderDate: '', // todayDate,
    orderDeliveryDate: '', // todayDate,
    paymentStatus: '',
  })

  const [showModal, setShowModal] = useState(false)

  const [vizID, setVizID] = useState('')
  const [shopCode, setShopCode] = useState('')
  const [dateOfRequest, setDateOfRequest] = useState(new Date())
  const [tagAgent, setTagAgent] = useState()

  const [shopName, setShopName] = useState('')
  const [amountRequired, setAmountRequired] = useState('')

  const [requestStatus, setRequestStatus] = useState('')
  const [totalTaggedAgents, setTotalTaggedAgents] = useState('')

  const [distributionID, setDistributionID] = useState('')
  const [orderDate, setOrderDate] = useState('')
  const [orderDeliveryDate, setOrderDeliveryDate] = useState('')
  const [numberOfOrders, setNumberOfOrders] = useState('')
  const [amount, setAmount] = useState('')
  const [payStatus, setPayStatus] = useState('')

  const [paymentStatusReportList, setPaymentStatusReportList] = useState([])

  const statusOptions = [
    {
      value: 'Pending',
      label: 'Pending',
    },
    {
      value: 'InProgress',
      label: 'In Progress',
    },
    {
      value: 'Paid',
      label: 'Paid',
    },
    {
      value: 'Failed',
      label: 'Failed',
    },
  ]

  const columns = [
    {
      Header: 'Distribution ID',
      accessor: 'distributionId',
    },
    {
      Header: 'Order Date',
      accessor: 'orderDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Order Delivery Date',
      accessor: 'deliveryDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Number of Orders',
      accessor: 'noOfOrders',
    },
    {
      Header: 'Amount',
      accessor: 'totalAmount',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Payment Status',
      accessor: 'paymentStatus',
    },
    // {
    //   Header: 'Action',
    //   accessor: 'action',
    //   Cell: (row) => (
    //     <div>
    //       <button onClick={() => setShowModal(true)} className=' btn btn-sm'>
    //         <i className='fa fa-eye'></i>
    //         {/* <i className='fa-solid fa-circle-xmark'></i> */}
    //       </button>
    //     </div>
    //   ),
    // },
  ]
  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionID(null)
    setPaymentStatus(null)
    setFilterOrderDate(null)
    setFilterDeliveryDate(null)
    setFilterValuesModal({
      distributorName: '',
      distributionID: '',
      orderDate: '', //todayDate,
      orderDeliveryDate: '', //todayDate,
      paymentStatus: '',
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    })
    // props.resetDropdownList()
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)

    let requestData = {
      DistributorId: event.distributorName == '' ? null : event.distributorName,
      DistCenterId: event.distributionID == '' ? null : event.distributionID,
      DeliveryDate:
        event.orderDeliveryDate == '' ? null : event.orderDeliveryDate,
      OrderDate:
        event.orderDate == ''
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.orderDate)
            )
          )
            .toISOString()
            .split('T')[0],
      PaymentStatus: event.paymentStatus == '' ? null : event.paymentStatus,
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }

    props.getPaymentStatusReport(requestData)
    setShow(false)
    setFilterOrderDate(event.orderDate == '' ? null : event.orderDate)
    setFilterDeliveryDate(
      event.orderDeliveryDate == '' ? null : event.orderDeliveryDate
    )
  }

  const onRowClick = (e, rowData) => {
    history.push(
      `/OrderShopDetails/${rowData.deliveryDate}/${rowData.distCenterId}`
    )
    history.go(
      `/OrderShopDetails/${rowData.deliveryDate}/${rowData.distCenterId}`
    )
  }

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId:
        filterDistributionID == null ? null : filterDistributionID.value,
      DeliveryDate: filterDeliveryDate == null ? null : filterDeliveryDate,
      OrderDate:
        filterOrderDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterOrderDate)
            )
          )
            .toISOString()
            .split('T')[0],
      PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getPaymentStatusReport(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId:
        filterDistributionID == null ? null : filterDistributionID.value,
      DeliveryDate: filterDeliveryDate == null ? null : filterDeliveryDate,
      OrderDate:
        filterOrderDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterOrderDate)
            )
          )
            .toISOString()
            .split('T')[0],
      PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,

      isRegistered: true,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getPaymentStatusReport(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  useEffect(() => {
    let requestData = {
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId:
        filterDistributionID == null ? null : filterDistributionID.value,
      DeliveryDate: filterDeliveryDate == null ? null : filterDeliveryDate,
      OrderDate: filterOrderDate == null ? null : filterOrderDate,
      PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getPaymentStatusReport(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.paymentStatusReport) {
      setPaymentStatusReportList(props.paymentStatusReport)
      if (props.paymentStatusReport.length > 0) {
        setTotalRecords(props.paymentStatusReport[0].totalRecords)
      } else {
        setTotalRecords(0)
      }
    }
  }, [props.paymentStatusReport])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.paymentStatusDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddOrderDistributionCentersOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddOrderDistributionCentersOptions(
        props.paymentStatusDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.paymentStatusDistCentersddlList])

  useEffect(() => {
    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])
  return (
    <div>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <VzPageContainer title='Shop-wise Payment Status Report'>
          <Row>
            <Col md={3} style={{ float: 'right' }}>
              <div ref={ref}>
                <Button
                  onClick={openFilterModal}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-filter' />
                  Filters
                </Button>

                <Overlay
                  show={show}
                  target={target}
                  placement='bottom'
                  container={ref}
                  containerPadding={20}
                  width='430px'
                  style={{ width: '430px' }}
                >
                  <Popover
                    id='popover-contained'
                    style={{ maxWidth: '360px', width: '360px' }}
                  >
                    <Popover.Header as='h3'>Filters</Popover.Header>
                    <Popover.Body>
                      <Formik
                        enableReinitialize
                        initialValues={filterValuesModal}
                        validator={() => ({})}
                        onSubmit={LoadDataByfilter}
                      >
                        {(formik) => (
                          <Form autoComplete='off'>
                            <Row>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor Name'
                                  name='distributorName'
                                  options={addJobDistributorsOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributorName = e.value
                                    setFilterDistributorName({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    setFilterDistributionID(null)
                                  }}
                                  value={filterDistributorName}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution ID'
                                  name='distributionID'
                                  options={addOrderDistributionCentersOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributionID = e.value
                                    setFilterDistributionID({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionID}
                                />
                              </Col>
                              <Row style={{ paddingRight: 0 }}>
                                <Col xs={12} md={12}>
                                  <FormikControl
                                    formik={formik}
                                    control='react-select'
                                    label='Payment Status'
                                    name='paymentStatus'
                                    options={statusOptions}
                                    isClearable={true}
                                    isSearchable={false}
                                    isMulti={false}
                                    handleOnChange={(e) => {
                                      if (!e || !e.value) {
                                        filterValuesModal.paymentStatus = ''
                                      }
                                      setPaymentStatus(e)
                                    }}
                                    value={paymentStatus}
                                  />
                                </Col>
                              </Row>
                              <Row style={{ paddingRight: 0 }}>
                                <Col xs={12} md={12}>
                                  <FormikControl
                                    // formik={formik}
                                    control='date'
                                    label='Order Date'
                                    name='orderDate'
                                    handleOnChange={(value) => {
                                      filterValuesModal.orderDate = value
                                      setFilterOrderDate(value)
                                    }}
                                    value={filterValuesModal.orderDate}
                                  />
                                </Col>
                                {/* <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  // type='date'
                                  label='Order Delivery Date'
                                  name='orderDeliveryDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.orderDeliveryDate = value
                                    setFilterDeliveryDate(value)
                                  }}
                                  value={filterValuesModal.orderDeliveryDate}
                                />
                              </Col> */}
                              </Row>
                            </Row>
                            <div className='row' style={{ marginTop: 20 }}>
                              <div className='form-group'>
                                <Link
                                  onClick={handleResetFilters.bind(
                                    null,
                                    formik.resetForm
                                  )}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'left',
                                  }}
                                >
                                  Reset
                                </Link>
                                <Link
                                  onClick={() => {
                                    setShow(false)
                                    setTarget(null)
                                  }}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Cancel
                                </Link>
                                <div className='float-right'>
                                  <Button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{
                                      marginRight: '10px',
                                      float: 'right',
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <ServerSideDataTable
                data={paymentStatusReportList ? paymentStatusReportList : []}
                columns={columns}
                getTrProps={(event, data) => onRowClick(event, data)}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={totalRecords}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                myIndex={currentIndex}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
            </Col>
          </Row>
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            backdrop='static'
            keyboard={false}
            size='xl'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Shop-wise Payment Status Report
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize
              // initialValues={addOrderModalInitialValue}
              // validationSchema={addOrderFormValidationSchema}
              // validator={() => ({})}
              // onSubmit={submitOrderData}
              >
                {(formik) => (
                  <Form
                    autoComplete='off'
                    encType='multipart/form-data'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                      }
                    }}
                  >
                    <Container>
                      <Row>
                        <Col md={{ span: 3 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              label='Distribution ID'
                              name='distributionID'
                              value={distributionID}
                              control='input'
                              type='distributionID'
                            // handleOnChange={(value) =>
                            //   (currentQRorTillDetails.tillNumber = value)
                            // }
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 3 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              label='Order Date'
                              name='orderDate'
                              value={orderDate}
                              control='date'
                              type='orderDate'
                            // handleOnChange={(value) =>
                            //   (currentQRorTillDetails.tillNumber = value)
                            // }
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 3 }}>
                          <div className='form-group'>
                            <FormikControl
                              // formik={formik}
                              control='date'
                              // type='date'
                              label='Order Delivery Date'
                              name='orderDeliveryDate'
                              value={orderDeliveryDate}

                            // handleOnChange={(value) => {
                            //   filterValuesModal.orderDeliveryDate = value
                            // }}
                            // value={filterValuesModal.orderDeliveryDate}
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 3 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              label='Number of Orders'
                              name='numberOfOrders'
                              value={numberOfOrders}
                              control='input'
                              type='numberOfOrders'
                            // handleOnChange={(value) =>
                            //   (currentQRorTillDetails.tillNumber = value)
                            // }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ span: 6 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              label='Amount'
                              name='amount'
                              value={amount}
                              control='input'
                              type='amount'
                            // handleOnChange={(value) =>
                            //   (currentQRorTillDetails.tillNumber = value)
                            // }
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 6 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              label='Payment Status'
                              name='payStatus'
                              value={payStatus}
                              control='input'
                              type='payStatus'
                            // handleOnChange={(value) =>
                            //   (currentQRorTillDetails.tillNumber = value)
                            // }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} md={12}>
                          <Button
                            className='btn btn-default'
                            style={{ float: 'right', marginTop: '10px' }}
                            onClick={() => setShowModal(false)}
                          >
                            Cancel
                          </Button>
                          <input
                            type='submit'
                            className='btn btn-primary'
                            style={{
                              marginRight: '10px',
                              marginTop: '10px',
                              float: 'right',
                            }}
                            value='Save '
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </VzPageContainer>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    filteredDistributorList: state.ddlList.filteredDistributorList
      ? state.ddlList.filteredDistributorList
      : [],
    distCentersddlList: state.ddlList.selectedDistCentersList
      ? state.ddlList.selectedDistCentersList
      : [],
    paymentStatusDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    paymentStatusReport: state.PaymentStatusReport,
    userRole: storage.get('_appUserRole'),
  }
}
export default connect(mapStateToProps, {
  getDropdownList,
  getPaymentStatusReport,
})(PaymentStatusReport)
