import { GET_TILL_NUMBER_DETAILS_LIST } from '../../../Types'

const TillNumberDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_TILL_NUMBER_DETAILS_LIST:
      return action.payload

    default:
      return state
  }
}
export default TillNumberDetailsReducer
