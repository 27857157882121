import React, { Component, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'

import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../layouts/Input/FormikControl'

import storage from '../../../apis/Storage'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import axios from '../../../apis/Api'

import Moment from 'react-moment'

import {
  downloadJob,
  getTillNumberDetailsReport,
  ExportUser,
} from './TillNumberDetailsActions'
import { isPermittedPage } from '../../../apis/Utility'
import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'

const TillNumberDetails = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterFromDate, setFilterFromDate] = useState(null)
  const [filterToDate, setFilterToDate] = useState(null)
  const [maxToDate, setMaxToDate] = useState(new Date())

  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionID, setFilterDistributionID] = useState(null)
  const [filterDistributionCenterId, setFilterDistributionCenterId] =
  useState(null)
  
  const [filterTillNo, setFilterTillNo] = useState(null)
  const [filterDeliveryUser, setFilterDeliveryUser] = useState(null)
  const [filterUsername, setFilterUsername] = useState(null)

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])

  const [qrCodeList, setQrCodeList] = useState([])
  const [deliveryUserList, setDeliveryUserList] = useState([])
  const [usernameList, setUsernameList] = useState([])

  const [modalShow, setModalShow] = useState(false)
  const [userData, setUserData] = useState(null)

  const printRef = useRef()

  const [filterValuesModal, setFilterValuesModal] = useState({
    distributorId: '',
    distCenterId: '',
    fromDate: '',
    toDate: '',
    deliverymanName: '',
    userName: '',
    tillNo: '',
  })

  const Columns = [
    {
      Header: 'Distribution Center',
      accessor: 'distributionCenter',
    },
    {
      Header: 'User Name',
      accessor: 'username',
    },
    {
      Header: 'Delivery Man Name',
      accessor: 'deliverymanName',
    },
    {
      Header: 'VL Till No',
      accessor: 'qrTill',
    },
    // {
    //   Header: 'QR Image',
    //   accessor: 'appType',
    // },
    {
      Header: 'QR/Till Image',
      accessor: 'id',
      Cell: (row) => {
        if (isPermittedPage('view details', props.actions, props.userRole)) {
          return (
            <div>
              <Link
                onClick={() => ViewUser(row.value)}
                style={{ cursor: 'pointer', width: '106px' }}
                to='#'
              >
                View
              </Link>
            </div>
          );
        }
        return null; // Or some other component or message if not permitted
      }
    },
  ]

  const ViewUser = (id) => {
    let user = props.tillNumberDetails?.report.find((x) => x.id == id)
    setUserData(user)
    setModalShow(true)
  }

  const ExportUser = () => {
    props.ExportUser(userData.username)
  }

  const downloadQR = () => { }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionID(null)
    setFilterDistributionCenterId(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterDeliveryUser(null)
    setFilterTillNo(null)
    setFilterUsername(null)
    setAddJobDistCenterOptions([])
    setDeliveryUserList([])
    setQrCodeList([])
    setFilterValuesModal({
      distributorId: '',
      distCenterId: '',
      fromDate: '',
      toDate: '',
      deliverymanName: '',
      userName: '',
      tillNo: '',
    })
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageSize: pageSize }
    fetchReportData(request)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchReportData(request)
    setShow(false)
  }

  const Download = () => {
    let request = formRequestObject(true)
    props.downloadJob(request)
  }

  function formRequestObject(isExport = false) {
    let requestData = {
      distributionId:
      filterDistributionID == null ? null : filterDistributionID.value,
      distributionCenterId:
      filterDistributionCenterId && filterDistributionCenterId.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      name: filterDeliveryUser == null ? null : filterDeliveryUser.value,
      username: filterUsername == null ? null : filterUsername.value,
      qrTill: filterTillNo == null ? null : filterTillNo.value,
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    return requestData
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getTillNumberDetailsReport(finalRequest)
  }

  const getCenterDeliveryUsers = (id) => {
    axios
      .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
      .then(({ data }) => {
        if (data?.response) {
          setDeliveryUserList(
            data.response.map(function (item) {
              return {
                value: item.id,
                label: item.fullname,
              }
            })
          )

          setUsernameList(
            data.response.map(function (item) {
              return {
                value: item.username,
                label: item.username,
              }
            })
          )

          setQrCodeList(
            data.response.map(function (item) {
              return {
                value: item.qrCode,
                label: item.qrCode,
              }
            })
          )
        }
      })
      .catch(function (error) { })
  }

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData()
  }, [searchText])

  useEffect(() => {
    if (props.tillNumberDetails) {
      setTotalRecords(props.tillNumberDetails?.totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.tillNumberDetails])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddJobDistCenterOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistCenterOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])

  useEffect(() => {
    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])

  useEffect(() => {
    if (filterDistributionCenterId) {
      getCenterDeliveryUsers(filterDistributionCenterId.value)
    }
  }, [filterDistributionCenterId])

  return (
    <VzPageContainer title='VL Till Number Details'>
      <div>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>VL Till Number Details</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor'
                                  name='distributorName'
                                  options={addJobDistributorsOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributorName = e.value
                                    setFilterDistributorName({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    setFilterDistributionID({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    // setFilterTillNo(null)
                                  }}
                                  value={filterDistributorName}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Center'
                                  name='distributionID'
                                  options={addJobDistCenterOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributionCenterId(null)
                                      return
                                    }
                                    filterValuesModal.distributionCenterId =
                                      e.value
                                    setFilterDistributionCenterId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionCenterId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                    // setToDateValueAndMax(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                  maxDate={maxToDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  maxDate={maxToDate}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Deliveryman Name'
                                  name='deliveryUser'
                                  options={deliveryUserList}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.deliveryUser = e.value
                                    setFilterDeliveryUser({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDeliveryUser}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='User Name'
                                  name='userName'
                                  options={usernameList}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.userName = e.value
                                    setFilterUsername({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterUsername}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Till No'
                                  name='tillNo'
                                  options={qrCodeList}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.tillNo = e.value
                                    setFilterTillNo({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterTillNo}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>

          {isPermittedPage('Export', props.actions, props.userRole) &&
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={Download}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-download me-1' />
                Export
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col>
            <ServerSideDataTable
              data={props.tillNumberDetails?.report ?? []}
              columns={Columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Formik
          enableReinitialize
          initialValues={userData}
        // validationSchema={validationSchema}
        // onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Vizlink Till Number Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={8} md={8}>
                      <Row>
                        <Col xs={6} md={6}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='input'
                              type='distributionCenter'
                              label='Distribution Center'
                              name='distributionCenter'
                              value={userData?.distributionCenter}
                              isReadOnly={true}
                            />
                          </div>
                        </Col>
                        <Col xs={6} md={6}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='input'
                              type='deliverymanName'
                              label='Delivery Man Name'
                              name='deliverymanName'
                              value={userData?.deliverymanName}
                              isReadOnly={true}
                            />
                          </div>
                        </Col>
                        <Col xs={6} md={6}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='input'
                              type='qrTill'
                              label='VL Till No.'
                              name='qrTill'
                              value={userData?.qrTill}
                              isReadOnly={true}
                            />
                          </div>
                        </Col>
                        <Col xs={6} md={6}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='input'
                              type='username'
                              label='User Name'
                              name='username'
                              value={userData?.username}
                              isReadOnly={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col style={{ textAlign: 'center' }}>
                      <label style={{ marginBottom: 0 }}>QR/Till Image</label>
                      <div>
                        <QRCode
                          ref={printRef}
                          size={256}
                          style={{
                            height: 'auto',
                            maxWidth: '50%',
                            width: '50%',
                          }}
                          value={userData.qrTill}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {/* {isView ? null : ( */}
                <>
                  <Button
                    className='btn btn-default'
                    onClick={() => ExportUser()}
                  >
                    Export
                  </Button>
                  {/* <input
                    // type='submit'
                    className='btn btn-primary'
                    value='Print'
                  /> */}
                  {/* <Button
                    // className='btn btn-primary'
                    onClick={() => downloadQR()}
                  >
                    Print
                  </Button> */}
                  <ReactToPrint
                    trigger={() => <Button>Print</Button>}
                    content={() => printRef.current}
                    documentTitle='QR/Till'
                    bodyClass='print-qr'
                  />
                </>
                {/* )} */}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </VzPageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],

    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    tillNumberDetails: state.TillNumberDetails,
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  downloadJob,
  getTillNumberDetailsReport,
  ExportUser,
})(TillNumberDetails)
