import { useEffect, useState, useRef } from 'react'
import { Link, Redirect } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css'
import FormikControl from '../../../layouts/Input/FormikControl'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from '../../../apis/Api'
import { getVizShops } from './VizShopsListAction'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { Formik, Form } from 'formik'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { toast } from 'react-toastify'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'
import { tagAgentShop } from '../../VizlinkAgents/OnBoardingAgentsList/OnBoardingAgentsListAction'
import history from '../../../history'

const VizLinkShopsList = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const [showDistributorViewModal, setShowDistributorViewModal] =
    useState(false)
  const [distributorCompleteData, setDistributorCompleteData] = useState(null)
  const [vizShopsList, setVizShopsList] = useState([])
  const [filterValuesModal, setFilterValuesModal] = useState({
    distShopCode: '',
    shopCode: '',
    shopName: '',
  })
  const [distShopCode, setDistShopCode] = useState(null)
  const [shopCode, setShopCode] = useState(null)
  const [shopName, setShopName] = useState(null)

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)

  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [showAgent, setShowAgent] = useState(false)
  const [targetAgent, setTargetAgent] = useState(null)
  const refAgent = useRef(null)
  const [showAgentViewModal, setShowAgentViewModal] = useState(false)
  const [agentsList, setAgentsList] = useState([])
  const [searchTextAgent, setSearchTextAgent] = useState('')
  const [selectedShopId, setSelectedShopId] = useState(null)

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getVizShopsData(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getVizShopsData(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getVizShopsData(requestData)
  }, [searchText])
  useEffect(() => {
    if (props.vizShopsList) {
      setVizShopsList(props.vizShopsList)
      if (props.vizShopsList.length > 0) {
        setTotalRecords(props.vizShopsList[0].totalRecords)
      } else {
        setTotalRecords(0)
      }
    }
  }, [props.vizShopsList])
  const LoadDataByfilter = (event) => {
    if (!event) {
      return
    }
    // if (!shopCode && !shopName) {
    //   toast.error('Please select Vizlink Shop Code or Shop Name')
    //   return
    // }
    props.getVizShopsData({
      DistShopCode: distShopCode,
      ShopCode: shopCode,
      ShopName: shopName,
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
    })
    setShow(false)
  }

  const handleResetFilters = (resetForm) => {
    // props.getVizShopsData({
    //   ShopCode: '',
    //   ShopName: '',
    //   pageSize: currentPageSize,
    //   pageNo: currentPageNo,
    //   searchText: searchText,
    // })
    setShopName('')
    setShopCode('')
    setDistShopCode('')
    resetForm()
    setFilterValuesModal({
      distShopCode: '',
      shopCode: '',
      shopName: '',
    })
  }

  const OpenFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const ViewVizShopDetails = (id) => {
    props.showLoadingBar()
    axios
      .get(`api/VizShop/` + id)
      .then(({ data }) => {
        setDistributorCompleteData(data[0])
        setShowDistributorViewModal(true)
        props.hideLoadingBar()
      })
      .catch(function (error) {
        setDistributorCompleteData(null)
        toast.error('Error while loading the data')
        props.hideLoadingBar()
      })
    // const responseData = await getDistributorCompleteData(id);
  }

  const ViewAgentsList = (id) => {
    if (id != null) {
      setSelectedShopId(id)
      setShowAgentViewModal(true)
    }
    let request = {}

    if (searchTextAgent != '' && searchTextAgent != null) {
      request = {
        shopId: id == null ? selectedShopId : id,
        searchText: searchTextAgent,
      }
    } else {
      request = { shopId: id == null ? selectedShopId : id }
      if (request.shopId == null) return
    }
    props.showLoadingBar()
    axios
      .post(`api/TaggedShop/GetTaggedAgentsListByShopId`, request)
      .then(({ data }) => {
        setAgentsList(data.data)

        props.hideLoadingBar()
      })
      .catch(function (error) {
        setAgentsList([])
        toast.error('Error while loading the data')
        props.hideLoadingBar()
      })
  }

  const tagAgentShop = async (id) => {
    let parameters = {
      vizShopId: selectedShopId,
      vizAgentId: id,
    }
    await props.tagAgentShop(parameters)
    let index = agentsList.findIndex((x) => x.id == id)
    let agents = [...agentsList]
    agents[index].isTagged = !agents[index].isTagged
    setAgentsList([...agents])
  }

  const NavigateToEditShop = (id) => {
    history.push(`/EditShop/${id}`)
    history.go(`/EditShop/${id}`)
  }

  var columns = [
    {
      Header: 'VizShop Code',
      accessor: 'vizShopCode',
      sortType: 'basic',
    },
    {
      Header: 'Shop Name',
      accessor: 'vizShopName',
      filter: 'includes', // new
    },
    {
      Header: 'CNIC',
      accessor: 'cnic',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: "Contact No's",
      accessor: 'contactNo',
    },

    // {
    //   Header: 'Action',
    //   accessor: 'id',
    //   Cell: (row) => (
    //     <div style={{ width: '125px' }}>
    //       <Button
    //         variant='primary'
    //         onClick={() => ViewVizShopDetails(row.value)}
    //         className='btn btn-primary'
    //       >
    //         View Details
    //       </Button>
    //     </div>
    //   ),
    // },
  ]

  if (isPermittedPage('View Details', props.actions, props.userRole)) {
    let viewDetails = {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '125px' }}>
          <Button
            variant='primary'
            onClick={() => ViewVizShopDetails(row.value)}
            className='btn btn-primary'
          >
            View Details
          </Button>
        </div>
      ),
    }
    columns.push(viewDetails)
  }

  const distributorColumns = [
    {
      Header: 'Distributor Name',
      accessor: 'distributor',
    },
    {
      Header: 'Distributor Id',
      accessor: 'distributionCenter',
    },
    {
      Header: 'Shop Id',
      accessor: 'shopCode',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Average Sales',
      accessor: 'avgMonthlySales',
    },
    {
      Header: 'Payment Mode',
      accessor: 'paymentMode',
    },
    // {
    //   Header: 'Tagged Agents',
    //   accessor: 'taggedAgents',
    // },
    {
      Header: 'RSO Name',
      accessor: 'rsoName',
    },
  ]

  const agentsColumns = [
    {
      Header: 'Agent Code',
      accessor: 'vizAgentCode',
    },
    {
      Header: 'Business Name',
      accessor: 'agentBusinessName',
    },
    {
      Header: 'Owner Name',
      accessor: 'agentOwnerName',
    },
    {
      Header: 'Owner Cnic',
      accessor: 'agentOwnerNic',
    },
    {
      Header: 'Type',
      accessor: 'agentType',
    },
    {
      Header: 'Contact',
      accessor: 'contactNumber',
    },
    {
      Header: 'Decision',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <Link
            onClick={() => tagAgentShop(row.value)}
            style={{ cursor: 'pointer', width: '106px' }}
            to='#'
          >
            {agentsList.filter((x) => x.id == row.value)[0].isTagged == false
              ? 'Tag'
              : 'Untag'}
          </Link>
        </div>
      ),
    },
  ]

  useEffect(() => {
    // if (isPermittedPage('View', props.actions, props.userRole)) {
    //   let viewDetails = {
    //     Header: 'Action',
    //     accessor: 'id',
    //     Cell: (row) => (
    //       <div style={{ width: '125px' }}>
    //         <Button
    //           variant='primary'
    //           onClick={() => ViewVizShopDetails(row.value)}
    //           className='btn btn-primary'
    //         >
    //           View Details
    //         </Button>
    //       </div>
    //     ),
    //   }
    //   columns.push(viewDetails)
    // }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      ViewAgentsList(null)
    }, 800)

    return () => clearTimeout(timer)
  }, [searchTextAgent])

  return (
    <div>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <>
          <VzPageContainer title='VizLink Shops List'>
            <Row>
              <Col md={{ span: 3 }} style={{ float: 'left' }}>
                <h4>
                  <b>VizLink Shops List</b>
                </h4>
              </Col>
              <Col md={{ span: 3, offset: 6 }} style={{ float: 'right' }}></Col>
            </Row>
            <Row>
              <Col md={12} style={{ float: 'right' }}>
                <Row>
                  <Col md={3} style={{ float: 'right' }}>
                    <div ref={ref}>
                      <Button
                        onClick={OpenFilterModal}
                        className='m-3'
                        variant='outline-primary'
                      >
                        <i className='fa fa-filter' />
                        Filters
                      </Button>

                      <Overlay
                        show={show}
                        target={target}
                        placement='bottom'
                        container={ref}
                        containerPadding={20}
                        width='430px'
                        style={{ width: '430px' }}
                      >
                        <Popover id='popover-contained' width='400'>
                          <Popover.Header as='h3'>Filters</Popover.Header>
                          <Popover.Body>
                            <Formik
                              enableReinitialize
                              initialValues={filterValuesModal}
                              validator={() => ({})}
                              onSubmit={LoadDataByfilter}
                            >
                              {(formik) => (
                                <Form autoComplete='off'>
                                  <Row>
                                    <Col xs={12} md={12}>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='DistShopCode'
                                        label='Dist. Shop Code'
                                        name='DistShopCode'
                                        handleOnChange={(value) => {
                                          setDistShopCode(value)
                                          filterValuesModal.distShopCode = value
                                        }}
                                        value={distShopCode}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12} md={12}>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='ShopCode'
                                        label='Viz. Shop Code'
                                        name='ShopCode'
                                        handleOnChange={(value) => {
                                          setShopCode(value)
                                          filterValuesModal.shopCode = value
                                        }}
                                        value={shopCode}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12} md={12}>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='ShopName'
                                        label='Shop Name'
                                        name='ShopName'
                                        handleOnChange={(value) => {
                                          setShopName(value)
                                          filterValuesModal.shopName = value
                                        }}
                                        value={shopName}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={12} md={12}>
                                      <div
                                        className='form-group'
                                        style={{ margin: '1rem 0rem' }}
                                      >
                                        <Link
                                          onClick={handleResetFilters.bind(
                                            null,
                                            formik.resetForm
                                          )}
                                          to='#'
                                          className='active'
                                          style={{
                                            padding: '0px',
                                            marginTop: '10px',
                                            float: 'left',
                                          }}
                                        >
                                          Reset
                                        </Link>
                                        <Link
                                          onClick={() => {
                                            setShow(false)
                                            setTarget(null)
                                            // LoadDataByfilter({})
                                          }}
                                          to='#'
                                          className='active'
                                          style={{
                                            padding: '0px',
                                            marginTop: '10px',
                                            float: 'right',
                                          }}
                                        >
                                          Cancel
                                        </Link>
                                        <div className='float-right'>
                                          <Button
                                            type='submit'
                                            className='btn btn-primary'
                                            style={{
                                              marginRight: '10px',
                                              float: 'right',
                                            }}
                                          >
                                            Search
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              )}
                            </Formik>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                    </div>
                  </Col>
                </Row>
                {/* <CustomDataTable
              data={vizShopsList ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
              IsPagingEnable={true}
            /> */}
                <ServerSideDataTable
                  data={vizShopsList ? vizShopsList : []}
                  columns={columns ? columns : []}
                  getTrProps={(event, data) => null}
                  goToPreviousPage={(val) => getData(val)}
                  goToNextPage={(val) => getData(val)}
                  totalRecords={totalRecords}
                  pageSizeChange={(size) => pageSizeChanged(size)}
                  myPageSize={currentPageSize}
                  myIndex={currentIndex}
                  pageNo={currentPageNo}
                  searchChanged={searchChanged}
                />
              </Col>
            </Row>
          </VzPageContainer>
          <Modal
            show={showDistributorViewModal}
            onHide={() => setShowDistributorViewModal(false)}
            size='xl'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Vizlink Shop Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>VIZ ID:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>
                          {distributorCompleteData?.vizShopCode ?? '----'}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>Shop Name:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>
                          {distributorCompleteData?.vizShopName ?? '----'}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>CNIC Number:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>{distributorCompleteData?.cnic ?? '----'}</label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>Address:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>
                          {' '}
                          {distributorCompleteData?.address ?? '----'}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>Geo Tag:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>
                          {`${distributorCompleteData?.lat ?? '00.00'}, ${distributorCompleteData?.long ?? '00.00'
                            }`}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>Contact No:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>
                          {distributorCompleteData?.contactNumbers ?? '--'}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>Agent Status:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>
                          {distributorCompleteData?.agentStatus ?? '--'}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                        <label>
                          <strong>Agent Count:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <label>
                          {distributorCompleteData?.agentCount ?? '--'}{' '}
                          {isPermittedPage('Tagged Agents', props.actions, props.userRole) &&
                            <button
                              title='View Agent Details'
                              onClick={() =>
                                ViewAgentsList(distributorCompleteData?.id)
                              }
                              className=' btn btn-sm ms-2'
                            >
                              <i className='fas fa-eye'>  Tagged Agents</i>
                            </button>
                          }
                        </label>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12}>
                    <Row>
                      <Col xs={12} md={3} style={{ textAlign: 'right' }}>
                        <label style={{ paddingRight: '30px' }}>
                          <strong>Wallet Details:</strong>
                        </label>
                      </Col>
                      <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                        <ul>
                          {distributorCompleteData?.shopWallets.length > 0
                            ? distributorCompleteData?.shopWallets?.map(
                              (item, index) => (
                                <li
                                  style={{ listStyleType: 'none' }}
                                >{`Wallet Number (${item.mfiName}):  ${item.walletNumber}`}</li>
                              )
                            )
                            : 'Empty'}
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {isPermittedPage('Distributors Profile', props.actions, props.userRole) &&
                    <Col>
                      <h5 style={{ color: '#EA5F2D' }}>Distributors Profile</h5>
                      <hr style={{ color: '#EA5F2D', width: '50%' }} />
                    </Col>
                  }
                  {isPermittedPage('Edit', props.actions, props.userRole) && (
                    <Col className='text-right'>
                      <Button
                        variant='primary'
                        onClick={() =>
                          NavigateToEditShop(distributorCompleteData?.id)
                        }
                        className='btn btn-primary'
                      >
                        Edit
                      </Button>
                    </Col>
                  )}
                </Row>
                {isPermittedPage('Distributors Profile', props.actions, props.userRole) &&
                  <Row>
                    <CustomDataTable
                      data={distributorCompleteData?.distShopDetails ?? []}
                      columns={distributorColumns}
                      getTrProps={(event, data) => null}
                      IsPagingEnable={false}
                    />
                  </Row>
                }
              </Container>
            </Modal.Body>
          </Modal>

          <Modal
            show={showAgentViewModal}
            onHide={() => {
              setShowAgentViewModal(false)
              setSearchTextAgent(null)
              setAgentsList([])
            }}
            size='xl'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Tagged Agents
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <Row>
                  <Col md={6} style={{ marginLeft: 'auto' }}>
                    <Formik
                      enableReinitialize
                      initialValues={[]}
                      validationSchema={null}
                      onSubmit={null}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='input'
                              type='search'
                              label='Search Record'
                              name='search'
                              value={searchTextAgent}
                              handleOnChange={(e) => setSearchTextAgent(e)}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
                <Row>
                  <CustomDataTable
                    IsSearchEnable={false}
                    data={agentsList ?? []}
                    columns={agentsColumns}
                    getTrProps={(event, data) => null}
                    IsPagingEnable={true}
                  />
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    showLoadingBar: () => {
      dispatch(showLoading())
    },
    hideLoadingBar: () => {
      dispatch(hideLoading())
    },
    getDropdownListData: () => {
      dispatch(getDropdownList())
    },
    getVizShopsData: (requestData) => {
      dispatch(getVizShops(requestData))
    },
    tagAgentShop: (requestData) => {
      dispatch(tagAgentShop(requestData))
    },
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    vizShopsList: state.vizShopsList ? state.vizShopsList : [],
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VizLinkShopsList)
