import {
  GET_DISTRIBUTORPRINCIPALS,
  ADD_DISTRIBUTORPRINCIPALS,
  UPDATE_DISTRIBUTORPRINCIPALS,
  DELETE_DISTRIBUTORPRINCIPALS,
  EMPTY_DISTRIBUTORPRINCIPALS,
} from "../../../Types";

const distributorPrincipalsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DISTRIBUTORPRINCIPALS:
      return [...action.payload];
    case ADD_DISTRIBUTORPRINCIPALS:
      return [...action.payload];
    case UPDATE_DISTRIBUTORPRINCIPALS:
      return [...state, action.payload];
    case DELETE_DISTRIBUTORPRINCIPALS:
      return state.filter((e) => e.id !== action.payload);
    case EMPTY_DISTRIBUTORPRINCIPALS:
      return [];
    default:
      return state;
  }
};
export default distributorPrincipalsReducer;
