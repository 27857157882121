import React, { Component, useEffect, useState, useRef } from 'react'
import { VzPageContainer } from '../../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import ReactSelect from 'react-select'
import { useParams } from 'react-router-dom'

import FormikControl from '../../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import CustomDataTable from '../../../../layouts/DataTables/CustomDataTable'
import { Link, Redirect } from 'react-router-dom'

import { connect } from 'react-redux'
import { getDropdownList } from '../../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_DISTRIBUTIONCENTER_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../../Types'
import { addOrder, deleteOrder, getOrders } from '../ManageDailyOrdersActions'
import {
  getManageOrderDetails,
  deleteManageOrderDetails,
} from './ManageOrderDetailsActions'
import ExcelIcon from '../../../../assets/Images/Distributors/excel-icon.png'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { confirmAlert } from 'react-confirm-alert'
import UploadOrdersTemplate from '../../../../assets/Templates/UploadOrdersTemplate.xlsx'
import Moment from 'react-moment'
import ServerSideDataTable from '../../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../../apis/Storage'
import { isPermittedPage } from '../../../../apis/Utility'
import { formatDate } from '../../../../utilities/FUNCTIONS'
const ManageOrderDetails = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))

  const [currentPageNo, setCurrentPageNo] = useState(0)

  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [ordersList, setOrdersList] = useState([])
  const { deliveryDate, distCenterId } = useParams()
  const { obid, orderDate: createdDate } = JSON.parse(
    localStorage.getItem('_manageOrderDetails')
  )

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [
    addOrderDistributionCentersOptions,
    setAddOrderDistributionCentersOptions,
  ] = useState(null)
  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionID, setFilterDistributionID] = useState(null)

  const [paymentStatus, setPaymentStatus] = useState(null)
  const [filterOrderDate, setFilterOrderDate] = useState(null)
  const [filterDeliveryDate, setFilterDeliveryDate] = useState(deliveryDate)
  const todayDate = new Date()

  const [filterValuesModal, setFilterValuesModal] = useState({
    distributorName: '',
    distributionID: distCenterId,
    orderDate: '',
    orderDeliveryDate: deliveryDate,
    paymentStatus: '',
    obid: obid,
  })

  const [addOrderDistributorsOptions, setAddOrderDistributorsOptions] =
    useState(null)

  const [addOrderDistributorName, setAddOrderDistributorName] = useState(null)
  const [addOrderDistributionCenter, setAddOrderDistributionCenter] =
    useState(null)
  const [uploadDataModalShow, setUploadDataModalShow] = useState(false)
  const [dataSheetSelectedFile, setDataSheetSelectedFile] = useState(null)

  const [orderDeliveryDate, setOrderDeliveryDate] = useState(new Date())
  const [orderDate, setOrderDate] = useState(new Date())

  const [addOrderModalInitialValue, setAddOrderModalInitialValue] = useState({
    distributorName: '',
    distributionCenter: '',
    OrderDataFile: null,
  })

  const addOrderFormValidationSchema = Yup.object().shape({
    distributorName: Yup.string().required('Distributor Name is required'),
    distributionCenter: Yup.string().required(
      'Distribution Center is required'
    ),
  })

  const statusOptions = [
    {
      value: 'Pending',
      label: 'Pending',
    },
    {
      value: 'InProgress',
      label: 'In Progress',
    },
    {
      value: 'Paid',
      label: 'Paid',
    },
    {
      value: 'Failed',
      label: 'Failed',
    },
  ]
  const columns = [
    {
      Header: 'Shop Viz ID',
      accessor: 'vizlinkId',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Date of Request',
      accessor: 'dateOfRequest',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Order Amount',
      accessor: 'amount',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    // {
    //   Header: 'Payment Status',
    //   accessor: 'paymentStatus',
    // },
    // {
    //   Header: 'Tagged Agents',
    //   accessor: 'taggedAgents',
    // },
    isPermittedPage('Delete', props.actions, props.userRole) && {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          {row?.data?.find((x) => x.id == row.value)?.isDeleteable == true && (
            <button
              onClick={() => Delete(row.value)}
              className='btn btn-danger'
              style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
            >
              <i className='fa fa-trash'></i>
            </button>
          )}
        </div>
      ),
    },
  ]
  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionID(null)
    setPaymentStatus(null)
    setFilterOrderDate(null)
    setFilterDeliveryDate(deliveryDate)
    setFilterValuesModal({
      distributorName: '',
      distributionID: '',
      orderDate: '',
      orderDeliveryDate: deliveryDate,
      paymentStatus: '',
      obid: obid,
    })
    // props.resetDropdownList()
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)

    props.getManageOrderDetails({
      DistributorId: event.distributorName == '' ? null : event.distributorName,
      DistCenterId: distCenterId,
      DeliveryDate:
        event.orderDeliveryDate == '' ? null : event.orderDeliveryDate,
      OrderDate:
        event.orderDate == '' ? createdDate : formatDate(event.orderDate),
      PaymentStatus: event.paymentStatus == '' ? null : event.paymentStatus,
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
      obid: obid,
    })
    setShow(false)
  }

  const OpenModelForAddOrder = (isOpen) => {
    setUploadDataModalShow(isOpen)
    setAddOrderModalInitialValue({
      distributorName: '',
      distributionCenter: '',
      orderDate: '',
      orderDeliveryDate: '',
      paymentStatus: '',
    })
    setDataSheetSelectedFile(null)
    setAddOrderDistributionCenter(null)
    setAddOrderDistributorName(null)
  }

  const submitOrderData = (data) => {
    if (
      dataSheetSelectedFile != null &&
      addOrderDistributorName &&
      addOrderDistributionCenter
    ) {
      props.addOrder({
        distributorId: addOrderDistributorName.value, //data.distributorId,
        DistCenterId: addOrderDistributionCenter.value,
        DataFile: dataSheetSelectedFile,
      })
      setUploadDataModalShow(false)
    } else {
      toast.error('Please select Order Data Sheet')
    }
  }

  const validateDataSheetFile = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return
    }
    if (!image.name.match(/\.(xlsx|xls)$/)) {
      toast.error('You can only upload file of type (xlsx, xls)')
      event.target.value = null
      return
    } else {
      setDataSheetSelectedFile(event.target.files[0])
    }
  }

  const Delete = (id) => {
    // confirmAlert({
    //   title: 'Confirm to Delete',
    //   message: 'Are you sure you want to delete this?',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: () => props.deleteOrder(id),
    //     },
    //     {
    //       label: 'No',
    //     },
    //   ],
    // })

    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    // props.deleteManageOrderDetails(id)
                    DeleteRecord(id)

                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  useEffect(() => {
    // if (deliveryDate) {
    //   props.getManageOrderDetails({
    //     DeliveryDate: deliveryDate,
    //     DistCenterId: distCenterId,
    //   })
    // }
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId: distCenterId,
      DeliveryDate:
        filterDeliveryDate == null ? deliveryDate : filterDeliveryDate,
      OrderDate:
        filterOrderDate == null ? createdDate : formatDate(filterOrderDate),
      PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
      obid: obid,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getManageOrderDetails(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId: distCenterId,
      DeliveryDate:
        filterDeliveryDate == null ? deliveryDate : filterDeliveryDate,
      OrderDate:
        filterOrderDate == null ? createdDate : formatDate(filterOrderDate),
      PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
      obid: obid,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getManageOrderDetails(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const DeleteRecord = async (id) => {
    await props.deleteManageOrderDetails(id)

    if (totalRecords > 5 && ordersList.length == 1) {
      let previousPage = currentPageNo > 0 ? currentPageNo - 1 : 0
      setCurrentPageNo(previousPage)
      let requestData = {
        pageSize: currentPageSize,
        pageNo: previousPage,
        searchText: searchText,
        DistributorId:
          filterDistributorName == null ? null : filterDistributorName.value,
        DistCenterId: distCenterId,
        DeliveryDate:
          filterDeliveryDate == null ? deliveryDate : filterDeliveryDate,
        OrderDate:
          filterOrderDate == null ? createdDate : formatDate(filterOrderDate),
        PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
        obid: obid,
      }
      if (userRights != null && userRights?.userType == 'DistributorUser') {
        requestData = {
          ...requestData,
          assignedDistCenters: userRights?.distCenterIds,
        }
      }
      props.getManageOrderDetails(requestData)
    } else {
      let requestData = {
        pageSize: currentPageSize,
        pageNo: currentPageNo,
        searchText: searchText,
        DistributorId:
          filterDistributorName == null ? null : filterDistributorName.value,
        DistCenterId: distCenterId,
        DeliveryDate:
          filterDeliveryDate == null ? deliveryDate : filterDeliveryDate,
        OrderDate:
          filterOrderDate == null ? createdDate : formatDate(filterOrderDate),
        PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
        obid: obid,
      }
      if (userRights != null && userRights?.userType == 'DistributorUser') {
        requestData = {
          ...requestData,
          assignedDistCenters: userRights?.distCenterIds,
        }
      }
      props.getManageOrderDetails(requestData)
    }
  }

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      DistributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      DistCenterId: distCenterId,
      DeliveryDate:
        filterDeliveryDate == null ? deliveryDate : filterDeliveryDate,
      OrderDate:
        filterOrderDate == null ? createdDate : formatDate(filterOrderDate),
      PaymentStatus: paymentStatus == null ? null : paymentStatus.value,
      obid: obid,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getManageOrderDetails(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.ManageOrderDetails) {
      setOrdersList(props.ManageOrderDetails)
      if (props.ManageOrderDetails.length > 0) {
        setTotalRecords(props.ManageOrderDetails[0].totalRecords)
      } else {
        setTotalRecords(0)
      }
    }
  }, [props.ManageOrderDetails])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.paymentStatusDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddOrderDistributionCentersOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddOrderDistributionCentersOptions(
        props.paymentStatusDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.paymentStatusDistCentersddlList])

  useEffect(() => {
    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])
  return (
    <div>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <VzPageContainer title='New Orders Form'>
          <Row>
            <Col md={3} style={{ float: 'right' }}>
              <div ref={ref}>
                <Button
                  onClick={openFilterModal}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-filter' />
                  Filters
                </Button>

                <Overlay
                  show={show}
                  target={target}
                  placement='bottom'
                  container={ref}
                  containerPadding={20}
                  width='430px'
                  style={{ width: '430px' }}
                >
                  <Popover id='popover-contained' width='400'>
                    <Popover.Header as='h3'>Filters</Popover.Header>
                    <Popover.Body>
                      <Formik
                        enableReinitialize
                        initialValues={filterValuesModal}
                        validator={() => ({})}
                        onSubmit={LoadDataByfilter}
                      >
                        {(formik) => (
                          <Form autoComplete='off'>
                            <Row>
                              {/* <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor Name'
                                  name='distributorName'
                                  options={addJobDistributorsOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributorName = e.value
                                    setFilterDistributorName({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    setFilterDistributionID(null)
                                  }}
                                  value={filterDistributorName}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution ID'
                                  name='distributionID'
                                  options={addOrderDistributionCentersOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributionID = e.value
                                    setFilterDistributionID({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionID}
                                />
                              </Col> */}
                              <Row style={{ paddingRight: 0 }}>
                                <Col xs={12} md={12}>
                                  <FormikControl
                                    formik={formik}
                                    control='react-select'
                                    label='Payment Status'
                                    name='paymentStatus'
                                    options={statusOptions}
                                    isSearchable={true}
                                    handleOnChange={(e) => {
                                      if (!e || !e.value) {
                                        return
                                      }
                                      filterValuesModal.paymentStatus = e.value
                                      setPaymentStatus({
                                        label: e.label,
                                        value: e.value,
                                      })
                                    }}
                                    value={paymentStatus}
                                  />
                                </Col>
                              </Row>
                              <Row style={{ paddingRight: 0 }}>
                                <Col xs={12} md={12}>
                                  <FormikControl
                                    // formik={formik}
                                    control='date'
                                    label='Order Date'
                                    name='orderDate'
                                    handleOnChange={(value) => {
                                      filterValuesModal.orderDate = value
                                      setFilterOrderDate(value)
                                    }}
                                    value={filterValuesModal.orderDate}
                                  />
                                </Col>
                                {/* <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  // type='date'
                                  label='Delivery Date'
                                  name='orderDeliveryDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.orderDeliveryDate = value
                                    setFilterDeliveryDate(value)
                                  }}
                                  value={filterValuesModal.orderDeliveryDate}
                                />
                              </Col> */}
                              </Row>
                            </Row>
                            <div className='row' style={{ marginTop: 20 }}>
                              <div className='form-group'>
                                <Link
                                  onClick={handleResetFilters.bind(
                                    null,
                                    formik.resetForm
                                  )}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'left',
                                  }}
                                >
                                  Reset
                                </Link>
                                <Link
                                  onClick={() => {
                                    setShow(false)
                                    setTarget(null)
                                  }}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Cancel
                                </Link>
                                <div className='float-right'>
                                  <Button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{
                                      marginRight: '10px',
                                      float: 'right',
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <ServerSideDataTable
                data={ordersList ? ordersList : []}
                columns={columns}
                getTrProps={(event, data) => null}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={totalRecords}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
            </Col>
          </Row>
          <Modal
            show={uploadDataModalShow}
            onHide={() => setUploadDataModalShow(false)}
            backdrop='static'
            keyboard={false}
            size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Add New Orders Data
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize
                initialValues={addOrderModalInitialValue}
                validationSchema={addOrderFormValidationSchema}
                onSubmit={submitOrderData}
              >
                {(formik) => (
                  <Form
                    // autoComplete='off'
                    encType='multipart/form-data'
                    // onKeyDown={(e) => {
                    //   if (e.key === 'Enter') {
                    //     e.preventDefault()
                    //   }
                    // }}
                  >
                    <Container>
                      <Row>
                        <Col md={{ span: 5 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              // isSearchable={true}
                              // isClearable={true}
                              label='Distributor'
                              name='distributorName'
                              options={addOrderDistributorsOptions}
                              value={addOrderDistributorName}
                              handleOnChange={(option) => {
                                if (!option || !option.value) {
                                  setAddOrderDistributorName(null)
                                  addOrderModalInitialValue.distributorName = ''
                                } else {
                                  setAddOrderDistributorName(option)
                                  addOrderModalInitialValue.distributorName =
                                    option.label
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 7 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              // isSearchable={true}
                              // isClearable={true}
                              label='Distribution Center'
                              name='distributionCenter'
                              options={props.addOrderDistCentersddlList}
                              value={addOrderDistributionCenter}
                              handleOnChange={(option) => {
                                if (!option || !option.value) {
                                  setAddOrderDistributionCenter(null)
                                  addOrderModalInitialValue.distributionCenter =
                                    ''
                                } else {
                                  setAddOrderDistributionCenter(option)
                                  addOrderModalInitialValue.distributionCenter =
                                    option.value
                                }
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={{ span: 5 }}>
                          <label style={{ width: '100%' }}>
                            <b>Principal:</b>
                            {addOrderDistributionCenter
                              ? addOrderDistributionCenter.principalName
                              : 'Not Selected'}
                          </label>
                        </Col>
                        <Col xs={12} md={{ span: 7, offset: 0 }}>
                          <div className='form-group'>
                            <div>
                              <div>
                                Select Data Sheet File (Only Excel file)
                              </div>
                              {dataSheetSelectedFile && (
                                <div>
                                  <img
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'100px'}
                                    height={'100px'}
                                    src={ExcelIcon}
                                  />
                                  <br />
                                  <button
                                    className='btn btn-info'
                                    style={{
                                      marginLeft: '10px',
                                      marginTop: '10px',
                                      marginBottom: '20px',
                                    }}
                                    onClick={() => {
                                      // addOrderModalInitialValue.OrderDataFile = null
                                      setDataSheetSelectedFile(null)
                                    }}
                                  >
                                    <i className='fa fa-close' />
                                    Remove
                                  </button>
                                </div>
                              )}
                              {!dataSheetSelectedFile && (
                                <div>
                                  <br />
                                  <input
                                    type='file'
                                    name='dataSheetSelectedFile'
                                    onChange={(event) =>
                                      validateDataSheetFile(event)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col xs={12} md={6}>
                        <FormikControl
                          formik={formik}
                          control='date'
                          label='Order Date'
                          name='orderDate'
                          handleOnChange={(value) => {
                            addOrderModalInitialValue.orderDate = value
                          }}
                          value={addOrderModalInitialValue.orderDate}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <FormikControl
                          formik={formik}
                          control='date'
                          // type='date'
                          label='Order Delivery Date'
                          name='orderDeliveryDate'
                          handleOnChange={(value) => {
                            addOrderModalInitialValue.orderDeliveryDate = value
                          }}
                          value={addOrderModalInitialValue.orderDeliveryDate}
                        />
                      </Col>
                    </Row> */}

                      <Row>
                        <Col xs={12} md={12}>
                          <Button
                            className='btn btn-default'
                            style={{ float: 'right', marginTop: '10px' }}
                            onClick={() => setUploadDataModalShow(false)}
                          >
                            Cancel
                          </Button>
                          <input
                            type='submit'
                            className='btn btn-primary'
                            style={{
                              marginRight: '10px',
                              marginTop: '10px',
                              float: 'right',
                            }}
                            value='Save '
                          />

                          <a
                            href={`${UploadOrdersTemplate}`}
                            download='OrdersTemplateFile'
                            className='active'
                            style={{
                              padding: '0px',
                              marginTop: '20px',
                              float: 'left',
                            }}
                          >
                            Download Template
                          </a>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </VzPageContainer>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    ManageDailyOrders: state.ManageDailyOrders,
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],

    addOrderDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],

    filteredDistributorList: state.ddlList.filteredDistributorList
      ? state.ddlList.filteredDistributorList
      : [],
    distCentersddlList: state.ddlList.selectedDistCentersList
      ? state.ddlList.selectedDistCentersList
      : [],
    paymentStatusDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    ManageOrderDetails: state.ManageOrderDetails,
    userRole: storage.get('_appUserRole'),
  }
}
export default connect(mapStateToProps, {
  getDropdownList,
  addOrder,
  deleteOrder,
  getManageOrderDetails,
  deleteManageOrderDetails,
})(ManageOrderDetails)
