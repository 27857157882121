import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  ADD_BANKDETAILS,
  GET_BANKDETAILS,
  DELETE_BANKDETAILS,
  UPDATE_BANKDETAILS,
} from '../../../Types'

export const addBankDetails = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/DistributorBankDetails', JSON.stringify(formValues))
    .then(({ data }) => {
      formValues.id = data.response
      dispatch({
        type: ADD_BANKDETAILS,
        payload: formValues,
      })
      dispatch(hideLoading())
      toast.success('Record added successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const getBankDetails = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/DistributorBankDetails/${distributorId}`)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_BANKDETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateBankDetails = (id, formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .put(`api/DistributorBankDetails/${id}`, formValues)
    .then(({ data }) => {
      formValues.id = id
      dispatch(hideLoading())
      dispatch({
        type: UPDATE_BANKDETAILS,
        payload: formValues,
      })
      toast.success('Record updated successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const deleteBankDetails = (id) => async (dispatch) => {
  if (!id) return
  dispatch(showLoading())
  await axios
    .delete(`api/DistributorBankDetails/${id}`)
    .then(() => {
      dispatch({ type: DELETE_BANKDETAILS, payload: id })
      dispatch(hideLoading())
      toast.success('Record deleted successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
