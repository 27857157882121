import { Image, NavItem } from 'react-bootstrap'
import { NavLink } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/Images/Vizlink_logo.png'
import { connect } from 'react-redux'
import storage from '../../apis/Storage'
import { useState, useEffect } from 'react'

const DistributorSideNabar = (props) => {
  console.log('permitted pages ::', props.permittedPages)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const splitLocation = useLocation().pathname.split('/')

  const isPermittedPage = (isParent, pageUrl) => {
    let permittedPage = {}
    let permittedPages = []
    if (pageUrl) {
      if (props.userRole == 'Administrator') return true

      if (isParent === true) {
        permittedPages = props.permittedPages.filter(
          (page) => page.parentName.toLowerCase() == pageUrl.toLowerCase()
        )
        if (permittedPages.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        permittedPage = props.permittedPages.find(
          (page) => page.pageUrl.toLowerCase() == pageUrl.toLowerCase()
        )
        if (permittedPage == false || typeof permittedPage == 'undefined') {
          return false
        } else {
          return true
        }
      }
    } else return false
  }

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <div className={props.isNavbarOpen ? 'sidebar-backdrop' : ''}>
        <aside className='main-sidebar sidebar-light-primary elevation-4'>
          <div className={props.isNavbarOpen ? 'sidebar' : ''}>
            <span className='d-flex align-items-center justify-content-between px-3'>
              <a className=' clearfix' href='/dashboard'>
                <Image src={logo} alt='Logo' className='brand-image'></Image>
              </a>
              {windowSize.width < 993 && (
                <button
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                  onClick={props.onClickOutside}
                // onClick={() => {
                //   var hamburger = document.getElementById('pushmenu')
                //   hamburger.click()
                //   props.onClickOutside()
                // }}
                ></button>
              )}
            </span>
            <nav className='mt-2'>
              <ul
                className='nav nav-pills nav-sidebar flex-column'
                data-widget='treeview'
                role='menu'
                data-accordion='true'
              >
                {isPermittedPage(false, '/dashboard') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      tag={Link}
                      to='/dashboard'
                      className={` ${splitLocation[1] === 'dashboard' ? 'active' : ''
                        }`}
                    >
                      <p>Dashboard</p>
                    </NavLink>
                  </NavItem>
                )}
                {isPermittedPage(true, 'QR/Till Details') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'QrTillList' ||
                        splitLocation[1] === 'DeliverymanUserRegistration' ||
                        splitLocation[1] === 'TillNumberDetails' ||
                        splitLocation[1] === 'DailyCollectionsReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        QR/Till Details
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/QrTillList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/QrTillList'
                            className={` ${splitLocation[1] === 'QrTillList' ? 'active' : ''
                              }`}
                          >
                            <p>QR/Till List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(
                        false,
                        '/DeliverymanUserRegistration'
                      ) && (
                          <NavItem>
                            <NavLink
                              href={void 0}
                              tag={Link}
                              to='/DeliverymanUserRegistration'
                              className={` ${splitLocation[1] === 'DeliverymanUserRegistration'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <p>User Registration</p>
                            </NavLink>
                          </NavItem>
                        )}
                      {isPermittedPage(false, '/DailyCollectionsReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DailyCollectionsReport'
                            className={` ${splitLocation[1] === 'DailyCollectionsReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Daily Collections Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/TillNumberDetails') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/TillNumberDetails'
                            className={` ${splitLocation[1] === 'TillNumberDetails'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>VL Till Number Details</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(true, 'Vizlink Shops') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'vizLinkJobsList' ||
                        splitLocation[1] === 'vizLinkShopsOnBoardingDetails' ||
                        splitLocation[1] === 'vizLinkShopsList'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        VizLink Shops
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/vizLinkJobsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/vizLinkJobsList'
                            className={` ${splitLocation[1] === 'vizLinkJobsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shops Induction Jobs List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(
                        false,
                        '/vizLinkShopsOnBoardingDetails'
                      ) && (
                          <NavItem>
                            <NavLink
                              href={void 0}
                              tag={Link}
                              to='/vizLinkShopsOnBoardingDetails'
                              className={` ${splitLocation[1] ===
                                'vizLinkShopsOnBoardingDetails'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <p>Shops On-Boarding Details</p>
                            </NavLink>
                          </NavItem>
                        )}
                      {isPermittedPage(false, '/vizLinkShopsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/vizLinkShopsList'
                            className={` ${splitLocation[1] === 'vizLinkShopsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>VizLink Shops List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/sectors') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/sectors'
                            className={` ${splitLocation[1] === 'sectors' ? 'active' : ''
                              }`}
                          >
                            <p>Sectors List</p>
                          </NavLink>
                        </NavItem>
                      )}

                      {isPermittedPage(false, '/SectorBasedReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/SectorBasedReport'
                            className={` ${splitLocation[1] === 'SectorBasedReport' ? 'active' : ''
                              }`}
                          >
                            <p>Sector-Based Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(true, 'Vizlink Agents') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'RegisteredAgentsList' ||
                        splitLocation[1] === 'VizAgentsList'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Vizlink Agents
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/VizAgentsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/VizAgentsList'
                            className={` ${splitLocation[1] === 'VizAgentsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Manage Agents</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/RegisteredAgentsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/RegisteredAgentsList'
                            className={` ${splitLocation[1] === 'RegisteredAgentsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Registered Agents</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(true, 'Daily Orders') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'NewOrdersForm' ||
                        splitLocation[1] === 'OrderStatusReport' ||
                        splitLocation[1] === 'OrderStatusReport' ||
                        splitLocation[1] === 'PaymentStatusReport' ||
                        splitLocation[1] === 'OrderShopDetails' ||
                        splitLocation[1] === 'InvoiceAllocationListing'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Daily Orders
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/NewOrdersForm') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/NewOrdersForm'
                            className={` ${splitLocation[1] === 'NewOrdersForm'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>New Orders Form</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/PaymentStatusReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/PaymentStatusReport'
                            className={` ${splitLocation[1] === 'PaymentStatusReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shop-wise Payment Status Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/DLMCollectionReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DLMCollectionReport'
                            className={` ${splitLocation[1] === 'DLMCollectionReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Deliveryman-wise Collection Details Report</p>
                          </NavLink>
                        </NavItem>)}
                      {isPermittedPage(false, '/InvoiceAllocationListing') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/InvoiceAllocationListing'
                            className={` ${splitLocation[1] === 'InvoiceAllocationListing'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Invoice Allocation Listing</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/SCRDetailReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/SCRDetailReport'
                            className={` ${splitLocation[1] === 'SCRDetailReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>SCR Detail Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(true, 'VL Loyalty Program') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'SchemesList' ||
                        splitLocation[1] === 'CoinsHistoryReport' ||
                        splitLocation[1] === 'DistributorCoinsPaymentReport' ||
                        splitLocation[1] === 'DistributorCoinsDisbursmentReport' ||
                        splitLocation[1] === 'DeliveryManCoinsPaymentReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        VL Loyalty Program
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/SchemesList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/SchemesList'
                            className={` ${splitLocation[1] === 'SchemesList' ? 'active' : ''
                              }`}
                          >
                            <p>Schemes List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/CoinsHistoryReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/CoinsHistoryReport'
                            className={` ${splitLocation[1] === 'CoinsHistoryReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Coins History Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/DistributorCoinsDisbursmentReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DistributorCoinsDisbursmentReport'
                            className={` ${splitLocation[1] ===
                              'DistributorCoinsDisbursmentReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Distributor Coins Disbursement Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/DeliveryManCoinsPaymentReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DeliveryManCoinsPaymentReport'
                            className={` ${splitLocation[1] === 'DeliveryManCoinsPaymentReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Deliveryman Coins Payment Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/DistributorCoinsPaymentReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DistributorCoinsPaymentReport'
                            className={` ${splitLocation[1] === 'DistributorCoinsPaymentReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Distributor Coins Payment Report</p>
                          </NavLink>
                        </NavItem>
                      )}

                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(true, 'Loan Payments') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'LoanDisbursementReport' ||
                        splitLocation[1] === 'FinjaLoanReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Loan Payments
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/LoanDisbursementReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/LoanDisbursementReport'
                            className={` ${splitLocation[1] === 'LoanDisbursementReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Loan Disbursement Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/FinjaLoanReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/FinjaLoanReport'
                            className={` ${splitLocation[1] === 'FinjaLoanReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Successful Loan Details Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(true, 'M2D Reports') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'InvoiceWiseDailyCollectionDetails' ||
                        splitLocation[1] === 'InvoiceWisePaymentBreakup' ||
                        splitLocation[1] === 'monthWiseM2DReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        M2D Reports
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/InvoiceWiseDailyCollectionDetails') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/InvoiceWiseDailyCollectionDetails'
                            className={` ${splitLocation[1] === 'InvoiceWiseDailyCollectionDetails'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Invoice-wise Daily Collection Details</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/InvoiceWisePaymentBreakup') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/InvoiceWisePaymentBreakup'
                            className={` ${splitLocation[1] === 'InvoiceWisePaymentBreakup'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Invoice-wise Payment Break-up</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/monthWiseM2DReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/monthWiseM2DReport'
                            className={` ${splitLocation[1] === 'monthWiseM2DReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Month-Wise M2D Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                )}


                {isPermittedPage(true, 'Advertisements') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      tag={Link}
                      to='/Advertisements'
                      className={` ${splitLocation[1] === 'Advertisements' ? 'active' : ''
                        }`}
                    >
                      <p>Advertisements</p>
                    </NavLink>
                  </NavItem>
                )}


                {isPermittedPage(true, 'VL Connect') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'VLCCommunicationListing' ||
                        splitLocation[1] === 'DistributorInbox'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        VL Connect
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/VLCCommunicationListing') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/VLCCommunicationListing'
                            className={` ${splitLocation[1] === 'VLCCommunicationListing'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Communication Listing</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/DistributorInbox') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DistributorInbox'
                            className={` ${splitLocation[1] === 'DistributorInbox'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Distributor Inbox</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(true, 'Reports') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'DailyTransactionsSummary' ||
                        splitLocation[1] === 'ShopwiseTransactionsDetails' ||
                        splitLocation[1] === 'DigitalCollectionsReport' ||
                        splitLocation[1] === 'RsoDetailsReport' ||
                        splitLocation[1] === 'RsoPerformanceReport' ||
                        splitLocation[1] === 'ShopsDetailReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Reports
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage(false, '/DailyTransactionsSummary') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DailyTransactionsSummary'
                            className={` ${splitLocation[1] === 'ManageUsers' ? 'active' : ''
                              }`}
                          >
                            <p>Daily Transactions Summary</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/ShopwiseTransactionsDetails') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/ShopwiseTransactionsDetails'
                            className={` ${splitLocation[1] === 'ManageUsers' ? 'active' : ''
                              }`}
                          >
                            <p>Shop Wise Transaction Details</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/DigitalCollectionsReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DigitalCollectionsReport'
                            className={` ${splitLocation[1] === 'DigitalCollectionsReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Digital Collections Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/RsoDetailsReport') && (
                        < NavItem >
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/RsoDetailsReport'
                            className={` ${splitLocation[1] === 'RsoDetailsReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Rso Details Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/RsoPerformanceReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/RsoPerformanceReport'
                            className={` ${splitLocation[1] === 'RsoPerformanceReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Rso Performance Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(false, '/ShopWisePulseReport') && (
                        <NavItem className='nav-item'>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/ShopWisePulseReport'
                            className={`nav-link ${splitLocation[1] === 'ShopWisePulseReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shops-Wise Pulse Report</p>
                          </NavLink>
                        </NavItem>
                      )}

                      {isPermittedPage(false, '/DateWiseShopsSummaryReport') && (
                        <NavItem className='nav-item'>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DateWiseShopsSummaryReport'
                            className={`nav-link ${splitLocation[1] === 'DateWiseShopsSummaryReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Active Shops Summary</p>
                          </NavLink>
                        </NavItem>
                      )}

                      {isPermittedPage(false, '/ShopsDetailReport') && (
                        <NavItem className='nav-item'>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/ShopsDetailReport'
                            className={`nav-link ${splitLocation[1] === 'ShopsDetailReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shops Detail Report</p>
                          </NavLink>
                        </NavItem>
                      )}

                    </ul>
                  </NavItem>
                )}
                {isPermittedPage(false, '/About') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      tag={Link}
                      to='/About'
                      className={` ${splitLocation[1] === 'profile' ? 'active' : ''
                        }`}
                    >
                      <p>About</p>
                    </NavLink>
                  </NavItem>
                )}
                {isPermittedPage(false, '/my-profile') && (
                  <NavItem>
                    <NavLink
                      href={void 0}
                      tag={Link}
                      to='/my-profile'
                      className={` ${splitLocation[1] === 'my-profile' ? 'active' : ''
                        }`}
                    >
                      <p>My Profile</p>
                    </NavLink>
                  </NavItem>
                )}

              </ul>
            </nav>
          </div>
        </aside >
      </div >
    </>
  )
}

// get permitted pages from redux
export default connect((state) => ({
  userRole: storage.get('_appUserRole'),
  permittedPages: JSON.parse(storage.get('_userPermissionsList')), ///state.user.permittedPages,
}))(DistributorSideNabar)
