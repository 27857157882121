import { GET_VIZLINKSHOPS } from '../../../Types'
// import _ from 'lodash'

const VizLinkShopListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_VIZLINKSHOPS:
      return [...action.payload]
    default:
      return state
  }
}
export default VizLinkShopListReducer
