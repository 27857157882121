import React, { useRef, useEffect, useState } from 'react'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { connect } from 'react-redux'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import axios from '../../../apis/Api'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'
import storage from '../../../apis/Storage';
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const showLoader = () => async (dispatch) => {
  dispatch(showLoading())
}

export const hideLoader = () => async (dispatch) => {
  dispatch(hideLoading())
}

const InvoiceAllocationListing = (props) => {

  const userRights = JSON.parse(storage.get('_userRights'))
  const [obidList, setObidList] = useState([])
  const [invoiceList, setInvoiceList] = useState([])

  const [allDeliveryManList, setAllDeliveryManList] = useState([])

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] = useState([])
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])
  const [deliveryManFilterList, setDeliveryManFilterList] = useState([])
  const [obidFilterList, setObidFilterList] = useState([])

  const [filter, setfilter] = useState({
    dist: '',
    distCenter: '',
    deliveryMan: '',
    pageNo: 0,
    searchText: '',
    obid: '',
    isExport: false,
  })

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [showInvoices, setShowInvoices] = useState(false)
  const [targetInvoices, setTargetInvoices] = useState(null)
  const refInvoices = useRef(null)

  const columns = [
    {
      Header: 'OB ID',
      accessor: 'obid',
    },

    {
      Header: 'Count Of Invoice',
      accessor: 'invoiceCount',
    },

    {
      Header: 'Delivery Man',
      accessor: 'd',
      Cell: (row) => (
        <>
          <ReactSelect
            id='obiddeliveryMan'
            name='obiddeliveryMan'
            className='basic-single'
            classNamePrefix='select'
            placeholder='Select Delivery Man'
            isMulti={false}
            value={obidSelectedDeliveryMan(row.row.original.deliveryMan)}
            isSearchable={true}
            onChange={(option) =>
              changeObidDeliveryMan(row.row.original.obid, option)
            }
            options={deliveryDropDown(row.row.original.distCenterId)}
            menuPlacement='auto'
            // other props
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          ></ReactSelect>
        </>
      ),
    },
    {
      Header: 'Allocated DeliveryMen',
      accessor: 'dm',
      Cell: (row) => (
        <>
          <p>{obidDeliveryMenList(row.row.original.deliveryMan)}</p>
        </>
      ),
    },

    {
      Header: '',
      accessor: 'isSelected',
      Cell: (row) => (
        <>
          <h3
            style={{
              color: '#EA5F2D',
              fontWeight: 'bold',
            }}
            onClick={(event) => toggleObid(row, event)}
          >
            {row.value == true ? '-' : '+'}
          </h3>
        </>
      ),
    },
  ]

  const toggleObid = (row, event) => {
    if (obidList.filter((x) => x.isSelected == true).length != 0) return
    let selectedObid = row.row.original.obid
    // getDeliveryUserList(row.row.original.distCenterId, 'invoices')
    getObidInvoicesList(selectedObid)
    let list = [...obidList]
    let index = list.findIndex((x) => x.obid == selectedObid)
    list[index].isSelected = !list[index].isSelected
    setObidList(list)

    setShowInvoices(true)
    setTargetInvoices(event.target)
  }

  const getObidList = (request) => {
    props.showLoader()
    axios
      .post(`api/ShopOrder/GetInvoiceAllocationList`, request)
      .then(({ data }) => {
        props.hideLoader()
        setObidList(data?.response?.data)
        setTotalRecords(data?.response?.totalRecords)
      })
      .catch(function (error) {
        props.hideLoader()
      })
  }

  const getObidInvoicesList = (obid) => {
    let request = { obid: obid, invoiceNo: filter?.searchText }
    axios
      .post(`api/ShopOrder/GetObidWiseInvoiceList`, request)
      .then(({ data }) => {
        setInvoiceList(data?.response)
      })
      .catch(function (error) { })
  }

  const getObidFilterList = () => {
    axios
      .get(`api/ShopOrder/GetObidList`)
      .then(({ data }) => {
        setObidFilterList(
          data?.response.map(function (item) {
            return {
              value: item,
              label: item,
            }
          })
        )
      })
      .catch(function (error) { })
  }

  // const getDeliveryUserList = (id, from) => {
  //   axios
  //     .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
  //     .then(({ data }) => {
  //       if (from == 'filter') {
  //         setDeliveryManFilterList(
  //           data?.response.map(function (item) {
  //             return {
  //               value: item.id,
  //               label: item.fullname,
  //             }
  //           })
  //         )
  //       }

  //       if (from == 'invoices') {
  //         setDeliveryManList(
  //           data?.response.map(function (item) {
  //             return {
  //               value: item.id,
  //               label: item.fullname,
  //             }
  //           })
  //         )
  //         setDeliveryManListOriginal(data?.response)
  //       }
  //     })
  //     .catch(function (error) {})
  // }

  const getAllDeliveryUserList = () => {
    axios
      .get(`api/User/GetAllDeliveryUsers`)
      .then(({ data }) => {
        setAllDeliveryManList(data?.response)
      })
      .catch(function (error) { })
  }

  const saveDeliveryUserAllocation = () => {
    const request = {}
    axios
      .post(`api/ShopOrder/ReassignedDeliveryUserForInvoices`, request)
      .then(({ data }) => { })
      .catch(function (error) { })
  }

  const deliveryDropDown = (centerId) => {
    let dUsersList = [...allDeliveryManList]
    let centerUsers = dUsersList
      .filter((x) => x.distCenterId == centerId)
      .map(function (item) {
        return {
          value: item.id,
          label: item?.fullname,
        }
      })

    return centerUsers
  }
  const selectedDeliveryUser = (userId) => {
    if (userId == '' || userId == null) return
    let users = [...allDeliveryManList]
    let selectedUser = users.find((x) => x.id == userId)
    return {
      label: selectedUser?.fullname,
      value: selectedUser.id,
    }
  }

  const obidDeliveryMenList = (users) => {
    let allUsers = [...allDeliveryManList]
    let userList = []
    users.forEach((element) => {
      let u = allUsers.find((x) => x.qrCode == element)
      if (u != null) {
        userList.push(u.fullname)
      }
    })
    return userList.join(', ')
  }

  const obidSelectedDeliveryMan = (users) => {
    if (users.length == 1) {
      let allUsers = [...allDeliveryManList]
      let selectedUser = allUsers.find((x) => x.qrCode == users[0])
      return {
        label: selectedUser?.fullname,
        value: selectedUser?.id,
      }
    }
    return null
  }

  const changeObidDeliveryMan = (obid, option) => {
    let obids = [...obidList]
    let index = obids.findIndex((x) => x.obid == obid)
    let user = allDeliveryManList.find((x) => x.id == option.value)
    obids[index].deliveryMan = []
    obids[index].deliveryMan.push(user.qrCode)
    setObidList(obids)
    saveObidAssignment(obid, user.qrCode)
  }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()

    setAddJobDistCenterOptions([])
    setDeliveryManFilterList([])
    setfilter({
      dist: null,
      distCenter: null,
      obid: null,

      deliveryMan: null,
      pageNo: 0,
      searchText: '',
      isExport: false,
    })
  }

  function formRequestObject() {
    let requestData = {
      distId: filter.dist?.value,
      distCenterId: filter.distCenter?.value,

      deliveryMan: filter.deliveryMan?.value,
      obid: filter.obid?.value,

      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
      isExport: filter.isExport,
    }

    return requestData
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setfilter({ ...filter, searchText: text, currentPageNo: 0 })
    var request = {
      ...formRequestObject(),
      searchText: text,
      currentPageNo: 0,
    }
    fetchReportData(request)
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    getObidList(finalRequest)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchReportData(request)
    setShow(false)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize }
    fetchReportData(request)
  }

  const closeInvoices = () => {
    setShowInvoices(false)
    setTargetInvoices(null)
    setInvoiceList([])

    let list = [...obidList]
    list.forEach((x) => (x.isSelected = false))
    setObidList(list)
  }

  const selectInvoice = (record, idx) => {
    let invoices = [...invoiceList]
    invoices[idx].isSelected = !invoices[idx].isSelected
    setInvoiceList(invoices)
  }

  const asignDeliveryManToInvoice = (invoice, delMan) => {
    let invoices = [...invoiceList]
    let index = invoices.findIndex((x) => x.invoiceNo == invoice)
    invoices[index].deliveryMan = delMan.value
    setInvoiceList(invoices)
  }

  const saveInvoiceAssignment = () => {
    let list = [...invoiceList.filter((x) => x.isSelected)]
    if (list.length == 0) {
      toast.error('Select invoice(s)')
      return
    }
    let finalList = list.map((x) => {
      let du = allDeliveryManList.find((d) => d.id == x.deliveryMan)
      return {
        invoiceNo: x.invoiceNo,
        deliveryMan: du?.qrCode,
      }
    })

    setShowInvoices(false)
    setTargetInvoices(null)
    saveData(finalList)
    closeInvoices()
  }

  const saveObidAssignment = (obid, deliveryUser) => {
    let data = []
    data.push({
      obid: obid,
      deliveryMan: deliveryUser,
    })

    saveData(data)
  }

  const saveData = (req) => {
    axios
      .post(`api/ShopOrder/ReassignedDeliveryUserForInvoices`, req)
      .then(({ data }) => {
        // setObidList(data?.response?.data)
        // setTotalRecords(data?.response?.totalRecords)
        toast.success('Delivery man assigned successfully')
      })
      .catch(function (error) { })
  }

  useEffect(() => {
    fetchReportData()
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
    getObidFilterList()
    getAllDeliveryUserList()
  }, [])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      var filteredDistList = props.allDistributorList.filter(d => d.value === userRights?.distributorId.toUpperCase());
      setAddJobDistributorsOptions(filteredDistList);
    }
    else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      var assignedDistcenterIds = userRights.distCenterIds.split(',');
      let filteredItems = props.selectedAddJobDistCentersList.filter(item => assignedDistcenterIds.includes(item.value));
      setAddJobDistCenterOptions(
        filteredItems.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          }
        })
      )
    }
    else {
      setAddJobDistCenterOptions(
        props.selectedAddJobDistCentersList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.selectedAddJobDistCentersList])

  useEffect(() => {
    if (filter.dist) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filter.dist.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filter.dist])

  useEffect(() => {
    if (filter.distCenter) {
      // getDeliveryUserList(filter.distCenter.value, 'filter')
      debugger
      var filteredDM = allDeliveryManList.filter((x) => x.distCenterId == filter.distCenter.value.toLowerCase())
      setDeliveryManFilterList(
        filteredDM.map(function (item) {
          return {
            value: item.id,
            label: item.fullname,
          }
        })
      )
    }
  }, [filter.distCenter])

  return (
    <div>
      <VzPageContainer title='Invoice Allocation Listing'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Invoice Allocation Listing</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10} style={{ float: 'right' }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filter}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distributor Name'
                                name='distributorName'
                                options={addJobDistributorsOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    setfilter({
                                      ...filter,
                                      dist: null,
                                    })
                                    return
                                  }
                                  setfilter({
                                    ...filter,
                                    dist: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  })
                                }}
                                value={filter.dist}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distribution ID'
                                name='distributionID'
                                options={addJobDistCenterOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  setfilter({
                                    ...filter,
                                    distCenter: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  })
                                }}
                                value={filter.distCenter}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Delivery Man'
                                name='deliveryMan'
                                options={deliveryManFilterList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  setfilter({
                                    ...filter,
                                    deliveryMan: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  })
                                }}
                                value={filter.deliveryMan}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='OB ID'
                                name='obid'
                                options={obidFilterList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  setfilter({
                                    ...filter,
                                    obid: {
                                      label: e.label,
                                      value: e.value,
                                    },
                                  })
                                }}
                                value={filter.obid}
                              />
                            </Col>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} ref={refInvoices}>
            <ServerSideDataTable
              data={obidList ?? []}
              columns={columns}
              getTrProps={(event, data) => { }}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords ?? 0}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
              searchLabel='Search Invoice :'
            />

            <Overlay
              show={showInvoices}
              target={targetInvoices}
              placement='center'
              container={refInvoices}
              containerPadding={20}
            >
              <Popover
                id='popover-contained'
                style={{
                  maxWidth: '70%',
                  width: '70%',
                  maxHeight: '60%',
                  overflow: 'auto',
                  position: 'absolute',
                  left: '15%',
                }}
              >
                <Popover.Header as='h3' style={{ textAlign: 'end' }}>
                  <button
                    type='button'
                    class='btn-close'
                    aria-label='Close'
                    onClick={closeInvoices}
                  ></button>
                </Popover.Header>
                <Popover.Body>
                  <Formik
                    enableReinitialize
                    initialValues={filter}
                    validator={() => ({})}
                    onSubmit={LoadDataByfilter}
                  >
                    {(formik) => (
                      <Form autoComplete='off'>
                        <Row>
                          <Col xs={12} md={12}>
                            <table
                              className='table striped bordered hover'
                              style={{ minHeight: '330pxs' }}
                            >
                              <thead>
                                <th>Select</th>
                                <th>Invoice No</th>
                                <th>Invoice Amount</th>
                                <th>Delivery Man</th>
                              </thead>
                              <tbody>
                                {invoiceList.map((x, idx) => {
                                  return (
                                    <tr
                                      className={
                                        x.status == 'Paid' ? 'disable-tr' : ''
                                      }
                                      disabled={x.status == 'Paid'}
                                    >
                                      <td>
                                        <div className='ms-2  '>
                                          <input
                                            disabled={x.status == 'Paid'}
                                            checked={x.isSelected}
                                            value={x.isSelected}
                                            type='checkbox'
                                            onChange={() =>
                                              selectInvoice(x, idx)
                                            }
                                            style={{ transform: 'scale(1.3)' }}
                                          />
                                        </div>
                                      </td>
                                      <td>{x.invoiceNo}</td>
                                      <td>{x.amount}</td>
                                      <td>
                                        <ReactSelect
                                          isDisabled={x.status == 'Paid'}
                                          id='deliveryMan'
                                          name='deliveryMan'
                                          className='basic-single'
                                          classNamePrefix='select'
                                          placeholder='Select Delivery Man'
                                          isMulti={false}
                                          value={selectedDeliveryUser(
                                            x.deliveryMan
                                          )}
                                          isSearchable={true}
                                          onChange={(option) =>
                                            asignDeliveryManToInvoice(
                                              x.invoiceNo,
                                              option
                                            )
                                          }
                                          options={deliveryDropDown(
                                            x.distCenterId
                                          )}
                                          menuPlacement='auto'
                                        ></ReactSelect>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                        <div className='row' style={{ marginTop: 20 }}>
                          <div className='form-group'>
                            <Link
                              onClick={closeInvoices}
                              to='#'
                              className='active'
                              style={{
                                padding: '0px',
                                marginTop: '10px',
                                float: 'right',
                              }}
                            >
                              Cancel
                            </Link>
                            <div className='float-right'>
                              <Button
                                type='submit'
                                className='btn btn-primary'
                                style={{
                                  marginRight: '10px',
                                  float: 'right',
                                }}
                                onClick={saveInvoiceAssignment}
                              >
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Popover.Body>
              </Popover>
            </Overlay>
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  }
}
export default connect(mapStateToProps, {
  getDropdownList,
  showLoader,
  hideLoader,
})(InvoiceAllocationListing)
