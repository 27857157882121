import React, { Component, useEffect, useState } from 'react'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import ReactSelect from 'react-select'

import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'

import { connect } from 'react-redux'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_DISTRIBUTIONCENTER_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import { addOrder, addUnileverOrder, addMasterDataOrder, deleteOrder, getOrders } from './ManageDailyOrdersActions'
import ExcelIcon from '../../../assets/Images/Distributors/excel-icon.png'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { confirmAlert } from 'react-confirm-alert'
import NestleTemplate from '../../../assets/Templates/NestleTemplate.xlsx'
import UnileverHDRTemplate from '../../../assets/Templates/UnileverHDRTemplate.xlsx'
import UnileverDTLTemplate from '../../../assets/Templates/UnileverDTLTemplate.xlsx'
import MasterDataTemplate from '../../../assets/Templates/MasterDataTemplate.xlsx'
// import history from '../../../history'
import Moment from 'react-moment'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'
import { useHistory } from 'react-router-dom'

const ManageDailyOrders = (props) => {
  const history = useHistory()
  const userRights = JSON.parse(storage.get('_userRights'))

  const [currentPageNo, setCurrentPageNo] = useState(0)

  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [dailyOrdersList, setDailyOrdersList] = useState([])

  const [addOrderDistributorsOptions, setAddOrderDistributorsOptions] =
    useState(null)
  const [
    addOrderDistributionCentersOptions,
    setAddOrderDistributionCentersOptions,
  ] = useState(null)

  const [addOrderDistributorName, setAddOrderDistributorName] = useState(null)
  const [addOrderDistributionCenter, setAddOrderDistributionCenter] =
    useState(null)
  const [uploadDataModalShow, setUploadDataModalShow] = useState(false)
  const [dataSheetSelectedFile, setDataSheetSelectedFile] = useState(null)
  const [dataSheetChildSelectedFile, setDataSheetChildSelectedFile] = useState(null)

  const [orderDeliveryDate, setOrderDeliveryDate] = useState(new Date())
  const [orderDate, setOrderDate] = useState(new Date())

  const [downloadTemplateShow, setdownloadTemplateShow] = useState(false)

  const [uploadMasterDataCheckbox, setUploadMasterDataCheckbox] = useState(false)

  const [addOrderModalInitialValue, setAddOrderModalInitialValue] = useState({
    distributorName: '',
    distributionCenter: '',
    OrderDataFile: null,
  })

  const addOrderFormValidationSchema = Yup.object().shape({
    distributorName: Yup.string().required('Distributor Name is required'),
    distributionCenter: Yup.string().required(
      'Distribution Center is required'
    ),
  })
  const columns = [
    {
      Header: 'Distribution ID',
      accessor: 'distributionId',
    },
    {
      Header: 'Obid',
      accessor: 'obid',
    },
    {
      Header: 'Order Date',
      accessor: 'orderDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Order Delivery Date',
      accessor: 'deliveryDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Number of Orders',
      accessor: 'noOfOrders',
    },
    {
      Header: 'Amount',
      accessor: 'totalAmount',
      Cell: (row) => <>{Math.round(Number(row.value)).toLocaleString()}</>,
    },
    // {
    //   Header: 'Payment Status',
    //   accessor: 'paymentStatus',
    // },
  ]

  const OpenModelForAddOrder = (isOpen) => {
    setUploadDataModalShow(isOpen)
    setAddOrderModalInitialValue({
      distributorName: '',
      distributionCenter: '',
      orderDate: '',
      orderDeliveryDate: '',
    })
    setDataSheetSelectedFile(null)
    setDataSheetChildSelectedFile(null)
    setUploadMasterDataCheckbox(false)
    setAddOrderDistributionCenter(null)
    setAddOrderDistributorName(null)
  }

  const submitUnliverOrderData = (data) => {
    if (
      dataSheetSelectedFile != null &&
      dataSheetChildSelectedFile != null &&
      addOrderDistributorName &&
      addOrderDistributionCenter
    ) {
      let requestData = {
        distributorId: addOrderDistributorName.value, //data.distributorId,
        DistCenterId: addOrderDistributionCenter.value,
        DataFile: dataSheetSelectedFile,
        DataFileChild: dataSheetChildSelectedFile
      }

      if (userRights != null && userRights?.userType == 'DistributorUser') {
        requestData = {
          ...requestData,
          assignedDistCenters: userRights?.distCenterIds,
        }
      }
      props.addUnileverOrder(requestData)
      setUploadDataModalShow(false)
    } else {
      if (dataSheetSelectedFile == null && dataSheetChildSelectedFile == null) {
        toast.error('Please select Both Data Sheet')
      }
      else if (dataSheetSelectedFile == null) {
        toast.error('Please select HDR Data Sheet')
      }
      else if (dataSheetChildSelectedFile == null) {
        toast.error('Please select DTL Data Sheet')
      }
    }
  }

  const submitMasterOrderData = (data) => {
    if (
      dataSheetSelectedFile != null &&
      uploadMasterDataCheckbox == true &&
      addOrderDistributorName &&
      addOrderDistributionCenter
    ) {
      let requestData = {
        distributorId: addOrderDistributorName.value, //data.distributorId,
        DistCenterId: addOrderDistributionCenter.value,
        DataFile: dataSheetSelectedFile,
      }

      if (userRights != null && userRights?.userType == 'DistributorUser') {
        requestData = {
          ...requestData,
          assignedDistCenters: userRights?.distCenterIds,
        }
      }
      props.addMasterDataOrder(requestData)
      setUploadDataModalShow(false)
    } else {
      if (uploadMasterDataCheckbox != true) {
        toast.error('Please check upload master data checkbox')
      }
      else {
        toast.error('Please select Master Data Sheet')
      }
    }
  }

  const submitOrderData = (data) => {
    if (uploadMasterDataCheckbox) {
      submitMasterOrderData(data);
      return
    }
    else if (addOrderDistributionCenter?.principalName == "Unilever") {
      submitUnliverOrderData(data);
      return
    }
    else {
      if (
        dataSheetSelectedFile != null &&
        addOrderDistributorName &&
        addOrderDistributionCenter
      ) {
        let requestData = {
          distributorId: addOrderDistributorName.value, //data.distributorId,
          DistCenterId: addOrderDistributionCenter.value,
          DataFile: dataSheetSelectedFile,
        }

        if (userRights != null && userRights?.userType == 'DistributorUser') {
          requestData = {
            ...requestData,
            assignedDistCenters: userRights?.distCenterIds,
          }
        }
        props.addOrder(requestData)
        setUploadDataModalShow(false)
      } else {
        toast.error('Please select Order Data Sheet')
      }
    }
  }

  const validateDataSheetFile = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return
    }
    if (!image.name.match(/\.(xlsx|xls)$/)) {
      toast.error('You can only upload file of type (xlsx, xls)')
      event.target.value = null
      return
    } else {
      setDataSheetSelectedFile(event.target.files[0])
    }
  }

  const validateDataSheetChildFile = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return
    }
    if (!image.name.match(/\.(xlsx|xls)$/)) {
      toast.error('You can only upload file of type (xlsx, xls)')
      event.target.value = null
      return
    } else {
      setDataSheetChildSelectedFile(event.target.files[0])
    }
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    props.deleteOrder(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const onRowClick = (e, rowData) => {
    history.push(
      `/ManageOrderDetails/${rowData.deliveryDate}/${rowData.distCenterId}`
    )
    localStorage.setItem(
      '_manageOrderDetails',
      JSON.stringify({ obid: rowData.obid, orderDate: rowData.orderDate })
    )
    // history.go(
    //   `/ManageOrderDetails/${rowData.deliveryDate}/${rowData.distCenterId}/${rowData.obid}`
    // )
  }

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
    // props.getOrders({ DeliveryDate: new Date(), DistCenterId: null })
  }, [])

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }

    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getOrders(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getOrders(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getOrders(requestData)
  }, [searchText])
  useEffect(() => {
    if (props.ManageDailyOrders) {
      setDailyOrdersList(props.ManageDailyOrders)
      if (props.ManageDailyOrders.length > 0) {
        setTotalRecords(props.ManageDailyOrders[0].totalRecords)
      } else {
        setTotalRecords(0)
      }
    }
  }, [props.ManageDailyOrders])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddOrderDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddOrderDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addOrderDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddOrderDistributionCentersOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          }
        })
      )
    } else {
      setAddOrderDistributionCentersOptions(
        props.addOrderDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          }
        })
      )
    }
  }, [props.addOrderDistCentersddlList])

  useEffect(() => {
    if (addOrderDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${addOrderDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [addOrderDistributorName])

  // useEffect(() => {
  // }, [props.ManageDailyOrders])
  const setFileForPrincipal = () => {
    return addOrderDistributionCenter?.principalName == "Unilever" && uploadMasterDataCheckbox == false ?
      <Row>
        <Col xs={6}>
          <div className='form-group'>
            <div>
              <div>
                Select (HDR) Data Sheet File (Only Excel file)
              </div>
              {dataSheetSelectedFile && (
                <div>
                  <img
                    style={{ border: '1px solid #ccc' }}
                    alt='not fount'
                    width={'100px'}
                    height={'100px'}
                    src={ExcelIcon}
                  />
                  <br />
                  <button
                    className='btn btn-info'
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px',
                      marginBottom: '20px',
                    }}
                    onClick={() => {
                      // addOrderModalInitialValue.OrderDataFile = null
                      setDataSheetSelectedFile(null)
                    }}
                  >
                    <i className='fa fa-close' />
                    Remove
                  </button>
                </div>
              )}
              {!dataSheetSelectedFile && (
                <div>
                  <br />
                  <input
                    type='file'
                    name='dataSheetSelectedFile'
                    onChange={(event) =>
                      validateDataSheetFile(event)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className='form-group'>
            <div>
              <div>
                Select (DTL) Data Sheet File (Only Excel file)
              </div>
              {dataSheetChildSelectedFile && (
                <div>
                  <img
                    style={{ border: '1px solid #ccc' }}
                    alt='not fount'
                    width={'100px'}
                    height={'100px'}
                    src={ExcelIcon}
                  />
                  <br />
                  <button
                    className='btn btn-info'
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px',
                      marginBottom: '20px',
                    }}
                    onClick={() => {
                      // addOrderModalInitialValue.OrderDataFile = null
                      setDataSheetChildSelectedFile(null)
                    }}
                  >
                    <i className='fa fa-close' />
                    Remove
                  </button>
                </div>
              )}
              {!dataSheetChildSelectedFile && (
                <div>
                  <br />
                  <input
                    type='file'
                    name='dataSheetChildSelectedFile'
                    onChange={(event) =>
                      validateDataSheetChildFile(event)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      : <Col xs={12} md={{ span: 7, offset: 0 }}>
        <div className='form-group'>
          <div>
            <div>
              Select Data Sheet File (Only Excel file)
            </div>
            {dataSheetSelectedFile && (
              <div>
                <img
                  style={{ border: '1px solid #ccc' }}
                  alt='not fount'
                  width={'100px'}
                  height={'100px'}
                  src={ExcelIcon}
                />
                <br />
                <button
                  className='btn btn-info'
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    marginBottom: '20px',
                  }}
                  onClick={() => {
                    // addOrderModalInitialValue.OrderDataFile = null
                    setDataSheetSelectedFile(null)
                  }}
                >
                  <i className='fa fa-close' />
                  Remove
                </button>
              </div>
            )}
            {!dataSheetSelectedFile && (
              <div>
                <br />
                <input
                  type='file'
                  name='dataSheetSelectedFile'
                  onChange={(event) =>
                    validateDataSheetFile(event)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </Col>
  }

  const ChangeUploadMasterDataCheckBox = (event) => {
    setUploadMasterDataCheckbox(event.target.checked)
    setDataSheetSelectedFile(null)
    setDataSheetChildSelectedFile(null)
  }

  return (
    <div>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <VzPageContainer title='New Orders Form'>
          {isPermittedPage(
            'Upload Data Sheet',
            props.actions,
            props.userRole
          ) && (
              <Row style={{ marginBottom: 30 }}>
                <Col>
                  <div className='form-group'>
                    <Button
                      className='form-btn'
                      variant='primary'
                      onClick={() => OpenModelForAddOrder(true)}
                    >
                      Upload Data Sheet
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          <Row>
            <Col xs={12} md={12}>
              {/* <CustomDataTable
              data={props.ManageDailyOrders}
              columns={columns}
              getTrProps={(event, data) => onRowClick(event, data)}
            /> */}
              <ServerSideDataTable
                data={dailyOrdersList ? dailyOrdersList : []}
                columns={columns}
                getTrProps={(event, data) => onRowClick(event, data)}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={totalRecords}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
            </Col>
          </Row>
          <Modal
            show={uploadDataModalShow}
            onHide={() => setUploadDataModalShow(false)}
            backdrop='static'
            keyboard={false}
            size='lg'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Add New Orders Data
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                enableReinitialize
                initialValues={addOrderModalInitialValue}
                validationSchema={addOrderFormValidationSchema}
                onSubmit={submitOrderData}
              >
                {(formik) => (
                  <Form
                    // autoComplete='off'
                    encType='multipart/form-data'
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Enter') {
                  //     e.preventDefault()
                  //   }
                  // }}
                  >
                    <Container>
                      <Row>
                        <Col md={{ span: 5 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              // isSearchable={true}
                              // isClearable={true}
                              label='Distributor'
                              name='distributorName'
                              options={addOrderDistributorsOptions}
                              value={addOrderDistributorName}
                              handleOnChange={(option) => {
                                setAddOrderDistributionCenter(null)

                                if (!option || !option.value) {
                                  setAddOrderDistributorName(null)
                                  setAddOrderModalInitialValue({
                                    ...addOrderModalInitialValue,
                                    distributionCenter: '',
                                    distributorName: '',
                                  })
                                } else {
                                  setAddOrderDistributorName(option)

                                  setAddOrderModalInitialValue({
                                    ...addOrderModalInitialValue,
                                    distributionCenter: '',
                                    distributorName: option.label,
                                  })
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 7 }} style={{ float: 'right' }}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              // isSearchable={true}
                              // isClearable={true}
                              label='Distribution Center'
                              name='distributionCenter'
                              options={addOrderDistributionCentersOptions}
                              value={addOrderDistributionCenter}
                              handleOnChange={(option) => {
                                if (!option || !option.value) {
                                  setAddOrderDistributionCenter(null)
                                  addOrderModalInitialValue.distributionCenter =
                                    ''
                                } else {
                                  setAddOrderDistributionCenter(option)
                                  addOrderModalInitialValue.distributionCenter =
                                    option.value
                                }
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={{ span: 5 }}>
                          <label style={{ width: '100%' }}>
                            <b>Principal:</b>
                            {addOrderDistributionCenter
                              ? addOrderDistributionCenter.principalName
                              : 'Not Selected'}
                          </label>

                          <label
                            className='form-check-label'
                            htmlFor='uploadHeader'
                            style={{
                              float: 'left',
                              marginBottom: '10px'
                            }}
                          >
                            <input
                              type='checkbox'
                              id='uploadHeader'
                              value={uploadMasterDataCheckbox}
                              onChange={ChangeUploadMasterDataCheckBox}
                            />
                            Upload Master Data
                          </label>
                        </Col>
                        {
                          setFileForPrincipal()
                        }
                        {/* <Col xs={12} md={{ span: 7, offset: 0 }}>
                          <div className='form-group'>
                            <div>
                              <div>
                                Select Data Sheet File (Only Excel file)
                              </div>
                              {dataSheetSelectedFile && (
                                <div>
                                  <img
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'100px'}
                                    height={'100px'}
                                    src={ExcelIcon}
                                  />
                                  <br />
                                  <button
                                    className='btn btn-info'
                                    style={{
                                      marginLeft: '10px',
                                      marginTop: '10px',
                                      marginBottom: '20px',
                                    }}
                                    onClick={() => {
                                      // addOrderModalInitialValue.OrderDataFile = null
                                      setDataSheetSelectedFile(null)
                                    }}
                                  >
                                    <i className='fa fa-close' />
                                    Remove
                                  </button>
                                </div>
                              )}
                              {!dataSheetSelectedFile && (
                                <div>
                                  <br />
                                  <input
                                    type='file'
                                    name='dataSheetSelectedFile'
                                    onChange={(event) =>
                                      validateDataSheetFile(event)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      {/* <Row>
                      <Col xs={12} md={6}>
                        <FormikControl
                          formik={formik}
                          control='date'
                          label='Order Date'
                          name='orderDate'
                          handleOnChange={(value) => {
                            addOrderModalInitialValue.orderDate = value
                          }}
                          value={addOrderModalInitialValue.orderDate}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <FormikControl
                          formik={formik}
                          control='date'
                          // type='date'
                          label='Order Delivery Date'
                          name='orderDeliveryDate'
                          handleOnChange={(value) => {
                            addOrderModalInitialValue.orderDeliveryDate = value
                          }}
                          value={addOrderModalInitialValue.orderDeliveryDate}
                        />
                      </Col>
                    </Row> */}

                      <Row>
                        <Col xs={12} md={12}>
                          <Button
                            className='btn btn-default'
                            style={{ float: 'right', marginTop: '10px' }}
                            onClick={() => setUploadDataModalShow(false)}
                          >
                            Cancel
                          </Button>
                          <input
                            type='submit'
                            className='btn btn-primary'
                            style={{
                              marginRight: '10px',
                              marginTop: '10px',
                              float: 'right',
                            }}
                            value='Save '
                          />
                          <a
                            onClick={() => setdownloadTemplateShow(true)}
                            className='active'
                            style={{
                              padding: '0px',
                              marginTop: '20px',
                              float: 'left',
                              cursor: 'pointer'
                            }}
                          >
                            Download Template
                          </a>
                          {/* <a
                            href={`${NewUploadOrdersTemplate}`}
                            download='OrdersTemplateFile'
                            className='active'
                            style={{
                              padding: '0px',
                              marginTop: '20px',
                              float: 'left',
                            }}
                          >
                            Download Template
                          </a> */}
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* Download Templates Modal */}
          <Modal
            show={downloadTemplateShow}
            onHide={() => setdownloadTemplateShow(false)}
            backdrop='static'
            keyboard={false}
            size='sm'
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Download Template
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} md={12}>
                  <a
                    href={`${MasterDataTemplate}`}
                    download='MasterDataTemplate'
                    className='active'
                    style={{
                      padding: '0px',
                      marginTop: '20px',
                      float: 'left',
                    }}
                  >
                    Master Data Template
                  </a>
                </Col>
                <Col xs={12} md={12}>
                  <a
                    href={`${NestleTemplate}`}
                    download='NestleTemplate'
                    className='active'
                    style={{
                      padding: '0px',
                      marginTop: '20px',
                      float: 'left',
                    }}
                  >
                    Nestle Template
                  </a>
                </Col>
                <Col xs={12} md={12}>
                  <a
                    href={`${UnileverHDRTemplate}`}
                    download='UnileverHDRTemplate'
                    className='active'
                    style={{
                      padding: '0px',
                      marginTop: '20px',
                      float: 'left',
                    }}
                  >
                    Unilever HDR Template
                  </a>
                </Col>
                <Col xs={12} md={12}>
                  <a
                    href={`${UnileverDTLTemplate}`}
                    download='UnileverDTLTemplate'
                    className='active'
                    style={{
                      padding: '0px',
                      marginTop: '20px',
                      float: 'left',
                    }}
                  >
                    Unilever DTL Template
                  </a>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

        </VzPageContainer>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    ManageDailyOrders: state.ManageDailyOrders,
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],

    addOrderDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    userRole: storage.get('_appUserRole'),
  }
}
export default connect(mapStateToProps, {
  getDropdownList,
  addOrder,
  addMasterDataOrder,
  addUnileverOrder,
  deleteOrder,
  getOrders,
})(ManageDailyOrders)
