import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import storage from '../apis/Storage'

const checkPageAccess = (path, permittedPages, role) => {
  let isUserAuthorised = false
  let url = path //.replace(/\//g, '')
  let allowedActions = []
  if (role === 'Administrator') {
    return {
      isUserAuthorised: true,
      allowedActions: [],
    }
  }
  if (Array.isArray(permittedPages) && permittedPages.length > 0) {
    // If permittedPages exists
    isUserAuthorised = permittedPages.find((page) => page.pageUrl === url)
    permittedPages.forEach((element) => {
      // If Url exists
      if (element.pageUrl == url) {
        // If Action exists
        var anyPermittedAction = element.actions.filter(
          (x) => x.isSystemAction == true || x.ischecked
        )
        if (
          Array.isArray(element.actions) &&
          element.actions.length > 0 &&
          anyPermittedAction.length > 0
        ) {
          // Prepare Action List
          element.actions.forEach((item) => {
            allowedActions.push(item)
          })
        }
      }
    })

  }

  return {
    isUserAuthorised,
    allowedActions,
  }
}

const _ProtectedRoute = (props) => {
  const {
    component: Component,
    path,
    permittedPages,
    appToken,
    userRole,
    ...rest
  } = props
  const checkAccess = checkPageAccess(path, permittedPages, userRole)
  //prettier-ignore
  return (
    <Route
      {...rest}
      render={(props) =>
        checkAccess.isUserAuthorised === false ||
        typeof checkAccess.isUserAuthorised === 'undefined' ? (
          <Redirect to={{ pathname: '/login' }} />
        ) : (
          <Component
            {...props}
            actions={checkAccess.allowedActions}
            path={path}
          />
        )
      }
    />
  )
}

// get pages from redux Store
const RestrictedRoute = connect((state) => ({
  permittedPages: JSON.parse(storage.get('_userPermissionsList')), ///state.user.permittedPages,
  appToken: storage.get('_appToken'),
  userRole: storage.get('_appUserRole'),
}))(_ProtectedRoute)

export default RestrictedRoute
