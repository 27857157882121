import {
  GET_CURRENT_DIGITAL_COLLECTIONS_REPORT,
  RESET_CURRENT_DIGITAL_COLLECTIONS_REPORT,
} from '../../../../Types'

const CurrentDigitalCollectionsReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CURRENT_DIGITAL_COLLECTIONS_REPORT:
      return [...action.payload]
    case RESET_CURRENT_DIGITAL_COLLECTIONS_REPORT:
      return []

    default:
      return state
  }
}
export default CurrentDigitalCollectionsReportReducer
