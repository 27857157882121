import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import FormikControl from '../../../layouts/Input/FormikControl'
import { toast } from 'react-toastify'
import _ from 'lodash'
// import validater from 'validator'
import {
  addMfiDetails,
  getMfiDetails,
  updateMfiDetails,
  deleteMfiDetails,
  getMfiDetailsList,
} from './MfiDetailsAction'
import { getMfis } from '../../MFIs/MFIAction'

const ManageMfiDetails = (props) => {
  const { id } = useParams()
  const [defaultDistributionMfiDetails, setDefaultDistributionMfiDetails] =
    useState({
      id: '',
      distributorId: '',
      Mfi: '',
      masterNumber: '',
      cbaNo: '',
      sellerCode: '',
      correspondingEmail: '',
      branchName: '',
      masterTillNo: '',
      DistCenters: [],
    })
  const [currentDistributionMfiDetails, setCurrentDistributionMfiDetails] =
    useState(defaultDistributionMfiDetails)
  const [modalShow, setModalShow] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [MfiName, setMfiName] = useState(null)
  const [MfiList, setMfiList] = useState([])
  const [DistCentersSelectedList, setDistCentersSelectedList] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(null)
  const [DistCentersList, setDistCentersList] = useState([])

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    Mfi: Yup.string().required('MFI is required'),
    masterNumber: Yup.string(), //.required("Master Number is required"),
    cbaNo: Yup.string().max(28, 'CBA# must be at most 28 characters'), //.required("CBA No. is required"),
    sellerCode: Yup.string(), //.required("Seller Code is required"),
    correspondingEmail: Yup.string().email('E-mail format is invalid'), //.required("Corresponding e-mail is required"),
    branchName: Yup.string(), //.required("Branch Name is required"),
    masterTillNo: Yup.string(), //.required("Master Till No. is required"),
    DistCenters: Yup.array()
      .min(1, 'Distribution Center must have atleast 1 Item')
      .required('Distribution Center is required')
      .nullable(true),
  })
  const columns = [
    {
      Header: 'MFI',
      accessor: 'mfiName',
    },
    {
      Header: 'Master Till No.',
      accessor: 'masterTillNo',
    },
    {
      Header: 'Franchise Code',
      accessor: 'sellerCode',
    },
    {
      Header: 'Master No.',
      accessor: 'masterNumber',
    },
    {
      Header: 'Corresponding E-mail',
      accessor: 'correspondingEmail',
    },

    {
      Header: 'Branch Name',
      accessor: 'branchName',
    },
    {
      Header: 'Action',
      id: 'edit',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          <button onClick={() => View(row.value)} className=' btn btn-sm'>
            <i className='fas fa-eye'></i>
          </button>
          &nbsp;
          <button onClick={() => Edit(row.value)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button>
          &nbsp;
          <button onClick={() => Delete(row.value)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  useEffect(async () => {
    await props.getMfis()
    if (id) {
      props.getMfiDetailsList(id)
    }
  }, [])

  useEffect(() => {
    if (props.distributionCenters && props.distributionCenters.length > 0) {
      setDistCentersList(
        props.distributionCenters.map(function (item) {
          return {
            value: item.id,
            label: item.distCenterName,
          }
        })
      )
    }
  }, [props.distributionCenters])

  useEffect(async () => {
    if (props.mfis && props.mfis.length > 0) {
      setMfiList(
        props.mfis.map(function (item) {
          return {
            value: item.id,
            label: item.mfiName,
          }
        })
      )
    }
  }, [props.mfis])

  const View = (id) => {
    setIsSelectAll(null)
    let tempObj = props.mfiDetails.find((detail) => detail.id === id)
    if (tempObj === null) {
      return
    }
    var selectedMfi = props.mfis.find((detail) => detail.id === tempObj.mfiId)
    setMfiName({
      label: selectedMfi.mfiName,
      value: selectedMfi.mfiId,
    })
    setDistCentersSelectedList(
      tempObj.distCentersArray.map(function (item) {
        return {
          value: props.distributionCenters.find((detail) => detail.id === item)
            .id,
          label: props.distributionCenters.find((detail) => detail.id === item)
            .distCenterName,
        }
      })
    )
    setCurrentDistributionMfiDetails({
      ...tempObj,
      DistCenters: DistCentersSelectedList,
      // Mfi: MfiName,
    })
    setModalShow(true)
    setReadOnly(true)
  }

  const Delete = (id) => {
    setIsSelectAll(null)
    let tempObj = props.mfiDetails.find((detail) => detail.id === id)
    if (
      props.qrOrTillDetails.filter((item) => item.mfiId === tempObj.mfiId)
        .length > 0
    ) {
      toast.warn(
        'First you have to delete QR/Till Records against this MFI Detail'
      )
      return
    }
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    props.deleteMfiDetails(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const Edit = (id) => {
    setIsSelectAll(null)
    let tempObj = props.mfiDetails.find((detail) => detail.id === id)
    if (tempObj === null) {
      return
    }
    var selectedMfi = props.mfis.find((detail) => detail.id === tempObj.mfiId)
    setMfiName({
      label: selectedMfi.mfiName,
      value: selectedMfi.mfiId ? selectedMfi.mfiId : selectedMfi.id,
    })
    if (tempObj.distCentersArray && tempObj.distCentersArray.length > 0)
      setDistCentersSelectedList(
        tempObj.distCentersArray.map(function (item) {
          return {
            value: props.distributionCenters.find(
              (detail) => detail.id === item
            ).id,
            label: props.distributionCenters.find(
              (detail) => detail.id === item
            ).distCenterName,
          }
        })
      )
    setCurrentDistributionMfiDetails({
      ...tempObj,
      DistCenters: tempObj.distCentersArray
        ? tempObj.distCentersArray.map(function (item) {
            return {
              value: props.distributionCenters.find((detail) =>
                detail.id === item.id ? item.id : item
              ).id,
              label: props.distributionCenters.find((detail) =>
                detail.id === item.id ? item.id : item
              ).distCenterName,
            }
          })
        : [],
      Mfi: selectedMfi.mfiId ? selectedMfi.mfiId : selectedMfi.id,
      mfiId: selectedMfi.mfiId ? selectedMfi.mfiId : selectedMfi.id,
    })
    setModalShow(true)
    setReadOnly(false)
    setIsEdit(true)
  }

  const OpenModelForAddMfiDetails = () => {
    setReadOnly(false)
    setMfiName(null)
    setDistCentersSelectedList([])

    setCurrentDistributionMfiDetails({ ...defaultDistributionMfiDetails })
    setModalShow(true)
    setIsEdit(false)
  }

  const handleSelectAll = (event) => {
    setIsSelectAll(event.target.checked)
    if (event.target.checked) {
      setDistCentersSelectedList(
        props.distributionCenters.map(function (item) {
          return {
            value: item.id,
            label: item.distCenterName,
          }
        })
      )

      setCurrentDistributionMfiDetails({
        ...currentDistributionMfiDetails,
        DistCenters: DistCentersList,
      })
    } else {
      setDistCentersSelectedList([])
      setCurrentDistributionMfiDetails({
        ...currentDistributionMfiDetails,
        DistCenters: [],
      })
    }
  }

  const handleSubmit = (data) => {
    if (!props.distributorMainInfo.id) {
      toast.warn('First you have to add distributor main information')
      return
    }
    // if (!validater.isEmail(data.correspondingEmail)) {
    //   toast.warn("Please enter valid e-mail address");
    //   return;
    // }
    var distCentersString = _.map(DistCentersSelectedList, 'value').join(',')
    var distCenterNamesString = _.map(DistCentersSelectedList, 'label').join(
      ','
    )
    if (!data.id) {
      let tempObj = props.mfiDetails.find((detail) => detail.mfiId === data.Mfi)
      if (tempObj != null) {
        toast.warn('MFI details already exist')
        return
      }
      props.addMfiDetails({
        distributorId: props?.distributorMainInfo?.id, //data.distributorId,
        masterNumber: data.masterNumber,
        cbaNo: data.cbaNo,
        sellerCode: data.sellerCode,
        correspondingEmail: data.correspondingEmail,
        branchName: data.branchName,
        masterTillNo: data.masterTillNo,
        mfiId: data.Mfi,
        distributionCenters: distCenterNamesString,
        distCentersArray:
          distCentersString.length > 0 ? distCentersString.split(',') : [],
        distCenterNamesArray:
          distCentersString.length > 0 ? distCenterNamesString.split(',') : [],
      })
    } else {
      let tempObj = props.mfiDetails.find(
        (detail) => detail.mfiId === data.Mfi && detail.id !== data.id
      )
      if (tempObj != null) {
        toast.warn('MFI details already exist')
        return
      }
      props.updateMfiDetails(data.id, {
        id: data.id,
        distributorId: props.distributorMainInfo.id,
        masterNumber: data.masterNumber,
        cbaNo: data.cbaNo,
        sellerCode: data.sellerCode,
        correspondingEmail: data.correspondingEmail,
        branchName: data.branchName,
        masterTillNo: data.masterTillNo,
        mfiId: data.Mfi,
        distributionCenters: distCenterNamesString,
        distCentersArray:
          distCentersString.length > 0 ? distCentersString.split(',') : [],
        distCenterNamesArray:
          distCentersString.length > 0 ? distCenterNamesString.split(',') : [],
      })
    }
    setModalShow(false)
  }

  return (
    <div>
      <Row>
        <Col xs={12} md={12}>
          <Button
            className='form-btn pull-right'
            variant='primary'
            style={{ float: 'right' }}
            onClick={OpenModelForAddMfiDetails}
          >
            <i className='fa fa-plus' /> Add New MFI
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          {props.mfiDetails.length > 0 ? (
            <CustomDataTable
              data={props.mfiDetails}
              columns={columns}
              getTrProps={() => null}
            />
          ) : (
            <h4
              style={{
                display: 'flex',
                marginTop: '50px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              No MFI Details added yet
            </h4>
          )}
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Formik
          enableReinitialize
          initialValues={currentDistributionMfiDetails}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  MFI Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          readOnly={readOnly}
                          control='react-select'
                          type='Mfi'
                          label='MFI'
                          name='Mfi'
                          value={MfiName}
                          options={MfiList}
                          handleOnChange={(option) => {
                            setMfiName(option)
                            currentDistributionMfiDetails.Mfi = option.value
                          }}
                          formik={formik}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          control='input'
                          type='text'
                          label='Master No.'
                          readOnly={readOnly}
                          name='masterNumber'
                          handleOnChange={(e) => {
                            currentDistributionMfiDetails.masterNumber = e
                          }}
                          value={currentDistributionMfiDetails.masterNumber}
                          formik={formik}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={3}>
                      <div className='form-group'>
                        <FormikControl
                          control='input'
                          type='text'
                          label='CBA #'
                          readOnly={readOnly}
                          name='cbaNo'
                          handleOnChange={(e) => {
                            currentDistributionMfiDetails.cbaNo = e
                          }}
                          value={currentDistributionMfiDetails.cbaNo}
                          formik={formik}
                        />
                      </div>
                    </Col>
                    <Col xs={6} md={3}>
                      <div className='form-group'>
                        <FormikControl
                          control='input'
                          type='text'
                          label='Franchise Code'
                          readOnly={readOnly}
                          name='sellerCode'
                          handleOnChange={(e) => {
                            currentDistributionMfiDetails.sellerCode = e
                          }}
                          value={currentDistributionMfiDetails.sellerCode}
                          formik={formik}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          control='input'
                          type='text'
                          label='Corresponding E-mail'
                          readOnly={readOnly}
                          name='correspondingEmail'
                          handleOnChange={(e) => {
                            currentDistributionMfiDetails.correspondingEmail = e
                          }}
                          value={
                            currentDistributionMfiDetails.correspondingEmail
                          }
                          formik={formik}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={3}>
                      <div className='form-group'>
                        <FormikControl
                          control='input'
                          type='text'
                          label='Branch Name'
                          readOnly={readOnly}
                          name='branchName'
                          handleOnChange={(e) => {
                            currentDistributionMfiDetails.branchName = e
                          }}
                          value={currentDistributionMfiDetails.branchName}
                          formik={formik}
                        />
                      </div>
                    </Col>
                    <Col xs={6} md={3}>
                      <div className='form-group'>
                        <FormikControl
                          control='input'
                          type='text'
                          label='Master Till No.'
                          readOnly={readOnly}
                          name='masterTillNo'
                          handleOnChange={(e) => {
                            currentDistributionMfiDetails.masterTillNo = e
                          }}
                          value={currentDistributionMfiDetails.masterTillNo}
                          formik={formik}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      {!readOnly ? (
                        <>
                          <div
                            className='form-group'
                            style={{ marginBottom: '0px' }}
                          >
                            <div className='custom-check'>
                              <label
                                className='form-check-label'
                                htmlFor='isAvaiableAll'
                              >
                                <input
                                  type='checkbox'
                                  id='isAvaiableAll'
                                  value={isSelectAll}
                                  onChange={handleSelectAll}
                                  style={{ margin: '10px' }}
                                />
                                Available at all Distribution Centers
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          readOnly={readOnly}
                          control='react-select'
                          type='DistCenters'
                          label='Distribution Centers'
                          name='DistCenters'
                          isMultiSelect={true}
                          formik={formik}
                          isSelectAll={isSelectAll}
                          options={DistCentersList}
                          handleOnChange={(value) => {
                            setDistCentersSelectedList(value)
                            currentDistributionMfiDetails.DistCenters = value
                          }}
                          value={DistCentersSelectedList}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                {readOnly === false ? (
                  <>
                    <input
                      type='submit'
                      className='btn btn-primary'
                      value={
                        isEdit === false
                          ? 'Add MFI Details'
                          : 'Update MFI Details'
                      }
                    />
                    {isEdit === false ? (
                      // <button
                      //   className="btn btn-default"
                      //   onClick={handleReset.bind(null, formik.resetForm)}
                      //   type="button"
                      // >
                      //   Reset
                      // </button>
                      <></>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <Button
                  className='btn btn-default'
                  onClick={() => setModalShow(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo
      ? state.distributorMainInfo
      : {},
    mfiDetails: state.mfiDetails ? state.mfiDetails : [],
    distributionCenters: state.distributionCenters
      ? state.distributionCenters
      : [],
    mfis: state.MFIs ? state.MFIs : [],
    qrOrTillDetails: state.qrOrTillDetails ? state.qrOrTillDetails : [],
  }
}
export default connect(mapStateToProps, {
  addMfiDetails,
  getMfiDetails,
  updateMfiDetails,
  deleteMfiDetails,
  getMfiDetailsList,
  getMfis,
})(ManageMfiDetails)
