import React from 'react'
import { useAsyncDebounce } from 'react-table' // new

// Define a default UI for filtering
function ServerSideGlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  totalRecords,
  searchChanged,
}) {
  // const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    // setGlobalFilter(value || undefined)
    searchChanged(value || '')
  }, 1000)

  return (
    <span>
      <input
        style={{ marginBottom: 6, width: '40%', float: 'right' }}
        className='form-control search-size'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Search from ${totalRecords} Records...`}
      />
    </span>
  )
}
export default ServerSideGlobalFilter
