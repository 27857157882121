import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import reduxThunk from 'redux-thunk'
import rootReducer from './reducers/Index'
import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
toast.configure()
//
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(reduxThunk, createLogger()))
)
Sentry.init({
  dsn: 'https://e68831f48b574dd3afaf9104c77badc8@o1397440.ingest.sentry.io/6726372',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)
