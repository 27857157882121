import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
// import storage from "../../../apis/Storage";
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  ADD_QRTILLDETAILS,
  GET_QRTILLDETAILS,
  DELETE_QRTILLDETAILS,
  UPDATE_QRTILLDETAILS,
  UPLOAD_QRTILLDETAILS,
  GET_MFI_DETAILSLIST,
  GET_QRTILL_LIST,
  GET_QRTILL_MFI_FILTER_LIST,
} from '../../../Types'

export const addQRorTillDetails =
  (formValues, distCenterName, MfiName) => async (dispatch) => {
    dispatch(showLoading())
    const formData = new FormData()
    formData.append('DistributorId', formValues.distributorId)
    formData.append('MerchantId', formValues.merchantId)
    formData.append('DistCenterId', formValues.distCenterId)
    formData.append('ResourceName', formValues.resourceName)
    formData.append('TillNumber', formValues.tillNumber)
    formData.append('Msisdn', formValues.msisdn)
    formData.append('Notifier1', formValues.notifier1)
    formData.append('Notifier2', formValues.notifier2)
    formData.append('Email', formValues.email)
    formData.append('RouteNo', formValues.routeNo)
    formData.append('Address', formValues.address)
    formData.append('QrImage', formValues.qrImage)
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }
    await axios
      .post('api/MfiMerchantTill/MfiMerchantTillWithUserAdd', formData, config)
      .then(({ data }) => {
        formValues.id = data.response.id
        formValues.mfiName = MfiName
        formValues.distCenterName = distCenterName
        formValues.qrImagePath = data.response.qrImagePath
        dispatch({
          type: ADD_QRTILLDETAILS,
          payload: formValues,
        })
        dispatch(hideLoading())
        toast.success('Record added successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }

export const getQRorTillDetails = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(
      `api/MfiMerchantTill/GetMfiMerchantTillByDistributor/${distributorId}/distributor`
    )
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_QRTILLDETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateQRorTillDetails =
  (id, formValues, distCenterName, MfiName) => async (dispatch) => {
    dispatch(showLoading())
    const formData = new FormData()
    formData.append('Id', formValues.id)
    formData.append('DistributorId', formValues.distributorId)
    formData.append('MerchantId', formValues.merchantId)
    formData.append('DistCenterId', formValues.distCenterId)
    formData.append('ResourceName', formValues.resourceName)
    formData.append('TillNumber', formValues.tillNumber)
    formData.append('Msisdn', formValues.msisdn)
    formData.append('Notifier1', formValues.notifier1)
    formData.append('Notifier2', formValues.notifier2)
    formData.append('Email', formValues.email)
    formData.append('RouteNo', formValues.routeNo)
    formData.append('Address', formValues.address)
    formData.append('QrImage', formValues.qrImage)
    formData.append(
      'QrImagePath',
      formValues.qrImagePath && formValues.qrImagePath !== 'null'
        ? formValues.qrImagePath
        : ''
    )
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }
    await axios
      .put(`api/MfiMerchantTill/${id}`, formData, config)
      .then(({ data }) => {
        formValues.mfiName = MfiName
        formValues.distCenterName = distCenterName
        formValues.qrImagePath = data.qrImagePath
        dispatch({
          type: UPDATE_QRTILLDETAILS,
          payload: formValues,
        })
        dispatch(hideLoading())
        toast.success('Record updated successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }

export const deleteQRorTillDetails = (id) => async (dispatch) => {
  if (!id) return
  dispatch(showLoading())
  await axios
    .delete(`api/MfiMerchantTill/SoftDelete/${id}`)
    .then(({ data }) => {
      dispatch(hideLoading())
      if (data.isSuccess) {
        dispatch({ type: DELETE_QRTILLDETAILS, payload: id })
        toast.success(data.message)
      }
      else {
        toast.error(data.message)
      }
    })
    .catch(function (error) {
      dispatch(hideLoading())
      toast.error('Error while processing the request')
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const saveExcelSheetData = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  const formData = new FormData()
  formData.append('DistributorId', formValues.distributorId)
  formData.append('MerchantId', formValues.merchantId)
  formData.append('DistCenterId', formValues.distCenterId)
  formData.append('MfiName', formValues.mfiName)
  formData.append('DataFile', formValues.dataSheetFile)
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  }
  await axios
    .post('api/MfiMerchantTill/UploadDataSheet', formData, config)
    .then(({ data }) => {
      if (data.status == 'Error') {
        toast.error(data.message)
      } else {
        dispatch({ type: UPLOAD_QRTILLDETAILS, payload: data.response })
        toast.success('QR/Till Data Sheet added successfully')
      }
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const getMfiDetailsList = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(
      `api/MfiMerchant/GetDistributionMfiDetailsByDistributor/${distributorId}`
    )
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_MFI_DETAILSLIST,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getQRorTillList = (res) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/MfiMerchantTill/GetAllDistributorsTill`, res)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_QRTILL_LIST,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getFilterMfiList = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(
      `api/MfiMerchant/GetDistributionMfiDetailsByDistributor/${distributorId}`
    )
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_QRTILL_MFI_FILTER_LIST,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      toast.error('Error while processing the request')
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
