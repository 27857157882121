import React, { Component, useEffect, useState, useRef } from 'react'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { connect } from 'react-redux'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { isPermittedPage } from '../../../apis/Utility'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import { getDropdownList } from '../../Shared/dropdownListActions'

import { Redirect, Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import ExcelIcon from '../../../assets/Images/Distributors/excel-icon.png'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import UploadAgentsTemplateFile from '../../../assets/Templates/UploadAgentsTemplateFile.xlsx'
import { getMfis } from '../../MFIs/MFIAction'
import ReactSelect from 'react-select'
import Input from '../../../layouts/Input/Input'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'

import {
  addAgents,
  getAgents,
  deleteAgent,
  getSingleAgentDetails,
  updateAgentDetails,
  getAgentShops,
  tagAgentShop,
} from './OnBoardingAgentsListAction'
import { GET_CITIES, GET_RSO_USERS } from '../../../Types'
import { searchVizShops } from '../../VizlinkShops/JobsList/JobDetails/JobDetailsAction'

const OnBoardingAgentsList = (props) => {
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterValuesModal, setFilterValuesModal] = useState({
    city: '',
    area: '',
    mfi: '',
    agentType: '',
    rso: '',
  })

  const [filterCity, setFilterCity] = useState(null)
  const [filterArea, setFilterArea] = useState(null)
  const [filterMfi, setFilterMfi] = useState(null)
  const [filterAgentType, setFilterAgentType] = useState(null)
  const [filterRSO, setFilterRSO] = useState(null)

  const [showViewFilter, setShowViewFilter] = useState(false)
  const [targetViewFilter, setTargetViewFilter] = useState(null)
  const refViewFilter = useRef(null)

  const [filterValuesModalViewFilter, setFilterValuesModalViewFilter] =
    useState({
      city: '',
      area: '',
      distance: '',
    })

  const [filterCityView, setFilterCityView] = useState(null)
  const [filterAreaView, setFilterAreaView] = useState(null)
  const [filterDistanceView, setFilterDistanceView] = useState(null)
  const [filterSearch, setFilterSearch] = useState(null)

  const [uploadDataModalShow, setUploadDataModalShow] = useState(false)
  const [dataSheetSelectedFile, setDataSheetSelectedFile] = useState(null)
  const [MfiList, setMfiList] = useState([])
  const [selectedMFI, setSelectedMFI] = useState('')
  const [viewAgentModalShow, setViewAgentModalShow] = useState(false)
  const [editAgentModalShow, setEditAgentModalShow] = useState(false)

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [agentsList, setAgentsList] = useState([])

  const initialValues = {
    vizID: '',
    shopName: '',
    shopCategory: '',
    address: '',
    contactNo: '',
  }
  const [currentAgentDetail, setCurrentAgentDetail] = useState(initialValues)
  const [showAgentShops, setShowAgentShops] = useState(false)

  const validationSchema = Yup.object().shape({
    vizID: Yup.string(),
    shopName: Yup.string().required('Shop Name is required'),
    shopCategory: Yup.string().required('Shop Category is Required'),
    address: Yup.string().required('Address is Required'),
    contactNo: Yup.string().required('Contact Number is Required'),
  })

  const columns = [
    {
      Header: 'VIZ ID',
      accessor: 'vizId',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Shop Address',
      accessor: 'shopAddress',
    },
    {
      Header: 'Contact No.',
      accessor: 'contactNumber',
    },
    {
      Header: 'CNIC',
      accessor: 'cnic',
    },
    {
      Header: 'RSO Name',
      accessor: 'rsoName',
    },
    {
      Header: 'Registered MFI',
      accessor: 'registeredMfi',
    },
    {
      Header: 'Date of Application',
      accessor: 'dateOfApplication',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          {isPermittedPage('View Details', props.actions, props.userRole) &&
            <button
              onClick={() => viewAgentDetails(row.value)}
              className=' btn btn-sm'
            >
              <i className='fas fa-eye'></i>
            </button>
          }
          &nbsp;
          {/* <button
            onClick={() => editAgentDetails(row.value)}
            className=' btn btn-sm'
          >
            <i className='fas fa-edit'></i>
          </button> */}
          &nbsp;

          {isPermittedPage('Delete', props.actions, props.userRole) &&
            <button onClick={() => Delete(row.value)} className='btn btn-sm'>
              <i className='fas fa-trash'></i>
            </button>
          }
        </div>
      ),
    },
  ]

  const modalColumns = [
    {
      Header: 'VIZ ID',
      accessor: 'vizId',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Average Sale',
      accessor: 'avgSale',
    },
    {
      Header: 'RSO Name',
      accessor: 'rsoName',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'Distance From The Agent',
      accessor: 'distance',
    },
    {
      Header: 'Decision',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <Link
            onClick={() => tagAgentShop(row.value)}
            style={{ cursor: 'pointer', width: '106px' }}
            to='#'
          >
            {props.OnBoardingAgentsList?.agentShopsList.filter(
              (x) => x.id == row.value
            )[0].isTagged == false
              ? 'Tag'
              : 'Untag'}
          </Link>
        </div>
      ),
    },
  ]

  const agentTypeOptions = [
    { label: 'T1', value: 'T1' },
    { label: 'T2', value: 'T2' },
    { label: 'T3', value: 'T3' },
    { label: 'T4', value: 'T4' },
  ]

  const distanceOptions = [
    { label: '100', value: '100' },
    { label: '500', value: '500' },
    { label: '1000', value: '1000' },
    { label: '1500', value: '1500' },
    { label: '2000', value: '2000' },
  ]

  const openUploadDataSheet = () => {
    setSelectedMFI('')
    setDataSheetSelectedFile(null)
    setUploadDataModalShow(true)
  }

  const tagAgentShop = (id) => {
    let data = {
      vizShopId: id,
      vizAgentId: props.OnBoardingAgentsList?.selectedAgentDetails?.id,
    }
    props.tagAgentShop(data)
  }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterCity(null)
    setFilterArea(null)
    setFilterMfi(null)
    setFilterAgentType(null)
    setFilterRSO(null)
    setFilterValuesModal({
      city: '',
      area: '',
      mfi: '',
      agentType: '',
      rso: '',
    })
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    props.getAgents({
      City: event.city == '' ? null : event.city,
      MfiId: event.mfi == '' ? null : event.mfi,
      AgentType: event.agentType == '' ? null : event.agentType,
      Rso: event.rso == '' ? null : event.rso,

      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    })
    setShow(false)
  }

  const openFilterModalView = (event) => {
    setShowViewFilter(!show)
    setTargetViewFilter(event.target)
  }

  const handleResetFiltersView = (resetForm) => {
    resetForm()
    setFilterCityView(null)
    setFilterAreaView(null)
    setFilterDistanceView(null)
    setFilterValuesModalViewFilter({
      city: '',
      area: '',
      distance: '',
    })
  }

  const LoadDataByfilterView = (event) => {
    let coord =
      currentAgentDetail.geoTag == null ? 'N/A' : currentAgentDetail.geoTag
    props.getAgentShops({
      City: filterCityView?.value ? filterCityView?.value : null,
      Distance: filterDistanceView?.value ? filterDistanceView?.value : 0,
      LatLong: coord,
      VizAgentId: currentAgentDetail.id,
    })
    setShowViewFilter(false)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      City: filterCity?.value,
      MfiId: filterMfi?.value,
      AgentType: filterAgentType?.value,
      Rso: filterRSO?.value,
    }
    props.getAgents(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      City: filterCity?.value,
      MfiId: filterMfi?.value,
      AgentType: filterAgentType?.value,
      Rso: filterRSO?.value,
    }
    props.getAgents(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const DeleteRecord = async (id) => {
    await props.deleteAgent(id)

    if (totalRecords > 5 && agentsList.length == 1) {
      let previousPage = currentPageNo > 0 ? currentPageNo - 1 : 0
      setCurrentPageNo(previousPage)
      let requestData = {
        pageSize: currentPageSize,
        pageNo: previousPage,
        searchText: searchText,
        City: filterCity?.value,
        MfiId: filterMfi?.value,
        AgentType: filterAgentType?.value,
        Rso: filterRSO?.value,
      }
      props.getAgents(requestData)
    } else {
      let requestData = {
        pageSize: currentPageSize,
        pageNo: currentPageNo,
        searchText: searchText,
        City: filterCity?.value,
        MfiId: filterMfi?.value,
        AgentType: filterAgentType?.value,
        Rso: filterRSO?.value,
      }
      props.getAgents(requestData)
    }
  }

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      City: filterCity?.value,
      MfiId: filterMfi?.value,
      AgentType: filterAgentType?.value,
      Rso: filterRSO?.value,
    }
    props.getAgents(requestData)
  }, [searchText])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowViewFilter(false)
      let coord =
        currentAgentDetail.geoTag == null ? 'N/A' : currentAgentDetail.geoTag
      if (currentAgentDetail?.vizId) {
        props.getAgentShops({
          City: filterCityView?.value ? filterCityView?.value : null,
          Distance: filterDistanceView?.value ? filterDistanceView?.value : 0,
          SearchText: filterSearch,
          LatLong: coord,
          VizAgentId: currentAgentDetail.id,
        })
      }
    }, 800)

    return () => clearTimeout(timer)
  }, [filterSearch])

  const validateDataSheetFile = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return
    }
    if (!image.name.match(/\.(xlsx|xls)$/)) {
      toast.error('You can only upload file of type (xlsx, xls)')
      event.target.value = null
      return
    } else {
      setDataSheetSelectedFile(event.target.files[0])
    }
  }

  const viewAgentDetails = (id) => {
    setFilterCityView(null)
    setFilterAreaView(null)
    setFilterDistanceView(null)

    setCurrentAgentDetail(null)
    let coord = agentsList.filter((x) => x.id == id)[0].geoTag
    if (coord == 'N/A') {
      setShowAgentShops(false)
    } else {
      props.getAgentShops({ LatLong: coord, VizAgentId: id })
      setShowAgentShops(true)
    }
    props.getSingleAgentDetails(id)
    setViewAgentModalShow(true)
  }

  const editAgentDetails = (id) => {
    props.getSingleAgentDetails(id)
    setEditAgentModalShow(true)
  }

  const submitAgentData = async () => {
    if (selectedMFI == '') {
      toast.error('Please select MFI')
    } else if (dataSheetSelectedFile != null) {
      await props.addAgents({
        mfiId: selectedMFI.value,
        DataFile: dataSheetSelectedFile,
      })
      props.getAgents({
        pageSize: currentPageSize,
        pageNo: currentPageNo,
        searchText: searchText,
      })
      setUploadDataModalShow(false)
    } else {
      toast.error('Please select Agent data sheet')
    }
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  useEffect(async () => {
    props.getMfis()
    props.getDropdownList(`api/City`, GET_CITIES)
    props.getDropdownList(`api/User/GetRSODDL`, GET_RSO_USERS)
  }, [])

  useEffect(async () => {
    if (props.mfis && props.mfis.length > 0) {
      setMfiList(
        props.mfis.map(function (item) {
          return {
            value: item.id,
            label: item.mfiName,
          }
        })
      )
    }
  }, [props.mfis])

  useEffect(() => {
    if (props.OnBoardingAgentsList?.agentsList) {
      setAgentsList(props.OnBoardingAgentsList?.agentsList)
      if (props.OnBoardingAgentsList?.agentsList.length > 0) {
        setTotalRecords(props.OnBoardingAgentsList?.agentsList[0].totalRecords)
      } else {
        setTotalRecords(0)
      }
    }
  }, [props.OnBoardingAgentsList?.agentsList])

  useEffect(async () => {
    setCurrentAgentDetail(props.OnBoardingAgentsList?.selectedAgentDetails)
  }, [props.OnBoardingAgentsList?.selectedAgentDetails])

  return (
    <div>
      <VzPageContainer title='VizLink Agents List'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>VizLink Agents List</b>
            </h4>
          </Col>
        </Row>

        {isPermittedPage('Upload Data Sheet', props.actions, props.userRole) &&
          <Row style={{ marginBottom: 30 }}>
            <Col>
              <div className='form-group'>
                <Button
                  className='form-btn'
                  variant='primary'
                  onClick={() => openUploadDataSheet()}
                >
                  Upload Data Sheet
                </Button>
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col md={3} style={{ float: 'right' }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='City'
                                name='city'
                                options={props.cities}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.city = e.value
                                  setFilterCity({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterCity}
                              />
                            </Col>
                            {/* <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Area'
                                name='area'
                                options={[]}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.area = e.value
                                  setFilterArea({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterArea}
                              />
                            </Col> */}
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='MFI'
                                name='mfi'
                                options={MfiList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.mfi = e.value
                                  setFilterMfi({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterMfi}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='AgentType'
                                name='agentType'
                                options={agentTypeOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.agentType = e.value
                                  setFilterAgentType({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterAgentType}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='RSO'
                                name='rso'
                                options={props.rsoUsers}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.rso = e.value
                                  setFilterRSO({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterRSO}
                              />
                            </Col>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        {/* <CustomDataTable
          data={props.OnBoardingAgentsList?.agentsList}
          columns={columns}
          getTrProps={(event, data) => null}
        /> */}
        <ServerSideDataTable
          data={agentsList ? agentsList : []}
          columns={columns}
          getTrProps={(event, data) => null}
          goToPreviousPage={(val) => getData(val)}
          goToNextPage={(val) => getData(val)}
          totalRecords={totalRecords}
          pageSizeChange={(size) => pageSizeChanged(size)}
          myPageSize={currentPageSize}
          myIndex={currentIndex}
          pageNo={currentPageNo}
          searchChanged={searchChanged}
        />
        <Modal
          show={uploadDataModalShow}
          onHide={() => setUploadDataModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Add New Agent Data
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize
              initialValues={[]}
              validationSchema={null}
              onSubmit={submitAgentData}
            >
              {(formik) => (
                <Form encType='multipart/form-data'>
                  <Container>
                    <Row>
                      <Col md={{ span: 5 }} style={{ float: 'right' }}>
                        <div className='form-group'>
                          <ReactSelect
                            id='mfi'
                            name='mfi'
                            className='basic-single'
                            classNamePrefix='select'
                            placeholder='Select MFI'
                            isMulti={false}
                            value={selectedMFI}
                            isClearable={true}
                            onChange={(value) => setSelectedMFI(value)}
                            options={MfiList}
                          ></ReactSelect>
                        </div>
                      </Col>
                      <Col xs={12} md={{ span: 7, offset: 0 }}>
                        <div className='form-group'>
                          <div>
                            <div>Select Data Sheet File (Only Excel file)</div>
                            {dataSheetSelectedFile && (
                              <div>
                                <img
                                  style={{ border: '1px solid #ccc' }}
                                  alt='not fount'
                                  width={'100px'}
                                  height={'100px'}
                                  src={ExcelIcon}
                                />
                                <br />
                                <button
                                  className='btn btn-info'
                                  style={{
                                    marginLeft: '10px',
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                  }}
                                  onClick={() => {
                                    setDataSheetSelectedFile(null)
                                  }}
                                >
                                  <i className='fa fa-close' />
                                  Remove
                                </button>
                              </div>
                            )}
                            {!dataSheetSelectedFile && (
                              <div>
                                <br />
                                <input
                                  type='file'
                                  name='dataSheetSelectedFile'
                                  onChange={(event) =>
                                    validateDataSheetFile(event)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Button
                          className='btn btn-default'
                          style={{ float: 'right', marginTop: '10px' }}
                          onClick={() => setUploadDataModalShow(false)}
                        >
                          Cancel
                        </Button>
                        <input
                          type='submit'
                          className='btn btn-primary'
                          style={{
                            marginRight: '10px',
                            marginTop: '10px',
                            float: 'right',
                          }}
                          value='Save '
                        />

                        <a
                          href={`${UploadAgentsTemplateFile}`}
                          download='AgentsTemplateFile'
                          className='active'
                          style={{
                            padding: '0px',
                            marginTop: '20px',
                            float: 'left',
                          }}
                        >
                          Download Template
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        <Modal
          show={viewAgentModalShow}
          onHide={() => setViewAgentModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Agent Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Agent Category:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {' '}
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.agentType ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Agent Code:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {' '}
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.vizId ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={6} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Shop Name:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.shopName ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>MFI:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.registeredMfi ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Address:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {' '}
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.address ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={6} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Agent Status:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.agentStatus ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Retailer Number:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.retailorNumber ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>RSO:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.OnBoardingAgentsList?.selectedAgentDetails
                          ?.rsoName ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {showAgentShops ? (
                <>
                  <Row>
                    <h5 style={{ color: '#EA5F2D' }}>Agent Shops</h5>
                    <hr style={{ color: '#EA5F2D', width: '20%' }} />
                  </Row>
                  <Row>
                    <Col md={6} style={{ float: 'right' }}>
                      <div ref={refViewFilter}>
                        <Button
                          onClick={openFilterModalView}
                          className='m-3'
                          variant='outline-primary'
                        >
                          <i className='fa fa-filter' />
                          Filters
                        </Button>

                        <Overlay
                          show={showViewFilter}
                          target={targetViewFilter}
                          placement='bottom'
                          container={refViewFilter}
                          containerPadding={20}
                          width='430px'
                          style={{ width: '430px' }}
                        >
                          <Popover
                            id='popover-contained'
                            style={{ maxWidth: '360px', width: '360px' }}
                          >
                            <Popover.Header as='h3'>Filters</Popover.Header>
                            <Popover.Body>
                              <Formik
                                enableReinitialize
                                initialValues={filterValuesModalViewFilter}
                                validator={() => ({})}
                                onSubmit={LoadDataByfilterView}
                              >
                                {(formik) => (
                                  <Form autoComplete='off'>
                                    <Row>
                                      <Col xs={12} md={12}>
                                        <FormikControl
                                          formik={formik}
                                          control='react-select'
                                          label='City'
                                          name='city'
                                          options={props.cities}
                                          isSearchable={true}
                                          handleOnChange={(e) => {
                                            if (!e || !e.value) {
                                              return
                                            }
                                            filterValuesModalViewFilter.city =
                                              e.value
                                            setFilterCityView({
                                              label: e.label,
                                              value: e.value,
                                            })
                                          }}
                                          value={filterCityView}
                                        />
                                      </Col>
                                      <Col xs={12} md={12}>
                                        <FormikControl
                                          formik={formik}
                                          control='react-select'
                                          label='Area'
                                          name='area'
                                          options={[]}
                                          isSearchable={true}
                                          handleOnChange={(e) => {
                                            if (!e || !e.value) {
                                              return
                                            }
                                            filterValuesModalViewFilter.area =
                                              e.value
                                            setFilterAreaView({
                                              label: e.label,
                                              value: e.value,
                                            })
                                          }}
                                          value={filterAreaView}
                                        />
                                      </Col>
                                      <Col xs={12} md={12}>
                                        <FormikControl
                                          formik={formik}
                                          control='react-select'
                                          label='Distance'
                                          name='distance'
                                          options={distanceOptions}
                                          isSearchable={true}
                                          handleOnChange={(e) => {
                                            if (!e || !e.value) {
                                              return
                                            }
                                            filterValuesModalViewFilter.distance =
                                              e.value
                                            setFilterDistanceView({
                                              label: e.label,
                                              value: e.value,
                                            })
                                          }}
                                          value={filterDistanceView}
                                        />
                                      </Col>
                                    </Row>
                                    <div
                                      className='row'
                                      style={{ marginTop: 20 }}
                                    >
                                      <div className='form-group'>
                                        <Link
                                          onClick={handleResetFiltersView.bind(
                                            null,
                                            formik.resetForm
                                          )}
                                          to='#'
                                          className='active'
                                          style={{
                                            padding: '0px',
                                            marginTop: '10px',
                                            float: 'left',
                                          }}
                                        >
                                          Reset
                                        </Link>
                                        <Link
                                          onClick={() => {
                                            setShowViewFilter(false)
                                            setTargetViewFilter(null)
                                          }}
                                          to='#'
                                          className='active'
                                          style={{
                                            padding: '0px',
                                            marginTop: '10px',
                                            float: 'right',
                                          }}
                                        >
                                          Cancel
                                        </Link>
                                        <div className='float-right'>
                                          <Button
                                            type='submit'
                                            className='btn btn-primary'
                                            style={{
                                              marginRight: '10px',
                                              float: 'right',
                                            }}
                                          >
                                            Apply
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </Form>
                                )}
                              </Formik>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </div>
                    </Col>
                    <Col md={6} style={{ float: 'right' }}>
                      <Formik
                        enableReinitialize
                        initialValues={[]}
                        validationSchema={null}
                        onSubmit={submitAgentData}
                      >
                        {(formik) => (
                          <Form autoComplete='off'>
                            <div className='form-group'>
                              <FormikControl
                                formik={formik}
                                control='input'
                                type='search'
                                label='Search Record'
                                name='search'
                                value={filterSearch}
                                handleOnChange={(e) => setFilterSearch(e)}
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                  <Row>
                    <CustomDataTable
                      IsSearchEnable={false}
                      data={props.OnBoardingAgentsList?.agentShopsList}
                      columns={modalColumns}
                      getTrProps={(event, data) => null}
                      IsPagingEnable={false}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className='btn btn-default'
                        style={{ float: 'right', marginTop: '10px' }}
                        onClick={() => setViewAgentModalShow(false)}
                      >
                        Cancel
                      </Button>
                      <input
                        type='submit'
                        className='btn btn-primary'
                        style={{
                          marginRight: '10px',
                          marginTop: '10px',
                          float: 'right',
                        }}
                        value='Save '
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Container>
          </Modal.Body>
        </Modal>
        <Modal
          show={editAgentModalShow}
          onHide={() => setEditAgentModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={currentAgentDetail}
            validationSchema={validationSchema}
            onSubmit={null}
          >
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    Agent Details
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='shopName'
                            label='Shop Name'
                            name='shopName'
                            value={currentAgentDetail.shopName}
                            handleOnChange={(value) =>
                              (currentAgentDetail.shopName = value)
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='shopCategory'
                            label='Shop Category'
                            name='shopCategory'
                            value={currentAgentDetail.shopCategory}
                            handleOnChange={(value) =>
                              (currentAgentDetail.shopCategory = value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='address'
                            label='Address'
                            name='address'
                            value={currentAgentDetail.address}
                            handleOnChange={(value) =>
                              (currentAgentDetail.address = value)
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='contactNo'
                            label='Contact #'
                            name='contactNo'
                            value={currentAgentDetail.contactNo}
                            handleOnChange={(value) =>
                              (currentAgentDetail.contactNo = value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <div>
                    <input
                      type='submit'
                      className='btn btn-primary'
                      value={'Update Agent Detail'}
                    />
                  </div>
                  <Button
                    className='btn btn-default'
                    onClick={() => setEditAgentModalShow(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    mfis: state.MFIs ? state.MFIs : [],
    OnBoardingAgentsList: state.OnBoardingAgentsList
      ? state.OnBoardingAgentsList
      : [],
    cities: state.ddlList.cities ? state.ddlList.cities : [],
    rsoUsers: state.ddlList.rsoUsers ? state.ddlList.rsoUsers : [],
  }
}
export default connect(mapStateToProps, {
  getMfis,
  addAgents,
  getAgents,
  deleteAgent,
  getSingleAgentDetails,
  updateAgentDetails,
  getDropdownList,
  getAgentShops,
  tagAgentShop,
})(OnBoardingAgentsList)
