import React from 'react'
import { useAsyncDebounce } from 'react-table' // new

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      <input
        style={{ marginBottom: 6, width: '40%', float: 'right' }}
        className='form-control search-size'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Search from ${count} Records...`}
      />
    </span>
  )
}
export default GlobalFilter
