import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import 'react-confirm-alert/src/react-confirm-alert.css'
import FormikControl from '../../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'
import Moment from 'react-moment'
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
  Container,
} from 'react-bootstrap'
import { VzPageContainer } from '../../../../layouts/VzPageContainer'
import CustomDataTable from '../../../../layouts/DataTables/CustomDataTable'
import {
  getRsoJobDetails,
  updateRsoShopStatus,
  searchVizShops,
  updateShopsRsoDetail,
  linkDistributorShopToVizLinkShop,
  unLinkDistributorShopToVizLinkShop,
} from './RsoJobDetailsAction'
import UpdateShopsDataFileTemplate from '../../../../assets/Templates/UpdateShopsDataFileTemplate.xlsx'
import ExcelIcon from '../../../../assets/Images/Distributors/excel-icon.png'
import ServerSideDataTable from '../../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'

const RsoJobDetails = (props) => {
  const { id, rsoId } = useParams()
  const [shopTypeFilter, setShopTypeFilter] = useState(null)
  const [paymentModeFilter, setPaymentModeFilter] = useState(null)
  const [linkShopModal, setLinkShopModal] = useState(false)
  const [vizShopsData, setVizShopsData] = useState([])
  const [vizShopId, setVizShopId] = useState('')
  const [selectedDistributorShop, setSelectedDistributorShop] = useState(null)
  const [vizShopDetails, setVizShopDetails] = useState(null)
  const [distShopsList, setDistShopsList] = useState([])
  const [updateShopsModalShow, setUpdateShopsModalShow] = useState(false)
  const [shopDetailsModalShow, setShopDetailsModalShow] = useState(false)
  const validationPrincipalSchema = Yup.object().shape({
    vizShopId: Yup.string().required('Viz Shop ID is required'),
  })
  const [dataSheetSelectedFile, setDataSheetSelectedFile] = useState(null)

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)

  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [shopType, setShopType] = useState('')
  const [paymentMode, setPaymentMode] = useState('')

  const getData = (page) => {
    setCurrentPageNo(page)
    props.getRsoJobDetails(
      id,
      rsoId,
      currentPageSize,
      page,
      searchText,
      shopType == '' ? shopType : shopType.value,
      paymentMode == '' ? paymentMode : paymentMode.value
    )
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    props.getRsoJobDetails(
      id,
      rsoId,
      pageSize,
      0,
      searchText,
      shopType == '' ? shopType : shopType.value,
      paymentMode == '' ? paymentMode : paymentMode.value
    )
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  useEffect(() => {
    if (id && rsoId) {
      props.getRsoJobDetails(
        id,
        rsoId,
        currentPageSize,
        currentPageNo,
        searchText,
        shopType == '' ? shopType : shopType.value,
        paymentMode == '' ? paymentMode : paymentMode.value
      )
    }
  }, [searchText])

  useEffect(() => {
    setSearchText('')
    if (id && rsoId) {
      props.getRsoJobDetails(
        id,
        rsoId,
        currentPageSize,
        currentPageNo,
        searchText,
        shopType.value,
        paymentMode.value
      )
    }
  }, [shopType, paymentMode])

  useEffect(() => {
    if (props.jobDetails) {
      if (
        props.jobDetails.distributorShops &&
        props.jobDetails.distributorShops.length > 0
      ) {
        handleShopTypesFilter(shopTypeFilter)
        handlePaymentModeFilter(paymentModeFilter)
        // setDistShopsList(props.jobDetails.distributorShops)
        setTotalRecords(props.jobDetails.totalRecords)
      } else setDistShopsList([])
    }
  }, [props.jobDetails.distributorShops])

  useEffect(() => {
    if (props.jobDetails) {
      if (
        props.jobDetails.vizlinkShops &&
        props.jobDetails.vizlinkShops.length > 0
      ) {
        setVizShopsData(props.jobDetails.vizlinkShops)
        setTotalRecords(props.jobDetails.totalRecords)
      } else setVizShopsData([])
    }
  }, [props.jobDetails.vizlinkShops])

  // useEffect(() => {
  //   if (id && rsoId) {
  //     props.getRsoJobDetails(id, rsoId)
  //   }
  // }, [])

  //#region Selection Options and datatable columns

  const shopTypesOptions = [
    { value: 'Not Matched', label: 'Not Matched' },
    { value: 'Matched', label: 'Matched' },
    { value: 'Match To Many', label: 'Match To Many' },
  ]
  const paymentModeOptions = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Bank', label: 'Bank' },
  ]
  const columns = [
    {
      Header: 'Van No.',
      accessor: 'vanNo',
      sortType: 'basic',
    },
    {
      Header: 'Shop ID',
      accessor: 'shopCode',
      filter: 'includes', // new
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'CNIC',
      accessor: 'cnic',
    },
    {
      Header: 'Shop Address',
      accessor: 'shopAddress',
    },
    {
      Header: 'Date',
      accessor: 'rsoVisitDate',
      Cell: (row) => (
        <div style={{ width: '115px' }}>
          {row.value ? (
            <Moment format='ddd DD-MM-YY'>{row.value}</Moment>
          ) : (
            '--'
          )}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'inductionStatus',
      Cell: (row) => <strong>{row.value ? row.value : '--'}</strong>,
    },

    // {
    //   Header: 'RSO Name',
    //   accessor: 'rsoName',
    //   Cell: (row) => <strong>{row.value ? row.value : '--'}</strong>,
    // },
  ]
  const vizShopsListColumns = [
    {
      Header: 'VizShop ID',
      accessor: 'vizShopCode',
      filter: 'includes', // new
    },
    {
      Header: 'VizShop Name',
      accessor: 'vizShopName',
    },
    {
      Header: 'Shop Address',
      accessor: 'address',
    },
    {
      Header: 'Agent',
      accessor: 'agent',
      Cell: (row) => <strong>{row.value ? row.value : '--'}</strong>,
    },
    {
      Header: 'Distributors',
      accessor: 'distributors',
      Cell: (row) => <strong>{row.value ? row.value : '--'}</strong>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (row) => <strong>{row.value ? row.value : '--'}</strong>,
    },
    {
      Header: 'Link Shop',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div>
          {original.id == selectedDistributorShop.vizShopId ? (
            <>
              <Button
                variant='primary'
                title='Un Link Shop'
                onClick={() =>
                  clickToUnLinkShop(selectedDistributorShop.id, original.id)
                }
              >
                Un-Link
              </Button>
            </>
          ) : (
            <>
              <Button
                variant='primary'
                title='Link Shop'
                onClick={() => clickToLinkShop(original.id)}
              >
                Link
              </Button>
            </>
          )}
          <Button
            variant='link'
            title='Link Shop'
            onClick={() => openModalForShopDetails(original)}
          >
            View Details
          </Button>
        </div>
      ),
    },
  ]
  //#endregion

  const clickToUnLinkShop = (shopId, vizShopId) => {
    props.unLinkDistributorShopToVizLinkShop(shopId, vizShopId)
    setLinkShopModal(false)
  }
  const clickToLinkShop = (vizShopId) => {

    props.linkDistributorShopToVizLinkShop(
      selectedDistributorShop.id,
      vizShopId
    )
    setLinkShopModal(false)
  }

  const openModalForLinkOrUnlinkShops = (distShop) => {
    setVizShopId(null)
    setSelectedDistributorShop(distShop)
    loadVizShopsByDistShopId(distShop.id)
    setLinkShopModal(true)
  }

  const handleShopTypesFilter = (option) => {
    if (!props.jobDetails.distributorShops) {
      return
    }
    if (!option || !option.value) {
      setShopTypeFilter(null)
      if (paymentModeFilter && paymentModeFilter.value) {
        let tempData = props.jobDetails.distributorShops.filter(
          (x) => x.paymentMode == paymentModeFilter.value
        )
        if (tempData) setDistShopsList(tempData)
        else setDistShopsList([])
      } else setDistShopsList(props.jobDetails.distributorShops)
      return
    }
    setShopTypeFilter(option)
    let filteredData = props.jobDetails.distributorShops.filter(
      (x) => x.inductionMatchingStatus == option.value
    )
    if (filteredData && filteredData.length > 0) {
      if (paymentModeFilter && paymentModeFilter.value) {
        filteredData = filteredData.filter(
          (x) => x.paymentMode == paymentModeFilter.value
        )
        if (filteredData) setDistShopsList(filteredData)
        else setDistShopsList([])
      } else setDistShopsList(filteredData)
    } else setDistShopsList([])
  }
  const handlePaymentModeFilter = (option) => {
    if (!props.jobDetails.distributorShops) {
      return
    }
    // setShopTypeFilter(null)
    if (!option || !option.value) {
      setPaymentModeFilter(null)
      if (shopTypeFilter && shopTypeFilter.value) {
        let tempData = props.jobDetails.distributorShops.filter(
          (x) =>
            x.inductionMatchingStatus.toUpperCase() ==
            shopTypeFilter.value.toUpperCase()
        )
        if (tempData) setDistShopsList(tempData)
        else setDistShopsList([])
      } else setDistShopsList(props.jobDetails.distributorShops)
      return
    }
    setPaymentModeFilter(option)
    let filteredData = props.jobDetails.distributorShops.filter(
      (x) => x.paymentMode.toUpperCase() == option.value.toUpperCase()
    )
    if (filteredData && filteredData.length > 0) {
      if (shopTypeFilter && shopTypeFilter.value) {
        filteredData = filteredData.filter(
          (x) => x.inductionMatchingStatus == shopTypeFilter.value
        )
        if (filteredData) setDistShopsList(filteredData)
        else setDistShopsList([])
      } else setDistShopsList(filteredData)
    } else setDistShopsList([])
  }
  const validateDataSheetFile = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return
    }
    if (!image.name.match(/\.(xlsx|xls)$/)) {
      toast.error('You can only upload file of type (xlsx, xls)')
      event.target.value = null
      return
    } else {
      setDataSheetSelectedFile(event.target.files[0])
    }
  }
  //// Code to manage Principals
  const loadVizShopsByDistShopId = (shopId) => {
    props.searchVizShops(shopId, vizShopId)
  }

  const submitUpdateDataSheet = () => {
    if (dataSheetSelectedFile != null) {
      props.updateShopsRsoDetail({
        DistCenterId: props.jobDetails.distCenterId,
        DataFile: dataSheetSelectedFile,
        JobId: props.jobDetails.id,
      })
      setUpdateShopsModalShow(false)
    } else {
      toast.error('Please select data sheet file for updating shops')
    }
  }
  const openModalForUpdateShopDetails = () => {
    setDataSheetSelectedFile(null)
    setUpdateShopsModalShow(true)
  }
  const openModalForShopDetails = (vizShop) => {
    setVizShopDetails(vizShop)
    setShopDetailsModalShow(true)
  }
  return (
    <div>
      <VzPageContainer title='Job Details'>
        <Row>
          <Col md={{ span: 6 }} style={{ float: 'left' }}>
            <h4>
              <b>Job Details</b>
            </h4>
          </Col>
          <Col
            md={{ span: 3 }}
            style={{ float: 'right', padding: 0, margin: 0 }}
          >
            <div className='form-group'>
              <ReactSelect
                id='types'
                name='types'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Shop Type'
                isMulti={false}
                value={shopType}
                isClearable={true}
                isSearchable={true}
                onChange={(option) => setShopType(option != null ? option : '')}
                options={shopTypesOptions}
              ></ReactSelect>
            </div>
          </Col>
          <Col
            md={{ span: 3 }}
            style={{
              float: 'right',
              padding: 0,
              margin: 0,
              paddingLeft: '20px',
            }}
          >
            <div className='form-group'>
              <ReactSelect
                id='paymentMode'
                name='paymentMode'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Payment Mode'
                isMulti={false}
                value={paymentMode}
                isClearable={true}
                isSearchable={true}
                onChange={(option) =>
                  setPaymentMode(option != null ? option : '')
                }
                options={paymentModeOptions}
              ></ReactSelect>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <Col
                xs={12}
                md={12}
                style={{ textAlign: 'left' }}
                className='text-nowrap'
              >
                <label>
                  <b>Distributor: </b>
                  {props.jobDetails?.distributorName}
                </label>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            md={2}
            style={{ textAlign: 'left' }}
            className='text-nowrap'
          >
            <label>
              <b>Job No. </b>
              {props.jobDetails?.jobNo}
            </label>
          </Col>
          <Col
            xs={12}
            md={3}
            style={{ textAlign: 'left' }}
            className='text-nowrap'
          >
            <label>
              <b>Distribution Center:</b> {props.jobDetails?.distCenterName}
            </label>
          </Col>

          <Col
            xs={12}
            md={2}
            style={{ textAlign: 'right' }}
            className='text-nowrap'
          >
            <label>
              <b>Principal: </b>
              {props.jobDetails?.principalName}
            </label>
          </Col>
          <Col
            xs={12}
            md={2}
            style={{ textAlign: 'right' }}
            className='text-nowrap'
          >
            <label>
              <b>RSO: </b>
              {distShopsList[0]?.rsoName}
            </label>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ float: 'right' }}>
            <Row></Row>
            {/* <CustomDataTable
              data={distShopsList ? distShopsList : []}
              columns={columns}
              getTrProps={(event, data) => null}
            /> */}
            <ServerSideDataTable
              data={distShopsList ? distShopsList : []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </VzPageContainer>
      <Modal
        show={linkShopModal}
        onHide={() => setLinkShopModal(false)}
        backdrop='static'
        keyboard={false}
        size='xl'
      >
        <Formik
          enableReinitialize
          validationSchema={validationPrincipalSchema}
          onSubmit={() => null}
          initialValues={{ vizShopId: '' }}
        >
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Link Shops
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12} md={3}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='vizShopId'
                          label='Vizlink Shop Id'
                          name='vizShopId'
                          value={vizShopId}
                          handleOnChange={(value) => setVizShopId(value)}
                          onKeyDown={(e) =>
                            e.key === 'Enter' &&
                            loadVizShopsByDistShopId(selectedDistributorShop.id)
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={4} md={3}>
                      <div>
                        <Button
                          style={{ marginTop: '30px' }}
                          onClick={() =>
                            loadVizShopsByDistShopId(selectedDistributorShop.id)
                          }
                          value='Search'
                          variant='outline-primary'
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      {vizShopsData && vizShopsData.length > 0 ? (
                        <CustomDataTable
                          // data={props.jobDetails.vizlinkShops}
                          data={vizShopsData}
                          columns={vizShopsListColumns}
                          getTrProps={(event, data) => null}
                          IsPagingEnable={false}
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className='btn btn-default'
                  onClick={() => setLinkShopModal(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* //// Upload Job Data Sheet */}
      <Modal
        show={updateShopsModalShow}
        onHide={() => setUpdateShopsModalShow(false)}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Update Shops Data of {props.jobDetails?.distributorName} -{' '}
            {props.jobDetails?.distCenterName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={{ span: 5 }} style={{ float: 'right' }}>
                <strong>Distributor: </strong>
                {props.jobDetails?.distributorName}
              </Col>
              <Col md={{ span: 7 }} style={{ float: 'right' }}>
                <strong>Distribution Center: </strong>
                {props.jobDetails?.distributorName}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={{ span: 5 }}>
                <label style={{ width: '100%' }}>
                  <b>Principal:</b>
                  {props.jobDetails?.principalName}
                </label>
              </Col>
              <Col xs={12} md={{ span: 7, offset: 0 }}>
                <div className='form-group'>
                  <div>
                    {dataSheetSelectedFile && (
                      <div>
                        <img
                          style={{ border: '1px solid #ccc' }}
                          alt='not fount'
                          width={'100px'}
                          height={'100px'}
                          src={ExcelIcon}
                        />
                        <br />
                        <button
                          className='btn btn-info'
                          style={{
                            marginLeft: '10px',
                            marginTop: '10px',
                            marginBottom: '20px',
                          }}
                          onClick={() => {
                            setDataSheetSelectedFile(null)
                          }}
                        >
                          <i className='fa fa-close' />
                          Remove
                        </button>
                      </div>
                    )}
                    {!dataSheetSelectedFile && (
                      <div>
                        <br />
                        <input
                          type='file'
                          name='dataSheetSelectedFile'
                          onChange={(event) => validateDataSheetFile(event)}
                        />
                      </div>
                    )}
                    <div>Select Job Data Sheet File (Only Excel file)</div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={{ span: 5 }}></Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Button
                  className='btn btn-default'
                  style={{ float: 'right', marginTop: '10px' }}
                  onClick={() => setUpdateShopsModalShow(false)}
                >
                  Cancel
                </Button>
                <input
                  type='submit'
                  className='btn btn-primary'
                  onClick={() => submitUpdateDataSheet()}
                  style={{
                    marginRight: '10px',
                    marginTop: '10px',
                    float: 'right',
                  }}
                  value='Update Data'
                />

                <a
                  href={`${UpdateShopsDataFileTemplate}`}
                  download='UpdateShopsTemplateFile'
                  className='active'
                  style={{
                    padding: '0px',
                    marginTop: '20px',
                    float: 'left',
                  }}
                >
                  Download Template
                </a>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      {/* Main Informations Details Modal */}
      <Modal
        show={shopDetailsModalShow}
        onHide={() => setShopDetailsModalShow(false)}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Shop Details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={7}>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Shop Id:</strong>
                    </label>
                  </Col>
                  <Col
                    xs={12}
                    md={7}
                    style={{ textAlign: 'left' }}
                    className='text-nowrap'
                  >
                    <label>{vizShopDetails?.vizShopCode}</label>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={5}>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Shop Name:</strong>
                    </label>
                  </Col>
                  <Col
                    xs={12}
                    md={7}
                    style={{ textAlign: 'left' }}
                    className='text-nowrap'
                  >
                    <label>{vizShopDetails?.vizShopName}</label>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={7}>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Shop Address:</strong>
                    </label>
                  </Col>
                  <Col
                    xs={12}
                    md={7}
                    style={{ textAlign: 'left' }}
                    className='text-nowrap'
                  >
                    <p>{vizShopDetails?.address}</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={5}>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Agent:</strong>
                    </label>
                  </Col>
                  <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                    <p>
                      {vizShopDetails?.agentName
                        ? vizShopDetails?.agentName
                        : '--'}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={7}>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Wallet Status:</strong>
                    </label>
                  </Col>
                  <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                    <p>
                      {vizShopDetails?.status ? vizShopDetails?.status : '--'}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={5}>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Wallet Number:</strong>
                    </label>
                  </Col>
                  <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                    <p>
                      {vizShopDetails?.WalletStatus
                        ? vizShopDetails?.WalletStatus
                        : '--'}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={7} fluid>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Geo Tag Status</strong>
                    </label>
                  </Col>
                  <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                    <p>
                      {vizShopDetails?.geoTagStatus
                        ? vizShopDetails?.geoTagStatus
                        : '--'}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={5}></Col>

              <Col xs={12} md={12}>
                <Row>
                  <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                    <label>
                      <strong>Distributors:</strong>
                    </label>
                  </Col>
                  <Col
                    xs={12}
                    md={7}
                    style={{ textAlign: 'left' }}
                    className='text-nowrap'
                  >
                    <p>
                      {vizShopDetails?.distributorNames
                        ? vizShopDetails?.distributorNames
                        : '--'}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    distributorsddlList: state.ddlList.distributorList
      ? state.ddlList.distributorList
      : [],
    distCentersddlList: state.ddlList.selectedDistCentersList
      ? state.ddlList.selectedDistCentersList
      : [],
    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    jobDetails: state.jobDetails ? state.jobDetails : [],
  }
}
export default connect(mapStateToProps, {
  getRsoJobDetails,
  updateRsoShopStatus,
  searchVizShops,
  updateShopsRsoDetail,
  linkDistributorShopToVizLinkShop,
  unLinkDistributorShopToVizLinkShop,
})(RsoJobDetails)
