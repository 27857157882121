import axios from '../../apis/Api'
import history, { logout } from '../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { GET_ADVERTISMENTS, RESET_ADVERTISMENTS } from '../../Types'

export const getAdvertisements = (postedData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .post(`api/Advertisement/GetAdvertisements`, postedData)
        .then(({ data }) => {
            dispatch({
                type: GET_ADVERTISMENTS,
                payload: data.response,
            })
            dispatch(hideLoading())
        })
        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (
                    error.response.status === 400 &&
                    error.response.data.errors
                ) {
                    let errorMessage = ''
                    var errors = error.response.data.errors
                    var keys = Object.keys(errors)
                    for (let j = 0; j < keys.length; j++) {
                        errorMessage += errors[keys[j]] + ' , \n'
                    }
                    toast.error(errorMessage)
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            } else toast.error('Error while processing the request')
        })
}

export const addAdvertisement = (postedData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .post(`api/Advertisement`, postedData)
        .then(({ data }) => {
            toast.success("Posted successfully");
            dispatch(hideLoading())
        })
        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (
                    error.response.status === 400 &&
                    error.response.data.errors
                ) {
                    let errorMessage = ''
                    var errors = error.response.data.errors
                    var keys = Object.keys(errors)
                    for (let j = 0; j < keys.length; j++) {
                        errorMessage += errors[keys[j]] + ' , \n'
                    }
                    toast.error(errorMessage)
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            } else toast.error('Error while processing the request')
        })
}

export const updateAdvertisement = (id, postedData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .put(`api/Advertisement/${id}`, postedData)
        .then(({ data }) => {
            toast.success("updated successfully");
            dispatch(hideLoading())
        })
        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (
                    error.response.status === 400 &&
                    error.response.data.errors
                ) {
                    let errorMessage = ''
                    var errors = error.response.data.errors
                    var keys = Object.keys(errors)
                    for (let j = 0; j < keys.length; j++) {
                        errorMessage += errors[keys[j]] + ' , \n'
                    }
                    toast.error(errorMessage)
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            } else toast.error('Error while processing the request')
        })
}

export const deleteAdvertisement = (id) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .delete(`api/Advertisement/SoftDelete/${id}`)
        .then(({ data }) => {
            dispatch(hideLoading())
            if (data.isSuccess) {
                toast.success(data.message)
            }
            else {
                toast.error(data.message)
            }
        })
        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (
                    error.response.status === 400 &&
                    error.response.data.errors
                ) {
                    let errorMessage = ''
                    var errors = error.response.data.errors
                    var keys = Object.keys(errors)
                    for (let j = 0; j < keys.length; j++) {
                        errorMessage += errors[keys[j]] + ' , \n'
                    }
                    toast.error(errorMessage)
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            } else toast.error('Error while processing the request')
        })
}

export const resetAdvertisements = () => async (dispatch) => {
    dispatch({
        type: RESET_ADVERTISMENTS,
        payload: [],
    })
}
