import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'

import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../../layouts/DataTables/CustomDataTable'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Moment from 'react-moment'
import FormikControl from '../../../../layouts/Input/FormikControl'
import { VzPageContainer } from '../../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { getDateWiseShopsSummaryReport, downloadJob } from './DateWiseShopsSummaryReportAction'
import { getDropdownList } from '../../../Shared/dropdownListActions'
import { GET_DATEWISE_SHOPS_SUMMARY_REPORT } from '../../../../Types'
import { isPermittedPage } from '../../../../apis/Utility'

const ShopWisePulseReport = (props) => {
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [filterFromDate, setFilterFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 29)));
  const [filterToDate, setFilterToDate] = useState(new Date())
  const [filterPulseStatus, setFilterPulseStatus] = useState(null)
  const [maxToDate, setMaxToDate] = useState(new Date())
  const [pageCount, setPageCount] = useState(0);
  const [filterValuesModal, setFilterValuesModal] = useState({
    fromDate: new Date(new Date().setDate(new Date().getDate() - 29)),
    toDate: new Date(),
    pulseStatus: '',
  })
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const Columns = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        row.value && <Moment format='DD/MM/YYYY'>{row.value}</Moment>
      )
    },
    {
      Header: 'Total Shops',
      accessor: 'totalShops',
    },
    {
      Header: 'Pulse Status',
      accessor: 'status',
    }
    // {
    //   Header: 'Last Active Date & Time ',
    //   accessor: 'lastActiveDateAndTime',
    //   Cell: (row) => (
    //     <div style={{ width: '75px' }}>
    //       <Moment format='DD/MM/YYYY'>{row.value}</Moment>
    //     </div>
    //   ),
    // },
  ]

  const LatestPulseStatus = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'In-Active',
      value: 'inActive',
    },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterPulseStatus(null)
    setFilterValuesModal({
      fromDate: '',
      toDate: '',
      pulseStatus: '',
    })
  }

  const getData = (page) => {
    if (page < pageCount) {
      setCurrentPageNo(page)
      var request = { ...formRequestObject(), pageNo: page }
      fetchReportData(request);
    }
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize);
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize }
    fetchReportData(request);
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0);
    var request = { ...formRequestObject(), pageNo: 0 };
    fetchReportData(request);
    setShow(false);
  }

  const Download = () => {
    let request = formRequestObject(true);
    props.downloadJob(request)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData();
  }, [searchText])

  useEffect(() => {
    if (props.DateWiseShopsSummaryReportData) {
      setTotalRecords(props.DateWiseShopsSummaryReportData.totalRecords)
      getPageCount(props.DateWiseShopsSummaryReportData.totalRecords, currentPageSize);
    } else {
      setTotalRecords(0)
    }
  }, [props.DateWiseShopsSummaryReportData])

  function formRequestObject(isExport = false) {
    let requestData = {
      status: filterPulseStatus == '' ? '1' : filterPulseStatus === "inActive" ? 2 : filterPulseStatus === "active" ? 1 : 1,
      fromDate: filterFromDate && formatDate(filterFromDate),
      toDate: filterToDate && formatDate(filterToDate),
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport
    }
    return requestData;
  }



  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    props.getDateWiseShopsSummaryReport(finalRequest)
  }

  function formatDate(dateInput) {
    var res = new Date(
      Date.parse(
        new Intl.DateTimeFormat('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(dateInput))
      )
    )
      .toISOString()
      .split('T')[0]
    return res
  }

  function getPageCount(totalRecords, pageSize) {
    setPageCount(Math.ceil(totalRecords / pageSize));
  }


  return (
    <VzPageContainer title='Active Shops Summary'>
      <div>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Active Shops Summary Report</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  maxDate={maxToDate}
                                  minDate={filterFromDate}
                                />
                              </Col>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Latest Pulse Status'
                                  name='status'
                                  options={LatestPulseStatus}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(option) => {
                                    filterValuesModal.pulseStatus = option
                                    setFilterPulseStatus(option && option.value)
                                  }}
                                  value={filterValuesModal.pulseStatus}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          {isPermittedPage('Export', props.actions, props.userRole) &&
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={Download}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-download me-1' />
                Export
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col xs={12}>
            <ServerSideDataTable
              data={props.DateWiseShopsSummaryReportData.data ?? []}
              columns={Columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </div>
    </VzPageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    DateWiseShopsSummaryReportData: state.DateWiseShopSummaryReport,
  }
}

export default connect(mapStateToProps, {
  getDateWiseShopsSummaryReport,
  getDropdownList,
  downloadJob,
})(ShopWisePulseReport)
