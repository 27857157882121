import { useEffect, useState, useRef } from 'react'
import { Col, Row, Overlay, Button, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import FormikControl from '../../../layouts/Input/FormikControl'
import storage from '../../../apis/Storage'
import Moment from 'react-moment'
import { GET_DISTRIBUTOR_DDL, GET_ADDJOB_DISTRIBUTIONCENTER_DDL } from '../../../Types'
import { formatDate } from '../../../utilities/FUNCTIONS'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { Link } from 'react-router-dom'
import { getSCRDetailReportReport } from './SCRDetailReportActions'
import { getDropdownList } from '../../Shared/dropdownListActions'
import axios from '../../../apis/Api'

const SCRDetailReport = (props) => {
    const userRights = JSON.parse(storage.get('_userRights'));
    const [target, setTarget] = useState(null);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    // pagination states
    const [currentIndex, setCurrentIndex] = useState();
    const [currentPageNo, setCurrentPageNo] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [distributorList, setDistributorList] = useState([]);
    const [distCenterList, setdistCenterList] = useState([]);
    const [deliveryManNameList, setDeliveryManNameList] = useState([]);
    const [deliveryManTillNoList, setDeliveryManTillNoList] = useState([]);
    const [isApplyFilter, setIsApplyFilter] = useState(false);

    const [filter, setfilter] = useState({
        distributor: null,
        distCenter: null,
        deliveryManName: null,
        deliveryManTillNo: null,
        fromDate: new Date(),
        toDate: new Date(),
        pageNo: 0,
        searchText: null,
    })

    const openFilterModal = (event) => {
        setShow(!show)
        setTarget(event.target)
    }

    function commonReset(reset = true) {
        if (reset) {
            setfilter({
                distributor:
                    userRights != null && userRights?.userType == 'DistributorUser'
                        ? filter.distributor
                        : null,
                distCenter: null,
                deliveryManName: null,
                deliveryManTillNo: null,
                fromDate: new Date(),
                toDate: new Date(),
                pageNo: 0,
                searchText: null,
            })
        }
    }

    function formRequestObject(isExport = false) {
        let requestData = {
            distributor: filter.distributor?.value,
            distCenter: filter.distCenter?.value,
            deliveryManTillNo: filter.deliveryManTillNo?.value,
            deliveryManName: filter.deliveryManName?.value,
            deliveryManTillNo: filter.deliveryManTillNo?.value,
            fromDate: filter.fromDate && formatDate(filter.fromDate),
            toDate: filter.fromDate && formatDate(filter.toDate),
            pageSize: currentPageSize,
            pageNo: currentPageNo,
            searchText: searchText,
        }
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            requestData = {
                ...requestData,
                distributorId: userRights?.distributorId,
            }
        }
        return requestData
    }

    function fetchReportData(request) {
        let finalRequest = request == null ? formRequestObject() : request
        props.getSCRDetailReportReport(finalRequest)
    }

    const handleResetFilters = (resetForm) => {
        resetForm()
        commonReset()
    }

    const getData = (page) => {
        setCurrentPageNo(page)
        var request = { ...formRequestObject(), pageNo: page }
        fetchReportData(request)
    }

    const pageSizeChanged = (pageSize) => {
        setCurrentPageNo(0)
        setCurrentPageSize(pageSize)
        var request = { ...formRequestObject(), pageSize: pageSize }
        fetchReportData(request)
    }

    const searchChanged = (text) => {
        setSearchText(text)
    }

    const LoadDataByfilter = (event) => {
        setCurrentPageNo(0)
        var request = { ...formRequestObject(), pageNo: 0 }
        fetchReportData(request)
        setShow(false)
        setIsApplyFilter(true)
    }

    useEffect(() => {
        setCurrentPageNo(0)
        fetchReportData()
    }, [searchText])

    useEffect(() => {
        if (props.report && props.report.totalRecords > 0) {
            setTotalRecords(props.report.totalRecords)
        } else {
            setTotalRecords(0)
        }
    }, [props.report])

    useEffect(() => {
        props.getDropdownList(
            `api/Distributor/GetDistributorsDDL`,
            GET_DISTRIBUTOR_DDL
        )
    }, [])

    useEffect(() => {
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            let distributorList = props.allDistributorList.filter(
                (x) => x.value == userRights?.distributorId
            )
            if (distributorList?.length > 0) {
                setfilter({
                    ...filter,
                    distributor: {
                        label: distributorList[0]?.label,
                        value: distributorList[0]?.value,
                    },
                })
            }
            setDistributorList(
                distributorList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        } else {
            setDistributorList(
                props.allDistributorList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        }
    }, [props.allDistributorList])

    useEffect(() => {
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            let distCenterList = props.selectedAddJobDistCentersList.filter((x) =>
                userRights?.distCenterIds.includes(x.value)
            )
            setdistCenterList(
                distCenterList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        } else {
            setdistCenterList(
                props.selectedAddJobDistCentersList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        }
    }, [props.selectedAddJobDistCentersList])

    useEffect(() => {
        if (filter.distributor) {
            props.getDropdownList(
                `api/DistCenter/GetDistCentersDDL/${filter.distributor.value}`,
                GET_ADDJOB_DISTRIBUTIONCENTER_DDL
            )
        }
        else {
            filter.distCenter = null;
            filter.deliveryManName = null;
            filter.deliveryManTillNo = null;
        }
    }, [filter.distributor])

    useEffect(() => {
        filter.deliveryManName = null;
        filter.deliveryManTillNo = null;
    }, [filter.distCenter])

    const getCenterDeliveryUsers = (id) => {
        axios
            .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
            .then(({ data }) => {
                if (data?.response) {
                    setDeliveryManNameList(
                        data.response.map(function (item) {
                            return {
                                value: item.id,
                                label: item.fullname,
                            }
                        })
                    )
                }

                setDeliveryManTillNoList(
                    data.response.map(function (item) {
                        return {
                            value: item.qrCode,
                            label: item.qrCode,
                        }
                    })
                )
            })
            .catch(function (error) { })
    }

    useEffect(() => {
        if (filter.distCenter) {
            getCenterDeliveryUsers(filter.distCenter.value)
        }
    }, [filter.distCenter])

    const columns = [
        {
            Header: 'Distribution Center',
            accessor: 'distCenter',
        },
        {
            Header: 'Deliveryman Name',
            accessor: 'deliverymanName',
        },
        {
            Header: 'Deliveryman Till#',
            accessor: 'deliverymanTillNo',
        },
        {
            Header: 'Total Invoice Amount',
            accessor: 'invoiceAmount',
            Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
        },
        {
            Header: 'Total Amount Collected',
            accessor: 'collectedAmount',
            Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
        },
        {
            Header: 'Date',
            accessor: 'date',
            Cell: (row) => (
                <div style={{ width: '75px' }}>
                    <Moment format='DD/MM/YYYY'>{row.value}</Moment>
                </div>
            ),
        },
        {
            Header: 'Progress',
            id: 'Progress',
            Cell: (props) => (
                <div style={{ width: '106px' }}>
                    <div className="progress mt-2" style={{ height: '12px' }}>
                        <div className="progress-bar bg-success progress-bar-striped rounded" role="progressbar" style={{ width: (props.row.original.progressPaidInvoices / props.row.original.progressTotalInvoices * 100) + '%' }}>
                            {props.row.original.progressPaidInvoices}
                        </div>
                        <div className="progress-bar bg-light progress-bar-striped rounded" role="progressbar" style={{ width: (props.row.original.progressPaidInvoices / props.row.original.progressTotalInvoices * 100) - 100 + '%' }}>
                            {props.row.original.progressTotalInvoices}
                        </div>
                    </div>
                </div>
            ),
        },
        {
            Header: 'Action',
            id: 'viewdetail',
            accessor: 'id',
            Cell: (row) => (
                <div div style={{ width: '106px' }}>
                    <Link
                        className=''
                        to={`/EditDistributorInductionForm/${row.value}`}
                    >
                        <u>View Details</u>
                    </Link>
                </div >
            ),
        },
    ]

    return (
        <div>
            <VzPageContainer title='SCR Detail Report'>
                <Row>
                    <Col style={{ float: 'left' }}>
                        <h4>
                            <b>SCR Detail Report</b>
                        </h4>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col md={10} style={{ float: 'right' }}>
                        <div ref={ref}>
                            <Button
                                onClick={openFilterModal}
                                className='m-3'
                                variant='outline-primary'
                            >
                                <i className='fa fa-filter' />
                                Filters
                            </Button>

                            <Overlay
                                show={show}
                                target={target}
                                placement='bottom'
                                container={ref}
                                containerPadding={20}
                                width='430px'
                                style={{ width: '430px' }}
                            >
                                <Popover
                                    id='popover-contained'
                                    style={{ maxWidth: '360px', width: '360px' }}
                                >
                                    <Popover.Header as='h3'>Filters</Popover.Header>
                                    <Popover.Body>
                                        <Formik
                                            enableReinitialize
                                            initialValues={filter}
                                            validator={() => ({})}
                                            onSubmit={LoadDataByfilter}
                                        >
                                            {(formik) => (
                                                <Form autoComplete='off'>
                                                    <Row>
                                                        {userRights == null && (
                                                            <Col xs={12} md={12}>
                                                                <FormikControl
                                                                    formik={formik}
                                                                    control='react-select'
                                                                    label='Distributor'
                                                                    name='distributor'
                                                                    options={distributorList}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    handleOnChange={(e) => {
                                                                        if (!e || !e.value) {
                                                                            setfilter({
                                                                                ...filter,
                                                                                distributor: null,
                                                                            })
                                                                            return
                                                                        }
                                                                        setfilter({
                                                                            ...filter,
                                                                            distributor: {
                                                                                label: e.label,
                                                                                value: e.value,
                                                                            },
                                                                        })
                                                                    }}
                                                                    value={filter.distributor}
                                                                />
                                                            </Col>
                                                        )}

                                                        <Col xs={12} md={12}>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='react-select'
                                                                label='Distribution Center'
                                                                name='distCenter'
                                                                options={filter.distributor != null ? distCenterList : []}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                handleOnChange={(e) => {
                                                                    if (!e || !e.value) {
                                                                        setfilter({
                                                                            ...filter,
                                                                            distCenter: null,
                                                                        })
                                                                        return
                                                                    }
                                                                    setfilter({
                                                                        ...filter,
                                                                        distCenter: {
                                                                            label: e.label,
                                                                            value: e.value,
                                                                        },
                                                                    })
                                                                }}
                                                                value={filter.distCenter}
                                                            />
                                                            <Col xs={12} md={12}>
                                                                <FormikControl
                                                                    formik={formik}
                                                                    control='react-select'
                                                                    label='DLM Name'
                                                                    name='deliveryUser'
                                                                    options={filter.distCenter != null ? deliveryManNameList : []}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    handleOnChange={(e) => {
                                                                        if (!e || !e.value) {
                                                                            setfilter({
                                                                                ...filter,
                                                                                deliveryManName: null,
                                                                            })
                                                                            return
                                                                        }
                                                                        setfilter({
                                                                            ...filter,
                                                                            deliveryManName: {
                                                                                label: e.label,
                                                                                value: e.value,
                                                                            },
                                                                        })
                                                                    }}
                                                                    value={filter.deliveryManName}
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={12}>
                                                                <FormikControl
                                                                    formik={formik}
                                                                    control='react-select'
                                                                    label='DLM Till No'
                                                                    name='tillNo'
                                                                    options={filter.distCenter != null ? deliveryManTillNoList : []}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    handleOnChange={(e) => {
                                                                        if (!e || !e.value) {
                                                                            setfilter({
                                                                                ...filter,
                                                                                deliveryManTillNo: null,
                                                                            })
                                                                            return
                                                                        }
                                                                        setfilter({
                                                                            ...filter,
                                                                            deliveryManTillNo: {
                                                                                label: e.label,
                                                                                value: e.value,
                                                                            },
                                                                        })
                                                                    }}
                                                                    value={filter.deliveryManTillNo}
                                                                />
                                                            </Col>
                                                        </Col>
                                                        <Row style={{ paddingRight: 0 }}>
                                                            <Col xs={12} md={6}>
                                                                <FormikControl
                                                                    formik={formik}
                                                                    type='fromDate'
                                                                    control='date'
                                                                    label='From Date'
                                                                    name='fromDate'
                                                                    handleOnChange={(value) => {
                                                                        setfilter({ ...filter, fromDate: value })
                                                                    }}
                                                                    value={filter.fromDate}
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={6}>
                                                                <FormikControl
                                                                    formik={formik}
                                                                    control='date'
                                                                    type='toDate'
                                                                    label='To Date'
                                                                    name='toDate'
                                                                    handleOnChange={(value) => {
                                                                        setfilter({ ...filter, toDate: value })
                                                                    }}
                                                                    value={filter.toDate}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                    <div className='row' style={{ marginTop: 20 }}>
                                                        <div className='form-group'>
                                                            <Link
                                                                onClick={handleResetFilters.bind(
                                                                    null,
                                                                    formik.resetForm,
                                                                    setIsApplyFilter(false)
                                                                )}
                                                                to='#'
                                                                className='active'
                                                                style={{
                                                                    padding: '0px',
                                                                    marginTop: '10px',
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                Reset
                                                            </Link>
                                                            <Link
                                                                onClick={() => {
                                                                    setShow(false)
                                                                    setTarget(null)
                                                                    commonReset(isApplyFilter)
                                                                }}
                                                                to='#'
                                                                className='active'
                                                                style={{
                                                                    padding: '0px',
                                                                    marginTop: '10px',
                                                                    float: 'right',
                                                                }}
                                                            >
                                                                Cancel
                                                            </Link>
                                                            <div className='float-right'>
                                                                <Button
                                                                    type='submit'
                                                                    className='btn btn-primary'
                                                                    style={{
                                                                        marginRight: '10px',
                                                                        float: 'right',
                                                                    }}
                                                                >
                                                                    Apply
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Popover.Body>
                                </Popover>
                            </Overlay>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ServerSideDataTable
                            data={props.report?.data ?? []}
                            columns={columns}
                            getTrProps={(event, data) => null}
                            goToPreviousPage={(val) => getData(val)}
                            goToNextPage={(val) => getData(val)}
                            totalRecords={totalRecords}
                            pageSizeChange={(size) => pageSizeChanged(size)}
                            myPageSize={currentPageSize}
                            myIndex={currentIndex}
                            pageNo={currentPageNo}
                            searchChanged={searchChanged}
                        />
                    </Col>
                </Row>
            </VzPageContainer>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        report: state.SCRDetailReport,
        allDistributorList: state.ddlList.allDistributorList
            ? state.ddlList.allDistributorList
            : [],
        selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
            ? state.ddlList.selectedAddJobDistCentersList
            : [],
    }
}
export default connect(mapStateToProps, {
    getSCRDetailReportReport,
    getDropdownList
})(SCRDetailReport)
