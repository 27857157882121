import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { VzPageContainer } from '../../layouts/VzPageContainer'
import PaymentUpdateImage from '../../assets/Images/Dashboard/Task.png'
import StoreImage from '../../assets/Images/Dashboard/Store.png'
import userAccountsImage from '../../assets/Images/Dashboard/Task.png'
import { Accordion } from 'react-bootstrap'
import { getDropdownList } from '../Shared/dropdownListActions'
import { isPermittedPage } from '../../apis/Utility'
import { GET_BANKS, GET_CITIES, GET_BUSINESS_ENTITIES } from '../../Types'
import storage from '../../apis/Storage'

const Dashboard = (props) => {
  // const [activityType, setActivityType] = useState('Recents')
  useEffect(() => {
    // const isLogin = storage.get('_appLogged') === 'true' ? true : false
    // if (isLogin) {
    //   props.getDropdownList(`api/Bank`, GET_BANKS)
    //   props.getDropdownList(`api/City`, GET_CITIES)
    //   props.getDropdownList(`api/BusinessEntityType`, GET_BUSINESS_ENTITIES)
    // }
    // setShow(true)
  }, [])
  return (
    <>
      {isPermittedPage('View', props.actions, props.userRole) && (
        <VzPageContainer>
          <Accordion
            defaultActiveKey='0'
            style={{ maxWidth: '700px', margin: 'auto' }}
          >
            <Accordion.Item eventKey='0'>
              <Accordion.Header>To Do List</Accordion.Header>
              <Accordion.Body style={{ margin: '0px' }}>
                <div className='row' style={{ margin: '0px' }}>
                  <div className='col-sm-4'>
                    <div className='card' style={{ minHeight: 'auto' }}>
                      <img
                        style={{
                          width: '80px',
                          height: '80px',
                          margin: '25px auto 10px auto',
                        }}
                        src={PaymentUpdateImage}
                        className='card-img-top'
                        alt='Payment Update'
                      />
                      <div
                        className='card-body'
                        style={{ textAlign: 'center' }}
                      >
                        <p>Payment Update</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='card' style={{ minHeight: 'auto' }}>
                      <img
                        src={StoreImage}
                        style={{
                          width: '80px',
                          height: '80px',
                          margin: '25px auto 10px auto',
                        }}
                        className='card-img-top'
                        alt='Payment Update'
                      />
                      <div
                        className='card-body'
                        style={{ textAlign: 'center' }}
                      >
                        <p>Shops Data Update</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='card' style={{ minHeight: 'auto' }}>
                      <img
                        style={{
                          width: '80px',
                          height: '80px',
                          margin: '25px auto 10px auto',
                        }}
                        src={userAccountsImage}
                        className='card-img-top'
                        alt='Payment Update'
                      />
                      <div
                        className='card-body'
                        style={{ textAlign: 'center' }}
                      >
                        <p>User Addition</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>Recents</Accordion.Header>
              <Accordion.Body> &nbsp; No Activity Found.</Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-9">
            <div className="form-group">
              <select
                className="form-control select2"
                style={{ width: "100%" }}
                value={activityType}
                onChange={(event) => setActivityType(event.target.value)}
                placeholder="Select Type"
              >
                <option value="Recents">Recents</option>
                <option value="Old">Old</option>
              </select>
            </div>
          </div>
          <div className="col-sm-2"></div>
        </div> */}
        </VzPageContainer>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.auth.isSignedIn,
    userId: state.auth.userId,
    userRole: storage.get('_appUserRole'),
  }
}

export default connect(mapStateToProps, { getDropdownList })(Dashboard)
