import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'

import { Tabs, Tab } from 'react-bootstrap'
import MonthlyDigitalCollectionsReport from './MonthlyDigitalCollectionsReport/MonthlyDigitalCollectionsReport'
import CurrentDigitalCollectionsReport from './CurrentDigitalCollectionsReport/CurrentDigitalCollectionsReport'
import { isPermittedPage } from '../../../apis/Utility'

const DigitalCollectionsReport = (props) => {
  return (
    <div>
      <VzPageContainer title='Digital Collections Report'>
        <Tabs
          defaultActiveKey=
          {isPermittedPage('Current', props.actions, props.userRole) ?
            'CurrentDigitalCollectionsReport' :
            "MonthlyDigitalCollectionsReport"}
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          {isPermittedPage('Current', props.actions, props.userRole) &&
            <Tab eventKey='CurrentDigitalCollectionsReport' title='Current'>
              <CurrentDigitalCollectionsReport actions={props.actions}></CurrentDigitalCollectionsReport>
            </Tab>
          }
          {isPermittedPage('Monthly', props.actions, props.userRole) &&
            <Tab eventKey='MonthlyDigitalCollectionsReport' title='Monthly'>
              <MonthlyDigitalCollectionsReport actions={props.actions}></MonthlyDigitalCollectionsReport>
            </Tab>
          }
        </Tabs>
      </VzPageContainer>
    </div >
  )
}

export default connect()(DigitalCollectionsReport)
