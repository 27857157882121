import {
  GET_DI_USERS,
  ADD_DI_USERS,
  UPDATE_DI_USERS,
  DELETE_DI_USERS,
} from "../../../Types";
import _ from "lodash";

const diUsersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DI_USERS:
      return [...state, action.payload];
    case ADD_DI_USERS:
      return [...state, action.payload];
    case UPDATE_DI_USERS:
      return state.splice(_.findIndex(state,{id: action.payload.id}), 1, ...action.payload);
    case DELETE_DI_USERS:
      return state.filter((e) => e.id !== action.payload);
    default:
      return state;
  }
};
export default diUsersReducer;
