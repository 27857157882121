import { GET_SECTORS_BASED_MAP } from '../../../Types'
// import _ from 'lodash'
const SectorsBasedMapReducer = (state = [], action) => {
    switch (action.type) {
        case GET_SECTORS_BASED_MAP:
            return action.payload
        default:
            return state
    }
}
export default SectorsBasedMapReducer
