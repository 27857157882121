import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'

import { Tabs, Tab } from 'react-bootstrap'
import DayWiseDailyTransactionsSummary from './DayWiseDailyTransactionsSummary/DayWiseDailyTransactionsSummary'
import MonthlyDailyTransactionsSummary from './MonthlyDailyTransactionsSummary/MonthlyDailyTransactionsSummary'
import { isPermittedPage } from '../../../apis/Utility'
const DailyTransactionsSummary = (props) => {
  return (
    <div>
      <VzPageContainer title='Daily Transactions Summary'>
        <Tabs
          defaultActiveKey=
          {
            isPermittedPage('Day Wise', props.actions, props.userRole) ? 'DayWise' : 'Monthly'
          }
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          {isPermittedPage('Day Wise', props.actions, props.userRole) &&
            <Tab eventKey='DayWise' title='Day Wise'>
              <DayWiseDailyTransactionsSummary actions={props.actions}></DayWiseDailyTransactionsSummary>
            </Tab>
          }
          {isPermittedPage('Monthly', props.actions, props.userRole) &&
            <Tab eventKey='Monthly' title='Monthly'>
              <MonthlyDailyTransactionsSummary actions={props.actions}></MonthlyDailyTransactionsSummary>
            </Tab>
          }
        </Tabs>
      </VzPageContainer>
    </div>
  )
}

export default connect()(DailyTransactionsSummary)
