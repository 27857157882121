import { GET_USER_ACTIVITY } from '../../../Types'

const UserActivityReducer = (state = [], action) => {
  switch (action.type) {
    case GET_USER_ACTIVITY:
      return action.payload
    default:
      return state
  }
}
export default UserActivityReducer
