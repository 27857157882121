import {
  GET_DAY_WISE_DAILY_TRANSACTION_SUMMARY,
  RESET_DAY_WISE_DAILY_TRANSACTION_SUMMARY,
} from '../../../../Types'

const MonthWiseDailyTransactionsSummaryReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DAY_WISE_DAILY_TRANSACTION_SUMMARY:
      return [...action.payload]
    case RESET_DAY_WISE_DAILY_TRANSACTION_SUMMARY:
      return []

    default:
      return state
  }
}
export default MonthWiseDailyTransactionsSummaryReducer
