import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import PublicRoute from './PublicRoutes'
import LoginPage from '../components/Login/LoginPage'
import ResetPassword from '../components/Login/ResetPassword'
import DeleteAccount from '../components/Users/DeleteAccount';
import LoanTermsAndConditions from '../components/Loan/LoanTermsAndConditions';

const DefaultRoutes = () => {
  return (
    <Switch>
      <Redirect strict exact from='/' to='/login' />
      <PublicRoute restricted exact component={LoginPage} path='/login' />
      <PublicRoute restricted exact component={DeleteAccount} path='/account/deleteaccount' />
      <PublicRoute restricted exact component={LoanTermsAndConditions} path='/loan/termsandconditions' />
      <PublicRoute
        restricted
        // exact
        component={ResetPassword}
        path='/resetpassword/:username/:code'
      />
      <PublicRoute path='*'>
        <Redirect to='/' />
      </PublicRoute>
    </Switch>
  )
}

export default DefaultRoutes;
