import React, { Component, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import { Input } from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'

import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../layouts/Input/FormikControl'

import storage from '../../../apis/Storage'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_VLP_EVENTS_DDL,
  GET_VLP_PAYER_DDL,
  GET_VLP_SHOP_CATEGORY_DDL,
  GET_DISTRIBUTOR_DDL,
  GET_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import {
  addVlpScheme,
  getVlpSchemes,
  updateVlpScheme,
  deleteScheme,
} from './SchemesListActions'
import Moment from 'react-moment'
import { confirmAlert } from 'react-confirm-alert'
import { getMfis } from '../../MFIs/MFIAction'
import axios from '../../../apis/Api'
import { isPermittedPage } from '../../../apis/Utility'
const SchemesList = (props) => {
  const [initialValues, setInitialValues] = useState({
    id: '',
    name: '',
    event: '',
    startDate: '',
    endDate: '',
    payer: '',
    shops: null,
    fixedCoinsPerTransaction: '',
    coinAsPercentageCoins: '',
    coinAsPercentageAmount: '',
    maxCoinsPerTransaction: '',
    transactionsPerDayLimit: '',
    distributor: '',
    center: '',
    principal: '',
    mfi: '',
  })
  const [uploadDataModalShow, setUploadDataModalShow] = useState(false)
  const [currentScheme, setCurrentScheme] = useState(initialValues)
  const [event, setEvent] = useState(null)
  const [payer, setPayer] = useState(null)
  const [shops, setShops] = useState(null)
  const [distributor, setDistributor] = useState(null)
  const [center, setCenter] = useState(null)
  const [principal, setPrincipal] = useState(null)
  const [mfi, setMfi] = useState(null)

  const [distributorsOptions, setDistributorsOptions] = useState([])
  const [centerOptions, setCenterOptions] = useState([])
  const [principalOptions, setPrincipalOptions] = useState([])
  const [mfiOptions, setMfiOptions] = useState([])

  const [isView, setIsView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [selectedOption, setSelectedOption] = useState('')

  const formikRef = useRef(null)

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string().required('Scheme Name is required'),
      event: Yup.string().required('Event is Required'),
      startDate: Yup.date().required('Start Date is Required'),
      endDate: Yup.date().required('End Date is Required'),
      payer: Yup.string().required('Payer is Required'),
      // shops: Yup.string().required('Shops is Required'),
      distributor: Yup.array()
        .min(1, 'Assign at least one Distributor')
        .required(),
      center: Yup.array().min(1, 'Assign at least one Center').required(),
      principal: Yup.array().min(1, 'Assign at least one Principal').required(),
      mfi: Yup.array().min(1, 'Assign at least one Mfi').required(),

      fixedCoinsPerTransaction: Yup.number().when(
        ['coinAsPercentageCoins', 'coinAsPercentageAmount'],
        {
          is: (coinAsPercentageCoins, coinAsPercentageAmount) =>
            (!coinAsPercentageCoins || coinAsPercentageCoins.length === 0) &&
            (!coinAsPercentageAmount || coinAsPercentageAmount.length === 0),
          then: Yup.number()
            .min(0, 'Number must be 0 or greater')
            .required('Coins per transaction is Required')
            .typeError('Enter a valid number'),
          otherwise: Yup.number(),
        }
      ),
      coinAsPercentageCoins: Yup.number().when('fixedCoinsPerTransaction', {
        is: (fixedCoinsPerTransaction) =>
          !fixedCoinsPerTransaction || fixedCoinsPerTransaction === '',
        then: Yup.number()
          .min(0, 'Number must be 0 or greater')
          .required('Coins are Required')
          .typeError('Enter a valid number'),
        otherwise: Yup.number(),
      }),
      coinAsPercentageAmount: Yup.number().when('fixedCoinsPerTransaction', {
        is: (fixedCoinsPerTransaction) =>
          !fixedCoinsPerTransaction || fixedCoinsPerTransaction === '',
        then: Yup.number()
          .min(0, 'Number must be 0 or greater')
          .required('Amount  is Required')
          .typeError('Enter a valid number'),
        otherwise: Yup.number(),
      }),
      maxCoinsPerTransaction: Yup.number()
        .min(0, 'Number must be 0 or greater')
        .required('Max coins per transaction is Required')
        .typeError('Enter a valid number'),
      transactionsPerDayLimit: Yup.number()
        .min(0, 'Number must be 0 or greater')
        .required('Transactions per day limit is Required')
        .typeError('Enter a valid number'),
    },
    [
      ['fixedCoinsPerTransaction', 'coinAsPercentageCoins'],
      ['fixedCoinsPerTransaction', 'coinAsPercentageAmount'],
    ]
  )

  const columns = [
    {
      Header: 'Scheme Title',
      accessor: 'name',
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          {row.value ? <Moment format='DD/MM/YYYY'>{row.value}</Moment> : '--'}
        </div>
      ),
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          {row.value ? <Moment format='DD/MM/YYYY'>{row.value}</Moment> : '--'}
        </div>
      ),
    },
    {
      Header: 'Event',
      accessor: 'event',
    },
    // {
    //   Header: 'Shops',
    //   accessor: 'shop',
    // },
    {
      Header: 'Payer',
      accessor: 'payer',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          {isPermittedPage('View Details', props.actions, props.userRole) &&
            <button
              onClick={() => view(row?.cell?.value)}
              className=' btn btn-sm'
            >
              <i className='fas fa-eye'></i>
            </button>
          }
          &nbsp;
          {isPermittedPage('Edit', props.actions, props.userRole) &&
            <button onClick={() => edit(row?.cell?.value)} className='btn btn-sm'>
              <i className='fas fa-edit'></i>
            </button>
          }
          &nbsp;
          {isPermittedPage('Delete', props.actions, props.userRole) &&
            <button
              onClick={() =>
                row?.row?.original?.isDeleteable ? Delete(row.value) : {}
              }
              className='btn btn-sm'
              title={
                !row?.row?.original?.isDeleteable
                  ? 'This scheme cannot be deleted because coins have already been earned on it.'
                  : ''
              }
            >
              <i
                className={
                  !row?.row?.original?.isDeleteable
                    ? 'fas fa-trash disabled-button'
                    : 'fas fa-trash'
                }
              ></i>
            </button>
          }
        </div>
      ),
    },
  ]

  const setValidationValue = () => {
    formikRef.current.setFieldValue('coinAsPercentageAmount', '')
    formikRef.current.setFieldValue('coinAsPercentageCoins', '')

    formikRef.current.setFieldValue('fixedCoinsPerTransaction', '')
    formikRef.current.setFieldValue('maxCoinsPerTransaction', '')
  }

  const handleSubmit = async (data) => {
    if (!isEdit) {
      let distributorCS = ''
      data.distributor?.forEach((element, index, arr) => {
        distributorCS +=
          index === arr.length - 1 ? element.value : element.value + ','
      })

      let centerCS = ''
      data.center?.forEach((element, index, arr) => {
        centerCS +=
          index === arr.length - 1 ? element.value : element.value + ','
      })

      let principalCS = ''
      data.principal?.forEach((element, index, arr) => {
        principalCS +=
          index === arr.length - 1 ? element.value : element.value + ','
      })

      let MfiCS = ''
      data.mfi?.forEach((element, index, arr) => {
        MfiCS += index === arr.length - 1 ? element.value : element.value + ','
      })

      let request = {
        Name: data.name,
        StartDate: new Date(
          Date.parse(
            new Intl.DateTimeFormat('fr-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }).format(data.startDate)
          )
        )
          .toISOString()
          .split('T')[0],

        EndDate: new Date(
          Date.parse(
            new Intl.DateTimeFormat('fr-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }).format(data.endDate)
          )
        )
          .toISOString()
          .split('T')[0],
        VLPEventId: data.event,
        VLPPayerId: data.payer,
        VLPShopCategoryId: data.shops,
        DistributorId: distributorCS,
        DistCenterId: centerCS,
        PrincipalId: principalCS,
        MfiId: MfiCS,
        MaxCoinsPerTransaction: data.maxCoinsPerTransaction,
        TransactionsPerDayLimit: data.transactionsPerDayLimit,
      }

      if (
        data.fixedCoinsPerTransaction != null &&
        data.fixedCoinsPerTransaction.length > 0
      ) {
        request = {
          ...request,
          FixedCoinsPerTransaction: data.fixedCoinsPerTransaction,
        }
      } else if (
        data.coinAsPercentageCoins != null &&
        data.coinAsPercentageCoins.length > 0 &&
        data.coinAsPercentageAmount != null &&
        data.coinAsPercentageAmount.length > 0
      ) {
        request = {
          ...request,
          CoinAsPercentageCoins: data.coinAsPercentageCoins,
          CoinAsPercentageAmount: data.coinAsPercentageAmount,
        }
      }

      await props
        .addVlpScheme(request)
        .then((response) => { })
        .catch((error) => {
          console.error('Error:', error)
        })
      props.getVlpSchemes()
    } else {
      let editReq = {
        Id: currentScheme.id,
        EndDate: new Date(
          Date.parse(
            new Intl.DateTimeFormat('fr-CA', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }).format(data.endDate)
          )
        )
          .toISOString()
          .split('T')[0],
      }
      await props.updateVlpScheme(currentScheme.id, editReq)
    }

    setUploadDataModalShow(false)
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    props.deleteScheme(id)

                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const AddScheme = () => {
    let vizProPayer = props.vlpPayers.filter((x) => x.label == 'Vizpro')[0]

    setCurrentScheme({
      ...initialValues,
      payer: vizProPayer.value,
      distributor: [{ value: '', label: 'All' }],
      principal: [{ value: '', label: 'All' }],
      mfi: [{ value: '', label: 'All' }],
      center: [{ value: '', label: 'All' }],
    })
    setIsView(false)
    setIsEdit(false)
    setEvent(null)
    setShops(null)
    setPayer(vizProPayer)

    setDistributor([{ value: '', label: 'All' }])
    setCenter([{ value: '', label: 'All' }])
    setMfi([{ value: '', label: 'All' }])
    setPrincipal([{ value: '', label: 'All' }])

    setSelectedOption('')
    setUploadDataModalShow(true)
    setCenterOptions([{ value: '', label: 'All' }])
  }

  const view = (id) => {
    let scheme = props.vlpSchemes.filter((x) => x.id == id)[0]
    setIsView(true)
    setIsEdit(false)

    setEvent({ label: scheme.event, value: scheme.vlpEventId })
    setPayer({ label: scheme.payer, value: scheme.vlpPayerId })
    setShops({ label: scheme.shop, value: scheme.vlpShopCategoryId })

    let schemeObj = {
      id: scheme.id,
      name: scheme.name,
      event: scheme.event,
      startDate: new Date(scheme.startDate),
      endDate: new Date(scheme.endDate),
      payer: scheme.payer,
      shops: scheme.shop,
      // distributor: scheme.distributorId == null ? '' : scheme.distributorId,
      // principal: scheme.principalId == null ? '' : scheme.principalId,
      // mfi: scheme.mfiId == null ? '' : scheme.mfiId,
      fixedCoinsPerTransaction: scheme.fixedCoinsPerTransaction
        ? scheme.fixedCoinsPerTransaction
        : '',
      coinAsPercentageCoins: scheme.coinAsPercentageCoins
        ? scheme.coinAsPercentageCoins
        : '',
      coinAsPercentageAmount: scheme.coinAsPercentageAmount
        ? scheme.coinAsPercentageAmount
        : '',
      maxCoinsPerTransaction: scheme.maxCoinsPerTransaction
        ? scheme.maxCoinsPerTransaction
        : '',
      transactionsPerDayLimit: scheme.transactionsPerDayLimit
        ? scheme.transactionsPerDayLimit
        : '',
    }

    let all = { value: '', label: 'All' }
    if (scheme.distributorId != null && scheme.distributorId != '') {
      let DistList = distributorsOptions.filter(
        (x) => scheme.distributorId.includes(x.value) && x.value != ''
      )
      setDistributor(DistList)
    } else {
      setDistributor(all)
    }

    if (scheme.distCenterId == null || scheme.distCenterId == '') {
      setCenter(all)
    }

    if (scheme.principalId != null && scheme.principalId != '') {
      let principalList = principalOptions.filter(
        (x) => scheme.principalId.includes(x.value) && x.value != ''
      )
      setPrincipal(principalList)
    } else {
      setPrincipal(all)
    }

    if (scheme.mfiId != null && scheme.mfiId != '') {
      let mfiList = mfiOptions.filter(
        (x) => scheme.mfiId.includes(x.value) && x.value != ''
      )
      setMfi(mfiList)
    } else {
      setMfi(all)
    }

    setCurrentScheme(schemeObj)

    if (
      scheme.fixedCoinsPerTransaction != '' &&
      scheme.fixedCoinsPerTransaction != null
    ) {
      setSelectedOption('option1')
    } else {
      setSelectedOption('option2')
    }

    setUploadDataModalShow(true)
  }

  const edit = (id) => {
    let scheme = props.vlpSchemes.filter((x) => x.id == id)[0]
    setIsEdit(true)
    setIsView(false)

    setEvent({ label: scheme.event, value: scheme.vlpEventId })
    setPayer({ label: scheme.payer, value: scheme.vlpPayerId })
    setShops({ label: scheme.shop, value: scheme.vlpShopCategoryId })

    let schemeObj = {
      id: scheme.id,
      name: scheme.name,
      event: scheme.event,
      startDate: new Date(scheme.startDate),
      endDate: new Date(scheme.endDate),
      payer: scheme.payer,
      shops: scheme.shop,
      // distributor: scheme.distributorId == null ? '' : scheme.distributorId,
      // principal: scheme.principalId == null ? '' : scheme.principalId,
      // mfi: scheme.mfiId == null ? '' : scheme.mfiId,
      fixedCoinsPerTransaction: scheme.fixedCoinsPerTransaction
        ? scheme.fixedCoinsPerTransaction
        : '',
      coinAsPercentageCoins: scheme.coinAsPercentageCoins
        ? scheme.coinAsPercentageCoins
        : '',
      coinAsPercentageAmount: scheme.coinAsPercentageAmount
        ? scheme.coinAsPercentageAmount
        : '',
      maxCoinsPerTransaction: scheme.maxCoinsPerTransaction
        ? scheme.maxCoinsPerTransaction
        : '',
      transactionsPerDayLimit: scheme.transactionsPerDayLimit
        ? scheme.transactionsPerDayLimit
        : '',
    }

    let all = { value: '', label: 'All' }
    if (scheme.distributorId != null && scheme.distributorId != '') {
      let DistList = distributorsOptions.filter(
        (x) => scheme.distributorId.includes(x.value) && x.value != ''
      )
      setDistributor(DistList)
      schemeObj = { ...schemeObj, distributor: DistList }
    } else {
      setDistributor(all)
      schemeObj = { ...schemeObj, distributor: [all] }
    }

    if (scheme.distCenterId == null || scheme.distCenterId == '') {
      setCenter(all)
      schemeObj = { ...schemeObj, center: [all] }
    }

    if (scheme.principalId != null && scheme.principalId != '') {
      let principalList = principalOptions.filter(
        (x) => scheme.principalId.includes(x.value) && x.value != ''
      )
      setPrincipal(principalList)
      schemeObj = { ...schemeObj, principal: principalList }
    } else {
      setPrincipal(all)
      schemeObj = { ...schemeObj, principal: [all] }
    }

    if (scheme.mfiId != null && scheme.mfiId != '') {
      let mfiList = mfiOptions.filter(
        (x) => scheme.mfiId.includes(x.value) && x.value != ''
      )
      setMfi(mfiList)
      schemeObj = { ...schemeObj, mfi: mfiList }
    } else {
      setMfi(all)
      schemeObj = { ...schemeObj, mfi: [all] }
    }

    setCurrentScheme(schemeObj)

    if (
      scheme.fixedCoinsPerTransaction != '' &&
      scheme.fixedCoinsPerTransaction != null
    ) {
      setSelectedOption('option1')
    } else {
      setSelectedOption('option2')
    }

    setUploadDataModalShow(true)
  }

  const getPrincipalList = async () => {
    await axios
      .get('api/DistributorPrincipalDetails/GetAllDistributorPrinicipals')
      .then(({ data }) => {
        let all = { value: '', label: 'All' }
        setPrincipalOptions([
          all,
          ...data?.response?.map(function (item) {
            return {
              value: item.id,
              label: item.principalName,
            }
          }),
        ])
      })
      .catch(function (error) { })
  }

  const distributorChanged = (distributor) => {
    let singleRecord = distributor.filter(
      (x) => x.value != currentScheme.distributor[0]?.value
    )
    currentScheme.distributor = singleRecord
    setDistributor(singleRecord)

    setCenter(null)
    setCurrentScheme({ ...currentScheme, center: '' })
  }

  useEffect(() => {
    props.getVlpSchemes()
    props.getDropdownList(`api/VLPEvents`, GET_VLP_EVENTS_DDL)
    props.getDropdownList(`api/VLPPayer`, GET_VLP_PAYER_DDL)
    props.getDropdownList(`api/VLPShopCategory`, GET_VLP_SHOP_CATEGORY_DDL)
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
    props.getMfis()
    getPrincipalList()
  }, [])

  useEffect(() => {
    let all = { value: '', label: 'All' }
    setDistributorsOptions([
      all,
      ...props.allDistributorList.map(function (item) {
        return {
          value: item.value,
          label: item.label,
        }
      }),
    ])
  }, [props.allDistributorList])

  useEffect(() => {
    let all = { value: '', label: 'All' }
    setCenterOptions([
      all,
      ...props.distCentersddlList.map(function (item) {
        return {
          value: item.value,
          label: item.label,
        }
      }),
    ])
  }, [props.distCentersddlList])

  useEffect(() => {
    if (
      distributor &&
      distributor.length > 0 &&
      distributor[0]?.label != 'All'
    ) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${distributor[0].value}`,
        GET_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [distributor])

  useEffect(() => {
    if (isEdit || isView) {
      let all = { value: '', label: 'All' }
      let scheme = props.vlpSchemes.find((x) => x.id == currentScheme.id)
      if (scheme.distCenterId != null && scheme.distCenterId != '') {
        let DistList = centerOptions.filter(
          (x) => scheme.distCenterId.includes(x.value) && x.value != ''
        )
        setCenter(DistList)
        setCurrentScheme({ ...currentScheme, center: DistList })
      } else {
        setDistributor(all)
        setCurrentScheme({ ...currentScheme, center: [all] })
      }
    }
  }, [centerOptions])

  useEffect(() => {
    let all = { value: '', label: 'All' }

    if (props.mfis && props.mfis.length > 0) {
      setMfiOptions([
        all,
        ...props.mfis.map(function (item) {
          return {
            value: item.id,
            label: item.mfiName,
          }
        }),
      ])
    }
  }, [props.mfis])

  return (
    <div>
      <VzPageContainer title='Schemes List'>
        <Row>
          <Col style={{ float: 'left' }}>
            <h4>
              <b>Schemes List</b>
            </h4>
            <br />
          </Col>
        </Row>
        {isPermittedPage('Create New Scheme', props.actions, props.userRole) &&
          <Row style={{ marginBottom: 30 }}>
            <Col>
              <div className='form-group'>
                <Button
                  className='form-btn'
                  variant='primary'
                  onClick={AddScheme}
                >
                  Create New Scheme
                </Button>
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col xs={12} md={12}>
            {/* <ServerSideDataTable
              data={props.DeliverymanUserList.users ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            /> */}
            <CustomDataTable
              data={props.vlpSchemes}
              columns={columns}
              getTrProps={(event, data) => null}
            />
          </Col>
        </Row>
        <Modal
          show={uploadDataModalShow}
          onHide={() => setUploadDataModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Formik
            enableReinitialize
            initialValues={currentScheme}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    Scheme Details
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='name'
                            label='Scheme Name'
                            name='name'
                            handleOnChange={(e) => (currentScheme.name = e)}
                            value={currentScheme.name}
                            disabled={isView || isEdit}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='event'
                            label='Event'
                            name='event'
                            value={event}
                            options={props.vlpEvents}
                            handleOnChange={(value) => {
                              currentScheme.event = value.value
                              setEvent(value)
                            }}
                            isDisabled={isView || isEdit}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='date'
                            type='startDate'
                            label='Start Date'
                            name='startDate'
                            handleOnChange={(e) =>
                              (currentScheme.startDate = e)
                            }
                            value={currentScheme.startDate}
                            minDate={new Date()}
                            isDisabled={isView || isEdit}
                            maxDate={
                              currentScheme.endDate ? currentScheme.endDate : ''
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='date'
                            type='endDate'
                            label='End Date'
                            name='endDate'
                            value={currentScheme.endDate}
                            handleOnChange={(e) => (currentScheme.endDate = e)}
                            minDate={
                              currentScheme.startDate && !isEdit
                                ? currentScheme.startDate
                                : new Date()
                            }
                            isDisabled={isView}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='payer'
                            label='Payer'
                            name='payer'
                            value={payer}
                            options={props.vlpPayers}
                            handleOnChange={(value) => {
                              currentScheme.payer = value.value
                              setPayer(value)
                            }}
                            isDisabled={isView || isEdit}
                          />
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      {' '}
                      <Col xs={6} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='shops'
                            label='Shops'
                            name='shops'
                            value={shops}
                            options={props.vlpShopCategories}
                            handleOnChange={(value) => {
                              currentScheme.shops = value.id
                              setShops(value)
                            }}
                            isDisabled={isView || isEdit}
                          />
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col xs={3} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='distributor'
                            label='Distributor'
                            name='distributor'
                            value={distributor}
                            options={distributorsOptions}
                            handleOnChange={(value) => {
                              distributorChanged(value)

                              // let checkAll = value.filter(
                              //   (x) => x.label == 'All'
                              // )
                              // if (checkAll.length > 0) {
                              //   currentScheme.distributor = checkAll
                              //   setDistributor(checkAll)
                              //   return
                              // }
                              // currentScheme.distributor = value
                              // setDistributor(value)
                            }}
                            isDisabled={isView || isEdit}
                            isMultiSelect={true}
                          />
                        </div>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='center'
                            label='Distribution Center'
                            name='center'
                            value={center}
                            options={centerOptions}
                            handleOnChange={(value) => {
                              let checkAll = value.filter(
                                (x) => x.label == 'All'
                              )
                              if (checkAll.length > 0) {
                                currentScheme.center = checkAll
                                setCenter(checkAll)
                                return
                              }
                              currentScheme.center = value
                              setCenter(value)
                            }}
                            isDisabled={isView || isEdit}
                            isMultiSelect={true}
                          />
                        </div>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='principal'
                            label='Principal'
                            name='principal'
                            value={principal}
                            options={principalOptions}
                            handleOnChange={(value) => {
                              let checkAll = value.filter(
                                (x) => x.label == 'All'
                              )
                              if (checkAll.length > 0) {
                                currentScheme.principal = checkAll
                                setPrincipal(checkAll)
                                return
                              }
                              currentScheme.principal = value
                              setPrincipal(value)
                            }}
                            isDisabled={isView || isEdit}
                            isMultiSelect={true}
                          />
                        </div>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            type='mfi'
                            label='Mfi'
                            name='mfi'
                            value={mfi}
                            options={mfiOptions}
                            handleOnChange={(value) => {
                              let checkAll = value.filter(
                                (x) => x.label == 'All'
                              )
                              if (checkAll.length > 0) {
                                currentScheme.mfi = checkAll
                                setMfi(checkAll)
                                return
                              }
                              currentScheme.mfi = value
                              setMfi(value)
                            }}
                            isDisabled={isView || isEdit}
                            isMultiSelect={true}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <p>Scheme Earning Type (Select One)</p>
                    </Row>
                    <Row className='ms-1 align-items-center'>
                      <Col xs={3} md={3} className='my-0 py-0'>
                        <label className='my-0'>
                          <input
                            disabled={isView || isEdit}
                            type='radio'
                            value='option1'
                            checked={selectedOption === 'option1'}
                            onChange={(event) => {
                              setSelectedOption(event.target.value)
                              setValidationValue()
                              currentScheme.coinAsPercentageCoins = ''
                              currentScheme.coinAsPercentageAmount = ''
                            }}
                            className='mr-2 radio-input'
                          />
                          Fixed Coins per Transaction
                        </label>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='fixedCoinsPerTransaction'
                            // label='Fixed Coins per Transaction'
                            name='fixedCoinsPerTransaction'
                            handleOnChange={(e) => {
                              currentScheme.fixedCoinsPerTransaction = e
                              currentScheme.maxCoinsPerTransaction = e
                            }}
                            value={currentScheme.fixedCoinsPerTransaction}
                            disabled={
                              currentScheme.coinAsPercentageCoins.length > 0 ||
                                currentScheme.coinAsPercentageAmount.length > 0 ||
                                isView ||
                                selectedOption != 'option1' ||
                                isEdit
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className='ms-1 align-items-center'>
                      <Col xs={3} md={3}>
                        <label className='my-0'>
                          <input
                            disabled={isView || isEdit}
                            type='radio'
                            value='option2'
                            checked={selectedOption === 'option2'}
                            onChange={(event) => {
                              setSelectedOption(event.target.value)
                              setValidationValue()
                              currentScheme.fixedCoinsPerTransaction = ''
                              currentScheme.maxCoinsPerTransaction = ''
                            }}
                            className='mr-2 radio-input'
                          />
                          Coins Per Amount of Transaction
                        </label>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='coinAsPercentageCoins'
                            // label='Coins as Percentage (Coins)'
                            name='coinAsPercentageCoins'
                            handleOnChange={(e) => {
                              currentScheme.coinAsPercentageCoins = e
                            }}
                            value={currentScheme.coinAsPercentageCoins}
                            disabled={
                              currentScheme.fixedCoinsPerTransaction.length >
                                0 ||
                                isView ||
                                selectedOption != 'option2' ||
                                isEdit
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        xs={1}
                        md={1}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p className='my-0'>per</p>
                      </Col>
                      <Col xs={3} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='coinAsPercentageAmount'
                            // label='Coins as Percentage (Amount) '
                            name='coinAsPercentageAmount'
                            handleOnChange={(e) => {
                              currentScheme.coinAsPercentageAmount = e
                            }}
                            value={currentScheme.coinAsPercentageAmount}
                            disabled={
                              currentScheme.fixedCoinsPerTransaction.length >
                                0 ||
                                isView ||
                                selectedOption != 'option2' ||
                                isEdit
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        xs={2}
                        md={2}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <p className='my-0'>of transactions</p>
                      </Col>
                    </Row>
                    <Row>
                      <p>Earning Limits</p>
                    </Row>
                    <Row className='ms-1'>
                      <Col xs={6} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='maxCoinsPerTransaction'
                            label='Max Coins per Transaction'
                            name='maxCoinsPerTransaction'
                            handleOnChange={(e) => {
                              currentScheme.maxCoinsPerTransaction = e
                            }}
                            value={currentScheme.maxCoinsPerTransaction}
                            disabled={
                              isView ||
                              isEdit ||
                              currentScheme.fixedCoinsPerTransaction != ''
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className='ms-1'>
                      <Col xs={6} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='input'
                            type='transactionsPerDayLimit'
                            label='Transactions Per Day Limit'
                            name='transactionsPerDayLimit'
                            handleOnChange={(e) => {
                              currentScheme.transactionsPerDayLimit = e
                            }}
                            value={currentScheme.transactionsPerDayLimit}
                            disabled={isView || isEdit}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  {!isView && (
                    <>
                      <input
                        type='submit'
                        className='btn btn-primary'
                        value={!isEdit ? 'Save' : 'Update'}
                      />
                      <Button
                        className='btn btn-default'
                        onClick={() => setUploadDataModalShow(false)}
                      >
                        Close
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    vlpEvents: state.ddlList.vlpEvents ? state.ddlList.vlpEvents : [],
    vlpPayers: state.ddlList.vlpPayers ? state.ddlList.vlpPayers : [],
    vlpShopCategories: state.ddlList.vlpShopCategories
      ? state.ddlList.vlpShopCategories
      : [],
    vlpSchemes: state.SchemesList ? state.SchemesList : [],
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    distCentersddlList: state.ddlList.selectedDistCentersList
      ? state.ddlList.selectedDistCentersList
      : [],
    mfis: state.MFIs ? state.MFIs : [],
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  addVlpScheme,
  getVlpSchemes,
  updateVlpScheme,
  deleteScheme,
  getMfis,
})(SchemesList)
