import {
  GET_ORDER_SHOP_DETAILS,
  ADD_ORDER_SHOP_DETAILS,
  GET_SINGLE_ORDER_SHOP_DETAILS,
} from '../../../../Types'
import _ from 'lodash'

const OrderShopDetailsReducer = (
  state = { orderShopsList: [], selectedOrderDetails: {} },
  action
) => {
  switch (action.type) {
    case GET_ORDER_SHOP_DETAILS:
      return { orderShopsList: [...action.payload] }
    case ADD_ORDER_SHOP_DETAILS:
      return { orderShopsList: [...state.orderShopsList, action.payload] }
    case GET_SINGLE_ORDER_SHOP_DETAILS:
      return {...state, selectedOrderDetails: action.payload }
    default:
      return state
  }
}

export default OrderShopDetailsReducer
