import { GET_SCR_DETAIL_REPORT } from '../../../Types'

const SCRDetailReportReducer = (state = [], action) => {
    switch (action.type) {
        case GET_SCR_DETAIL_REPORT:
            return action.payload
        default:
            return state
    }
}
export default SCRDetailReportReducer