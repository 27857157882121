import {GET_Notification_Schedular, ADD_Notification_Schedular, DEL_Notification_Schedular, UPDATE_Notification_Schedular } from "../../../Types"
import _ from 'lodash'
  
  const NotificationSchedulerReducer = (state = [], action) => {
    switch (action.type) {
        case GET_Notification_Schedular:
            return action.payload;        
        case ADD_Notification_Schedular:
            return [action.payload, ...state]    
        case UPDATE_Notification_Schedular:
                return [action.payload, ...state]    
        case DEL_Notification_Schedular:
                return [action.payload, ...state]
        default:
            return state
    }
  }
  export default NotificationSchedulerReducer
  