import { GET_NOTIFICATIONS, ADD_NOTIFICATION } from '../../../Types'
const initialState = {
  totalRecords: 0,
  notifications: [],
}
const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return action.payload
    default:
      return state
  }
}
export default NotificationsReducer
