import { Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import _ from 'lodash'

import FormikControl from '../../../layouts/Input/FormikControl'
import {
  addMainInformations,
  getMainInformations,
  updateMainInformations,
  removeAllDataFromRedux,
} from './MainInformationsAction'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

import { getDropdownList } from '../../Shared/dropdownListActions'
import { GET_BANKS, GET_CITIES, GET_BUSINESS_ENTITIES } from '../../../Types'
// import { Prompt } from "react-router";
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { useDispatch } from 'react-redux'
import axios from '../../../apis/Api'
import PlaceholderImage from '../../../assets/Images/placeholder-image.png'
import { uploadFile } from '../../../utilities/ImageUpload'

const ManageMainInformation = (props) => {
  // const { addToast } = useToasts();
  const dispatch = useDispatch()

  const [OfficeEmailValue, setOfficeEmailValue] = useState([])
  const [OfficeContactValue, setOfficeContactValue] = useState([])
  const [initialValues, setInitialValues] = useState({
    DistributorName: '',
    HeadOfficeAddress: '',
    HeadOfficeContacts: '',
    NTNGSTNo: '',
    Strn: '',
    HeadOfficeEmails: '',
    OwnerCEOName: '',
    BusinessOrganizationType: '',
    City: '',
    Icon: '',
  })
  const [CityValue, setCityValue] = useState(null)
  const [BusinessTypeValue, setBusinessTypeValue] = useState(null)
  const { id } = useParams()
  const [disabled, setDisabled] = useState(false)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (id) {
      props.getMainInformations(id)
    }
    return () => {
      props.removeAllDataFromRedux()
    }
  }, [])
  useEffect(() => {
    if (props.distributorMainInfo.id && !id) setDisabled(true)

    return () => {
      setDisabled(false)
    }
  }, [props.distributorMainInfo.id])

  // const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)
  // useEffect(() => {
  //   if (shouldBlockNavigation) {
  //     window.onbeforeunload = () => true;
  //   } else {
  //     window.onbeforeunload = undefined;
  //   }
  // }, [shouldBlockNavigation]);

  useEffect(() => {
    // if (props.distributorMainInfo.id) setShouldBlockNavigation(true)
    // here we will get data on edit of distributor
    if (id != null && id !== '' && props.distributorMainInfo.id) {
      setOfficeEmailValue(
        props.distributorMainInfo.headOfficeEmails
          ? props.distributorMainInfo.headOfficeEmails
              .split(',')
              .map((value) => {
                return { label: value, value: value }
              })
          : []
      )
      setOfficeContactValue(
        props.distributorMainInfo.headOfficeContacts
          ? props.distributorMainInfo.headOfficeContacts
              .split(',')
              .map((value) => {
                return { label: value, value: value }
              })
          : []
      )
      if (props.distributorMainInfo.city)
        setCityValue({
          label: props.distributorMainInfo.city,
          value: props.distributorMainInfo.city,
        })
      if (props.distributorMainInfo.businessOrganizationType)
        setBusinessTypeValue({
          label: props.distributorMainInfo.businessOrganizationType.trim(),
          value: props.distributorMainInfo.businessOrganizationType.trim(),
        })
      setInitialValues({
        ...initialValues,
        DistributorName: props.distributorMainInfo.distributorName,
        HeadOfficeAddress: props.distributorMainInfo.headOfficeAddress,
        HeadOfficeContacts: props.distributorMainInfo.headOfficeContacts
          ? props.distributorMainInfo.headOfficeContacts
              .split(',')
              .map((value) => {
                return { label: value, value: value }
              })
          : [],
        NTNGSTNo: props.distributorMainInfo.ntnOrGst,
        Strn: props.distributorMainInfo.strn,
        HeadOfficeEmails: props.distributorMainInfo.headOfficeEmails
          ? props.distributorMainInfo.headOfficeEmails
              .split(',')
              .map((value) => {
                return { label: value, value: value }
              })
          : [],
        OwnerCEOName: props.distributorMainInfo.ownerOrCEOName,
        BusinessOrganizationType: props.distributorMainInfo
          .businessOrganizationType
          ? props.distributorMainInfo.businessOrganizationType
          : '',
        City: props.distributorMainInfo.city,
        Icon: props.distributorMainInfo.icon,
      })
    }
  }, [props.distributorMainInfo])

  useEffect(() => {
    if (props.banks.length == 0) {
      props.getDropdownList(`api/Bank`, GET_BANKS)
    }
    if (props.cities.length == 0) {
      props.getDropdownList(`api/City`, GET_CITIES)
    }

    if (props.businessEntities.length == 0) {
      props.getDropdownList(`api/BusinessEntityType`, GET_BUSINESS_ENTITIES)
    }
  }, [])

  const validationSchema = Yup.object().shape({
    DistributorName: Yup.string().required('Distributor Name is required'),
    HeadOfficeContacts: Yup.array().max(5, 'You can add maximum 5 Contacts'),
    HeadOfficeEmails: Yup.array().max(5, 'You can add maximum 5 Emails'),
  })

  const citiesOptions = [
    { value: 'Lahore', label: 'Lahore' },
    { value: 'Karachi', label: 'Karachi' },
    { value: 'Islamabad', label: 'Islamabad' },
  ]
  const businessTypeOptions = [
    { value: 'AOP', label: 'AOP' },
    { value: 'Private Limited', label: 'Private Limited' },
    { value: 'Public Limited', label: 'Public Limited' },
    { value: 'Sole Proprietor', label: 'Sole Proprietor' },
    { value: 'Government Organization', label: 'Government Organization' },
    { value: 'Foreign Company', label: 'Foreign Company' },
  ]
  const handleSubmit = async (data) => {
    if (!data.DistributorName) {
      toast.warn('Distributor Name is required')
      return
    }
    const requestData = {
      distributorName: data.DistributorName,
      city: CityValue?.value,
      headOfficeAddress: data.HeadOfficeAddress,
      headOfficeContacts: _.map(OfficeContactValue, 'label').join(', '),
      headOfficeEmails: _.map(OfficeEmailValue, 'label').join(', '),
      ntnOrGst: data.NTNGSTNo,
      strn: data.Strn,
      businessOrganizationType: BusinessTypeValue?.value,
      ownerOrCEOName: data.OwnerCEOName,
      icon: data?.Icon,
    }
    props.distributorMainInfo.id
      ? await props.updateMainInformations(
          props.distributorMainInfo.id,
          requestData
        )
      : await props.addMainInformations(requestData)
  }

  const handleInputChange = (field, selectedData) => {
    if (field === 'HeadOfficeContacts') {
      setOfficeContactValue(selectedData)
    } else if ('HeadOfficeEmails') {
      setOfficeEmailValue(selectedData)
    }
  }

  const saveImage = (event) => {
    dispatch(showLoading())
    uploadFile(event.target.files[0])
      .then((response) => {
        dispatch(hideLoading())
        initialValues.Icon = response
        setCount(count + 1)
      })
      .catch(function (error) {
        dispatch(hideLoading())
        toast.error('Image upload failed')
      })

    // dispatch(showLoading())
    // const formData = new FormData()
    // formData.append('DataFile', event.target.files[0])
    // axios
    //   .post(`api/LenderKYC/UploadImage`, formData)
    //   .then(({ data }) => {
    //     initialValues.Icon = data?.response
    //     dispatch(hideLoading())
    //   })
    //   .catch(function (error) {
    //     dispatch(hideLoading())
    //   })
  }

  const removeImage = () => {
    initialValues.Icon = ''
    setCount(count + 1)
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form
            autoComplete='off'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
              }
            }}
          >
            <Row>
              <Col xs={12} md={9}>
                <Row>
                  <Col xs={12} md={8}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='input'
                        type='DistributorName'
                        label='Distributor Name'
                        name='DistributorName'
                        handleOnChange={(value) => {
                          initialValues.DistributorName = value
                          setDisabled(false)
                        }}
                        value={initialValues.DistributorName}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='react-select'
                        type='BusinessOrganizationType'
                        label='Business Entity Type'
                        name='BusinessOrganizationType'
                        options={_.orderBy(
                          props.businessEntities,
                          ['value'],
                          ['asc']
                        )}
                        isSearchable={true}
                        // isClearable={true}
                        handleOnChange={(e) => {
                          if (!e || !e.value) {
                            return
                          }
                          setBusinessTypeValue({
                            label: e.label,
                            value: e.value,
                          })
                          initialValues.businessOrganizationType = e.label
                          setDisabled(false)
                        }}
                        value={BusinessTypeValue}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='react-select'
                        type='City'
                        label='City'
                        name='City'
                        options={props.cities}
                        isSearchable={false}
                        handleOnChange={(e) => {
                          if (!e || !e.value) {
                            return
                          }
                          setCityValue({ label: e.value, value: e.value })
                          initialValues.City = e.value
                          setDisabled(false)
                        }}
                        value={CityValue}
                      />
                    </div>
                  </Col>
                  <Col xs={6} md={4}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='input'
                        type='NTNGSTNo'
                        label='NTN'
                        name='NTNGSTNo'
                        handleOnChange={(value) => {
                          initialValues.NTNGSTNo = value
                          setDisabled(false)
                        }}
                        value={initialValues.NTNGSTNo}
                      />
                    </div>
                  </Col>
                  <Col xs={6} md={4}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='input'
                        type='Strn'
                        label='STRN'
                        name='Strn'
                        handleOnChange={(value) => {
                          initialValues.Strn = value
                        }}
                        value={initialValues.Strn}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='input'
                        type='OwnerCEOName'
                        label='Owner/CEO Name'
                        name='OwnerCEOName'
                        handleOnChange={(value) => {
                          initialValues.OwnerCEOName = value
                          setDisabled(false)
                        }}
                        value={initialValues.OwnerCEOName}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='react-creatable-noOption'
                        type='emails'
                        label='Head Office E-mail'
                        name='HeadOfficeEmails'
                        value={OfficeEmailValue}
                        handleValueChange={(value) => {
                          handleInputChange('HeadOfficeEmails', value)
                          setDisabled(false)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='input'
                        type='HeadOfficeAddress'
                        label='Head Office Address'
                        name='HeadOfficeAddress'
                        handleOnChange={(value) => {
                          initialValues.HeadOfficeAddress = value
                          setDisabled(false)
                        }}
                        value={initialValues.HeadOfficeAddress}
                      />
                    </div>
                  </Col>

                  <Col xs={12} md={8}>
                    <div className='form-group'>
                      <FormikControl
                        formik={formik}
                        control='react-creatable-noOption'
                        type='contacts'
                        label='Head Office Contact'
                        name='HeadOfficeContacts'
                        value={OfficeContactValue}
                        handleValueChange={(value) => {
                          handleInputChange('HeadOfficeContacts', value)
                          setDisabled(false)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={3}>
                <Row>
                  <Col>
                    <div className='form-group '>
                      <div>Select Distributor's Image</div>
                      <input
                        type='file'
                        name='distributorIcon'
                        accept='image/*'
                        onChange={(event) => saveImage(event)}
                      />
                      <div className='mt-3 text-md-center'>
                        <img
                          style={{ border: '1px solid #ccc' }}
                          alt='not fount'
                          width={'150px'}
                          height={'150px'}
                          src={initialValues.Icon || PlaceholderImage}
                        />
                        {initialValues.Icon && (
                          <div>
                            <Button
                              onClick={removeImage}
                              className='btn-sm  mt-3'
                              style={{ backgroundColor: '#ea5f2d' }}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className='row'>
              <div className='form-group'>
                <div className='float-right'>
                  <Button type='submit' className='btn btn-primary'>
                    {!props.distributorMainInfo.id ? 'Save' : 'Update'}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo,
    cities: state.ddlList.cities ? state.ddlList.cities : [],
    businessEntities: state.ddlList.businessEntities
      ? state.ddlList.businessEntities
      : [],
    banks: state.ddlList.banks ? state.ddlList.banks : [],
  }
}

export default connect(mapStateToProps, {
  addMainInformations,
  updateMainInformations,
  getMainInformations,
  removeAllDataFromRedux,
  getDropdownList,
})(ManageMainInformation)
