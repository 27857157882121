import { useEffect, useState, useRef, memo } from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import history from '../../../history'
import 'react-confirm-alert/src/react-confirm-alert.css'

import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { connect } from 'react-redux'

import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { useParams } from 'react-router-dom'
import axios from '../../../apis/Api'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Input } from 'reactstrap'
import ReactSelect from 'react-select'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'

import { getDropdownList } from '../../Shared/dropdownListActions'
import { GET_DISTRIBUTOR_DDL } from '../../../Types'
const EditShop = (props) => {
  const { id } = useParams()

  const [shopData, setShopData] = useState(null)
  const [shopCategories, setShopCategories] = useState([])
  const [subLocalities, setSubsetSubLocalities] = useState([])
  const [mfiList, setMfiList] = useState([])
  const [rsoList, setRsoList] = useState([])
  const [distributorList, setDistributorList] = useState([])

  const [rsoModal, setRsoModal] = useState(false)
  const [selectedRso, setSelectedRso] = useState(null)
  const [walletModal, setWalletModal] = useState(false)
  const [distributorModal, setDistributorModal] = useState(false)

  const toggleValues = {
    profileInformation: true,
    address: true,
    wallet: true,
    distributorProfile: true,
    agents: true,
  }
  const [showSection, setShowSection] = useState(toggleValues)

  const getShopData = () => {
    axios
      .get(`api/VizShop/GetVizShopForWeb/${id}`)
      .then(({ data }) => {
        if (data?.cityId) {
          getSubsetSubLocalities(data.cityId)
        }
        setShopData(data)
      })
      .catch(function (error) { })
  }

  const initialValues = {
    vizShopCode: '',
    cnic: '',
    vizShopName: '',
    shopCategory: '',
    address: '',
    locality: '',
    area: '',
    lat: '',
    long: '',
    mapAddress: '',
    reasonDetailsOfWalletOrBank: '',
    selectedReasonForWalletOrBank: '',
    isActive: false,
    isShopOwnerHavePhoneNo: false,
    cityId: '',
  }

  const toggleSection = (name) => {
    setShowSection({ ...showSection, [name]: !showSection[name] })
  }

  const getShopCategories = () => {
    axios
      .get(`api/VizShop/GetShopCategories`)
      .then(({ data }) => {
        setShopCategories(
          data?.response?.map(function (item) {
            return {
              value: item.name,
              label: item.name,
            }
          })
        )
      })
      .catch(function (error) { })
  }

  const getSubsetSubLocalities = (cityId) => {
    axios
      .get(`api/Area/${cityId}`)
      .then(({ data }) => {
        setSubsetSubLocalities(
          data?.response?.map(function (item) {
            return {
              value: item.name,
              label: item.name,
            }
          })
        )
      })
      .catch(function (error) { })
  }

  const getMfis = () => {
    axios
      .get(`api/Mfi`)
      .then(({ data }) => {
        setMfiList(
          data?.map(function (item) {
            return {
              value: item.id,
              label: item.mfiName,
            }
          })
        )
      })
      .catch(function (error) { })
  }

  const getRsoList = () => {
    axios
      .get(`api/User/GetRSODDL`)
      .then(({ data }) => {
        setRsoList(
          data?.response?.map(function (item) {
            return {
              value: item.value,
              label: item.label,
            }
          })
        )
      })
      .catch(function (error) { })
  }

  const getDistributorList = () => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }

  const saveShopData = () => {
    axios
      .post(`api/VizShop/UpdateVizShopForWeb`, shopData)
      .then(({ data }) => {
        toast.success('Shop updated')
      })
      .catch(function (error) { })
  }

  const openRsoModal = () => {
    let rso = rsoList.find((x) => x.value == shopData?.rsoId)
    setSelectedRso(rso)
    setRsoModal(true)
  }

  const setNewRso = () => {
    setShopData({ ...shopData, rsoId: selectedRso.value })
    setRsoModal(false)
  }

  const NavigateToVizShopsList = () => {
    history.push(`/vizLinkShopsList`)
    history.go(`/vizLinkShopsList`)
  }

  useEffect(() => {
    getShopData()
    getShopCategories()
    getRsoList()
    getMfis()
    getDistributorList()
  }, [])

  useEffect(() => {
    setDistributorList(
      props.allDistributorList.map(function (item) {
        return {
          value: item.value,
          label: item.label,
        }
      })
    )
  }, [props.allDistributorList])

  return (
    <div>
      <VzPageContainer title='Edit Shop'>
        <Row>
          <Col>
            <Row>
              <Col md={4} className='d-flex align-items-center'>
                <p className=''>Viz ID</p>
              </Col>
              <Col md={6}>
                <div className='form-group '>
                  <Input
                    value={shopData?.vizShopCode}
                    type='text'
                    placeholder='Enter Shop Code'
                    readOnly={true}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md={4} className='d-flex align-items-center'>
                <p> Shop Closed</p>
              </Col>
              <Col md={6}>
                <div className='d-flex align-items-center'>
                  <input
                    checked={!shopData?.isActive}
                    value={shopData?.isActive}
                    type='checkbox'
                    onChange={(event) =>
                      // handleCheckboxSelectDistCenter(event, index)
                      setShopData({ ...shopData, isActive: false })
                    }
                    style={{ transform: 'scale(1.3)' }}
                  />
                  <span className='ms-2'>Yes</span>

                  <input
                    checked={shopData?.isActive}
                    value={shopData?.isActive}
                    type='checkbox'
                    onChange={(event) =>
                      setShopData({ ...shopData, isActive: true })
                    }
                    style={{ transform: 'scale(1.3)' }}
                    className='ms-4'
                  />
                  <span className='ms-2'>No</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Row>
              <Col md={4} className='d-flex align-items-center'>
                <p className=''>Finja Contact No</p>
              </Col>
              <Col md={6}>
                <div className='form-group '>
                  <Input
                    value={shopData?.finjaContactNo}
                    type='text'
                    placeholder='Enter Finja Contact'
                    onChange={(e) =>
                      setShopData({
                        ...shopData,
                        finjaContactNo: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className='mt-4 mb-3'>
          <Col>
            <h5
              style={{
                color: '#EA5F2D',
                fontWeight: 'bold',
              }}
            >
              Profile Information{' '}
              <span
                className='ms-4 mouse-pointer'
                onClick={() => toggleSection('profileInformation')}
              >
                {showSection.profileInformation ? '-' : '+'}
              </span>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            {showSection.profileInformation ? (
              <ProfileSection
                cnic={shopData?.cnic}
                vizShopName={shopData?.vizShopName}
                shopCategory={shopData?.shopCategory}
                shopCategories={shopCategories}
                ownerName={shopData?.ownerName}
                isShopOwnerHavePhoneNo={shopData?.isShopOwnerHavePhoneNo}
                contactNumbers={shopData?.contactNumbers}
                ntn={shopData?.ntn}
                saleTaxNo={shopData?.saleTaxNo}
                updateData={(data) => setShopData({ ...shopData, ...data })}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className='mt-4 mb-3'>
          <Col>
            <h5
              style={{
                color: '#EA5F2D',
                fontWeight: 'bold',
              }}
            >
              Address Details{' '}
              <span
                className='ms-4 mouse-pointer'
                onClick={() => toggleSection('address')}
              >
                {showSection.address ? '-' : '+'}
              </span>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            {showSection.address ? (
              <AddressSection
                address={shopData?.address}
                locality={shopData?.locality}
                area={shopData?.area}
                lat={shopData?.lat}
                long={shopData?.long}
                mapAddress={shopData?.mapAddress}
                subLocalities={subLocalities}
                updateData={(data) => setShopData({ ...shopData, ...data })}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className='mt-4 mb-3'>
          <Col>
            <h5
              style={{
                color: '#EA5F2D',
                fontWeight: 'bold',
              }}
            >
              Wallet Details{' '}
              <span
                className='ms-4 mouse-pointer'
                onClick={() => toggleSection('wallet')}
              >
                {showSection.wallet ? '-' : '+'}
              </span>
            </h5>
          </Col>
          <Col>
            {showSection.wallet ? (
              <Button
                variant='primary'
                onClick={() => setWalletModal(true)}
                className='btn btn-primary btn-sm ms-3'
                style={{
                  background: '#EA5F2D',
                }}
              >
                Add another wallet
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {showSection.wallet ? (
              <WalletSection
                shopWallets={shopData?.shopWallets}
                selectedReasonForWalletOrBank={
                  shopData?.selectedReasonForWalletOrBank
                }
                reasonDetailsOfWalletOrBank={
                  shopData?.reasonDetailsOfWalletOrBank
                }
                mfiList={mfiList}
                showModal={walletModal}
                toggleModal={(val) => setWalletModal(val)}
                updateData={(data) => setShopData({ ...shopData, ...data })}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className='mt-4 mb-3'>
          <Col>
            <h5
              style={{
                color: '#EA5F2D',
                fontWeight: 'bold',
              }}
            >
              Distributor Profile{' '}
              <span
                className='ms-4 mouse-pointer'
                onClick={() => toggleSection('distributorProfile')}
              >
                {showSection.distributorProfile ? '-' : '+'}
              </span>
            </h5>
          </Col>
          <Col md={3} lg={2}>
            {showSection.distributorProfile ? (
              <Button
                variant='primary'
                onClick={() => setDistributorModal(true)}
                className='btn btn-primary btn-sm ms-3'
                style={{
                  background: '#EA5F2D',
                }}
              >
                Add Another Distributor Shop
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {showSection.distributorProfile ? (
              <DistributorSection
                shopDistributors={shopData?.shopDistributors}
                distributorList={distributorList}
                showModal={distributorModal}
                toggleModal={(val) => setDistributorModal(val)}
                updateData={(data) => setShopData({ ...shopData, ...data })}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className='mt-4 '>
          <Col>
            <h5
              style={{
                color: '#EA5F2D',
                fontWeight: 'bold',
              }}
            >
              Agents{' '}
              <span
                className='ms-4 mouse-pointer'
                onClick={() => toggleSection('agents')}
              >
                {showSection.agents ? '-' : '+'}
              </span>
            </h5>
            {showSection.agents ? (
              <AgentSection
                shopAgents={shopData?.shopAgents}
                updateData={(data) => setShopData({ ...shopData, ...data })}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col>
            <Button
              variant='primary'
              onClick={openRsoModal}
              className='btn btn-primary btn-sm'
              style={{
                background: '#EA5F2D',
              }}
            >
              Re-Allocate to RSO
            </Button>
          </Col>
          <Col className='text-end'>
            <Button
              variant=''
              onClick={NavigateToVizShopsList}
              className='btn btn-primary btn-sm'
              style={{
                color: '#EA5F2D',
                background: 'white',
              }}
            >
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={saveShopData}
              className='btn btn-primary btn-sm ms-3'
              style={{
                background: '#EA5F2D',
              }}
            >
              Save
            </Button>
          </Col>
        </Row>

        <Modal
          show={rsoModal}
          onHide={() => setRsoModal(false)}
          backdrop='static'
          keyboard={false}
          size='md'
        >
          <Formik>
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    Assign Rso
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col md={6}>
                        <div className='form-group '>
                          <ReactSelect
                            id='rsoId'
                            name='rsoId'
                            className='basic-single'
                            classNamePrefix='select'
                            placeholder='Select Rso'
                            isMulti={false}
                            value={selectedRso}
                            isClearable={false}
                            onChange={(e) => {
                              setSelectedRso(e)
                            }}
                            options={rsoList}
                          ></ReactSelect>
                        </div>
                      </Col>
                      <Col md={6}>
                        <Button
                          variant='primary'
                          onClick={setNewRso}
                          className='btn btn-primary btn-sm'
                          style={{
                            background: '#EA5F2D',
                          }}
                        >
                          Assign To Rso
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <>
                    <Button
                      className='btn btn-default'
                      onClick={() => setRsoModal(false)}
                    >
                      Close
                    </Button>
                  </>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
  }
}
export default connect(mapStateToProps, { getDropdownList })(EditShop)

const ProfileSection = ({
  cnic,
  vizShopName,
  shopCategory,
  shopCategories,
  ownerName,
  isShopOwnerHavePhoneNo,
  contactNumbers,
  ntn,
  saleTaxNo,
  updateData,
}) => {
  const [contactModal, setContactModal] = useState(false)
  const [contact, setContact] = useState('')
  const [selectedContact, setSelectedContact] = useState('')

  const openModal = (isNew, contact = '') => {
    if (isNew) {
      setSelectedContact('')
      setContact('')
    }
    if (!isNew) {
      setSelectedContact(contact)
      setContact(contact)
    }

    setContactModal(true)
  }

  const saveContact = () => {
    if (contact == '') {
      toast.error('Contact field is empty')
      return
    }

    if (contactNumbers.includes(contact)) {
      toast.error('Contact already exists')
      return
    }

    if (selectedContact == '') {
      let newContact =
        contactNumbers.length > 0 ? contactNumbers + ',' + contact : contact
      updateData({ contactNumbers: newContact })
    } else {
      let editedContact = contactNumbers.replace(selectedContact, contact)
      updateData({ contactNumbers: editedContact })
    }
    setContactModal(false)
  }

  const deleteContact = (number) => {
    let allContacts = contactNumbers
      .split(',')
      .filter((x) => x != number)
      .join(',')
    updateData({ contactNumbers: allContacts })
  }

  const Delete = (number) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    deleteContact(number)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  return (
    <>
      <Row>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>CNIC</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <Input
                  value={cnic}
                  onChange={(e) => updateData({ cnic: e.target.value })}
                  type='text'
                  placeholder='Enter Cnic'
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Shop Name</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <Input
                  value={vizShopName}
                  onChange={(e) => updateData({ vizShopName: e.target.value })}
                  type='text'
                  placeholder='Enter Shop Name'
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Shop Category</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <ReactSelect
                  id='shopCategory'
                  name='shopCategory'
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Select Category'
                  isMulti={false}
                  value={{
                    label: shopCategory,
                    value: shopCategory,
                  }}
                  isClearable={false}
                  onChange={(e) => {
                    updateData({ shopCategory: e.value })
                  }}
                  options={shopCategories}
                ></ReactSelect>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Shop Owner Name</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <Input
                  value={ownerName}
                  onChange={(e) => updateData({ ownerName: e.target.value })}
                  type='text'
                  placeholder='Enter Owner Name'
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>NTN No.</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <Input
                  value={ntn}
                  onChange={(e) => updateData({ ntn: e.target.value })}
                  type='text'
                  placeholder='Enter NTN'
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Sale Tax No.</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <Input
                  value={saleTaxNo}
                  onChange={(e) => updateData({ saleTaxNo: e.target.value })}
                  type='text'
                  placeholder='Enter Sale Tax No'
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col></Col>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Smart Phone</p>
            </Col>
            <Col md={6}>
              <div className='d-flex align-items-center'>
                <input
                  checked={isShopOwnerHavePhoneNo}
                  value={isShopOwnerHavePhoneNo}
                  type='checkbox'
                  onChange={(event) =>
                    updateData({
                      isShopOwnerHavePhoneNo: true,
                    })
                  }
                  style={{ transform: 'scale(1.3)' }}
                />
                <span className='ms-2'>Yes</span>

                <input
                  checked={!isShopOwnerHavePhoneNo}
                  value={isShopOwnerHavePhoneNo}
                  type='checkbox'
                  onChange={(event) =>
                    updateData({
                      isShopOwnerHavePhoneNo: false,
                    })
                  }
                  style={{ transform: 'scale(1.3)' }}
                  className='ms-4'
                />
                <span className='ms-2'>No</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col md={4}>
          <h6
            style={{
              color: '#EA5F2D',
              fontWeight: 'bold',
            }}
          >
            Contact No
          </h6>
        </Col>
        <Col>
          <Button
            variant='primary'
            onClick={() => openModal(true, '')}
            className='btn btn-primary btn-sm'
            style={{
              background: '#EA5F2D',
            }}
          >
            Add More
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {contactNumbers?.length > 0 && (
            <table className=' contact-table-sm' style={{ width: '60%' }}>
              <thead></thead>
              <tbody>
                {contactNumbers?.split(',').map((value, index) => (
                  <tr>
                    <td colSpan={3}>{index + 1}</td>
                    <td colSpan={3}>{value}</td>

                    <td colSpan={3}>
                      <div>
                        <button
                          title='Edit Contact'
                          onClick={() => openModal(false, value)}
                          className=' btn btn-sm'
                        >
                          <i className='fas fa-edit'></i>
                        </button>
                        &nbsp;
                        <button
                          onClick={() => {
                            Delete(value)
                          }}
                          className='btn btn-sm'
                        >
                          <i className='fas fa-trash'></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>

      <Modal
        show={contactModal}
        onHide={() => setContactModal(false)}
        backdrop='static'
        keyboard={false}
        size='md'
      >
        <Formik>
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Contact Number
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col md={6}>
                      <div className='form-group '>
                        <Input
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          type='text'
                          placeholder='Enter contact number'
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <Button
                        variant='primary'
                        onClick={saveContact}
                        className='btn btn-primary btn-sm'
                        style={{
                          background: '#EA5F2D',
                        }}
                      >
                        Add Contact Number
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <>
                  <Button
                    className='btn btn-default'
                    onClick={() => setContactModal(false)}
                  >
                    Close
                  </Button>
                </>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const AddressSection = ({
  address,
  locality,
  area,
  lat,
  long,
  mapAddress,
  subLocalities,
  updateData,
}) => {
  return (
    <>
      <Row>
        <Col md={2} className='d-flex align-items-center'>
          <p>Shop Address</p>
        </Col>
        <Col md={8}>
          <div className='form-group '>
            <Input
              value={address}
              onChange={(e) =>
                updateData({
                  address: e.target.value,
                })
              }
              type='text'
              placeholder='Enter address'
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Locality</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <ReactSelect
                  id='area'
                  name='area'
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Select sub-locality'
                  isMulti={false}
                  value={{
                    label: area,
                    value: area,
                  }}
                  isClearable={false}
                  onChange={(e) => {
                    updateData({
                      area: e.value,
                    })
                  }}
                  options={subLocalities}
                ></ReactSelect>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className=''>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>sub-locality</p>
            </Col>
            <Col md={6}>
              <div className='form-group '>
                <Input
                  value={locality}
                  onChange={(e) =>
                    updateData({
                      locality: e.target.value,
                    })
                  }
                  type='text'
                  placeholder='Enter locality'
                  width={'100%'}
                />
              </div>
            </Col>
          </Row>
        </Col>

      </Row>
      <Row>
        <Col>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Lat</p>
            </Col>
            <Col md={6}>
              {' '}
              <div className='form-group '>
                <Input
                  value={lat}
                  onChange={(e) =>
                    updateData({
                      lat: e.target.value,
                    })
                  }
                  type='text'
                  placeholder='Enter lat'
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md={4} className='d-flex align-items-center'>
              <p>Long</p>
            </Col>
            <Col md={6}>
              {' '}
              <div className='form-group '>
                <Input
                  value={long}
                  onChange={(e) =>
                    updateData({
                      long: e.target.value,
                    })
                  }
                  type='text'
                  placeholder='Enter long'
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col md={2} className='d-flex align-items-center'>
          <p>Map Address</p>
        </Col>
        <Col md={8}>
          <div className='form-group '>
            <Input
              value={mapAddress}
              onChange={(e) => updateData({ mapAddress: e.target.value })}
              type='text'
              placeholder='Enter map address'
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

const WalletSection = ({
  shopWallets,
  selectedReasonForWalletOrBank,
  reasonDetailsOfWalletOrBank,
  mfiList,

  updateData,
  showModal,
  toggleModal,
}) => {
  const walletInitialValues = {
    id: null,
    isL2: false,
    mfiId: '',
    mfiName: '',
    walletNumber: '',
    requiredLimit: '',
  }

  const [walletDetails, setWalletDetails] = useState(walletInitialValues)
  const [walletLimits, setWalletLimits] = useState([])
  const [currentIndex, setCurrentIndex] = useState(null)

  const columns = [
    {
      Header: 'MFI',
      accessor: 'mfiName',
    },
    {
      Header: 'Wallet Number',
      accessor: 'walletNumber',
    },
    {
      Header: 'Required Wallet',
      accessor: 'isL2',
      Cell: (row) => <div>{row.row.original.isL2 ? 'L2' : 'L1'}</div>,
    },
    {
      Header: 'Required Limit',
      accessor: 'requiredLimit',
    },

    {
      Header: 'Decision',
      accessor: 'id',
      Cell: ({ row }) => (
        <div>
          <button
            title='View User Details'
            onClick={() => openModalForEdit(row.index)}
            className=' btn btn-sm'
          >
            <i className='fas fa-edit'></i>
          </button>
          &nbsp;
          <button onClick={() => Delete(row.original)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  const getWalletLimits = (mfiId) => {
    axios
      .get(`api/Mfi/GetMfiRequiredLimitsDDL/${mfiId}`)
      .then(({ data }) => {
        setWalletLimits(
          data?.response?.map(function (item) {
            return {
              value: item.name,
              label: item.name,
            }
          })
        )
      })
      .catch(function (error) { })
  }

  const clearAndCloseModal = () => {
    setWalletDetails(walletInitialValues)
    toggleModal(false)
    setCurrentIndex(null)
  }

  const openModalForEdit = (index) => {
    setCurrentIndex(index)
    let wallet = shopWallets[index]
    setWalletDetails({
      id: wallet.id,
      isL2: wallet.isL2,
      mfiId: wallet.mfiId,
      mfiName: wallet.mfiName,
      walletNumber: wallet.walletNumber,
      requiredLimit: wallet.requiredLimit,
    })
    toggleModal(true)
    getWalletLimits(wallet.mfiId)
  }

  const saveAndCloseModal = () => {
    if (walletDetails.mfiId == '' || walletDetails.walletNumber == '') {
      toast.error('Please provide complete wallet details')
      return
    }

    if (checkDuplicateWallet(walletDetails) == true) {
      toast.error('Wallet already exists')
      return
    }

    if (currentIndex == null) {
      let wallets = [...shopWallets, walletDetails]
      updateData({ shopWallets: wallets })
    } else {
      let wallets = [...shopWallets]
      wallets[currentIndex] = walletDetails
      updateData({ shopWallets: wallets })
    }
    toggleModal(false)
    setWalletDetails(walletInitialValues)
    setCurrentIndex(null)
  }

  const deleteWallet = (wallet) => {
    shopWallets.splice(shopWallets.indexOf(wallet), 1)
    updateData({ shopWallets: shopWallets })
  }

  const checkDuplicateWallet = (wallet) => {
    let exists =
      currentIndex == null
        ? shopWallets.find(
          (x) =>
            x.mfiId == wallet.mfiId && x.walletNumber == wallet.walletNumber
        )
        : shopWallets.find(
          (x) =>
            x.mfiId == wallet.mfiId &&
            x.walletNumber == wallet.walletNumber &&
            x.requiredLimit == wallet.requiredLimit &&
            x.isL2 == wallet.isL2
        )

    if (exists != null) return true

    return false
  }

  const Delete = (wallet) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    deleteWallet(wallet)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  return (
    <>
      <CustomDataTable
        data={shopWallets ?? []}
        columns={columns}
        getTrProps={() => null}
        IsPagingEnable={true}
        IsSearchEnable={false}
      />
      <Row className='mt-2'>
        <Col>
          <h6
            style={{
              color: '#EA5F2D',
              fontWeight: 'bold',
            }}
          >
            Reasons For Not Using Wallet
          </h6>
          <div className='ms-2 my-2 '>
            <input
              checked={
                selectedReasonForWalletOrBank ==
                'Not suitable for digital payments'
              }
              value={selectedReasonForWalletOrBank}
              type='checkbox'
              onChange={(event) =>
                updateData({
                  selectedReasonForWalletOrBank:
                    'Not suitable for digital payments',
                })
              }
              style={{ transform: 'scale(1.3)' }}
            />
            <span className='ms-2'>Not suitable for digital payments</span>
          </div>
          <div className='ms-2 my-2 '>
            <input
              checked={
                selectedReasonForWalletOrBank == 'Not willing to share details'
              }
              value={selectedReasonForWalletOrBank}
              type='checkbox'
              onChange={(event) =>
                updateData({
                  selectedReasonForWalletOrBank: 'Not willing to share details',
                })
              }
              style={{ transform: 'scale(1.3)' }}
            />
            <span className='ms-2'>Not willing to share details</span>
          </div>
          <div className='ms-2 my-2 '>
            <input
              checked={
                selectedReasonForWalletOrBank == 'Not willing to register'
              }
              value={selectedReasonForWalletOrBank}
              type='checkbox'
              onChange={(event) =>
                updateData({
                  selectedReasonForWalletOrBank: 'Not willing to register',
                })
              }
              style={{ transform: 'scale(1.3)' }}
            />
            <span className='ms-2'>Not willing to register</span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4} className='d-flex align-items-center'>
          <p>Other Reason</p>
        </Col>
        <Col md={6}>
          <div className='form-group '>
            <Input
              value={reasonDetailsOfWalletOrBank}
              onChange={(e) =>
                updateData({ reasonDetailsOfWalletOrBank: e.target.value })
              }
              type='text'
              placeholder='Enter reason'
            />
          </div>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={clearAndCloseModal}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Formik>
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Add Wallet
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col md={6}>
                      <p>MFI</p>
                      <div className='form-group '>
                        <ReactSelect
                          id='mfiId'
                          name='mfiId'
                          className='basic-single'
                          classNamePrefix='select'
                          placeholder='Select Mfi'
                          isMulti={false}
                          value={{
                            value: walletDetails.mfiId,
                            label: walletDetails.mfiName,
                          }}
                          isClearable={false}
                          onChange={(e) => (
                            setWalletDetails({
                              ...walletDetails,
                              mfiId: e.value,
                              mfiName: e.label,
                            }),
                            getWalletLimits(e.value)
                          )}
                          options={mfiList}
                        ></ReactSelect>
                      </div>
                    </Col>
                    <Col md={6}>
                      <p>Wallet</p>
                      <Input
                        value={walletDetails.walletNumber}
                        onChange={(e) =>
                          setWalletDetails({
                            ...walletDetails,
                            walletNumber: e.target.value,
                          })
                        }
                        type='text'
                        placeholder='Enter wallet number'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p>Required Limit(Optional)</p>
                      <div className='form-group '>
                        <ReactSelect
                          id='requiredLimit'
                          name='requiredLimit'
                          className='basic-single'
                          classNamePrefix='select'
                          placeholder='Select Wallet Limit'
                          isMulti={false}
                          value={{
                            value: walletDetails.requiredLimit,
                            label: walletDetails.requiredLimit,
                          }}
                          isClearable={false}
                          onChange={(e) => {
                            setWalletDetails({
                              ...walletDetails,
                              requiredLimit: e.value,
                            })
                          }}
                          options={walletLimits}
                        ></ReactSelect>
                      </div>
                    </Col>
                    <Col md={6}>
                      <p>Required L2</p>
                      <div className='d-flex '>
                        <input
                          checked={walletDetails?.isL2}
                          value={walletDetails?.isL2}
                          type='checkbox'
                          onChange={(event) =>
                            setWalletDetails({
                              ...walletDetails,
                              isL2: true,
                            })
                          }
                          style={{ transform: 'scale(1.3)' }}
                        />
                        <span className='ms-2'>Yes</span>

                        <input
                          checked={!walletDetails?.isL2}
                          value={walletDetails?.isL2}
                          type='checkbox'
                          onChange={(event) =>
                            setWalletDetails({
                              ...walletDetails,
                              isL2: false,
                            })
                          }
                          style={{ transform: 'scale(1.3)' }}
                          className='ms-4'
                        />
                        <span className='ms-2'>No</span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <>
                  <Button
                    className='btn btn-default'
                    onClick={clearAndCloseModal}
                  >
                    Close
                  </Button>

                  <Button
                    variant='primary'
                    onClick={saveAndCloseModal}
                    className='btn btn-primary btn-sm ms-2'
                    style={{
                      background: '#EA5F2D',
                    }}
                  >
                    Save
                  </Button>
                </>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const DistributorSection = ({
  shopDistributors,
  distributorList,
  showModal,
  toggleModal,
  updateData,
}) => {
  const filterInitialValues = {
    distributorId: '',
    distributorName: '',
    centerId: '',
    centerName: '',
    shopCode: '',
  }

  const [filterValues, setFilterValues] = useState(filterInitialValues)
  const [centerList, setCenterList] = useState([])
  const [distShopList, setDistShopList] = useState([])
  const [paginatedList, setPaginatedList] = useState([])

  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const selectRecord = (row) => {
    let list = [...paginatedList]
    list[row.row.id].isSelected = !list[row.row.id].isSelected

    setPaginatedList(list)

    // let list = [...paginatedList]
    // list[row.row.id].isSelected = !list[row.row.id].isSelected

    // setDistShopList(list)
  }

  const columns = [
    {
      Header: 'Select',
      accessor: 'id',
      Cell: (row) => (
        <div className='ms-2 my-2 ' key={1}>
          <input
            checked={row.row.original.isSelected}
            value={row.row.original.isSelected}
            type='checkbox'
            onChange={() => selectRecord(row)}
            style={{ transform: 'scale(1.3)' }}
          />
          <span className='ms-2'></span>
        </div>
      ),
    },
    {
      Header: 'Distributors Name',
      accessor: 'distributorName',
    },
    {
      Header: 'Distribution ID',
      accessor: 'centerName',
    },
    {
      Header: 'Shop Code',
      accessor: 'shopCode',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Average Sales',
      accessor: 'averageSales',
    },
    {
      Header: 'Payment Mode',
      accessor: 'paymentMode',
    },
  ]

  const addedColumns = [
    {
      Header: 'Distributors Name',
      accessor: 'distributorName',
    },
    {
      Header: 'Distribution ID',
      accessor: 'centerName',
    },
    {
      Header: 'Shop Code',
      accessor: 'shopCode',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Average Sales',
      accessor: 'averageSales',
    },
    {
      Header: 'Payment Mode',
      accessor: 'paymentMode',
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button
            onClick={() => Delete(row.row.original.id)}
            className='btn btn-sm'
          >
            <i className='fas fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  const clearData = () => {
    setCenterList([])
    setDistShopList([])
    setPaginatedList([])
    setCurrentPageNo(0)
    setCurrentPageSize(5)
    setSearchText('')
  }

  const clearAndCloseModal = () => {
    setFilterValues(filterInitialValues)
    toggleModal(false)
    clearData()
  }

  const saveAndCloseModal = () => {
    let allDists = [
      ...shopDistributors,
      ...distShopList.filter((x) => x.isSelected == true),
    ]
    updateData({ shopDistributors: allDists })

    setFilterValues(filterInitialValues)
    toggleModal(false)
    clearData()
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    setPaginatedList(distShopList.slice(0, pageSize))
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)

    setPaginatedList(distShopList.filter((x) => x.shopCode.includes(text)))
  }

  const getData = (page) => {
    setCurrentPageNo(page)

    let index = page * currentPageSize
    setPaginatedList(distShopList.slice(index, index + currentPageSize))
  }

  const getCenterList = (id) => {
    axios
      .get(`api/DistCenter/GetDistCentersDDL/${id}`)
      .then(({ data }) => {
        setCenterList(data?.response)
      })
      .catch(function (error) { })
  }

  const getDistShopList = (id) => {
    axios
      .get(`api/VizShop/GetDistributorCenterShops/${id}`)
      .then(({ data }) => {
        setDistShopList(data)
        setPaginatedList(data.slice(0, currentPageSize))
      })
      .catch(function (error) { })
  }

  const deleteDistributor = (id) => {
    updateData({ shopDistributors: shopDistributors.filter((x) => x.id != id) })

    if (shopDistributors.length == 1) {
      toast.error(
        'One distributor is compulsory to add otherwise this shop record will be inactive'
      )
    }
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    deleteDistributor(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }
  return (
    <>
      <CustomDataTable
        data={shopDistributors ?? []}
        columns={addedColumns}
        getTrProps={() => null}
        IsPagingEnable={true}
        IsSearchEnable={false}
      />

      <Modal
        show={showModal}
        onHide={clearAndCloseModal}
        backdrop='static'
        keyboard={false}
        size='xl'
      >
        <Formik>
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col md={6}>
                      <Row className='d-flex align-items-center'>
                        <Col md={4}>Select Distributor</Col>
                        <Col md={8}>
                          <div className='form-group m-0'>
                            <ReactSelect
                              id='distributorId'
                              name='distributorId'
                              className='basic-single'
                              classNamePrefix='select'
                              placeholder='Select Distributor'
                              isMulti={false}
                              value={{
                                value: filterValues.distributorId,
                                label: filterValues.distributorName,
                              }}
                              isClearable={false}
                              onChange={(e) =>
                                setFilterValues(
                                  {
                                    ...filterValues,
                                    distributorId: e.value,
                                    distributorName: e.label,
                                    centerId: '',
                                    centerName: '',
                                  },
                                  getCenterList(e.value)
                                )
                              }
                              options={distributorList}
                            ></ReactSelect>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className='d-flex align-items-center'>
                        <Col md={4}>Select Distribution</Col>
                        <Col md={8}>
                          <div className='form-group m-0'>
                            <ReactSelect
                              id='centerId'
                              name='centerId'
                              className='basic-single'
                              classNamePrefix='select'
                              placeholder='Select Distribution'
                              isMulti={false}
                              value={{
                                value: filterValues.centerId,
                                label: filterValues.centerName,
                              }}
                              isClearable={false}
                              onChange={(e) =>
                                setFilterValues(
                                  {
                                    ...filterValues,
                                    centerId: e.value,
                                    centerName: e.label,
                                  },
                                  getDistShopList(e.value)
                                )
                              }
                              options={centerList}
                            ></ReactSelect>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='mt-4'>
                    <Col>
                      {/* <CustomDataTable
                        searchLabel='Search Shop Code'
                        data={distShopList}
                        columns={columns}
                        getTrProps={() => null}
                        IsPagingEnable={false}
                      /> */}
                      <ServerSideDataTable
                        searchLabel='Search Shop Code'
                        data={paginatedList ? paginatedList : []}
                        columns={columns}
                        getTrProps={() => null}
                        goToPreviousPage={(val) => getData(val)}
                        goToNextPage={(val) => getData(val)}
                        totalRecords={distShopList.length}
                        pageSizeChange={(size) => pageSizeChanged(size)}
                        myPageSize={currentPageSize}
                        pageNo={currentPageNo}
                        searchChanged={searchChanged}
                      />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <>
                  <Button
                    className='btn btn-default'
                    onClick={clearAndCloseModal}
                  >
                    Close
                  </Button>

                  <Button
                    variant='primary'
                    onClick={saveAndCloseModal}
                    className='btn btn-primary btn-sm ms-2'
                    style={{
                      background: '#EA5F2D',
                    }}
                  >
                    Save
                  </Button>
                </>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const AgentSection = ({ shopAgents, updateData }) => {
  const unTagAgent = (id) => {
    let newAgentList = shopAgents.filter((x) => x.id != id)
    updateData({ shopAgents: newAgentList })
  }

  const columns = [
    {
      Header: 'Agent Code',
      accessor: 'vizAgentCode',
    },
    {
      Header: 'Business Name',
      accessor: 'agentBusinessName',
    },
    {
      Header: 'Owner Name',
      accessor: 'agentOwnerName',
    },
    {
      Header: 'Owner Cnic',
      accessor: 'agentOwnerNic',
    },
    {
      Header: 'Agent Type',
      accessor: 'agentType',
    },
    {
      Header: 'Contact',
      accessor: 'contactNumber',
    },

    {
      Header: 'Decision',
      accessor: 'id',
      Cell: (row) => (
        <div
          i
          style={{ color: '#ea5f2d', cursor: 'pointer' }}
          onClick={() => unTagAgent(row.row.original.id)}
        >
          Untag
        </div>
      ),
    },
  ]
  return (
    <>
      <CustomDataTable
        data={shopAgents ?? []}
        columns={columns}
        getTrProps={() => null}
        IsPagingEnable={true}
        IsSearchEnable={false}
      />
    </>
  )
}
