import { useEffect, useState, useRef } from 'react'
import { Col, Row, Overlay, Button, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import FormikControl from '../../../layouts/Input/FormikControl'
import storage from '../../../apis/Storage'
import Moment from 'react-moment'
import { GET_DISTRIBUTOR_DDL, GET_ADDJOB_DISTRIBUTIONCENTER_DDL } from '../../../Types'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { Link } from 'react-router-dom'
import { isPermittedPage } from '../../../apis/Utility'
import { getShopsDetailReport, exportReport } from './ShopsDetailReportActions'


const ShopsDetailReport = (props) => {
    const userRights = JSON.parse(storage.get('_userRights'));
    const [target, setTarget] = useState(null);
    const [show, setShow] = useState(false);
    const ref = useRef(null);
    // pagination states
    const [currentIndex, setCurrentIndex] = useState();
    const [currentPageNo, setCurrentPageNo] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [distributorList, setDistributorList] = useState([]);
    const [distCenterList, setdistCenterList] = useState([]);
    const [isApplyFilter, setIsApplyFilter] = useState(false);

    const statusColumns = [
        {
            label: 'Yes',
            value: 'yes',
        },
        {
            label: 'No',
            value: 'no',
        },
    ]

    const [filter, setfilter] = useState({
        distributor: null,
        distCenter: null,
        vizId: '',
        shopCode: '',
        locality: '',
        registrationStatus: '',
        pageNo: 0,
        searchText: null,
        isExport: false,
    })

    const openFilterModal = (event) => {
        setShow(!show)
        setTarget(event.target)
    }

    function commonReset(reset = true) {
        setSearchText('');

        if (reset) {
            setfilter({
                distributor:
                    userRights != null && userRights?.userType == 'DistributorUser'
                        ? filter.distributor
                        : null,
                distCenter: null,
                vizId: '',
                shopCode: '',
                locality: '',
                registrationStatus: null,
                pageNo: 0,
                searchText: null,
                isExport: false,
            })
        }
    }

    function formRequestObject(isExport = false) {
        let requestData = {
            distributor: filter.distributor?.value,
            distCenter: filter.distCenter?.value,
            vizId: filter.vizId,
            shopCode: filter.shopCode,
            locality: filter.locality,
            registrationStatus: filter.registrationStatus?.value,
            pageSize: currentPageSize,
            pageNo: currentPageNo,
            searchText: searchText,
            isExport: isExport,
        }
        if (!requestData.distributor && userRights != null && userRights?.userType == 'DistributorUser') {
            requestData = {
                ...requestData,
                distributor: userRights?.distributorId,
            }
        }
        return requestData
    }

    function fetchReportData(request) {
        let finalRequest = request == null ? formRequestObject() : request
        props.getShopsDetailReport(finalRequest)
    }

    const handleResetFilters = (resetForm) => {
        resetForm()
        commonReset()
    }

    const getData = (page) => {
        setCurrentPageNo(page)
        var request = { ...formRequestObject(), pageNo: page }
        fetchReportData(request)
    }

    const pageSizeChanged = (pageSize) => {
        setCurrentPageNo(0)
        setCurrentPageSize(pageSize)
        var request = { ...formRequestObject(), pageSize: pageSize }
        fetchReportData(request)
    }

    const searchChanged = (text) => {
        setSearchText(text)
    }

    const LoadDataByfilter = (event) => {
        setCurrentPageNo(0)
        var request = { ...formRequestObject(), pageNo: 0 }
        fetchReportData(request)
        setShow(false)
        setIsApplyFilter(true)
    }

    const Download = () => {
        let request = formRequestObject(true)
        props.exportReport(request)
    }

    useEffect(() => {
        setCurrentPageNo(0)
        fetchReportData()
    }, [searchText])

    useEffect(() => {
        if (props.report && props.report.totalRecords > 0) {
            setTotalRecords(props.report.totalRecords)
        } else {
            setTotalRecords(0)
        }
    }, [props.report])

    useEffect(() => {
        props.getDropdownList(
            `api/Distributor/GetDistributorsDDL`,
            GET_DISTRIBUTOR_DDL
        )
    }, [])

    useEffect(() => {
        setDistributorList([]);
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            let distributorList = props.allDistributorList.filter(
                (x) => x.value == userRights?.distributorId
            )
            if (distributorList?.length > 0) {
                setfilter({
                    ...filter,
                    distributor: {
                        label: distributorList[0]?.label,
                        value: distributorList[0]?.value,
                    },
                })
            }
            setDistributorList(
                distributorList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        } else {
            setDistributorList(
                props.allDistributorList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        }
    }, [props.allDistributorList])

    useEffect(() => {
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            let distCenterList = props.selectedAddJobDistCentersList.filter((x) =>
                userRights?.distCenterIds.includes(x.value)
            )
            setdistCenterList(
                distCenterList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        } else {
            setdistCenterList(
                props.selectedAddJobDistCentersList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        }
    }, [props.selectedAddJobDistCentersList])

    useEffect(() => {
        if (filter.distributor) {
            props.getDropdownList(
                `api/DistCenter/GetDistCentersDDL/${filter.distributor.value}`,
                GET_ADDJOB_DISTRIBUTIONCENTER_DDL
            )
        }
        filter.distCenter = null
    }, [filter.distributor])

    const columns = [
        {
            Header: 'Distribution Center',
            accessor: 'distCenter',
        },
        {
            Header: 'Viz ID',
            accessor: 'vizId',
        },
        {
            Header: 'Shop Code',
            accessor: 'shopCode',
        },
        {
            Header: 'ShopName',
            accessor: 'shopName',
        },
        {
            Header: 'CNIC',
            accessor: 'cnic',
        },
        {
            Header: 'Locality',
            accessor: 'locality',
        },
        {
            Header: 'Date Of Induction',
            accessor: 'dateOfInduction',
            Cell: (row) => (
                <div style={{ width: '75px' }}>
                    <Moment format='DD/MM/YYYY'>{row.value}</Moment>
                </div>
            ),
        },
        {
            Header: 'Registration Status',
            accessor: 'registrationStatus',
        },
    ]

    return (
        <div>
            <VzPageContainer title='Shops Detail Report'>
                <Row>
                    <Col style={{ float: 'left' }}>
                        <h4>
                            <b>Shops Detail Report</b>
                        </h4>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col md={10} style={{ float: 'right' }}>
                        <div ref={ref}>
                            <Button
                                onClick={openFilterModal}
                                className='m-3'
                                variant='outline-primary'
                            >
                                <i className='fa fa-filter' />
                                Filters
                            </Button>

                            <Overlay
                                show={show}
                                target={target}
                                placement='bottom'
                                container={ref}
                                containerPadding={20}
                                width='430px'
                                style={{ width: '430px' }}
                            >
                                <Popover
                                    id='popover-contained'
                                    style={{ maxWidth: '360px', width: '360px' }}
                                >
                                    <Popover.Header as='h3'>Filters</Popover.Header>
                                    <Popover.Body>
                                        <Formik
                                            enableReinitialize
                                            initialValues={filter}
                                            validator={() => ({})}
                                            onSubmit={LoadDataByfilter}
                                        >
                                            {(formik) => (
                                                <Form autoComplete='off'>
                                                    <Row>
                                                        {userRights == null && (
                                                            <Col xs={12} md={12}>
                                                                <FormikControl
                                                                    formik={formik}
                                                                    control='react-select'
                                                                    label='Distributor Name'
                                                                    name='distributor'
                                                                    options={distributorList}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    handleOnChange={(e) => {
                                                                        if (!e || !e.value) {
                                                                            setfilter({
                                                                                ...filter,
                                                                                distributor: null,
                                                                            })
                                                                            return
                                                                        }
                                                                        setfilter({
                                                                            ...filter,
                                                                            distributor: {
                                                                                label: e.label,
                                                                                value: e.value,
                                                                            },
                                                                        })
                                                                    }}
                                                                    value={filter.distributor}
                                                                />
                                                            </Col>
                                                        )}

                                                        <Col xs={12} md={12}>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='react-select'
                                                                label='Distribution Center'
                                                                name='distCenter'
                                                                options={filter.distributor != null ? distCenterList : []}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                handleOnChange={(e) => {
                                                                    if (!e || !e.value) {
                                                                        setfilter({
                                                                            ...filter,
                                                                            distCenter: null,
                                                                        })
                                                                        return
                                                                    }
                                                                    setfilter({
                                                                        ...filter,
                                                                        distCenter: {
                                                                            label: e.label,
                                                                            value: e.value,
                                                                        },
                                                                    })
                                                                }}
                                                                value={filter.distCenter}
                                                            />
                                                            <FormikControl
                                                                formik={formik}
                                                                control='input'
                                                                type='vizId'
                                                                label='Viz Id'
                                                                name='vizId'
                                                                handleOnChange={(value) => {
                                                                    filter.vizId = value
                                                                }}
                                                                value={filter.vizId}
                                                            />
                                                            <FormikControl
                                                                formik={formik}
                                                                control='input'
                                                                type='shopCode'
                                                                label='Shop Code'
                                                                name='shopCode'
                                                                handleOnChange={(value) => {
                                                                    filter.shopCode = value
                                                                }}
                                                                value={filter.shopCode}
                                                            />
                                                            <FormikControl
                                                                formik={formik}
                                                                control='input'
                                                                type='locality'
                                                                label='Locality'
                                                                name='locality'
                                                                handleOnChange={(value) => {
                                                                    filter.locality = value
                                                                }}
                                                                value={filter.locality}
                                                            />
                                                            <FormikControl
                                                                formik={formik}
                                                                control='react-select'
                                                                label='Registration Status'
                                                                name='registrationStatus'
                                                                options={statusColumns}
                                                                isClearable={true}
                                                                handleOnChange={(option) => {
                                                                    filter.registrationStatus = option
                                                                }}
                                                                value={filter.registrationStatus}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className='row' style={{ marginTop: 20 }}>
                                                        <div className='form-group'>
                                                            <Link
                                                                onClick={handleResetFilters.bind(
                                                                    null,
                                                                    formik.resetForm,
                                                                    setIsApplyFilter(false)
                                                                )}
                                                                to='#'
                                                                className='active'
                                                                style={{
                                                                    padding: '0px',
                                                                    marginTop: '10px',
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                Reset
                                                            </Link>
                                                            <Link
                                                                onClick={() => {
                                                                    setShow(false)
                                                                    setTarget(null)
                                                                    commonReset(isApplyFilter)
                                                                }}
                                                                to='#'
                                                                className='active'
                                                                style={{
                                                                    padding: '0px',
                                                                    marginTop: '10px',
                                                                    float: 'right',
                                                                }}
                                                            >
                                                                Cancel
                                                            </Link>
                                                            <div className='float-right'>
                                                                <Button
                                                                    type='submit'
                                                                    className='btn btn-primary'
                                                                    style={{
                                                                        marginRight: '10px',
                                                                        float: 'right',
                                                                    }}
                                                                >
                                                                    Apply
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Popover.Body>
                                </Popover>
                            </Overlay>
                        </div>
                    </Col>
                    {isPermittedPage('Export', props.actions, props.userRole) &&
                        <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={Download}
                                className='m-3'
                                variant='outline-primary'
                            >
                                <i className='fa fa-download me-1' />
                                Export
                            </Button>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <ServerSideDataTable
                            data={props.report?.data ?? []}
                            columns={columns}
                            getTrProps={(event, data) => null}
                            goToPreviousPage={(val) => getData(val)}
                            goToNextPage={(val) => getData(val)}
                            totalRecords={totalRecords}
                            pageSizeChange={(size) => pageSizeChanged(size)}
                            myPageSize={currentPageSize}
                            myIndex={currentIndex}
                            pageNo={currentPageNo}
                            searchChanged={searchChanged}
                        />
                    </Col>
                </Row>
            </VzPageContainer>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        report: state.ShopsDetailReport,
        allDistributorList: state.ddlList.allDistributorList
            ? state.ddlList.allDistributorList
            : [],
        selectedAddJobDistCentersList: state.ddlList.selectedAddJobDistCentersList
            ? state.ddlList.selectedAddJobDistCentersList
            : [],
    }
}
export default connect(mapStateToProps, {
    getShopsDetailReport,
    getDropdownList,
    exportReport,
})(ShopsDetailReport)
