import React, { useState } from 'react';
import { Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import backgroundImage from '../../assets/Images/Login_background_Image.gif';
import vizLogo from '../../assets/Images/Vizlink_logo.png';

import FormikControl from '../../layouts/Input/FormikControl';

import { signIn } from './AuthAction';
import { requestPasswordRecoveryLink } from './AuthAction';


const Loginpage = props => 
{
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [showModal_ForgotPassword, setShowModal_ForgotPassword] = useState(false);

  const [loginObject, setLoginObject] = useState({ username: '', password: '' });

  const [passwordRecoveryUsername, setPasswordRecoveryUsername] = useState({ username: '' });

  const [shouldRedirect, setShouldRedirect] = useState(null);

  const loginValidationSchema = Yup.object().shape
  ({
    username: Yup.string()
              .min(4, 'Username length must be 04 or more characters')
              .required('Username is required'),
    password: Yup.string()
              .min(7, 'Password length must be 07 or more characters')
              .required('Password is required'),
  });

  const usernameValidationSchema = Yup.object().shape
  ({
    username: Yup.string()
           .min(4, 'Username length must be 04 or more characters')
           .required('Username is required'),
  });
  
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  function LoginUser()
  {
    props.signIn(loginObject.username, loginObject.password);
  }

  async function RequestPasswordRecoveryLink()
  {
    await props.requestPasswordRecoveryLink(passwordRecoveryUsername.username);

    EmptyAndHideForgotPasswordModal();
  }

  function EmptyAndHideForgotPasswordModal()
  {
    setShowModal_ForgotPassword(false);
    setPasswordRecoveryUsername({ username: '' });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return(
    <>
      <div 
        className='login-page'
        style=
        {{
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        {
          showModal_ForgotPassword
          &&
          <Modal
            show={showModal_ForgotPassword}
            centered
            backdrop='static'
            keyboard={false}
            onHide={() => EmptyAndHideForgotPasswordModal(false)}
          >
            <Modal.Header 
              style={{ alignItems: 'center', }} 
              closeButton
            >
              <Modal.Title>
                {'Password Recovery'}
              </Modal.Title>
            </Modal.Header>

            <Formik
              enableReinitialize
              validationSchema={usernameValidationSchema}
              onSubmit={() => RequestPasswordRecoveryLink()}
              initialValues={passwordRecoveryUsername}
            >
              {
                (formik) =>
                (
                  <Form>
                    <Modal.Body>
                      <Container>
                        <Row>
                          <Col>
                            <label>
                              {'A password recovery link will be sent to your registered email. Please provide your username.'}
                            </label>
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col xs={12} md={12}>
                            <div className='form-group'>
                              <FormikControl
                                formik={formik}
                                control='input'
                                type='text'
                                label='Username'
                                name='username'
                                value={passwordRecoveryUsername.username}
                                handleOnChange={text => setPasswordRecoveryUsername({ username: text })}
                              />
                            </div>
                          </Col>
                        </Row>
                      
                      </Container>
                    </Modal.Body>

                    <Modal.Footer style={{ margin: '0px 2% 2% 0px',}}>
                      <input
                        className='btn btn-secondary'
                        style={{ marginRight: '2.5%', }}
                        type='button'
                        value='Cancel'
                        onClick={() => EmptyAndHideForgotPasswordModal(false)}
                      />
                      <input
                        className='btn btn-primary'
                        type='submit'
                        value='Request Password'
                      />
                    </Modal.Footer>
                  </Form>
                )
              }
            </Formik>

          </Modal>
        }

        <div className='login-box elevation-4'>

          <div className='card-header text-center'>
            <Link 
              className='brand-link clearfix'
              to={'/login'} 
            >
              <Image
                src={vizLogo}
                alt={'VizLink App Logo'}
              />
            </Link>
          </div>

          <div className='card-body'>
            <p className='login-box-msg'>
              {'Login to start your session'}
            </p>

            <Formik
              enableReinitialize
              validationSchema={loginValidationSchema}
              onSubmit={() => LoginUser()}
              initialValues={loginObject}
            >
              {
                (formik) =>
                (
                  <Form autoComplete={'off'}>
                    <Modal.Body>
                      <Container>

                        <Row>
                          <Col xs={12} md={12}>
                            <div className='form-group'>
                              <FormikControl
                                formik={formik}
                                control='input'
                                type='text'
                                label='Username'
                                name='username'
                                value={loginObject.username}
                                handleOnChange={text => setLoginObject({ ...loginObject, username: text})}
                              />
                            </div>
                          </Col>

                          <Col xs={12} md={12}>
                            <div className='form-group'>
                              <FormikControl
                                formik={formik}
                                control='input'
                                type='password'
                                label='Password'
                                name='password'
                                value={loginObject.password}
                                handleOnChange={text => setLoginObject({ ...loginObject, password: text})}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} md={12}>
                            <div className='custom-checkbox'>
                              <label 
                                className='custom-control-label' 
                                style={{ width: '50%' }}
                                htmlFor='customCheck1' 
                              >
                                <input
                                  id='customCheck1'
                                  className='custom-control-input-info'
                                  style={{ marginRight: '6%' }}
                                  type='checkbox'
                                />
                                {'Remember Me'}
                              </label>
                            </div>
                          </Col>
                        </Row>
                      
                      </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: 'space-between', marginRight: '2.5%', }}>
                      <p 
                        className='forgot-password' 
                        style={{ padding: '2.5%', }}
                        onClick={() => setShowModal_ForgotPassword(true)}
                      >
                        <a>{'Forgot Password?'}</a>
                      </p>

                      <input
                        className='btn btn-primary'
                        type='submit'
                        value='Login'
                      />
                    </Modal.Footer>
                  </Form>
                )
              }
            </Formik>
          </div>

        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => 
{
  return{
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    token: state.auth.token,
  }
}

export default connect(mapStateToProps, { signIn, requestPasswordRecoveryLink })(Loginpage);
