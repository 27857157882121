import ReactCreatable from 'react-select/creatable'
import { useField, ErrorMessage, getIn } from 'formik'
import TextError from './TextError'

function SelectCreatable(props) {
  const { name, label, value, handleValueChange, formik, disabled, ...rest } =
    props
  const [, , helpers] = useField(name)
  const getStyles = (formik, fieldName) => {
    if (getIn(formik.touched, fieldName) && getIn(formik.errors, fieldName)) {
      return {
        border: '1px solid red',
        borderRadius: '4px',
      }
    } else return {}
  }
  return (
    <>
      <label style={{ marginBottom: 0 }} htmlFor={name}>
        {label}
      </label>
      <div style={formik ? getStyles(formik, name) : {}}>
        <ReactCreatable
          isClearable={false}
          isSearchable={!disabled}
          isMulti={true}
          value={value}
          readOnly={disabled}
          isDisabled={disabled}
          onChange={(value) => {
            handleValueChange(value)
            helpers.setValue(value)
          }}
          // style={formik ? getStyles(formik, name) : {}}
          placeholder={`Enter ${label}`}
          onBlur={() => helpers.setTouched(true)}
          {...rest}
        ></ReactCreatable>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </>
  )
}

export default SelectCreatable
