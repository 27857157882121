import {
  GET_RSO_PERFORMANCE_REPORT,
  RESET_RSO_PERFORMANCE_REPORT,
} from '../../../Types'

const RsoPerformanceReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_RSO_PERFORMANCE_REPORT:
      return [...action.payload]
    case RESET_RSO_PERFORMANCE_REPORT:
      return []

    default:
      return state
  }
}
export default RsoPerformanceReportReducer
