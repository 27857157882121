import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import storage from '../../../apis/Storage'
import { ADD_ORDERS, ADD_Unilever_ORDERS, ADD_Master_ORDERS, GET_ORDERS, DELETE_ORDER } from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const addMasterDataOrder = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  const formData = new FormData()
  formData.append('DistributorId', formValues.distributorId)
  formData.append('DistCenterId', formValues.DistCenterId)
  formData.append('DataFile', formValues.DataFile)
  if (formValues.assignedDistCenters) {
    formData.append('assignedDistCenters', formValues.assignedDistCenters)
  }
  const config = {
    headers: {
      Authorization: `Bearer ${storage.get('_appToken')}`,
      'content-type': 'multipart/form-data',
      accept: 'application/json',
    },
  }
  await axios
    .post('api/ShopOrder/UploadMasterOrderDataSheet', formData, config)
    .then(({ data }) => {
      dispatch({
        type: ADD_Master_ORDERS,
        payload: data.response,
      })
      dispatch(hideLoading())
      toast.success('Orders uploaded successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const addUnileverOrder = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  const formData = new FormData()
  formData.append('DistributorId', formValues.distributorId)
  formData.append('DistCenterId', formValues.DistCenterId)
  formData.append('DataFile', formValues.DataFile)
  formData.append('DataFileChild', formValues.DataFileChild)
  if (formValues.assignedDistCenters) {
    formData.append('assignedDistCenters', formValues.assignedDistCenters)
  }
  const config = {
    headers: {
      Authorization: `Bearer ${storage.get('_appToken')}`,
      'content-type': 'multipart/form-data',
      accept: 'application/json',
    },
  }
  await axios
    .post('api/ShopOrder/UploadUnileverOrderDataSheet', formData, config)
    .then(({ data }) => {
      dispatch({
        type: ADD_Unilever_ORDERS,
        payload: data.response,
      })
      dispatch(hideLoading())
      toast.success('Orders uploaded successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const addOrder = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  const formData = new FormData()
  formData.append('DistributorId', formValues.distributorId)
  formData.append('DistCenterId', formValues.DistCenterId)
  formData.append('DataFile', formValues.DataFile)
  if (formValues.assignedDistCenters) {
    formData.append('assignedDistCenters', formValues.assignedDistCenters)
  }
  const config = {
    headers: {
      Authorization: `Bearer ${storage.get('_appToken')}`,
      'content-type': 'multipart/form-data',
      accept: 'application/json',
    },
  }
  await axios
    .post('api/ShopOrder/UploadNestleOrderDataSheet', formData, config)
    .then(({ data }) => {
      dispatch({
        type: ADD_ORDERS,
        payload: data.response,
      })
      dispatch(hideLoading())
      toast.success('Orders uploaded successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const getOrders = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/ShopOrder/GetShopOrdersList`, postedData)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_ORDERS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const deleteOrder = (id) => async (dispatch) => {
  dispatch(showLoading())
  const config = {
    headers: { Authorization: `Bearer ${storage.get('_appToken')}` },
  }
  await axios
    .delete(`api/Distributor/DeleteUsers/${id}`, config)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({ type: DELETE_ORDER, payload: id })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response)
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
    })
}
