import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import storage from '../../../../apis/Storage'
import {
  GET_MANAGE_ORDER_DETAILS,
  DELETE_MANAGE_ORDER_DETAILS,
} from '../../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const getManageOrderDetails = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/ShopOrder/GetOrderShopsList`, postedData)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_MANAGE_ORDER_DETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const deleteManageOrderDetails = (id) => async (dispatch) => {
   dispatch(showLoading())
  const config = {
    headers: { Authorization: `Bearer ${storage.get('_appToken')}` },
  }
  await axios
    .delete(`api/ShopOrder/${id}`, config)
    .then(({ data }) => {
      dispatch(hideLoading())
      if (data.isSuccess) {
          toast.success(data.message)
          dispatch({ type: DELETE_MANAGE_ORDER_DETAILS, payload: id })
      }
      else {
          toast.error(data.message)
      }
  })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response)
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
    })
}
