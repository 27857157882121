import { Image, NavItem } from 'react-bootstrap'
import { NavLink } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/Images/Vizlink_logo.png'
import { connect } from 'react-redux'
import storage from '../../apis/Storage'
import { useState, useEffect, useRef } from 'react'

const SideNavbar = (props) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const splitLocation = useLocation().pathname.split('/')

  const isPermittedPage = (pageUrl) => {
    if (pageUrl) {
      if (props.userRole == 'Administrator') return true
      if (!props.permittedPages) return false
      const isUserAuthorised = props.permittedPages.find(
        (page) => page.pageUrl === pageUrl
      )
      if (isUserAuthorised == false || typeof isUserAuthorised == 'undefined') {
        return false
      } else {
        return true
      }
    } else return false
  }

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <>
      {
        <div className={props.isNavbarOpen ? 'sidebar-backdrop' : ''}>
          <aside className='main-sidebar sidebar-sticky sidebar-light-primary elevation-4 sidebar '>
            <div className={props.isNavbarOpen ? 'sidebar' : ''}>
              <span className='d-flex align-items-center justify-content-between px-3'>
                <a className=' clearfix' href='/dashboard'>
                  <Image src={logo} alt='Logo' className='brand-image'></Image>
                </a>
                {windowSize.width < 993 && (
                  <button
                    type='button'
                    className='btn-close'
                    aria-label='Close'
                    onClick={props.onClickOutside}
                  // onClick={() => {
                  //   var hamburger = document.getElementById('pushmenu')
                  //   hamburger.click()
                  //   props.onClickOutside()
                  // }}
                  ></button>
                )}
              </span>

              <nav className='mt-2'>
                <ul
                  className='nav nav-pills nav-sidebar flex-column'
                  data-widget='treeview'
                  role='menu'
                  data-accordion='true'
                >
                  {/* {props.permittedPages.map((page, index) => (
              <NavItem >
                <NavLink
                  href={void 0}
                  tag={Link}
                  to={page.pageUrl}
                  className={` ${
                    splitLocation[1] === page.pageUrl ? 'active' : ''
                  }`}
                >
                  <p>{page.pageName}</p>
                </NavLink>
              </NavItem>
            ))} */}
                  {isPermittedPage('/dashboard') && (
                    <NavItem>
                      <NavLink
                        href={void 0}
                        tag={Link}
                        to='/dashboard'
                        className={` ${splitLocation[1] === 'dashboard' ? 'active' : ''
                          }`}
                      >
                        <p>Dashboard</p>
                      </NavLink>
                    </NavItem>
                  )}

                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'AddDistributorInductionForm' ||
                        splitLocation[1] === 'EditDistributorInductionForm' ||
                        splitLocation[1] === 'Distributors' ||
                        splitLocation[1] === 'IncompleteDistributors'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Distributor
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/AddDistributorInductionForm') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/AddDistributorInductionForm'
                            className={` ${splitLocation[1] ===
                              'AddDistributorInductionForm' ||
                              splitLocation[1] ===
                              'EditDistributorInductionForm'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Distributor Induction form</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/Distributors') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/Distributors'
                            className={` ${splitLocation[1] === '/Distributors'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Complete Distributors List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/IncompleteDistributors') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            to='/IncompleteDistributors'
                            tag={Link}
                            className={` ${splitLocation[1] === '/IncompleteDistributors'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Incomplete Distributors</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'QrTillList' ||
                        splitLocation[1] === 'DeliverymanUserRegistration' ||
                        splitLocation[1] === 'TillAssignmentForm' ||
                        splitLocation[1] === 'DailyCollectionsReport' ||
                        splitLocation[1] === 'TillNumberDetails'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        QR/Till Details
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/QrTillList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/QrTillList'
                            className={` ${splitLocation[1] === 'QrTillList' ? 'active' : ''
                              }`}
                          >
                            <p>QR/Till List</p>
                          </NavLink>
                        </NavItem>
                      )}

                      {isPermittedPage('/DeliverymanUserRegistration') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DeliverymanUserRegistration'
                            className={` ${splitLocation[1] === 'DeliverymanUserRegistration'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>User Registration</p>
                          </NavLink>
                        </NavItem>
                      )}

                      {isPermittedPage('/DailyCollectionsReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DailyCollectionsReport'
                            className={` ${splitLocation[1] === 'DailyCollectionsReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Daily Collections Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/TillNumberDetails') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/TillNumberDetails'
                            className={` ${splitLocation[1] === 'TillNumberDetails'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>VL Till Number Details</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'vizLinkJobsList' ||
                        splitLocation[1] === 'vizLinkShopsOnBoardingDetails' ||
                        splitLocation[1] === 'vizLinkShopsList' ||
                        splitLocation[1] === 'sectors' ||
                        splitLocation[1] === 'SectorBasedReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        VizLink Shops
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/vizLinkJobsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/vizLinkJobsList'
                            className={` ${splitLocation[1] === 'vizLinkJobsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shops Induction Jobs List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/vizLinkShopsOnBoardingDetails') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/vizLinkShopsOnBoardingDetails'
                            className={` ${splitLocation[1] ===
                              'vizLinkShopsOnBoardingDetails'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shops On-Boarding Details</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/vizLinkShopsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/vizLinkShopsList'
                            className={` ${splitLocation[1] === 'vizLinkShopsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>VizLink Shops List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/sectors') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/sectors'
                            className={` ${splitLocation[1] === 'sectors' ? 'active' : ''
                              }`}
                          >
                            <p>Sectors List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/SectorBasedReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/SectorBasedReport'
                            className={` ${splitLocation[1] === 'SectorBasedReport' ? 'active' : ''
                              }`}
                          >
                            <p>Sector-Based Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'RegisteredAgentsList' ||
                        splitLocation[1] === 'VizAgentsList'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        VizLink Agents
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/VizAgentsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/VizAgentsList'
                            className={` ${splitLocation[1] === 'VizAgentsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Manage Agents</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/RegisteredAgentsList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/RegisteredAgentsList'
                            className={` ${splitLocation[1] === 'RegisteredAgentsList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Registered Agents</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'NewOrdersForm' ||
                        splitLocation[1] === 'OrderStatusReport' ||
                        splitLocation[1] === 'OrderStatusReport' ||
                        splitLocation[1] === 'PaymentStatusReport' ||
                        splitLocation[1] === 'OrderShopDetails' ||
                        splitLocation[1] === 'InvoiceAllocationListing'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Daily Orders
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/NewOrdersForm') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/NewOrdersForm'
                            className={` ${splitLocation[1] === 'NewOrdersForm'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>New Orders Form</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/PaymentStatusReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/PaymentStatusReport'
                            className={` ${splitLocation[1] === 'PaymentStatusReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shop-wise Payment Status Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/DLMCollectionReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DLMCollectionReport'
                            className={` ${splitLocation[1] === 'DLMCollectionReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Deliveryman-wise Collection Details Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/InvoiceAllocationListing') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/InvoiceAllocationListing'
                            className={` ${splitLocation[1] === 'InvoiceAllocationListing'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Invoice Allocation Listing</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/SCRDetailReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/SCRDetailReport'
                            className={` ${splitLocation[1] === 'SCRDetailReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>SCR Detail Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'SchemesList' ||
                        splitLocation[1] === 'CoinsHistoryReport' ||
                        splitLocation[1] === 'DistributorCoinsPaymentReport' ||
                        splitLocation[1] === 'DistributorCoinsDisbursmentReport' ||
                        splitLocation[1] === 'DeliveryManCoinsPaymentReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        VL Loyalty Program
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/SchemesList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/SchemesList'
                            className={` ${splitLocation[1] === 'SchemesList' ? 'active' : ''
                              }`}
                          >
                            <p>Schemes List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/CoinsHistoryReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/CoinsHistoryReport'
                            className={` ${splitLocation[1] === 'CoinsHistoryReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Coins History Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage(
                        '/DistributorCoinsDisbursmentReport'
                      ) && (
                          <NavItem>
                            <NavLink
                              href={void 0}
                              tag={Link}
                              to='/DistributorCoinsDisbursmentReport'
                              className={` ${splitLocation[1] ===
                                'DistributorCoinsDisbursmentReport'
                                ? 'active'
                                : ''
                                }`}
                            >
                              <p>Distributor Coins Disbursement Report</p>
                            </NavLink>
                          </NavItem>
                        )}
                      {isPermittedPage('/DeliveryManCoinsPaymentReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DeliveryManCoinsPaymentReport'
                            className={` ${splitLocation[1] ===
                              'DeliveryManCoinsPaymentReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Deliveryman Coins Payment Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/DistributorCoinsPaymentReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DistributorCoinsPaymentReport'
                            className={` ${splitLocation[1] ===
                              'DistributorCoinsPaymentReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Distributor Coins Payment Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'LoanDisbursementReport' ||
                        splitLocation[1] === 'FinjaLoanReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Loan Payments
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/LoanDisbursementReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/LoanDisbursementReport'
                            className={` ${splitLocation[1] === 'LoanDisbursementReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Loan Disbursement Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/FinjaLoanReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/FinjaLoanReport'
                            className={` ${splitLocation[1] === 'FinjaLoanReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Successful Loan Details Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'InvoiceWiseDailyCollectionDetails' ||
                        splitLocation[1] === 'InvoiceWisePaymentBreakup' ||
                        splitLocation[1] === 'monthWiseM2DReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        M2D Reports
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/InvoiceWiseDailyCollectionDetails') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/InvoiceWiseDailyCollectionDetails'
                            className={` ${splitLocation[1] === 'InvoiceWiseDailyCollectionDetails'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Invoice-wise Daily Collection Details</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/InvoiceWisePaymentBreakup') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/InvoiceWisePaymentBreakup'
                            className={` ${splitLocation[1] === 'InvoiceWisePaymentBreakup'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Invoice-wise Payment Break-up</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/monthWiseM2DReport') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/monthWiseM2DReport'
                            className={` ${splitLocation[1] === 'monthWiseM2DReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Month-Wise M2D Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'KycFormList' ||
                        splitLocation[1] === 'KycVisitRequiredList' ||
                        splitLocation[1] === 'KycInformationInquiryList' ||
                        splitLocation[1] === 'KYCFinalized'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        KYC
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/KycFormList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/KycFormList'
                            className={` ${splitLocation[1] === 'KycFormList' ? 'active' : ''
                              }`}
                          >
                            <p>KYC Form List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/KycVisitRequiredList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/KycVisitRequiredList'
                            className={` ${splitLocation[1] === 'KycVisitRequiredList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Visit Required List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/KycInformationInquiryList') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/KycInformationInquiryList'
                            className={` ${splitLocation[1] === 'KycInformationInquiryList'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Information Inquiry List</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/KYCFinalized') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/KYCFinalized'
                            className={` ${splitLocation[1] === 'KYCFinalized'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Finalized KYC Form List</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  {isPermittedPage('/Advertisements') && (
                    <NavItem>
                      <NavLink
                        href={void 0}
                        tag={Link}
                        to='/Advertisements'
                        className={` ${splitLocation[1] === 'Advertisements' ? 'active' : ''
                          }`}
                      >
                        <p>Advertisements</p>
                      </NavLink>
                    </NavItem>
                  )}
                  {isPermittedPage('/ManageRSO') && (
                    <NavItem>
                      <NavLink
                        href={void 0}
                        tag={Link}
                        to='/ManageRSO'
                        className={` ${splitLocation[1] === 'ManageRSO' ? 'active' : ''
                          }`}
                      >
                        <p>RSO's</p>
                      </NavLink>
                    </NavItem>
                  )}
                  {isPermittedPage('/ManagePrincipals') && (
                    <NavItem>
                      <NavLink
                        href={void 0}
                        tag={Link}
                        to='/ManagePrincipals'
                        className={` ${splitLocation[1] === 'ManagePrincipals'
                          ? 'active'
                          : ''
                          }`}
                      >
                        <p>Manage Principals</p>
                      </NavLink>
                    </NavItem>
                  )}
                  {isPermittedPage('/ManageMFIs') && (
                    <NavItem>
                      <NavLink
                        href={void 0}
                        tag={Link}
                        to='/ManageMFIs'
                        className={` ${splitLocation[1] === 'ManageMFIs' ? 'active' : ''
                          }`}
                      >
                        <p>Manage MFI</p>
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'CityAndArea' ||
                        splitLocation[1] === 'ManageUsers' ||
                        splitLocation[1] === 'MfiWalletRequiredLimit' ||
                        splitLocation[1] === 'BusinessEntityType' ||
                        splitLocation[1] === 'Banks' ||
                        splitLocation[1] === 'Permissions' ||
                        splitLocation[1] === 'Versions' ||
                        splitLocation[1] === 'UserActivity' ||
                        splitLocation[1] === 'Notifications'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Settings
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/ManageUsers') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/ManageUsers'
                            className={` ${splitLocation[1] === 'ManageUsers' ? 'active' : ''
                              }`}
                          >
                            <p>Manage Users</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/CityAndArea') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/CityAndArea'
                            className={` ${splitLocation[1] === 'CityAndArea' ? 'active' : ''
                              }`}
                          >
                            <p>Manage City / Area</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/MfiWalletRequiredLimit') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/MfiWalletRequiredLimit'
                            className={` ${splitLocation[1] === 'MfiWalletRequiredLimit'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Manage Mfi Wallet Limit</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/BusinessEntityType') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/BusinessEntityType'
                            className={` ${splitLocation[1] === 'BusinessEntityType'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Manage Business Entity Types</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/Banks') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/Banks'
                            className={` ${splitLocation[1] === 'Banks' ? 'active' : ''
                              }`}
                          >
                            <p>Manage Banks</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/Permissions') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/Permissions'
                            className={` ${splitLocation[1] === 'Permissions' ? 'active' : ''
                              }`}
                          >
                            <p>Manage Permissions</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/Versions') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/Versions'
                            className={` ${splitLocation[1] === 'Versions' ? 'active' : ''
                              }`}
                          >
                            <p>Manage Versions</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/UserActivity') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/UserActivity'
                            className={` ${splitLocation[1] === 'Versions' ? 'active' : ''
                              }`}
                          >
                            <p>User Activity</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/Notifications') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/Notifications'
                            className={` ${splitLocation[1] === 'Notifications'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Notifications</p>
                          </NavLink>
                        </NavItem>
                      )}

                      <NavItem>
                        <NavLink
                          href={void 0}
                          tag={Link}
                          to='/NotificationsScheduler'
                          className={` ${splitLocation[1] === 'NotificationsScheduler'
                            ? 'active'
                            : ''
                            }`}
                        >
                          <p>Auto Notifications</p>
                        </NavLink>
                      </NavItem>
                    </ul>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'VLCCommunicationListing'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        VL Connect
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      {isPermittedPage('/VLCCommunicationListing') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/VLCCommunicationListing'
                            className={` ${splitLocation[1] === 'VLCCommunicationListing'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Communication Listing</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/DistributorInbox') && (
                        <NavItem>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DistributorInbox'
                            className={` ${splitLocation[1] === 'DistributorInbox'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Distributor Inbox</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href={void 0}
                      className={` ${splitLocation[1] === 'DailyTransactionsSummary' ||
                        splitLocation[1] === 'ShopwiseTransactionsDetails' ||
                        splitLocation[1] === 'DigitalCollectionsReport' ||
                        splitLocation[1] === 'RsoDetailsReport' ||
                        splitLocation[1] === 'RsoPerformanceReport' ||
                        splitLocation[1] === 'ShopsDetailReport'
                        ? 'active'
                        : ''
                        }`}
                    >
                      <p>
                        Reports
                        <i className='right fas fa-angle-left'></i>
                      </p>
                    </NavLink>
                    <ul className='nav nav-treeview'>
                      <NavItem>
                        <NavLink
                          href={void 0}
                          tag={Link}
                          to='/DailyTransactionsSummary'
                          className={` ${splitLocation[1] === 'ManageUsers' ? 'active' : ''
                            }`}
                        >
                          <p>Daily Transactions Summary</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href={void 0}
                          tag={Link}
                          to='/ShopwiseTransactionsDetails'
                          className={` ${splitLocation[1] === 'ShopwiseTransactionsDetails'
                            ? 'active'
                            : ''
                            }`}
                        >
                          <p>Shop-wise Transactions Details</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href={void 0}
                          tag={Link}
                          to='/DigitalCollectionsReport'
                          className={` ${splitLocation[1] === 'DigitalCollectionsReport'
                            ? 'active'
                            : ''
                            }`}
                        >
                          <p>Digital Collections Report</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href={void 0}
                          tag={Link}
                          to='/RsoDetailsReport'
                          className={` ${splitLocation[1] === 'RsoDetailsReport'
                            ? 'active'
                            : ''
                            }`}
                        >
                          <p>Rso Details Report</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href={void 0}
                          tag={Link}
                          to='/RsoPerformanceReport'
                          className={` ${splitLocation[1] === 'RsoPerformanceReport'
                            ? 'active'
                            : ''
                            }`}
                        >
                          <p>Rso Performance Report</p>
                        </NavLink>
                      </NavItem>
                      {isPermittedPage('/ShopWisePulseReport') && (
                        <NavItem className='nav-item'>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/ShopWisePulseReport'
                            className={`nav-link ${splitLocation[1] === 'ShopWisePulseReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shops-Wise Pulse Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/DateWiseShopsSummaryReport') && (
                        <NavItem className='nav-item'>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/DateWiseShopsSummaryReport'
                            className={`nav-link ${splitLocation[1] === 'DateWiseShopsSummaryReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Active Shops Summary</p>
                          </NavLink>
                        </NavItem>
                      )}
                      {isPermittedPage('/ShopsDetailReport') && (
                        <NavItem className='nav-item'>
                          <NavLink
                            href={void 0}
                            tag={Link}
                            to='/ShopsDetailReport'
                            className={`nav-link ${splitLocation[1] === 'ShopsDetailReport'
                              ? 'active'
                              : ''
                              }`}
                          >
                            <p>Shops Detail Report</p>
                          </NavLink>
                        </NavItem>
                      )}
                    </ul>
                  </NavItem>
                  {isPermittedPage('/About') && (
                    <NavItem>
                      <NavLink
                        href={void 0}
                        tag={Link}
                        to='/About'
                        className={` ${splitLocation[1] === 'About' ? 'active' : ''
                          }`}
                      >
                        <p>About</p>
                      </NavLink>
                    </NavItem>
                  )}
                </ul>
              </nav>
            </div>
          </aside>
        </div>
      }
    </>
  )
}

// get permitted pages from redux
export default connect((state) => ({
  userRole: storage.get('_appUserRole'),
  permittedPages: JSON.parse(storage.get('_userPermissionsList')), ///state.user.permittedPages,
}))(SideNavbar)
