import { useEffect, useState, useRef } from 'react'
import { Col, Row, Overlay, Button, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import FormikControl from '../../../layouts/Input/FormikControl'
import storage from '../../../apis/Storage'

import {
    GET_DISTRIBUTIONCENTER_DDL,
    GET_VIZSHOP_CATEGORIES,
    GET_DISTRIBUTOR_DDL,
    GET_SUBLOCALITY,
    GET_LOCALITY,
} from '../../../Types'

import { getDropdownList } from '../../Shared/dropdownListActions'
import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'
import { toast } from 'react-toastify'
import { getSectorsBasedMap } from './SectorBasedReportActions'

const Marker = (props) => {
    const { color, name, id } = props
    return (
        <div
            className='marker'
            style={{ backgroundColor: color, cursor: 'pointer' }}
            title={name}
        />
    )
}

const Legend = ({ sectors, shops, agents }) => {
    return (
        <div className='legend px-3'>
            <div className='legend-item'>
                <div className='legend-icon legend-sector'></div>
                <div className='legend-label'>Sector {sectors}</div>
            </div>
            <div className='legend-item'>
                <div className='legend-icon legend-shop'></div>
                <div className='legend-label'>Shop {shops}</div>
            </div>
            <div className='legend-item'>
                <div className='legend-icon legend-agent'></div>
                <div className='legend-label'>Agent {agents}</div>
            </div>
        </div>
    )
}

const createPolygon = (map, polygonCoords) => {
    const polygon = new window.google.maps.Polygon({
        paths: polygonCoords,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
    })
    polygon.setMap(map)
    return polygon
}

const SimpleMap = (props) => {
    return (
        <div style={{ height: '80vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDqs07ua04jy-Zz_nYm6GMvOo6WgKZrWNI' }}
                defaultCenter={{
                    lat: parseFloat('31.517106'),
                    lng: parseFloat('74.363479'),
                }}
                defaultZoom={12}
            >
            </GoogleMapReact>
            <Legend
                sectors={props.sectors.length}
                shops={props.shops.length}
                agents={props.agents.length}
            />
        </div>
    )
}

const SectorBasedReport = (props) => {

    const userRights = JSON.parse(storage.get('_userRights'));
    const [target, setTarget] = useState(null);
    const [show, setShow] = useState(false);
    const ref = useRef(null);

    const [distributorList, setDistributorList] = useState([]);
    const [distCenterList, setdistCenterList] = useState([]);
    const [localityList, setlocalityList] = useState([]);
    const [sublocalityList, setsublocalityList] = useState([]);
    const [shopTypeList, setshopTypeList] = useState([]);
    const [isApplyFilter, setIsApplyFilter] = useState(false);

    const [activeShops, setActiveShops] = useState(true);
    const [agents, setAgents] = useState(true);

    const [selectedSector, setSelectedSector] = useState([]);
    const [agentsList, setAgentsList] = useState([]);
    const [shopsList, setShopsList] = useState([]);

    const [searchText, setSearchText] = useState('');

    const [filter, setfilter] = useState({
        distributor: null,
        distCenter: null,
        locality: null,
        sublocality: null,
        sectorId: '',
        shopType: null,
        activeShops: activeShops,
        agents: agents
    })

    const [isAlreadyLoadMap, setIsAlreadyLoadMap] = useState(false);

    const Map = () => {
        const center = {
            lat: parseFloat(selectedSector.length == 1 ? selectedSector[0].poligonPoint1.split(',')[1] : '31.517106'),
            lng: parseFloat(selectedSector.length == 1 ? selectedSector[0].poligonPoint1.split(',')[0] : '74.363479'),
        }
        const zoom = selectedSector.length == 1 ? 14.2 : 12;
        const handleApiLoaded = (map, maps) => {
            selectedSector.map((sector) => {
                let polygon = [
                    {
                        lat: parseFloat(sector.poligonPoint1.split(',')[1]),
                        lng: parseFloat(sector.poligonPoint1.split(',')[0]),
                    },
                    {
                        lat: parseFloat(sector.poligonPoint2.split(',')[1]),
                        lng: parseFloat(sector.poligonPoint2.split(',')[0]),
                    },
                    {
                        lat: parseFloat(sector.poligonPoint3.split(',')[1]),
                        lng: parseFloat(sector.poligonPoint3.split(',')[0]),
                    },
                    {
                        lat: parseFloat(sector.poligonPoint4.split(',')[1]),
                        lng: parseFloat(sector.poligonPoint4.split(',')[0]),
                    },
                    {
                        lat: parseFloat(sector.poligonPoint5.split(',')[1]),
                        lng: parseFloat(sector.poligonPoint5.split(',')[0]),
                    },
                ]
                createPolygon(map, polygon);
            });
        }
        return (
            <div style={{ height: '80vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDqs07ua04jy-Zz_nYm6GMvOo6WgKZrWNI' }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                >
                    {shopsList.map((item, index) => (
                        <Marker
                            lat={item.lat}
                            lng={item.long}
                            name={item.code + ' : ' + item.name}
                            color='orange'
                        />
                    ))}

                    {agentsList.map((item, index) => (
                        <Marker
                            lat={item.lat}
                            lng={item.long}
                            name={item.code + ' : ' + item.name}
                            color='blue'
                        />
                    ))}
                </GoogleMapReact>
                <Legend
                    sectors={selectedSector.length}
                    shops={shopsList.length}
                    agents={agentsList.length}
                />
            </div>
        )
    }

    useEffect(() => {
        if (props.SectorsBasedMap && props.SectorsBasedMap.length > 0) {
            let sectors = [];
            let shops = [];
            let agents = [];
            props.SectorsBasedMap.map((data) => {
                sectors.push({
                    poligonPoint1: data.poligonPoint1,
                    poligonPoint2: data.poligonPoint2,
                    poligonPoint3: data.poligonPoint3,
                    poligonPoint4: data.poligonPoint4,
                    poligonPoint5: data.poligonPoint5,
                });

                setSelectedSector(sectors);

                data.shops.map((shop) => {
                    shops.push(shop);
                });

                data.agents.map((agent) => {
                    agents.push(agent);
                });
            });

            setShopsList(shops);
            setAgentsList(agents);
        }
        else {
            setAgentsList([]);
            setShopsList([]);
            setSelectedSector([]);
        }
    }, [props.SectorsBasedMap])

    const openFilterModal = (event) => {
        setShow(!show)
        setIsAlreadyLoadMap(show);
        setTarget(event.target)
    }

    function commonReset(reset = true) {
        if (reset) {
            setIsAlreadyLoadMap(false);
            setSelectedSector([]);
            setShopsList([]);
            setAgentsList([]);
            setSearchText('');
            setAgents(true);
            setActiveShops(true);

            setfilter({
                distributor:
                    userRights != null && userRights?.userType == 'DistributorUser'
                        ? filter.distributor
                        : null,
                distCenter: null,
                locality: null,
                sublocality: null,
                sectorId: '',
                shopType: null,
                activeShops: activeShops,
                agents: agents,
            })
        }
    }

    function formRequestObject() {
        let requestData = {
            distributor: filter.distributor?.value,
            distCenter: filter.distCenter?.value,
            locality: filter.locality?.value,
            sublocality: filter.sublocality?.value,
            sectorId: filter.sectorId,
            shopType: filter.shopType?.label,
            activeShops: activeShops,
            agents: agents,
            searchText: searchText
        }
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            requestData = {
                ...requestData,
                distributorId: userRights?.distributorId,
            }
        }
        return requestData
    }

    const handleResetFilters = (resetForm) => {
        resetForm()
        commonReset()
    }

    useEffect(() => {
        if (searchText) {
            const delayDebounceFn = setTimeout(() => {
                LoadDataByfilter()
            }, 2000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchText])

    function fetchReportData(request) {
        let finalRequest = request == null ? formRequestObject() : request
        props.getSectorsBasedMap(finalRequest)
    }

    const LoadDataByfilter = (event) => {
        var request = { ...formRequestObject() }
        // if (!request.distributor) {
        //     toast.warn('Please select distributor');
        //     return;
        // }
        // if (!request.distCenter) {
        //     toast.warn('Please select center');
        //     return;
        // }
        if (!request.activeShops && !request.agents) {
            toast.warn("Please check Active shops Or Agents");
            return;
        }
        fetchReportData(request)
        setShow(false)
        setIsAlreadyLoadMap(true);
        setIsApplyFilter(true)
    }

    useEffect(() => {
        props.getDropdownList(
            `api/Distributor/GetDistributorsDDL`,
            GET_DISTRIBUTOR_DDL
        )
    }, [])

    useEffect(() => {
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            let distributorList = props.allDistributorList.filter(
                (x) => x.value == userRights?.distributorId
            )
            if (distributorList?.length > 0) {
                setfilter({
                    ...filter,
                    distributor: {
                        label: distributorList[0]?.label,
                        value: distributorList[0]?.value,
                    },
                })
            }
            setDistributorList(
                distributorList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        } else {
            setDistributorList(
                props.allDistributorList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        }
    }, [props.allDistributorList])

    useEffect(() => {
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            let distCenterList = props.selectedDistCentersList.filter((x) =>
                userRights?.distCenterIds.includes(x.value)
            )
            setdistCenterList(
                distCenterList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        } else {
            setdistCenterList(
                props.selectedDistCentersList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        }
    }, [props.selectedDistCentersList])

    useEffect(() => {
        setlocalityList(
            props.locality.map(function (item) {
                return {
                    value: item.value,
                    label: item.label,
                }
            })
        )
    }, [props.locality])

    useEffect(() => {
        setsublocalityList(
            props.subLocality.map(function (item) {
                return {
                    value: item.value,
                    label: item.label,
                }
            })
        )
    }, [props.subLocality])

    useEffect(() => {
        setshopTypeList(
            props.vizShopCategories.map(function (item) {
                return {
                    value: item.value,
                    label: item.label,
                }
            })
        )
    }, [props.vizShopCategories])

    useEffect(() => {
        if (filter.distributor) {
            props.getDropdownList(
                `api/DistCenter/GetDistCentersDDL/${filter.distributor.value}`,
                GET_DISTRIBUTIONCENTER_DDL
            )
        }
        filter.distCenter = null
        filter.locality = null
        filter.sublocality = null
    }, [filter.distributor])

    useEffect(() => {
        if (filter.distCenter) {
            props.getDropdownList(
                `api/Sector/GetLocality/${filter.distCenter.value}`,
                GET_LOCALITY
            )
        }
        filter.locality = null
        filter.sublocality = null
    }, [filter.distCenter])

    useEffect(() => {
        if (filter.locality) {
            props.getDropdownList(
                `api/Sector/GetSubLocality/${filter.locality.value}`,
                GET_SUBLOCALITY
            )
        }
        filter.sublocality = null
    }, [filter.locality])

    useEffect(() => {
        props.getDropdownList(
            `api/VizShop/GetShopCategoriesDDL`,
            GET_VIZSHOP_CATEGORIES
        )
    }, [])

    return (
        <div>
            <VzPageContainer title='Sector-Based Report'>
                <Row>
                    <Col style={{ float: 'left' }}>
                        <h4>
                            <b>Sector-Based Report</b>
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={9} style={{ float: 'right' }}>
                        <div ref={ref}>
                            <Button
                                onClick={openFilterModal}
                                className='m-3'
                                variant='outline-primary'
                            >
                                <i className='fa fa-filter' />
                                Filters
                            </Button>

                            <Overlay
                                show={show}
                                target={target}
                                placement='bottom'
                                container={ref}
                                containerPadding={20}
                                width='430px'
                                style={{ width: '430px' }}
                            >
                                <Popover
                                    id='popover-contained'
                                    style={{ maxWidth: '360px', width: '360px' }}
                                >
                                    <Popover.Header as='h3'>Filters</Popover.Header>
                                    <Popover.Body>
                                        <Formik
                                            enableReinitialize
                                            initialValues={filter}
                                            onSubmit={LoadDataByfilter}
                                        >
                                            {(formik) => (
                                                <Form autoComplete='off'>
                                                    <Row>
                                                        {userRights == null && (
                                                            <Col xs={12} md={12}>
                                                                <FormikControl
                                                                    formik={formik}
                                                                    control='react-select'
                                                                    label='Distributor'
                                                                    name='distributor'
                                                                    options={distributorList}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    handleOnChange={(e) => {
                                                                        if (!e || !e.value) {
                                                                            setfilter({
                                                                                ...filter,
                                                                                distributor: null,
                                                                            })
                                                                            return
                                                                        }
                                                                        setfilter({
                                                                            ...filter,
                                                                            distributor: {
                                                                                label: e.label,
                                                                                value: e.value,
                                                                            },
                                                                        })
                                                                    }}
                                                                    value={filter.distributor}
                                                                />
                                                            </Col>
                                                        )}

                                                        <Col xs={12} md={12}>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='react-select'
                                                                label='Distribution Center'
                                                                name='distCenter'
                                                                options={filter.distributor != null ? distCenterList : []}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                handleOnChange={(e) => {
                                                                    if (!e || !e.value) {
                                                                        setfilter({
                                                                            ...filter,
                                                                            distCenter: null,
                                                                        })
                                                                        return
                                                                    }
                                                                    setfilter({
                                                                        ...filter,
                                                                        distCenter: {
                                                                            label: e.label,
                                                                            value: e.value,
                                                                        },
                                                                    })
                                                                }}
                                                                value={filter.distCenter}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='react-select'
                                                                label='Locality'
                                                                name='locality'
                                                                options={filter.distCenter != null ? localityList : []}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                handleOnChange={(e) => {
                                                                    if (!e || !e.value) {
                                                                        setfilter({
                                                                            ...filter,
                                                                            locality: null,
                                                                        })
                                                                        return
                                                                    }
                                                                    setfilter({
                                                                        ...filter,
                                                                        locality: {
                                                                            label: e.label,
                                                                            value: e.value,
                                                                        },
                                                                    })
                                                                }}
                                                                value={filter.locality}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='react-select'
                                                                label='Sub Locality'
                                                                name='sublocality'
                                                                options={filter.locality != null ? sublocalityList : []}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                handleOnChange={(e) => {
                                                                    if (!e || !e.value) {
                                                                        setfilter({
                                                                            ...filter,
                                                                            sublocality: null,
                                                                        })
                                                                        return
                                                                    }
                                                                    setfilter({
                                                                        ...filter,
                                                                        sublocality: {
                                                                            label: e.label,
                                                                            value: e.value,
                                                                        },
                                                                    })
                                                                }}
                                                                value={filter.sublocality}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='input'
                                                                type='sectorId'
                                                                label='Sector Id'
                                                                name='sectorId'
                                                                handleOnChange={(value) => {
                                                                    filter.sectorId = value
                                                                }}
                                                                value={filter.sectorId}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='react-select'
                                                                label='Shop Type'
                                                                name='shopType'
                                                                options={shopTypeList}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                handleOnChange={(e) => {
                                                                    if (!e || !e.value) {
                                                                        setfilter({
                                                                            ...filter,
                                                                            shopType: null,
                                                                        })
                                                                        return
                                                                    }
                                                                    setfilter({
                                                                        ...filter,
                                                                        shopType: {
                                                                            label: e.label,
                                                                            value: e.value,
                                                                        },
                                                                    })
                                                                }}
                                                                value={filter.shopType}
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <label className='my-2'>
                                                                Shop Status
                                                            </label>
                                                            <row>
                                                                <Col xs={12} md={6}>
                                                                    <label className='my-0'>
                                                                        <input
                                                                            type='checkbox'
                                                                            checked={activeShops}
                                                                            onChange={(event) => {
                                                                                setActiveShops(event.target.checked)
                                                                            }}
                                                                            className='mr-2'
                                                                            value={activeShops}
                                                                        />
                                                                        <span>Active Shops</span>
                                                                    </label>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <label className='my-0'>
                                                                        <input
                                                                            type='checkbox'
                                                                            checked={agents}
                                                                            onChange={(event) => {
                                                                                setAgents(event.target.checked)
                                                                            }}
                                                                            className='mr-2'
                                                                            value={agents}
                                                                        />
                                                                        <span>Agents</span>
                                                                    </label>
                                                                </Col>
                                                            </row>

                                                        </Col>
                                                    </Row>
                                                    <div className='row' style={{ marginTop: 20 }}>
                                                        <div className='form-group'>
                                                            <Link
                                                                onClick={handleResetFilters.bind(
                                                                    null,
                                                                    formik.resetForm,
                                                                    setIsApplyFilter(false),
                                                                )}
                                                                to='#'
                                                                className='active'
                                                                style={{
                                                                    padding: '0px',
                                                                    marginTop: '10px',
                                                                    float: 'left',
                                                                }}
                                                            >
                                                                Reset
                                                            </Link>
                                                            <Link
                                                                onClick={() => {
                                                                    setShow(false)
                                                                    setIsAlreadyLoadMap(true)
                                                                    setTarget(null)
                                                                    commonReset(isApplyFilter)
                                                                }}
                                                                to='#'
                                                                className='active'
                                                                style={{
                                                                    padding: '0px',
                                                                    marginTop: '10px',
                                                                    float: 'right',
                                                                }}
                                                            >
                                                                Cancel
                                                            </Link>
                                                            <div className='float-right'>
                                                                <Button
                                                                    type='submit'
                                                                    className='btn btn-primary'
                                                                    style={{
                                                                        marginRight: '10px',
                                                                        float: 'right',
                                                                    }}
                                                                >
                                                                    Apply
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Popover.Body>
                                </Popover>
                            </Overlay>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <input
                            className='form-control'
                            value={searchText || ''}
                            onChange={(e) => {
                                setSearchText(e.target.value)
                            }}
                            placeholder={`Search...`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        {isAlreadyLoadMap ?
                            <Map /> :
                            <SimpleMap
                                sectors={[]}
                                shops={[]}
                                agents={[]}
                            />
                        }
                    </Col>
                </Row>
            </VzPageContainer>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        SectorsBasedMap: state.SectorsBasedMap
            ? state.SectorsBasedMap
            : [],
        allDistributorList: state.ddlList.allDistributorList
            ? state.ddlList.allDistributorList
            : [],
        selectedDistCentersList: state.ddlList.selectedDistCentersList
            ? state.ddlList.selectedDistCentersList
            : [],
        locality: state.ddlList.locality
            ? state.ddlList.locality
            : [],
        subLocality: state.ddlList.subLocality
            ? state.ddlList.subLocality
            : [],
        vizShopCategories: state.ddlList.vizShopCategories
            ? state.ddlList.vizShopCategories
            : [],
    }
}
export default connect(mapStateToProps, {
    getDropdownList,
    getSectorsBasedMap
})(SectorBasedReport)
