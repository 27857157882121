import { connect } from 'react-redux'
import { VzPageContainer } from '../../../../layouts/VzPageContainer'
import { useState, useEffect } from 'react'
import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../../layouts/DataTables/CustomDataTable'
import { Input } from 'reactstrap'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import {
  addPageAction,
  deletePageAction,
  getPageActions,
  getPageAndActionsList,
} from './PageActionsActions'
import ReactSelect from 'react-select'

const PageActions = (props) => {
  const [pagesList, setPagesList] = useState([])
  const [newAction, setNewAction] = useState('')
  const [selectedPage, setSelectedPage] = useState()

  const [searchParent, setSearchParent] = useState()
  const [filteredPages, setFilteredPages] = useState([])

  const pagesColumns = [
    {
      Header: 'Page Name',
      accessor: 'pageName',
    },
    {
      Header: 'Action Name',
      accessor: 'actionName',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button
            onClick={() => Delete(row.value)}
            className='btn btn-danger'
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fa fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  const addPageAction = async () => {
    if (!selectedPage.value) {
      toast.error('Select Page')
      return
    }

    if (newAction == '' || newAction == null) {
      toast.error('Enter Action Name')
      return
    }

    debugger
    var newActionName = newAction.toUpperCase().replace(/ /g, '').trim();
    var selectedPageId = selectedPage.value.toUpperCase().replace(/ /g, '').trim();
    let checkIsExist = props.PageActions?.filter(
      (item) =>
        newActionName === item.actionName.toUpperCase().replace(/ /g, '').trim() &&
      selectedPageId === item.pageId.replace(/ /g, '').trim().toUpperCase()

    )

    if (checkIsExist?.length > 0) {
      toast.warn('Action with the same name already exist')
      return
    }

    let data = {
      ActionName: newAction,
      PageId: selectedPage.value,
    }

    await props.addPageAction(data)
    getPageActions()

    setNewAction('')
    setSelectedPage(null)

    props.getPageAndActionsList()
  }

  const getPageActions = () => {
    props.getPageActions()
    props.getPageAndActionsList()
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const DeleteRecord = async (id) => {
    await props.deletePageAction(id)
    if (searchParent != null) {
      setFilteredPages(filteredPages.filter((x) => x.id != id))
    }
    props.getPageAndActionsList()
  }

  useEffect(() => {
    getPageActions()
  }, [])

  useEffect(() => {
    if (searchParent != null) {
      setFilteredPages(
        props.PageActions?.filter((x) => x.pageId == searchParent.value)
      )
    }
  }, [props.PageActions])
  useEffect(() => {
    if (searchParent != null) {
      axios
        .get(`api/PageAction/${searchParent.value}`)
        .then(({ data }) => {
          setFilteredPages(data)
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              logout()
              history.push('/login')
              history.go('/login')
            } else if (
              error.response.status === 400 &&
              error.response.data.errors
            ) {
              let errorMessage = ''
              var errors = error.response.data.errors
              var keys = Object.keys(errors)
              for (let j = 0; j < keys.length; j++) {
                errorMessage += errors[keys[j]] + ' , \n'
              }
              toast.error(errorMessage)
            } else if (error.response.data)
              toast.error(
                error.response.data.Message
                  ? error.response.data.Message
                  : error.response.data.message
              )
            else {
              toast.error('Error while processing the request')
            }
          } else toast.error('Error while processing the request')
        })
    } else {
      setFilteredPages([])
    }
  }, [searchParent])

  useEffect(() => {
    if (props.pages)
      setPagesList(
        props.pages
          .filter((x) => x.parentId != null)
          .map((item) => {
            return {
              label: item.pageName,
              value: item.id,
            }
          })
      )
  }, [props.pages])

  return (
    <div>
      <Row style={{ marginTop: '40px' }}>
        <Col xs={12} md={{ span: 4, offset: 1 }} style={{ marginTop: '80px' }}>
          <Col md={12}>
            <Col md={12}>
              <div className='form-group'>
                <ReactSelect
                  id='mfi'
                  name='mfi'
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Select Page'
                  isMulti={false}
                  value={selectedPage}
                  isClearable={true}
                  onChange={(res) => setSelectedPage(res)}
                  options={pagesList ?? []}
                ></ReactSelect>
              </div>
            </Col>
            <Col md={12}>
              <div className='form-group'>
                <Input
                  value={newAction}
                  onChange={(name) => setNewAction(name.target.value)}
                  type='text'
                  placeholder='Enter Action Name'
                />
              </div>
            </Col>
            <Col md={12} style={{ marginTop: '10px', display: 'inline-block' }}>
              <div className='form-group'>
                <Button
                  className='form-btn'
                  variant='primary'
                  onClick={addPageAction}
                  style={{ fontSize: 13 }}
                >
                  Add Page
                </Button>
              </div>
            </Col>
          </Col>
        </Col>
        <Col xs={12} md={{ span: 6, offset: 1 }}>
          <Row>
            <Col md={5}>
              <div className='form-group'>
                <ReactSelect
                  id='parentSearch'
                  name='parentSearch'
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Filter Page Actions'
                  isMulti={false}
                  value={searchParent}
                  isClearable={true}
                  onChange={(res) => setSearchParent(res)}
                  options={pagesList}
                ></ReactSelect>
              </div>
            </Col>
          </Row>
          <Row>
            <CustomDataTable
              data={
                searchParent != null ? filteredPages : props.PageActions ?? []
              }
              columns={pagesColumns}
              getTrProps={null}
            />
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    pages: state.Pages,
    PageActions: state.PageActions,
  }
}

export default connect(mapStateToProps, {
  addPageAction,
  deletePageAction,
  getPageActions,
  getPageAndActionsList,
})(PageActions)
