import { useState } from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from 'react-table'
import ServerSideGlobalFilter from './ServerSideGlobalFilter'

const ServerSideDataTable = ({
  searchChanged,
  pageNo,
  pageSizeChange,
  myIndex,
  myPageSize,
  totalRecords,
  goToPreviousPage,
  goToNextPage,
  columns,
  data,
  getTrProps,
  IsPagingEnable = true,
  PageTableName = '',
  searchLabel = '',
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state, // new
    preGlobalFilteredRows, // new
    setGlobalFilter, // new
  } = useTable(
    {
      searchChanged,
      myIndex,
      pageNo,
      pageSizeChange,
      myPageSize,
      totalRecords,
      goToPreviousPage,
      goToNextPage,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // new
    useGlobalFilter, // new
    useSortBy,
    usePagination
  )

  const checkLastPage = () => {
    if (Math.floor(totalRecords % myPageSize) == 0) {
      if (pageNo + 1 < Math.floor(totalRecords / myPageSize)) {
        goToNextPage(pageNo + 1, myPageSize)
      }
    } else {
      if (pageNo < Math.floor(totalRecords / myPageSize)) {
        goToNextPage(pageNo + 1, myPageSize)
      }
    }
  }

  const checkRowColor = (type, data) => {
    if (type == 'DistributorInbox') {
      if (data.original.notificationVisited == false) {
        return '#f6bca7'
      }
    }

    return ''
  }

  return (
    <div
      style={{ overflow: 'hidden', overflowX: 'scroll', minHeight: '350px' }}
    >
      <ServerSideGlobalFilter
        totalRecords={totalRecords}
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        searchChanged={searchChanged}
      />
      <span style={{ float: 'right', margin: '8px' }}>{searchLabel}</span>
      {/* new */}
      {headerGroups.map((headerGroup) =>
        headerGroup.headers.map((column) =>
          column.Filter ? (
            <div key={column.id}>
              <label for={column.id}>{column.render('Header')}: </label>
              {column.render('Filter')}
            </div>
          ) : null
        )
      )}
      <table
        className='table striped bordered hover'
        {...getTableProps()}
        style={{ minHeight: '330pxs' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ Height: 'auto' }}>
          {page && page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  style={{
                    cursor: 'pointer',
                    background: checkRowColor(PageTableName, row),
                  }}
                  {...row.getRowProps()}
                  className='table-hover-class'
                  key={Math.random().toString(36).substring(2, 9)}
                  onDoubleClick={(event) => getTrProps(event, row?.original)}
                >
                  {row.cells.map((cell, j) => {
                    return (
                      <>
                        <td
                          {...cell.getCellProps()}
                          key={Math.random().toString(36).substring(2, 9)}
                        >
                          {cell.render('Cell')}
                        </td>
                      </>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <tr>
              <td
                colSpan={headerGroups[0].headers.length}
                style={{ textAlign: 'center' }}
              >
                No record found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {IsPagingEnable === true ? (
        <ul className='pagination float-right pagination-scroll'>
          <select
            className='form-control'
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
              pageSizeChange(Number(e.target.value))
            }}
            style={{ width: '120px', height: '38px' }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize, index) => (
              <option
                key={Math.random().toString(36).substring(2, 9)}
                value={pageSize}
              >
                Show {pageSize}
              </option>
            ))}
          </select>
          <li
            className='page-item pointer'
            onClick={pageNo == 0 ? null : () => goToPreviousPage(0, pageSize)}
            disabled={!canPreviousPage}
          >
            <a className='page-link'>&laquo;</a>
          </li>
          <li
            className='page-item pointer'
            onClick={
              pageNo == 0
                ? null
                : () => goToPreviousPage(pageNo - 1, myPageSize)
            }
            disabled={!canPreviousPage}
          >
            <a className='page-link'>&lt;</a>
          </li>
          <li
            className='page-item pointer'
            onClick={checkLastPage}
            disabled={!canNextPage}
          >
            <a className='page-link'>&gt;</a>
          </li>
          <li
            className='page-item pointer'
            onClick={
              pageNo > Math.floor(totalRecords / myPageSize) - 1
                ? null
                : () => {
                    goToNextPage(
                      Math.floor(totalRecords % myPageSize) == 0
                        ? Math.floor(totalRecords / myPageSize) - 1
                        : Math.floor(totalRecords / myPageSize),
                      myPageSize
                    )
                    //checkLastPage()
                  }
            }
            disabled={!canNextPage}
          >
            <a className='page-link'>&raquo;</a>
          </li>
          <li>
            <a className='page-link' style={{ width: '125px' }}>
              Page
              <strong>
                {' '}
                {pageNo + 1} of{' '}
                {totalRecords == null || totalRecords == 0
                  ? 1
                  : Math.ceil(totalRecords / myPageSize)}
              </strong>
            </a>
          </li>
          <li>
            <a className='page-link'>
              <input
                className='form-control'
                type='number'
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  if (page < 0) return
                  goToNextPage(page, myPageSize)
                }}
                style={{ width: '100px', height: '20px' }}
              />
            </a>
          </li>
        </ul>
      ) : (
        <></>
      )}
    </div>
  )
}
export default ServerSideDataTable
