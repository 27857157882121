import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import storage from '../../../apis/Storage'
import { GET_DLM_COLLECTION_REPORT } from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as XLSX from 'xlsx'
import fileSaver from 'file-saver'

export const getDataListing = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Reports/GetDLMCollectionReport`, postedData)
    .then(({ data }) => {
      dispatch({
        type: GET_DLM_COLLECTION_REPORT,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const downloadJob = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Reports/GetDLMCollectionReport`, requestData)
    .then(({ data }) => {
      const outputFilename = `Deliveryman_Wise_Collection_Report${Date.now()}`
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const exportToCSV = (csvData, jobNumber, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData)
        if (ws) {
          var range = XLSX.utils.decode_range(ws['!ref'])
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
            if (!ws[address]) continue
            ws[address].v =
              ws[address].v == 'date'
                ? 'Date'
                : ws[address].v == 'distCenter'
                ? 'Dist Center'
                : ws[address].v == 'vlTillId'
                ? 'VL Till Id'
                : ws[address].v == 'deliverymanName'
                ? 'DeliverymanName'
                : ws[address].v == 'invoiceNo'
                ? 'Invoice No.'
                : ws[address].v == 'invoiceValue'
                ? 'Invoice Value'
                : ws[address].v == 'totalPayment'
                ? 'Total Payment'
                : ws[address].v == 'walletPayment'
                ? 'Digital Payment(EP + Jazz)'
                : ws[address].v == 'walletPaymentEP'
                ? 'Easypaisa Payment'
                : ws[address].v == 'walletPaymentJC'
                ? 'Jazzcash Payment'
                : ws[address].v == 'lendingPayment'
                ? 'Lending Payment (FinjaLoan)'
                : ws[address].v == 'coinsAvailed'
                ? 'Coins Availed (ValueInCash)'
                : ws[address].v == 'cashRecorded'
                ? 'Cash Recorded'
                : ws[address].v
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const fileData = new Blob([excelBuffer], { type: fileType })
        fileSaver.saveAs(fileData, fileName + fileExtension)
      }
      exportToCSV(data?.response?.data, '', outputFilename)
      dispatch(hideLoading())
    })

    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
