import {
  GET_JOB_DETAILS,
  UPDATE_JOB_DETAILS,
  SEARCH_VIZLINK_SHOPS,
  EMPTY_VIZLINK_SHOPS,
  LINK_DIST_SHOP_TO_VIZ_SHOP,
  UNLINK_DIST_SHOP_TO_VIZ_SHOP,
} from '../../../../Types'
// import _ from 'lodash'

const JobDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_JOB_DETAILS:
      return { ...state, ...action.payload, vizlinkShops: [] }
    case UPDATE_JOB_DETAILS:
      var index = state.distributorShops.findIndex(
        (item) => item.id === action.payload.id
      ) //finding index of the item
      var newState = [...state.distributorShops] //making a new array
      var rsoName = newState[index].rsoName
      newState[index] = action.payload //changing value in the new array
      newState[index].rsoName = rsoName
      return { ...state, distributorShops: newState } //reassingning todos to new array
    case SEARCH_VIZLINK_SHOPS:
      return { ...state, vizlinkShops: action.payload }
    case EMPTY_VIZLINK_SHOPS:
      return { ...state, vizlinkShops: [] }
    case UNLINK_DIST_SHOP_TO_VIZ_SHOP:
      var index = state.distributorShops.findIndex(
        (item) => item.id === action.payload.distShopId
      ) //finding index of the item
      var newState = [...state.distributorShops] //making a new array
      newState[index].vizShopId = '' //changing value in the new array
      newState[index].inductionStatus = 'Not Linked' //changing value in the new array
      // newState[index].inductionMatchingStatus = action.payload.matchingStatus //changing value in the new array
      return { ...state, distributorShops: newState, vizlinkShops: [] }
    case LINK_DIST_SHOP_TO_VIZ_SHOP:
      var index = state.distributorShops.findIndex(
        (item) => item.id === action.payload.distShopId
      ) //finding index of the item
      var newState = [...state.distributorShops] //making a new array
      newState[index].vizShopId = action.payload.vizShopId //changing value in the new array
      newState[index].inductionStatus = 'Linked' //changing value in the new array
      // newState[index].inductionMatchingStatus = 'Matched' //changing value in the new array
      return { ...state, distributorShops: newState, vizlinkShops: [] }
    default:
      return state
  }
}
export default JobDetailsReducer
