import { GET_SHOPS_DETAIL_REPORT } from '../../../Types'

const ShopsDetailReportReducer = (state = [], action) => {
    switch (action.type) {
        case GET_SHOPS_DETAIL_REPORT:
            return action.payload
        default:
            return state
    }
}
export default ShopsDetailReportReducer