import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import FormikControl from '../../../layouts/Input/FormikControl'
import _ from 'lodash'
// import validater from 'validator'
import { toast } from 'react-toastify'
import {
  addDistributionCenters,
  getDistributionCenters,
  updateDistributionCenters,
  deleteDistributionCenters,
  getDistributionCentersList,
} from './DistributionCentersAction'

const ManageDistributionCenters = (props) => {
  const { id } = useParams()
  const initialValues = {
    id: '',
    distributorId: props.distributorMainInfo.id,
    distCenterName: '',
    numberofShops: 0,
    distCenterAddress: '',
    DistPrincipal: '',
    DistCenterEmails: [],
    DistCenterContactNumbers: [],
    CityValue: '',
  }
  const [currentDistributionCenter, setCurrentDistributionCenter] =
    useState(initialValues)
  const [modalShow, setModalShow] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [DistPrincipal, setDistPrincipal] = useState('')
  const [DistCenterEmails, setDistCenterEmails] = useState([])
  const [DistCenterContactNumbers, setDistCenterContactNumbers] = useState([])
  const [CityValue, setCityValue] = useState(null)

  const citiesOptions = [
    { value: 'Lahore', label: 'Lahore' },
    { value: 'Karachi', label: 'Karachi' },
    { value: 'Islamabad', label: 'Islamabad' },
  ]

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    distCenterName: Yup.string().required(
      'Distribution center name is required'
    ),
    numberofShops: Yup.number()
      .typeError('you must specify a number')
      .min(1, 'Min. required value is 1.')
      .max(50000, 'Max. required value is 50000.'),
    distCenterAddress: Yup.string().required(
      'Distribution center address is required'
    ),
    CityValue: Yup.string().required('City is required').nullable(),
    DistPrincipal: Yup.string().required('Principal is required').nullable(),
    DistCenterEmails: Yup.array().max(5, 'You can add maximum 5 E-mails'), // .min(1, 'Please add atleast 1 E-mail')
    DistCenterContactNumbers: Yup.array().max(
      5,
      'You can add maximum 5 Contacts'
    ), ///.min(1,"Please add minimum 1 Contact")
  })
  const columns = [
    {
      Header: 'Distribution Center',
      accessor: 'distCenterName',
    },
    {
      Header: 'Principal',
      accessor: 'principalName',
    },
    {
      Header: 'No. of Shops',
      accessor: 'numberofShops',
    },
    {
      Header: 'Contact No.',
      accessor: 'distCenterContactNumbers',
    },
    {
      Header: 'Dist. Center E-mail',
      accessor: 'distCenterEmails',
    },
    {
      Header: 'Dist. Center Address',
      accessor: 'distCenterAddress',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'Action',
      id: 'edit',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          <button onClick={() => View(row.value)} className=' btn btn-sm'>
            <i className='fas fa-eye'></i>
          </button>
          &nbsp;
          <button onClick={() => Edit(row.value)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button>
          &nbsp;
          <button onClick={() => Delete(row.value)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]
  useEffect(() => {
    if (id) {
      props.getDistributionCentersList(id)
    }
  }, [])
  const options = props.distributorPrincipals.map((p) => ({
    value: props.Principals.find((item) => item.id === p.principalId)?.id,
    label: props.Principals.find((item) => item.id === p.principalId)
      ?.principalName,
  }))

  const View = (id) => {
    let tempObj = props.distributionCenters.find((detail) => detail.id === id)
    if (tempObj === null) {
      toast.warn('No record found.')
      return
    }
    var selectedPrincipal = props.Principals.find(
      (detail) => detail.id === tempObj.principalId
    )
    setCityValue({
      label: tempObj.city,
      value: tempObj.city,
    })
    setDistPrincipal({
      label: selectedPrincipal?.principalName,
      value: selectedPrincipal?.principalId
        ? selectedPrincipal?.principalId
        : selectedPrincipal?.id,
    })
    let distCenterEmailsList = []
    if (tempObj.distCenterEmails) {
      tempObj.distCenterEmails.split(',').forEach((element) => {
        distCenterEmailsList.push({
          label: element,
          value: element,
        })
      })
    }
    setDistCenterEmails(distCenterEmailsList)
    let distCenterContactNumbersList = []
    if (tempObj.distCenterContactNumbers) {
      tempObj.distCenterContactNumbers.split(',').forEach((element) => {
        distCenterContactNumbersList.push({
          label: element,
          value: element,
        })
      })
    }
    setDistCenterContactNumbers(distCenterContactNumbersList)

    setCurrentDistributionCenter({
      ...tempObj,
      DistCenterEmails: distCenterEmailsList,
      DistCenterContactNumbers: distCenterContactNumbersList,
      DistPrincipal: DistPrincipal,
      CityValue: CityValue,
    })
    setModalShow(true)
    setReadOnly(true)
  }

  const Delete = (id) => {
    if (
      props.mfiDetails.filter((item) => item.distCentersArray.includes(id))
        .length > 0 ||
      props.qrOrTillDetails.filter((item) => item.distCenterId === id).length >
        0
    ) {
      toast.warn(
        'First you have to delete MFIs or QR/Till Records against this Distribution Center'
      )
      return
    }
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    props.deleteDistributionCenters(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }
  const Edit = (id) => {
    let tempObj = props.distributionCenters.find((detail) => detail.id === id)
    if (tempObj === null) {
      toast.warn('selected record not found in our system.')
      return
    }
    let selectedPrincipal = props.Principals.find(
      (detail) => detail.id === tempObj.principalId
    )
    if (selectedPrincipal)
      setDistPrincipal({
        label: tempObj.principalName.trim(),
        value: tempObj.principalId.trim(),
      })
    if (tempObj.city)
      setCityValue({
        label: tempObj.city,
        value: tempObj.city,
      })
    let distCenterEmailsList = []
    if (tempObj.distCenterEmails) {
      tempObj.distCenterEmails.split(',').forEach((element) => {
        distCenterEmailsList.push({
          label: element.trim(),
          value: element.trim(),
        })
      })
      setDistCenterEmails(distCenterEmailsList)
    }
    let distCenterContactNumbersList = []

    if (tempObj.distCenterContactNumbers) {
      tempObj.distCenterContactNumbers.split(',').forEach((element) => {
        distCenterContactNumbersList.push({
          label: element.trim(),
          value: element.trim(),
        })
      })
      setDistCenterContactNumbers(distCenterContactNumbersList)
    }
    setCurrentDistributionCenter({
      ...tempObj,
      DistCenterEmails: distCenterEmailsList,
      DistCenterContactNumbers: distCenterContactNumbersList,
      DistPrincipal: tempObj.principalName.trim(),
      CityValue: tempObj.city,
    })
    setReadOnly(false)
    setModalShow(true)
    setIsEdit(true)
  }

  const OpenModelForAddDistributionCenters = () => {
    setReadOnly(false)
    setDistPrincipal(null)
    setCityValue(null)
    setDistCenterEmails([])
    setDistCenterContactNumbers([])
    setCurrentDistributionCenter({ ...initialValues })
    setModalShow(true)
    setIsEdit(false)
  }

  const handleInputChange = (field, selectedData) => {
    switch (field) {
      case 'DistCenterContacts':
        setDistCenterContactNumbers(selectedData)
        currentDistributionCenter.DistCenterContactNumbers = selectedData
        break
      case 'DistCenterEmails':
        setDistCenterEmails(selectedData)
        currentDistributionCenter.DistCenterEmails = selectedData
        break
      default:
        break
    }
  }
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }
  const handleSubmit = (data) => {
    if (!props.distributorMainInfo.id) {
      toast.warn('First you have to add distributor main informations.')
      return
    }
    if (!data.id) {
      let checkIsExist = props.distributionCenters.filter(
        (item) =>
          item.distCenterName.toUpperCase() ===
            data.distCenterName.toUpperCase() ||
          item.distCenterName.replace(/ /g, '').trim().toUpperCase() ===
            data.distCenterName.replace(/ /g, '').trim().toUpperCase()
      )

      if (checkIsExist && checkIsExist.length > 0) {
        toast.warn('Distribution Center detail already added')
        return
      }
      props.addDistributionCenters({
        distributorId: props?.distributorMainInfo?.id, //data.distributorId,
        distCenterName: data.distCenterName,
        numberofShops: parseInt(data.numberofShops),
        distCenterAddress: data.distCenterAddress,
        principalId: DistPrincipal.value,
        city: CityValue.value,
        distCenterEmails: _.map(DistCenterEmails, 'label').join(', '),
        distCenterContactNumbers: _.map(DistCenterContactNumbers, 'label').join(
          ', '
        ),
      })
    } else {
      let checkIsExist = props.distributionCenters.filter(
        (item) =>
          (item.distCenterName.toUpperCase() ===
            data.distCenterName.toUpperCase() ||
            item.distCenterName.replace(/ /g, '').trim().toUpperCase() ===
              data.distCenterName.replace(/ /g, '').trim().toUpperCase()) &&
          item.id !== data.id
      )

      if (checkIsExist && checkIsExist.length > 0) {
        toast.warn('Distribution Center detail already added')
        return
      }
      props.updateDistributionCenters(data.id, {
        id: data.id,
        distributorId: props.distributorMainInfo.id,
        distCenterName: data.distCenterName,
        numberofShops: parseInt(data.numberofShops),
        distCenterAddress: data.distCenterAddress,
        principalId: DistPrincipal.value,
        city: CityValue.value,
        distCenterEmails: _.map(DistCenterEmails, 'label').join(', '),
        distCenterContactNumbers: _.map(DistCenterContactNumbers, 'label').join(
          ', '
        ),
      })
    }
    setModalShow(false)
  }
  return (
    <div>
      <Row>
        <Col xs={12} md={12}>
          <Button
            className='form-btn pull-right'
            variant='primary'
            style={{ float: 'right' }}
            onClick={OpenModelForAddDistributionCenters}
          >
            <i className='fa fa-plus' /> Add Distribution Center
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          {props.distributionCenters.length > 0 ? (
            <CustomDataTable
              data={props.distributionCenters}
              columns={columns}
              getTrProps={() => null}
            />
          ) : (
            <h4
              style={{
                display: 'flex',
                marginTop: '50px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              No Distribution Center added yet
            </h4>
          )}
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Formik
          enableReinitialize
          initialValues={currentDistributionCenter}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form autoComplete='off' onKeyDown={onKeyDown}>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Distribution Center Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='text'
                          label='Distribution Center Name'
                          readOnly={readOnly}
                          name='distCenterName'
                          handleOnChange={(e) => {
                            currentDistributionCenter.distCenterName = e
                          }}
                          value={currentDistributionCenter.distCenterName}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          control='input'
                          type='number'
                          label='No. of Shops'
                          readOnly={readOnly}
                          name='numberofShops'
                          handleOnChange={(value) => {
                            currentDistributionCenter.numberofShops = value
                          }}
                          value={currentDistributionCenter.numberofShops}
                          formik={formik}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='text'
                          label='Distribution Center Address'
                          readOnly={readOnly}
                          name='distCenterAddress'
                          handleOnChange={(e) => {
                            currentDistributionCenter.distCenterAddress = e
                          }}
                          value={currentDistributionCenter.distCenterAddress}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          readOnly={readOnly}
                          control='react-select'
                          label='City'
                          name='CityValue'
                          options={props.cities}
                          isSearchable={false}
                          handleOnChange={(e) => {
                            if (!e || !e.value) {
                              return
                            }
                            setCityValue({ label: e.value, value: e.value })
                            currentDistributionCenter.CityValue = e.value
                          }}
                          value={CityValue}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          readOnly={readOnly}
                          control='react-select'
                          type='DistPrincipal'
                          label='Principal'
                          name='DistPrincipal'
                          value={DistPrincipal}
                          options={options}
                          handleOnChange={(value) => {
                            setDistPrincipal(value)
                            currentDistributionCenter.DistPrincipal =
                              value.value
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          disabled={readOnly}
                          control='react-creatable-noOption'
                          type='emails'
                          label='Distribution Center E-mail'
                          name='DistCenterEmails'
                          value={currentDistributionCenter.DistCenterEmails}
                          handleValueChange={(value) => {
                            handleInputChange('DistCenterEmails', value)
                          }}
                          options={[]}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          disabled={readOnly}
                          control='react-creatable-noOption'
                          type='contacts'
                          label='Distribution Center Contact No.'
                          name='DistCenterContactNumbers'
                          value={DistCenterContactNumbers}
                          handleValueChange={(value) => {
                            handleInputChange('DistCenterContacts', value)
                          }}
                          options={[]}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                {readOnly === false ? (
                  <>
                    <input
                      type='submit'
                      className='btn btn-primary'
                      value={
                        isEdit === false
                          ? 'Add Center'
                          : 'Update Center Details'
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
                <Button
                  className='btn btn-default'
                  onClick={() => setModalShow(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo
      ? state.distributorMainInfo
      : {},
    distributionCenters: state.distributionCenters
      ? state.distributionCenters
      : [],
    distributorPrincipals: state.distributorPrincipals
      ? state.distributorPrincipals
      : [],
    Principals: state.Principals ? state.Principals : [],
    mfiDetails: state.mfiDetails ? state.mfiDetails : [],
    qrOrTillDetails: state.qrOrTillDetails ? state.qrOrTillDetails : [],
    cities: state.ddlList.cities ? state.ddlList.cities : [],
  }
}
export default connect(mapStateToProps, {
  addDistributionCenters,
  getDistributionCenters,
  updateDistributionCenters,
  deleteDistributionCenters,
  getDistributionCentersList,
})(ManageDistributionCenters)
