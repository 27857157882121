import React, { Component } from 'react'
import { Image, NavItem, NavLink } from 'react-bootstrap'
import profileImg from '../../assets/Images/User-profile.png'

import { connect } from 'react-redux'
import { signOut } from '../../components/Login/AuthAction'
import Socket from '../Socket/Socket'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { showNavbar: true }
  }
  handleLogout = () => {
    this.props.signOut()
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.showNavbar !== this.state.showNavbar) {
      // this.setState({ showNavbar: nextProps.showNavbar })
      // hamburger.click()
    }

    if (nextProps.showNavbar == true) {
      document.body.classList.add('sidebar-open')
      document.body.classList.remove('sidebar-collapse')
      document.body.classList.remove('sidebar-closed')
    }
    if (nextProps.showNavbar == false) {
      document.body.classList.remove('sidebar-open')
      document.body.classList.add('sidebar-collapse')
      document.body.classList.add('sidebar-closed')
    }
  }

  render() {
    return (
      <>
        <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
          <ul className='navbar-nav'>
            <NavItem className='nav-item'>
              <NavLink
                data-widget='pushmenu'
                href={void 0}
                role='button'
                id='pushmenu'
                onClick={this.props.onNavToggle}
                // onClick={() => {
                //   var hamburger = document.getElementById('pushmenu')
                //   hamburger.click()
                //   this.props.onNavToggle()
                // }}
              >
                <i className='fas fa-bars'></i>
              </NavLink>
            </NavItem>
          </ul>

          <ul className='navbar-nav ml-auto'>
            <li className='nav-item dropdown'>
              <NavLink data-toggle='dropdown' href={void 0}>
                <i className='far fa-bell'></i>
                <span className='badge badge-warning navbar-badge'></span>
              </NavLink>
              <div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
                <span className='dropdown-item dropdown-header'>
                  Notifications
                </span>
                <div className='dropdown-divider'></div>
                <NavLink href={void 0} className='dropdown-item'>
                  <i className='fas fa-envelope mr-2'></i> No new message
                  <span className='float-right text-muted text-sm'>0 mins</span>
                </NavLink>

                <div className='dropdown-divider'></div>
                <NavLink
                  href={void 0}
                  className='dropdown-item dropdown-footer'
                >
                  See All Notifications
                </NavLink>
              </div>
            </li>
            <li className='nav-item dropdown profile-info'>
              <NavLink
                data-toggle='dropdown'
                href={void 0}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Image
                  style={{ width: '32px' }}
                  src={profileImg}
                  className='profile-image img-circle elevation-1'
                  alt='Raheel Riaz Profile Picture'
                />
                <span className='profile-Name'>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      // justifyContent: 'space-around',
                    }}
                  >
                    <span>{localStorage.getItem('_userFullName')}</span>
                    <span style={{ fontSize: '11px', marginTop: '-4px' }}>
                      {localStorage.getItem('_appUserRole')}
                    </span>
                  </span>
                </span>
                <i className='right fas fa-angle-left'></i>
              </NavLink>
              <div className='dropdown-menu dropdown-menu-lg dropdown-menu-right'>
                <span className='dropdown-item dropdown-header'>
                  User Profile
                </span>
                <div className='dropdown-divider'></div>
                <NavLink href={void 0} className='dropdown-item'>
                  <i className='fas fa-user mr-2'></i> Profile
                  <span className='float-right text-muted text-sm'></span>
                </NavLink>

                <div className='dropdown-divider'></div>
                <NavLink
                  href={void 0}
                  onClick={this.handleLogout}
                  className='dropdown-item dropdown-footer'
                >
                  Logout
                </NavLink>
              </div>
            </li>
          </ul>
        </nav>
        <Socket />
      </>
    )
  }
}

export default connect(null, { signOut })(Header)
