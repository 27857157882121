import React, { Component, useEffect, useState, useRef } from 'react'
import Moment from 'react-moment'

import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { connect } from 'react-redux'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { Formik, Form } from 'formik'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Link, Redirect } from 'react-router-dom'
import {
  getDistributorCoinsPaymentReport,
  exportReport,
} from './DistributorCoinsPaymentReportActions'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../apis/Storage'
import { toast } from 'react-toastify'
import { isPermittedPage } from '../../../apis/Utility'

const DistributorCoinsPaymentReport = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionID, setFilterDistributionID] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(new Date())
  const [filterToDate, setFilterToDate] = useState(new Date())
  const [filterDistributionCenterId, setFilterDistributionCenterId] =
  useState(null)
  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [maxToDate, setMaxToDate] = useState(new Date())

  const [filterValuesModal, setFilterValuesModal] = useState({
    distributorName: '',
    distributionID: '',
    fromDate: new Date(),
    toDate: new Date(),
  })

  const columns = [
    {
      Header: 'Distributor',
      accessor: 'distributorName',
    },
    {
      Header: 'Distribution ID',
      accessor: 'distributionCenterName',
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Invoice Amount',
      accessor: 'invoiceTotals',
    },
    {
      Header: 'Total Digital Payment (Jazz+EP)',
      accessor: 'walletPayments',
    },
    {
      Header: 'Total Coins Redeemed (Rupees)',
      accessor: 'coins',
    },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionID(null)
    setFilterDistributionCenterId(null)
    setFilterFromDate(new Date())
    setFilterToDate(new Date())

    setFilterValuesModal({
      distributorName: '',
      distributionID: '',
      fromDate: new Date(),
      toDate: new Date(),
    })
  }

  const getData = (page) => {
    if (checkDateRange()) {
      setCurrentPageNo(page)
      var request = { ...formRequestObject(), pageNo: page }
      fetchReportData(request)
    }
  }

  const pageSizeChanged = (pageSize) => {
    if (checkDateRange()) {
      setCurrentPageNo(0)
      setCurrentPageSize(pageSize)
      var request = { ...formRequestObject(), pageSize: pageSize }
      fetchReportData(request)
    }
  }

  const searchChanged = (text) => {
    if (checkDateRange()) {
      setCurrentPageNo(0)
      setSearchText(text)
    }
  }

  const LoadDataByfilter = (event) => {
    if (checkDateRange()) {
      setCurrentPageNo(0)
      var request = { ...formRequestObject(), pageNo: 0 }
      fetchReportData(request)
      setShow(false)
    }
  }

  const checkDateRange = () => {
    let Difference_In_Time = filterFromDate.getTime() - filterToDate.getTime()
    let Difference_In_Days = Math.abs(Difference_In_Time / (1000 * 3600 * 24))
    if (Difference_In_Days > 15) {
      toast.error('Select date range of max 15 days')

      return false
    }

    return true
  }

  const Download = () => {
    let request = formRequestObject(true)
    props.exportReport(request)
  }

  function formRequestObject(isExport = false) {
   // alert(JSON.stringify(filterDistributionID));
    let requestData = {
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
        distributionCenterId:
        filterDistributionCenterId && filterDistributionCenterId.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    return requestData
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getDistributorCoinsPaymentReport(finalRequest)
  }

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddJobDistCenterOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistCenterOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  useEffect(() => {
    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData()
  }, [searchText])

  useEffect(() => {
    if (props.DistributorCoinsPaymentReport) {
      setTotalRecords(props.DistributorCoinsPaymentReport.totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.DistributorCoinsPaymentReport])

  return (
    <>
      <div>
        <VzPageContainer title='Distributor Coins Payment Report'>
          <Row>
            <Col style={{ float: 'left' }}>
              <h4>
                <b>Distributor Coins Payment Report</b>
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={10} style={{ float: 'right' }}>
              <div ref={ref}>
                <Button
                  onClick={openFilterModal}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-filter' />
                  Filters
                </Button>

                <Overlay
                  show={show}
                  target={target}
                  placement='bottom'
                  container={ref}
                  containerPadding={20}
                  width='430px'
                  style={{ width: '430px' }}
                >
                  <Popover
                    id='popover-contained'
                    style={{ maxWidth: '360px', width: '360px' }}
                  >
                    <Popover.Header as='h3'>Filters</Popover.Header>
                    <Popover.Body>
                      <Formik
                        enableReinitialize
                        initialValues={filterValuesModal}
                        validator={() => ({})}
                        onSubmit={(e) => LoadDataByfilter(e)}
                      >
                        {(formik) => (
                          <Form autoComplete='off'>
                            <Row>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor'
                                  name='distributorName'
                                  options={addJobDistributorsOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributorName = e.value
                                    setFilterDistributorName({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    setFilterDistributionID({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributorName}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Center'
                                  name='distributionID'
                                  options={addJobDistCenterOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributionCenterId(null)
                                      return
                                    }
                                    filterValuesModal.distributionCenterId =
                                      e.value
                                    setFilterDistributionCenterId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionCenterId}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                  maxDate={maxToDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  maxDate={maxToDate}
                                />
                              </Col>
                            </Row>
                            <div className='row' style={{ marginTop: 20 }}>
                              <div className='form-group'>
                                <Link
                                  onClick={handleResetFilters.bind(
                                    null,
                                    formik.resetForm
                                  )}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'left',
                                  }}
                                >
                                  Reset
                                </Link>
                                <Link
                                  onClick={() => {
                                    setShow(false)
                                    setTarget(null)
                                  }}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Cancel
                                </Link>
                                <div className='float-right'>
                                  <Button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{
                                      marginRight: '10px',
                                      float: 'right',
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </Col>
            {isPermittedPage('Export', props.actions, props.userRole) &&
              <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={Download}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-download me-1' />
                  Export
                </Button>
              </Col>}
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <ServerSideDataTable
                data={props.DistributorCoinsPaymentReport?.report ?? []}
                columns={columns}
                getTrProps={(event, data) => null}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={totalRecords}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                myIndex={currentIndex}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
            </Col>
          </Row>
        </VzPageContainer>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],

    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    DistributorCoinsPaymentReport: state.DistributorCoinsPaymentReport
      ? state.DistributorCoinsPaymentReport
      : [],
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  getDistributorCoinsPaymentReport,
  exportReport,
})(DistributorCoinsPaymentReport)
