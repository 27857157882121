import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  ADD_VLP_SCHEME,
  GET_VLP_SCHEMES,
  UPDATE_VLP_SCHEME,
  DELETE_VLP_SCHEME,
} from '../../../Types'

export const addVlpScheme = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/VLPSchemes', formValues)
    .then(({ data }) => {
      if (data.response)
        dispatch({
          type: ADD_VLP_SCHEME,
          payload: data.response,
        })
      dispatch(hideLoading())
      toast.success('Scheme created successfully')
      return data.response
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const getVlpSchemes = () => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get('api/VLPSchemes')
    .then(({ data }) => {
      if (data)
        dispatch({
          type: GET_VLP_SCHEMES,
          payload: data.response ? data.response : data,
        })
      dispatch(hideLoading())
      // toast.success('Scheme created successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const updateVlpScheme = (id, formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .put(`api/VLPSchemes/${id}`, formValues)
    .then(({ data }) => {
      dispatch(hideLoading())
      dispatch({
        type: UPDATE_VLP_SCHEME,
        payload: formValues,
      })
      toast.success('Record updated successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const deleteScheme = (id) => async (dispatch) => {
  if (!id) return
  dispatch(showLoading())
  await axios
    .delete(`api/VLPSchemes/SoftDelete/${id}`)
    .then(({ data }) => {
      dispatch(hideLoading())
      if (data.isSuccess) {
        dispatch({ type: DELETE_VLP_SCHEME, payload: id })
        toast.success(data.message)
      }
      else {
        toast.error(data.message)
      }
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response)
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
    })
}
