import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
  Accordion,
} from 'react-bootstrap'
//import { Clipboard } from 'react-bootstrap-icons'
import { IoIosCopy } from 'react-icons/io'
import { BiSolidDownArrowSquare, BiSolidUpArrowSquare } from 'react-icons/bi'

import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form, Field, setIn, useFormikContext, FastField } from 'formik'
import Moment from 'react-moment'
import FormikControl from '../../../layouts/Input/FormikControl'
import { VzPageContainer } from '../../../layouts/VzPageContainer' //'../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import {
  getVLCCommunicationListing,
  downloadJob,
  initiateCommunication,
  getDistCentersList,
  GetTicketDetails,
  parseCommunication,
} from './VLCCommunicationListingAction'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_COMMUNICATION_LISTING,
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  GET_VLC_TICKET_CATEGORIES_DDL,
  GET_VIZ_IDS_DDL,
} from '../../../Types'
import axios from '../../../apis/Api'
import storage from '../../../apis/Storage'
import * as Yup from 'yup'
import AudioPlayer from '../../../layouts/Input/AudioPlayer'
import { toast } from 'react-toastify'
import { func } from 'prop-types'
import { isPermittedPage } from '../../../apis/Utility'


const VLCCommunicationListing = (props) => {
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [filterVizId, setFilterVizId] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(null)
  const [filterToDate, setFilterToDate] = useState(null)
  const [filterDistributorId, setFilterDistributorId] = useState(null)
  const [filterDistributionCenterId, setFilterDistributionCenterId] =
    useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  const [addJobDistributorsOptions, setAddJobDistributorsOptions] = useState([])
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])
  const [commListModalShow, setCommListModalShow] = useState(false)
  const [vizIdsDDLList, setVizIdsDDLList] = useState(null)

  //modal initiate communication states
  const [showParseSection, setShowParseSection] = useState(false)
  const [modalDistCentersList, setModalDistCentersList] = useState([])
  const formikRef = useRef(null)

  const [formDataSaveType, setFormDataSaveType] = useState(4) // 4 : Proceed
  const [viewOnlyAccess, setViewOnlyAccess] = useState(false)
  const [formSubmitSucceeded, setFormSubmitSucceeded] = useState(false)
  const [isConsolidated, setIsConsolidated] = useState(false)

  const [initialValues, setInitialValues] = useState({
    id: null,
    distributorId: null,
    distCenterId: null,
    vizId: null,
    notUsingAppReasonType: null,
    notUsingAppReason: null,
    textDetails: null,
    infoText: null,
    ordersText: null,
    complaintsText: null,
    othersText: null,
    initiateType: 2, // 1: In-App, 2: On call
    status: 1, //InComplete = 1, Allocated = 2, InProgress = 3, Resolved = 4
    initiateCommunicationDataSaveType: 1, // 1 : default, 2 : parsedAndSaved, 3: SavedOnly, 4: Proceed
  })
  const initiateCom_validationSchema = Yup.object().shape({
    distributorId: Yup.object().required('Distributor is requied').nullable(),
    distCenterId: Yup.object().required('Dist center is required').nullable(),
    vizId: Yup.object().required('Viz id is requied').nullable(),
    notUsingAppReasonType: Yup.object()
      .required('Reason type is required')
      .nullable(),
    textDetails: Yup.string().required('Details are requried').nullable(),
  })

  const [filterValuesModal, setFilterValuesModal] = useState({
    vizId: '',
    fromDate: null,
    toDate: null,
    distributorId: '',
    distCenterId: '',
    status: '',
  })

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const userRights = JSON.parse(storage.get('_userRights'))

  const Columns = [
    {
      Header: 'Medium',
      accessor: 'medium',
    },
    {
      Header: 'Shop Id',
      accessor: 'shopId',
    },
    {
      Header: 'Distributor Name',
      accessor: 'distName',
    },
    {
      Header: 'Distribution Center',
      accessor: 'distCenter',
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY HH:mm:ss'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Action',
      id: 'edit',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          {isPermittedPage('View Details', props.actions, props.userRole) &&
            <button onClick={() => View(row.value)} className=' btn btn-sm'>
              <i className='fas fa-eye'></i>
            </button>
          }
          &nbsp;
          {isPermittedPage('Edit', props.actions, props.userRole) &&
            <button onClick={() => Edit(row.value)} className='btn btn-sm'>
              <i className='fas fa-edit'></i>
            </button>
          }
        </div>
      ),
    },
  ]

  const ticketStatusList = [
    {
      label: 'In Complete',
      value: 1,
    },
    {
      label: 'Allocated',
      value: 2,
    },
    {
      label: 'In Progress',
      value: 3,
    },
    {
      label: 'Resolved',
      value: 4,
    },
  ]

  const notUsingAppReasonTypeList = [
    {
      label: 'Unable to operate the product',
      value: 1,
    },
    {
      label: 'Forgot the password',
      value: 2,
    },
    {
      label: 'Do not have the app yet',
      value: 3,
    },
    {
      label: 'Other',
      value: 4,
    },
  ]

  const View = (id) => {
    setIsConsolidated(false)

    setViewOnlyAccess(true)
    props.GetTicketDetails(id, getTicketDetails_CallBack)
  }
  function getTicketDetails_CallBack(data) {
    var reasonTypeLabel = notUsingAppReasonTypeList.find(
      (x) => x.value == data.notUsingAppReasonType
    )?.label
    var infoText = data.ticketUserAllocation?.find(
      (x) => x.ticketCategoryCode == 1
    )?.ticketText
    var orderText = data.ticketUserAllocation?.find(
      (x) => x.ticketCategoryCode == 2
    )?.ticketText
    var complaintText = data.ticketUserAllocation?.find(
      (x) => x.ticketCategoryCode == 3
    )?.ticketText
    var othersText = data.ticketUserAllocation?.find(
      (x) => x.ticketCategoryCode == 4
    )?.ticketText

    setInitialValues({
      id: data.id,
      distributorId: { label: data.distributorName, value: data.distributorId },
      distCenterId: { label: data.distCenterName, value: data.distCenterId },
      vizId: { label: data.vizShopCode, value: data.vizId },
      notUsingAppReasonType: {
        label: reasonTypeLabel,
        value: data.notUsingAppReasonType,
      },
      notUsingAppReason: data.notUsingAppReasonText,
      textDetails: data.ticketText,
      infoText: infoText,
      ordersText: orderText,
      complaintsText: complaintText,
      othersText: othersText,
      initiateType: data.initiateType,
      status: data.status,
      initiateCommunicationDataSaveType: data.initiateCommunicationDataSaveType,
      initialChat: data.initialChat == null ? [] : data.initialChat,
    })
    if (
      data.initiateCommunicationDataSaveType == 2 ||
      data.initiateCommunicationDataSaveType == 4
    ) {
      setShowParseSection(true)
    }
    setCommListModalShow(true)
  }

  function checkTicketDuration(ticket) {
    const date1 = new Date(ticket.createdDate)
    const date2 = new Date()

    const timeDifferenceMillis = Math.abs(date2 - date1)

    const minutesDifference = Math.floor(timeDifferenceMillis / 60000)
    return minutesDifference
  }

  const Edit = (id) => {
    setIsConsolidated(false)

    setViewOnlyAccess(false)
    let ticket = props.communicationListingData.dataListing.find(
      (x) => x.id == id
    )

    // if (ticket.medium == 'InApp' && checkTicketDuration(ticket) < 10) {
    //   toast.error('Ticket will be available for edit after 10 mins')
    //   return
    // }
    props.GetTicketDetails(id, getTicketDetails_CallBack)
  }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const handleResetFilters = (resetForm) => {
    resetForm()

    setFilterVizId(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterDistributorId(null)
    setFilterDistributionCenterId(null)
    setAddJobDistCenterOptions([])
    setFilterStatus(null)
    setFilterValuesModal({
      vizId: '',
      distributorId: '',
      distributionCenterId: '',
      fromDate: '',
      toDate: '',
      deliveryManId: '',
      vlTillNo: '',
    })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchData(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize }
    fetchData(request)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchData(request)
    setShow(false)
  }

  const Download = () => {
    let request = formRequestObject(true)
    props.downloadJob(request)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    fetchData()
  }, [searchText])

  useEffect(() => {
    if (props.communicationListingData) {
      setTotalRecords(props.communicationListingData.totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.communicationListingData])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddJobDistCenterOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistCenterOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])

  // get distributor dropdown
  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  useEffect(() => {
    if (filterDistributorId) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorId.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorId])

  // get distribution centers list on change of distributorid
  useEffect(() => {
    if (initialValues.distributorId) {
      var request = `api/DistCenter/GetDistCentersDDL/${initialValues.distributorId.value}`
      props.getDistCentersList(request, getDistCentersList_successCallBack)
    }
  }, [initialValues.distributorId])

  function getDistCentersList_successCallBack(data) {
    var modifiedList = data.map((d) => {
      return {
        ...d,
        label: d.label + '-' + d.principalName,
      }
    })
    setModalDistCentersList(modifiedList)
  }

  useEffect(() => {
    if (initialValues.distCenterId) {
      props.getDropdownList(
        `api/vizshop/GetVizShopIdsDropDown/${initialValues.distCenterId.value}`,
        GET_VIZ_IDS_DDL
      )
    }
  }, [initialValues.distCenterId])

  useEffect(() => {
    setVizIdsDDLList(props.vizIdsDdlList)
  }, [props.vizIdsDdlList])

  function formRequestObject(isExport = false) {
    let requestData = {
      vizId: filterVizId,
      fromDate: filterFromDate && formatDate(filterFromDate),
      toDate: filterToDate && formatDate(filterToDate),
      distributorId: filterDistributorId && filterDistributorId.value,
      distCenterId:
        filterDistributionCenterId && filterDistributionCenterId.value,
      status: filterStatus && filterStatus.value,
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport,
    }

    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData.distributorId = userRights?.distributorId
    }
    return requestData
  }

  function fetchData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getVLCCommunicationListing(finalRequest)
  }

  function formatDate(dateInput) {
    var res = new Date(
      Date.parse(
        new Intl.DateTimeFormat('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(dateInput))
      )
    )
      .toISOString()
      .split('T')[0]
    return res
  }

  function initiateCommunication_handler() {
    setVizIdsDDLList([])
    setModalDistCentersList([])
    setIsConsolidated(false)
    setViewOnlyAccess(false)
    setInitialValues({
      id: null,
      distributorId: null,
      distCenterId: null,
      vizId: null,
      notUsingAppReasonType: null,
      notUsingAppReason: null,
      textDetails: null,
      infoText: null,
      ordersText: null,
      complaintsText: null,
      othersText: null,
      initiateType: 2,
      initiateCommunicationDataSaveType: 1,
      status: 1,
    })

    setCommListModalShow(true)
  }

  function parseAndSave() {
    setFormDataSaveType(2) // 2 : parsedAndSaved  C# enum
    setShowParseSection(true)
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
    //setInitialValues({ ...initialValues, initiateCommunicationDataSaveType: 2 })
  }

  function saveOnly() {
    setFormDataSaveType(3) // 3 : SavedOnly C# enum
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
    // setInitialValues({ ...initialValues, initiateCommunicationDataSaveType: 3 })
  }

  function proceed() {
    setFormDataSaveType(4) // 3 : SavedOnly C# enum
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
    // setInitialValues({ ...initialValues, initiateCommunicationDataSaveType: 4 })
  }
  useEffect(() => {
    if (!commListModalShow) {
      getData(0)
    }
  }, [commListModalShow])

  const handleSubmit = async (data) => {
    setFormSubmitSucceeded(false)
    var request = {
      id: data.id,
      distributorId: data?.distributorId?.value,
      distCenterId: data?.distCenterId?.value,
      vizId: data?.vizId?.value,
      ticketText: data?.textDetails,
      notUsingAppReasonType: data?.notUsingAppReasonType?.value,
      notUsingAppReason: data?.notUsingAppReason,
      initiateCommunicationDataSaveType: formDataSaveType,
      parsedCommunication: {
        infoText: data?.infoText,
        orderText: data?.ordersText,
        complaintText: data?.complaintsText,
        otherText: data?.othersText,
      },
    }
    if (data?.id != null && data?.id != '') {
      props.parseCommunication(request, submit_resultCallback)
    } else {
      props.initiateCommunication(request, submit_resultCallback)
    }
  }

  function submit_resultCallback(data) {
    if (data?.isSuccess) {
      toast.success(data?.message)
      setInitialValues({ ...initialValues, id: data?.response?.id })
      setFormSubmitSucceeded(true)
    } else {
      toast.error(data?.message)
      setInitialValues({
        ...initialValues,
        initiateCommunicationDataSaveType: 1,
      }) // default to reset disabled controls
      setFormSubmitSucceeded(false)
    }
  }

  useEffect(() => {
    if (formSubmitSucceeded) {
      // only set these state values when operation is seccussfull from backend
      if (formDataSaveType == 2) {
        setInitialValues({
          ...initialValues,
          initiateCommunicationDataSaveType: 2,
        }) // parseAndSave
      } else if (formDataSaveType == 3) {
        setInitialValues({
          ...initialValues,
          initiateCommunicationDataSaveType: 3,
        }) // save only
      } else if (formDataSaveType == 4) {
        setInitialValues({
          ...initialValues,
          initiateCommunicationDataSaveType: 4,
        }) // procded
        setCommListModalShow(false) // hide form only if proceed operation is succeeded
      }
    }
  }, [formSubmitSucceeded])

  const infoRef = useRef(null)
  const orderRef = useRef(null)
  const complaintRef = useRef(null)
  const otherRef = useRef(null)
  const [categoryTextAssigned, setCategoryTextAssigned] = useState(false)

  const handlePasteClick = (inputRef, fieldName) => {
    navigator.clipboard
      .readText()
      .then((clipboardText) => {
        if (inputRef.current) {
          if (inputRef.current.name == 'infoText') {
            setInitialValues({ ...initialValues, infoText: clipboardText })
          } else if (inputRef.current.name == 'ordersText') {
            setInitialValues({ ...initialValues, ordersText: clipboardText })
          } else if (inputRef.current.name == 'complaintsText') {
            setInitialValues({
              ...initialValues,
              complaintsText: clipboardText,
            })
          } else if (inputRef.current.name == 'othersText') {
            setInitialValues({ ...initialValues, othersText: clipboardText })
          }
        }
      })
      .catch((error) => {
        console.error('Error reading clipboard:', error)
      })
  }

  const consolidateData = () => {
    let ticketData = [...initialValues.initialChat]
    let conText = ''
    ticketData.forEach((x) => {
      if (x.messageType == 1) {
        conText = conText + x.textMessage + '\n'
      }
    })

    setInitialValues({
      ...initialValues,
      textDetails:
        initialValues.textDetails.length > 0
          ? initialValues.textDetails + '\n' + conText
          : conText,
    })

    setIsConsolidated(true)
  }

  return (
    <VzPageContainer title='Communication Listing'>
      <div>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Communication Listing</b>
            </h4>
          </Col>
          <Col md={{ span: 6 }} />
          {isPermittedPage('Initiate Communication', props.actions, props.userRole) &&
            <Col md={{ span: 3 }} style={{ float: 'right' }}>
              <Button
                className='form-btn pull-right'
                variant='primary'
                onClick={initiateCommunication_handler}
              >
                Initiate Communication
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col md={10}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  label='Viz ID'
                                  name='vizId'
                                  handleOnChange={(value) => {
                                    filterValuesModal.vizId = value
                                    setFilterVizId(value)
                                  }}
                                  value={filterValuesModal.vizId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  minDate={filterFromDate}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor Id'
                                  name='distributorId'
                                  options={addJobDistributorsOptions}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributorId(null)
                                      setFilterDistributionCenterId([])
                                      setAddJobDistCenterOptions([])
                                      return
                                    }
                                    filterValuesModal.distributorId = e.value
                                    setFilterDistributorId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributorId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Id'
                                  name='distributionCenterId'
                                  options={addJobDistCenterOptions}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributionCenterId([])
                                      setAddJobDistCenterOptions([])
                                      return
                                    }
                                    filterValuesModal.distributionCenterId =
                                      e.value
                                    setFilterDistributionCenterId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionCenterId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Status'
                                  name='status'
                                  options={ticketStatusList}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterStatus([])
                                      return
                                    }
                                    filterValuesModal.status = e.value
                                    setFilterStatus({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterStatus}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          {/* <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={Download}
              className='m-3'
              variant='outline-primary'
            >
              <i className='fa fa-download me-1' />
              Export
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col xs={12}>
            <ServerSideDataTable
              data={props.communicationListingData.dataListing ?? []}
              columns={Columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>

        <Modal
          show={commListModalShow}
          onHide={() => setCommListModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            //initialValues={ {infoText : 'info description is here'}}
            validationSchema={initiateCom_validationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {(formik) => (
              <Form
                autoComplete='off'
                encType='multipart/form-data'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    {initialValues.initiateType == 1
                      ? 'Parse Communication'
                      : 'Initiate Communuication'}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={12}>
                        <Container>
                          <Row>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  hidden
                                  innerRef={infoRef}
                                  readOnly
                                  formik={formik}
                                  control='input'
                                  type='id'
                                  name='id'
                                  placeholder=''
                                  handleOnChange={(value) => {
                                    setInitialValues({
                                      ...initialValues,
                                      id: value,
                                    })
                                  }}
                                  value={initialValues.id}
                                ></FormikControl>
                                <FormikControl
                                  formik={formik}
                                  readOnly={
                                    viewOnlyAccess ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    2 ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    4 ||
                                    initialValues.initiateType == 1 // InApp
                                  }
                                  control='react-select'
                                  type='distributorId'
                                  label='Distributor'
                                  name='distributorId'
                                  options={
                                    commListModalShow &&
                                    addJobDistributorsOptions
                                  }
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setInitialValues({
                                        ...initialValues,
                                        distCenterId: null,
                                        distributorId: null,
                                        vizId: null,
                                      })
                                      props.vizIdsDdlList = []
                                      setModalDistCentersList([])
                                      setVizIdsDDLList([])
                                      return
                                    }
                                    setInitialValues({
                                      ...initialValues,
                                      distributorId: {
                                        label: e.label,
                                        value: e.value,
                                      },
                                      distCenterId: null,
                                      vizId: null,
                                    })
                                    setVizIdsDDLList([])
                                  }}
                                  value={initialValues.distributorId}
                                />
                              </div>
                              {initialValues.initiateType == 2 && (
                                <Link
                                  target='_blank'
                                  style={{ float: 'right', marginTop: '-84px' }}
                                  to='/vizLinkShopsList'
                                >
                                  Advance Search
                                </Link>
                              )}
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  readOnly={
                                    viewOnlyAccess ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    2 ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    4 ||
                                    initialValues.initiateType == 1 // InApp
                                  }
                                  options={modalDistCentersList}
                                  control='react-select'
                                  type='distCenter'
                                  label='Principle'
                                  name='distCenterId'
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setInitialValues({
                                        ...initialValues,
                                        vizId: null,
                                        distCenterId: null,
                                      })
                                      setVizIdsDDLList([])
                                      return
                                    }
                                    setInitialValues({
                                      ...initialValues,
                                      distCenterId: {
                                        label: e.label,
                                        value: e.value,
                                      },
                                      vizId: null,
                                    })
                                    setVizIdsDDLList([])
                                  }}
                                  value={initialValues.distCenterId}
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  readOnly={
                                    viewOnlyAccess ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    2 ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    4 ||
                                    initialValues.initiateType == 1 // InApp
                                  }
                                  control='react-select'
                                  type='vizIds'
                                  label='Viz Id'
                                  name='vizId'
                                  options={vizIdsDDLList}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setInitialValues({
                                        ...initialValues,
                                        vizId: null,
                                      })
                                    }
                                    setInitialValues({
                                      ...initialValues,
                                      vizId: { label: e.label, value: e.value },
                                    })
                                  }}
                                  value={initialValues.vizId}
                                />
                              </div>
                            </Col>

                            {initialValues.initiateType === 2 && (
                              <Col xs={12} md={6}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    readOnly={
                                      viewOnlyAccess ||
                                      initialValues.initiateCommunicationDataSaveType ==
                                      2 ||
                                      initialValues.initiateCommunicationDataSaveType ==
                                      4
                                    }
                                    control='react-select'
                                    type='notUsingAppReasonType'
                                    label='Reason for Not Using App'
                                    name='notUsingAppReasonType'
                                    options={notUsingAppReasonTypeList}
                                    handleOnChange={(e) => {
                                      if (!e || !e.value) {
                                        setInitialValues({
                                          ...initialValues,
                                          notUsingAppReasonType: null,
                                        })
                                        return
                                      }
                                      setInitialValues({
                                        ...initialValues,
                                        notUsingAppReasonType: {
                                          label: e.label,
                                          value: e.value,
                                        },
                                      })
                                    }}
                                    value={initialValues.notUsingAppReasonType}
                                  />
                                </div>
                              </Col>
                            )}
                            {initialValues?.notUsingAppReasonType &&
                              initialValues?.notUsingAppReasonType.value ==
                              4 && (
                                <Col xs={12} md={{ span: 6, offset: 6 }}>
                                  <FormikControl
                                    innerRef={infoRef}
                                    // disabled={initialValues.status != 1}
                                    disabled={
                                      viewOnlyAccess ||
                                      initialValues.initiateCommunicationDataSaveType ==
                                      2 ||
                                      initialValues.initiateCommunicationDataSaveType ==
                                      4
                                    }
                                    formik={formik}
                                    control='input'
                                    type='notUsingAppReason'
                                    name='notUsingAppReason'
                                    placeholder='Please place text here'
                                    handleOnChange={(value) => {
                                      setInitialValues({
                                        ...initialValues,
                                        notUsingAppReason: value,
                                      })
                                    }}
                                    value={initialValues.notUsingAppReason}
                                  ></FormikControl>
                                </Col>
                              )}
                          </Row>
                          {initialValues.initialChat != null &&
                            initialValues.initialChat.length > 0 && (
                              <>
                                <Row className='in-app-ticket-area'>
                                  <Col xs={12}>
                                    <div>
                                      {initialValues.initialChat.map((x) => {
                                        return (
                                          <>
                                            {x.messageType == 1 ? (
                                              <p>
                                                <span className='in-app-ticket-area-text'>
                                                  {x.textMessage}
                                                </span>
                                              </p>
                                            ) : (
                                              <></>
                                            )}
                                            {x.messageType == 2 ? (
                                              <p>
                                                <AudioPlayer
                                                  controlsList='nodownload'
                                                  src={x.filePath}
                                                  style={{
                                                    height: '30px',
                                                  }}
                                                  className='received-audio'
                                                />
                                              </p>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col align='right' className='my-2'>
                                    <Button
                                      disabled={
                                        viewOnlyAccess ||
                                        isConsolidated ||
                                        initialValues.initiateCommunicationDataSaveType ==
                                        2 ||
                                        initialValues.initiateCommunicationDataSaveType ==
                                        4
                                      }
                                      variant='primary'
                                      onClick={consolidateData}
                                    >
                                      Consolidate
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )}

                          <Row>
                            <div align='right'>
                              <BiSolidDownArrowSquare
                                onClick={() => {
                                  setShowParseSection(true)
                                }}
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  marginLeft: 'auto',
                                  marginRight: '0px',
                                }}
                              ></BiSolidDownArrowSquare>
                            </div>
                            <Col xs={12} md={12}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  disabled={
                                    viewOnlyAccess ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    2 ||
                                    initialValues.initiateCommunicationDataSaveType ==
                                    4
                                  }
                                  control='textarea'
                                  type='textarea'
                                  label='Details'
                                  name='textDetails'
                                  style={{ height: '15vh' }}
                                  handleOnChange={(value) => {
                                    setInitialValues({
                                      ...initialValues,
                                      textDetails: value,
                                    })
                                  }}
                                  value={initialValues.textDetails}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div>
                                <BiSolidUpArrowSquare
                                  onClick={() => {
                                    setShowParseSection(false)
                                  }}
                                  style={{ width: '40px', height: '40px' }}
                                ></BiSolidUpArrowSquare>
                              </div>
                            </Col>
                            <Col align='right'>
                              <Button
                                disabled={
                                  viewOnlyAccess ||
                                  initialValues.initiateCommunicationDataSaveType ==
                                  2 ||
                                  initialValues.initiateCommunicationDataSaveType ==
                                  4
                                }
                                style={{ marginRight: '10px' }}
                                variant='primary'
                                onClick={() => {
                                  saveOnly()
                                }}
                              >
                                Save
                              </Button>
                              <Button
                                disabled={
                                  viewOnlyAccess ||
                                  initialValues?.initiateCommunicationDataSaveType ==
                                  2 ||
                                  initialValues?.initiateCommunicationDataSaveType ==
                                  4
                                } // disable it if already prse done to avoid extra database hits
                                variant='primary'
                                onClick={() => {
                                  parseAndSave()
                                }}
                              >
                                Parse
                              </Button>
                            </Col>
                          </Row>
                          {showParseSection && (
                            <div>
                              <Row style={{ marginTop: '50px' }}>
                                <Col xs={2} md={11}>
                                  <div className='form-group'>
                                    <FormikControl
                                      innerRef={infoRef}
                                      disabled={true}
                                      formik={formik}
                                      control='input'
                                      type='infoText'
                                      name='infoText'
                                      label='Info'
                                      handleOnChange={(value) => {
                                        setInitialValues({
                                          ...initialValues,
                                          infoText: value,
                                        })
                                      }}
                                      value={initialValues.infoText}
                                    ></FormikControl>
                                  </div>
                                </Col>
                                <Col align='right' xs={2} md={1}>
                                  {initialValues.initiateCommunicationDataSaveType ==
                                    4 || viewOnlyAccess ? (
                                    <IoIosCopy
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'context-menu',
                                        opacity: 0.5,
                                      }}
                                    ></IoIosCopy>
                                  ) : (
                                    <IoIosCopy
                                      // disabled={initialValues.initiateCommunicationDataSaveType == 4}
                                      disabled
                                      onClick={() => {
                                        handlePasteClick(infoRef)
                                      }}
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'pointer',
                                      }}
                                    ></IoIosCopy>
                                  )}
                                </Col>

                                <Col xs={10} md={11}>
                                  <div className='form-group'>
                                    <FormikControl
                                      innerRef={orderRef}
                                      disabled={true}
                                      formik={formik}
                                      control='input'
                                      type='ordersText'
                                      name='ordersText'
                                      label='Orders'
                                      handleOnChange={(value) => {
                                        setInitialValues({
                                          ...initialValues,
                                          ordersText: value,
                                        })
                                      }}
                                      value={initialValues.ordersText}
                                    ></FormikControl>
                                  </div>
                                </Col>
                                <Col align='right' xs={2} md={1}>
                                  {initialValues.initiateCommunicationDataSaveType ==
                                    4 || viewOnlyAccess ? (
                                    <IoIosCopy
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'context-menu',
                                        opacity: 0.5,
                                      }}
                                    ></IoIosCopy>
                                  ) : (
                                    <IoIosCopy
                                      disabled={
                                        initialValues.initiateCommunicationDataSaveType ==
                                        4
                                      }
                                      onClick={() => {
                                        handlePasteClick(orderRef)
                                      }}
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'pointer',
                                      }}
                                    ></IoIosCopy>
                                  )}
                                </Col>

                                <Col xs={10} md={11}>
                                  <div className='form-group'>
                                    <FormikControl
                                      innerRef={complaintRef}
                                      disabled={true}
                                      formik={formik}
                                      control='input'
                                      type='complaintsText'
                                      name='complaintsText'
                                      label='Complaints'
                                      handleOnChange={(value) => {
                                        setInitialValues({
                                          ...initialValues,
                                          complaintsText: value,
                                        })
                                      }}
                                      value={initialValues.complaintsText}
                                    ></FormikControl>
                                  </div>
                                </Col>
                                <Col align='right' xs={2} md={1}>
                                  {initialValues.initiateCommunicationDataSaveType ==
                                    4 || viewOnlyAccess ? (
                                    <IoIosCopy
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'context-menu',
                                        opacity: 0.5,
                                      }}
                                    ></IoIosCopy>
                                  ) : (
                                    <IoIosCopy
                                      disabled={
                                        initialValues.initiateCommunicationDataSaveType ==
                                        4
                                      }
                                      onClick={() => {
                                        handlePasteClick(complaintRef)
                                      }}
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'pointer',
                                      }}
                                    ></IoIosCopy>
                                  )}
                                </Col>
                                <Col xs={10} md={11}>
                                  <div className='form-group'>
                                    <FormikControl
                                      innerRef={otherRef}
                                      disabled={true}
                                      formik={formik}
                                      control='input'
                                      type='othersText'
                                      name='othersText'
                                      label='Others'
                                      handleOnChange={(value) => {
                                        setInitialValues({
                                          ...initialValues,
                                          othersText: value,
                                        })
                                      }}
                                      value={initialValues.othersText}
                                    ></FormikControl>
                                  </div>
                                </Col>
                                <Col align='right' xs={2} md={1}>
                                  {initialValues.initiateCommunicationDataSaveType ==
                                    4 || viewOnlyAccess ? (
                                    <IoIosCopy
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'context-menu',
                                        opacity: 0.5,
                                      }}
                                    ></IoIosCopy>
                                  ) : (
                                    <IoIosCopy
                                      onClick={() => {
                                        handlePasteClick(otherRef)
                                      }}
                                      style={{
                                        width: '40px',
                                        height: '40px',
                                        color: '#ea5f2d',
                                        'margin-top': '27px',
                                        cursor: 'pointer',
                                      }}
                                    ></IoIosCopy>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className='btn btn-default'
                    onClick={() => setCommListModalShow(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={
                      viewOnlyAccess ||
                      initialValues.initiateCommunicationDataSaveType == 4 ||
                      initialValues.initiateCommunicationDataSaveType != 2 ||
                      (initialValues.infoText == null &&
                        initialValues.ordersText == null &&
                        initialValues.complaintsText == null &&
                        initialValues.othersText == null)
                    }
                    className='btn btn-primary'
                    onClick={() => {
                      proceed()
                    }}
                  >
                    Proceed
                  </Button>

                  {/* {
                    <input
                      disabled={initialValues.status != 1}
                      //type='submit'

                      className='btn btn-primary'
                      value='Proceed'
                    />
                  } */}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    </VzPageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    communicationListingData: state.VLCCommunicationListing,
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    vlcTicketCategoriesList: state.ddlList.VLCTicketCategories
      ? state.ddlList.VLCTicketCategories
      : [],
    vizIdsDdlList: state.ddlList.VizShopIds ? state.ddlList.VizShopIds : [],
    connection: state.Socket.socketData,
  }
}

export default connect(mapStateToProps, {
  getVLCCommunicationListing,
  getDropdownList,
  downloadJob,
  initiateCommunication,
  parseCommunication,
  getDistCentersList,
  GetTicketDetails,
})(VLCCommunicationListing)
