export const isPermittedPage = (actionName, actionsList, userRole) => {
  if (actionName) {
    if (userRole == 'Administrator') return true

    const isActionAuthorised = actionsList.find(
      (page) => page.actionName.toLowerCase() === actionName.toLowerCase()
    )
    if (isActionAuthorised?.ischecked == false) {
      return false
    } else {
      return true
    }
  } else return false
}
