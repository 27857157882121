import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../../layouts/DataTables/CustomDataTable'
import { Input } from 'reactstrap'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { addUserRole, deleteUserRole, getUserRoles, updateUserRole } from './UserRolesActions'
import ReactSelect from 'react-select'
import { defaultIntegrations } from '@sentry/react';

const UserRoles = (props) => {
  const defaultUserRoleId = '00000000-0000-0000-0000-000000000000'
  const [newUserRole, setNewUserRole] = useState('')
  const [userRoleId, setuserRoleId] = useState(defaultUserRoleId)

  const UserRolesColumns = [
    {
      Header: 'Role Name',
      accessor: 'value',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: ({ row }) => (
        <div>
          <button
            disabled={row.original.isSystemRole === true}
            onClick={() => {
              Delete(row.original.id)
            }}
            className='btn btn-danger'
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fa fa-trash'></i>
          </button>

          {/* <Link
            className='btn btn-sm'
            // to={`/EditDistributorInductionForm/${row.value}`}
            disabled={row.original.isSystemRole === true}
            onClick={() => {
              Edit(row.original.id, row.original.name)
            }}
          >
            <i className='fas fa-edit'></i>
          </Link> */}
          <button
            className='btn btn-sm'
            disabled={row.original.isSystemRole === true}
            onClick={() => {
              Edit(row.original.id, row.original.name)
            }}
            // className=''
            style={{ width: 30, height: 30, cursor: 'pointer', marginLeft: "3px" }}
          >
            <i className='fa fa-edit'></i>
          </button>
        </div>
      ),
    },
  ]

  const addUserRole = async () => {
    if (newUserRole == '' || newUserRole == null) {
      toast.error('Enter User Role Name')
      return
    }

    let checkIsExist = props.UserRoles?.filter(
      (item) =>
        item.value.toUpperCase() === newUserRole.toUpperCase() ||
        item.value.replace(/ /g, '').trim().toUpperCase() ===
        newUserRole.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist?.length > 0) {
      toast.warn('User role with the same name already exist')
      return
    }

    let data = {
      Id: userRoleId,
      Name: newUserRole
    }
    if (data.Id != defaultUserRoleId) {
      await props.updateUserRole(data.Id, data)
    }
    else {
      await props.addUserRole(data)
    }
    getUserRoles()
    setNewUserRole('')
    setuserRoleId(defaultUserRoleId)
  }

  const getUserRoles = () => {
    props.getUserRoles()
  }

  const Edit = (id, roleName) => {
    setuserRoleId(id);
    setNewUserRole(roleName);
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const DeleteRecord = async (id) => {
    await props.deleteUserRole(id)
  }

  useEffect(() => {
    getUserRoles()
  }, [])
  return (
    <div>
      <Row style={{ marginTop: '40px' }}>
        <Col xs={12} md={{ span: 4, offset: 1 }} style={{ marginTop: '80px' }}>
          <Col md={12}>
            <Col md={12}>
              <div className='form-group'>
                <Input
                  value={userRoleId}
                  type='text'
                  placeholder='Enter User Role Name'
                  hidden
                />
                <Input
                  value={newUserRole}
                  onChange={(name) => setNewUserRole(name.target.value)}
                  type='text'
                  placeholder='Enter User Role Name'
                />
              </div>
            </Col>
            <Col md={12} style={{ marginTop: '10px', display: 'inline-block' }}>
              <div className='form-group'>
                <Button
                  className='form-btn'
                  variant='primary'
                  onClick={addUserRole}
                  style={{ fontSize: 13 }}
                >
                Save
                </Button>
              </div>
            </Col>
          </Col>
        </Col>
        <Col xs={12} md={{ span: 6, offset: 1 }}>
          <CustomDataTable
            data={props.UserRoles ?? []}
            columns={UserRolesColumns}
            getTrProps={() => null}
          />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    UserRoles: state.UserRoles,
  }
}

export default connect(mapStateToProps, {
  addUserRole,
  updateUserRole,
  deleteUserRole,
  getUserRoles,
})(UserRoles)
