import {
  ADD_AGENTS_DETAILS,
  GET_AGENTS_LIST,
  DELETE_AGENT,
  SELECTED_AGENT_DETAILS,
  UPDATE_AGENT_DETAILS,
  GET_AGENT_SHOPS,
  TAG_AGENT_SHOP,
} from '../../../Types'
import _ from 'lodash'

const OnBoardingAgentsListReducer = (
  state = { agentsList: [], selectedAgentDetails: {}, agentShopsList: [] },
  action
) => {
  switch (action.type) {
    // case ADD_AGENTS_DETAILS:
    //   return { ...state, agentsList: [...action.payload] }
    case GET_AGENTS_LIST:
      return { ...state, agentsList: [...action.payload] }
    case DELETE_AGENT:
      return {
        ...state,
        agentsList: state.agentsList.filter((e) => e.id !== action.payload),
      }
    case SELECTED_AGENT_DETAILS:
      return { ...state, selectedAgentDetails: action.payload }
    // case UPDATE_AGENT_DETAILS:
    //   const index = state.findIndex((item) => item.id === action.payload.id)
    //   const newState = [...state]
    //   newState[index] = action.payload
    //   return [...newState]
    case GET_AGENT_SHOPS:
      return { ...state, agentShopsList: [...action.payload] }
    case TAG_AGENT_SHOP:
      let index = state.agentShopsList.findIndex((x) => x.id == action.payload)
      state.agentShopsList[index].isTagged =
        !state.agentShopsList[index].isTagged
      return {
        ...state,
        // agentShopsList: state.agentShopsList.filter((e) => e.id !== action.payload),
      }
    default:
      return state
  }
}
export default OnBoardingAgentsListReducer
