import {
  GET_MONTHLY_DIGITAL_COLLECTIONS_REPORT,
  RESET_MONTHLY_DIGITAL_COLLECTIONS_REPORT,
} from '../../../../Types'

const MonthlyDigitalCollectionsReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MONTHLY_DIGITAL_COLLECTIONS_REPORT:
      return [...action.payload]
    case RESET_MONTHLY_DIGITAL_COLLECTIONS_REPORT:
      return []

    default:
      return state
  }
}
export default MonthlyDigitalCollectionsReportReducer
