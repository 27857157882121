import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
// import storage from "../../apis/storage";
import { GET_DISTRIBUTORSLIST, DELETE_DISTRIBUTOR } from '../../../Types'

export const getDistributorsList = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Distributor/GetDistributorsList`, requestData)
    .then(({ data }) => {
      dispatch({
        type: GET_DISTRIBUTORSLIST,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
// export const getDistributorCompleteData = async (distributorId) => {
//   // dispatch(showLoading())

//   return {};
// };
export const deleteDistributor = (id) => async (dispatch) => {
  if (!id) return
  dispatch(showLoading())
  await axios
    .delete(`api/Distributor/SoftDelete/${id}`)
    .then(({ data }) => {
      dispatch(hideLoading())
      if (data.isSuccess) {
        dispatch({ type: DELETE_DISTRIBUTOR, payload: id })
        toast.success(data.message)
      }
      else {
        toast.error(data.message)
      }
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
