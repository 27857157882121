import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import RestrictedRoute from './RestrictedRoute'
import LoginPage from '../components/Login/LoginPage'
import Dashboard from '../components/Dashboard/Dashboard'
import ManageDailyOrders from '../components/DailyOrders/ManageDailyOrders/ManageDailyOrders'
import ManageOrderDetails from '../components/DailyOrders/ManageDailyOrders/ManageOrderDetails/ManageOrderDetails'
import PaymentStatusReport from '../components/DailyOrders/PaymentStatusReport/PaymentStatusReport'
import OrderShopDetails from '../components/DailyOrders/PaymentStatusReport/OrderShopDetails/OrderShopDetails'
import vizLinkJobsList from '../components/VizlinkShops/JobsList/JobsList'
import JobDetails from '../components/VizlinkShops/JobsList/JobDetails/JobDetails'
import vizLinkShopsOnBoardingDetails from '../components/VizlinkShops/OnBoardingShops/OnBoardingShops'
import VizLinkShopsList from '../components/VizlinkShops/VizShopsList/VizShopsList'
import PublicRoute from './PublicRoutes'
import ResetPassword from '../components/Login/ResetPassword'
import QrTillList from '../components/QrTillDetails/QrTillList/QrTillList'
import DeliverymanUserRegistration from '../components/QrTillDetails/DeliverymanUserRegistration/DeliverymanUserRegistration'
import OnBoardingAgentsList from '../components/VizlinkAgents/OnBoardingAgentsList/OnBoardingAgentsList'
import RegisteredAgentsList from '../components/VizlinkAgents/RegisteredAgentsList/RegisteredAgentsList'
import TillNumberDetails from '../components/QrTillDetails/TillNumberDetails/TillNumberDetails'
import DailyCollectionsReport from '../components/QrTillDetails/DailyCollectionsReport/DailyCollectionsReport'
import DeliveryManCoinsPaymentReport from '../components/VlLoyaltyProgram/DeliveryManCoinsPaymentReport/DeliveryManCoinsPaymentReport'
import DailyTransactionsSummary from '../components/Reports/DailyTransactionsSummary/DailyTransactionsSummary'

import DistributorCoinsPaymentReport from '../components/VlLoyaltyProgram/DistributorCoinsPaymentReport/DistributorCoinsPaymentReport'
import VLCCommunicationListing from '../components/VLConnect/VLCShopkeeperTickets/VLCCommunicationListing'
import DistributorInbox from '../components/VLConnect/DistributorInbox/DistributorInbox'
import ConnectConversation from '../components/VLConnect/ConnectConversation/ConnectConversation'
import DLMCollectionReport from '../components/DailyOrders/DLMCollectionReport/DLMCollectionReport'
import ShopwiseTransactionsDetails from '../components/Reports/ShopwiseTransactionsDetails/ShopwiseTransactionsDetails'
import DigitalCollectionsReport from '../components/Reports/DigitalCollectionsReport/DigitalCollectionsReport'
import LoanDisbursementReport from '../components/LoanPayments/LoanDisbursementReport/LoanDisbursementReport'
import RsoJobDetails from '../components/VizlinkShops/OnBoardingShops/RsoJobDetails/RsoJobDetails'
import Sectors from '../components/VizlinkShops/Sectors/Sectors'
import SectorBasedReport from '../components/VizlinkShops/SectorBasedReport/SectorBasedReport'
import InvoiceAllocationListing from '../components/DailyOrders/InvoiceAllocationListing/InvoiceAllocationListing'
import SchemesList from '../components/VlLoyaltyProgram/SchemesList/SchemesList'
import CoinsHistoryReport from '../components/VlLoyaltyProgram/CoinsHistoryReport/CoinsHistoryReport'
import DistributorConisDisbursmentReport from '../components/VlLoyaltyProgram/DistributorConisDisbursmentReport/DistributorConisDisbursmentReport'
import FinjaLoanReport from '../components/Reports/FinjaLoanReport/FinjaLoanReport'
import InvoiceWiseDailyCollectionDetails from '../components/M2DReorts/InvoiceWiseDailyCollectionDetails/InvoiceWiseDailyCollectionDetails'
import InvoiceWisePaymentBreakup from '../components/M2DReorts/InvoiceWisePaymentBreakup/InvoiceWisePaymentBreakup'
import Advertisements from '../components/Advertisements/Advertisements'
import RsoDetailsReport from '../components/Reports/RsoDetailsReport/RsoDetailsReport'
import RsoPerformanceReport from '../components/Reports/RsoPerformanceReport/RsoPerformanceReport'
import ShopWisePulseReport from '../components/Reports/AppPulse/ShopWisePulseReport/ShopWisePulseReport'
import DateWiseShopsSummaryReport from '../components/Reports/AppPulse/DateWiseShopsSummaryReport/DateWiseShopsSummaryReport'
import About from '../components/About/About'
import ShopsDetailReport from '../components/Reports/ShopsDetailReport/ShopsDetailReport'
import monthWiseM2DReport from '../components/M2DReorts/monthWiseM2DReport/monthWiseM2DReport'
import SCRDetailReport from '../components/DailyOrders/SCRDetailReport/SCRDetailReport'
class DistributorUserRoutes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      auth: false,
      clientCookie: false,
      logout: false,
    }
  }

  render() {
    return (
      <Switch>
        {/* <Redirect strict exact from='/' to='/dashboard' /> */}
        <PublicRoute
          restricted={true}
          component={LoginPage}
          path='/login'
          exact
        />
        <RestrictedRoute component={Dashboard} path='/dashboard' exact />

        <RestrictedRoute
          component={ManageDailyOrders}
          path='/NewOrdersForm'
          exact
        />
        <RestrictedRoute
          component={ManageOrderDetails}
          path='/ManageOrderDetails/:deliveryDate/:distCenterId'
          exact
        />
        <RestrictedRoute
          component={PaymentStatusReport}
          path='/PaymentStatusReport'
          exact
        />
        <RestrictedRoute
          component={OrderShopDetails}
          path='/OrderShopDetails/:deliveryDate/:distCenterId'
          exact
        />

        <RestrictedRoute
          component={Sectors}
          path='/sectors'
          exact
        />

        <RestrictedRoute
          component={SectorBasedReport}
          path='/SectorBasedReport'
          exact
        />


        <RestrictedRoute
          component={vizLinkJobsList}
          path='/vizLinkJobsList'
          exact
        />
        <RestrictedRoute component={JobDetails} path='/JobDetails/:id' exact />
        <RestrictedRoute
          component={vizLinkShopsOnBoardingDetails}
          path='/vizLinkShopsOnBoardingDetails'
          exact
        />
        <RestrictedRoute
          component={RsoJobDetails}
          path='/RsoJobDetails/:id/:rsoId'
          exact
        />
        <RestrictedRoute
          component={VizLinkShopsList}
          path='/vizLinkShopsList'
          exact
        />
        <RestrictedRoute component={QrTillList} path='/QrTillList' exact />
        <RestrictedRoute
          component={DeliverymanUserRegistration}
          path='/DeliverymanUserRegistration'
          exact
        />

        <RestrictedRoute
          component={OnBoardingAgentsList}
          path='/VizAgentsList'
          exact
        />
        <RestrictedRoute
          component={RegisteredAgentsList}
          path='/RegisteredAgentsList'
          exact
        />
        <RestrictedRoute
          component={TillNumberDetails}
          path='/TillNumberDetails'
          exact
        />

        <RestrictedRoute
          component={DailyCollectionsReport}
          path='/DailyCollectionsReport'
          exact
        />
        <RestrictedRoute
          component={DeliveryManCoinsPaymentReport}
          path='/DeliveryManCoinsPaymentReport'
          exact
        />
        <RestrictedRoute
          component={DistributorCoinsPaymentReport}
          path='/DistributorCoinsPaymentReport'
          exact
        />
        <RestrictedRoute
          component={LoanDisbursementReport}
          path='/LoanDisbursementReport'
          exact
        />
        <RestrictedRoute
          component={FinjaLoanReport}
          path='/FinjaLoanReport'
          exact
        />

        <RestrictedRoute
          component={InvoiceWiseDailyCollectionDetails}
          path='/InvoiceWiseDailyCollectionDetails'
          exact
        />

        <RestrictedRoute
          component={InvoiceWisePaymentBreakup}
          path='/InvoiceWisePaymentBreakup'
          exact
        />

        <RestrictedRoute
          component={Advertisements}
          path='/Advertisements'
          exact
        />

        <RestrictedRoute
          component={VLCCommunicationListing}
          path='/VLCCommunicationListing'
          exact
        />
        <RestrictedRoute
          component={ConnectConversation}
          path='/ConnectConversation'
          exact
        />

        <RestrictedRoute
          component={DistributorInbox}
          path='/DistributorInbox'
          exact
        />

        <RestrictedRoute
          component={ShopwiseTransactionsDetails}
          path='/ShopwiseTransactionsDetails'
          exact
        />
        <RestrictedRoute
          component={DLMCollectionReport}
          path='/DLMCollectionReport'
          exact
        />

        <RestrictedRoute
          component={InvoiceAllocationListing}
          path='/InvoiceAllocationListing'
          exact
        />

        <RestrictedRoute
          component={SchemesList}
          path='/SchemesList'
          exact />
        <RestrictedRoute
          component={CoinsHistoryReport}
          path='/CoinsHistoryReport'
          exact
        />
        <RestrictedRoute
          component={DistributorConisDisbursmentReport}
          path='/DistributorCoinsDisbursmentReport'
          exact
        />
        <RestrictedRoute
          component={DailyTransactionsSummary}
          path='/DailyTransactionsSummary'
          exact
        />
        <RestrictedRoute
          component={RsoDetailsReport}
          path='/RsoDetailsReport'
          exact
        />
        <RestrictedRoute
          component={RsoPerformanceReport}
          path='/RsoPerformanceReport'
          exact
        />
        <RestrictedRoute
          component={ShopWisePulseReport}
          path='/ShopWisePulseReport'
          exact
        />
        <RestrictedRoute component={About} path='/About' exact />
        <RestrictedRoute
          component={DateWiseShopsSummaryReport}
          path='/DateWiseShopsSummaryReport'
          exact
        />
        <RestrictedRoute
          component={DigitalCollectionsReport}
          path='/DigitalCollectionsReport'
          exact
        />
        <RestrictedRoute
          component={ShopsDetailReport}
          path='/ShopsDetailReport'
          exact
        />
        <RestrictedRoute
          component={monthWiseM2DReport}
          path='/monthWiseM2DReport'
          exact
        />
        <RestrictedRoute
          component={SCRDetailReport}
          path='/SCRDetailReport'
          exact
        />
        <PublicRoute path='*'>
          <Redirect to='/' />
        </PublicRoute>
      </Switch>
    )
  }
}

export default DistributorUserRoutes
