import { GET_VERSIONS, ADD_VERSION, DELETE_VERSION } from '../../../Types'

const VersionsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_VERSIONS:
      return action.payload
    case ADD_VERSION:
      return [...state, action.payload]
    case DELETE_VERSION:
      return state.filter((e) => e.id !== action.payload)
    default:
      return state
  }
}
export default VersionsReducer
