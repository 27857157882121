import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'

import { Tabs, Tab } from 'react-bootstrap'
import CurrentShopwiseTransactionsDetails from './CurrentShopwiseTransactionsDetails/CurrentShopwiseTransactionsDetails'
import { isPermittedPage } from '../../../apis/Utility'


const ShopwiseTransactionsDetails = (props) => {
  return (
    <div>
      <VzPageContainer title='Shop-wise Transactions Details'>
        <Tabs
          defaultActiveKey='CurrentShopwiseTransactionsDetails'
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          {isPermittedPage('Current', props.actions, props.userRole) &&
            <Tab eventKey='CurrentShopwiseTransactionsDetails' title='Current'>
              <CurrentShopwiseTransactionsDetails actions={props.actions}></CurrentShopwiseTransactionsDetails>
            </Tab>
          }
        </Tabs>
      </VzPageContainer>
    </div>
  )
}

export default connect()(ShopwiseTransactionsDetails)
