import { GET_COMMUNICATION_LISTING } from '../../../Types'

const initialState = []

const VLCCommunicationListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMUNICATION_LISTING:
      return action.payload
    default:
      return state
  }
}
export default VLCCommunicationListingReducer