import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Row,
  Col,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import FormikControl from '../../../layouts/Input/FormikControl'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'
import Moment from 'react-moment'
import { Formik, Form } from 'formik'
import { getCoinHistoryReport, exportReport } from './CoinsHistoryReportActions'
import { isPermittedPage } from '../../../apis/Utility'
import storage from '../../../apis/Storage'

const CoinsHistoryReport = (props) => {
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionID, setFilterDistributionID] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(new Date())
  const [filterToDate, setFilterToDate] = useState(new Date())
  const [filterVizId, setFilterVizId] = useState(null)
  const [filterType, setFilterType] = useState(null)

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const userRights = JSON.parse(storage.get('_userRights'))
  const [filterValuesModal, setFilterValuesModal] = useState({
    distributorName: '',
    distributionID: '',
    fromDate: new Date(),
    toDate: new Date(),
    vizId: '',
    type: '',
  })

  const typeOptions = [
    {
      label: 'Credit',
      value: 'Credit',
    },
    {
      label: 'Debit',
      value: 'Debit',
    },
  ]

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          {row.value ? <Moment format='DD/MM/YYYY'>{row.value}</Moment> : '--'}
        </div>
      ),
    },
    {
      Header: 'VIZ ID',
      accessor: 'vizId',
    },
    {
      Header: 'Distribution ID',
      accessor: 'distCenter',
    },
    {
      Header: 'INV No.',
      accessor: 'invoiceNo',
    },
    {
      Header: 'Transaction Amount',
      accessor: 'transactionAmount',
    },
    {
      Header: 'Coins',
      accessor: 'coins',
    },
    {
      Header: 'Net Coins',
      accessor: 'netCoins',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    // {
    //   Header: 'Actions',
    //   accessor: 'date',
    // },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionID(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterVizId(null)
    setFilterType(null)

    setFilterValuesModal({
      distributorName: '',
      distributionID: '',
      fromDate: new Date(),
      toDate: new Date(),
      vizId: '',
      type: '',
    })
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    let requestData = {
      distributorId: filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        event.distributionID == '' || event.distributionID == null
          ? null
          : event.distributionID,
      type: event.type == '' || event.type == null ? null : event.type,

      vizId: event.vizId == '' || event.vizId == null ? null : event.vizId,

      fromDate:
        event.fromDate == '' || event.fromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.fromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        event.toDate == '' || event.toDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.toDate)
            )
          )
            .toISOString()
            .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    props.getCoinHistoryReport(requestData)
    setFilterFromDate(event.fromDate == '' ? null : event.fromDate)
    setFilterToDate(event.toDate == '' ? null : event.toDate)

    setShow(false)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      distributorId: filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      type: filterType == null ? null : filterType.value,
      vizId: filterVizId == null ? null : filterVizId,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    props.getCoinHistoryReport(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      distributorId: filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      type: filterType == null ? null : filterType.value,
      vizId: filterVizId == null ? null : filterVizId,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    props.getCoinHistoryReport(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const Download = () => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      distributorId: filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      type: filterType == null ? null : filterType.value,
      vizId: filterVizId == null ? null : filterVizId,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    props.exportReport(requestData)
  }

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])
  // setAddJobDistributorsOptions(
  //   props.allDistributorList.map(function (item) {
  //     return {
  //       value: item.value,
  //       label: item.label,
  //     }
  //   })
  // )
  // }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddJobDistCenterOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistCenterOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])

  // useEffect(() => {
  //   setAddJobDistCenterOptions(
  //     props.addJobDistCentersddlList.map(function (item) {
  //       return {
  //         value: item.value,
  //         label: item.label,
  //       }
  //     })
  //   )
  // }, [props.addJobDistCentersddlList])

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  useEffect(() => {

    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
      distributorId: filterDistributorName == null ? null : filterDistributorName.value,
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      type: filterType == null ? null : filterType.value,
      vizId: filterVizId == null ? null : filterVizId,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    props.getCoinHistoryReport(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.CoinsHistoryReport && props.CoinsHistoryReport.length > 0) {
      setTotalRecords(props.CoinsHistoryReport[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.CoinsHistoryReport])

  return (
    <>
      <div>
        <VzPageContainer title='Coins History Report'>
          <Row>
            <Col style={{ float: 'left' }}>
              <h4>
                <b>Coins History Report</b>
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={10} style={{ float: 'right' }}>
              <div ref={ref}>
                <Button
                  onClick={openFilterModal}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-filter' />
                  Filters
                </Button>

                <Overlay
                  show={show}
                  target={target}
                  placement='bottom'
                  container={ref}
                  containerPadding={20}
                  width='430px'
                  style={{ width: '430px' }}
                >
                  <Popover
                    id='popover-contained'
                    style={{ maxWidth: '360px', width: '360px' }}
                  >
                    <Popover.Header as='h3'>Filters</Popover.Header>
                    <Popover.Body>
                      <Formik
                        enableReinitialize
                        initialValues={filterValuesModal}
                        validator={() => ({})}
                        onSubmit={(e) => LoadDataByfilter(e)}
                      >
                        {(formik) => (
                          <Form autoComplete='off'>
                            <Row>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Type'
                                  name='type'
                                  options={typeOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.type = e.value
                                    setFilterType({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterType}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='text'
                                  label='Viz ID'
                                  name='vizId'
                                  handleOnChange={(e) => {
                                    filterValuesModal.vizId = e
                                    setFilterVizId(e)
                                  }}
                                  value={filterValuesModal.vizId}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor'
                                  name='distributorName'
                                  options={addJobDistributorsOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributorName = e.value
                                    setFilterDistributorName({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    setFilterDistributionID(null)
                                  }}
                                  value={filterDistributorName}
                                />
                              </Col>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Center'
                                  name='distributionID'
                                  options={addJobDistCenterOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    filterValuesModal.distributionID = e.value
                                    setFilterDistributionID({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionID}
                                />
                              </Col>
                            </Row>
                            <div className='row' style={{ marginTop: 20 }}>
                              <div className='form-group'>
                                <Link
                                  onClick={handleResetFilters.bind(
                                    null,
                                    formik.resetForm
                                  )}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'left',
                                  }}
                                >
                                  Reset
                                </Link>
                                <Link
                                  onClick={() => {
                                    setShow(false)
                                    setTarget(null)
                                  }}
                                  to='#'
                                  className='active'
                                  style={{
                                    padding: '0px',
                                    marginTop: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Cancel
                                </Link>
                                <div className='float-right'>
                                  <Button
                                    type='submit'
                                    className='btn btn-primary'
                                    style={{
                                      marginRight: '10px',
                                      float: 'right',
                                    }}
                                  >
                                    Apply
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </Col>
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {isPermittedPage('Export', props.actions, props.userRole) &&
                <Button
                  onClick={Download}
                  className='m-3'
                  variant='outline-primary'
                >
                  <i className='fa fa-download me-1' />
                  Export
                </Button>
              }
            </Col>

          </Row>
          <Row>
            <Col xs={12} md={12}>
              <ServerSideDataTable
                data={props.CoinsHistoryReport ?? []}
                columns={columns}
                getTrProps={(event, data) => null}
                goToPreviousPage={(val) => getData(val)}
                goToNextPage={(val) => getData(val)}
                totalRecords={totalRecords}
                pageSizeChange={(size) => pageSizeChanged(size)}
                myPageSize={currentPageSize}
                myIndex={currentIndex}
                pageNo={currentPageNo}
                searchChanged={searchChanged}
              />
              {/* <CustomDataTable
                data={props.CoinsHistoryReport ?? []}
                columns={columns}
                getTrProps={(event, data) => null}
              /> */}
            </Col>
          </Row>
        </VzPageContainer>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],

    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    CoinsHistoryReport: state.CoinsHistoryReport
      ? state.CoinsHistoryReport
      : [],
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  getCoinHistoryReport,
  exportReport,
})(CoinsHistoryReport)
