import { createBrowserHistory } from 'history';
import storage from './apis/Storage';

export const logout = () => {
 storage.set('_appLogged', 'false')
 storage.set('_appToken', '')
 storage.set('_userRights', '{}')
 storage.set('_userPermissionsList', '[]')
 storage.set('_appUserId', '')
 storage.set('_appUserRoleId', '')
}


export default createBrowserHistory();

