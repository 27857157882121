import { GET_RSO_JOBS, ADD_RSO_JOBS } from '../../../Types'
// import _ from 'lodash'

const OnBoardingShopsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_RSO_JOBS:
      return [...action.payload]
    // case ADD_RSO_JOBS:
    //   if (action.payload.length > 0) {
    //     return [...action.payload]
    //   } else {
    //     return [...state]
    //   }
    default:
      return state
  }
}
export default OnBoardingShopsReducer
