import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  GET_MONTHLY_DIGITAL_COLLECTIONS_REPORT,
  RESET_MONTHLY_DIGITAL_COLLECTIONS_REPORT,
} from '../../../../Types'

import fileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { getCustomCloumns } from '../../../../utilities/FUNCTIONS'

export const getMonthlyDigitalCollectionsReport =
  (requestData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .post(`api/Reports/GetMonthlyDigitalCollectionsReport`, requestData)
      .then(({ data }) => {
        if (data && data?.response)
          dispatch({
            type: GET_MONTHLY_DIGITAL_COLLECTIONS_REPORT,
            payload: data.response,
          })
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }

export const resetMonthlyDigitalCollectionsReport = () => async (dispatch) => {
  dispatch({
    type: RESET_MONTHLY_DIGITAL_COLLECTIONS_REPORT,
    payload: [],
  })
}

const invisibleColumns = ["bankingChannel", "markedASDigital", "cash", "date", "noOfActiveDays", "totalRecords"];

export const exportReport = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Reports/GetMonthlyDigitalCollectionsReport`, requestData)
    .then(({ data }) => {
      const outputFilename = `Monthly_Digital_Collection_Report_${Date.now()}`
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const exportToCSV = (csvData, jobNumber, fileName) => {
        csvData = getCustomCloumns(csvData, invisibleColumns);
        const ws = XLSX.utils.json_to_sheet(csvData)
        if (ws) {
          var range = XLSX.utils.decode_range(ws['!ref'])
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
            if (!ws[address]) continue
            ws[address].v =
              ws[address].v == 'distributionId'
                ? 'Distribution Id'
                : ws[address].v == 'totalSalesRecorded'
                  ? 'Total Sales Recorded'
                  : ws[address].v == 'jazzCash'
                    ? 'Jazz Cash'
                    : ws[address].v == 'easyPaisa'
                      ? 'Easy Paisa'
                      : ws[address].v == 'digitalCollectionPercentage'
                        ? 'Digital Collection %'
                        : ws[address].v == 'month'
                          ? 'Month'
                          : ws[address].v
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const fileData = new Blob([excelBuffer], { type: fileType })
        fileSaver.saveAs(fileData, fileName + fileExtension)
      }
      exportToCSV(data.response, '', outputFilename)
      dispatch(hideLoading())
    })

    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
