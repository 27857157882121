import {
  GET_BANKDETAILS,
  ADD_BANKDETAILS,
  UPDATE_BANKDETAILS,
  DELETE_BANKDETAILS,
  EMPTY_BANKDETAILS,
} from '../../../Types'

const bankDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_BANKDETAILS:
      return [...action.payload]
    case ADD_BANKDETAILS:
      return [...state, action.payload]
    case UPDATE_BANKDETAILS:
      const index = state.findIndex((item) => item.id === action.payload.id) //finding index of the item
      const newState = [...state] //making a new array
      newState[index] = action.payload //changing value in the new array
      return [...newState] //reassingning todos to new array
    case DELETE_BANKDETAILS:
      return state.filter((e) => e.id !== action.payload)
    case EMPTY_BANKDETAILS:
      return []
    default:
      return state
  }
}
export default bankDetailsReducer
