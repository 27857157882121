import { ADD_ORDERS, ADD_Unilever_ORDERS, ADD_Master_ORDERS, GET_ORDERS, DELETE_ORDER } from '../../../Types'
import _ from 'lodash'

const ManageDailyOrdersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ORDERS:
      return [...action.payload]
    case ADD_ORDERS:
      return [...action.payload]
    case ADD_Unilever_ORDERS:
      return [...action.payload]
    case ADD_Master_ORDERS:
      return [...action.payload]
    case DELETE_ORDER:
      return state.filter((e) => e.id !== action.payload)
    default:
      return state
  }
}
export default ManageDailyOrdersReducer
