import {
  ADD_USER_ROLE,
  DELETE_USER_ROLE,
  GET_USER_ROLES_LIST,
  UPDATE_USER_ROLE_
} from '../../../../Types'
const UserRolesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_USER_ROLES_LIST:
      return action.payload.map(function (item) {
        return {
          value: item.name,
          label: item.name,
          id: item.id,
          ...item,
        }
      })
    case ADD_USER_ROLE:
      return [...state, action.payload]
    case DELETE_USER_ROLE:
      return state.filter((e) => e.id !== action.payload)
    default:
      return state
  }
}
export default UserRolesReducer
