import {
  GET_MFI_DETAILS,
  ADD_MFI_DETAILS,
  UPDATE_MFI_DETAILS,
  DELETE_MFI_DETAILS,
  GET_MFI_DETAILSLIST,
  EMPTY_MFI_DETAILSLIST,
} from '../../../Types'

const mfiDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MFI_DETAILS:
      return [...action.payload]
    case ADD_MFI_DETAILS:
      return [...state, action.payload]
    case UPDATE_MFI_DETAILS:
      const index = state.findIndex((item) => item.id === action.payload.id) //finding index of the item
      const newState = [...state] //making a new array
      newState[index] = action.payload //changing value in the new array
      return [...newState] //reassingning todos to new array
    case DELETE_MFI_DETAILS:
      return state.filter((e) => e.id !== action.payload)
    case GET_MFI_DETAILSLIST:
      return [...action.payload]
    case EMPTY_MFI_DETAILSLIST:
      return []
    default:
      return state
  }
}
export default mfiDetailsReducer
