import React, { Component } from "react";
import { VzPageContainer } from "../../layouts/VzPageContainer";
export default class ManageRSO extends Component {
  render() {
    return (
      <div>
        <VzPageContainer title="VizLink RSO's">
        </VzPageContainer>
      </div>
    );
  }
}
