import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { Col, Row, Overlay, Button, Popover, Container } from 'react-bootstrap'
import { Input } from 'reactstrap'

import Moment from 'react-moment'
import { VzPageContainer } from '../../../layouts/VzPageContainer'

import { getDropdownList } from '../../Shared/dropdownListActions'
import { GET_COMMUNICATION_LISTING } from '../../../Types'
import axios from '../../../apis/Api'
import storage from '../../../apis/Storage'
import * as Yup from 'yup'
import AudioPlayer from '../../../layouts/Input/AudioPlayer'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import ReactSelect from 'react-select'
import {
  getChatMessages,
  resetChat,
  sendMessage,
} from './ConnectConversationActions'

const ConnectConversation = (props) => {
  const _appUserRole = storage.get('_appUserRole')

  const { ticketId } = useParams()
  const [messageList, setMessageList] = useState([])

  const [showSpinner, setShowSpinner] = useState(false)
  const [newMessage, setNewMessage] = useState('')
  const [chatActive, setChatActive] = useState(false)
  const [firstMsgSent, setFirstMsgSent] = useState(false)

  const divRef = useRef(null)
  const [chatDetails, setChatDetails] = useState(null)

  const [initalLoad, setInitalLoad] = useState(true)
  const [ticketCategory, setTicketCategory] = useState({})

  const categoryColumns = [
    {
      label: 'Information',
      value: '1',
    },
    {
      label: 'Online Orders',
      value: '2',
    },
    {
      label: 'Complaints',
      value: '3',
    },
    {
      label: 'Others',
      value: '4',
    },
  ]

  const Spinner = () => {
    return (
      <div className='spinner-container'>
        <div className='spinner' />
      </div>
    )
  }

  const DateSeparator = (props) => {
    return (
      <p
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <span
          style={{
            borderRadius: '10px',
            backgroundColor: '#5B5B5B',
            color: 'white',
          }}
          className='px-2 py-1'
        >
          {props.date}
        </span>
      </p>
    )
  }

  const Message = ({ msg }) => {
    if (msg.messageType == 1) {
      return (
        <>
          <p
            className='py-1 px-2'
            style={{
              backgroundColor:
                msg.messageDeliveryType == 1 ? '#F6BCA7' : '#F9E4ACE5',
              color: 'black',
              maxWidth: '60%',

              marginLeft: msg.messageDeliveryType == 1 ? 'auto' : '',
              position: 'relative',
            }}
          >
            <span
              style={{
                display: 'block',
                marginBottom: '14px',
                marginRight: '40px',
                fontSize: 14,

                wordBreak: 'break-all',
                whiteSpace: 'pre-line',
              }}
            >
              {msg.messageText}
            </span>

            <span
              className='ms-auto'
              style={{
                fontSize: 10,
                position: 'absolute',
                bottom: '5px',
                right: '5px',
                color: 'whitesmoke',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'black',
              }}
            >
              <Moment format='hh:mm a'>{msg.messageDate}</Moment>
              {msg.messageDeliveryType == 2 && msg.isRead && (
                <i
                  style={{ color: '#53bdeb' }}
                  className='ms-1 fas fa-check-double read-icon'
                ></i>
              )}
            </span>
          </p>
        </>
      )
    }
    if (msg.messageType == 2) {
      return (
        <>
          <p
            className='py-1 px-2'
            style={{
              backgroundColor:
                msg.messageDeliveryType == 1 ? '#F6BCA7' : '#F9E4ACE5',
              color: 'black',
              maxWidth: '60%',

              marginLeft: msg.messageDeliveryType == 1 ? 'auto' : '',
              position: 'relative',
            }}
          >
            <span
              style={{
                display: 'block',
                marginBottom: '14px',
                marginRight: '40px',
                fontSize: 14,

                wordBreak: 'break-all',
                whiteSpace: 'pre-line',
              }}
            >
              <p>
                <AudioPlayer
                  controlsList='nodownload'
                  src={msg.filePath}
                  style={{
                    height: '30px',
                  }}
                  className={
                    msg.messageDeliveryType == 1
                      ? 'sent-audio'
                      : 'received-audio'
                  }
                />
              </p>
            </span>

            <span
              className='ms-auto'
              style={{
                fontSize: 10,
                position: 'absolute',
                bottom: '5px',
                right: '5px',
                color: 'whitesmoke',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'black',
              }}
            >
              <Moment format='hh:mm a'>{msg.messageDate}</Moment>
              {msg.messageDeliveryType == 1 && msg.isRead && (
                <i
                  style={{ color: '#53bdeb' }}
                  className='ms-1 fas fa-check-double read-icon'
                ></i>
              )}
            </span>
          </p>
        </>
      )
    }
    return <></>
  }

  const markAsResolved = () => {
    let handlerId = chatDetails?.handlerId
    axios
      .get(`api/VLCShopKeeperTicket/DistributorMarkResolved/${handlerId}`)
      .then(({ data }) => {
        setChatActive(false)
        toast.success('Ticket resolved')
      })
      .catch(function (error) {})
  }

  const reallocateTicket = (category) => {
    setTicketCategory(category)
    let handlerId = chatDetails?.handlerId
    axios
      .get(
        `api/VLCShopKeeperTicket/ReAllocateTicket/${handlerId}/${category.value}`
      )
      .then(({ data }) => {
        if (data) {
          setChatActive(false)
          toast.success('Ticket reallocated')
        }
      })
      .catch(function (error) {})
  }

  const getChatMessages = async () => {
    // const req = {
    //   skipRecord: props.ConnectConversation.messages.length,
    //   distUserId: '08dbc577-d611-4e07-8183-92586336146d',
    //   ticketId: '08dbca2d-0e0a-45fb-8912-7738593e6d3a',
    //   distCenterId: '25dd8e46-1b1c-4e0b-a503-f9368b7e6d24',
    //   ticketCategory: '1',
    // }
    const req = {
      skipRecord: props.ConnectConversation.messages.length,
      handlerId: chatDetails.handlerId,
      ticketId: chatDetails.ticketId,
      distCenterId: chatDetails.distCenterId,
      ticketCategory: '1',
    }
    props.getChatMessages(req)
  }

  const handleScroll = () => {
    const atTop = divRef.current.scrollTop === 0

    if (atTop) {
      if (
        props.ConnectConversation.messages.length <
        props.ConnectConversation.totalRecords
      ) {
        getChatMessages()
      }
    }
  }

  const scrollBottomChat = () => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight
    }
  }

  const sendNewMessage = async () => {
    if (newMessage.trim().length > 0 && chatActive) {
      let newMsg = {
        HandlerId: chatDetails?.handlerId,
        TextMessage: newMessage,
        FilePath: '',
        MessageType: 1,
      }

      setNewMessage('')
      await props.sendMessage(newMsg)

      setTimeout(() => {
        scrollBottomChat()
      }, 300)
    }
  }

  const setHandlerVisited = (handlerId) => {
    axios
      .get(`api/VLCShopKeeperTicket/SetHandlerNotificationVisited/${handlerId}`)
      .then(({ data }) => {
        // if (data) {
        //   setChatActive(false)
        //   toast.success('Ticket resolved')
        // }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    const convo = JSON.parse(localStorage.getItem('_convo'))
    setChatDetails(convo)
    // getChatMessages()
    if (_appUserRole != 'Administrator') {
      setHandlerVisited(convo.handlerId)
    }
  }, [])

  useEffect(() => {
    if (chatDetails != null) {
      getChatMessages()
      setTicketCategory(
        categoryColumns.find((x) => x.value == chatDetails?.handlerCategoryCode)
      )
    }
  }, [chatDetails])

  useEffect(() => {
    let isResolved = false
    let data = props.ConnectConversation
    if (data) {
      let msgs = [...data.messages]
      const groupedItems = {}
      msgs.forEach((item) => {
        const messageDate = new Date(item.messageDate)
          .toISOString()
          .split('T')[0]
        if (groupedItems[messageDate]) {
          groupedItems[messageDate].push(item)
        } else {
          groupedItems[messageDate] = [item]
        }
        if (item.messageDeliveryType == 3) {
          isResolved = true
        }
        if (item.messageDeliveryType != 1) {
          setFirstMsgSent(true)
        }
      })
      setMessageList(groupedItems)
      setChatActive(isResolved == true ? false : data.chatActive)
    }
  }, [props.ConnectConversation])

  useEffect(() => {
    if (messageList != null && initalLoad) {
      setInitalLoad(false)
      setTimeout(() => {
        scrollBottomChat()
      }, 300)
    }

    if (
      messageList != null &&
      !initalLoad &&
      !props.ConnectConversation.readMsgs
    ) {
      setTimeout(() => {
        divRef.current.scrollTop += 450
      }, 400)
    }

    if (_appUserRole == 'Administrator') {
      setChatActive(false)
    }
  }, [messageList])

  return (
    <VzPageContainer title='Connect Details'>
      <div>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Connect Details</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col lg={8} md={9}>
            <div className='form-group px-4 py-2'>
              <p className='p-0 m-0'>Title : {ticketCategory.label}</p>
              <p className='p-0 m-0'>Ticket ID : {chatDetails?.ticketSrNo}</p>
            </div>
          </Col>
          <Col lg={4} md={5} sm={5}>
            <p>Category</p>

            <div className='form-group'>
              <ReactSelect
                id='category'
                name='category'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Re-allocate Category'
                isMulti={false}
                value={ticketCategory}
                isClearable={false}
                onChange={(data) => {
                  reallocateTicket(data)
                }}
                options={categoryColumns}
                isDisabled={!chatActive}
              ></ReactSelect>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className=' px-2 border' style={{ minWidth: '300px' }}>
            {
              <>
                <Row
                  className=' border py-2'
                  style={{
                    backgroundColor: '#D9D9D9',
                    color: 'black',
                    fontSize: '12px',
                  }}
                >
                  <Col xs={12}>
                    <Row className='py-1'>
                      <Col>
                        Distribution Center: {chatDetails?.distCenterName}
                      </Col>
                      <Col>Shop Code: {chatDetails?.vizShopCode}</Col>
                      <Col>OB ID: {chatDetails?.vizShopObId}</Col>
                    </Row>
                    <Row className='py-1'>
                      <Col>Shop Name: {chatDetails?.vizShopName}</Col>
                      <Col>Contact Number: {chatDetails?.vizShopContact}</Col>
                      <Col>Address: {chatDetails?.vizShopAddress}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      height: '55vh',
                      overflowY: 'auto',
                      scrollBehavior: 'smooth',
                    }}
                    onScroll={handleScroll}
                    ref={divRef}
                  >
                    {showSpinner && <Spinner />}
                    {Object.entries(messageList).map(([date, items]) => (
                      <>
                        <DateSeparator date={date} />

                        {items.map((item, index) => (
                          <li
                            style={{
                              listStyleType: 'none',
                              display: 'flex',
                              // height: 'normal',
                            }}
                            key={item.id}
                          >
                            <Message msg={item} />
                          </li>
                        ))}
                      </>
                    ))}
                    {chatActive && firstMsgSent && (
                      <p className='d-flex justify-content-end'>
                        <Button variant='primary' onClick={markAsResolved}>
                          Issue Resolved
                        </Button>
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col align='right' className='my-2'></Col>
                </Row>
                <Row
                  style={{
                    height: '10vh',
                    // backgroundColor: '#80808070',
                    color: 'white',
                  }}
                  // className='py-3'
                >
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Input
                      className='mx-3'
                      style={{
                        maxWidth: '80%',
                        resize: 'none',
                        height: '10px',
                        border: 'none',
                        color: 'black',
                        background: chatActive ? 'rgb(245 245 245)' : '#D9D9D9',
                      }}
                      value={newMessage}
                      onChange={(res) => setNewMessage(res.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault()
                          sendNewMessage()
                        }
                      }}
                      type='textarea'
                      placeholder='Type a message'
                      disabled={!chatActive}
                    />
                    <button
                      title='Send'
                      onClick={sendNewMessage}
                      className=' btn btn-sm'
                      style={{
                        color: chatActive ? 'rgb(247 135 95)' : '#f6bca7',
                        // backgroundColor: 'transparent',
                      }}
                    >
                      <i className={'fas fa-paper-plane  fa-2x'}></i>
                    </button>
                  </Col>
                </Row>
              </>
            }
          </Col>
        </Row>
      </div>
    </VzPageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    ConnectConversation: state.ConnectConversation,
  }
}

export default connect(mapStateToProps, {
  getChatMessages,
  resetChat,
  sendMessage,
})(ConnectConversation)
