import { GET_Shop_wise_Payment_Status_Report } from '../../../Types'
import _ from 'lodash'

const PaymentStatusReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_Shop_wise_Payment_Status_Report:
      return [...action.payload]
    default:
      return state
  }
}
export default PaymentStatusReportReducer
