import { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { VzPageContainer } from '../../layouts/VzPageContainer'
import ManageMainInformations from './MainInformations/ManageMainInformations'
import ManagePrincipalDetails from './ManagePrincipals/ManagePrincipalDetails'
import ManageBankDetails from './BankDetails/ManageBankDetails'
import ManageDistributionCenters from './DistributionCenters/ManageDistributionCenters'
import ManageMFIDetails from './MFIDetails/ManageMFIDetails'
// import ManageDIUsers from "./ManageUsers/ManageDIUsers";
import DistributorDetailedSummary from './DistributorSummary/DistributorDetailedSummary'
import ManageQRorTillDetails from './QRorTillDetails/ManageQRorTillDetails'
import ProductConfiguration from './ProductConfiguration/ProductConfiguration'

import { connect } from 'react-redux'

class NewDistributorInductionForm extends Component {
  render() {
    return (
      <>
        <VzPageContainer title='Distributor Induction Form'>
          <Tabs
            defaultActiveKey='MainInformation'
            id='uncontrolled-tab-example'
            className='mb-3'
          >
            <Tab eventKey='MainInformation' title='Main Information'>
              <ManageMainInformations />
            </Tab>
            <Tab
              eventKey='PrincipalDetails'
              title='Principal Details'
              // disabled={this.props.distributorMainInfo?.id ? false : true}
            >
              <ManagePrincipalDetails />
            </Tab>
            <Tab
              eventKey='BankDetails'
              title='Bank Details'
              // disabled={this.props.distributorPrincipals?.length > 0 ? false : true}
            >
              <ManageBankDetails />
            </Tab>
            <Tab
              eventKey='DistributionCenters'
              title='Distribution Centers'
              // disabled={this.props.bankDetails?.length > 0 ? false : true}
            >
              <ManageDistributionCenters />
            </Tab>
            <Tab
              eventKey='MFIDetails'
              title='MFI Details'
              // disabled={this.props.distributionCenters?.length > 0 ? false : true}
            >
              <ManageMFIDetails />
            </Tab>
            {/* <Tab
              eventKey="UserManagement"
              title="User Management"
              // disabled={this.props.distributorMainInfo?.id ? false : true}
            >
              <ManageDIUsers />
            </Tab> */}
            <Tab
              eventKey='QR/TillDetails'
              title='QR/Till Details'
              // disabled={this.props.distributorMainInfo?.id ? false : true}
            >
              <ManageQRorTillDetails />
            </Tab>
            <Tab
              eventKey='ProductConfiguration'
              title='Product Configuration'
              // disabled={this.props.distributorMainInfo?.id ? false : true}
            >
              <ProductConfiguration />
            </Tab>
            <Tab
              eventKey='DistributorCompleteInformations'
              title='Profile'
              mountOnEnter={true}
              // disabled={this.props.distributorMainInfo?.id ? false : true}
            >
              <DistributorDetailedSummary />
            </Tab>
          </Tabs>
        </VzPageContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    distributorMainInfo: state.distributorMainInfo,
    distributorPrincipals: state.distributorPrincipals,
    bankDetails: state.bankDetails,
    distributionCenters: state.distributionCenters,
  }
}
export default connect(mapStateToProps)(NewDistributorInductionForm)
