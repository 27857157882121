import { useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { VzPageContainer } from '../../layouts/VzPageContainer'
import Moment from 'react-moment'
import ServerSideDataTable from '../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { addAdvertisement, updateAdvertisement, deleteAdvertisement, getAdvertisements, resetAdvertisements } from './AdvertisementsActions'

//Form releated imports
import FormikControl from '../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { GET_VLP_PAYER_DDL, GET_DISTRIBUTOR_DDL, GET_DISTRIBUTIONCENTER_DDL } from '../../Types';
import axios from '../../apis/Api'
import { getDropdownList } from '../Shared/dropdownListActions';
import { getAdvertisementStatus, getAdvertisementStatusName } from '../../utilities/FUNCTIONS'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { uploadFile } from '../../utilities/ImageUpload'
import { getImageSize } from 'react-image-size'
import { set } from 'react-hook-form'
import { isPermittedPage } from '../../apis/Utility'

const Advertisements = (props) => {

    // pagination states
    const [currentIndex, setCurrentIndex] = useState();
    const [currentPageNo, setCurrentPageNo] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    function formRequestObject() {
        let requestData = {
            pageSize: currentPageSize,
            pageNo: currentPageNo,
            searchText: searchText,
        }
        return requestData
    }

    function fetchReportData(request) {
        let finalRequest = request == null ? formRequestObject() : request
        props.getAdvertisements(finalRequest)
    }

    const getData = (page) => {
        setCurrentPageNo(page)
        var request = { ...formRequestObject(), pageNo: page }
        fetchReportData(request)
    }

    const pageSizeChanged = (pageSize) => {
        setCurrentPageNo(0)
        setCurrentPageSize(pageSize)
        var request = { ...formRequestObject(), pageSize: pageSize }
        fetchReportData(request)
    }

    const searchChanged = (text) => {
        setSearchText(text)
    }

    useEffect(() => {
        setCurrentPageNo(0)
        fetchReportData()
    }, [searchText])

    useEffect(() => {
        if (props.report && props.report.totalRecords > 0) {
            setTotalRecords(props.report.totalRecords)
        } else {
            setTotalRecords(0)
        }
    }, [props.report])

    const columns = [
        {
            Header: 'Title',
            accessor: 'title',
        },
        {
            Header: 'Start Date',
            accessor: 'startDate',
            Cell: (row) => (
                <div style={{ width: '75px' }}>
                    <Moment format='DD/MM/YYYY'>{row.value}</Moment>
                </div>
            ),
        },
        {
            Header: 'End Date',
            accessor: 'endDate',
            Cell: (row) => (
                <div style={{ width: '75px' }}>
                    <Moment format='DD/MM/YYYY'>{row.value}</Moment>
                </div>
            ),
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: (row) => (
                <div>
                    {getAdvertisementStatusName(row.value)}
                </div>
            ),
        },
        {
            Header: 'Positioning',
            accessor: 'position',
        },
        {
            Header: 'Action',
            accessor: 'id',
            Cell: (row) => (
                <div style={{ width: '106px' }}>
                    {isPermittedPage('View Details', props.actions, props.userRole) &&
                        <button
                            onClick={() => editOrView(row?.cell?.value)}
                            className=' btn btn-sm'
                        >
                            <i className='fas fa-eye'></i>
                        </button>
                    }
                    &nbsp;
                    {isPermittedPage('Edit', props.actions, props.userRole) &&
                        <button onClick={() => editOrView(row?.cell?.value, false)} className='btn btn-sm'>
                            <i className='fas fa-edit'></i>
                        </button>
                    }
                    &nbsp;
                    {isPermittedPage('Delete', props.actions, props.userRole) &&
                        <button onClick={() => Delete(row?.cell?.value, false)} className='btn btn-sm'>
                            <i className='fas fa-trash'></i>
                        </button>
                    }
                    &nbsp;
                </div>
            ),
        },
    ]

    //=========================================|| Form for Add - Start ||==============================================

    //form
    // const [initialValues] = useState({
    //     id: '',
    //     title: '',
    //     payer: '',
    //     status: '',
    //     distributor: '',
    //     center: '',
    //     principal: '',
    //     mfi: '',
    //     startDate: '',
    //     endDate: '',
    //     position: 0,
    // });

    const [currentEntity, setCurrentEntity] = useState({
        id: '',
        title: '',
        payer: '',
        status: '',
        distributor: '',
        center: '',
        principal: '',
        mfi: '',
        startDate: '',
        endDate: '',
        position: 0,
    });

    let specificenters = [];
    const [modalShow, setModalShow] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [ImageURL, setImageURL] = useState(null);
    const [payer, setPayer] = useState([]);

    const [distributorsOptions, setDistributorsOptions] = useState([])
    const [centerOptions, setCenterOptions] = useState([])
    const [principalOptions, setPrincipalOptions] = useState([])
    const [mfiOptions, setMfiOptions] = useState([])

    const updateStatus = (value) => {
        currentEntity.status = value;
    }

    const setStatusValue = () => {
        let today = new Date().toJSON().slice(0,10);
        var day = 60 * 60 * 24 * 1000;
        let startDateObj = currentEntity.startDate == '' ? null : new Date(currentEntity.startDate);
        let endDateObj = currentEntity.endDate == '' ? null : new Date(currentEntity.endDate);
        if (startDateObj != null && endDateObj != null) {
            var startDate = new Date(startDateObj.getTime() + day).toJSON().slice(0,10);
            //let startDate = startDateObj.toLocaleDateString();
            var endDate = new Date(endDateObj.getTime() + day).toJSON().slice(0,10);
            //let endDate = endDateObj.toLocaleDateString();

            if (startDate <= today && endDate >= today) {
                updateStatus('Active');
            }
            else if (!isEdit && endDate < today) {
                updateStatus('Complete');
            }
            // else if (isEdit && endDate < today) {
            //     updateStatus('Terminated');
            // }
            else if (startDate > today) {
                updateStatus('Create');
            }
        }
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        payer: Yup.string().required('Payer is required'),
        distributor: Yup.array()
            .min(1, 'Assign at least one Distributor')
            .required(),
        center: Yup.array().min(1, 'Assign at least one Center').required(),
        principal: Yup.array().min(1, 'Assign at least one Principal').required(),
        mfi: Yup.array().min(1, 'Assign at least one Mfi').required(),
        startDate: Yup.string().required('StartDate is required'),
        endDate: Yup.string().required('EndDate is required'),
        position: Yup.number().required('Positioning is required')
            .min(1, 'Minimum position can be 1'),
    })

    const handleSubmit = async (data) => {

        if (ImageURL == null) {
            alert("Please select image")
            return
        }
        let distributorCS = ''
        currentEntity.distributor?.forEach((element, index, arr) => {
            distributorCS +=
                index === arr.length - 1 ? element.value : element.value + ','
        })

        let centerCS = ''
        currentEntity.center?.forEach((element, index, arr) => {
            centerCS +=
                index === arr.length - 1 ? element.value : element.value + ','
        })

        let principalCS = ''
        currentEntity.principal?.forEach((element, index, arr) => {
            principalCS +=
                index === arr.length - 1 ? element.value : element.value + ','
        })

        let MfiCS = ''
        currentEntity.mfi?.forEach((element, index, arr) => {
            MfiCS += index === arr.length - 1 ? element.value : element.value + ','
        })

        let request = {
            title: currentEntity.title,
            startDate: new Date(
                Date.parse(
                    new Intl.DateTimeFormat('fr-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }).format(currentEntity.startDate)
                )
            )
                .toISOString()
                .split('T')[0],

            endDate: new Date(
                Date.parse(
                    new Intl.DateTimeFormat('fr-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }).format(currentEntity.endDate)
                )
            )
                .toISOString()
                .split('T')[0],

            status: getAdvertisementStatus(currentEntity.status),
            payerId: currentEntity.payer,
            uRL: ImageURL,
            position: currentEntity.position,
            distIds: distributorCS,
            distCenterIds: centerCS,
            PrincipleIds: principalCS,
            mfiLenderIds: MfiCS,
        }

        if (!isEdit) {
            await props.addAdvertisement(request);
            setModalShow(false)
        } else {
            await props.updateAdvertisement(currentEntity.id, request);
            setModalShow(false)
        }
        fetchReportData();
    }

    const AddAdvertisement = () => {

        setIsView(false)
        setIsEdit(false)

        let vizProPayer = props.vlpPayers.filter((x) => x.label == 'Vizpro')[0]

        let All = [{ value: '', label: 'All' }];
        currentEntity.status = 'Create';
        currentEntity.title = '';
        currentEntity.payer = vizProPayer.value;
        currentEntity.distributor = All;
        currentEntity.principal = All;
        currentEntity.mfi = All;
        currentEntity.center = All;
        currentEntity.startDate = '';
        currentEntity.endDate = '';
        currentEntity.id = '';
        currentEntity.position = 0;

        setPayer(vizProPayer)
        setCenterOptions(All)

        setImageURL(null)
        setModalShow(true)
    }

    const editOrView = async (id, isViewOnly = true) => {
        setIsEdit(!isViewOnly)
        setIsView(isViewOnly)

        await axios.get(`api/Advertisement/${id}`).then(
            (result) => {
                debugger
                let data = result.data.response;
                if (data) {
                    debugger
                    const currentDate = new Date();
                    const adEndDate = new Date(data.endDate);
                    const adEndDateOnly = new Date(adEndDate.getFullYear(), adEndDate.getMonth(), adEndDate.getDate());
                    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    if (data.status == 3 || data.status == 4 || (data.status == 5 && currentDateOnly > adEndDateOnly))
                        setIsView(true);

                    let advertisementData = {
                        id: id,
                        title: data.title,
                        payer: data.payerId,
                        status: getAdvertisementStatusName(data.status),
                        startDate: new Date(data.startDate),
                        endDate: new Date(data.endDate),
                        position: data.position,
                    }

                    if (props.vlpPayers != null) {
                        let payreList = props.vlpPayers.filter(
                            (x) => data.payerId.includes(x.value) && x.value != ''
                        )
                        setPayer(payreList)
                    }

                    let all = { value: '', label: 'All' }
                    if (data.distIds != null && data.distIds != '') {
                        let DistList = distributorsOptions.filter(
                            (x) => data.distIds.includes(x.value) && x.value != ''
                        )
                        advertisementData = { ...advertisementData, distributor: DistList }
                    } else {
                        advertisementData = { ...advertisementData, distributor: [all] }
                    }

                    getSpecificCenters(data.distIds).then(() => {
                        if (data.distCenterIds != null && data.distCenterIds != "") {
                            let CenterList = specificenters.filter(
                                (x) => data.distCenterIds.includes(x.value) && x.value != ''
                            )
                            advertisementData = { ...advertisementData, center: CenterList }
                        } else {
                            advertisementData = { ...advertisementData, center: [all] }
                        }

                        if (data.principleIds != null && data.principleIds != '') {
                            let principalList = principalOptions.filter(
                                (x) => data.principleIds.includes(x.value) && x.value != ''
                            )
                            advertisementData = { ...advertisementData, principal: principalList }
                        } else {
                            advertisementData = { ...advertisementData, principal: [all] }
                        }

                        if (data.mfiLenderIds != null && data.mfiLenderIds != '') {
                            let mfiList = mfiOptions.filter(
                                (x) => data.mfiLenderIds.includes(x.value) && x.value != ''
                            )
                            advertisementData = { ...advertisementData, mfi: mfiList }
                        } else {
                            advertisementData = { ...advertisementData, mfi: [all] }
                        }

                        setImageURL(data.url)

                        currentEntity.id = advertisementData.id;
                        currentEntity.mfi = advertisementData.mfi;
                        currentEntity.center = advertisementData.center;
                        currentEntity.distributor = advertisementData.distributor;
                        currentEntity.principal = advertisementData.principal;
                        currentEntity.payer = advertisementData.payer;
                        currentEntity.title = advertisementData.title;
                        currentEntity.status = advertisementData.status;
                        currentEntity.startDate = advertisementData.startDate;
                        currentEntity.endDate = advertisementData.endDate;
                        currentEntity.position = advertisementData.position;

                        setModalShow(true)
                    });
                }
            }).catch(function (error) { })
    }

    const Delete = (id) => {
        confirmAlert({
          closeOnEscape: true,
          closeOnClickOutside: true,
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <Modal.Dialog>
                  <Modal.Header id='customized-dialog-title'>
                    <Modal.Title>Confirm to Delete</Modal.Title>
                    <button
                      type='button'
                      onClick={() => onClose()}
                      className='btn-close'
                      aria-label='No'
                    ></button>
                  </Modal.Header>
                  <Modal.Body dividers>
                    <p>Are you sure you want to delete this?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        DeleteRecord(id)
                        onClose()
                      }}
                      variant='outline-primary'
                    >
                      Yes, Delete it!
                    </Button>
                    <Button
                      variant='primary'
                      onClick={onClose}
                      className='btn btn-primary'
                    >
                      No
                    </Button>
                  </Modal.Footer>
                </Modal.Dialog>
              </div>
            )
          },
        })
      }

      const DeleteRecord = async (id) => {
        await props.deleteAdvertisement(id)
    
        if (totalRecords > 5 && props.report?.data.length == 1) {
          let previousPage = currentPageNo > 0 ? currentPageNo - 1 : 0
          setCurrentPageNo(previousPage)
          let requestData = {
            pageSize: currentPageSize,
            pageNo: previousPage,
            searchText: searchText,
            principal: null,
            isIncomplete: true,
          }
          props.getAdvertisements(requestData)
        } else {
          let requestData = {
            pageSize: currentPageSize,
            pageNo: currentPageNo,
            searchText: searchText,
            principal: null,
            isIncomplete: true,
          }
          props.getAdvertisements(requestData)
        }
      }

    const getMfiList = async () => {
        await axios
            .post('api/Advertisement/GetMfisAndLenderDropdownList')
            .then(({ data }) => {
                let all = { value: '', label: 'All' }
                setMfiOptions([
                    all,
                    ...data?.response?.map(function (item) {
                        return {
                            value: item.value,
                            label: item.text,
                        }
                    }),
                ])
            })
            .catch(function (error) { })
    }

    const getPrincipalList = async () => {
        await axios
            .get('api/DistributorPrincipalDetails/GetAllDistributorPrinicipals')
            .then(({ data }) => {
                let all = { value: '', label: 'All' }
                setPrincipalOptions([
                    all,
                    ...data?.response?.map(function (item) {
                        return {
                            value: item.id,
                            label: item.principalName,
                        }
                    }),
                ])
            })
            .catch(function (error) { })
    }

    const getSpecificCenters = async (distributor) => {
        if (distributor) {
            await axios.get(`api/DistCenter/GetDistsCenterDDL/${distributor}`).then(
                ({ data }) => {
                    let centers = data?.response.map(function (item) {
                        return {
                            value: item.value,
                            label: item.label,
                        }
                    });
                    specificenters = centers;
                    specificenters.unshift({ value: '', label: 'All' });
                });
        }
    }

    useEffect(() => {
        props.getDropdownList(`api/VLPPayer`, GET_VLP_PAYER_DDL)
        props.getDropdownList(`api/Distributor/GetDistributorsDDL`, GET_DISTRIBUTOR_DDL)
        getMfiList();
        getPrincipalList();
    }, []);

    const distributorChanged = (distributor) => {

        let checkAll = distributor.filter(
            (x) => x.label == 'All'
        )
        if (checkAll.length > 0) {
            currentEntity.distributor = checkAll;
        }
        else {
            currentEntity.distributor = distributor;
            currentEntity.center = '';
        }
        setCenterOptions([]);
    }

    const distCenterChanged = (value) => {

        let checkAll = value.filter(
            (x) => x.label == 'All'
        )
        if (checkAll.length > 0) {
            currentEntity.center = checkAll
            return;
        }
        currentEntity.center = value
    }

    const principalChanged = (value) => {

        let checkAll = value.filter(
            (x) => x.label == 'All'
        )
        if (checkAll.length > 0) {
            currentEntity.principal = checkAll;
            return;
        }
        currentEntity.principal = value;
    }

    useEffect(() => {
        let all = { value: '', label: 'All' }
        setDistributorsOptions([
            all,
            ...props.allDistributorList.map(function (item) {
                return {
                    value: item.value,
                    label: item.label,
                }
            }),
        ])
    }, [props.allDistributorList])

    useEffect(() => {
        let all = { value: '', label: 'All' }
        setCenterOptions([
            all,
            ...props.distCentersddlList.map(function (item) {
                return {
                    value: item.value,
                    label: item.label,
                }
            }),
        ]);
    }, [props.distCentersddlList])

    useEffect(() => {
        if (
            !isView &&
            currentEntity.distributor &&
            currentEntity.distributor.length > 0 &&
            currentEntity.distributor[0]?.label != 'All'
        ) {
            let ids = '';
            currentEntity.distributor.forEach((element, index, arr) => {
                ids += index === arr.length - 1 ? element.value : element.value + ','
            })

            props.getDropdownList(
                `api/DistCenter/GetDistsCenterDDL/${ids}`,
                GET_DISTRIBUTIONCENTER_DDL
            )
        }
    }, [currentEntity.distributor])

    useEffect(() => {
        let all = { value: '', label: 'All' }

        if (mfiOptions && mfiOptions > 0) {
            setMfiOptions([
                all,
                mfiOptions.map(function (item) {
                    return {
                        value: item.value,
                        label: item.text,
                    }
                }),
            ])
        }
    }, [mfiOptions])


    const dispatch = useDispatch()
    const saveImage = async (event) => {
        dispatch(showLoading());
        uploadFile(event.target.files[0])
            .then(async (response) => {
                dispatch(hideLoading());
                setImageURL(response);
                let dimensions = await getImageSize(response);


                if (dimensions.width == 420 || dimensions.height == 200) {
                    setImageURL(null);
                    alert("Image size is incorrect, Image with 420 X 200 dimensions are allowed.");
                }
            })
            .catch(function (error) {
                dispatch(hideLoading())
                alert("Image upload failed")
            });
    }
    const removeImage = () => {
        setImageURL(null)
    }
    useEffect(() => {
    }, [ImageURL])

    //=========================================|| Form for Add - End   ||==============================================

    return (
        <div>
            <VzPageContainer title='Advertisements'>
                <Row>
                    <Col style={{ float: 'left' }}>
                        <h4>
                            <b>Advertisements</b>
                        </h4>
                        <br />
                    </Col>
                </Row>
                {isPermittedPage('Add New', props.actions, props.userRole) &&
                    <Row>
                        <Col md={9} style={{ float: 'right' }}>
                        </Col>
                        <Col md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={AddAdvertisement}
                                className='m-3'
                                variant='outline-primary'
                            >
                                <i className='fa fa-plus' /> Add New
                            </Button>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col>
                        <ServerSideDataTable
                            data={props.report?.data ?? []}
                            columns={columns}
                            getTrProps={(event, data) => null}
                            goToPreviousPage={(val) => getData(val)}
                            goToNextPage={(val) => getData(val)}
                            totalRecords={totalRecords}
                            pageSizeChange={(size) => pageSizeChanged(size)}
                            myPageSize={currentPageSize}
                            myIndex={currentIndex}
                            pageNo={currentPageNo}
                            searchChanged={searchChanged}
                        />
                    </Col>
                </Row>

                {/* Form Start*/}
                <row>
                    <Modal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        backdrop='static'
                        keyboard={false}
                        size='xl'
                    >
                        <Formik
                            enableReinitialize
                            initialValues={currentEntity}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {(formik) => (
                                <Form autoComplete='off'>
                                    <Modal.Header closeButton>
                                        <Modal.Title id='contained-modal-title-vcenter'>
                                            Details
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Container>
                                            <Row>
                                                <Col xs={6} md={6}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            readOnly={isView}
                                                            control='input'
                                                            type='text'
                                                            label='Title'
                                                            name='title'
                                                            handleOnChange={(e) => (currentEntity.title = e)}
                                                            value={currentEntity.title}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='react-select'
                                                            type='text'
                                                            label='Payer'
                                                            name='payer'
                                                            value={payer}
                                                            options={props.vlpPayers}
                                                            handleOnChange={(value) => {
                                                                currentEntity.payer = value.value
                                                                setPayer(value)
                                                            }}
                                                            isDisabled={isView}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            readOnly={true}
                                                            control='input'
                                                            type='text'
                                                            label='Status'
                                                            name='status'
                                                            handleOnChange={(e) => (currentEntity.status = e)}
                                                            value={currentEntity.status}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={3} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='react-select'
                                                            type='distributor'
                                                            label='Distributor'
                                                            name='distributor'
                                                            value={currentEntity.distributor}
                                                            options={distributorsOptions}
                                                            handleOnChange={(value) => {
                                                                distributorChanged(value)
                                                            }}
                                                            isDisabled={isView}
                                                            isMultiSelect={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={3} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='react-select'
                                                            type='text'
                                                            label='Distribution Center'
                                                            name='center'
                                                            value={currentEntity.center}
                                                            options={centerOptions}
                                                            handleOnChange={(value) => {
                                                                distCenterChanged(value)
                                                            }}
                                                            isDisabled={isView}
                                                            isMultiSelect={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={3} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='react-select'
                                                            type='text'
                                                            label='Principal'
                                                            name='principal'
                                                            value={currentEntity.principal}
                                                            options={principalOptions}
                                                            handleOnChange={(value) => {
                                                                principalChanged(value)
                                                            }}
                                                            isDisabled={isView}
                                                            isMultiSelect={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={3} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='react-select'
                                                            type='text'
                                                            label='Mfi'
                                                            name='mfi'
                                                            value={currentEntity.mfi}
                                                            options={mfiOptions}
                                                            handleOnChange={(value) => {
                                                                let checkAll = value.filter(
                                                                    (x) => x.label == 'All'
                                                                )
                                                                if (checkAll.length > 0) {
                                                                    currentEntity.mfi = checkAll
                                                                    return
                                                                }
                                                                currentEntity.mfi = value
                                                            }}
                                                            isDisabled={isView}
                                                            isMultiSelect={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={6} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='date'
                                                            type='text'
                                                            label='Start Date'
                                                            name='startDate'
                                                            handleOnChange={(e) => {
                                                                (currentEntity.startDate = e)
                                                                setStatusValue()
                                                            }}
                                                            value={currentEntity.startDate}
                                                            minDate={isEdit ? currentEntity.startDate : new Date()}
                                                            isDisabled={isView}
                                                            maxDate={
                                                                currentEntity.endDate ? currentEntity.endDate : ''
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            control='date'
                                                            type='text'
                                                            label='End Date'
                                                            name='endDate'
                                                            value={currentEntity.endDate}
                                                            handleOnChange={(e) => {
                                                                (currentEntity.endDate = e)
                                                                setStatusValue()
                                                            }}
                                                            minDate={
                                                                currentEntity.startDate
                                                                    ? currentEntity.startDate
                                                                    : new Date()
                                                            }
                                                            isDisabled={isView}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <div className='form-group'>
                                                        <FormikControl
                                                            formik={formik}
                                                            readOnly={isView}
                                                            control='input'
                                                            type='number'
                                                            label='Positioning'
                                                            name='position'
                                                            handleOnChange={(e) => (currentEntity.position = e)}
                                                            value={currentEntity.position}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <row>
                                                <Col xs={6} md={4}>
                                                    <div className='form-group '>
                                                        {!ImageURL && (
                                                            <>
                                                                <div>Select Image</div>
                                                                <input
                                                                    type='file'
                                                                    disabled={isView}
                                                                    name='url'
                                                                    accept='image/*'
                                                                    onChange={async (event) => await saveImage(event)}
                                                                />
                                                            </>
                                                        )}
                                                        <div >
                                                            {ImageURL && (
                                                                <>
                                                                    <div>
                                                                        <img
                                                                            style={{ border: '1px solid #ccc' }}
                                                                            className='mt-2'
                                                                            alt='not fount'
                                                                            width={'175px'}
                                                                            height={'150px'}
                                                                            src={ImageURL}
                                                                        />
                                                                    </div>
                                                                    {!isView && (
                                                                        <div className='mt-3'>
                                                                            <Button
                                                                                onClick={removeImage}
                                                                                className='btn-sm ml-5'
                                                                                style={{ backgroundColor: '#ea5f2d' }}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            </row>
                                        </Container>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {isView ? null : (
                                            <>
                                                <input
                                                    type='submit'
                                                    className='btn btn-primary'
                                                    value={!isEdit ? 'Save' : 'Update'}
                                                />
                                                <Button
                                                    className='btn btn-default'
                                                    onClick={() => setModalShow(false)}
                                                >
                                                    Close
                                                </Button>
                                            </>
                                        )}
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </row>
                {/* Form End*/}

            </VzPageContainer>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        report: state.Advertisements,
        //Forms Releated
        vlpPayers: state.ddlList.vlpPayers ? state.ddlList.vlpPayers : [],
        allDistributorList: state.ddlList.allDistributorList
            ? state.ddlList.allDistributorList
            : [],
        distCentersddlList: state.ddlList.selectedDistCentersList
            ? state.ddlList.selectedDistCentersList
            : [],
    }
}
export default connect(mapStateToProps, {
    getAdvertisements,
    resetAdvertisements,
    //Forms Releated
    getDropdownList,
    addAdvertisement,
    updateAdvertisement,
    deleteAdvertisement
})(Advertisements)
