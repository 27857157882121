import axios from '../../apis/Api'
import history, { logout } from '../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  SET_SOCKET,
  SAVE_SOCKET_ID,
  MESSAGE_RECEIVED,
  MESSAGES_READ,
  GET_CHAT_BY_ID,
  NOTIFICATION_VISITED,
  NEW_NOTIFICATION,
} from '../../Types'

export const setSocket = (socket) => async (dispatch) => {
  dispatch({
    type: SET_SOCKET,
    payload: socket,
  })
}

export const messageReceived = (msg) => async (dispatch) => {
  dispatch({
    type: MESSAGE_RECEIVED,
    payload: msg,
  })
}

export const messagesRead = (list) => async (dispatch) => {
  dispatch({
    type: MESSAGES_READ,
    payload: list,
  })
}

export const notificationVisited = (msg) => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_VISITED,
    payload: msg,
  })
}

export const newNotification = (msg) => async (dispatch) => {
  dispatch({
    type: NEW_NOTIFICATION,
    payload: msg,
  })
}

export const getNewChat = (id) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/VizUserChat/GetByChatId/` + id)
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: GET_CHAT_BY_ID,
          payload: data,
        })
        dispatch(hideLoading())
      }
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
