import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import axios from '../../../apis/Api'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { toast } from 'react-toastify'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Moment from 'react-moment'
import FormikControl from '../../../layouts/Input/FormikControl'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { getDistributorCoinsDisbursementReport, downloadJob } from './DistributorConisDisbursmentReportAction'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { GET_DIST_COINS_DISBURSEMENT_REPORT, GET_DISTRIBUTOR_DDL, GET_ADDJOB_DISTRIBUTIONCENTER_DDL } from '../../../Types'
//import { date } from 'yup'
import * as Yup from 'yup'
import { isPermittedPage } from '../../../apis/Utility'
import storage from '../../../apis/Storage'

const DistributorConisDisbursmentReport = (props) => {
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)

  const [filterVizId, setFilterVizId] = useState(null)
  // const [filterFromDate, setFilterFromDate] = useState(new Date(
  //   Date.parse(
  //     new Intl.DateTimeFormat('fr-CA', {
  //       year: 'numeric',
  //       month: '2-digit',
  //       day: '2-digit',
  //     }).format(new Date().setDate(new Date().getDate() - 29))
  //   )
  // ).toISOString()
  //   .split('T')[0]);
  const userRights = JSON.parse(storage.get('_userRights'))
  const [filterFromDate, setFilterFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 14)));
  const [filterToDate, setFilterToDate] = useState(new Date())
  const [filterDistributorId, setFilterDistributorId] = useState(null);
  const [filterDistributionCenterId, setFilterDistributionCenterId] = useState(null)
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])
  const [pageCount, setPageCount] = useState(0);
  const [filterValuesModal, setFilterValuesModal] = useState({
    distributorId: '',
    distributionCenterId: '',
    fromDate: new Date(new Date().setDate(new Date().getDate() - 14)),
    toDate: new Date(),
  })
  useEffect(() => {
  }, [filterVizId])
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)


  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, []);

  const Columns = [

    {
      Header: 'Distribution Name',
      accessor: 'distributorName',
    },
    {
      Header: 'Distribution Center ID',
      accessor: 'distributionCenterName',
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          {row.value ? <Moment format='DD/MM/YYYY'>{row.value}</Moment> : '--'}
        </div>
      ),
    },
    {
      Header: 'Invoice Value (Rupees)',
      accessor: 'invoiceTotals',
    },
    {
      Header: 'Total Digital Payment (Jazz + EP)',
      accessor: 'walletPayments',
    },
    {
      Header: 'Total Coins Disbursement',
      accessor: 'coinsDisbursed',
    }]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorId(null)
    setFilterDistributionCenterId(null)
    setFilterVizId(null)
    setFilterFromDate(new Date(new Date().setDate(new Date().getDate() - 14)))
    setFilterToDate(new Date())
    setFilterValuesModal({
      distributorId: '',
      distributionCenterId: '',
      fromDate: (new Date(new Date().setDate(new Date().getDate() - 14))),
      toDate: new Date(),
    })
  }

  const getData = (page) => {
    if (page < pageCount) {
      setCurrentPageNo(page);
      var request = { ...formRequestObject(), pageNo: page }
      fetchReportData(request);
    }
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0);
    setCurrentPageSize(pageSize);
    var request = { ...formRequestObject(), pageSize: pageSize }
    fetchReportData(request);
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0);
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchReportData(request);
    setShow(false);
  }

  const Download = () => {
    let request = formRequestObject(true);
    props.downloadJob(request)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData();
  }, [searchText])

  useEffect(() => {
    if (props.DistCoinsDisbursementReportData) {
      setTotalRecords(props.DistCoinsDisbursementReportData.totalRecords)
      getPageCount(props.DistCoinsDisbursementReportData.totalRecords, currentPageSize);
    } else {
      setTotalRecords(0)
    }
  }, [props.DistCoinsDisbursementReportData])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddJobDistCenterOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistCenterOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])

  // useEffect(() => {
  //   setAddJobDistCenterOptions(
  //     props.addJobDistCentersddlList.map(function (item) {
  //       return {
  //         value: item.value,
  //         label: item.label,
  //       }
  //     })
  //   )
  // }, [props.addJobDistCentersddlList])
  // get distribution centers list on change of distributorid
  useEffect(() => {
    if (filterDistributorId) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorId.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorId])

  function formRequestObject(isExport = false) {
    let requestData = {
      distributorId: filterDistributorId ? filterDistributorId.value : null,
      distributionCenterId: filterDistributionCenterId ? filterDistributionCenterId.value : null,
      fromDate: filterFromDate && formatDate(filterFromDate),
      toDate: filterToDate && formatDate(filterToDate),
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData.distributorId = userRights?.distributorId
    }
    return requestData;
  }
  function formatDate(dateInput) {
    var res = new Date(
      Date.parse(
        new Intl.DateTimeFormat('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(dateInput))
      )
    )
      .toISOString()
      .split('T')[0];
    return res;
  }

  function getPageCount(totalRecords, pageSize) {
    setPageCount(Math.ceil(totalRecords / pageSize));
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    if (checkDateRange()) {
      props.getDistributorCoinsDisbursementReport(finalRequest)
    }
  }

  const checkDateRange = () => {
    let Difference_In_Time = filterFromDate.getTime() - filterToDate.getTime()
    let Difference_In_Days = Math.abs(Difference_In_Time / (1000 * 3600 * 24))
    if (Difference_In_Days > (15 - 1)) {
      toast.error('Select date range of max 15 days')
      return false
    }

    return true
  }

  const addJobFormValidationSchema = Yup.object().shape({
    fromDate: Yup.date().required('From Date is required').nullable(),
    toDate: Yup.date().required('To Date is required').nullable(),
  })
  return (
    <VzPageContainer title='Distributor Coins Disbursement Report'>
      <div>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Distributor Coins Disbursement Report</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      validationSchema={addJobFormValidationSchema}
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor Id'
                                  name='distributorId'
                                  options={addJobDistributorsOptions}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributorId(null)
                                      return
                                    }
                                    filterValuesModal.distributorId = e.value
                                    setFilterDistributorId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributorId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Id'
                                  name='distributionCenterId'
                                  options={addJobDistCenterOptions}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributionCenterId(null)
                                      return
                                    }
                                    filterValuesModal.distributionCenterId = e.value
                                    setFilterDistributionCenterId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionCenterId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  minDate={filterValuesModal.fromDate}
                                />
                              </Col>
                            </Row>
                          </Row>

                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          {isPermittedPage('Export', props.actions, props.userRole) &&
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={Download}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-download me-1' />
                Export
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col xs={12}>
            <ServerSideDataTable
              data={props.DistCoinsDisbursementReportData.dataList ?? []}
              columns={Columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </div>
    </VzPageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    DistCoinsDisbursementReportData: state.DistCoinsDisbursementReport,
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  }
}

export default connect(mapStateToProps, {
  getDistributorCoinsDisbursementReport,
  getDropdownList,
  downloadJob,
})(DistributorConisDisbursmentReport)
