import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { GET_RSO_JOBS, RESET_DDL } from '../../../Types'

export const getRsoJobs = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(
      `api/DistShopInductionJob/GetDistRSOShopInductionJobsList`,
      postedData
    )
    .then(({ data }) => {
      dispatch({
        type: GET_RSO_JOBS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const resetDropdownList = () => async (dispatch) => {
  dispatch({
    type: RESET_DDL,
    payload: 'FilterDistCenters', ///'FilterRsos',
  })
}
