import { GET_MONTHWISE_M2D_REPORT } from '../../../Types'

const MonthWiseM2DRportReducer = (state = [], action) => {
    switch (action.type) {
        case GET_MONTHWISE_M2D_REPORT:
            return action.payload
        default:
            return state
    }
}
export default MonthWiseM2DRportReducer