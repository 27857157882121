import { React, useEffect } from 'react'
import { Field, ErrorMessage, useField } from 'formik'
import TextError from './TextError'

function Textarea(props) {
  const { label, value, name, handleOnChange, ...rest } = props
  const [, , helpers] = useField(name)
  useEffect(() => {
    if (value) {
      helpers.setValue(value)
    } ////else helpers.setValue('');
  }, [value])

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field
        className='form-control'
        as='textarea'
        id={name}
        name={name}
        value={value}
        onChange={(e) => {
          helpers.setValue(e.target.value)
          handleOnChange(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            helpers.setValue(e.target.value + '\n')
            handleOnChange(e.target.value + '\n')
          }
        }}
        {...rest}
      />
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Textarea
