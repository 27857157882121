import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT, RESET_GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT } from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as XLSX from 'xlsx'
import fileSaver from 'file-saver'

export const getInvoiceWisePaymentBreakupReport = (postedData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .post(`api/M2DReports/GetInvoiceWisePaymentBreakup`, postedData)
        .then(({ data }) => {
            dispatch({
                type: GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT,
                payload: data.response,
            })
            dispatch(hideLoading())
        })
        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (
                    error.response.status === 400 &&
                    error.response.data.errors
                ) {
                    let errorMessage = ''
                    var errors = error.response.data.errors
                    var keys = Object.keys(errors)
                    for (let j = 0; j < keys.length; j++) {
                        errorMessage += errors[keys[j]] + ' , \n'
                    }
                    toast.error(errorMessage)
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            } else toast.error('Error while processing the request')
        })
}

export const resetInvoiceWisePaymentBreakupReport = () => async (dispatch) => {
    dispatch({
        type: RESET_GET_INVOICEWISE_PAYMENT_BREAKUP_REPORT,
        payload: [],
    })
}

export const exportReport = (requestData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .post(`api/M2DReports/GetInvoiceWisePaymentBreakup`, requestData)
        .then(({ data }) => {
            const outputFilename = `InvoiceWise_Payment_Breakup_Report${Date.now()}`
            const fileType =
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            const exportToCSV = (csvData, jobNumber, fileName) => {
                const ws = XLSX.utils.json_to_sheet(csvData)
                if (ws) {
                    var range = XLSX.utils.decode_range(ws['!ref'])
                    for (var C = range.s.c; C <= range.e.c; ++C) {
                        var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
                        if (!ws[address]) continue
                        ws[address].v =
                            ws[address].v == 'distributor'
                                ? 'Distributor'
                                : ws[address].v == 'distcenter'
                                    ? 'Distribution Center'
                                    : ws[address].v == 'deliverymanTillNo'
                                        ? 'Deliveryman Till ID'
                                        : ws[address].v == 'deliverymanName'
                                            ? 'Deliveryman Name'
                                            : ws[address].v == 'deliveryManUserName'
                                                ? 'DeliveryMan User Name'
                                                : ws[address].v == 'shopCode'
                                                    ? 'Shop code'
                                                    : ws[address].v == 'vizShopId'
                                                        ? 'Viz Id'
                                                        : ws[address].v == 'shopName'
                                                            ? 'Shop Name'
                                                            : ws[address].v == 'invoiceNo'
                                                                ? 'Dist. Inv No.'
                                                                : ws[address].v == 'invoiceDate'
                                                                    ? 'Inv Date'
                                                                    : ws[address].v == 'invoiceAmount'
                                                                        ? 'Inv Amount'
                                                                        : ws[address].v == 'transactionId'
                                                                            ? 'Transaction ID'
                                                                            : ws[address].v == 'paymentDate'
                                                                                ? 'Payment Date'
                                                                                : ws[address].v == 'paidAmount'
                                                                                    ? 'Paid Amount'
                                                                                    : ws[address].v == 'mfi'
                                                                                        ? 'MFI'
                                                                                        : ws[address].v
                    }
                }
                // var sheetName = `Job_Number_${jobNumber}`
                const wb = {
                    Sheets: { data: ws },
                    SheetNames: [`data`],
                }
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
                const fileData = new Blob([excelBuffer], { type: fileType })
                fileSaver.saveAs(fileData, fileName + fileExtension)
            }
            exportToCSV(data.response?.data, '', outputFilename)
            dispatch(hideLoading())
        })

        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            }
        })
}
