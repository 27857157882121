import { GET_ADVERTISMENTS, RESET_ADVERTISMENTS } from "../../Types"

const AdvertisementsReducer = (state = [], action) => {
    switch (action.type) {
        case GET_ADVERTISMENTS:
            return action.payload
        case RESET_ADVERTISMENTS:
            return action.payload
        default:
            return state
    }
}
export default AdvertisementsReducer