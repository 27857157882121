import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import storage from '../../../apis/Storage'
import {
  ADD_AREA,
  ADD_MFI_WALLET_LIMIT,
  DELETE_AREA,
  DELETE_MFI_WALLET_LIMIT,
  RESET_MFI_WALLET_LIMIT,
} from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const addMfiWalletLimit = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/Mfi/AddMfiWalletRequiredLimit', formValues)
    .then(({ data }) => {
      if (data)
        if (formValues?.id) {
          toast.success('Mfi wallet limit updated successfully')
        } else {
          toast.success('Mfi wallet limit added successfully')
        }
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const deleteMfiWalletLimit = (id) => async (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${storage.get('_appToken')}` },
  }

  await axios
    .delete(`api/Mfi/DeleteMfiWalletRequiredLimit/${id}`, config)
    .then(() => {
      toast.success('Mfi wallet limit deleted successfully')
      dispatch({ type: DELETE_MFI_WALLET_LIMIT, payload: id })
    })
    .catch(function (error) {
      dispatch(hideLoading())
      toast.error('Error: ' + error)
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const resetMfiWalletList = () => async (dispatch) => {
  dispatch({ type: RESET_MFI_WALLET_LIMIT })
}
