const storage = {
    set: (key, value) => {
      return localStorage.setItem(key, value);
    },
    get: (key) => {
      let json = localStorage.getItem(key);
      return json ? json : null;
    },
    remove: (key) => {
      return localStorage.setItem(key, null);
    },
  };

  export default storage;