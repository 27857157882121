import { useEffect, useState, useRef } from 'react'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { VzPageContainer } from '../../../../layouts/VzPageContainer'
import { connect } from 'react-redux'
import FormikControl from '../../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import { Link } from 'react-router-dom'
import { getDropdownList } from '../../../Shared/dropdownListActions'
import {
  getOrderShopDetails,
  addOrderShopDetails,
  getSingleOrderShopDetails,
} from './OrderShopDetailsActions'
import { useParams } from 'react-router-dom'
import Moment from 'react-moment'
import ServerSideDataTable from '../../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../../apis/Storage'

const OrderShopDetails = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [ordersList, setOrdersList] = useState([])
  const { deliveryDate, distCenterId } = useParams()
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterPaymentStatus, setFilterPaymentStatus] = useState(null)

  const [filterValuesModal, setFilterValuesModal] = useState({
    paymentStatus: '',
    vizLinkId: '',
    VizShopName: '',
  })

  const [paymentStatusModalShow, setPaymentStatusModalShow] = useState(false)

  const statusOptions = [
    {
      value: 'Unpaid',
      label: 'Unpaid',
    },
    {
      value: 'Pending',
      label: 'Pending',
    },

    {
      value: 'Paid',
      label: 'Paid',
    },
  ]

  const columns = [
    {
      Header: 'VizLink ID',
      accessor: 'vizlinkId',
    },
    {
      Header: 'Shop Name',
      accessor: 'shopName',
    },
    {
      Header: 'Date of Request',
      accessor: 'dateOfRequest',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Order Amount',
      accessor: 'amount',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Paid Amount',
      accessor: 'paidAmount',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Payment Status',
      accessor: 'paymentStatus',
    },
    {
      Header: 'Tagged Agents',
      accessor: 'taggedAgents',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button
            onClick={() => getSingleOrderDetails(row.value)}
            className=' btn btn-sm'
          >
            <i className='fa fa-eye'></i>
          </button>
        </div>
      ),
    },
  ]

  function formRequestObject(isExport = false) {
    let requestData = {
      VizLinkId: filterValuesModal.vizLinkId,
      VizShopName: filterValuesModal.VizShopName,
      PaymentStatus: filterValuesModal.paymentStatus,
      DeliveryDate: deliveryDate,
      DistCenterId: distCenterId,
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    return requestData
  }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const getSingleOrderDetails = (id) => {
    props.getSingleOrderShopDetails(id)
    setPaymentStatusModalShow(true)
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getOrderShopDetails(finalRequest)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject() }
    fetchReportData(request)
    setShow(false)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageSize: pageSize }
    fetchReportData(request)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData()
  }, [searchText])

  useEffect(() => {
    if (props.ShopOrder) {
      setOrdersList(props.ShopOrder)
      if (props.ShopOrder.length > 0) {
        setTotalRecords(props.ShopOrder[0].totalRecords)
      } else {
        setTotalRecords(0)
      }
    }
  }, [props.ShopOrder])

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterPaymentStatus(null)
    setFilterValuesModal({
      paymentStatus: '',
      vizLinkId: '',
      VizShopName: '',
    })
  }

  return (
    <div>
      <VzPageContainer title='Order Payment Status Shop Details'>
        <Row>
          <Col md={3} style={{ float: 'right' }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover id='popover-contained' width='400'>
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          {userRights != null &&
                          userRights?.userType == 'DistributorUser' ? (
                            <>
                              <Row style={{ width: 260 }}>
                                <Col xs={12} md={12}>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='vizLinkId'
                                    name='vizLinkId'
                                    label='VizLink ID'
                                    handleOnChange={(e) => {
                                      setFilterValuesModal({
                                        ...filterValuesModal,
                                        vizLinkId: e,
                                      })
                                    }}
                                    value={filterValuesModal.vizLinkId}
                                  />
                                </Col>
                              </Row>
                              <Row style={{ width: 260 }}>
                                <Col xs={12} md={12}>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    name='VizShopName'
                                    type='VizShopName'
                                    label='Shop Name'
                                    handleOnChange={(e) => {
                                      setFilterValuesModal({
                                        ...filterValuesModal,
                                        VizShopName: e,
                                      })
                                    }}
                                    value={filterValuesModal.VizShopName}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <></>
                          )}
                          <Row style={{ width: 260 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Payment Status'
                                name='paymentStatus'
                                options={statusOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.paymentStatus = e.value
                                  setFilterPaymentStatus({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterPaymentStatus}
                              />
                            </Col>
                          </Row>
                          <div className='row' style={{ marginTop: 40 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <ServerSideDataTable
              data={ordersList ? ordersList : []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
        <Modal
          show={paymentStatusModalShow}
          onHide={() => setPaymentStatusModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Shop-wise Payment Status Report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Viz ID:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.SelectedShopOrder?.vizshop?.vizShopCode ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Shop Code:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.SelectedShopOrder?.shop?.shopCode ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Date of Request:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        <Moment format='DD/MM/YYYY - hh:mm:ss'>
                          {props.SelectedShopOrder?.orderDate}
                        </Moment>
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Tag Agent-T1:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>{props.SelectedShopOrder?.agentT1 ?? '-'}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Shop Name:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.SelectedShopOrder?.vizshop?.vizShopName ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Order Amount:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>{props.SelectedShopOrder?.netSales ?? '-'}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Paid Amount:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.SelectedShopOrder?.amountPaid ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Request Status:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.SelectedShopOrder?.orderStatus ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Total Agents:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {props.SelectedShopOrder?.totalTaggedAgents ?? '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    ShopOrder: state.ShopOrder?.orderShopsList,
    SelectedShopOrder: state.ShopOrder?.selectedOrderDetails,
  }
}
export default connect(mapStateToProps, {
  getDropdownList,
  getOrderShopDetails,
  addOrderShopDetails,
  getSingleOrderShopDetails,
})(OrderShopDetails)
