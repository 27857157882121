import {
  UPDATE_VIZLINK_AGENT,
  DELETE_VIZLINK_AGENT,
  EMPTY_VIZLINK_AGENT,
  UPLOAD_VIZLINK_AGENT,
  ADD_VIZLINK_AGENT,
  GET_VIZLINK_AGENT,
} from '../../../Types'
// import _ from 'lodash'

const AgentsListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_VIZLINK_AGENT:
      return [...action.payload]
    case ADD_VIZLINK_AGENT:
      if (action.payload.length > 0) {
        return [...action.payload]
      } else {
        return [...state]
      }
    case UPDATE_VIZLINK_AGENT:
      const index = state.findIndex((item) => item.id === action.payload.id) //finding index of the item
      const newState = [...state] //making a new array
      newState[index] = action.payload //changing value in the new array
      return [...newState] //reassingning todos to new array
    case DELETE_VIZLINK_AGENT:
      return state.filter((e) => e.id !== action.payload)
    case EMPTY_VIZLINK_AGENT:
      return []
    case UPLOAD_VIZLINK_AGENT:
      return [...action.payload]
    default:
      return state
  }
}
export default AgentsListReducer
