import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { connect } from 'react-redux'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import {
  getVersions,
  addVersion,
  deleteVersion,
  updateVersion,
} from './VersionsActions'
import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../layouts/Input/FormikControl'
import { confirmAlert } from 'react-confirm-alert'

const Versions = (props) => {
  const [isView, setIsView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [currentVersion, setCurrentVersion] = useState(null)

  const validationSchema = Yup.object().shape({
    appName: Yup.string().required('App Name is required'),
    packageName: Yup.string().required('Package Name is Required'),
    version: Yup.string().required('Version Name is Required'),
    details: Yup.string().required('Version details Required'),
    displayVersion: Yup.string().required('Display Version is Required'),
  })
  const columns = [
    {
      Header: 'App Name',
      accessor: 'appName',
    },
    {
      Header: 'Package Name',
      accessor: 'packageName',
    },
    {
      Header: 'Version Name',
      accessor: 'version',
    },
    {
      Header: 'Display Version',
      accessor: 'displayVersion',
    },
    {
      Header: 'Version Details',
      accessor: 'details',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div>
          <button onClick={() => View(original.id)} className='btn btn-sm'>
            <i className='fas fa-eye'></i>
          </button>
          &nbsp;
          <button onClick={() => Edit(original.id)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button>
          &nbsp;
          <button onClick={() => Delete(original.id)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  const initialValues = {
    id: '',
    appName: '',
    packageName: '',
    version: '',
    details: '',
  }

  const handleSubmit = async (data) => {
    let newUserData = {
      appName: data.appName,
      packageName: data.packageName,
      version: data.version,
      details: data.details,
      displayVersion: data.displayVersion,
    }
    if (!isEdit) {
      await props.addVersion(newUserData)
    } else {
      await props.updateVersion(currentVersion.id, newUserData)
    }

    props.getVersions()
    setCurrentVersion({ ...initialValues })
    setModalShow(false)
  }

  const OpenModelForAddVersion = () => {
    setCurrentVersion(initialValues)
    setModalShow(true)
    setIsView(false)
    setIsEdit(false)
  }

  const View = (id) => {
    let tempObj = props.Versions.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }
    setCurrentVersion({ ...tempObj })
    setModalShow(true)
    setIsView(true)
    setIsEdit(false)
  }

  const Edit = (id) => {
    let tempObj = props.Versions.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }
    setCurrentVersion({ ...tempObj })
    setModalShow(true)
    setIsView(false)
    setIsEdit(true)
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const DeleteRecord = async (id) => {
    await props.deleteVersion(id)
  }

  useEffect(() => {
    props.getVersions()
  }, [])

  return (
    <div>
      <VzPageContainer title='App Versions'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>App Versions</b>
            </h4>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={12}>
            <Button
              className='form-btn pull-right'
              variant='primary'
              style={{ float: 'right' }}
              onClick={OpenModelForAddVersion}
            >
              <i className='fa fa-plus' /> Add New Version
            </Button>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col xs={12} md={12}>
            <CustomDataTable
              data={props.Versions ?? []}
              columns={columns}
              getTrProps={() => null}
            />
          </Col>
        </Row>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    User Record
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='appName'
                            label='App Name'
                            name='appName'
                            handleOnChange={(e) => (currentVersion.appName = e)}
                            value={currentVersion.appName}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='packageName'
                            label='Package Name'
                            name='packageName'
                            value={currentVersion.packageName}
                            handleOnChange={(e) =>
                              (currentVersion.packageName = e)
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='version'
                            label='Version'
                            name='version'
                            value={currentVersion.version}
                            handleOnChange={(e) => (currentVersion.version = e)}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='displayVersion'
                            label='Display Version'
                            name='displayVersion'
                            value={currentVersion.displayVersion}
                            handleOnChange={(e) =>
                              (currentVersion.displayVersion = e)
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={12}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='details'
                            label='Details'
                            name='details'
                            value={currentVersion.details}
                            handleOnChange={(e) => (currentVersion.details = e)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  {isView ? null : (
                    <>
                      <input
                        type='submit'
                        className='btn btn-primary'
                        value='Save'
                      />
                      {/* <button
                  className="btn btn-default"
                  onClick={handleReset.bind(null, formik.resetForm)}
                  type="button"
                >
                  Reset
                </button> */}
                      <Button
                        className='btn btn-default'
                        onClick={() => setModalShow(false)}
                      >
                        Close
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    Versions: state.Versions,
  }
}

export default connect(mapStateToProps, {
  getVersions,
  addVersion,
  deleteVersion,
  updateVersion,
})(Versions)
