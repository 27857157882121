import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { Col, Row, Overlay, Button, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import FormikControl from '../../../../layouts/Input/FormikControl'
import ServerSideDataTable from '../../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import {
  getMonthlyDigitalCollectionsReport,
  resetMonthlyDigitalCollectionsReport,
  exportReport,
} from './MonthlyDigitalCollectionsReportActions'

import { getDropdownList } from '../../../Shared/dropdownListActions'
import { GET_DISTRIBUTOR_DDL, GET_ADDJOB_DISTRIBUTIONCENTER_DDL } from '../../../../Types'
import storage from '../../../../apis/Storage'
import { isPermittedPage } from '../../../../apis/Utility'

const MonthlyDigitalCollectionsReport = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [filterFromDate, setFilterFromDate] = useState(new Date())
  const [filterToDate, setFilterToDate] = useState(null)
  const [filterDistCenter, setFilterDistCenter] = useState(null)


  const [filterDistributor, setFilterDistributor] = useState(null)
  const [distributorsOptions, setDistributorsOptions] = useState(null)
  const [centerOptions, setCenterOptions] = useState(null)






  const [filterValuesModal, setFilterValuesModal] = useState({
    fromDate: new Date(),
    toDate: '',
    distCenter: '',
  })
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterFromDate(new Date())
    setFilterToDate(null)
    setFilterDistCenter(null)
    setFilterValuesModal({
      fromDate: new Date(),
      toDate: '',
      distCenter: '',
    })
  }

  function formRequestObject(isExport = false) {
    let requestData = {
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      distributorId: filterDistributor == null ? null : filterDistributor.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],

      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        distributorId: userRights?.distributorId,
      }
    }
    return requestData
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getMonthlyDigitalCollectionsReport(finalRequest)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageSize: pageSize }
    fetchReportData(request)
  }

  const searchChanged = (text) => {
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchReportData(request)
    setShow(false)
  }

  const Download = () => {
    let request = formRequestObject(true)
    props.exportReport(request)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData()
  }, [searchText])

  useEffect(() => {
    if (props.Reports && props.Reports.length > 0) {
      setTotalRecords(props.Reports[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.Reports])

  useEffect(() => {
    props.getDropdownList(`api/Distributor/GetDistributorsDDL`, GET_DISTRIBUTOR_DDL)
    return () => {
      props.resetMonthlyDigitalCollectionsReport()
    }
  }, [])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      var filteredDistList = props.allDistributorList.filter(d => d.value === userRights?.distributorId.toUpperCase());
      setDistributorsOptions(filteredDistList);
    }
    else {
      setDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      var assignedDistcenterIds = userRights.distCenterIds.split(',');
      let filteredItems = props.addOrderDistCentersddlList.filter(item => assignedDistcenterIds.includes(item.value));
      setCenterOptions(
        filteredItems.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          }
        })
      )
    }
    else {
      setCenterOptions(
        props.addOrderDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            principalName: item.principalName,
            cityName: item.cityName,
          }
        })
      )
    }
  }, [props.addOrderDistCentersddlList])

  useEffect(() => {
    setCenterOptions(null)
    if (filterDistributor) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributor.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributor])

  const Columns = [
    {
      Header: 'Distribution ID',
      accessor: 'distributionId',
    },
    {
      Header: 'Total Ordered Amount (Rs)',
      accessor: 'totalOrderAmount',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Total Sales Recorded (Rs)',
      accessor: 'totalSalesRecorded',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },

    {
      Header: 'Jazz Cash (Rs)',
      accessor: 'jazzCash',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Easy Paisa (Rs)',
      accessor: 'easyPaisa',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },

    {
      Header: 'Digital Collections (%)',
      accessor: 'digitalCollectionPercentage',
    },

    {
      Header: 'Month',
      accessor: 'month',
    },
  ]

  return (
    <div>
      <Row>
        <Col md={10}>
          <div ref={ref}>
            <Button
              onClick={openFilterModal}
              className='m-3'
              variant='outline-primary'
            >
              <i className='fa fa-filter' />
              Filters
            </Button>

            <Overlay
              show={show}
              target={target}
              placement='bottom'
              container={ref}
              containerPadding={20}
              width='430px'
              style={{ width: '430px' }}
            >
              <Popover
                id='popover-contained'
                style={{ maxWidth: '360px', width: '360px' }}
              >
                <Popover.Header as='h3'>Filters</Popover.Header>
                <Popover.Body>
                  <Formik
                    enableReinitialize
                    initialValues={filterValuesModal}
                    validator={() => ({})}
                    onSubmit={LoadDataByfilter}
                  >
                    {(formik) => (
                      <Form autoComplete='off'>
                        <Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distributor'
                                name='distributor'
                                options={distributorsOptions ?? []}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.distributor = option
                                  setFilterDistributor(option)
                                }}
                                value={filterValuesModal.distributor}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distributor Center'
                                name='distID'
                                options={centerOptions ?? []}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.distCenter = option
                                  setFilterDistCenter(option)
                                }}
                                value={filterValuesModal.distCenter}
                              />
                            </Col>
                          </Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                // formik={formik}
                                control='date'
                                label='Month'
                                name='fromDate'
                                showMonthYearPicker={true}
                                handleOnChange={(value) => {
                                  filterValuesModal.fromDate = value
                                  setFilterFromDate(value)
                                }}
                                value={filterValuesModal.fromDate}
                              />
                            </Col>
                          </Row>
                        </Row>
                        <div className='row' style={{ marginTop: 20 }}>
                          <div className='form-group'>
                            <Link
                              onClick={handleResetFilters.bind(
                                null,
                                formik.resetForm
                              )}
                              to='#'
                              className='active'
                              style={{
                                padding: '0px',
                                marginTop: '10px',
                                float: 'left',
                              }}
                            >
                              Reset
                            </Link>
                            <Link
                              onClick={() => {
                                setShow(false)
                                setTarget(null)
                              }}
                              to='#'
                              className='active'
                              style={{
                                padding: '0px',
                                marginTop: '10px',
                                float: 'right',
                              }}
                            >
                              Cancel
                            </Link>
                            <div className='float-right'>
                              <Button
                                type='submit'
                                className='btn btn-primary'
                                style={{
                                  marginRight: '10px',
                                  float: 'right',
                                }}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </Col>
        {isPermittedPage('Export', props.actions, props.userRole) &&
          <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={Download} className='m-3' variant='outline-primary'>
              <i className='fa fa-download me-1' />
              Export
            </Button>
          </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <ServerSideDataTable
            data={props.Reports ?? []}
            columns={Columns}
            getTrProps={(event, data) => null}
            goToPreviousPage={(val) => getData(val)}
            goToNextPage={(val) => getData(val)}
            totalRecords={totalRecords}
            pageSizeChange={(size) => pageSizeChanged(size)}
            myPageSize={currentPageSize}
            myIndex={currentIndex}
            pageNo={currentPageNo}
            searchChanged={searchChanged}
          />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    Reports: state.MonthlyDigitalCollectionsReport,
    DistCentersList: state.ddlList.distCenters,
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    addOrderDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  }
}

export default connect(mapStateToProps, {
  getMonthlyDigitalCollectionsReport,
  resetMonthlyDigitalCollectionsReport,
  getDropdownList,
  exportReport,
})(MonthlyDigitalCollectionsReport)
