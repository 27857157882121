import {
  GET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS,
  RESET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS,
} from '../../../../Types'

const CurrentShopwiseTransactionsDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS:
      return [...action.payload]
    case RESET_CURRENT_SHOP_WISE_TRANSACTION_DETAILS:
      return []

    default:
      return state
  }
}
export default CurrentShopwiseTransactionsDetailsReducer
