import {
  GET_DIST_COINS_DISBURSEMENT_REPORT
} from '../../../Types'

const initialState = []

const DistCoinsDisbursementReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIST_COINS_DISBURSEMENT_REPORT:
      return action.payload
    default:
      return state
  }
}
export default DistCoinsDisbursementReportReducer