import { useState, useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
import {
  setSocket,
  messageReceived,
  messagesRead,
  getNewChat,
  notificationVisited,
  newNotification,
} from './SocketActions'
import storage from '../../apis/Storage'
import msgTone from '../../assets/Sound/message-tone.mp3'
import axios from '../../apis/Api'

const Socket = (props) => {
  const currentUserId = storage.get('_appUserId')
  const _appUserRole = storage.get('_appUserRole')

  const [connection, setConnection] = useState(null)

  const [playing, setPlaying] = useState(false)
  const audioRef = useRef(null)

  const [newMessage, setNewMessage] = useState(null)

  useEffect(() => {
    // Create audio element and set source
    audioRef.current = new Audio(msgTone)

    // Clean up on component unmount
    return () => {
      audioRef.current.pause()
      audioRef.current = null
    }
  }, [])

  const startSound = () => {
    setPlaying(true)
    audioRef.current.play()
  }

  const newMessageReceived = (message) => {
    if (message.messageDeliveryType == 1 && _appUserRole != 'Administrator') {
      startSound()
    }
    props.messageReceived(message)
    // if (
    //   props.chatList.filter((x) => x.id == message.vizUserChat_Id).length > 0
    // ) {
    //   props.messageReceived(message)
    // } else {
    //   props.getNewChat(message.vizUserChat_Id)
    // }
  }

  // const stopSound = () => {
  //   setPlaying(false)
  //   audioRef.current.pause()
  //   audioRef.current.currentTime = 0 // Reset audio to the beginning
  // }

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${axios.defaults.baseURL}hub/chat`, {
        accessTokenFactory: () => {
          // Generate or fetch the access token for authentication
          const access_token = currentUserId.toString()
          return access_token
        },
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build()

    setConnection(newConnection)
  }, [])

  useEffect(() => {
    if (connection) {
      props.setSocket(connection)
      connection
        .start()
        .then((result) => {
          connection.on('ReceiveMessage', (message) => {
            setNewMessage(message)
          })

          connection.on('GetReadReceipts', (msgs) => {
            props.messagesRead(msgs)
          })

          connection.on('NotificationVisited', (msgs) => {
            props.notificationVisited(msgs)
          })

          connection.on('NewNotification', (msgs) => {
            props.newNotification(msgs)
          })
        })
        .catch((e) => console.log('Connection failed: ', e))
    }
  }, [connection])

  useEffect(() => {
    if (newMessage != null) {
      newMessageReceived(newMessage)
    }
  }, [newMessage])

  return <></>
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {
  setSocket,
  messageReceived,
  messagesRead,
  getNewChat,
  notificationVisited,
  newNotification,
})(Socket)
