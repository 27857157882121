import React from 'react'
import Input from './Input'
import Textarea from './Textarea'
import Select from './Select'
import SelectBySearch from './SelectBySearch'
import RadioButtons from './RadioButtons'
import CheckboxGroup from './CheckboxGroup'
import { DatePickerField } from './DatePicker'
import SelectCreatable from './SelectCreatable'
import SelectCreatableWithNoOption from './SelectCreatableWithNoOption'
// import ChakraInput from './ChakraInput'

function FormikControl(props) {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <Input {...rest} />
    case 'textarea':
      return <Textarea {...rest} />
    case 'select':
      return <Select {...rest} />
    case 'react-select':
      return <SelectBySearch {...rest} />
    case 'react-creatable':
      return <SelectCreatable {...rest} />
    case 'react-creatable-noOption':
      return <SelectCreatableWithNoOption {...rest} />
    case 'radio':
      return <RadioButtons {...rest} />
    case 'checkbox':
      return <CheckboxGroup {...rest} />
    case 'date':
      return <DatePickerField {...rest} />
    // case 'chakraInput':
    //   return <ChakraInput {...rest} />
    default:
      return null
  }
}

export default FormikControl
