import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap'
// import DualListComp from "../DualListComp";
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {
  getPrincipals,
  addPrincipals,
} from '../../Principals/ManagePrincipalsAction'
import {
  getDistributorPrincipals,
  addDistributorPrincipals,
} from './DistributorPrincipalsAction'

const ManagePrincipalDetails = (props) => {
  const [principalSelectedOptions, setPrincipalSelectedOptions] = useState([])
  const [principalOptions, setPrincipalOptions] = useState([])
  const [principalName, setPrincipalName] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [principalLoaded, setPrincipalLoaded] = useState(false)
  const { id } = useParams()

  const validationDistPrincipalSchema = Yup.object().shape({
    principalName: Yup.array().min(1, 'Assign at least 1 Principal').required(),
  })

  const validationPrincipalSchema = Yup.object().shape({
    principalName: Yup.string().required('Principal Name is required'),
  })
  useEffect(() => {
    props.getPrincipals()
    // if (id) props.getDistributorPrincipals(id)
  }, [])

  useEffect(() => {
    if (id && principalLoaded) props.getDistributorPrincipals(id)
  }, [principalLoaded])

  useEffect(async () => {
    setPrincipalOptions(
      props.principals.map(function (item) {
        return {
          value: item.id,
          label: item.principalName,
        }
      })
    )
    if (!principalLoaded) {
      setPrincipalLoaded(true)
    }
  }, [props.principals])

  useEffect(async () => {
    if (props.distributorPrincipals.length > 0) {
      setPrincipalSelectedOptions(
        props.distributorPrincipals.map(function (item) {
          return {
            value: item.principalId,
            label: props.principals.find((p) => p.id === item.principalId)
              ?.principalName,
          }
        })
      )
    }
  }, [props.distributorPrincipals])

  //// Code to manage Principals

  const handlePrincipalsSubmit = (data) => {
    let checkIsExist = props.principals.filter(
      (item) =>
        item.principalName.toUpperCase() === data.principalName.toUpperCase() ||
        item.principalName.replace(/ /g, '').trim().toUpperCase() ===
          data.principalName.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist.length > 0) {
      toast.warn('Principal with the same name already exist')
      return
    }
    props.addPrincipals(data)
    setPrincipalName('')
    setModalShow(false)
  }

  //// Code to Manage Distributor Principals
  const handleDistPrincipalsSubmit = (data) => {
    if (!props.distributorMainInfo?.id) {
      toast.warn('First you have to add distributor main information')
      return
    }
    if (data.length === 0) {
      toast.warn('Please select at least 1 principal name')
      return
    }
    props.addDistributorPrincipals(
      data.map((item) => {
        return {
          distributorId: props.distributorMainInfo?.id,
          principalId: !item.value.id ? item.value : item.value.id,
          principalName: !item.value.id ? item.label : item.value.principalName,
        }
      })
    )
  }
  // const handleReset = (resetForm) => {
  //   resetForm();
  //   setPrincipalSelectedOptions([]);
  // };

  const handleDistPrincipalOnChange = (value) => {
    setPrincipalSelectedOptions(value)
  }
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={principalSelectedOptions}
        validationSchema={validationDistPrincipalSchema}
        onSubmit={handleDistPrincipalsSubmit}
      >
        {(formik) => (
          <Form autoComplete='off'>
            <Row>
              <Col xs={12} md={12}>
                <Button
                  className='form-btn pull-right'
                  variant='primary'
                  style={{ float: 'right' }}
                  onClick={() => {
                    setPrincipalName('')
                    setModalShow(true)
                  }}
                >
                  <i className='fa fa-plus' /> Add New Principal
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <Row>
                  <Col md={{ span: 7, offset: 1 }} style={{ float: 'left' }}>
                    <div className='form-group'>
                      <label htmlFor='principals'></label>
                      <FormikControl
                        formik={formik}
                        control='react-select'
                        type='principals'
                        label='Principals'
                        name='principals'
                        handleOnChange={(value) =>
                          handleDistPrincipalOnChange(value)
                        }
                        value={principalSelectedOptions}
                        isMultiSelect={true}
                        options={principalOptions}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <div className='form-group'>
                      <div className='float-right'>
                        {/* <Button
                          type="reset"
                          onClick={handleReset.bind(null, formik.resetForm)}
                          className="btn btn-default"
                        >
                          Reset
                        </Button> */}
                        &nbsp;
                        <Button type='submit' className='btn btn-primary'>
                          Assign Principals
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop='static'
        keyboard={false}
        size='md'
      >
        <Formik
          enableReinitialize
          validationSchema={validationPrincipalSchema}
          onSubmit={handlePrincipalsSubmit}
          initialValues={{ principalName: '' }}
        >
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Create New Principal
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='principalName'
                          label='Principal Name'
                          name='principalName'
                          value={principalName}
                          handleOnChange={(value) => setPrincipalName(value)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <input
                  type='submit'
                  className='btn btn-primary'
                  value='Add Principal'
                />
                <Button
                  className='btn btn-default'
                  onClick={() => {
                    setModalShow(false)
                    setPrincipalName('')
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo,
    distributorPrincipals: state.distributorPrincipals,
    principals: state.Principals,
    userId: state.auth.userId,
  }
}

export default connect(mapStateToProps, {
  getPrincipals,
  addPrincipals,
  getDistributorPrincipals,
  addDistributorPrincipals,
})(ManagePrincipalDetails)
