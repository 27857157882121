import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
// import storage from "../../apis/storage";
import {
  ADD_MAININFORMATIONS,
  GET_MAININFORMATIONS,
  DELETE_MAININFORMATIONS,
  UPDATE_MAININFORMATIONS,
  SIGN_OUT,
  EMPTY_QRTILLDETAILS,
  EMPTY_MFI_DETAILSLIST,
  EMPTY_DISTRIBUTIONCENTERSLIST,
  EMPTY_BANKDETAILS,
  EMPTY_DISTRIBUTORPRINCIPALS,
  EMPTY_MAININFORMATIONS,
} from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const addMainInformations = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/Distributor', JSON.stringify(formValues))
    .then(({ data }) => {
      formValues.id = data.response
      dispatch({
        type: ADD_MAININFORMATIONS,
        payload: formValues,
      })
      toast.success('Record added successfully')
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getMainInformations = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/Distributor/${distributorId}`)
    .then(({ data }) => {
      dispatch({
        type: GET_MAININFORMATIONS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateMainInformations =
  (distributorId, formValues) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .put(`api/Distributor/${distributorId}`, JSON.stringify(formValues))
      .then(({ data }) => {
        formValues.id = data.response
        dispatch({
          type: UPDATE_MAININFORMATIONS,
          payload: formValues,
        })
        dispatch(hideLoading())
        toast.success('Record updated successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
    // history.push("/");
  }
export const removeAllDataFromRedux = () => (dispatch) => {
  dispatch({ type: EMPTY_MAININFORMATIONS })
  dispatch({ type: EMPTY_BANKDETAILS })
  dispatch({ type: EMPTY_DISTRIBUTIONCENTERSLIST })
  dispatch({ type: EMPTY_MFI_DETAILSLIST })
  dispatch({ type: EMPTY_QRTILLDETAILS })
  dispatch({ type: EMPTY_DISTRIBUTORPRINCIPALS })
}
export const deleteMainInformations = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios.delete(`api/Distributor/DeleteMainInformations/${distributorId}`)
  dispatch({ type: DELETE_MAININFORMATIONS, payload: distributorId })
  dispatch(hideLoading())
  toast.success('Record deleted successfully')
}
