import axios from "../../../apis/Api";
import history from "../../../history";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { FINALIZE_DISTRIBUTOR_SUMMARY } from "../../../Types";

export const finalizeDistributorDetails =
  (distributorId) => async (dispatch) => {
    dispatch(showLoading());
    await axios
      .get(`api/Distributor/Finalize/${distributorId}`)
      .then(({ data }) => {
        dispatch({
          type: FINALIZE_DISTRIBUTOR_SUMMARY,
        });
        dispatch(hideLoading());
        toast.success(data.message);
        history.push("/Distributors");
        history.go("/Distributors");
      })
      .catch(function (error) {
        dispatch(hideLoading());
        toast.error("Error while finalizing the distributor records");
        if (error.response)
          if (error.response.status === 401) history.push("/login");
      });
  };

export const saveDistributorDetailsForLator = () => async (dispatch) => {
  dispatch(showLoading());
  history.push("/IncompleteDistributors");
  history.go("/IncompleteDistributors");
  dispatch({
      type: FINALIZE_DISTRIBUTOR_SUMMARY,
    });
    dispatch(hideLoading());
};
