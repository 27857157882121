import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import FormikControl from '../../../layouts/Input/FormikControl'
import { toast } from 'react-toastify'
import {
  addBankDetails,
  getBankDetails,
  updateBankDetails,
  deleteBankDetails,
} from './BankDetailsAction'
// import ReactAsyncTable from "react-async-table";

const ManageBankDetails = (props) => {
  const { id } = useParams()
  const initialValues = {
    id: '',
    bankName: '',
    branchCode: '',
    swiftCode: '',
    iban: '',
    accountTitle: '',
    accountNumber: '',
  }
  const [currentBankDetail, setCurrentBankDetail] = useState(initialValues)
  const [modalShow, setModalShow] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [bankNameValue, setBankNameValue] = useState(null)

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    bankName: Yup.string().required('Bank Name is required'),
    branchCode: Yup.string().required('Branch Code is Required'),
    iban: Yup.string()
      .min(22, 'Enter correct IBAN')
      .max(28, 'Enter correct IBAN')
      .required('IBAN is Required'),
    accountTitle: Yup.string()
      .required('Account Title is Required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    accountNumber: Yup.string()
      .max(20, 'Maximum 20 characters are allowed')
      .required('Account Number is Required')
      .matches(
        /^[a-zA-Z0-9_]*$/,
        'Only alphabets and Numeric digits are allowed for this field '
      ),
  })
  const columns = [
    {
      Header: 'Bank Name',
      accessor: 'bankName',
    },
    {
      Header: 'Branch  Code',
      accessor: 'branchCode',
    },
    {
      Header: 'Account Title',
      accessor: 'accountTitle',
    },
    {
      Header: 'Swift Code',
      accessor: 'swiftCode',
    },
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
    },
    {
      Header: 'IBAN',
      accessor: 'iban',
    },
    {
      Header: 'Action',
      id: 'edit',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          <button onClick={() => View(row.value)} className=' btn btn-sm'>
            <i className='fas fa-eye'></i>
          </button>
          &nbsp;
          <button onClick={() => Edit(row.value)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button>
          &nbsp;
          <button onClick={() => Delete(row.value)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]
  const options = [
    {
      value: 'Al Baraka Bank Pakistan Limited',
      label: 'Al Baraka Bank Pakistan Limited',
    },
    { value: 'Alfalah Bank Limited', label: 'Alfalah Bank Limited' },
    { value: 'Allied Bank Limited', label: 'Allied Bank Limited' },
    { value: 'Askari Bank Limited', label: 'Askari Bank Limited' },
    { value: 'Bank AL Habib Limited', label: 'Bank AL Habib Limited' },
    {
      value: 'BankIslami Pakistan Limited',
      label: 'BankIslami Pakistan Limited',
    },
    { value: 'Burj Bank Limited', label: 'Burj Bank Limited' },
    { value: 'Deutsche Bank', label: 'Deutsche Bank' },
    {
      value: 'Dubai Islamic Bank Pakistan Limited',
      label: 'Dubai Islamic Bank Pakistan Limited',
    },
    { value: 'Faysal Bank Limited', label: 'Faysal Bank Limited' },
    { value: 'First Women Bank Limited', label: 'First Women Bank Limited' },
    { value: 'Habib Bank Limited', label: 'Habib Bank Limited' },
    {
      value: 'Habib Metropolitan Bank Limited',
      label: 'Habib Metropolitan Bank Limited',
    },
    {
      value: 'Industrial And Commercial Bank Of Chaina',
      label: 'Industrial And Commercial Bank Of Chaina',
    },
    { value: 'JS Bank Limited', label: 'JS Bank Limited' },
    { value: 'MCB Bank Limited', label: 'MCB Bank Limited' },
    { value: 'MCB Islamic Bank Limited', label: 'MCB Islamic Bank Limited' },
    { value: 'Meezan Bank Limited', label: 'Meezan Bank Limited' },
    { value: 'National Bank Of Pakistan', label: 'National Bank Of Pakistan' },
    { value: 'S.M.E. Bank Limited', label: 'S.M.E. Bank Limited' },
    { value: 'Samba Bank Limited', label: 'Samba Bank Limited' },
    { value: 'Silk Bank Limited', label: 'Silk Bank Limited' },
    { value: 'Sindh Bank Limited', label: 'Sindh Bank Limited' },
    { value: 'Soneri Bank Limited', label: 'Soneri Bank Limited' },
    {
      value: 'Standard Chartered Bank Pakistan Limited',
      label: 'Standard Chartered Bank Pakistan Limited',
    },
    { value: 'Summit Bank Limited', label: 'Summit Bank Limited' },
    { value: 'The Bank Of Khyber', label: 'The Bank Of Khyber' },
    { value: 'The Bank Of Punjab', label: 'The Bank Of Punjab' },
    {
      value: 'The Punjab Provincial Cooperative Bank Limited',
      label: 'The Punjab Provincial Cooperative Bank Limited',
    },
    { value: 'UBL Bank Limited', label: 'UBL Bank Limited' },
    {
      value: 'Zarai Taraqiati Bank Limited',
      label: 'Zarai Taraqiati Bank Limited',
    },
  ]
  useEffect(() => {
    if (id) {
      props.getBankDetails(id)
    }
  }, [])

  const View = (id) => {
    let tempObj = props.bankDetails.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }
    setBankNameValue({ label: tempObj.bankName, value: tempObj.bankName })
    // currentBankDetail.bankName = {
    //   label: tempObj.bankName,
    //   value: tempObj.bankName,
    // };
    setCurrentBankDetail({ ...tempObj })
    setModalShow(true)
    setReadOnly(true)
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    props.deleteBankDetails(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const Edit = (id) => {
    let tempObj = props.bankDetails.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }
    setBankNameValue({ label: tempObj.bankName, value: tempObj.bankName })
    setCurrentBankDetail({ ...tempObj })
    setModalShow(true)
    setReadOnly(false)
    setIsEdit(true)
  }

  const OpenModelForAddBankDetails = () => {
    setReadOnly(false)
    setIsEdit(false)
    setCurrentBankDetail({ ...initialValues })
    setBankNameValue(null)
    setModalShow(true)
  }

  const handleSubmit = (data) => {
    if (!props.distributorMainInfo.id) {
      toast.warn('First you have to add distributor main information.')
      return
    }
    if (!data.id) {
      let tempObj = props.bankDetails.find(
        (detail) => detail.bankName === data.bankName
      )
      if (tempObj != null) {
        toast.warn('Bank details already exist')
        return
      }
      props.addBankDetails({
        id: data.id,
        bankName: data.bankName,
        branchCode: data.branchCode,
        accountTitle: data.accountTitle,
        swiftCode: data.swiftCode,
        accountNumber: data.accountNumber,
        distributorId: props.distributorMainInfo.id, /// storage.get("_appUserId");
        iban: data.iban,
      })
    } else {
      let tempObj = props.bankDetails.find((detail) => detail.id === data.id)
      if (!tempObj) {
        toast.warn('Bank details record not found')
        return
      }
      props.updateBankDetails(data.id, {
        id: data.id,
        bankName: data.bankName,
        branchCode: data.branchCode,
        accountTitle: data.accountTitle,
        swiftCode: data.swiftCode,
        accountNumber: data.accountNumber,
        distributorId: props.distributorMainInfo.id, /// storage.get("_appUserId");
        iban: data.iban,
      })
    }
    setModalShow(false)
  }

  // const handleReset = (resetForm) => {
  //   resetForm();
  //   setCurrentBankDetail(initialValues);
  //   setBankNameValue({});
  // };

  return (
    <div>
      <Row>
        <Col xs={12} md={12}>
          <Button
            className='form-btn pull-right'
            variant='primary'
            style={{ float: 'right' }}
            onClick={OpenModelForAddBankDetails}
          >
            <i className='fa fa-plus' /> Add Bank Detail
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          {props.bankDetails.length > 0 ? (
            <CustomDataTable
              data={props.bankDetails}
              columns={columns}
              getTrProps={() => null}
            />
          ) : (
            <h4
              style={{
                display: 'flex',
                marginTop: '50px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              No Bank Details added yet
            </h4>
          )}
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Formik
          enableReinitialize
          initialValues={currentBankDetail}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form autoComplete='off'>
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Bank Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          readOnly={readOnly}
                          control='react-select'
                          type='bankName'
                          label='Bank Name'
                          name='bankName'
                          value={bankNameValue}
                          options={props.banks}
                          handleOnChange={(option) => {
                            setBankNameValue(option)
                            currentBankDetail.bankName = option
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={6} md={3}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='branchCode'
                          label='Branch Code'
                          readOnly={readOnly}
                          name='branchCode'
                          handleOnChange={(value) => {
                            currentBankDetail.branchCode = value
                          }}
                          value={currentBankDetail.branchCode}
                        />
                      </div>
                    </Col>
                    <Col xs={6} md={3}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='swiftCode'
                          label='Swift Code'
                          readOnly={readOnly}
                          name='swiftCode'
                          value={currentBankDetail.swiftCode}
                          handleOnChange={(value) =>
                            (currentBankDetail.swiftCode = value)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='iban'
                          label='IBAN'
                          readOnly={readOnly}
                          name='iban'
                          value={currentBankDetail.iban}
                          handleOnChange={(value) =>
                            (currentBankDetail.iban = value)
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='accountTitle'
                          label='Account Title'
                          readOnly={readOnly}
                          name='accountTitle'
                          value={currentBankDetail.accountTitle}
                          handleOnChange={(value) =>
                            (currentBankDetail.accountTitle = value)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <div className='form-group'>
                        <FormikControl
                          formik={formik}
                          control='input'
                          type='accountNumber'
                          readOnly={readOnly}
                          label='Account Number'
                          name='accountNumber'
                          value={currentBankDetail.accountNumber}
                          handleOnChange={(value) =>
                            (currentBankDetail.accountNumber = value)
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                {readOnly === false ? (
                  <div>
                    <input
                      type='submit'
                      className='btn btn-primary'
                      value={
                        isEdit === false ? 'Add Bank' : 'Update Bank Detail'
                      }
                    />
                    {isEdit === false ? (
                      <div></div>
                    ) : (
                      // <button
                      //   className='btn btn-default'
                      //   onClick={handleReset.bind(null, formik.resetForm)}
                      //   type='button'
                      // >
                      //   Reset
                      // </button>
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <Button
                  className='btn btn-default'
                  onClick={() => setModalShow(false)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo
      ? state.distributorMainInfo
      : [],
    bankDetails: state.bankDetails ? state.bankDetails : [],
    userId: state.auth.userId,
    banks: state.ddlList.banks ? state.ddlList.banks : [],
  }
}
export default connect(mapStateToProps, {
  addBankDetails,
  getBankDetails,
  updateBankDetails,
  deleteBankDetails,
})(ManageBankDetails)
