import { useEffect } from 'react'
import { useField, Field, ErrorMessage, getIn } from 'formik'
import TextError from './TextError'

function Input(props) {
  const {
    autocompleteFalse,
    autocompleteOff,
    label,
    value,
    name,
    handleOnChange,
    formik,
    readOnly,
    isReadOnly,
    isSelectAll,
    ...rest
  } = props
  const [, , helpers] = useField(name)
  const getStyles = (formik, fieldName) => {
    if (getIn(formik.touched, fieldName) && getIn(formik.errors, fieldName)) {
      return {
        border: '1px solid red',
      }
    } else return {}
  }
  useEffect(() => {
    if (value) {
      helpers.setValue(value)
    } ////else helpers.setValue('');
  }, [value])

  return (
    <>
      <label style={{ marginBottom: 0 }} htmlFor={name}>
        {label}
      </label>
      <Field
        autoComplete="off"
        readOnly={isReadOnly}
        disabled={readOnly}
        className='form-control'
        id={name}
        value={value}
        onChange={(e) => {
          helpers.setValue(e.target.value)
          handleOnChange(e.target.value)
        }}
        style={formik ? getStyles(formik, name) : {}}
        name={name}
        placeholder={`Enter ${label ?? 'value'}`}
        onBlur={() => helpers.setTouched(true)}
        {...rest}
      />
      <ErrorMessage
        className='error invalid-field'
        component={TextError}
        name={name}
      />
    </>
  )
}

export default Input
