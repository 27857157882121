import {
  GET_QRTILL_LIST,
  GET_QRTILL_MFI_FILTER_LIST,
  GET_QRTILL_CENTER_FILTER_LIST,
} from '../../../Types'
// import _ from 'lodash'

const initialState = {
  qrTillList: [],
  mfiList: [],
  distCenterList: [],
}

const QrTillListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QRTILL_LIST:
      return { ...state, qrTillList: action.payload }
    case GET_QRTILL_MFI_FILTER_LIST:
      return {
        ...state,
        mfiList: action.payload.map(function (item) {
          return {
            value: item.id,
            label: item.mfiName,
          }
        }),
      }
    case GET_QRTILL_CENTER_FILTER_LIST:
      return { ...state, distCenterList: action.payload }
    // case ADD_QRTILLDETAILS:
    //   return [...state, action.payload]
    // case UPDATE_QRTILLDETAILS:
    //   const index = state.findIndex((item) => item.id === action.payload.id) //finding index of the item
    //   const newState = [...state] //making a new array
    //   newState[index] = action.payload //changing value in the new array
    //   return [...newState] //reassingning todos to new array
    // case DELETE_QRTILLDETAILS:
    //   return state.filter((e) => e.id !== action.payload)
    // case EMPTY_QRTILLDETAILS:
    //   return []
    // case UPLOAD_QRTILLDETAILS:
    //   return [...action.payload]
    default:
      return state
  }
}
export default QrTillListReducer
