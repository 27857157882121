import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'

import { Tabs, Tab } from 'react-bootstrap'
import Pages from './Pages/Pages'
import UserRoles from './UserRoles/UserRoles'
import UserRolePermissions from './UserRolePermissions/UserRolePermissions'
import PageActions from './PageActions/PageActions'
const Permissions = (props) => {
  return (
    <div>
      <VzPageContainer title='Manage Permissions'>
        <Tabs
          defaultActiveKey='ManageUserRoles'
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          <Tab eventKey='ManageUserRoles' title='Manage User Roles'>
            <UserRoles></UserRoles>
          </Tab>
          <Tab eventKey='ManagePages' title='Manage Pages'>
            <Pages></Pages>
          </Tab>
          <Tab eventKey='PageActions' title='Page Actions'>
            <PageActions></PageActions>
          </Tab>
          <Tab
            eventKey='ManageUserRolePermissions'
            title='Manage User Role Permissions'
          >
            <UserRolePermissions></UserRolePermissions>
          </Tab>
        </Tabs>
      </VzPageContainer>
    </div>
  )
}

export default connect()(Permissions)
