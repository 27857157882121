import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button, Overlay, Popover, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { Formik, Form } from 'formik'
import FormikControl from '../../../layouts/Input/FormikControl'
import { getDropdownList } from '../../Shared/dropdownListActions'
import axios from '../../../apis/Api'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
} from '../../../Types'

import {
  getLoanDisbursementReport,
  downloadJob,
} from './LoanDisbursementReportActions'
import Moment from 'react-moment'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'

const LoanDisbursementReport = (props) => {

  const userRights = JSON.parse(storage.get('_userRights'))
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterDistributorName, setFilterDistributorName] = useState(null)
  const [filterDistributionID, setFilterDistributionID] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(null)
  const [filterToDate, setFilterToDate] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  const [filterTillNo, setFilterTillNo] = useState(null)
  const [filterVizId, setFilterVizId] = useState(null)

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] =
    useState(null)
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])

  const [qrCodeList, setQrCodeList] = useState([])

  const [filterValuesModal, setFilterValuesModal] = useState({
    vizId: '',
    distributorName: '',
    distCenterId: '',
    fromDate: '',
    toDate: '',
    status: '',
    tillNo: '',
  })

  const statusOptions = [
    {
      label: 'Successful',
      value: 'Paid',
    },
    {
      label: 'Failed',
      value: 'Failed',
    },
  ]

  const columns = [
    {
      Header: 'Viz Id',
      accessor: 'vizId',
    },
    {
      Header: 'Lender',
      accessor: 'lender',
    },
    {
      Header: 'Distribution Center',
      accessor: 'distCenter',
    },

    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Inv Number',
      accessor: 'invoice',
    },
    {
      Header: 'Inv Amount',
      accessor: 'invoiceAmt',
    },
    {
      Header: 'Loan Amount',
      accessor: 'loanAmt',
    },
    {
      Header: 'Payment Status',
      accessor: 'status',
      Cell: (row) => <div>{columnStatusValue(row.value)}</div>,
    },
    {
      Header: 'Deliveryman Till No.',
      accessor: 'qrCode',
    },
  ]

  const columnStatusValue = (val) => {
    if (val == 'Paid') return 'Successful'

    return val
  }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterDistributorName(null)
    setFilterDistributionID(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterTillNo(null)
    setFilterStatus(null)
    setFilterVizId(null)

    setAddJobDistCenterOptions([])
    setQrCodeList([])
    setFilterValuesModal({
      vizId: '',
      distributorName: '',
      distributionID: '',
      fromDate: '',
      toDate: '',
      status: '',
      tillNo: '',
    })
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageSize: pageSize }
    fetchReportData(request)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchReportData(request)
    setShow(false)
  }

  const Download = () => {
    let request = formRequestObject(true)
    props.downloadJob(request)
  }

  function formRequestObject(isExport = false) {
    let requestData = {
      distributionId:
        filterDistributionID == null ? null : filterDistributionID.value,
      distributorId:
        filterDistributorName == null ? null : filterDistributorName.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterToDate)
            )
          )
            .toISOString()
            .split('T')[0],
      status: filterStatus == null ? null : filterStatus.value,
      qrTill: filterTillNo == null ? null : filterTillNo.value,
      vizId: filterVizId == null ? null : filterVizId,
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        distributorId: userRights?.distributorId,
      }
    }
    return requestData
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    props.getLoanDisbursementReport(finalRequest)
  }

  const getCenterDeliveryUsers = (id) => {
    axios
      .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
      .then(({ data }) => {
        if (data?.response) {
          setQrCodeList(
            data.response.map(function (item) {
              return {
                value: item.qrCode,
                label: item.qrCode,
              }
            })
          )
        }
      })
      .catch(function (error) { })
  }

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData()
  }, [searchText])

  useEffect(() => {
    if (props.LoanDisbursementReport) {
      setTotalRecords(props.LoanDisbursementReport?.totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.LoanDisbursementReport])

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

    useEffect(() => {
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            var assignedDistcenterIds = userRights.distCenterIds.split(',');
            let filteredItems = props.addJobDistCentersddlList.filter(item => assignedDistcenterIds.includes(item.value));
            setAddJobDistCenterOptions(
                filteredItems.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                        principalName: item.principalName,
                        cityName: item.cityName,
                    }
                })
            )
        }
        else {
            setAddJobDistCenterOptions(
                props.addJobDistCentersddlList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                    }
                })
            )
        }
  }, [props.addJobDistCentersddlList])

  useEffect(() => {
    if (filterDistributorName) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorName.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorName])

  useEffect(() => {
    if (filterDistributionID) {
      getCenterDeliveryUsers(filterDistributionID.value)
    }
  }, [filterDistributionID])

  return (
    <div>
      <VzPageContainer title='Loan Disbursement Report'>
        <Row>
          <Col style={{ float: 'left' }}>
            <h4>
              <b>Loan Disbursement Report</b>
            </h4>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md={10} style={{ float: 'right' }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={{}}
                      validator={() => ({})}
                      onSubmit={(e) => LoadDataByfilter(e)}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='input'
                                type='text'
                                label='Viz ID'
                                name='vizId'
                                handleOnChange={(e) => {
                                  filterValuesModal.vizId = e
                                  setFilterVizId(e)
                                }}
                                value={filterValuesModal.vizId}
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <FormikControl
                                // formik={formik}
                                control='date'
                                label='From Date'
                                name='fromDate'
                                handleOnChange={(value) => {
                                  filterValuesModal.fromDate = value
                                  setFilterFromDate(value)
                                }}
                                value={filterValuesModal.fromDate}
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <FormikControl
                                control='date'
                                label='To Date'
                                name='toDate'
                                handleOnChange={(value) => {
                                  filterValuesModal.toDate = value
                                  setFilterToDate(value)
                                }}
                                value={filterValuesModal.toDate}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distributor'
                                name='distributorName'
                                options={addJobDistributorsOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.distributorName = e.value
                                  setFilterDistributorName({
                                    label: e.label,
                                    value: e.value,
                                  })
                                  setFilterDistributionID(null)
                                  setFilterTillNo(null)
                                }}
                                value={filterDistributorName}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distribution Center'
                                name='distributionID'
                                options={addJobDistCenterOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.distributionID = e.value
                                  setFilterDistributionID({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterDistributionID}
                              />
                            </Col>

                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Status'
                                name='status'
                                options={statusOptions}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.status = e.value
                                  setFilterStatus({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterStatus}
                              />
                            </Col>

                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Delivery man Till No'
                                name='tillNo'
                                options={qrCodeList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.tillNo = e.value
                                  setFilterTillNo({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterTillNo}
                              />
                            </Col>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          {isPermittedPage('Export', props.actions, props.userRole) &&
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={Download}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-download me-1' />
                Export
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col>
            <ServerSideDataTable
              data={props.LoanDisbursementReport?.report ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],

    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    LoanDisbursementReport: state.LoanDisbursementReport,
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  getLoanDisbursementReport,
  downloadJob,
})(LoanDisbursementReport)
