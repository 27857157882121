import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import storage from '../../../apis/Storage'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { GET_DISTRIBUTOR_OTHER_CATEGORY_INBOX, GET_DISTRIBUTOR_COMPLAINT_CATEGORY_INBOX, GET_DISTRIBUTOR_ORDER_CATEGORY_INBOX, GET_DISTRIBUTOR_INFO_CATEGORY_INBOX, GET_DISTRIBUTOR_ALL_CATEGORY_INBOX } from '../../../Types'

import fileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export const getTickets =
  (requestData, getTickets_successCallBack) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .post(
        `api/VLCShopKeeperTicket/GetCategoryWiseDistUserTickets`,
        JSON.stringify(requestData)
      )
      .then(({ data }) => {
        if (data && data?.response) {
          if (requestData.ticketCategoryCode == null) {
            dispatch({
              type: GET_DISTRIBUTOR_ALL_CATEGORY_INBOX,
              payload: data?.response,
            })
          }
          else if (requestData.ticketCategoryCode == "1") { // info category data
            dispatch({
              type: GET_DISTRIBUTOR_INFO_CATEGORY_INBOX,
              payload: data?.response?.infoTickets,
            })
          }
          else if (requestData.ticketCategoryCode == "2") { // order category data
            dispatch({
              type: GET_DISTRIBUTOR_ORDER_CATEGORY_INBOX,
              payload: data?.response?.orderTickets,
            })
          }
          else if (requestData.ticketCategoryCode == "3") { // complaint category data
            dispatch({
              type: GET_DISTRIBUTOR_COMPLAINT_CATEGORY_INBOX,
              payload: data?.response?.complaintTickets,
            })
          }
          else if (requestData.ticketCategoryCode == "4") { // other category data
            dispatch({
              type: GET_DISTRIBUTOR_OTHER_CATEGORY_INBOX,
              payload: data?.response?.otherTickets,
            })
          }
        }
        //getTickets_successCallBack(data.response)
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        }
      })
  }

export const getAllocatedDistCentersByUserId =
  (userId, successCallBack) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .get(
        `api/VLCTicketCategoryUserAllocation/GetAllocatedDistCentersByUserId/${userId}`
      )
      .then(({ data }) => {
        successCallBack(data.response)
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }

export const downloadJob = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(
      `api/VLCShopKeeperTicket/GetCommunicationListing`,
      JSON.stringify(requestData)
    )
    .then(({ data }) => {
      const outputFilename = `SUCCESSFULL_LOAN_DETAILS_Report_${Date.now()}`
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const exportToCSV = (csvData, jobNumber, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData)
        if (ws) {
          var range = XLSX.utils.decode_range(ws['!ref'])
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
            if (!ws[address]) continue
            ws[address].v =
              ws[address].v == 'vizCode'
                ? 'Viz Id'
                : ws[address].v == 'lender'
                  ? 'Lender'
                  : ws[address].v == 'distCenterName'
                    ? 'Distribution Center'
                    : ws[address].v == 'loanDate'
                      ? 'Date'
                      : ws[address].v == 'invoiceNo'
                        ? 'Invoice No.'
                        : ws[address].v == 'invoiceAmount'
                          ? 'Invoice Amount'
                          : ws[address].v == 'loanAmount'
                            ? 'Loan Amount'
                            : ws[address].v == 'profit'
                              ? 'Profit'
                              : ws[address].v == 'totalPayable'
                                ? 'Total Payable'
                                : ws[address].v == 'dueDate'
                                  ? 'Due Date'
                                  : ws[address].v == 'deliverymanTillNo'
                                    ? 'Deliveryman Till No.'
                                    : ws[address].v
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const fileData = new Blob([excelBuffer], { type: fileType })
        fileSaver.saveAs(fileData, fileName + fileExtension)
      }
      exportToCSV(data?.response?.data, '', outputFilename)
      dispatch(hideLoading())
    })

    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
