import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import ReactSelect from 'react-select'
import { Input } from 'reactstrap'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { useState, useEffect, useRef } from 'react'
import { GET_BANKS } from '../../../Types'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import { getUserActivityLog } from './UserActivityActions'
import Moment from 'react-moment'
import { Formik, Form } from 'formik'
import { Link, Redirect } from 'react-router-dom'
import FormikControl from '../../../layouts/Input/FormikControl'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { getUserRoles } from '../../Users/ManageUsersAction'
import axios from '../../../apis/Api'
import SelectServerSide from '../../../layouts/Input/SelectServerSide'
import debounce from 'lodash/debounce'

const UserActivity = (props) => {
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)

  const [filterUser, setFilterUser] = useState(null)
  const [filterUserRole, setFilterUserRole] = useState(null)
  const [filterFromDate, setFilterFromDate] = useState(null)
  const [filterToDate, setFilterToDate] = useState(null)
  const [filterModule, setFilterModule] = useState(null)

  const [rolesList, setRolesList] = useState([])
  const [usersList, setUsersList] = useState([])
  const [moduleList, setModulesList] = useState([])

  const [filterValuesModal, setFilterValuesModal] = useState({
    user: '',
    userRole: '',
    fromDate: '',
    toDate: '',
    module: '',
  })

  const columns = [
    {
      Header: 'User',
      accessor: 'userName',
    },
    {
      Header: 'Activity Date',
      accessor: 'activityDate',
      Cell: (row) => (
        <div>
          <Moment format='DD/MM/YYYY | hh:mm:ss a'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'Module Name',
      accessor: 'moduleName',
    },
    {
      Header: 'Activity Type',
      accessor: 'activityType',
    },

    {
      Header: 'Activity Details',
      accessor: 'activityDetails',
      Cell: (row) => <div style={{ width: '400px' }}>{row.value}</div>,
    },
  ]

  const loadOptions = debounce(async (inputValue, callback) => {
    if (filterUserRole == null || inputValue == null || inputValue.length < 3)
      return

    const result = await axios.post(`api/User/GetUsersDDL`, {
      userType: filterUserRole.label,
      searchText: inputValue,
    })
    const data = await result.data.response

    const transformedOptions = data.map((item) => ({
      value: item.value,
      label: item.label,
    }))

    callback(transformedOptions)
    setUsersList(transformedOptions)
  }, 500)

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterUser(null)
    setFilterUserRole(null)
    setFilterModule(null)

    setFilterValuesModal({
      fromDate: '',
      toDate: '',
      user: '',
      userRole: '',
      module: '',
    })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      user: filterUser == null ? null : filterUser.value,
      userRole: filterUserRole == null ? null : filterUserRole.value,
      module: filterModule == null ? null : filterModule.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(filterFromDate)
              )
            )
              .toISOString()
              .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(filterToDate)
              )
            )
              .toISOString()
              .split('T')[0],
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }
    props.getUserActivityLog(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      user: filterUser == null ? null : filterUser.value,
      userRole: filterUserRole == null ? null : filterUserRole.value,
      module: filterModule == null ? null : filterModule.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(filterFromDate)
              )
            )
              .toISOString()
              .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(filterToDate)
              )
            )
              .toISOString()
              .split('T')[0],
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
    }
    props.getUserActivityLog(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)

    let requestData = {
      user: filterUser == null ? null : filterUser.value,
      userRole: event.userRole == null ? null : event.userRole,
      module: event.module == null ? null : event.module,
      fromDate:
        event.fromDate == '' || event.fromDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(event.fromDate)
              )
            )
              .toISOString()
              .split('T')[0],
      toDate:
        event.toDate == '' || event.toDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(event.toDate)
              )
            )
              .toISOString()
              .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }

    props.getUserActivityLog(requestData)
    setShow(false)
    setFilterFromDate(event.fromDate == '' ? null : event.fromDate)
    setFilterToDate(event.toDate == '' ? null : event.toDate)
  }

  const getModulesList = () => {
    axios
      .get(`api/UserActivityLog/ModulesList`)
      .then(({ data }) => {
        setModulesList(
          data.response.map(function (item) {
            return {
              value: item,
              label: item,
            }
          })
        )
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      user: filterUser == null ? null : filterUser.value,
      userRole: filterUserRole == null ? null : filterUserRole.value,
      module: filterModule == null ? null : filterModule.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(filterFromDate)
              )
            )
              .toISOString()
              .split('T')[0],
      toDate:
        filterToDate == null
          ? null
          : new Date(
              Date.parse(
                new Intl.DateTimeFormat('fr-CA', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(filterToDate)
              )
            )
              .toISOString()
              .split('T')[0],
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }
    props.getUserActivityLog(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.UserActivity && props.UserActivity.length > 0) {
      setTotalRecords(props.UserActivity[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.UserActivity])

  useEffect(() => {
    props.getUserRoles()
    getModulesList()
  }, [])

  useEffect(() => {
    setRolesList(
      props.Roles.map(function (item) {
        return {
          value: item.id,
          label: item.name,
        }
      })
    )
  }, [props.Roles])

  useEffect(() => {
    return
    if (filterUserRole != null) {
      axios
        .post(`api/User/GetUsersDDL`, { userType: filterUserRole.label })
        .then(({ data }) => {
          setUsersList(data.response)
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
            } else if (error.response.data)
              toast.error(
                error.response.data.Message
                  ? error.response.data.Message
                  : error.response.data.message
              )
            else {
              toast.error('Error while processing the request')
            }
          }
        })
    }
  }, [filterUserRole])

  return (
    <div>
      <VzPageContainer title='User Activity'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>User Activity</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={3} style={{ float: 'right' }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='User Role'
                                name='userRole'
                                options={rolesList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.userRole = e.value
                                  setFilterUserRole({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterUserRole}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              {/* <FormikControl
                                formik={formik}
                                control='react-select'
                                label='User'
                                name='user'
                                options={usersList}
                                isSearchable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    return
                                  }
                                  filterValuesModal.user = e.value
                                  setFilterUser({
                                    label: e.label,
                                    value: e.value,
                                  })
                                  apiCall(e)
                                }}
                                value={filterUser}
                              /> */}
                              <SelectServerSide
                                label='User'
                                value={filterUser}
                                handleValueChange={(e) => {
                                  if (!e || !e.value) {
                                    setFilterUser(null)
                                    return
                                  }
                                  setFilterUser({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                options={usersList}
                                loadOptions={loadOptions}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Module'
                                name='module'
                                options={moduleList}
                                isSearchable={true}
                                isClearable={true}
                                handleOnChange={(e) => {
                                  if (!e || !e.value) {
                                    setFilterModule(null)
                                    filterValuesModal.module = ''
                                    return
                                  }
                                  filterValuesModal.module = e.value
                                  setFilterModule({
                                    label: e.label,
                                    value: e.value,
                                  })
                                }}
                                value={filterModule}
                              />
                            </Col>
                          </Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={6}>
                              <FormikControl
                                control='date'
                                label='From Date'
                                name='fromDate'
                                handleOnChange={(value) => {
                                  filterValuesModal.fromDate = value
                                  setFilterFromDate(value)
                                }}
                                value={filterValuesModal.fromDate}
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <FormikControl
                                control='date'
                                label='To Date'
                                name='toDate'
                                handleOnChange={(value) => {
                                  filterValuesModal.toDate = value
                                  setFilterToDate(value)
                                }}
                                value={filterValuesModal.toDate}
                              />
                            </Col>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '40px' }}>
          <Col xs={12}>
            {/* <CustomDataTable
              data={props.UserActivity ?? []}
              columns={columns}
              getTrProps={null}
            /> */}
            <ServerSideDataTable
              data={props.UserActivity ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    UserActivity: state.UserActivity,
    Roles: state.ddlList?.userRoles ? state.ddlList?.userRoles : [],
  }
}

export default connect(mapStateToProps, {
  getUserActivityLog,
  getUserRoles,
})(UserActivity)
