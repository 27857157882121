import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import storage from '../../../apis/Storage'
import {
  ADD_AGENTS_DETAILS,
  GET_AGENTS_LIST,
  DELETE_AGENT,
  SELECTED_AGENT_DETAILS,
  UPDATE_AGENT_DETAILS,
  GET_AGENT_SHOPS,
  TAG_AGENT_SHOP,
} from '../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const addAgents = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  const formData = new FormData()
  formData.append('MfiId', formValues.mfiId)
  formData.append('DataFile', formValues.DataFile)
  const config = {
    headers: {
      Authorization: `Bearer ${storage.get('_appToken')}`,
      'content-type': 'multipart/form-data',
      accept: 'application/json',
    },
  }
  await axios
    .post('api/VizAgent/UploadAgentsDataSheet', formData, config)
    .then(({ data }) => {
      dispatch({
        type: ADD_AGENTS_DETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
      toast.success('Agents uploaded successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const getAgents = (filters) => async (dispatch) => {
  dispatch(showLoading())

  await axios
    .post(`api/VizAgent/GetAgentsList`, filters)
    .then(({ data }) => {
      dispatch({
        type: GET_AGENTS_LIST,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const deleteAgent = (id) => async (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${storage.get('_appToken')}` },
  }
  await axios
    .delete(`api/VizAgent/SoftDelete/${id}`, config)
    .then(({ data }) => {
      dispatch(hideLoading())
      if (data.isSuccess) {
        toast.success(data.message)
      }
      else {
        toast.error(data.message)
      }
      // toast.success('Agent deleted successfully')
      // dispatch({ type: DELETE_AGENT, payload: id })
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getSingleAgentDetails = (id) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/VizAgent/GetSingleVizAgentDetails?id=${id}`)
    .then(({ data }) => {
      dispatch({
        type: SELECTED_AGENT_DETAILS,
        payload: data,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateAgentDetails = (id, formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .put(`api/VizAgent/${id}`, formValues)
    .then(({ data }) => {
      formValues.id = id
      dispatch(hideLoading())
      dispatch({
        type: UPDATE_AGENT_DETAILS,
        payload: formValues,
      })
      toast.success('Record updated successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getAgentShops = (filters) => async (dispatch) => {
  dispatch(showLoading())

  await axios
    .post(`api/VizAgent/GetAgentShopsListToTag`, filters)
    .then(({ data }) => {
      dispatch({
        type: GET_AGENT_SHOPS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const tagAgentShop = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/TaggedShop', JSON.stringify(formValues))
    .then(({ data }) => {
      formValues.id = data.response
      dispatch({
        type: TAG_AGENT_SHOP,
        payload: formValues.vizShopId,
      })
      dispatch(hideLoading())
      toast.success('Record updated successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
