import { useEffect } from 'react'
import ReactSelect from 'react-select'
import { useField, ErrorMessage, getIn } from 'formik'
import TextError from './TextError'

function SelectBySearch(props) {
  const {
    label,
    name,
    options,
    value,
    readOnly,
    handleOnChange,
    isMultiSelect,
    isSelectAll,
    isSearchable,
    isClearable,
    formik,
    ...rest
  } = props
  const [, , helpers] = useField(name)
  const getStyles = (formik, fieldName) => {
    if (getIn(formik.touched, fieldName) && getIn(formik.errors, fieldName)) {
      return {
        border: '0.7px solid red',
        borderRadius: '5px',
        padding: 0,
        margin: 0,
      }
    } else return {}
  }
  useEffect(() => {
    if (isSelectAll === true) {
      helpers.setValue(
        options.map(function (item) {
          return {
            value: item.value,
            label: item.value,
          }
        })
      )
    }
    if (isSelectAll === false) {
      helpers.setValue(null)
    }
  }, [isSelectAll])
  return (
    <>
      {/* {checkForSelectAll()} */}
      <label style={{ marginBottom: 0 }} htmlFor={name}>
        {label}
      </label>
      <div style={formik ? getStyles(formik, name) : {}}>
        <ReactSelect
          id={name}
          name={name}
          isDisabled={readOnly}
          readOnly={readOnly}
          className='basic-single'
          classNamePrefix='select'
          isMulti={isMultiSelect}
          placeholder={`Select ${label}`}
          value={value}
          isClearable={isClearable}
          onChange={(option) => {
            handleOnChange(option)
            if (Array.isArray(option)) {
              helpers.setValue(option)
            } else {
              if (option) helpers.setValue(option.value)
              else helpers.setValue(null)
            }
          }}
          onBlur={() => helpers.setTouched(true)}
          options={options}
          {...rest}
        ></ReactSelect>
      </div>
      <ErrorMessage component={TextError} name={name} />
    </>
  )
}

export default SelectBySearch
