import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import ReactSelect from 'react-select'
import { Input } from 'reactstrap'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { useState, useEffect } from 'react'
import { GET_AREAS, GET_CITIES } from '../../../Types'
import { toast } from 'react-toastify'
import {
  addArea,
  deleteArea,
  resetArea,
  addCity,
  editCity,
  generateSector,
} from './CityAndAreaActions'
import { confirmAlert } from 'react-confirm-alert'

const CityAndArea = (props) => {
  const [newArea, setNewArea] = useState('')
  const [selectedCity, setSelectedCity] = useState('')

  const [newCity, setNewCity] = useState('')
  const [newCitySector, setNewCitySector] = useState('')

  const [isCityEdit, setIsCityEdit] = useState(false)

  const cityColumns = [
    {
      Header: 'City Code',
      accessor: 'code',
    },
    {
      Header: 'City Name',
      accessor: 'value',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button
            onClick={() => LoadCityData(row.value)}
            className='btn '
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fas fa-edit'></i>
          </button>
          &nbsp;
          {/* <Button
            variant='primary'
            className='btn btn-primary'
            title='Link Shop'
            onClick={() => GenerateSector(row.value)}
          >
            Generate Sector
          </Button> */}
          <button
            onClick={() => GenerateSector(row.value)}
            className='btn '
            title='Generate Sectors against this city'
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fas fa-city'></i>
          </button>
        </div>
      ),
    },
  ]

  const areaColumns = [
    {
      Header: 'Area Name',
      accessor: 'value',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button
            onClick={() => Delete(row.value)}
            className='btn btn-danger'
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fa fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  const addArea = async () => {
    if (selectedCity == '' || selectedCity == null) {
      toast.error('Select City ')
      return
    } else if (newArea == '' || newArea == null) {
      toast.error('Enter Area Name')
      return
    }

    let checkIsExist = props.areas.filter(
      (item) =>
        item.value.toUpperCase() === newArea.toUpperCase() ||
        item.value.replace(/ /g, '').trim().toUpperCase() ===
          newArea.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist.length > 0) {
      toast.warn('Area with the same name already exist for this city')
      return
    }

    let data = {
      Name: newArea,
      CityId: selectedCity.id,
    }
    await props.addArea(data)
    getAreas()

    setSelectedCity(null)
    setNewArea('')
  }

  const addCity = async () => {
    if (newCity == '' || newCity == null) {
      toast.error('Enter City Name')
      return
    } else if (newCitySector == '' || newCitySector == null) {
      toast.error('Enter Sector Coordinates')
      return
    }

    let checkIsExist = props.cities.filter(
      (item) =>
        item.label.toUpperCase() === newCity.toUpperCase() ||
        item.label.replace(/ /g, '').trim().toUpperCase() ===
          newCity.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist.length > 0) {
      toast.warn('City with the same name already exist')
      return
    }

    let data = {
      Name: newCity,
      CityPoligon: newCitySector,
    }
    await props.addCity(data)
    props.getDropdownList(`api/City`, GET_CITIES)
    // getAreas()

    setNewCity('')
    setNewCitySector('')
  }

  const updateCity = async () => {
    if (newCity == '' || newCity == null) {
      toast.error('Enter City Name')
      return
    } else if (newCitySector == '' || newCitySector == null) {
      toast.error('Enter Sector Coordinates')
      return
    }

    let cityId = props.cities.filter((x) => x.label == newCity)[0].id

    let data = {
      Name: newCity,
      CityPoligon: newCitySector,
    }
    await props.editCity(cityId, data)
    props.getDropdownList(`api/City`, GET_CITIES)
    // getAreas()

    setNewCity('')
    setNewCitySector('')
    setIsCityEdit(false)
  }

  const getAreas = () => {
    if (selectedCity != undefined && selectedCity?.id != undefined) {
      props.getDropdownList(`api/Area/${selectedCity.id}`, GET_AREAS)
    }
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const DeleteRecord = async (id) => {
    await props.deleteArea(id)
  }

  const LoadCityData = async (id) => {
    let city = props.cities.filter((x) => x.id == id)[0]
    setNewCity(city.label)
    setNewCitySector(city.polygon ?? '')
    setIsCityEdit(true)
  }

  const closeEditCity = () => {
    setNewCity('')
    setNewCitySector('')
    setIsCityEdit(false)
  }

  const GenerateSector = async (id) => {
    let req = { CityId: id }
    props.generateSector(req)
  }

  useEffect(() => {
    props.resetArea()

    props.getDropdownList(`api/City`, GET_CITIES)
  }, [])

  useEffect(() => {
    props.resetArea()
    getAreas()
  }, [selectedCity])
  return (
    <div>
      <VzPageContainer title='City And Area'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>City / Area</b>
            </h4>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col
            md={6}
            // style={{
            //   display: 'flex',
            //   flexDirection: 'row',
            //   justifyContent: 'space-between',
            // }}
          >
            <Row>
              <Col md={4}>
                <div className='form-group'>
                  <Input
                    value={newCity}
                    onChange={(name) => setNewCity(name.target.value)}
                    type='text'
                    placeholder='Enter City Name'
                    disabled={isCityEdit}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className='form-group'>
                  <Input
                    value={newCitySector}
                    onChange={(name) => setNewCitySector(name.target.value)}
                    type='text'
                    placeholder='Enter Sector Coordinates'
                  />
                </div>
              </Col>
              <Col
                md={3}
                style={{ marginTop: '10px', display: 'inline-block' }}
              >
                <div className='form-group'>
                  {!isCityEdit ? (
                    <Button
                      className='form-btn'
                      variant='primary'
                      onClick={addCity}
                      style={{ fontSize: 15 }}
                    >
                      Add City
                    </Button>
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span
                        style={{
                          marginRight: '10px',
                          cursor: 'pointer',
                          backgroundColor: '#fff',
                        }}
                        onClick={closeEditCity}
                      >
                        X
                      </span>

                      <Button
                        className='form-btn'
                        variant='primary'
                        onClick={updateCity}
                        style={{ fontSize: 15 }}
                      >
                        Update City
                      </Button>
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Row>
              <Col md={4}>
                <div className='form-group'>
                  <ReactSelect
                    id='city'
                    name='city'
                    className='basic-single'
                    classNamePrefix='select'
                    placeholder='Select City'
                    isMulti={false}
                    value={selectedCity}
                    isClearable={true}
                    onChange={(res) => setSelectedCity(res)}
                    options={props.cities ?? []}
                  ></ReactSelect>
                </div>
              </Col>
              <Col md={4}>
                <div className='form-group'>
                  <Input
                    value={newArea}
                    onChange={(name) => setNewArea(name.target.value)}
                    type='text'
                    placeholder='Enter Area Name'
                  />
                </div>
              </Col>
              <Col
                md={3}
                style={{ marginTop: '10px', display: 'inline-block' }}
              >
                <div className='form-group'>
                  <Button
                    className='form-btn'
                    variant='primary'
                    onClick={addArea}
                    style={{ fontSize: 15 }}
                  >
                    Add Area
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={1}></Col>
            </Row>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col xs={12} md={5}>
            <CustomDataTable
              data={props.cities ?? []}
              columns={cityColumns}
              getTrProps={() => {}}
            />
          </Col>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={5}>
            <CustomDataTable
              data={props.areas ?? []}
              columns={areaColumns}
              getTrProps={null}
            />
          </Col>{' '}
          <Col xs={12} md={1}></Col>
        </Row>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    cities: state.ddlList.cities,
    areas: state.ddlList.areas,
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
  addArea,
  deleteArea,
  resetArea,
  addCity,
  editCity,
  generateSector,
})(CityAndArea)
