import {
  GET_VLP_SCHEMES,
  UPDATE_VLP_SCHEME,
  DELETE_VLP_SCHEME,
} from '../../../Types'

const initialState = {
  totalRecords: 0,
  users: [],
}

const SchemesListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_VLP_SCHEMES:
      return action.payload
    case UPDATE_VLP_SCHEME:
      return state.map((x) => {
        if (x.id == action.payload.Id) {
          x.endDate = action.payload.EndDate
        }
        return x
      })
    case DELETE_VLP_SCHEME:
      return state.filter((x) => x.id != action.payload)
    default:
      return state
  }
}
export default SchemesListReducer
