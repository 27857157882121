import React, { Component, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { Formik, Form } from 'formik'
import FormikControl from '../../../layouts/Input/FormikControl'
import { getDropdownList } from '../../Shared/dropdownListActions'
import axios from '../../../apis/Api'

import Moment from 'react-moment'
import ReactSelect from 'react-select'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const ProductConfiguration = (props) => {
  const { id } = useParams()
  const [featureList, setFeatureList] = useState([])
  const [featureStatusList, setFeatureStatusList] = useState(null)
  const [centersList, setCentersList] = useState(null)
  const [connectColumns, setConnectColumns] = useState([])
  const [userList, setUserList] = useState(null)

  const [ticketCategoryList, setTicketCategoryList] = useState(null)

  const [centersLoaded, setCentersLoaded] = useState(false)

  const [isSelectAll, setIsSelectAll] = useState(false)

  const centerColumn = [
    {
      Header: 'Dist Center',
      accessor: 'centerId',
      Cell: (row) => (
        <div className='ms-2 my-2 ' key={1}>
          <input
            checked={row.row.original.isActive}
            value={row.row.original.isActive}
            type='checkbox'
            onChange={() => selectRow(row)}
            style={{ transform: 'scale(1.3)' }}
          />
          <span className='ms-2'>{row.cell?.row?.original?.centerName}</span>
        </div>
      ),
    },
  ]

  const resetColumn = [
    {
      Header: 'Reset',
      accessor: 'Reset',
      Cell: (row) => (
        <div className='form-group'>
          {/* <Link
            onClick={() => resetRow(row)}
            className='active'
            style={{
              padding: '0px',
              marginTop: '10px',
              float: 'left',
            }}
          >
            Reset
          </Link> */}
          <Button
            className='btn btn-default '
            onClick={() => resetRow(row)}
            style={{ fontSize: 15, margin: 0 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
  ]

  const selectAll = () => {
    setIsSelectAll(!isSelectAll)

    let centers = centersList.map((element) => {
      element.isActive = !isSelectAll
      return element
    })

    setCentersList(centers)
  }

  const columns = [
    {
      // Header: 'Dist Center',
      Header: (
        <div className='ms-2 my-2 '>
          <input
            checked={isSelectAll}
            value={isSelectAll}
            type='checkbox'
            onChange={selectAll}
            style={{ transform: 'scale(1.3)' }}
          />{' '}
          <span className='ms-2'>Dist Center</span>
        </div>
      ),
      accessor: 'centerId',
      disableSortBy: true,
      Cell: (row) => (
        <div className='ms-2 my-2 ' key={1}>
          <input
            checked={row.row.original.isActive}
            value={row.row.original.isActive}
            type='checkbox'
            onChange={() => selectRow(row)}
            style={{ transform: 'scale(1.3)' }}
          />
          <span className='ms-2'>{row.cell?.row?.original?.centerName}</span>
        </div>
      ),
    },
    {
      Header: 'Information',
      accessor: 'informationUserId',
      Cell: (row) => (
        <div className='form-group'>
          <ReactSelect
            id='informationUserId'
            name='informationUserId'
            className='basic-single'
            classNamePrefix='select'
            placeholder='Select User'
            isMulti={false}
            value={row.row.original.informationUserId}
            isDisabled={!row.row.original.isActive}
            // isClearable={true}
            // isSearchable={true}
            onChange={(option) => assignUser(row.row, option, 'information')}
            options={filterCenterUsers(row.cell?.row?.original?.centerId)}
            menuPlacement='auto'
            styles={customStyles}
            menuPortalTarget={document.body}
          ></ReactSelect>
        </div>
      ),
    },
    {
      Header: 'Online Orders',
      accessor: 'onlineOrderUserId',
      Cell: (row) => (
        <div className='form-group'>
          <ReactSelect
            id='onlineOrderUserId'
            name='onlineOrderUserId'
            className='basic-single'
            classNamePrefix='select'
            placeholder='Select User'
            isMulti={false}
            value={row.row.original.onlineOrderUserId}
            isDisabled={!row.row.original.isActive}
            // isClearable={true}
            // isSearchable={true}
            onChange={(option) => assignUser(row.row, option, 'online')}
            options={filterCenterUsers(row.cell?.row?.original?.centerId)}
            menuPlacement='auto'
            styles={customStyles}
            menuPortalTarget={document.body}
          ></ReactSelect>
        </div>
      ),
    },
    {
      Header: 'Complaints',
      accessor: 'complaintUserId',
      Cell: (row) => (
        <div className='form-group'>
          <ReactSelect
            id='complaintUserId'
            name='complaintUserId'
            className='basic-single'
            classNamePrefix='select'
            placeholder='Select User'
            isMulti={false}
            value={row.row.original.complaintUserId}
            isDisabled={!row.row.original.isActive}
            // isClearable={true}
            // isSearchable={true}
            onChange={(option) => assignUser(row.row, option, 'complaint')}
            options={filterCenterUsers(row.cell?.row?.original?.centerId)}
            menuPlacement='auto'
            styles={customStyles}
            menuPortalTarget={document.body}
          ></ReactSelect>
        </div>
      ),
    },
    {
      Header: 'Others',
      accessor: 'otherUserId',
      Cell: (row) => (
        <div className='form-group'>
          <ReactSelect
            id='otherUserId'
            name='otherUserId'
            className='basic-single'
            classNamePrefix='select'
            placeholder='Select User'
            isMulti={false}
            value={row.row.original.otherUserId}
            isDisabled={!row.row.original.isActive}
            // isClearable={true}
            // isSearchable={true}
            onChange={(option) => assignUser(row.row, option, 'other')}
            options={filterCenterUsers(row.cell?.row?.original?.centerId)}
            menuPlacement='auto'
            styles={customStyles}
            menuPortalTarget={document.body}
          ></ReactSelect>
        </div>
      ),
    },
    {
      Header: 'Reset',
      accessor: 'Reset',
      Cell: (row) => (
        <div className='form-group'>
          <Button
            className='btn btn-default '
            onClick={() => resetRow(row)}
            style={{ fontSize: 15, margin: 0 }}
          >
            Reset
          </Button>
        </div>
      ),
    },
  ]

  const data = [
    {
      centerId: '1',
    },
  ]

  const customStyles = {
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 9999,
    }),
  }

  const getFeaturesList = () => {
    axios
      .get(`api/DistFeatureCategory`)
      .then(({ data }) => {
        if (data != null && data.length > 0) {
          setFeatureList(
            data.map((x) => ({ ...x, isExpanded: false, isChecked: false }))
          )
        }
      })
      .catch(function (error) {})
  }

  const alterExpand = (id) => {
    let features = [...featureList]
    features.forEach((x) => {
      if (x.id == id) {
        x.isExpanded = !x.isExpanded
      }
    })

    setFeatureList(features)
  }

  const getFeatureStatusList = () => {
    if (id) {
      axios
        .get(`api/DistFeatureCategoryStatus/DistributorCategoryStatus/${id}`)
        .then(({ data }) => {
          if (data?.response != null && data?.response?.length > 0) {
            setFeatureStatusList(data.response)
            const features = featureList.map((x) => {
              const f = data.response.filter(
                (r) => r.distFeatureCategoryId == x.id
              )
              if (f != null && f.length) {
                x.isChecked = f[0].enabledStatus
              }
              return x
            })
            setFeatureList(features)
          } else {
            setFeatureStatusList([])
          }
        })
        .catch(function (error) {})
    }
  }

  const changeFeatureStatus = (event, feature) => {
    if (props?.distributorMainInfo?.id) {
      const req = {
        DistributorId: props?.distributorMainInfo?.id,
        DistFeatureCategoryId: feature.id,
        EnabledStatus: !feature.isChecked,
      }

      axios
        .post(`api/DistFeatureCategoryStatus`, req)
        .then(({ data }) => {
          if (data != null && data.status == 'Success') {
            toast.success('Feature right changed')
            let updateFeatures = featureList.map((x) => {
              if (x.id == feature.id) {
                x.isChecked = !x.isChecked
              }
              return x
            })
            setFeatureList(updateFeatures)
          }
        })
        .catch(function (error) {})
    }
  }

  const getTicketCategories = () => {
    axios
      .get(`api/VLCTicketCategory`)
      .then(({ data }) => {
        setTicketCategoryList(data)
        let catColumns = data.map((x) => {
          return {
            Header: x.name,
            accessor: x.name,
            Cell: (row) => (
              <div className='form-group'>
                <ReactSelect
                  id={x.name}
                  name={x.name}
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Select User'
                  isMulti={false}
                  value={row.cell.row.values[x.name]}
                  // isDisabled={!row.row.original.isActive}
                  onChange={(option) => {}}
                  options={filterCenterUsers(row.cell?.row?.original?.centerId)}
                  menuPlacement='auto'
                  styles={customStyles}
                  menuPortalTarget={document.body}
                ></ReactSelect>
              </div>
            ),
          }
        })

        setConnectColumns([...centerColumn, ...catColumns, ...resetColumn])
      })
      .catch(function (error) {})
  }

  const getCenterList = () => {
    if (id) {
      axios
        .get(`api/DistCenter/GetDistributionCentersByDistributor/${id}`)
        .then(({ data }) => {
          if (data != null && data?.length > 0) {
            setCentersList(
              data.map((x) => {
                return {
                  id: null,
                  centerId: x.id,
                  centerName: x.distCenterName,
                  isActive: false,
                  informationUserId: null,
                  onlineOrderUserId: null,
                  complaintUserId: null,
                  otherUserId: null,
                }
              })
            )
          }
        })
        .catch(function (error) {})
    }
  }

  const getDistUserList = () => {
    if (id) {
      axios
        .get(`api/Distributor/GetDistributorUsers/${id}`)
        .then(({ data }) => {
          if (data != null && data?.response) {
            setUserList(data.response)
          }
        })
        .catch(function (error) {})
    }
  }

  const filterCenterUsers = (centerId) => {
    if (userList != null && userList.length > 0) {
      let centerUser = userList
        .filter((x) => x.centerId.toLowerCase().includes(centerId))
        .map((u) => {
          return {
            value: u.userId,
            label: u.userName,
          }
        })
      return centerUser
    } else {
      return []
    }
  }

  const selectRow = (row) => {
    let list = [...centersList]
    list[row.row.id].isActive = !list[row.row.id].isActive

    // if (list[row.row.id].isActive == false) {
    //   list[row.row.id].isActive = false
    //   list[row.row.id].informationUserId = null
    //   list[row.row.id].onlineOrderUserId = null
    //   list[row.row.id].complaintUserId = null
    //   list[row.row.id].otherUserId = null
    // }
    setCentersList(list)
  }

  const resetRow = (row) => {

    let list = [...centersList]
    list[row.row.id].isActive = false
    list[row.row.id].informationUserId = null
    list[row.row.id].onlineOrderUserId = null
    list[row.row.id].complaintUserId = null
    list[row.row.id].otherUserId = null
    setCentersList(list)
  }

  const resetAll = () => {
    let list = [...centersList]
    list.map(
      (x) => (
        (x.isActive = false),
        (x.informationUserId = null),
        (x.onlineOrderUserId = null),
        (x.complaintUserId = null),
        (x.otherUserId = null)
      )
    )

    setCentersList(list)
  }

  const assignUser = (row, value, type) => {
    let list = [...centersList]
    // list[row.id].rso = rso

    if (type == 'information') {
      list[row.id].informationUserId = value
    }
    if (type == 'online') {
      list[row.id].onlineOrderUserId = value
    }
    if (type == 'complaint') {
      list[row.id].complaintUserId = value
    }
    if (type == 'other') {
      list[row.id].otherUserId = value
    }

    setCentersList(list)
  }

  const saveChanges = () => {
    let saveList = [...centersList]

    saveList = saveList.map((x) => {
      let checkActive =
        (x.informationUserId == '' || x.informationUserId == null) &&
        (x.onlineOrderUserId == '' || x.onlineOrderUserId == null) &&
        (x.complaintUserId == '' || x.complaintUserId == null) &&
        (x.otherUserId == '' || x.otherUserId == null)
          ? false
          : x.isActive
      return {
        id: x.id ? x.id : null,
        isActive: checkActive,
        distCenterId: x.centerId,
        informationUserId: x.informationUserId
          ? x.informationUserId?.value
          : '',
        onlineOrderUserId: x.onlineOrderUserId
          ? x.onlineOrderUserId?.value
          : '',
        complaintUserId: x.complaintUserId ? x.complaintUserId?.value : '',
        otherUserId: x.otherUserId ? x.otherUserId?.value : '',
      }
    })

    axios
      .post(`api/VLCTicketCategoryUserAllocation`, saveList)
      .then(({ data }) => {
        if (data != null && data.status == 'Success') {
          toast.success('Changes saved')
        }
      })
      .catch(function (error) {})
  }

  const getDistributorTicketUserAllocations = () => {
    if (id) {
      axios
        .get(
          `api/VLCTicketCategoryUserAllocation/GetDistributorTicketUserAllocations/${id}`
        )
        .then(({ data }) => {
          setCentersLoaded(true)
          if (data != null && data.length > 0) {
            mapUserAllocations(data)
          }
        })
        .catch(function (error) {})
    }
  }

  const mapUserAllocations = (data) => {
    let centers = [...centersList]
    centers.forEach((element) => {
      let row = data.find((x) => x.distCenterId == element.centerId)
      if (row != null) {
        element.isActive = row.isActive
        element.id = row.id
        element.informationUserId = findUser(row.informationUserId)
        element.onlineOrderUserId = findUser(row.onlineOrderUserId)
        element.complaintUserId = findUser(row.complaintUserId)
        element.otherUserId = findUser(row.otherUserId)
      }
    })
    // setCentersList(centers)
  }

  const findUser = (userId) => {
    if (userId == null || userId.length == 0) return null

    let user = userList.find((x) => x.userId == userId)
    return {
      label: user.userName,
      value: user.userId,
    }
  }

  useEffect(() => {
    getDistUserList()

    getFeaturesList()
    getFeatureStatusList()
  }, [])

  useEffect(() => {
    if (featureStatusList == null && featureList.length > 0) {
      getFeatureStatusList()
    }
  }, [featureList])

  useEffect(() => {
    if (centersList != null && centersList.length > 0 && !centersLoaded) {
      //getTicketCategories()
      getDistributorTicketUserAllocations()
    }
  }, [centersList])

  useEffect(() => {
    if (userList != null) {
      getCenterList()
    }
  }, [userList])

  return (
    <div>
      <Row>
        <Col>
          <p>Please select the product</p>
        </Col>
      </Row>
      {featureList.map((x, index) => (
        <Row>
          <Col
            xs={12}
            md={10}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Col xs={1}>
              <input
                // disabled={selectedUserRole == null ? true : false}
                value={x.isChecked}
                checked={x.isChecked}
                // onChange={(event) => {
                //   selectAllPermissions()
                // }}
                onChange={(event) => {
                  changeFeatureStatus(event, x)
                }}
                type='checkbox'
                style={{
                  transform: 'scale(1.3)',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
              />
            </Col>
            <Col xs={4}>
              <span>{x.name}</span>
            </Col>
            <Col xs={2}>
              <span
                className='product-expand'
                onClick={() => alterExpand(x.id)}
                style={{ alignItems: 'end' }}
              >
                {x.isExpanded ? <>-</> : <>+</>}
              </span>
            </Col>
          </Col>
          {x.isExpanded ? (
            <Col>
              {x.name.toLowerCase().includes('connect') && (
                <CustomDataTable
                  data={centersList ? centersList : []}
                  columns={columns}
                  IsSearchEnable={false}
                  buttonRow={
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        className='btn btn-default mx-2'
                        style={{ fontSize: 15, margin: 0 }}
                        onClick={resetAll}
                      >
                        Reset
                      </Button>
                      <Button
                        className='form-btn mx-2'
                        variant='primary'
                        style={{ fontSize: 15, margin: 0 }}
                        onClick={saveChanges}
                      >
                        Save
                      </Button>
                    </div>
                  }
                />
              )}
            </Col>
          ) : (
            <></>
          )}
        </Row>
      ))}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo
      ? state.distributorMainInfo
      : {},
  }
}

export default connect(mapStateToProps, {
  getDropdownList,
})(ProductConfiguration)
