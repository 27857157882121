import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import backgroundImage from '../../assets/Images/Login_background_Image.gif';
import vizLogo from '../../assets/Images/Vizlink_logo.png';

import FormikControl from '../../layouts/Input/FormikControl';

import { signIn } from './AuthAction';
import { resetAccountPassword } from './AuthAction';
import history from '../../history';


const ResetPassword = props => 
{
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  const routeParams = useParams();

  const [authenticationObject, setAuthenticationObject] = useState({ username: routeParams.username, code: routeParams.code });

  const [inputPassword, setInputPassword] = useState({ password: '', confirmPassword: '' });

  const passwordValidationSchema = Yup.object().shape
  ({
    password: Yup.string()
              .min(8, 'Password length must be 08 or more characters')
              .required('Password is required'),

    confirmPassword: Yup.string()
                     .min(8, 'Password length must be 08 or more characters')
                     .equals([inputPassword.password], 'Both passwords must match')
                     .required('Confirmation Password is required'),
  });
  
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  async function ResetAccountPassword()
  {
    await props.resetAccountPassword
    (
      authenticationObject.code, 
      authenticationObject.username, 
      inputPassword.password, 
      inputPassword.confirmPassword,
      () => setTimeout(() => RouteToLoginPage(), 1000),
      () => setInputPassword({ password: '', confirmPassword: '' })
    );
  }

  function RouteToLoginPage()
  {
    history.push('/login'); 
    history.go('/login');
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  return(
    <>
      <div 
        className='login-page'
        style=
        {{
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className='login-box elevation-4'>

          <div className='card-header text-center'>
            <Link 
              className='brand-link clearfix'
              to={'/resetpassword'} 
            >
              <Image
                src={vizLogo}
                alt={'VizLink App Logo'}
              />
            </Link>
          </div>

          <div className='card-body'>
            <p className='login-box-msg'>
              {'Please enter your new password for\n'}
              <span style={{color: '#ea5f2d'}}>{authenticationObject.username}</span>
            </p>

            <Formik
              enableReinitialize
              validationSchema={passwordValidationSchema}
              onSubmit={() => ResetAccountPassword()}
              initialValues={inputPassword}              
            >
              {
                (formik) =>
                (
                  <Form autoComplete={'off'}>
                    <Modal.Body>
                      <Container>

                        <Row>
                          <Col xs={12} md={12}>
                            <div className='form-group'>
                              <FormikControl
                                formik={formik}
                                control='input'
                                type='password'
                                label='Password'
                                name='password'
                                value={inputPassword.password}
                                handleOnChange={text => setInputPassword({ ...inputPassword, password: text})}
                              />
                            </div>
                          </Col>

                          <Col xs={12} md={12}>
                            <div className='form-group'>
                              <FormikControl
                                formik={formik}
                                control='input'
                                type='password'
                                label='Confirm Password'
                                name='confirmPassword'
                                value={inputPassword.confirmPassword}
                                handleOnChange={text => setInputPassword({ ...inputPassword, confirmPassword: text})}
                              />
                            </div>
                          </Col>

                        </Row>
                      
                      </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: 'space-between', margin: '0px 2.25% 0px 2.25%', }}>
                      <input
                        className='btn btn-secondary'
                        type='button'
                        value='Cancel'
                        onClick={() => RouteToLoginPage()}
                      />
                      <input
                        className='btn btn-primary'
                        type='submit'
                        value='Reset Password'
                      />
                    </Modal.Footer>
                  </Form>
                )
              }
            </Formik>
          </div>

        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => 
{
  return{
  }
}

export default connect(mapStateToProps, { signIn, resetAccountPassword })(ResetPassword);
