const AudioPlayer = (props) => {
  const { controlsList, src, style, className, ...rest } = props
  const handleAudioClick = () => {
    const audioElements = document.querySelectorAll('audio')
    audioElements.forEach((audio) => {
      if (audio.id != src) {
        audio.pause()
      }
    })

    // const currentAudio = document.getElementById(src)
    // if (currentAudio != null) {
    //   currentAudio.play()
    // }
  }

  return (
    <audio
      id={src}
      controls
      controlsList={controlsList}
      style={style}
      className={className}
      onPlaying={handleAudioClick}
    >
      <source src={src} type='audio/mpeg' />
      Your browser does not support the audio element.
    </audio>
  )
}

export default AudioPlayer
