import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import FormikControl from '../../../layouts/Input/FormikControl'
import ReactSelect from 'react-select'
import ExcelIcon from '../../../assets/Images/Distributors/excel-icon.png'
import QRorTillDataTemplateForJazzWarid from '../../../assets/Templates/QRorTillDataTemplateForJazzWarid.xlsx'
import QRorTillDataTemplateForTelenor from '../../../assets/Templates/QRorTillDataTemplateForTelenor.xlsx'
import { toast } from 'react-toastify'
import {
  addQRorTillDetails,
  getQRorTillDetails,
  updateQRorTillDetails,
  deleteQRorTillDetails,
  saveExcelSheetData,
  getMfiDetailsList,
  getQRorTillList,
  getFilterMfiList,
} from './QrTillListActions'
import ReactToPrint from 'react-to-print'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { getDropdownList } from '../../Shared/dropdownListActions'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  GET_MFI_DDL,
  GET_QRTILL_MFI_FILTER_LIST,
  GET_QRTILL_CENTER_FILTER_LIST,
} from '../../../Types'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'

const QrTillList = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [initialValues] = useState({
    id: '',
    distributor: '',
    distributionName: '',
    mfiName: '',
    resourceName: '',
    msisdn: '',
    tillNumber: '',
    email: '',
    notifier1: '',
    notifier2: '',
    routeNo: '',
    address: '',
    qrImagePath: '',
  })
  const [currentQRorTillDetails, setCurrentQRorTillDetails] =
    useState(initialValues)

  const [componentRef, setComponentRef] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [qrImageModalShow, setQrImageModalShow] = useState(false)
  const [qrSelectedImage, setQrSelectedImage] = useState('')
  const [readOnly, setReadOnly] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [distributorNameValue, setDistributorNameValue] = useState(null)
  const [distributionNameValue, setDistributionNameValue] = useState(null)
  const [dataSheetModalShow, setDataSheetModalShow] = useState(false)
  const [dataSheetMfiName, setDataSheetMfiName] = useState(null)
  const [dataSheetDistCenterName, setDataSheetDistCenterName] = useState(null)
  const [dataSheetSelectedFile, setDataSheetSelectedFile] = useState(null)
  const [dataSheetInitialValue, setDataSheetInitialValue] = useState({
    distributionCenterName: '',
    dataSheetMfiName: '',
  })
  const [mfiNameValue, setMfiNameValue] = useState(null)
  const [DistributorsList, setDistributorsList] = useState([])
  const [DistCentersList, setDistCentersList] = useState([])
  const [selectedMfiNames, setSelectedMfiNames] = useState([])
  const [qrImage, setQrImage] = useState(null)
  const [QRorTillDetailsList, setQRorTillDetailsList] = useState([])
  const [selectedMfiFilter, setSelectedMfiFilter] = useState(null)
  const [selectedDistributorFilter, setSelectedDistributorFilter] =
    useState(null)

  const [selectedDistCenterFilter, setSelectedDistCenterFilter] = useState(null)
  const [mfiDistCentersList, setMfiDistCentersList] = useState([])

  const [filterDistCentersList, setFilterDistCentersList] = useState([])

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    distributionName: Yup.string().required('Distribution Center is required'),
    mfiName: Yup.string().required('MFI is required'),
    resourceName: Yup.string()
      .matches(
        /\S/,
        'Resource Name should contain at least one non-blank character'
      )
      .required('Resource Name is Required'),
    msisdn: Yup.string()
      .matches(/\S/, 'MSISDN should contain at least one non-blank character')
      .required('MSISDN is Required'),
    tillNumber: Yup.string()
      .matches(
        /\S/,
        'Till Number should contain at least one non-blank character'
      )
      .required('Till Number is Required'),
  })
  const dataSheetValidationSchema = Yup.object().shape({
    distributionCenterName: Yup.string().required(
      'Distribution Center is required'
    ),
    dataSheetMfiName: Yup.string().required('MFI is required'),
  })
  const columns = [
    {
      Header: 'Distribution Center',
      accessor: 'distCenterName',
    },
    {
      Header: 'MFI',
      accessor: 'mfiName',
    },
    {
      Header: 'Resource Name',
      accessor: 'resourceName',
    },
    {
      Header: 'MSISDN',
      accessor: 'msisdn',
    },
    {
      Header: 'Till Number',
      accessor: 'tillNumber',
    },
    {
      Header: 'Upload QR',
      accessor: 'qrImagePath',
      Cell: (row) => (
        <div>
          {!row.value ||
            row.value === undefined ||
            row.value === null ||
            row.value === '' ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => null}
              className='badge badge-warning'
            >
              Not Available
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setQrSelectedImage(row.value)
                setQrImageModalShow(true)
              }}
              className='badge badge-info'
            >
              <i className='fas fa-eye'></i> View Image
            </div>
          )}
        </div>
      ),
    },
    {
      Header: 'Action',
      id: 'edit',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          {isPermittedPage('view details', props.actions, props.userRole) &&
            <button onClick={() => View(row.value)} className=' btn btn-sm'>
              <i className='fas fa-eye'></i>
            </button>
          }
          &nbsp;
          {isPermittedPage('edit', props.actions, props.userRole) &&
            <button onClick={() => Edit(row.value)} className='btn btn-sm'>
              <i className='fas fa-edit'></i>
            </button>
          }
          &nbsp;
          {isPermittedPage('delete', props.actions, props.userRole) &&
            <button onClick={() => Delete(row.value)} className='btn btn-sm'>
              <i className='fas fa-trash'></i>
            </button>
          }
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
      mfi: selectedMfiFilter == null ? null : selectedMfiFilter.label,
      distributor:
        selectedDistributorFilter == null
          ? null
          : selectedDistributorFilter.value,
      distCenter:
        selectedDistCenterFilter == null
          ? null
          : selectedDistCenterFilter.value,
    }

    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }

    props.getQRorTillList(requestData)
  }, [
    searchText,
    selectedMfiFilter,
    selectedDistCenterFilter,
    selectedDistributorFilter,
  ])

  useEffect(() => {
    if (props.QrTillList && props.QrTillList.length > 0) {
      setTotalRecords(props.QrTillList[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.QrTillList])
  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.distributionCenters.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setDistCentersList(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      if (props.distributionCenters && props.distributionCenters.length > 0) {
        setDistCentersList(
          props.distributionCenters.map(function (item) {
            return {
              value: item.id,
              label: item.distCenterName,
            }
          })
        )
      }
    }
  }, [props.distributionCenters])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.filterDistCenterList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setFilterDistCentersList(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      if (props.filterDistCenterList && props.filterDistCenterList.length > 0) {
        setFilterDistCentersList(
          props.filterDistCenterList.map(function (item) {
            return {
              value: item.value,
              label: item.label,
            }
          })
        )
      }
    }
  }, [props.filterDistCenterList])

  useEffect(() => {
    setQRorTillDetailsList(props.qrOrTillDetails)
  }, [props.qrOrTillDetails])
  const handleMfiFilterOnChange = (value) => {
    setSelectedMfiFilter(value)
    if (value && selectedDistCenterFilter && selectedDistCenterFilter.value) {
      setQRorTillDetailsList(
        props.qrOrTillDetails.filter(
          (e) =>
            e.merchantId === value.value &&
            e.distCenterId === selectedDistCenterFilter.value
        )
      )
    } else if (selectedDistCenterFilter && selectedDistCenterFilter.value) {
      setQRorTillDetailsList(
        props.qrOrTillDetails.filter(
          (e) => e.distCenterId === selectedDistCenterFilter.value
        )
      )
    } else if (value) {
      setQRorTillDetailsList(
        props.qrOrTillDetails.filter((e) => e.merchantId === value.value)
      )
    } else {
      setQRorTillDetailsList(props.qrOrTillDetails)
    }
  }
  const handleDistCenterFilterOnChange = (value) => {
    setSelectedDistCenterFilter(value)
    if (value && selectedMfiFilter && selectedMfiFilter.value) {
      setQRorTillDetailsList(
        props.qrOrTillDetails.filter(
          (e) =>
            e.merchantId === selectedMfiFilter.value &&
            e.distCenterId === value.value
        )
      )
    } else if (selectedMfiFilter && selectedMfiFilter.value) {
      setQRorTillDetailsList(
        props.qrOrTillDetails.filter(
          (e) => e.merchantId === selectedMfiFilter.value
        )
      )
    } else if (value) {
      setQRorTillDetailsList(
        props.qrOrTillDetails.filter((e) => e.distCenterId === value.value)
      )
    } else {
      setQRorTillDetailsList(props.qrOrTillDetails)
    }
  }

  const View = (id) => {
    let tempObj = props.QrTillList.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }
    let distributor = DistributorsList.find(
      (x) => x.value.toLowerCase() == tempObj.distributorId.toLowerCase()
    )
    setDistributorNameValue(distributor)
    setMfiNameValue({
      label: tempObj.mfiName,
      value: tempObj.merchantId,
    })
    setDistributionNameValue({
      label: tempObj.distCenterName,
      value: tempObj.distCenterId,
    })
    setCurrentQRorTillDetails({ ...tempObj })
    setModalShow(true)
    setReadOnly(true)
    setQrImage(null)
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    // props.deleteQRorTillDetails(id)
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const Edit = (id) => {
    let tempObj = props.QrTillList.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }

    let distributor = DistributorsList.find(
      (x) => x.value.toLowerCase() == tempObj.distributorId.toLowerCase()
    )
    setDistributorNameValue(distributor)
    handleMfiSelection({
      label: tempObj.mfiName,
      value: tempObj.merchantId,
    })
    setDistributionNameValue({
      label: tempObj.distCenterName,
      value: tempObj.distCenterId,
    })
    setCurrentQRorTillDetails({
      ...tempObj,
      distributionName: tempObj.distCenterId,
      mfiName: tempObj.merchantId,
    })
    setModalShow(true)
    setReadOnly(false)
    setIsEdit(true)
    setQrImage(null)
  }

  const OpenModelForAddQRorTillDetails = () => {
    setReadOnly(false)
    setIsEdit(false)
    setCurrentQRorTillDetails({ ...initialValues })
    setDistributionNameValue(null)
    setDistributorNameValue(null)
    setModalShow(true)
    setMfiNameValue(null)
    setQrImage(null)
  }
  const handleSubmit = async (data) => {
    if (!distributorNameValue.value) {
      return
    }
    if (!data.id) {
      await props.addQRorTillDetails(
        {
          distributorId: distributorNameValue.value, //data.distributorId,
          id: data.id,
          merchantId: mfiNameValue.value,
          distCenterId: distributionNameValue.value,
          resourceName: data.resourceName,
          tillNumber: data.tillNumber,
          // isAvailableForAllDistCenters: data.isAvailableForAllDistCenters,
          msisdn: data.msisdn,
          email: data.email,
          notifier1: data.notifier1,
          notifier2: data.notifier2,
          routeNo: data.routeNo,
          address: data.address,
          qrImage: qrImage,
        },
        distributionNameValue.label,
        mfiNameValue.label
      )
    } else {
      await props.updateQRorTillDetails(
        data.id,
        {
          distributorId: distributorNameValue.value, //data.distributorId,
          id: currentQRorTillDetails.id,
          merchantId: mfiNameValue.value,
          distCenterId: distributionNameValue.value,
          resourceName: currentQRorTillDetails.resourceName,
          tillNumber: currentQRorTillDetails.tillNumber,
          msisdn: currentQRorTillDetails.msisdn,
          email:
            currentQRorTillDetails.email == null
              ? ''
              : currentQRorTillDetails.email,
          notifier1:
            currentQRorTillDetails.notifier1 == null
              ? ''
              : currentQRorTillDetails.notifier1,
          notifier2:
            currentQRorTillDetails.notifier2 == null
              ? ''
              : currentQRorTillDetails.notifier2,
          routeNo:
            currentQRorTillDetails.routeNo == null
              ? ''
              : currentQRorTillDetails.routeNo,
          address:
            currentQRorTillDetails.address == null
              ? ''
              : currentQRorTillDetails.address,
          qrImage: qrImage,
          qrImagePath: currentQRorTillDetails.qrImagePath,
        },
        distributionNameValue.label,
        mfiNameValue.label
      )
    }

    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: '',
      mfi: selectedMfiFilter == null ? null : selectedMfiFilter.label,
      distributor:
        selectedDistributorFilter == null
          ? null
          : selectedDistributorFilter.value,
      distCenter:
        selectedDistCenterFilter == null
          ? null
          : selectedDistCenterFilter.value,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getQRorTillList(requestData)

    setModalShow(false)
  }
  const OpenModelForUploadDataSheet = (isOpen) => {
    setDataSheetDistCenterName(null)
    setDataSheetMfiName(null)
    setDataSheetSelectedFile(null)
    setDataSheetInitialValue({
      distributionCenterName: '',
      dataSheetMfiName: '',
    })
    setDataSheetModalShow(isOpen)
  }
  const submitExcelSheetData = (data) => {
    if (!props.distributorMainInfo.id) {
      return
    }
    if (dataSheetSelectedFile != null) {
      props.saveExcelSheetData({
        distributorId: props.distributorMainInfo.id, //data.distributorId,
        merchantId: dataSheetMfiName.value,
        mfiName: dataSheetMfiName.label,
        distCenterId: dataSheetDistCenterName.value,
        dataSheetFile: dataSheetSelectedFile,
      })
      OpenModelForUploadDataSheet(false)
    } else {
      toast.error('Please select QR/Till Data file')
    }
  }
  const handleQRImageOnChange = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return false
    }
    if (!image.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      toast.error(
        'You can only upload file image of type (jpg, jpeg, png, gif)'
      )
      event.target.value = null
      return false
    } else {
      setQrImage(event.target.files[0])
    }
  }
  const validateDataSheetFile = (event) => {
    const image = event.target.files[0]
    if (!image) {
      event.target.value = null
      return
    }
    if (!image.name.match(/\.(xlsx|xls)$/)) {
      toast.error('You can only upload file of type (xlsx, xls)')
      event.target.value = null
      return
    } else {
      setDataSheetSelectedFile(event.target.files[0])
    }
  }
  const removeImage = () => {
    setQrImage(null)
    setCurrentQRorTillDetails({ ...currentQRorTillDetails, qrImagePath: '' })
  }

  const handleMfiSelection = (option) => {
    setMfiNameValue(option)
    currentQRorTillDetails.mfiName = option.value
    var tempMfi = props.mfiDetails.find((x) => x.id == option.value)
    if (!tempMfi) return
    if (tempMfi.distCentersArray.length > 0)
      setMfiDistCentersList(
        props.distributionCenters
          .filter((item) => tempMfi.distCentersArray.includes(item.id))
          .map(function (item1) {
            return {
              value: item1.id,
              label: item1.distCenterName,
            }
          })
      )
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      mfi: selectedMfiFilter == null ? null : selectedMfiFilter.label,
      distributor:
        selectedDistributorFilter == null
          ? null
          : selectedDistributorFilter.value,
      distCenter:
        selectedDistCenterFilter == null
          ? null
          : selectedDistCenterFilter.value,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getQRorTillList(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      mfi: selectedMfiFilter == null ? null : selectedMfiFilter.label,
      distributor:
        selectedDistributorFilter == null
          ? null
          : selectedDistributorFilter.value,
      distCenter:
        selectedDistCenterFilter == null
          ? null
          : selectedDistCenterFilter.value,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData = {
        ...requestData,
        assignedDistCenters: userRights?.distCenterIds,
      }
    }
    props.getQRorTillList(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const DeleteRecord = async (id) => {
    await props.deleteQRorTillDetails(id)

    if (totalRecords > 5 && props.QrTillList.length == 1) {
      let previousPage = currentPageNo > 0 ? currentPageNo - 1 : 0
      setCurrentPageNo(previousPage)
      let requestData = {
        pageSize: currentPageSize,
        pageNo: previousPage,
        searchText: searchText,
        mfi: selectedMfiFilter == null ? null : selectedMfiFilter.label,
        distributor:
          selectedDistributorFilter == null
            ? null
            : selectedDistributorFilter.value,
        distCenter:
          selectedDistCenterFilter == null
            ? null
            : selectedDistCenterFilter.value,
      }

      if (userRights != null && userRights?.userType == 'DistributorUser') {
        requestData = {
          ...requestData,
          assignedDistCenters: userRights?.distCenterIds,
        }
      }
      props.getQRorTillList(requestData)
    } else {
      let requestData = {
        pageSize: currentPageSize,
        pageNo: currentPageNo,
        searchText: searchText,
        mfi: selectedMfiFilter == null ? null : selectedMfiFilter.label,
        distributor:
          selectedDistributorFilter == null
            ? null
            : selectedDistributorFilter.value,
        distCenter:
          selectedDistCenterFilter == null
            ? null
            : selectedDistCenterFilter.value,
      }

      if (userRights != null && userRights?.userType == 'DistributorUser') {
        requestData = {
          ...requestData,
          assignedDistCenters: userRights?.distCenterIds,
        }
      }
      props.getQRorTillList(requestData)
    }
  }

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setDistributorsList(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setDistributorsList(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.selectedDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setDistCentersList(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setDistCentersList(
        props.selectedDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.selectedDistCentersddlList])

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  useEffect(() => {
    if (distributorNameValue) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${distributorNameValue.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )

      props.getMfiDetailsList(distributorNameValue.value)
    }
  }, [distributorNameValue])

  useEffect(() => {
    if (selectedDistributorFilter) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${selectedDistributorFilter.value}`,
        GET_QRTILL_CENTER_FILTER_LIST
      )

      props.getFilterMfiList(selectedDistributorFilter.value)
    }
  }, [selectedDistributorFilter])

  useEffect(async () => {
    if (props.mfiDetails && props.mfiDetails.length > 0) {
      setSelectedMfiNames(
        props.mfiDetails.map(function (item) {
          return {
            value: item.id,
            label: item.mfiName,
          }
        })
      )
    }
  }, [props.mfiDetails])

  return (
    <div>
      <VzPageContainer title='QR/Till List'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>QR/Till List</b>
            </h4>
            <br />
          </Col>
        </Row>

        <Row>
          {/* <Col md={{ span: 2, offset: 0 }} style={{ float: 'left' }}>
            <Button
              className='form-btn pull-left'
              variant='outline-info'
              style={{ float: 'left', margin: '1px 0px 0px 0px' }}
              onClick={() => OpenModelForUploadDataSheet(true)}
            >
              <i className='fa fa-file-excel' /> Upload Data Sheet
            </Button>
          </Col> */}
          <Col md={{ span: 3, offset: 1 }} style={{ float: 'right' }}>
            <div className='form-group'>
              <ReactSelect
                id='DistributorFilter'
                name='DistributorFilter'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Distributor'
                isMulti={false}
                isClearable={true}
                value={selectedDistributorFilter}
                onChange={(option) => {
                  if (!option || !option.value) {
                    setSelectedDistributorFilter(null)
                  } else {
                    setSelectedDistributorFilter(option)
                  }
                }}
                options={DistributorsList}
              ></ReactSelect>
            </div>
          </Col>
          <Col md={{ span: 3 }} style={{ float: 'right' }}>
            <div className='form-group'>
              <ReactSelect
                id='MfiFilter'
                name='MfiFilter'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select MFI'
                isMulti={false}
                isClearable={true}
                value={selectedMfiFilter}
                onChange={(option) => {
                  if (!option || !option.value) {
                    setSelectedMfiFilter(null)
                  } else {
                    setSelectedMfiFilter(option)
                  }
                }}
                options={props.filterMfiList}
              ></ReactSelect>
            </div>
          </Col>

          <Col md={{ span: 3 }} style={{ float: 'right' }}>
            <div className='form-group'>
              <ReactSelect
                id='DistCenterFilter'
                name='DistCenterFilter'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Distribution Center'
                isMulti={false}
                isClearable={true}
                onChange={(value) => handleDistCenterFilterOnChange(value)}
                options={filterDistCentersList}
              ></ReactSelect>
            </div>
          </Col>
          {isPermittedPage('Add QR/Till', props.actions, props.userRole) &&
            <Col md={{ span: 2 }} style={{ float: 'right' }}>
              <Button
                className='form-btn pull-right'
                variant='primary'
                style={{ float: 'right', margin: '1px 0px 0px 0px' }}
                onClick={OpenModelForAddQRorTillDetails}
              >
                <i className='fa fa-plus' /> Add QR/Till
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <ServerSideDataTable
              data={props.QrTillList ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Formik
            enableReinitialize
            initialValues={currentQRorTillDetails}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form
                autoComplete='off'
                encType='multipart/form-data'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    QR/Till Details
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={12} md={8}>
                        <Container>
                          <Row>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  readOnly={readOnly}
                                  control='react-select'
                                  type='distributor'
                                  label='Distributor'
                                  name='distributor'
                                  value={distributorNameValue}
                                  options={DistributorsList}
                                  handleOnChange={(option) => {
                                    setDistributorNameValue(option)
                                    currentQRorTillDetails.distributor =
                                      option.value
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  readOnly={readOnly}
                                  control='react-select'
                                  type='distributionName'
                                  label='Distribution Center'
                                  name='distributionName'
                                  value={distributionNameValue}
                                  options={DistCentersList}
                                  handleOnChange={(option) => {
                                    setDistributionNameValue(option)
                                    currentQRorTillDetails.distributionName =
                                      option.value
                                  }}
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  readOnly={readOnly}
                                  control='react-select'
                                  type='mfiName'
                                  label='MFI'
                                  name='mfiName'
                                  value={mfiNameValue}
                                  options={selectedMfiNames}
                                  handleOnChange={(option) =>
                                    handleMfiSelection(option)
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={3}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='resourceName'
                                  label='Resource Name'
                                  readOnly={readOnly}
                                  name='resourceName'
                                  value={currentQRorTillDetails.resourceName}
                                  handleOnChange={(value) =>
                                  (currentQRorTillDetails.resourceName =
                                    value)
                                  }
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={3}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='tillNumber'
                                  readOnly={readOnly}
                                  label='Till Number'
                                  name='tillNumber'
                                  value={currentQRorTillDetails.tillNumber}
                                  handleOnChange={(value) =>
                                    (currentQRorTillDetails.tillNumber = value)
                                  }
                                />
                              </div>
                            </Col>
                            <Col xs={12} md={6}>
                              <div className='form-group'>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='msisdn'
                                  label='MSISDN'
                                  readOnly={readOnly}
                                  name='msisdn'
                                  value={currentQRorTillDetails.msisdn}
                                  handleOnChange={(value) =>
                                    (currentQRorTillDetails.msisdn = value)
                                  }
                                />
                              </div>
                            </Col>
                          </Row>

                          {mfiNameValue != null ? (
                            mfiNameValue.label.toLowerCase().indexOf('jazz') !==
                              -1 ? (
                              <>
                                <Row>
                                  <Col xs={12} md={3}>
                                    <div className='form-group'>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='email'
                                        readOnly={readOnly}
                                        label='E-mail'
                                        name='email'
                                        value={currentQRorTillDetails.email}
                                        handleOnChange={(value) =>
                                          (currentQRorTillDetails.email = value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <div className='form-group'>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='text'
                                        readOnly={readOnly}
                                        label='Notifier 1'
                                        name='notifier1'
                                        value={currentQRorTillDetails.notifier1}
                                        handleOnChange={(value) =>
                                        (currentQRorTillDetails.notifier1 =
                                          value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <div className='form-group'>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='notifier2'
                                        readOnly={readOnly}
                                        label='Notifier 2'
                                        name='notifier2'
                                        value={currentQRorTillDetails.notifier2}
                                        handleOnChange={(value) =>
                                        (currentQRorTillDetails.notifier2 =
                                          value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <div className='form-group'>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='text'
                                        readOnly={readOnly}
                                        label='Route No'
                                        name='routeNo'
                                        value={currentQRorTillDetails.routeNo}
                                        handleOnChange={(value) =>
                                        (currentQRorTillDetails.routeNo =
                                          value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12}>
                                    <div className='form-group'>
                                      <FormikControl
                                        formik={formik}
                                        control='input'
                                        type='text'
                                        readOnly={readOnly}
                                        label='Address'
                                        name='address'
                                        value={currentQRorTillDetails.address}
                                        handleOnChange={(value) =>
                                        (currentQRorTillDetails.address =
                                          value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                        </Container>
                      </Col>
                      <Col xs={12} md={4}>
                        <Col xs={12} md={6}>
                          <div className='form-group'>
                            <div>
                              <div>Select QR/Till Image</div>
                              {(qrImage ||
                                (currentQRorTillDetails.qrImagePath &&
                                  currentQRorTillDetails.qrImagePath !==
                                  'null')) && (
                                  <>
                                    <img
                                      alt='not fount'
                                      style={{ border: '1px solid #ccc' }}
                                      width={'250px'}
                                      height={'250px'}
                                      src={
                                        currentQRorTillDetails.qrImagePath
                                          ? currentQRorTillDetails.qrImagePath
                                          : URL.createObjectURL(qrImage)
                                      }
                                    />
                                    <br />
                                    {readOnly !== true ? (
                                      <input
                                        type='button'
                                        className='btn btn-info'
                                        style={{ marginLeft: '35px' }}
                                        onClick={removeImage}
                                        value='Remove'
                                      />
                                    ) : (
                                      //   <i className='fa fa-close'></i>
                                      //   Remove
                                      // </input>
                                      <></>
                                    )}
                                  </>
                                )}
                              {!qrImage &&
                                !currentQRorTillDetails.qrImagePath && (
                                  <div>
                                    <br />
                                    <input
                                      disabled={readOnly}
                                      type='file'
                                      name='qrImage'
                                      onChange={handleQRImageOnChange}
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  {readOnly === false ? (
                    <>
                      <input
                        type='submit'
                        className='btn btn-primary'
                        value={
                          isEdit === false ? 'Add QR/Till' : 'Update QR/Till'
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <Button
                    className='btn btn-default'
                    onClick={() => setModalShow(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal
          show={qrImageModalShow}
          onHide={() => setQrImageModalShow(false)}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              QR/Till Image
              <ReactToPrint
                trigger={() => (
                  <i
                    style={{
                      marginLeft: '20px',
                      cursor: 'pointer',
                      color: '#ea5f2d',
                    }}
                    className='fa fa-print'
                  ></i>
                )}
                content={() => componentRef}
              // content={() => (
              //   <Row>
              //     <img
              //       style={{ border: '1px solid #ccc' }}
              //       alt='QR/Till image not found'
              //       src={qrSelectedImage}
              //       height='530'
              //     />
              //   </Row>
              // )}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <img
                  style={{ border: '1px solid #ccc' }}
                  alt='QR/Till image not found'
                  src={qrSelectedImage}
                  height='530'
                />
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <div style={{ display: 'none' }}>
          <QRImageprintComponent
            qrImage={qrSelectedImage}
            ref={(el) => setComponentRef(el)}
          ></QRImageprintComponent>
        </div>
        {/* //// Upload QR/Till Data Sheet */}
        <Modal
          show={dataSheetModalShow}
          onHide={() => setDataSheetModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Upload QR/Till Data Sheet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              enableReinitialize
              initialValues={dataSheetInitialValue}
              validationSchema={dataSheetValidationSchema}
              onSubmit={submitExcelSheetData}
            >
              {(formik) => (
                <Form autoComplete='off' encType='multipart/form-data'>
                  <Container>
                    <Row>
                      <Col md={{ span: 4 }} style={{ float: 'right' }}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={readOnly}
                            control='react-select'
                            // isSearchable={false}
                            // isClearable={true}
                            label='MFI'
                            // classNamePrefix='select'
                            name='dataSheetMfiName'
                            value={dataSheetMfiName}
                            options={selectedMfiNames}
                            handleOnChange={(option) => {
                              if (!option || !option.value) {
                                return
                              }
                              setDataSheetMfiName(option)
                              dataSheetInitialValue.dataSheetMfiName =
                                option.label
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={{ span: 8 }} style={{ float: 'right' }}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={readOnly}
                            control='react-select'
                            isSearchable={false}
                            isClearable={true}
                            label='Distribution Center'
                            name='distributionCenterName'
                            value={dataSheetDistCenterName}
                            classNamePrefix='select'
                            options={DistCentersList}
                            handleOnChange={(option) => {
                              setDataSheetDistCenterName(option)
                              dataSheetInitialValue.distributionCenterName =
                                option.label
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ height: '250px' }}>
                      <Col xs={12} md={12} style={{ marginLeft: '28%' }}>
                        <div
                          className='form-group'
                          style={{ marginTop: '20px', marginLeft: '20px' }}
                        >
                          <div>
                            <div>
                              Select QR/Till Data Sheet File (Only Excel file)
                            </div>
                            {dataSheetSelectedFile && (
                              <div>
                                <img
                                  style={{ border: '1px solid #ccc' }}
                                  alt='not fount'
                                  width={'100px'}
                                  height={'100px'}
                                  src={ExcelIcon}
                                />
                                <br />
                                <button
                                  className='btn btn-info'
                                  style={{
                                    marginLeft: '10px',
                                    marginTop: '10px',
                                  }}
                                  onClick={() => {
                                    setDataSheetSelectedFile(null)
                                  }}
                                >
                                  <i className='fa fa-close'></i>
                                  Remove
                                </button>
                              </div>
                            )}
                            {!dataSheetSelectedFile && (
                              <div>
                                <br />
                                <input
                                  type='file'
                                  name='dataSheetSelectedFile'
                                  onChange={(event) =>
                                    validateDataSheetFile(event)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <input
                          type='submit'
                          className='btn btn-primary'
                          style={{
                            marginLeft: '35px',
                            marginTop: '10px',
                            float: 'right',
                          }}
                          value='Upload Data Sheet'
                        />

                        <a
                          href={`${QRorTillDataTemplateForJazzWarid}`}
                          download='QRorTillDataTemplateForJazzWarid'
                          className='active'
                          style={
                            dataSheetMfiName &&
                              dataSheetMfiName.label == 'Jazz/Warid'
                              ? { padding: '0px', margin: '0px' }
                              : {
                                display: 'none',
                                padding: '0px',
                                margin: '0px',
                              }
                          }
                        >
                          Download Template
                        </a>
                        <a
                          href={`${QRorTillDataTemplateForTelenor}`}
                          download='QRorTillDataTemplateForTelenor'
                          className='active'
                          style={
                            dataSheetMfiName &&
                              dataSheetMfiName.label == 'Telenor'
                              ? { padding: '0px', margin: '0px' }
                              : {
                                display: 'none',
                                padding: '0px',
                                margin: '0px',
                              }
                          }
                        >
                          Download Template
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    distributorMainInfo: state.distributorMainInfo
      ? state.distributorMainInfo
      : [],
    distributionCenters: state.distributionCenters
      ? state.distributionCenters
      : [],
    qrOrTillDetails: state.qrOrTillDetails ? state.qrOrTillDetails : [],
    userId: state.auth.userId,
    mfiDetails: state.mfiDetails ? state.mfiDetails : [],
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    selectedDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
    QrTillList: state.QrTillList.qrTillList ? state.QrTillList.qrTillList : [],
    filterMfiList: state.QrTillList.mfiList ? state.QrTillList.mfiList : [],
    filterDistCenterList: state.QrTillList.distCenterList
      ? state.QrTillList.distCenterList
      : [],
  }
}
export default connect(mapStateToProps, {
  addQRorTillDetails,
  getQRorTillDetails,
  updateQRorTillDetails,
  deleteQRorTillDetails,
  saveExcelSheetData,
  getDropdownList,
  getMfiDetailsList,
  getQRorTillList,
  getFilterMfiList,
})(QrTillList)

class QRImageprintComponent extends React.PureComponent {
  render() {
    return (
      <Container>
        <Row>
          <img
            style={{ border: '1px solid #ccc', marginTop: '40px' }}
            alt='QR/Till image not found'
            src={this.props.qrImage}
          />
        </Row>
      </Container>
    )
  }
}
