import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
// import storage from "../../../apis/Storage";
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { GET_VIZLINKSHOPS } from '../../../Types'

export const getVizShops = (postedData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/VizShop/GetVizShopsList`, JSON.stringify(postedData))
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_VIZLINKSHOPS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      // toast.error('Error while processing the request')
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
