import React, { Component } from 'react'
import { VzBreadCrumb } from './VzBreadCrumb.js'
export class VzPageContainer extends Component {
  render() {
    return (
      <>
        <VzBreadCrumb ComponentName={this.props.title} />
        <div className='row'>
          <div className='card'>
            {/* <div className="card-header">
              <h3 className="card-title">
                <b>{this.props.title}</b>
              </h3>
            </div> */}
            <div className='card-body'>{this.props.children}</div>
          </div>
        </div>
      </>
    )
  }
}
