import {
  GET_DISTRIBUTIONCENTERS,
  ADD_DISTRIBUTIONCENTERS,
  UPDATE_DISTRIBUTIONCENTERS,
  DELETE_DISTRIBUTIONCENTERS,
  GET_DISTRIBUTIONCENTERSLIST,
  EMPTY_DISTRIBUTIONCENTERSLIST,
} from '../../../Types'

const distributionCentersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DISTRIBUTIONCENTERS:
      return [...action.payload]
    case ADD_DISTRIBUTIONCENTERS:
      return [...state, action.payload]
    case UPDATE_DISTRIBUTIONCENTERS:
      const index = state.findIndex((item) => item.id === action.payload.id) //finding index of the item
      const newState = [...state] //making a new array
      newState[index] = action.payload //changing value in the new array
      return [...newState] //reassingning todos to new array
    case DELETE_DISTRIBUTIONCENTERS:
      return state.filter((e) => e.id !== action.payload)
    case GET_DISTRIBUTIONCENTERSLIST:
      if (state !== action.payload) return [...state, ...action.payload]
      else return state
    case EMPTY_DISTRIBUTIONCENTERSLIST:
      return []
    default:
      return state
  }
}
export default distributionCentersReducer
