import { GET_TILL_ASSSIGNMENT_LIST } from '../../../Types'

const TillAssignmentFormReducer = (state = [], action) => {
  switch (action.type) {
    case GET_TILL_ASSSIGNMENT_LIST:
      return [...action.payload]
    default:
      return state
  }
}
export default TillAssignmentFormReducer
