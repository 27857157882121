import { VzPageContainer } from '../../layouts/VzPageContainer'
import React, { Component, useEffect, useState } from 'react'
import { Modal, Row, Col, Container, Button } from 'react-bootstrap'
import CustomDataTable from '../../layouts/DataTables/CustomDataTable'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import FormikControl from '../../layouts/Input/FormikControl'
import { toast } from 'react-toastify'
import Moment from 'react-moment'
import {
  addUsers,
  getUsers,
  updateUsers,
  deleteUsers,
  getUserRoles,
  getUserRolesList,
  assignRole,
  toggleUserStatus,
} from './ManageUsersAction'
import ServerSideDataTable from '../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import ReactSelect from 'react-select'
import {
  GET_RSOS_DDL,
  GET_DISTRIBUTOR_DDL,
  GET_JOBS_DISTRIBUTOR_DDL,
  GET_DISTRIBUTIONCENTER_DDL,
  GET_ADDJOB_DISTRIBUTIONCENTER_DDL,
  GET_CITIES,
} from '../../Types'
import { getDropdownList } from '../Shared/dropdownListActions'
import { getUserRoles as getRolesList } from '../Settings/Permissions/UserRoles/UserRolesActions'
import storage from '../../apis/Storage';

const ManageUsers = (props) => {
  const [permissionRolesList, setPermissionRolesList] = useState()

  const [currentUser, setCurrentUser] = useState(null)
  const [modalShow, setModalShow] = useState(false)
  const [rolemodalShow, setRoleModalShow] = useState(false)

  const [userType, setUserType] = useState(null)
  const [distributor, setDistributor] = useState(null)
  const [distributorUser, setDistributorUser] = useState(null)
  const [distributorUserList, setDistributorUserList] = useState([])
  const [distributorUserListFull, setDistributorUserListFull] = useState([])
  const [permissionRole, setPermissionRole] = useState(null)

  const [selectAll, setSelectAll] = useState(false)
  const [searchCenter, setSearchCenter] = useState('')
  const [userRole, setUserRole] = useState('')

  const [isView, setIsView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is Required'),
    userName: Yup.string().required('User Name is Required'),
    email: Yup.string()
      .email('Enter correct Email Required')
      .required('Email  is Required'),
    tempPassword: Yup.string().required('Password is Required'),
    userType: Yup.string(),
    cnic: Yup.string().required('CNIC is Required'),
    phoneNumber: Yup.string(),
    newUserRole: Yup.string(),
    userRole: Yup.string().nullable(true)
  })
  const roleValidationSchema = Yup.object().shape({
    // userRoleId: Yup.string().required('User role is required'),
  })
  const columns = [
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
    },
    {
      Header: 'Username',
      accessor: 'userName',
    },
    // {
    //   Header: 'Email',
    //   accessor: 'email',
    // },
    {
      Header: 'Module Type',
      accessor: 'userType',
    },
    {
      Header: 'Role',
      accessor: 'userRoleName',
    },
    {
      Header: 'CNIC',
      accessor: 'cnic',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    // {
    //   Header: 'Created Date',
    //   accessor: 'createdDate',
    //   Cell: (row) => (
    //     <div style={{ width: '75px' }}>
    //       <Moment format='DD/MM/YYYY'>{row.value}</Moment>
    //     </div>
    //   ),
    // },
    {
      Header: 'Is-Active',
      id: 'active',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div>
          <button
            title='Toggle User Active Status'
            onClick={() => toggleActive(original.userName)}
            className=' btn btn-sm'
            style={{ color: '#e84d15', backgroundColor: 'transparent' }}
          >
            <i
              className={
                original.isActive
                  ? 'fas fa-toggle-on fa-2x'
                  : 'fas fa-toggle-off fa-2x'
              }
            ></i>
          </button>
        </div>
      ),
    },
    {
      Header: 'Actions',
      id: 'edit',
      accessor: 'id',
      Cell: ({ row: { original } }) => (
        <div>
          <button
            title='View User Details'
            onClick={() => View(original.id)}
            className=' btn btn-sm'
          >
            <i className='fas fa-eye'></i>
          </button>
          <button
            style={{ marginLeft: '10px' }}
            title='Update User Role'
            onClick={() => Edit(original.id)}
            className=' btn btn-sm'
          >
            <i className='fas fa-edit'></i>
          </button>
          {/* &nbsp;
          <button onClick={() => Edit(row.value)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button> */}
          {/* &nbsp;
          <button onClick={() => Delete(row.value)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button> */}
        </div>
      ),
    },
  ]

  const initialValues = {
    id: '',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    userType: '',
    cnic: '',
    phoneNumber: '',
    newUserRole: '',
    userRole: '',
    distributor: '',
    distributorUser: '',
  }

  const roleInitialValues = {
    userRoleId: '',
  }

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [rolesList, setRolesList] = useState('')
  const [userRolesList, setUserRolesList] = useState('')
  const [filterRole, setFilterRole] = useState()
  const [filterUserRole, setFilterUserRole] = useState()

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      role: filterRole == '' || filterRole == null ? null : filterRole.value,
      userRole: filterUserRole == '' || filterUserRole == null ? null : filterUserRole.value
    }
    props.getUsers(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      role: filterRole == '' || filterRole == null ? null : filterRole.value,
      userRole: filterUserRole == '' || filterUserRole == null ? null : filterUserRole.value
    }
    props.getUsers(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const filterCenters = (event) => {
    setSearchCenter(event.target.value)
    let values = [...distributorUserListFull]
    values = values.filter((x) =>
      x.label.toLowerCase().includes(event.target.value.toLowerCase())
    )
    setDistributorUserList(values)
  }

  const clearDistributorSection = () => {
    setDistributorUserList([])
    setDistributorUserListFull([])
    setSelectAll(false)
    setSearchCenter('')
    setDistributorUser(null)
    setDistributor(null)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
      role: filterRole == '' || filterRole == null ? null : filterRole.value,
      userRole: filterUserRole == '' || filterUserRole == null ? null : filterUserRole.value
    }
    props.getUsers(requestData)
  }, [searchText])

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      role: filterRole == '' || filterRole == null ? null : filterRole.value,
      userRole: filterUserRole == '' || filterUserRole == null ? null : filterUserRole.value
    }
    props.getUsers(requestData)
  }, [filterRole, filterUserRole])

  useEffect(() => {
    if (props.Users && props.Users.length > 0) {
      setTotalRecords(props.Users[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
    setModalShow(false)
  }, [props.Users])

  useEffect(() => {
    setRolesList(
      props.Roles.map(function (item) {
        return {
          value: item.id,
          label: item.name,
        }
      })
    )
  }, [props.Roles])

  useEffect(() => {
    setUserRolesList(
      props.UserRolesList.map(function (item) {
        return {
          value: item.id,
          label: item.name,
        }
      })
    )
  }, [props.UserRolesList])
  

  useEffect(() => {
    setPermissionRolesList(
      props.PermissionRoles?.map(function (item) {
        return {
          value: item.id,
          label: item.name,
        }
      })
    )
  }, [props.PermissionRoles])

  useEffect(() => {
    props.getUserRoles()
    props.getUserRolesList()
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
    props.getRolesList()
  }, [])

  useEffect(() => {
    if (distributor) {
      setDistributorUserList([])
      setDistributorUserListFull([])
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${distributor.value}`,
        GET_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [distributor])

  useEffect(() => {
    let setInitialCheck = false
    if (userType?.label == 'Distributor') {
      setInitialCheck = true
    }

    if ((isView || isEdit) && currentUser != null) {
      let tempObj = currentUser
      if (tempObj.userType == 'DistributorUser') {
        if (tempObj.isAllDistCenters == true) {
          setSelectAll(true)
        } else {
          setSelectAll(false)
        }
        let values = {};
        if(!tempObj.distCenterIds){
          values = props.distCentersddlList.map((x) => {
            return {
              value: x.value,
              label: x.label,
              isChecked:  false,
            }
          })
        }
        else{
          values = props.distCentersddlList.map((x) => {
            return {
              value: x.value,
              label: x.label,
              isChecked: tempObj.distCenterIds.includes(x.value) ? true : false,
            }
          })
        }
        setDistributorUserList(values)
        setDistributorUserListFull(values)
      }
    } else {
      setDistributorUserList(
        props.distCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            isChecked: setInitialCheck,
          }
        })
      )
      setDistributorUserListFull(
        props.distCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
            isChecked: setInitialCheck,
          }
        })
      )
      setSelectAll(setInitialCheck)
    }
  }, [props.distCentersddlList, currentUser])

  const View = (id) => {
    storage.set('_saveUserData', JSON.stringify(props.Users));
    let users = JSON.parse(storage.get('_saveUserData')); 
    let tempObj = users.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }
    setUserType({ label: tempObj.userType, value: tempObj.userType })
    setUserRole({label: tempObj.userRoleName, value: tempObj.userRoleId})
    setCurrentUser(tempObj)
    setModalShow(true)
    setIsView(true)

    if (tempObj.userType == 'DistributorUser') {
      let dist = props.allDistributorList.find(
        (x) => x.value == tempObj.distributorId
      )
      setDistributor(dist)

      // if (tempObj.isAllDistCenters == true) {
      //   setSelectAll(true)
      // }
      // let values = [...distributorUserList]
      // values.forEach((x) => {
      //   if (tempObj.distCenterIds.includes(x.value)) {
      //     x.isChecked = true
      //   } else {
      //     x.isChecked = false
      //   }
      // })
      // setDistributorUserList(values)
      // setDistributorUserListFull(values)
    }
  }

  const toggleActive = (userName) => {
    props.toggleUserStatus(userName)
  }

  // const Delete = (id) => {
  //   confirmAlert({
  //     title: 'Confirm to Delete',
  //     message: 'Are you sure you want to delete this?',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => props.deleteUsers(id),
  //       },
  //       {
  //         label: 'No',
  //       },
  //     ],
  //   })
  // }

  const Edit = (id) => {
    setIsView(false)    
    storage.set('_saveUserData', JSON.stringify(props.Users));
    let users = JSON.parse(storage.get('_saveUserData')); 
    let tempObj = users.find((detail) => detail.id === id)
    tempObj.userRole = tempObj.userRoleId;
    // currentUser.userRole
    setUserType({ label: tempObj.userType, value: tempObj.userType })
    setUserRole({label: tempObj.userRoleName, value: tempObj.userRoleId})
    if (tempObj == null) {
      return
    }
    if (tempObj.userType == 'DistributorUser') {
      let dist = props.allDistributorList.find(
        (x) => x.value == tempObj.distributorId
      )
      setDistributor(dist)
    }
    setUserType({ label: tempObj.userType, value: tempObj.userType })
    setCurrentUser(tempObj)
    setModalShow(true)
    setIsEdit(true)
  }

  const handleCheckboxSelectAll = (selectedEvent) => {
    setSelectAll(selectedEvent.target.checked)
    let values = [...distributorUserList]
    values.forEach((x) => {
      x.isChecked = selectedEvent.target.checked
    })

    setDistributorUserList(values)
    setDistributorUserListFull(values)
  }

  const handleCheckboxSelectDistCenter = (selectedEvent, index) => {
    let values = [...distributorUserList]
    values.forEach((x, i) => {
      if (i == index) {
        x.isChecked = selectedEvent.target.checked
      }
    })
    setDistributorUserList(values)

    values = [...distributorUserListFull]
    values.forEach((x, i) => {
      if (i == index) {
        x.isChecked = selectedEvent.target.checked
      }
    })

    setDistributorUserListFull(values)

    if (values.filter((x) => x.isChecked == false).length > 0) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }
  }

  const OpenModelForAddUsers = () => {
    setCurrentUser({ ...initialValues, tempPassword: '' })
    setUserType(null)
    setModalShow(true)
    setIsView(false)
    setIsEdit(false)
    setDistributorUser(null)
    setDistributor(null)
    setUserRole(null)
    setDistributorUserList([])
    setDistributorUserListFull([])
  }

  const OpenModelForAssignRoles = (id) => {
    let tempObj = props.Users.find((detail) => detail.id === id)
    if (tempObj == null) {
      return
    }
    setCurrentUser(tempObj)
    setRoleModalShow(true)
    if (tempObj.userRoleId != null) {
      let role = props.PermissionRoles?.find((x) => x.id == tempObj.userRoleId)
      setPermissionRole({
        label: role?.name,
        value: tempObj?.userRoleId,
      })
      roleInitialValues.userRoleId = {
        label: role?.name,
        value: tempObj.userRoleId,
      }
    } else {
      setPermissionRole(null)
    }
  }

  const handleSubmit = async (data) => {
    let tempObj = props.Users.find(
      (detail) => detail.userName === data.userName
    )
    if (tempObj != null && !isEdit) {
      toast.error('Selected User details already exist')
      return
    }



    let newUserData = {
      id: currentUser.id,
      contactNumber : data.phoneNumber,
      userName: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.tempPassword,
      userType: userType?.label,
      cNIC: data.cnic,
      userRoleId: userRole?.value,
      phoneNumber: data.phoneNumber,
      newUserRole: data.newUserRole,
    }

    if(!newUserData.userType)
    {
      toast.error('Module Type is Required')
    }

    if (
      userType.label == 'DistributorUser' &&
      distributorUserList.filter((x) => x.isChecked == true).length < 1
    ) {
      toast.error('Select Distribution Center(s) for the distributor')
      return
    } else if (
      userType.label == 'DistributorUser' &&
      distributorUserList.filter((x) => x.isChecked == true).length > 0
    ) {
      let DistributorId = distributor.value
      newUserData = { ...newUserData, DistributorId }
      let IsAllDistCenters = false
      if (selectAll == true) {
        IsAllDistCenters = true
      }

      let centers = distributorUserListFull.filter((x) => x.isChecked == true)
      let DistCenterIds = ''

      centers.forEach((x, index) => {
        if (index == 0) {
          DistCenterIds = DistCenterIds.concat(x.value)
        } else {
          DistCenterIds = DistCenterIds.concat(',', x.value)
        }
      })
      newUserData = { ...newUserData, DistCenterIds, IsAllDistCenters }
    }

    if (userType.label == 'Distributor' && distributor == null) {
      toast.error('Select Distributior')
      return
    } else if (
      userType.label == 'Distributor' &&
      distributor != null &&
      distributor != ''
    ) {
      let DistributorId = distributor.value
      let IsAllDistCenters = true,
      newUserData = { ...newUserData, DistributorId, IsAllDistCenters }
    }

    if (!isEdit) {
      await props.addUsers(newUserData)
    } else {
      await props.updateUsers(newUserData)
    }
    await props.getUserRolesList()
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      role: filterRole == '' || filterRole == null ? null : filterRole.value,
      userRole: filterUserRole == '' || filterUserRole == null ? null : filterUserRole.value
    }
    props.getUsers(requestData)
  }

  const handleRoleSubmit = async (data) => {
    if (permissionRole == null) {
      toast.error('Pleae select user role')
      return
    }
    props.assignRole(currentUser.id, permissionRole.value, permissionRole.label)
    setRoleModalShow(false)
  }

  // const handleReset = (resetForm) => {
  //   resetForm()
  //   setCurrentUser(initialValues)
  //   setUserNameValue(null)
  // }

  return (
    <div>
      <VzPageContainer title='Manage Users'>
        <Row>
          <Col xs={12} md={12}>
            <Button
              className='form-btn pull-right'
              variant='primary'
              style={{ float: 'right' }}
              onClick={OpenModelForAddUsers}
            >
              <i className='fa fa-plus' /> Add New User
            </Button>
          </Col>
        </Row>
        <div className='row'>
          <Col md={3} style={{ float: 'left' }}>
            <div className='form-group'>
              <div className='font-weight-bold ml-2 mb-1'>Module Type</div>
              <ReactSelect
                id='role'
                name='role'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Type'
                isMulti={false}
                value={filterRole}
                isClearable={true}
                onChange={(val) => setFilterRole(val)}
                options={rolesList}
              ></ReactSelect>
            </div>
          </Col>
          <Col md={3} style={{ float: 'left' }}>
            <div className='form-group'>
              <div className='font-weight-bold ml-2 mb-1'>Select Role</div>
              <ReactSelect
                id='userRole'
                name='userRole'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Role'
                isMulti={false}
                value={filterUserRole}
                isClearable={true}
                onChange={(val) => setFilterUserRole(val)}
                options={userRolesList}
              ></ReactSelect>
            </div>
          </Col>
        </div>
        <Row>
          <Col xs={12} md={12}>
            {/* <CustomDataTable
              data={props.Users ?? []}
              columns={columns}
              getTrProps={() => null}
            /> */}
            <ServerSideDataTable
              data={props.Users ?? []}
              columns={columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>

        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    User Record
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='firstName'
                            label='First Name'
                            name='firstName'
                            handleOnChange={(e) => (currentUser.firstName = e)}
                            value={currentUser.firstName}
                          />
                        </div>
                      </Col>
                      <Col xs={6} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='lastName'
                            label='Last Name'
                            name='lastName'
                            value={currentUser.lastName}
                            handleOnChange={(e) => (currentUser.lastName = e)}
                          />
                        </div>
                      </Col>
                      
                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView || isEdit}
                            control='input'
                            type='cnic'
                            label='CNIC'
                            name='cnic'
                            value={currentUser.cnic}
                            handleOnChange={(e) => (currentUser.cnic = e)}
                          />
                        </div>
                      </Col>

                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='phoneNumber'
                            label='Phone #'
                            name='phoneNumber'
                            value={currentUser.phoneNumber}
                            handleOnChange={(e) => (currentUser.phoneNumber = e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView || isEdit}
                            control='input'
                            type='userName'
                            label='User Name'
                            name='userName'
                            value={currentUser.userName}
                            autoComplete="off"
                            handleOnChange={(e) =>  (currentUser.userName = e)}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView || isEdit}
                            control='input'
                            type={isView ? 'text' : 'password'}
                            label='Password'
                            name='tempPassword'
                            value={currentUser.tempPassword}
                            autoComplete="new-password"
                            handleOnChange={(e) =>
                              (currentUser.tempPassword = e)
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            readOnly={isView}
                            control='input'
                            type='email'
                            label='Email'
                            name='email'
                            value={currentUser.email}
                            handleOnChange={(e) => (currentUser.email = e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <div className='form-group'>
                          <FormikControl
                            formik={formik}
                            control='react-select'
                            readOnly={isView}
                            type='userType'
                            label='Module Type'
                            name='userType'
                            value={userType}
                            options={rolesList}
                            handleOnChange={(value) => {
                              currentUser.userType = value.label
                              setUserType(value)
                              clearDistributorSection()
                            }}
                          />
                        </div>
                      </Col>
                      {userType &&
                      (userType.label == 'Distributor' ||
                        userType.label == 'DistributorUser') ? (
                      <>    
                        <Col xs={12} md={4}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              type='userRole'
                              label='User Role'
                              name='userRole'
                              value={userRole}
                              isDisabled={currentUser.newUserRole || isView}
                              options={userRolesList}
                              isClearable={true}
                              handleOnChange={(value) => {
                                if(value == null)
                                {
                                  currentUser.userRole = null
                                  setUserRole(null)
                                }
                                else{
                                  currentUser.userRole = value.label
                                  setUserRole(value)
                                }
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={4}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              readOnly={isView || isEdit}
                              control='input'
                              type='newUserRole'
                              label='Custom Role'
                              name='newUserRole'
                              disabled={currentUser.userRole || isView}
                              value={currentUser.newUserRole}
                              handleOnChange={(e) => (currentUser.newUserRole = e)}
                            />
                          </div>
                        </Col>
                      </>
                       ) : (
                        <></>
                      )}
                    </Row>
                    {userType &&
                      (userType.label == 'Distributor' ||
                        userType.label == 'DistributorUser') ? (
                      <Row>
                        <Col xs={12} md={6}>
                          <div className='form-group'>
                            <FormikControl
                              formik={formik}
                              control='react-select'
                              readOnly={isView}
                              type='distributor'
                              label='Distributor'
                              name='distributor'
                              value={distributor}
                              options={props.allDistributorList}
                              handleOnChange={(value) => {
                                currentUser.distributor = value
                                setDistributor(value)
                                setDistributorUser(null)
                                setSearchCenter('')
                              }}
                            />
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className='form-group '>
                            <label className='mb-0'>Dist Centers</label>
                            <input
                              value={searchCenter}
                              type='text'
                              className='form-control mb-3'
                              onChange={(e) => filterCenters(e)}
                              readOnly={
                                userType.label == 'Distributor' || isView
                              }
                            />
                            {distributorUserList?.length > 0 ? (
                              <div className='ms-2 border-bottom mb-2 '>
                                <input
                                  disabled={
                                    userType.label == 'Distributor' || isView
                                  }
                                  value={selectAll}
                                  checked={selectAll}
                                  onChange={(event) =>
                                    handleCheckboxSelectAll(event)
                                  }
                                  type='checkbox'
                                  style={{ transform: 'scale(1.3)' }}
                                />
                                <span className='ms-2'>Select All</span>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div
                              className='overflow-auto '
                              style={{ maxHeight: '100px' }}
                            >
                              {distributorUserList.map((item, index) => (
                                <div className='ms-2 my-2 ' key={index}>
                                  <input
                                    disabled={
                                      userType.label == 'Distributor' || isView
                                    }
                                    checked={item.isChecked}
                                    value={item.value}
                                    type='checkbox'
                                    onChange={(event) =>
                                      handleCheckboxSelectDistCenter(
                                        event,
                                        index
                                      )
                                    }
                                    style={{ transform: 'scale(1.3)' }}
                                  />
                                  <span className='ms-2'>{item.label}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  {isView ? null : (
                    <>
                      <input
                        type='submit'
                        className='btn btn-primary'
                        value='Save'
                      />
                      {/* <button
                  className="btn btn-default"
                  onClick={handleReset.bind(null, formik.resetForm)}
                  type="button"
                >
                  Reset
                </button> */}
                      <Button
                        className='btn btn-default'
                        onClick={() => setModalShow(false)}
                      >
                        Close
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal
          show={rolemodalShow}
          onHide={() => setRoleModalShow(false)}
          backdrop='static'
          keyboard={false}
          size='lg'
        >
          <Formik
            enableReinitialize
            initialValues={roleInitialValues}
            validationSchema={roleValidationSchema}
            onSubmit={handleRoleSubmit}
          >
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    User Role
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col xs={6} md={3}>
                        <Col md={6}>Username</Col>
                        <Col md={6} style={{ marginTop: '10px' }}>
                          {currentUser.userName}
                        </Col>
                      </Col>
                      <Col xs={6} md={3}>
                        <Col md={6}>CNIC</Col>
                        <Col md={6} style={{ marginTop: '10px' }}>
                          {currentUser.cnic}
                        </Col>
                      </Col>
                      <Col xs={12} md={6}>
                        <Col md={12}>Role</Col>
                        <Col md={12} style={{ marginTop: '5px' }}>
                          <div className='form-group'>
                            <ReactSelect
                              id='userRoleId'
                              name='userRoleId'
                              className='basic-single'
                              classNamePrefix='select'
                              placeholder='Select Role'
                              isMulti={false}
                              value={permissionRole}
                              isClearable={true}
                              onChange={(val) => setPermissionRole(val)}
                              options={permissionRolesList}
                            ></ReactSelect>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <>
                    <input
                      type='submit'
                      className='btn btn-primary'
                      value='Save'
                    />

                    <Button
                      className='btn btn-default'
                      onClick={() => setRoleModalShow(false)}
                    >
                      Close
                    </Button>
                  </>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    Users: state.Users ? state.Users : [],
    Roles: state.ddlList?.userRoles ? state.ddlList?.userRoles : [],
    UserRolesList: state.ddlList?.UserRolesList ? state.ddlList?.UserRolesList : [],
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    distCentersddlList: state.ddlList.selectedDistCentersList
      ? state.ddlList.selectedDistCentersList
      : [],
    PermissionRoles: state.UserRoles,
  }
}
export default connect(mapStateToProps, {
  addUsers,
  getUsers,
  updateUsers,
  deleteUsers,
  getUserRoles,
  getUserRolesList,
  getDropdownList,
  getRolesList,
  assignRole,
  toggleUserStatus,
})(ManageUsers)
