import { GET_MFIS } from "../../Types";
import _ from "lodash";

const mfiReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MFIS:
      return [...action.payload];
    default:
      return state;
  }
};
export default mfiReducer;
