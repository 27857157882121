import { GET_DELIVERY_USER_LIST } from '../../../Types'

const initialState = {
  totalRecords: 0,
  users: [],
}

const DeliverymanUserRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVERY_USER_LIST:
      return action.payload
    // case ADD_QRTILLDETAILS:
    //   return [...state, action.payload]
    // case UPDATE_QRTILLDETAILS:
    //   const index = state.findIndex((item) => item.id === action.payload.id) //finding index of the item
    //   const newState = [...state] //making a new array
    //   newState[index] = action.payload //changing value in the new array
    //   return [...newState] //reassingning todos to new array
    // case DELETE_QRTILLDETAILS:
    //   return state.filter((e) => e.id !== action.payload)
    // case EMPTY_QRTILLDETAILS:
    //   return []
    // case UPLOAD_QRTILLDETAILS:
    //   return [...action.payload]
    default:
      return state
  }
}
export default DeliverymanUserRegistrationReducer
