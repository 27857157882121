import {
  GET_MANAGE_ORDER_DETAILS,
  DELETE_MANAGE_ORDER_DETAILS,
} from '../../../../Types'
import _ from 'lodash'

const ManageOrderDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MANAGE_ORDER_DETAILS:
      return [...action.payload]
    case DELETE_MANAGE_ORDER_DETAILS:
      return state.filter((e) => e.id !== action.payload)
    default:
      return state
  }
}
export default ManageOrderDetailsReducer
