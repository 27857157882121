import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import { toast } from 'react-toastify'
// import storage from "../../../apis/Storage";
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  GET_JOB_DETAILS,
  UPDATE_JOB_DETAILS,
  SEARCH_VIZLINK_SHOPS,
  EMPTY_VIZLINK_SHOPS,
  LINK_DIST_SHOP_TO_VIZ_SHOP,
  UNLINK_DIST_SHOP_TO_VIZ_SHOP,
} from '../../../../Types'

export const getRsoJobDetails =
  (jobId, rsoId, pageSize, pageNo, searchText, shopType, paymentMode) =>
  async (dispatch) => {
    dispatch(showLoading())
    await axios
      .post(`api/DistShopInductionJob/GetJobDetailsList`, {
        jobId: jobId,
        rsoId: rsoId,
        pageSize: pageSize,
        pageNo: pageNo,
        searchText: searchText,
        shopType: shopType,
        paymentMode: paymentMode,
      })
      .then(({ data }) => {
        dispatch({
          type: GET_JOB_DETAILS,
          payload: data,
        })
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }

export const updateRsoShopStatus = (jobId, status) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(
      `api/DistShopInductionJob/UpdateDistributorShopInductionStatus/${jobId}/${status}`
    )
    .then(({ data }) => {
      dispatch({
        type: UPDATE_JOB_DETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const searchVizShops = (shopId, searchText) => async (dispatch) => {
  dispatch(showLoading())
  const searchTextString = searchText ? `${searchText}` : `null`
  await axios
    .get(`api/VizShop/SearchVizShops/${shopId}/${searchTextString}`)
    .then(({ data }) => {
      dispatch({
        type: EMPTY_VIZLINK_SHOPS,
        payload: data,
      })
      dispatch({
        type: SEARCH_VIZLINK_SHOPS,
        payload: data,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateShopsRsoDetail = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  const formData = new FormData() //// 03087661758
  // formData.append('DistributorId', formValues.distributorId)
  formData.append('DistCenterId', formValues.DistCenterId)
  formData.append('DataFile', formValues.DataFile)
  formData.append('JobId', formValues.JobId)
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  }
  await axios
    .post('api/DistShopInductionJob/UpdateShopsRsoDetail', formData, config)
    .then(({ data }) => {
      dispatch({
        type: GET_JOB_DETAILS,
        payload: data.response,
      })
      dispatch(hideLoading())
      toast.success('Job uploaded successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const linkDistributorShopToVizLinkShop =
  (shopId, vizShopId) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .get(
        `api/DistShopInductionJob/LinkDistributorShopToVizLinkShop/${shopId}/${vizShopId}`
      )
      .then(({ data }) => {
        dispatch({
          type: LINK_DIST_SHOP_TO_VIZ_SHOP,
          payload: data.response,
        })
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }
export const unLinkDistributorShopToVizLinkShop =
  (shopId) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .get(
        `api/DistShopInductionJob/UnLinkDistributorShopToVizLinkShop/${shopId}`
      )
      .then(({ data }) => {
        dispatch({
          type: UNLINK_DIST_SHOP_TO_VIZ_SHOP,
          payload: data.response,
        })
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }
