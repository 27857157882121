import axios from '../../apis/Api'
import history, { logout } from '../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  ADD_USERS,
  GET_USERS,
  DELETE_USERS,
  UPDATE_USERS,
  GET_USER_ROLES,
  UPDATE_USER_ROLE,
  UPDATE_USER_ACTIVE_STATUS,
  GET_USER_ROLES_LIST
} from '../../Types'

export const addUsers = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/Authenticate/CreateUser', formValues)
    .then(({ data }) => {
      if (data.response)
        dispatch({
          type: ADD_USERS,
          payload: data.response,
        })
      dispatch(hideLoading())
      toast.success('User created successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}

export const getUsers = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Authenticate/GetUsersList`, requestData)
    .then(({ data }) => {
      if (data && data.response)
        dispatch({
          type: GET_USERS,
          payload: data.response,
        })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateUsers = (formValues) => async (dispatch) => {
  var response = await axios.put(
    `api/Authenticate/UpdateUsers`,
    formValues
  )
  dispatch({
    type: UPDATE_USERS,
    payload: JSON.stringify(response.data),
  })
  history.push('/')
}

export const deleteUsers = (id) => async (dispatch) => {
  await axios.delete(`api/Authenticate/DeleteUsers/${id}`)
  dispatch({ type: DELETE_USERS, payload: id })
}

export const toggleUserStatus = (username) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/Authenticate/ToggleUserActiveStatus/${username}`)
    .then(({ data }) => {
      if (data)
        dispatch({
          type: UPDATE_USER_ACTIVE_STATUS,
          payload: { username: username, status: data.response },
        })
      dispatch(hideLoading())
      toast.success('User status updated successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getUserRoles = () => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/Authenticate/GetUserRoles`)
    .then(({ data }) => {
      if (data && data.length > 0)
        dispatch({
          type: GET_USER_ROLES,
          payload: data,
        })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getUserRolesList = () => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/UserRoles`)
    .then(({ data }) => {
      if (data && data?.response?.length > 0)
        dispatch({
          type: GET_USER_ROLES_LIST,
          payload: data.response,
        })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const assignRole = (userId, roleId, roleName) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/Authenticate/AssignRole?userId=${userId}&&roleId=${roleId}`)
    .then(({ data }) => {
      if (data)
        dispatch({
          type: UPDATE_USER_ROLE,
          payload: { userId: userId, roleId: roleId, roleName: roleName },
        })
      dispatch(hideLoading())
      toast.success('User role updated successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error: Server is not responding')
        }
      }
    })
}
