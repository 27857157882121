import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import { connect } from 'react-redux'
import { getSectors } from './SectorsActions'
import { useEffect, useState, useRef } from 'react'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import GoogleMapReact from 'google-map-react'

import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { isPermittedPage } from '../../../apis/Utility'
import storage from '../../../apis/Storage'

const Marker = (props) => {
  const { color, name, id } = props
  return (
    <div
      className='marker'
      style={{ backgroundColor: color, cursor: 'pointer' }}
      title={name}
    />
  )
}

const Legend = () => {
  return (
    <div className='legend px-3'>
      <div className='legend-item'>
        <div className='legend-icon legend-sector'></div>
        <div className='legend-label'>Sector</div>
      </div>
      <div className='legend-item'>
        <div className='legend-icon legend-shop'></div>
        <div className='legend-label'>Shop</div>
      </div>
      <div className='legend-item'>
        <div className='legend-icon legend-agent'></div>
        <div className='legend-label'>Agent</div>
      </div>
    </div>
  )
}

const Map = (props) => {
  const [center, setCenter] = useState({
    lat: parseFloat(props.sector.poligonPoint1.split(',')[1]),
    lng: parseFloat(props.sector.poligonPoint1.split(',')[0]),
  })
  const [zoom, setZoom] = useState(14.2)

  const polygonCoords = [
    {
      lat: props.sector.poligonPoint1.split(',')[1],
      lng: props.sector.poligonPoint1.split(',')[0],
    },
    {
      lat: props.sector.poligonPoint2.split(',')[1],
      lng: props.sector.poligonPoint2.split(',')[0],
    },
    {
      lat: props.sector.poligonPoint3.split(',')[1],
      lng: props.sector.poligonPoint3.split(',')[0],
    },
    {
      lat: props.sector.poligonPoint4.split(',')[1],
      lng: props.sector.poligonPoint4.split(',')[0],
    },
    {
      lat: props.sector.poligonPoint5.split(',')[1],
      lng: props.sector.poligonPoint5.split(',')[0],
    },
  ]

  const polygonCoords2 = [
    { lat: 31.57, lng: 74.30416667 },
    { lat: 31.584999999999997, lng: 74.30416667 },
    { lat: 31.584999999999997, lng: 74.31916667 },
    { lat: 31.57, lng: 74.31916667 },
    { lat: 31.57, lng: 74.30416667 },
  ]

  const createPolygon = (map, polygonCoords) => {
    const polygon = new window.google.maps.Polygon({
      paths: polygonCoords,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
    })
    polygon.setMap(map)
    return polygon
  }

  const handleApiLoaded = (map, maps) => {
    setCenter({
      lat: parseFloat(props.sector.poligonPoint1.split(',')[1]),
      lng: parseFloat(props.sector.poligonPoint1.split(',')[0]),
    })
    let polygon = [
      {
        lat: parseFloat(props.sector.poligonPoint1.split(',')[1]),
        lng: parseFloat(props.sector.poligonPoint1.split(',')[0]),
      },
      {
        lat: parseFloat(props.sector.poligonPoint2.split(',')[1]),
        lng: parseFloat(props.sector.poligonPoint2.split(',')[0]),
      },
      {
        lat: parseFloat(props.sector.poligonPoint3.split(',')[1]),
        lng: parseFloat(props.sector.poligonPoint3.split(',')[0]),
      },
      {
        lat: parseFloat(props.sector.poligonPoint4.split(',')[1]),
        lng: parseFloat(props.sector.poligonPoint4.split(',')[0]),
      },
      {
        lat: parseFloat(props.sector.poligonPoint5.split(',')[1]),
        lng: parseFloat(props.sector.poligonPoint5.split(',')[0]),
      },
    ]
    createPolygon(map, polygon)
    // createPolygon(map, polygonCoords2)
  }

  useEffect(() => {
  }, [props.sector])

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyDqs07ua04jy-Zz_nYm6GMvOo6WgKZrWNI' }}
        defaultCenter={center}
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {props.shops.map((item, index) => (
          <Marker
            lat={item.lat}
            lng={item.long}
            name={item.vizShopCode + ' : ' + item.vizShopName}
            color='orange'
          />
        ))}

        {props.agents.map((item, index) => (
          <Marker
            lat={item.lat}
            lng={item.long}
            name={item.vizAgentCode + ' : ' + item.agentBusinessName}
            color='blue'
          />
        ))}
      </GoogleMapReact>
      <Legend />
    </div>
  )
}

const Sectors = (props) => {
  const userRights = JSON.parse(storage.get('_userRights'))
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [sectorsList, setSectorsList] = useState([])
  const [viewMapModalShow, setViewMapModalShow] = useState(false)

  const [agentsList, setAgentsList] = useState([])
  const [shopsList, setShopsList] = useState([])

  const [selectedSector, setSelectedSector] = useState(null)

  const columns = [
    {
      Header: 'Sector No.',
      accessor: 'sectorNumber',
      sortType: 'basic',
    },
    // {
    //   Header: 'Point 1',
    //   accessor: 'poligonPoint1',
    // },
    // {
    //   Header: 'Point 2',
    //   accessor: 'poligonPoint2',
    // },
    // {
    //   Header: 'Point 3',
    //   accessor: 'poligonPoint3',
    // },
    // {
    //   Header: 'Point 4',
    //   accessor: 'poligonPoint4',
    // },
    {
      Header: 'Locality',
      accessor: 'locality',
    },
    {
      Header: 'subLocality',
      accessor: 'subLocality',
    },
    {
      Header: 'Total Shops',
      accessor: 'shopsCount',
    },
    {
      Header: 'Total Agents',
      accessor: 'agentsCount',
    },
    {
      Header: 'Map',
      accessor: 'id',
      Cell: (row) => (
        isPermittedPage('View Details', props.actions, props.userRole) &&
        <div style={{ width: '106px' }}>
          <button
            onClick={() => viewMapDetails(row.value)}
            className=' btn btn-sm'
          >
            <i className='fas fa-eye'></i>
          </button>
        </div>

      ),
    },
  ]

  const viewMapDetails = async (id) => {
    setSelectedSector(sectorsList.filter((x) => x.id == id)[0])
    setViewMapModalShow(true)
    var distributorId = '';
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      distributorId = userRights.distributorId;
    }
    await axios
      .get(`api/Sector/GetShopsAndAgents/${id}/${distributorId}`)
      .then(({ data }) => {
        setAgentsList(data.agentsCount)
        setShopsList(data.shopsCount)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            history.push('/login')
            history.go('/login')
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        }
      })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData.distributorId = userRights.distributorId;
    }
    else{
      requestData.distributorId = '';
    }
    props.getSectors(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData.distributorId = userRights.distributorId;
    }
    else{
      requestData.distributorId = '';
    }
    props.getSectors(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
    }
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      requestData.distributorId = userRights.distributorId;
    }
    props.getSectors(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.sectors) {
      setSectorsList(props.sectors.sectorsList)
      setTotalRecords(props.sectors.totalRecords)
    }
  }, [props.sectors])

  return (
    <div>
      <VzPageContainer title='Sectors List'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Sectors List</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ float: 'right' }}>
            {/* <CustomDataTable
              data={sectorsList}
              columns={columns}
              getTrProps={() => null}
            /> */}
            <ServerSideDataTable
              data={sectorsList}
              columns={columns}
              getTrProps={(event, data) => { }}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
        <Modal
          show={viewMapModalShow}
          onHide={() => {
            setViewMapModalShow(false)
          }}
          backdrop='false'
          keyboard={false}
          size='xl'
          animation={false}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Map View
            </Modal.Title>
          </Modal.Header> */}
          {/* <Modal.Body> */}
          {/* <Container> */}
          <Row>
            <Col xs={12}>
              <Map
                sector={selectedSector}
                agents={agentsList}
                shops={shopsList}
              ></Map>
            </Col>
          </Row>
          {/* </Container> */}
          {/* </Modal.Body> */}
        </Modal>
      </VzPageContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sectors: state.Sectors,
  }
}
export default connect(mapStateToProps, { getSectors })(Sectors)
