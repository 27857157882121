import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export class VzBreadCrumb extends Component {
  render() {
    return (
      <section className='content-header'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-6'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/'>Dashboard</Link>
                </li>
                {this.props.ComponentName ? (
                  <>
                    <li className='breadcrumb-item active'>
                      {this.props.ComponentName}
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ol>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
