import {
  GET_DATEWISE_SHOPS_SUMMARY_REPORT
} from '../../../../Types'

const initialState = []

const DateWiseShopSummaryReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATEWISE_SHOPS_SUMMARY_REPORT:
      return action.payload
    default:
      return state
  }
}
export default DateWiseShopSummaryReportReducer