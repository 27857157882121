import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import storage from '../../../../apis/Storage'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  GET_DATEWISE_SHOPS_SUMMARY_REPORT
} from '../../../../Types'
import fileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export const getDateWiseShopsSummaryReport = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Reports/GetDateWiseShopsSummary`, requestData)
    .then(({ data }) => {
      console.log('response from API ::', data)
      if (data && data?.response)
        dispatch({
          type: GET_DATEWISE_SHOPS_SUMMARY_REPORT,
          payload: data.response,
        })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}


export const downloadJob = (requestData) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(`api/Reports/GetDateWiseShopsSummary`, requestData)
    .then(({ data }) => {
      const outputFilename = `ACTIVE_SHOPS_SUMMARY_Report_${Date.now()}`
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const exportToCSV = (csvData, jobNumber, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData)
        if (ws) {
          var range = XLSX.utils.decode_range(ws['!ref'])
          for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1' // <-- first row, column number C
            if (!ws[address]) continue
            ws[address].v =
              ws[address].v == 'date'
                ? 'Date'
                : ws[address].v == 'totalShops'
                  ? 'Total Shops'
                  : ws[address].v == 'satus'
                    ? 'Pulse Status'
                    : ws[address].v
          }
        }
        // var sheetName = `Job_Number_${jobNumber}`
        const wb = {
          Sheets: { data: ws },
          SheetNames: [`data`],
        }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const fileData = new Blob([excelBuffer], { type: fileType })
        fileSaver.saveAs(fileData, fileName + fileExtension)
      }
      exportToCSV(data?.response?.data, '', outputFilename)
      dispatch(hideLoading())
    })

    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
