import { GET_SECTORS } from '../../../Types'
// import _ from 'lodash'

const initialState = {
  sectorsList: [],
  totalRecords: 0,
}

const SectorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTORS:
      return {
        sectorsList: action.payload.sectors,
        totalRecords: action.payload.totalRecords,
      }
    default:
      return state
  }
}
export default SectorsReducer
