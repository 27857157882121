import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { ADD_Notification_Schedular, DEL_Notification_Schedular, GET_Notification_Schedular, UPDATE_Notification_Schedular } from '../../../Types'

export const addNotificationScheduler = (formValue) => async (dispatch) =>{
    dispatch(showLoading());
    await axios
    .post('api/NotificationScheduler/InsertNotificationScheduler', formValue)
    .then(({ data }) => {
        if (data.response)
          dispatch({
            type: ADD_Notification_Schedular,
            payload: data.response,
          })
        dispatch(hideLoading())
        toast.success('Created successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
}

export const updateNotificationScheduler = (formValue) => async (dispatch) =>{
    dispatch(showLoading());
    await axios
    .post('api/NotificationScheduler/UpdateNotificationScheduler', formValue)
    .then(({ data }) => {
        if (data.response)
          dispatch({
            type: UPDATE_Notification_Schedular,
            payload: data.response,
          })
        dispatch(hideLoading())
        toast.success('Created successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
}

export const delNotificationScheduler = (formValue) => async (dispatch) =>{
    dispatch(showLoading());
    await axios
    .post('api/NotificationScheduler/DeleteNotificationScheduler', formValue)
    .then(({ data }) => {
        if (data.response)
          dispatch({
            type: DEL_Notification_Schedular,
            payload: data.response,
          })
        dispatch(hideLoading())
        toast.success('Deleted successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
}


export const getNotificationScheduler = (requestData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
      .post(`api/NotificationScheduler/GetNotificationSchedulers`, requestData)
      .then(({ data }) => {
        if (data && data.response)
          dispatch({
            type: GET_Notification_Schedular,
            payload: data.response,
          })
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        }
      })
  }