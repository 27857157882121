import {
  ADD_USER_ROLE_PERMISSIONS,
  DELETE_USER_ROLE_PERMISSIONS,
  GET_USER_ROLE_PERMISSIONS,
} from '../../../../Types'
const initialState = [
  {
    Id: '1',
    pageName: 'Distributor',
    isParent: true,
    pageActions: [],
    parentId: null,
  },
  {
    pageName: 'Complete Distributor',
    isParent: true,
    pageActions: [
      {
        id: '11',
        actionName: 'View',
        isChecked: true,
      },
      {
        id: '12',
        actionName: 'Update',
        isChecked: false,
      },
      {
        id: '13',
        actionName: 'Delete',
        isChecked: false,
      },
    ],
    parentId: 1,
  },
]

const UserRolePermissionReducer = (state = [], action) => {
  switch (action.type) {
    case GET_USER_ROLE_PERMISSIONS:
      return action.payload
    case ADD_USER_ROLE_PERMISSIONS:
      return [...state, action.payload]
    case DELETE_USER_ROLE_PERMISSIONS:
      return state.filter((e) => e.id !== action.payload)
    default:
      return state
  }
}
export default UserRolePermissionReducer
