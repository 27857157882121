import React from 'react'
import {
  Modal,
  Row,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap'
import _ from 'lodash'

class DistributionDetailsView extends React.Component {
  // const { id } = useParams();
  constructor(props) {
    super(props)
    this.state = {
      mainInfoModal: false,
      mfiDetailsModal: false,
      qrTillDetailsModal: false,
      mfiDetails: {},
      qrTillDetails: {},
    }
  }

  ViewMfiDetails = (mfiDetail) => {
    this.setState({ mfiDetails: mfiDetail })
    this.setState({ mfiDetailsModal: true })
  }

  ViewQRorTillDetails = (qrTillData) => {
    this.setState({ qrTillDetails: qrTillData })
    this.setState({ qrTillDetailsModal: true })
  }
  render() {
    return (
      <div>
        <Container>
          {/* ///// Distributor Main Information */}
          <Row>
            <Col xs={12} md={12}>
              <div className='card-header' style={{ marginTop: '20px' }}>
                <h4>Main Information:</h4>
              </div>

              <div className='card-body p-0 m-0'>
                <table className='table table-sm'>
                  <thead style={{ color: '#99A0B5' }}>
                    <tr>
                      <th className='border-0'>Distributor Name</th>
                      <th className='border-0'>Address</th>
                      <th className='border-0'>City</th>
                      <th className='border-0'>Head Office Contact</th>
                      <th className='border-0'>NTN</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.distributorMainInfo?.id ? (
                      <tr>
                        <td>
                          {this.props.distributorMainInfo?.distributorName}
                        </td>
                        <td>
                          {this.props.distributorMainInfo?.headOfficeAddress}
                        </td>
                        <td>{this.props.distributorMainInfo?.city}</td>
                        <td>
                          {this.props.distributorMainInfo?.headOfficeContacts}
                        </td>
                        <td>{this.props.distributorMainInfo?.ntnOrGst}</td>
                        <td>
                          <i
                            onClick={() =>
                              this.setState({ mainInfoModal: true })
                            }
                            className='fas fa-info-circle'
                            style={{ cursor: 'pointer', float: 'right' }}
                          ></i>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={6} style={{ textAlign: 'center' }}>
                          Main Informations record not found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>

          {/* ///// Distributor Principals */}
          <Row style={{ marginTop: '20px' }}>
            <Col xs={12} md={4}>
              <div className='card-header'>
                <h4>Principals:</h4>
              </div>
              {this.props.distributorPrincipals &&
              this.props.distributorPrincipals?.length > 0 ? (
                <>
                  <div className='card-body p-0 m-0 '>
                    <ListGroup as='ul' className='list-group-flush'>
                      {this.props.distributorPrincipals.map((item, j) => (
                        <ListGroup.Item as='li'>
                          {item.principalName}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>
                </>
              ) : (
                <div className='card-body p-0'>
                  <ListGroup className='list-group-flush'>
                    <ListGroupItem>No Principal added yet</ListGroupItem>
                  </ListGroup>
                </div>
              )}
            </Col>
          </Row>

          {/* ///// Bank Details */}
          <Row style={{ marginTop: '30px' }}>
            <Col xs={12} md={12}>
              <div className='card-header'>
                <h4>Bank Details:</h4>
              </div>
              <div className='card-body p-0'>
                <table className='table table-sm'>
                  <thead style={{ color: '#99A0B5' }}>
                    <tr>
                      <th className='border-0'>Bank Name</th>
                      <th className='border-0'>Branch Code</th>
                      <th className='border-0'>Account Number</th>
                      <th className='border-0'>Account Title</th>
                      <th className='border-0'>Swift Code</th>
                      <th className='border-0'>IBAN</th>
                      <th className='border-0'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.bankDetails &&
                    this.props.bankDetails.length > 0 ? (
                      this.props.bankDetails.map((bankDetail, j) => (
                        <tr>
                          <td style={{ wordBreak: 'break-word' }}>
                            {bankDetail.bankName}
                          </td>
                          <td style={{ wordBreak: 'break-word' }}>
                            {bankDetail.branchCode}
                          </td>
                          <td style={{ wordBreak: 'break-word' }}>
                            {bankDetail.accountNumber}
                          </td>
                          <td style={{ wordBreak: 'break-word' }}>
                            {bankDetail.accountTitle}
                          </td>
                          <td style={{ wordBreak: 'break-word' }}>
                            {bankDetail.swiftCode}
                          </td>
                          <td style={{ wordBreak: 'break-word' }}>
                            {bankDetail.iban}
                          </td>
                          <td>
                            {/* <i
                              onClick={() =>
                                alert('All detail is already listed in table')
                              }
                              title='All detail is already listed in table'
                              className='fas fa-info-circle'
                              style={{ cursor: 'pointer', float: 'right' }}
                            ></i> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} style={{ textAlign: 'center' }}>
                          No Bank Details added yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>

          {/* ///// Distributor Centers */}
          <Row style={{ marginTop: '20px' }}>
            <Col xs={12} md={12}>
              <div className='card-header'>
                <h4>Distribution Centers:</h4>
              </div>
              <div className='card-body p-0'>
                <table className='table table-sm'>
                  <thead style={{ color: '#99A0B5' }}>
                    <tr>
                      <th className='border-0'>Distribution Center</th>
                      <th className='border-0'>Principal</th>
                      <th className='border-0'>Shops Served</th>
                      <th className='border-0'>E-mail</th>
                      <th className='border-0'>Contact No.</th>
                      <th className='border-0'>Address</th>
                      <th className='border-0'>City</th>
                      <th className='border-0'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.distributionCenters &&
                    this.props.distributionCenters.length > 0 ? (
                      this.props.distributionCenters.map(
                        (distributionCenter, j) => (
                          <tr>
                            <td>{distributionCenter.distCenterName}</td>
                            <td>{distributionCenter.principalName}</td>
                            <td>{distributionCenter.numberofShops}</td>
                            <td>{distributionCenter.distCenterEmails}</td>
                            <td>
                              {distributionCenter.distCenterContactNumbers}
                            </td>
                            <td>{distributionCenter.distCenterAddress}</td>
                            <td>{distributionCenter.city}</td>
                            <td>
                              {/* <i
                                onClick={() =>
                                  alert('All detail is already listed in table')
                                }
                                title='All detail is already listed in table'
                                className='fas fa-info-circle'
                                style={{ cursor: 'pointer', float: 'right' }}
                              ></i> */}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={6} style={{ textAlign: 'center' }}>
                          No Distribution Center added yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>

          {/* ///// MFI Details */}
          <Row style={{ marginTop: '20px' }}>
            <Col xs={12} md={12}>
              <div className='card-header'>
                <h4>MFI Details:</h4>
              </div>
              <div className='card-body p-0'>
                <table className='table table-sm'>
                  <thead style={{ color: '#99A0B5' }}>
                    <tr>
                      <th className='border-0'>MFI</th>
                      <th className='border-0'>Master No.</th>
                      <th className='border-0'>CBA#</th>
                      <th className='border-0'>Franchise Code</th>
                      <th className='border-0'>Corresponding E-mail</th>
                      <th className='border-0'>Branch Name</th>
                      <th className='border-0'>Master Till No.</th>
                      <th className='border-0'>Distribution Centers</th>
                      <th className='border-0'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.mfiDetails &&
                    this.props.mfiDetails.length > 0 ? (
                      this.props.mfiDetails.map((mfiDetail, j) => (
                        <tr>
                          <td>{mfiDetail.mfiName}</td>
                          <td>{mfiDetail.masterNumber}</td>
                          <td>{mfiDetail.cbaNo}</td>
                          <td>{mfiDetail.sellerCode}</td>
                          <td>{mfiDetail.correspondingEmail}</td>
                          <td>{mfiDetail.branchName}</td>
                          <td>{mfiDetail.masterTillNo}</td>
                          <td>{mfiDetail.distributionCenters}</td>
                          <td>
                            <i
                              onClick={() => this.ViewMfiDetails(mfiDetail)}
                              className='fas fa-info-circle'
                              style={{ cursor: 'pointer', float: 'right' }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9} style={{ textAlign: 'center' }}>
                          No MFI Details added yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>

          {/* ///// QR/Till Details */}
          <Row style={{ marginTop: '20px', marginBottom: '100px' }}>
            <Col xs={12} md={12}>
              <div className='card-header'>
                <h4>QR/Till Details:</h4>
              </div>
              <div className='card-body p-0'>
                <table className='table table-sm'>
                  <thead style={{ color: '#99A0B5' }}>
                    <tr>
                      <th className='border-0'>Distribution Center</th>
                      <th className='border-0'>MFI</th>
                      <th className='border-0'>Resource Name</th>
                      <th className='border-0'>Till Number</th>
                      <th className='border-0'>MSISDN</th>
                      <th className='border-0'></th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.props.qrOrTillDetails &&
                    this.props.qrOrTillDetails.length > 0 ? (
                      this.props.qrOrTillDetails.map((qrOrTillDetail, j) => (
                        <tr>
                          <td>{qrOrTillDetail.distCenterName}</td>
                          <td>{qrOrTillDetail.mfiName}</td>
                          <td>{qrOrTillDetail.resourceName}</td>
                          <td>{qrOrTillDetail.tillNumber}</td>
                          <td>{qrOrTillDetail.msisdn}</td>
                          <td>
                            <i
                              onClick={() =>
                                this.ViewQRorTillDetails(qrOrTillDetail)
                              }
                              className='fas fa-info-circle'
                              style={{ cursor: 'pointer', float: 'right' }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} style={{ textAlign: 'center' }}>
                          No QR/Till Details added yet
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
        {/* Main Informations Details Modal */}
        <Modal
          show={this.state.mainInfoModal}
          onHide={() => this.setState({ mainInfoModal: false })}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              Main Information Details
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Distributor Name:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>
                        {this.props.distributorMainInfo?.distributorName}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>City:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <label>{this.props.distributorMainInfo?.city}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Head Office Contact:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <p>
                        {this.props.distributorMainInfo?.headOfficeContacts}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>NTN:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <p>{this.props.distributorMainInfo?.ntnOrGst}</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Business Entity Type:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <p>
                        {
                          this.props.distributorMainInfo
                            ?.businessOrganizationType
                        }
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>STRN:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <p>{this.props.distributorMainInfo?.strn}</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7} fluid>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Head Office E-mail:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <p>{this.props.distributorMainInfo?.headOfficeEmails}</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Owner/CEO:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <p>{this.props.distributorMainInfo?.ownerOrCEOName}</p>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={3} style={{ textAlign: 'right' }}>
                      <label style={{ paddingRight: '5px' }}>
                        <strong>Address:</strong>
                      </label>
                    </Col>
                    <Col
                      xs={12}
                      md={7}
                      style={{ textAlign: 'left' }}
                      className='text-nowrap'
                    >
                      <p>{this.props.distributorMainInfo?.headOfficeAddress}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>

        {/* MFI Details Modal */}
        <Modal
          show={this.state.mfiDetailsModal}
          onHide={() => {
            this.setState({ mfiDetails: {} })
            this.setState({ mfiDetailsModal: false })
          }}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              MFI Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>MFI:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.mfiDetails?.mfiName}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Master Number:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.mfiDetails?.masterNumber}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>CBA#:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.mfiDetails?.cbaNo}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Franchise Code:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label> {this.state.mfiDetails?.sellerCode}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Corresponding E-mail:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.mfiDetails.correspondingEmail}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Branch Name:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.mfiDetails.branchName}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Master Till No.:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.mfiDetails.masterTillNo}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={3} style={{ textAlign: 'right' }}>
                      <label style={{ paddingRight: '5px' }}>
                        <strong>Distribution Centers:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>
                        {this.state.mfiDetails != null &&
                        this.state.mfiDetails?.distCentersArray != null &&
                        this.state.mfiDetails?.distributionCenters != null
                          ? this.state.mfiDetails?.distributionCenters
                          : this.state.mfiDetails != null &&
                            this.state.mfiDetails.distCenterNamesArray != null
                          ? this.state.mfiDetails.distCenterNamesArray.join(
                              ', '
                            )
                          : ''}
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>

        {/* QR/Till Details Modal */}
        <Modal
          show={this.state.qrTillDetailsModal}
          onHide={() => this.setState({ qrTillDetailsModal: false })}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              QR/Till Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Distribution Center:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.distCenterName}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>MFI:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.mfiName}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Resource Name:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.resourceName}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Till Number:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.tillNumber}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>MSISDN:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.msisdn}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>E-mail:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label style={{ wordBreak: 'break-word' }}>
                        {this.state.qrTillDetails.email}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Notifier1:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.notifier1}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Notifier2:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.notifier2}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12} md={5} style={{ textAlign: 'right' }}>
                      <label>
                        <strong>Route No.:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.routeNo}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12} md={3} style={{ textAlign: 'right' }}>
                      <label style={{ paddingRight: '5px' }}>
                        <strong>Address:</strong>
                      </label>
                    </Col>
                    <Col xs={12} md={7} style={{ textAlign: 'left' }}>
                      <label>{this.state.qrTillDetails.address}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={12}>
                  <Row style={{ marginLeft: '30%' }}>
                    {this.state.qrTillDetails.qrImagePath != '' &&
                    this.state.qrTillDetails.qrImagePath != null ? (
                      <>
                        <Col xs={12} md={12}>
                          <img
                            alt='QR/Till Image'
                            style={{ border: '1px solid #ccc' }}
                            width={'250px'}
                            height={'250px'}
                            src={this.state.qrTillDetails.qrImagePath}
                          />
                        </Col>
                      </>
                    ) : (
                      <>No QR/Till Image found</>
                    )}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

// function mapStateToProps(state) {
//   return {
//     distributorMainInfo: state.distributorMainInfo
//       ? state.distributorMainInfo
//       : [],
//     bankDetails: state.bankDetails ? state.bankDetails : [],
//     distributionCenters: state.distributionCenters
//       ? state.distributionCenters
//       : [],
//     mfiDetails: state.mfiDetails ? state.mfiDetails : [],
//     qrOrTillDetails: state.qrOrTillDetails ? state.qrOrTillDetails : [],
//     distributorPrincipals: state.distributorPrincipals
//       ? state.distributorPrincipals
//       : [],
//   };
// }
export default DistributionDetailsView
