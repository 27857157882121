import {
  GET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY,
  RESET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY,
} from '../../../../Types'

const DayWiseDailyTransactionsSummaryReducer = (state = [], action) => {
  switch (action.type) {
    case GET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY:
      return [...action.payload]
    case RESET_MONTH_WISE_DAILY_TRANSACTION_SUMMARY:
      return []

    default:
      return state
  }
}
export default DayWiseDailyTransactionsSummaryReducer
