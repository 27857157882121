import { GET_SECTORS_BASED_MAP } from '../../../Types'
import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const getSectorsBasedMap = (postedData) => async (dispatch) => {
    dispatch(showLoading())
    await axios
        .post(`api/Sector/SectorBasedMapReport`, postedData)
        .then(({ data }) => {
            dispatch({
                type: GET_SECTORS_BASED_MAP,
                payload: data.response,
            })
            dispatch(hideLoading())
        })
        .catch(function (error) {
            dispatch(hideLoading())
            if (error.response) {
                if (error.response.status === 401) {
                    logout()
                    history.push('/login')
                    history.go('/login')
                } else if (error.response.data)
                    toast.error(
                        error.response.data.Message
                            ? error.response.data.Message
                            : error.response.data.message
                    )
                else {
                    toast.error('Error while processing the request')
                }
            } else toast.error('Error while processing the request')
        })
}
