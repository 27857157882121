import { GET_DISTRIBUTOR_COIN_PAYMENT_REPORT } from '../../../Types'

const DistributorCoinsPaymentReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DISTRIBUTOR_COIN_PAYMENT_REPORT:
      return action.payload

    default:
      return state
  }
}
export default DistributorCoinsPaymentReportReducer
