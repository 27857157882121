import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Select(props) {
  const { label, value, name, options, readOnly, handleOnChange, ...rest } = props
  return (
    <div className='form-control'>
      <label htmlFor={name} style={{ marginBottom: 0 }}>
        {label}
      </label>
      <Field
        disabled={readOnly}
        as='select'
        id={name}
        onChange={(value) => {
          handleOnChange(value)
        }}
        value={value}
        name={name}
        {...rest}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          )
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Select
