import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { Modal, Col, Row, Overlay, Button, Popover, Container, } from 'react-bootstrap'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import { Link, Redirect } from 'react-router-dom'
import { Formik, Form } from 'formik'
import Moment from 'react-moment'
import FormikControl from '../../../layouts/Input/FormikControl'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import { getFinjaLoanReport, downloadJob } from './FinjaLoanReportAction'
import { getDropdownList } from '../../Shared/dropdownListActions'
import { GET_FINJA_LOAN_REPORT, GET_DISTRIBUTOR_DDL, GET_ADDJOB_DISTRIBUTIONCENTER_DDL } from '../../../Types'
import axios from '../../../apis/Api'
import storage from '../../../apis/Storage'
import { isPermittedPage } from '../../../apis/Utility'


const FinjaLoanReport = (props) => {
  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [filterFromDate, setFilterFromDate] = useState(null)
  const [filterToDate, setFilterToDate] = useState(null)
  const [filterDistributorId, setFilterDistributorId] = useState(null)
  const [filterDistributionCenterId, setFilterDistributionCenterId] =
    useState(null)
  const [filterDeliveryManId, setFilterDeliveryManId] = useState(null)
  const [filterVlTillNo, setFilterVlTillNo] = useState(null)
  const [filterVizId, setFilterVizId] = useState(null);
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState(null);

  const [addJobDistributorsOptions, setAddJobDistributorsOptions] = useState([])
  const [addJobDistCenterOptions, setAddJobDistCenterOptions] = useState([])
  const [deliveryUserList, setDeliveryUserList] = useState([])
  const [qrCodeList, setQrCodeList] = useState([])

  const [filterValuesModal, setFilterValuesModal] = useState({
    fromDate: null,
    toDate: null,
    pulseStatus: '',
    vizId: '',
    InvoiceNumber: ''
  })
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const userRights = JSON.parse(storage.get('_userRights'))

  const Columns = [
    {
      Header: 'VIZ ID',
      accessor: 'vizId'
    },
    {
      Header: 'Lender',
      accessor: 'lender',
    },
    {
      Header: 'Dist Center',
      accessor: 'distCenterName',
    },
    {
      Header: 'Date',
      accessor: 'loanDate',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      )
    },
    {
      Header: 'Invoice No',
      accessor: 'invoiceNo',
    },
    {
      Header: 'Invoice Amount',
      accessor: 'invoiceAmount',
    },
    {
      Header: 'Loan Amount',
      accessor: 'loanAmount',
    },
    {
      Header: 'Profit',
      accessor: 'profit',
    },
    {
      Header: 'Total Payable',
      accessor: 'totalPayable',
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
    },
    {
      Header: 'Repayment Status',
      accessor: 'repaymentStatus'
    },
    {
      Header: 'Deliveryman Till No',
      accessor: 'deliverymanTillNo',
    }
    // {
    //   Header: 'Last Active Date & Time ',
    //   accessor: 'lastActiveDateAndTime',
    //   Cell: (row) => (
    //     <div style={{ width: '75px' }}>
    //       <Moment format='DD/MM/YYYY'>{row.value}</Moment>
    //     </div>
    //   ),
    // },
  ]

  const LatestPulseStatus = [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'In-Active',
      value: 'inActive',
    },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterVizId(null);
    setFilterDistributorId(null)
    setFilterDistributionCenterId(null)
    setFilterFromDate(null)
    setFilterToDate(null)
    setFilterDeliveryManId(null)
    setFilterVlTillNo(null)
    setFilterInvoiceNumber(null);
    setAddJobDistCenterOptions([])
    setDeliveryUserList([])
    setQrCodeList([])
    setFilterValuesModal({
      vizId: '',
      distributorId: '',
      distributionCenterId: '',
      fromDate: '',
      toDate: '',
      deliveryManId: '',
      InvoiceNumber: '',
      vlTillNo: '',
    })
  }

  const getData = (page) => {
    //if (page < pageCount) {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request);
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize);
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize }
    fetchReportData(request);
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0);
    var request = { ...formRequestObject(), pageNo: 0 };
    fetchReportData(request);
    setShow(false);
  }

  const Download = () => {
    let request = formRequestObject(true);
    props.downloadJob(request)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    fetchReportData();
  }, [searchText])

  useEffect(() => {
    if (props.FinjaLoanReportData) {
      setTotalRecords(props.FinjaLoanReportData.totalRecords)
    } else {
      setTotalRecords(0)
    }
  }, [props.FinjaLoanReportData])



  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distributorList = props.allDistributorList.filter(
        (x) => x.value == userRights?.distributorId
      )
      setAddJobDistributorsOptions(
        distributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      let distCenterList = props.addJobDistCentersddlList.filter((x) =>
        userRights?.distCenterIds.includes(x.value)
      )
      setAddJobDistCenterOptions(
        distCenterList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    } else {
      setAddJobDistCenterOptions(
        props.addJobDistCentersddlList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.addJobDistCentersddlList])


  // get distributor dropdown
  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
  }, [])

  // get distribution centers list on change of distributorid
  useEffect(() => {

    if (filterDistributorId) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributorId.value}`,
        GET_ADDJOB_DISTRIBUTIONCENTER_DDL
      )
    }
  }, [filterDistributorId])


  useEffect(() => {
    if (filterDistributionCenterId) {
      getCenterDeliveryUsers(filterDistributionCenterId.value)
    }
  }, [filterDistributionCenterId])

  const getCenterDeliveryUsers = (id) => {
    axios
      .get(`api/DistCenter/GetCenterDeliveryUserDDL/${id}`)
      .then(({ data }) => {
        if (data?.response) {
          setDeliveryUserList(
            data.response.map(function (item) {
              return {
                value: item.id,
                label: item.fullname,
              }
            })
          )

          setQrCodeList(
            data.response.map(function (item) {
              return {
                value: item.qrCode,
                label: item.qrCode,
              }
            })
          )
        }
      })
      .catch(function (error) { })
  }

  function formRequestObject(isExport = false) {
    let requestData = {
      vizId: filterVizId,
      fromDate: filterFromDate && formatDate(filterFromDate),
      toDate: filterToDate && formatDate(filterToDate),
      distributorId: filterDistributorId && filterDistributorId.value,
      distCenterId:
        filterDistributionCenterId && filterDistributionCenterId.value,
      // deliveyrManId: filterDeliveryManId && filterDeliveryManId.value,
      invoiceNumber: filterInvoiceNumber,
      vlTillNo: filterVlTillNo && filterVlTillNo.value,
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      isExport: isExport,
    }
    if (requestData.distributorId == null || requestData.distributorId == '') {
      if (userRights != null && userRights?.userType == 'DistributorUser') {
        debugger
        requestData.distributorId = userRights?.distributorId;
      }
    }
    return requestData;
  }


  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request;
    props.getFinjaLoanReport(finalRequest)
  }

  function formatDate(dateInput) {
    var res = new Date(
      Date.parse(
        new Intl.DateTimeFormat('fr-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(new Date(dateInput))
      )
    )
      .toISOString()
      .split('T')[0]
    return res
  }


  return (
    <VzPageContainer title='successful Loans Detail Report'>
      <div>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'left' }}>
            <h4>
              <b>Successful Loans detail Report</b>
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filterValuesModal}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  label='Viz ID'
                                  name='vizId'
                                  handleOnChange={(value) => {
                                    (filterValuesModal.vizId = value)
                                    setFilterVizId(value)
                                  }
                                  }
                                  value={filterValuesModal.vizId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.fromDate = value
                                    setFilterFromDate(value)
                                  }}
                                  value={filterValuesModal.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    filterValuesModal.toDate = value
                                    setFilterToDate(value)
                                  }}
                                  value={filterValuesModal.toDate}
                                  minDate={filterFromDate}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distributor Id'
                                  name='distributorId'
                                  options={addJobDistributorsOptions}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributorId(null)
                                      setFilterDistributionCenterId([])
                                      setAddJobDistCenterOptions([])
                                      setQrCodeList([])
                                      setFilterVlTillNo(null)
                                      return
                                    }
                                    filterValuesModal.distributorId = e.value
                                    setFilterDistributorId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributorId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Distribution Id'
                                  name='distributionCenterId'
                                  options={addJobDistCenterOptions}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setFilterDistributionCenterId([])
                                      setAddJobDistCenterOptions([])
                                      setQrCodeList([])
                                      setFilterVlTillNo(null)
                                      return
                                    }
                                    filterValuesModal.distributionCenterId =
                                      e.value
                                    setFilterDistributionCenterId({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterDistributionCenterId}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  label='Invoice Number'
                                  name='invoiceNumber'
                                  handleOnChange={(value) => {
                                    (filterValuesModal.InvoiceNumber = value)
                                    setFilterInvoiceNumber(value)
                                  }
                                  }
                                  value={filterValuesModal.InvoiceNumber}
                                />
                              </Col>
                            </Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='VL TILL NO'
                                  name='vlTillNo'
                                  options={qrCodeList ?? []}
                                  isClearable={true}
                                  isSearchable={false}
                                  isMulti={false}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      setQrCodeList([])
                                      setFilterVlTillNo([])
                                      return
                                    }
                                    filterValuesModal.vlTillNo = e.value
                                    setFilterVlTillNo({
                                      label: e.label,
                                      value: e.value,
                                    })
                                    setQrCodeList({
                                      label: e.label,
                                      value: e.value,
                                    })
                                  }}
                                  value={filterVlTillNo}
                                />
                              </Col>
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
          {isPermittedPage('Export', props.actions, props.userRole) &&
            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={Download}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-download me-1' />
                Export
              </Button>
            </Col>
          }
        </Row>
        <Row>
          <Col xs={12}>
            <ServerSideDataTable
              data={props.FinjaLoanReportData.data ?? []}
              columns={Columns}
              getTrProps={(event, data) => null}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
      </div>
    </VzPageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    FinjaLoanReportData: state.FinjaLoanReport,
    allDistributorList: state.ddlList.allDistributorList
      ? state.ddlList.allDistributorList
      : [],
    addJobDistCentersddlList: state.ddlList.selectedAddJobDistCentersList
      ? state.ddlList.selectedAddJobDistCentersList
      : [],
  }
}

export default connect(mapStateToProps, {
  getFinjaLoanReport,
  getDropdownList,
  downloadJob,
})(FinjaLoanReport)