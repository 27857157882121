import { SET_SOCKET, MESSAGE_RECEIVED } from '../../Types'
import _ from 'lodash'
const initialState = {
  socketData: null,
  notifications: [],
  // distCenterList: [],
}
const SocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOCKET:
      return { ...state, socketData: action.payload }

    default:
      return state
  }
}
export default SocketReducer
