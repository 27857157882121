import { useEffect, useState } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import axios from '../../../apis/Api'
import ReactSelect from 'react-select'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import CustomDataTable from '../../../layouts/DataTables/CustomDataTable'
import DistributionDetailsView from '../DistributorSummary/DistributionDetailsView'
// import { SelectColumnFilter } from '../../../layouts/DataTables/SelectColumnFilter'
import { connect } from 'react-redux'
import {
  getDistributorsList,
  deleteDistributor,
} from './ListofDistributorAction'
import { getPrincipals } from '../../Principals/ManagePrincipalsAction'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'

const ListofDistributors = (props) => {
  const [principalSelectedOptions, setPrincipalSelectedOptions] = useState([])
  const [principalOptions, setPrincipalOptions] = useState([])
  const [distFilterList, setDistFilterList] = useState([])
  const [distributorCompleteData, setDistributorCompleteData] = useState(null)
  const [showDistributorViewModal, setShowDistributorViewModal] =
    useState(false)

  //Pagination
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [filterPrincipal, setFilterPrincipal] = useState(null)

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      principal: filterPrincipal ? filterPrincipal.value : null,
      isIncomplete: false,
    }
    props.getDistributorsList(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      principal: filterPrincipal ? filterPrincipal.value : null,
      isIncomplete: false,
    }
    props.getDistributorsList(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const DeleteRecord = async (id) => {
    await props.deleteDistributor(id)

    if (totalRecords > 5 && distFilterList.length == 1) {
      let previousPage = currentPageNo > 0 ? currentPageNo - 1 : 0
      setCurrentPageNo(previousPage)
      let requestData = {
        pageSize: currentPageSize,
        pageNo: previousPage,
        searchText: searchText,
        principal: filterPrincipal ? filterPrincipal.value : null,
        isIncomplete: false,
      }
      props.getDistributorsList(requestData)
    } else {
      let requestData = {
        pageSize: currentPageSize,
        pageNo: currentPageNo,
        searchText: searchText,
        principal: filterPrincipal ? filterPrincipal.value : null,
        isIncomplete: false,
      }
      props.getDistributorsList(requestData)
    }
  }

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      principal: filterPrincipal ? filterPrincipal.value : null,
      isIncomplete: false,
    }
    props.getDistributorsList(requestData)
  }, [searchText])

  useEffect(() => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      principal: filterPrincipal ? filterPrincipal.value : null,
      isIncomplete: false,
    }
    props.getDistributorsList(requestData)
  }, [filterPrincipal])

  useEffect(() => {
    props.getPrincipals()
  }, [])
  useEffect(async () => {
    setPrincipalOptions(
      props.principals.map(function (item) {
        return {
          value: item.id,
          label: item.principalName,
        }
      })
    )
  }, [props.principals])
  const handleDistPrincipalOnChange = (value) => {
    setPrincipalSelectedOptions(value)
    if (value && value.value) {
      setDistFilterList(
        props.listofDistributors.filter((e) =>
          e.principalIds.includes(value.value)
        )
      )
    } else {
      setDistFilterList(props.listofDistributors)
    }
  }
  useEffect(async () => {
    setDistFilterList(props.listofDistributors)
    setTotalRecords(
      props.listofDistributors.length > 0
        ? props.listofDistributors[0].totalRecords
        : 0
    )
  }, [props.listofDistributors])
  const view = (id) => {
    axios
      .get(`api/Distributor/DistributorDetails/` + id)
      .then(({ data }) => {
        setDistributorCompleteData(data.response)
      })
      .catch(function (error) {
        // dispatch(hideLoading())
        return {}
      })
    setShowDistributorViewModal(true)
    // const responseData = await getDistributorCompleteData(id);
  }
  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }
  const Edit = (id) => {
    return <Redirect to='`/EditDistributorInductionForm/${row.value}`' />
  }

  const columns = [
    {
      Header: 'Distributors Name',
      accessor: 'distributorName',
      sortType: 'basic',
    },
    {
      Header: 'Principal',
      accessor: 'principals',
      // Filter: SelectColumnFilter, // new
      // filter: 'includes', // new
    },
    {
      Header: 'Dist. Centers',
      accessor: 'distCenters',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'Shops Served',
      accessor: 'shopsServed',
    },
    {
      Header: 'QR/Till',
      accessor: 'qrTills',
    },
    {
      Header: 'Action',
      id: 'edit',
      accessor: 'id',
      Cell: (row) => (
        <div style={{ width: '106px' }}>
          <button onClick={() => view(row.value)} className=' btn btn-sm'>
            <i className='fas fa-eye'></i>
          </button>
          &nbsp;
          <Link
            className='btn btn-sm'
            to={`/EditDistributorInductionForm/${row.value}`}
          >
            <i className='fas fa-edit'></i>
          </Link>
          {/* <button onClick={() => Edit(row.value)} className='btn btn-sm'>
            <i className='fas fa-edit'></i>
          </button> */}
          &nbsp;
          <button onClick={() => Delete(row.value)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  return (
    <div>
      <VzPageContainer title='Complete Distributors List'>
        <Row>
          <Col md={{ span: 3 }} style={{ float: 'right' }}>
            <h5>
              <b>Complete Distributors List</b>
            </h5>
          </Col>
          <Col md={{ span: 3, offset: 6 }} style={{ float: 'right' }}>
            <div className='form-group'>
              <ReactSelect
                id='principals'
                name='principals'
                className='basic-single'
                classNamePrefix='select'
                placeholder='Select Principal'
                isMulti={false}
                value={filterPrincipal}
                isClearable={true}
                onChange={(res) => setFilterPrincipal(res)}
                options={principalOptions}
              ></ReactSelect>
            </div>
          </Col>
        </Row>
        {/* <CustomDataTable
          data={distFilterList}
          columns={columns}
          getTrProps={() => null}
        /> */}
        <ServerSideDataTable
          data={distFilterList}
          columns={columns}
          getTrProps={(event, data) => null}
          goToPreviousPage={(val) => getData(val)}
          goToNextPage={(val) => getData(val)}
          totalRecords={totalRecords}
          pageSizeChange={(size) => pageSizeChanged(size)}
          myPageSize={currentPageSize}
          myIndex={currentIndex}
          pageNo={currentPageNo}
          searchChanged={searchChanged}
        />
      </VzPageContainer>
      <Modal
        show={showDistributorViewModal}
        onHide={() => setShowDistributorViewModal(false)}
        size='xl'
      >
        <DistributionDetailsView
          distributorMainInfo={
            distributorCompleteData?.distributorMainInfo
              ? distributorCompleteData.distributorMainInfo
              : []
          }
          bankDetails={
            distributorCompleteData?.bankDetails
              ? distributorCompleteData.bankDetails
              : []
          }
          distributionCenters={
            distributorCompleteData?.distributionCenters
              ? distributorCompleteData.distributionCenters
              : []
          }
          mfiDetails={
            distributorCompleteData?.mfiDetails
              ? distributorCompleteData.mfiDetails
              : []
          }
          qrOrTillDetails={
            distributorCompleteData?.qrOrTillDetails
              ? distributorCompleteData.qrOrTillDetails
              : []
          }
          distributorPrincipals={
            distributorCompleteData?.distributorPrincipals
              ? distributorCompleteData.distributorPrincipals
              : []
          }
        ></DistributionDetailsView>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    listofDistributors: state.listofDistributors
      ? state.listofDistributors
      : [],
    principals: state.Principals,
  }
}
export default connect(mapStateToProps, {
  getDistributorsList,
  deleteDistributor,
  getPrincipals,
})(ListofDistributors)
