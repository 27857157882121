import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  ADD_DISTRIBUTORPRINCIPALS,
  GET_DISTRIBUTORPRINCIPALS,
  DELETE_DISTRIBUTORPRINCIPALS,
  UPDATE_DISTRIBUTORPRINCIPALS,
} from '../../../Types'

export const addDistributorPrincipals = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post(
      'api/DistributorPrincipalDetails/SaveDistributorPrincipalDetails',
      formValues
    )
    .then(({ data }) => {
      dispatch({
        type: ADD_DISTRIBUTORPRINCIPALS,
        payload: data.response,
      })
      dispatch(hideLoading())
      toast.success('Record added successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response)
      {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getDistributorPrincipals = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(
      `api/DistributorPrincipalDetails/GetDistributorPrincipalDetailByDistributor/${distributorId}`
    )
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_DISTRIBUTORPRINCIPALS,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateDistributorPrincipals =
  (distributorId, formValues) => async (dispatch) => {
    dispatch(showLoading())
    var response = await axios.put(
      `api/DistributorPrincipalDetails/${distributorId}`,
      formValues
    )
    dispatch({
      type: UPDATE_DISTRIBUTORPRINCIPALS,
      payload: JSON.stringify(response.data),
    })
    dispatch(hideLoading())
    toast.success('Record updated successfully')
    history.push('/')
  }

export const deleteDistributorPrincipals =
  (distributorId) => async (dispatch) => {
    dispatch(showLoading())
    await axios.delete(
      `api/DistributorPrincipalDetails/DeleteDistributorPrincipals/${distributorId}`
    )
    dispatch({ type: DELETE_DISTRIBUTORPRINCIPALS, payload: distributorId })
    dispatch(hideLoading())
    toast.success('Record deleted successfully')
  }
