import { GET_DLM_COLLECTION_REPORT } from '../../../Types'
import _ from 'lodash'



const DLMCollectionReportReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DLM_COLLECTION_REPORT:
      return { ...action.payload }
    default:
      return state
  }
}
export default DLMCollectionReportReducer