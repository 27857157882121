import {
  GET_JOBS,
  ADD_JOBS,
  UPDATE_JOBS,
  DELETE_JOBS,
  EMPTY_JOBS,
  UPLOAD_JOBS,
} from '../../../Types'
// import _ from 'lodash'

const JobsListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_JOBS:
      return [...action.payload]
    case ADD_JOBS:
      if (action.payload.length > 0) {
        return action.payload
      } else {
        return [...state]
      }
    case UPDATE_JOBS:
      const index = state.findIndex((item) => item.id === action.payload.id) //finding index of the item
      const newState = [...state] //making a new array
      newState[index] = action.payload //changing value in the new array
      return [...newState] //reassingning todos to new array
    case DELETE_JOBS:
      return state.filter((e) => e.id !== action.payload)
    case EMPTY_JOBS:
      return []
    case UPLOAD_JOBS:
      return [...action.payload]
    default:
      return state
  }
}
export default JobsListReducer
