import { GET_FINJA_LOAN_REPORT } from '../../../Types'

const initialState = []

const FinjaLoanReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINJA_LOAN_REPORT:
      return action.payload
    default:
      return state
  }
}
export default FinjaLoanReportReducer