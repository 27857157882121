import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { VzPageContainer } from '../../../layouts/VzPageContainer'
import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap'
import Moment from 'react-moment'
import ServerSideDataTable from '../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'

import axios from '../../../apis/Api'
import FormikControl from '../../../layouts/Input/FormikControl'
import { Formik, Form } from 'formik'
import { Link, Redirect } from 'react-router-dom'
import { formatDate } from '../../../utilities/FUNCTIONS'
import ReactToPrint from 'react-to-print'
import PlaceholderImage from '../../../assets/Images/placeholder-image.png'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { useDispatch } from 'react-redux'

const KYCFinalized = (props) => {
  const dispatch = useDispatch()
  const [kycList, setKycList] = useState([])
  const [selectedKyc, setSelectedKyc] = useState(null)
  const [originalKycData, setOriginalKycData] = useState(null)

  const [isView, setIsView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDownload, setIsDownload] = useState(false)
  const [selectedImg, setSelectedImg] = useState('')

  const [target, setTarget] = useState(null)
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  const printRef = useRef()
  const imgUploadRef = useRef()

  // pagination states
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)

  const filterInitialValues = {
    fromDate: null,
    toDate: null,
    shopId: '',
    status: '',
    pageNo: 0,
    searchText: '',
  }

  const modalInitialValues = {
    id: '',
    cnic: '',
    cnicBack: '',
    cnicExpiryDate: new Date(),
    cnicFront: '',
    cnicHomeAddress: '',
    cnicIssuanceDate: new Date(),
    currentHomeAddress: '',
    date: '',
    dateOfBirth: new Date(),
    fatherName: '',
    lenderName: '',
    motherName: '',
    ownerName: '',
    ownerPicture: '',
    ownershipType: '',
    shopAddress: '',
    shopHeight: 0,
    shopWidth: 0,
    shopName: '',
    shopPicture: '',
    shopPictureRight: '',
    shopPictureLeft: '',
    invoicePicture: '',
    status: '',
    userKycRequestType: 0,
    vizshopCode: '',
    remarks: null,
    contactNo: '',
  }

  const [filter, setfilter] = useState(filterInitialValues)
  const [modalValues, setModalValues] = useState(modalInitialValues)

  const columns = [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row) => (
        <div style={{ width: '75px' }}>
          <Moment format='DD/MM/YYYY'>{row.value}</Moment>
        </div>
      ),
    },
    {
      Header: 'MFI',
      accessor: 'mfi',
    },

    {
      Header: 'Form Status',
      accessor: 'status',
    },

    {
      Header: 'Shop Id',
      accessor: 'shopCode',
    },
    {
      Header: 'Action',
      id: 'id',
      accessor: 'id',
      Cell: (row) => (
        <div className='d-flex' style={{ width: '106px' }}>
          <button
            onClick={() => viewRecord(row.value, false)}
            className='btn btn-sm'
          >
            <i className='fas fa-eye'></i>
          </button>
          &nbsp;
          <button
            onClick={() => viewRecord(row.value, true)}
            className='ms-2 btn btn-sm '
            style={{ backgroundColor: '#ea5f2d', color: 'white' }}
          >
            Download
          </button>
          {/* &nbsp;
          <button onClick={() => Delete(row.value)} className='btn btn-sm'>
            <i className='fas fa-trash'></i>
          </button> */}
        </div>
      ),
    },
  ]

  const statusOptions = [
    {
      label: 'Pending',
      value: 1,
    },
    {
      label: 'Completed',
      value: 2,
    },
    {
      label: 'Failed',
      value: 3,
    },
  ]

  const getKycList = (request) => {
    axios
      .post(`api/LenderKYC/GetKycFinalizedList`, request)
      .then(({ data }) => {
        setKycList(data?.response?.data)
        setTotalRecords(data?.response?.totalRecords)
      })
      .catch(function (error) {})
  }

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }

  const handleResetFilters = (resetForm) => {
    resetForm()

    setfilter(filterInitialValues)
  }

  function formRequestObject() {
    let requestData = {
      fromDate: filter.fromDate && formatDate(filter.fromDate),
      toDate: filter.fromDate && formatDate(filter.toDate),
      status: filter.status?.value,
      shopId: filter.shopId,
      pageSize: currentPageSize,
      pageNo: filter.pageNo,
      searchText: filter.searchText,
    }

    return requestData
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setfilter({ ...filter, searchText: text })
    var request = {
      ...formRequestObject(),
      searchText: text,
      currentPageNo: 0,
    }
    fetchReportData(request)
  }

  function fetchReportData(request) {
    let finalRequest = request == null ? formRequestObject() : request
    getKycList(finalRequest)
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    var request = { ...formRequestObject(), pageNo: page }
    fetchReportData(request)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    var request = { ...formRequestObject(), pageNo: 0 }
    fetchReportData(request)
    setShow(false)
  }

  const Download = () => {
    let request = { ...formRequestObject(), isExport: true }
    props.downloadJob(request)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    var request = { ...formRequestObject(), pageNo: 0, pageSize: pageSize }
    fetchReportData(request)
  }

  const getRecord = (id) => {
    axios
      .get(`api/LenderKYC/GetKycRecord/${id}`)
      .then(({ data }) => {
        setSelectedKyc(data?.response)
        setOriginalKycData({ ...data?.response })
        setModalValues({ ...data?.response })
      })
      .catch(function (error) {})
  }

  const viewRecord = (id, download) => {
    setIsDownload(download)
    setIsView(true)
    setIsEdit(false)
    getRecord(id)
  }

  const editRecord = (id) => {
    setIsDownload(false)
    setIsView(false)
    setIsEdit(true)
    getRecord(id)
  }

  const closeModal = () => {
    setIsView(false)
    setIsEdit(false)
    setSelectedKyc(null)
  }

  const resetData = () => {
    setModalValues({ ...originalKycData })
  }

  const removeImage = (fieldName) => {
    modalValues[fieldName] = ''
  }

  const openUploadImage = (img) => {
    setSelectedImg(img)
    imgUploadRef.current.click()
  }

  const saveImage = (event) => {
    dispatch(showLoading())
    const formData = new FormData()
    formData.append('DataFile', event.target.files[0])
    imgUploadRef.current.value = null
    axios
      .post(`api/LenderKYC/UploadImage`, formData)
      .then(({ data }) => {
        modalValues[selectedImg] = data?.response
        setSelectedImg('')
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
      })
  }

  const checkStatus = (type, current) => {
    switch (type) {
      case 'revert':
        return 4
      case 'finalize':
        return 5
      default:
        return current
    }
  }

  const saveToasterMessage = (type) => {
    switch (type) {
      case 4:
        return 'Record reverted to Shopkeeper'
      case 5:
        return 'Record finalized'
      default:
        return 'Record updated'
    }
  }

  const saveData = (formData, type) => {
    let currentStatus = checkStatus(type, formData.status)
    let req = { ...formData, status: currentStatus }

    dispatch(showLoading())
    axios
      .post(`api/LenderKYC/FinalizeOrRevertKYC`, req)
      .then(({ data }) => {
        toast.success(saveToasterMessage(currentStatus))

        closeModal()
        getData(0)
        dispatch(hideLoading())
      })
      .catch(function (error) {
        dispatch(hideLoading())
      })
  }

  const sendToFinja = (record) => {
    const { id } = record

    dispatch(showLoading())
    axios
      .get(`api/Payment/finja/onboardingInfo/${id}`)
      .then(({ data }) => {
        if (data?.response == id) {
          toast.success('Form sent to Finja')
          setSelectedKyc({ ...selectedKyc, status: 6 })
        }
        dispatch(hideLoading())
      })
      .catch(function (error) {
        toast.error(error.response.data.Message)
        dispatch(hideLoading())
      })
  }

  useEffect(() => {
    // getKycList({})
    fetchReportData()
  }, [])

  return (
    <>
      <VzPageContainer title='KYC Form List'>
        <Row>
          <Col style={{ float: 'left' }}>
            <h4>
              <b>Finalized KYC Form List</b>
            </h4>
            <br />
          </Col>
        </Row>
        <Row>
          <Col md={10} style={{ float: 'right' }}>
            <div ref={ref}>
              <Button
                onClick={openFilterModal}
                className='m-3'
                variant='outline-primary'
              >
                <i className='fa fa-filter' />
                Filters
              </Button>

              <Overlay
                show={show}
                target={target}
                placement='bottom'
                container={ref}
                containerPadding={20}
                width='430px'
                style={{ width: '430px' }}
              >
                <Popover
                  id='popover-contained'
                  style={{ maxWidth: '360px', width: '360px' }}
                >
                  <Popover.Header as='h3'>Filters</Popover.Header>
                  <Popover.Body>
                    <Formik
                      enableReinitialize
                      initialValues={filter}
                      validator={() => ({})}
                      onSubmit={LoadDataByfilter}
                    >
                      {(formik) => (
                        <Form autoComplete='off'>
                          <Row>
                            <Row style={{ paddingRight: 0 }}>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='From Date'
                                  name='fromDate'
                                  handleOnChange={(value) => {
                                    setfilter({ ...filter, fromDate: value })
                                  }}
                                  value={filter.fromDate}
                                />
                              </Col>
                              <Col xs={12} md={6}>
                                <FormikControl
                                  // formik={formik}
                                  control='date'
                                  label='To Date'
                                  name='toDate'
                                  handleOnChange={(value) => {
                                    setfilter({ ...filter, toDate: value })
                                  }}
                                  value={filter.toDate}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='input'
                                  type='shopId'
                                  label='Shop Id'
                                  name='shopId'
                                  handleOnChange={(e) => {
                                    setfilter({
                                      ...filter,
                                      shopId: e,
                                    })
                                  }}
                                  value={filter.shopId}
                                />
                              </Col>
                              {/* <Col xs={12} md={12}>
                                <FormikControl
                                  formik={formik}
                                  control='react-select'
                                  label='Form Status'
                                  name='status'
                                  options={statusOptions}
                                  isSearchable={true}
                                  handleOnChange={(e) => {
                                    if (!e || !e.value) {
                                      return
                                    }
                                    setfilter({
                                      ...filter,
                                      status: {
                                        label: e.label,
                                        value: e.value,
                                      },
                                    })
                                  }}
                                  value={filter.status}
                                />
                              </Col> */}
                            </Row>
                          </Row>
                          <div className='row' style={{ marginTop: 20 }}>
                            <div className='form-group'>
                              <Link
                                onClick={handleResetFilters.bind(
                                  null,
                                  formik.resetForm
                                )}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'left',
                                }}
                              >
                                Reset
                              </Link>
                              <Link
                                onClick={() => {
                                  setShow(false)
                                  setTarget(null)
                                }}
                                to='#'
                                className='active'
                                style={{
                                  padding: '0px',
                                  marginTop: '10px',
                                  float: 'right',
                                }}
                              >
                                Cancel
                              </Link>
                              <div className='float-right'>
                                <Button
                                  type='submit'
                                  className='btn btn-primary'
                                  style={{
                                    marginRight: '10px',
                                    float: 'right',
                                  }}
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ServerSideDataTable
              data={kycList ?? []}
              columns={columns}
              getTrProps={(event, data) => {}}
              goToPreviousPage={(val) => getData(val)}
              goToNextPage={(val) => getData(val)}
              totalRecords={totalRecords ?? 0}
              pageSizeChange={(size) => pageSizeChanged(size)}
              myPageSize={currentPageSize}
              myIndex={currentIndex}
              pageNo={currentPageNo}
              searchChanged={searchChanged}
            />
          </Col>
        </Row>
        <Modal
          show={isView || isEdit}
          onHide={closeModal}
          backdrop='static'
          keyboard={false}
          size='xl'
        >
          <Formik
            enableReinitialize
            initialValues={modalValues}
            validationSchema={null}
            // onSubmit={saveData}
          >
            {(formik) => (
              <Form autoComplete='off'>
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    {isDownload && (
                      <ReactToPrint
                        trigger={() => <Button>Download</Button>}
                        content={() => printRef.current}
                        documentTitle='KYC'
                      />
                    )}
                    {/* {isEdit && <Button onClick={resetData}>Reset</Button>} */}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body ref={printRef}>
                  {!isEdit ? (
                    <Row>
                      <Col xs={12} md={12}>
                        <div className='d-flex justify-content-between mx-4 mt-4'>
                          <p>
                            <b>Date : </b>
                            <span style={{ textDecoration: 'underline' }}>
                              <Moment format='DD/MM/YYYY'>
                                {selectedKyc?.date}
                              </Moment>
                            </span>
                          </p>
                          <p>
                            <b>Viz ID : </b>
                            <span style={{ textDecoration: 'underline' }}>
                              {selectedKyc?.vizshopCode}
                            </span>
                          </p>
                          <p>
                            <b>Shop Code : </b>
                            <span style={{ textDecoration: 'underline' }}>
                              {selectedKyc?.distshopCode}
                            </span>
                          </p>
                          <p>
                            <b>Form Status : </b>
                            <span style={{ textDecoration: 'underline' }}>
                              {selectedKyc?.statusValue}
                            </span>
                          </p>
                        </div>
                        <div
                          className='card-header'
                          style={{ marginTop: '20px' }}
                        >
                          <h5>Profile Information:</h5>
                        </div>

                        <div className='card-body p-0 m-0'>
                          <table className='table table-sm'>
                            <thead style={{ color: '#99A0B5' }}>
                              <tr>
                                <th className='border-0'>Shop Owner Name</th>
                                <th className='border-0'>Father's Name</th>
                                <th className='border-0'>Mother's Name</th>
                                <th className='border-0'>CNIC No.</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{selectedKyc?.ownerName}</td>
                                <td>{selectedKyc?.fatherName}</td>
                                <td>{selectedKyc?.motherName}</td>
                                <td>{selectedKyc?.cnic}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className='card-body p-0 m-0'>
                          <table className='table table-sm'>
                            <thead style={{ color: '#99A0B5' }}>
                              <tr>
                                <th className='border-0'>Date of Birth</th>
                                <th className='border-0'>CNIC Issuance Date</th>
                                <th className='border-0'>CNIC Expiry Date</th>
                                <th className='border-0'>Contact No</th>
                                <th className='border-0'>City</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Moment format='DD/MM/YYYY'>
                                    {selectedKyc?.dateOfBirth}
                                  </Moment>
                                </td>
                                <td>
                                  <Moment format='DD/MM/YYYY'>
                                    {selectedKyc?.cnicIssuanceDate}
                                  </Moment>
                                </td>
                                <td>
                                  <Moment format='DD/MM/YYYY'>
                                    {selectedKyc?.cnicExpiryDate}
                                  </Moment>
                                </td>
                                <td>{selectedKyc?.contactNo}</td>
                                <td>{selectedKyc?.city}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className='card-body p-0 m-0'>
                          <table className='table table-sm'>
                            <thead style={{ color: '#99A0B5' }}>
                              <tr>
                                <th className='border-0'>
                                  Home Address Current
                                </th>
                                <th className='border-0'></th>
                                <th className='border-0'>
                                  Home Address based on CNIC
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{selectedKyc?.currentHomeAddress}</td>
                                <td></td>
                                <td>{selectedKyc?.cnicHomeAddress}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />

                        <div className='d-flex  mx-4'>
                          <div className='me-4'>
                            <img
                              className='kyc-form-img'
                              style={{ border: '1px solid #ccc' }}
                              alt='not fount'
                              width={'125px'}
                              height={'125px'}
                              src={selectedKyc?.ownerPicture}
                            />
                            <p>Shop Owner Picture</p>
                          </div>
                          <div className='me-4'>
                            <img
                              className='kyc-form-img'
                              style={{ border: '1px solid #ccc' }}
                              alt='not fount'
                              width={'125px'}
                              height={'90px'}
                              src={selectedKyc?.cnicFront}
                            />
                            <p>Shop Owner Cnic Front</p>
                          </div>
                          <div className='me-4'>
                            <img
                              className='kyc-form-img'
                              style={{ border: '1px solid #ccc' }}
                              alt='not fount'
                              width={'125px'}
                              height={'90px'}
                              src={selectedKyc?.cnicBack}
                            />
                            <p>Shop Owner Cnic Back</p>
                          </div>
                        </div>
                        <div
                          className='card-header'
                          style={{ marginTop: '20px' }}
                        >
                          <h5>Shop Details:</h5>
                        </div>

                        <div className='card-body p-0 m-0'>
                          <table className='table table-sm'>
                            <thead style={{ color: '#99A0B5' }}>
                              <tr>
                                <th className='border-0'>Shop Name</th>
                                <th className='border-0'>Contact No</th>
                                <th className='border-0'>Shop Address</th>
                                <th className='border-0'>Locality</th>
                                <th className='border-0'>Shop Ownership</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{selectedKyc?.shopName}</td>
                                <td>{selectedKyc?.contactNo}</td>
                                <td>{selectedKyc?.shopAddress}</td>
                                <td>{selectedKyc?.locality}</td>
                                <td>{selectedKyc?.ownershipType}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className='card-body p-0 m-0'>
                          <table className='table table-sm'>
                            <thead style={{ color: '#99A0B5' }}>
                              <tr>
                                <th className='border-0'>Shop Category</th>
                                <th className='border-0'>Shop Height</th>
                                <th className='border-0'>Shop Width</th>
                                <th className='border-0'>Monthly Sales</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{selectedKyc?.shopCategory}</td>
                                <td>{selectedKyc?.shopHeight}</td>
                                <td>{selectedKyc?.shopWidth}</td>
                                <td>{selectedKyc?.sales}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className='d-flex  mx-4'>
                          <div className='me-4'>
                            <img
                              className='kyc-form-img'
                              style={{ border: '1px solid #ccc' }}
                              alt='not fount'
                              width={'200px'}
                              height={'125px'}
                              src={selectedKyc?.shopPicture}
                            />
                            <p>Shop Front Picture</p>
                          </div>
                          <div className='me-4'>
                            <img
                              className='kyc-form-img'
                              style={{ border: '1px solid #ccc' }}
                              alt='not fount'
                              width={'200px'}
                              height={'125px'}
                              src={selectedKyc?.shopPictureRight}
                            />
                            <p>Shop Side Picture </p>
                          </div>
                          <div className='me-4'>
                            <img
                              className='kyc-form-img'
                              style={{ border: '1px solid #ccc' }}
                              alt='not fount'
                              width={'200px'}
                              height={'125px'}
                              src={selectedKyc?.shopPictureLeft}
                            />
                            <p>Shop Inside Picture</p>
                          </div>
                          <div className='me-4'>
                            <img
                              className='kyc-form-img'
                              style={{ border: '1px solid #ccc' }}
                              alt='not fount'
                              width={'200px'}
                              height={'125px'}
                              src={selectedKyc?.invoicePicture}
                            />
                            <p>Invoice Picture</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    // <>
                    // <Row>
                    //   <Col xs={12}>
                    //     <div className='d-flex justify-content-between mx-4 mt-4'>
                    //       <p>
                    //         <b>Date : </b>
                    //         <span style={{ textDecoration: 'underline' }}>
                    //           <Moment format='DD/MM/YYYY'>
                    //             {selectedKyc?.date}
                    //           </Moment>
                    //         </span>
                    //       </p>
                    //       <p>
                    //         <b>Viz ID : </b>
                    //         <span style={{ textDecoration: 'underline' }}>
                    //           {selectedKyc?.vizshopCode}
                    //         </span>
                    //       </p>
                    //       <p>
                    //         <b>Shop Code : </b>
                    //         <span style={{ textDecoration: 'underline' }}>
                    //           {selectedKyc?.distshopCode}
                    //         </span>
                    //       </p>
                    //       <p>
                    //         <b>Form Status : </b>
                    //         <span style={{ textDecoration: 'underline' }}>
                    //           {selectedKyc?.status}
                    //         </span>
                    //       </p>
                    //     </div>
                    //   </Col>
                    // </Row>
                    //   <Row>
                    //     <Col>
                    //       <div
                    //         className='card-header'
                    //         style={{ marginTop: '20px' }}
                    //       >
                    //         <h5>Profile Information:</h5>
                    //       </div>
                    //     </Col>
                    //   </Row>
                    //   <Row>
                    //     <div className='d-flex justify-content-between mx-2 '>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='ownerName'
                    //             label='Shop Owner Name'
                    //             name='ownerName'
                    //             handleOnChange={(e) =>
                    //               (modalValues.ownerName = e)
                    //             }
                    //             value={modalValues.ownerName}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='fatherName'
                    //             label="Father's Name"
                    //             name='fatherName'
                    //             handleOnChange={(e) =>
                    //               (modalValues.fatherName = e)
                    //             }
                    //             value={modalValues.fatherName}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='motherName'
                    //             label="Mother's Name"
                    //             name='motherName'
                    //             handleOnChange={(e) =>
                    //               (modalValues.motherName = e)
                    //             }
                    //             value={modalValues.motherName}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='cnic'
                    //             label='CNIC No.'
                    //             name='cnic'
                    //             handleOnChange={(e) => (modalValues.cnic = e)}
                    //             value={modalValues.cnic}
                    //           />
                    //         </div>
                    //       </Col>
                    //     </div>
                    //   </Row>
                    //   <Row>
                    //     <div className='d-flex justify-content-between mx-2 '>
                    //       <Col xs={6} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='date'
                    //             label='Date of Birth'
                    //             name='dateOfBirth'
                    //             handleOnChange={(value) => {
                    //               modalValues.dateOfBirth = value
                    //             }}
                    //             value={modalValues.dateOfBirth}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={6} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='date'
                    //             label='CNIC Issuance Date'
                    //             name='cnicIssuanceDate'
                    //             handleOnChange={(value) => {
                    //               modalValues.cnicIssuanceDate = value
                    //             }}
                    //             value={modalValues.cnicIssuanceDate}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={6} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='date'
                    //             label='CNIC Expiry Date'
                    //             name='cnicExpiryDate'
                    //             handleOnChange={(value) => {
                    //               modalValues.cnicExpiryDate = value
                    //             }}
                    //             value={modalValues.cnicExpiryDate}
                    //           />
                    //         </div>
                    //       </Col>

                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'></div>
                    //       </Col>
                    //     </div>
                    //   </Row>
                    //   <Row>
                    //     <div className='d-flex  mx-2 '>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group d-flex '>
                    //           <div>
                    //             <img
                    //               style={{ border: '1px solid #ccc' }}
                    //               alt='not fount'
                    //               width={'125px'}
                    //               height={'125px'}
                    //               src={
                    //                 modalValues?.ownerPicture ||
                    //                 PlaceholderImage
                    //               }
                    //             />
                    //             <input
                    //               ref={imgUploadRef}
                    //               hidden
                    //               type='file'
                    //               name='imageFile'
                    //               accept='image/*'
                    //               onChange={(event) => saveImage(event)}
                    //             />
                    //           </div>
                    //           <div className='d-flex flex-column justify-content-center ms-2'>
                    //             <button
                    //               onClick={() =>
                    //                 openUploadImage('ownerPicture')
                    //               }
                    //               className=' btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-edit'></i>
                    //             </button>
                    //             {/* &nbsp; */}
                    //             <button
                    //               onClick={() => removeImage('ownerPicture')}
                    //               className='btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-trash'></i>
                    //             </button>
                    //           </div>
                    //         </div>
                    //         <p>Shop Owner Picture</p>
                    //       </Col>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group d-flex '>
                    //           <div>
                    //             <img
                    //               style={{ border: '1px solid #ccc' }}
                    //               alt='not fount'
                    //               width={'200px'}
                    //               height={'125px'}
                    //               src={
                    //                 modalValues?.cnicFront || PlaceholderImage
                    //               }
                    //             />
                    //           </div>
                    //           <div className='d-flex flex-column justify-content-center ms-2'>
                    //             <button
                    //               onClick={() => openUploadImage('cnicFront')}
                    //               className=' btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-edit'></i>
                    //             </button>
                    //             {/* &nbsp; */}
                    //             <button
                    //               onClick={() => removeImage('cnicFront')}
                    //               className='btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-trash'></i>
                    //             </button>
                    //           </div>
                    //         </div>
                    //         <p>Shop Owner Cnic Front </p>
                    //       </Col>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group d-flex '>
                    //           <div>
                    //             <img
                    //               style={{ border: '1px solid #ccc' }}
                    //               alt='not fount'
                    //               width={'200px'}
                    //               height={'125px'}
                    //               src={
                    //                 modalValues?.cnicBack || PlaceholderImage
                    //               }
                    //             />
                    //           </div>
                    //           <div className='d-flex flex-column justify-content-center ms-2'>
                    //             <button
                    //               onClick={() => openUploadImage('cnicBack')}
                    //               className=' btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-edit'></i>
                    //             </button>
                    //             {/* &nbsp; */}
                    //             <button
                    //               onClick={() => removeImage('cnicBack')}
                    //               className='btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-trash'></i>
                    //             </button>
                    //           </div>
                    //         </div>
                    //         <p>Shop Owner Cnic Back </p>
                    //       </Col>
                    //     </div>
                    //   </Row>
                    //   <Row>
                    //     <Col>
                    //       <div
                    //         className='card-header'
                    //         style={{ marginTop: '20px' }}
                    //       >
                    //         <h5>Shop Details:</h5>
                    //       </div>
                    //     </Col>
                    //   </Row>
                    //   <Row>
                    //     <div className='d-flex  mx-2 '>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='shopName'
                    //             label='Shop Name'
                    //             name='shopName'
                    //             handleOnChange={(e) =>
                    //               (modalValues.shopName = e)
                    //             }
                    //             value={modalValues.shopName}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={3} md={6}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='shopAddress'
                    //             label='Shop Address'
                    //             name='shopAddress'
                    //             handleOnChange={(e) =>
                    //               (modalValues.shopAddress = e)
                    //             }
                    //             value={modalValues.shopAddress}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='ownershipType'
                    //             label='Shop Ownership'
                    //             name='ownershipType'
                    //             handleOnChange={(e) =>
                    //               (modalValues.ownershipType = e)
                    //             }
                    //             value={modalValues.ownershipType}
                    //           />
                    //         </div>
                    //       </Col>
                    //     </div>
                    //   </Row>
                    //   <Row>
                    //     <div className='d-flex  mx-2 '>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='shopHeight'
                    //             label='Shop Height'
                    //             name='shopHeight'
                    //             handleOnChange={(e) =>
                    //               (modalValues.shopHeight = e)
                    //             }
                    //             value={modalValues.shopHeight}
                    //           />
                    //         </div>
                    //       </Col>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='shopWidth'
                    //             label='Shop Width'
                    //             name='shopWidth'
                    //             handleOnChange={(e) =>
                    //               (modalValues.shopWidth = e)
                    //             }
                    //             value={modalValues.shopWidth}
                    //           />
                    //         </div>
                    //       </Col>
                    //     </div>
                    //   </Row>
                    //   <Row>
                    //     <div className='d-flex  mx-2 '>
                    //       <Col xs={3} md={3}>
                    //         <div className='form-group d-flex '>
                    //           <div>
                    //             <img
                    //               style={{ border: '1px solid #ccc' }}
                    //               alt='not fount'
                    //               width={'300px'}
                    //               height={'200px'}
                    //               src={
                    //                 modalValues?.shopPicture || PlaceholderImage
                    //               }
                    //             />
                    //           </div>
                    //           <div className='d-flex flex-column justify-content-center ms-2'>
                    //             <button
                    //               onClick={() => openUploadImage('shopPicture')}
                    //               className=' btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-edit'></i>
                    //             </button>
                    //             {/* &nbsp; */}
                    //             <button
                    //               onClick={() => removeImage('shopPicture')}
                    //               className='btn btn-sm m-0'
                    //             >
                    //               <i className='fas fa-trash'></i>
                    //             </button>
                    //           </div>
                    //         </div>
                    //         <p>Shop Picture </p>
                    //       </Col>
                    //     </div>
                    //   </Row>
                    //   <Row>
                    //     <div className='d-flex  mx-2 '>
                    //       <Col xs={6} md={6}>
                    //         <div className='form-group'>
                    //           <FormikControl
                    //             formik={formik}
                    //             control='input'
                    //             type='remarks'
                    //             label='Remarks'
                    //             name='remarks'
                    //             handleOnChange={(e) =>
                    //               (modalValues.remarks = e)
                    //             }
                    //             value={modalValues.remarks}
                    //           />
                    //         </div>
                    //       </Col>
                    //     </div>
                    //   </Row>
                    // </>
                    <>
                      <Row>
                        <Col xs={12}>
                          <div className='d-flex justify-content-between mx-4 mt-4'>
                            <p>
                              <b>Date : </b>
                              <span style={{ textDecoration: 'underline' }}>
                                <Moment format='DD/MM/YYYY'>
                                  {selectedKyc?.date}
                                </Moment>
                              </span>
                            </p>
                            <p>
                              <b>Viz ID : </b>
                              <span style={{ textDecoration: 'underline' }}>
                                {selectedKyc?.vizshopCode}
                              </span>
                            </p>
                            <p>
                              <b>Shop Code : </b>
                              <span style={{ textDecoration: 'underline' }}>
                                {selectedKyc?.distshopCode}
                              </span>
                            </p>
                            <p>
                              <b>Form Status : </b>
                              <span style={{ textDecoration: 'underline' }}>
                                {selectedKyc?.statusValue}
                              </span>
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='card-header'>
                            <h5>
                              <b>Profile Information:</b>
                            </h5>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={12}>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Shop Owner Name</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='ownerName'
                                    // label='Shop Owner Name'
                                    name='ownerName'
                                    handleOnChange={(e) =>
                                      (modalValues.ownerName = e)
                                    }
                                    value={modalValues.ownerName}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Father's Name</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='fatherName'
                                    // label="Father's Name"
                                    name='fatherName'
                                    handleOnChange={(e) =>
                                      (modalValues.fatherName = e)
                                    }
                                    value={modalValues.fatherName}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Mother's Name</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='motherName'
                                    // label="Mother's Name"
                                    name='motherName'
                                    handleOnChange={(e) =>
                                      (modalValues.motherName = e)
                                    }
                                    value={modalValues.motherName}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Date of Birth</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='date'
                                    // label='Date of Birth'
                                    name='dateOfBirth'
                                    handleOnChange={(value) => {
                                      modalValues.dateOfBirth = value
                                    }}
                                    value={modalValues.dateOfBirth}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>CNIC No.</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='cnic'
                                    // label='CNIC No.'
                                    name='cnic'
                                    handleOnChange={(e) =>
                                      (modalValues.cnic = e)
                                    }
                                    value={modalValues.cnic}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>CNIC Issuance Date</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='date'
                                    // label='CNIC Issuance Date'
                                    name='cnicIssuanceDate'
                                    handleOnChange={(value) => {
                                      modalValues.cnicIssuanceDate = value
                                    }}
                                    value={modalValues.cnicIssuanceDate}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>CNIC Expiry Date</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='date'
                                    // label='CNIC Expiry Date'
                                    name='cnicExpiryDate'
                                    handleOnChange={(value) => {
                                      modalValues.cnicExpiryDate = value
                                    }}
                                    value={modalValues.cnicExpiryDate}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                        </Col>
                        <Col md={6} sm={12}>
                          <Row className='d-flex justify-content-end'>
                            <Col xs={5}>
                              <div className='form-group  '>
                                <div className='d-flex flex-column align-items-center'>
                                  <p>Shop Owner Picture</p>
                                  <img
                                    className='kyc-form-img'
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not found'
                                    width={'125px'}
                                    height={'125px'}
                                    src={
                                      modalValues?.ownerPicture ||
                                      PlaceholderImage
                                    }
                                  />
                                  <input
                                    ref={imgUploadRef}
                                    hidden
                                    type='file'
                                    name='imageFile'
                                    accept='image/*'
                                    onChange={(event) => saveImage(event)}
                                  />
                                </div>
                                <div className='d-flex  justify-content-center ms-2'>
                                  <button
                                    onClick={() =>
                                      openUploadImage('ownerPicture')
                                    }
                                    className=' btn btn-sm m-0'
                                  >
                                    <i className='fas fa-edit'></i>
                                  </button>
                                  {/* &nbsp; */}
                                  <button
                                    onClick={() => removeImage('ownerPicture')}
                                    className='btn btn-sm m-0'
                                  >
                                    <i className='fas fa-trash'></i>
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className='d-flex justify-content-end'>
                            <Col xs={5}>
                              <div className='form-group  '>
                                <div className='d-flex flex-column align-items-center'>
                                  <p>Shop Owner Cnic Front</p>
                                  <img
                                    className='kyc-form-img'
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'200px'}
                                    height={'125px'}
                                    src={
                                      modalValues?.cnicFront || PlaceholderImage
                                    }
                                  />
                                </div>
                                <div className='d-flex  justify-content-center ms-2'>
                                  <button
                                    onClick={() => openUploadImage('cnicFront')}
                                    className=' btn btn-sm m-0'
                                  >
                                    <i className='fas fa-edit'></i>
                                  </button>
                                  {/* &nbsp; */}
                                  <button
                                    onClick={() => removeImage('cnicFront')}
                                    className='btn btn-sm m-0'
                                  >
                                    <i className='fas fa-trash'></i>
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className='d-flex justify-content-end'>
                            <Col xs={5}>
                              <div className='form-group  '>
                                <div className='d-flex flex-column align-items-center'>
                                  <p>Shop Owner Cnic Back</p>
                                  <img
                                    className='kyc-form-img'
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'200px'}
                                    height={'125px'}
                                    src={
                                      modalValues?.cnicBack || PlaceholderImage
                                    }
                                  />
                                </div>
                                <div className='d-flex  justify-content-center ms-2'>
                                  <button
                                    onClick={() => openUploadImage('cnicBack')}
                                    className=' btn btn-sm m-0'
                                  >
                                    <i className='fas fa-edit'></i>
                                  </button>
                                  {/* &nbsp; */}
                                  <button
                                    onClick={() => removeImage('cnicBack')}
                                    className='btn btn-sm m-0'
                                  >
                                    <i className='fas fa-trash'></i>
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='card-header'>
                            <h5>
                              <b>Shop Details:</b>
                            </h5>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={12}>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Shop Name</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='shopName'
                                    // label='Shop Name'
                                    name='shopName'
                                    handleOnChange={(e) =>
                                      (modalValues.shopName = e)
                                    }
                                    value={modalValues.shopName}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Shop Address</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='shopAddress'
                                    // label='Shop Address'
                                    name='shopAddress'
                                    handleOnChange={(e) =>
                                      (modalValues.shopAddress = e)
                                    }
                                    value={modalValues.shopAddress}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Shop Ownership</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='ownershipType'
                                    // label='Shop Ownership'
                                    name='ownershipType'
                                    handleOnChange={(e) =>
                                      (modalValues.ownershipType = e)
                                    }
                                    value={modalValues.ownershipType}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Shop Height</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='shopHeight'
                                    // label='Shop Height'
                                    name='shopHeight'
                                    handleOnChange={(e) =>
                                      (modalValues.shopHeight = e)
                                    }
                                    value={modalValues.shopHeight}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Shop Width</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='shopWidth'
                                    // label='Shop Width'
                                    name='shopWidth'
                                    handleOnChange={(e) =>
                                      (modalValues.shopWidth = e)
                                    }
                                    value={modalValues.shopWidth}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <div className='d-flex justify-content-between mx-2 align-items-center'>
                              <Col xs={5}>
                                <b>Remarks</b>
                              </Col>
                              <Col xs={7}>
                                <div className='form-group'>
                                  <FormikControl
                                    formik={formik}
                                    control='input'
                                    type='remarks'
                                    // label='Remarks'
                                    name='remarks'
                                    handleOnChange={(e) =>
                                      (modalValues.remarks = e)
                                    }
                                    value={modalValues.remarks}
                                  />
                                </div>
                              </Col>
                            </div>
                          </Row>
                        </Col>
                        <Col md={6} sm={12}>
                          <Row className='d-flex justify-content-end'>
                            <Col xs={5}>
                              <div className='form-group  '>
                                <div className='d-flex flex-column align-items-center'>
                                  <p>Shop Picture</p>
                                  <img
                                    className='kyc-form-img'
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'200px'}
                                    height={'125px'}
                                    src={
                                      modalValues?.shopPicture ||
                                      PlaceholderImage
                                    }
                                  />
                                </div>
                                <div className='d-flex  justify-content-center ms-2'>
                                  <button
                                    onClick={() =>
                                      openUploadImage('shopPicture')
                                    }
                                    className=' btn btn-sm m-0'
                                  >
                                    <i className='fas fa-edit'></i>
                                  </button>
                                  {/* &nbsp; */}
                                  <button
                                    onClick={() => removeImage('shopPicture')}
                                    className='btn btn-sm m-0'
                                  >
                                    <i className='fas fa-trash'></i>
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className='d-flex justify-content-end'>
                            <Col xs={5}>
                              <div className='form-group  '>
                                <div className='d-flex flex-column align-items-center'>
                                  <p>Shop Picture Right</p>
                                  <img
                                    className='kyc-form-img'
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'200px'}
                                    height={'125px'}
                                    src={
                                      modalValues?.shopPictureRight ||
                                      PlaceholderImage
                                    }
                                  />
                                </div>
                                <div className='d-flex  justify-content-center ms-2'>
                                  <button
                                    onClick={() =>
                                      openUploadImage('shopPictureRight')
                                    }
                                    className=' btn btn-sm m-0'
                                  >
                                    <i className='fas fa-edit'></i>
                                  </button>
                                  {/* &nbsp; */}
                                  <button
                                    onClick={() =>
                                      removeImage('shopPictureRight')
                                    }
                                    className='btn btn-sm m-0'
                                  >
                                    <i className='fas fa-trash'></i>
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>{' '}
                          <Row className='d-flex justify-content-end'>
                            <Col xs={5}>
                              <div className='form-group  '>
                                <div className='d-flex flex-column align-items-center'>
                                  <p>Shop Picture Left</p>
                                  <img
                                    className='kyc-form-img'
                                    style={{ border: '1px solid #ccc' }}
                                    alt='not fount'
                                    width={'200px'}
                                    height={'125px'}
                                    src={
                                      modalValues?.shopPictureLeft ||
                                      PlaceholderImage
                                    }
                                  />
                                </div>
                                <div className='d-flex  justify-content-center ms-2'>
                                  <button
                                    onClick={() =>
                                      openUploadImage('shopPictureLeft')
                                    }
                                    className=' btn btn-sm m-0'
                                  >
                                    <i className='fas fa-edit'></i>
                                  </button>
                                  {/* &nbsp; */}
                                  <button
                                    onClick={() =>
                                      removeImage('shopPictureLeft')
                                    }
                                    className='btn btn-sm m-0'
                                  >
                                    <i className='fas fa-trash'></i>
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </Modal.Body>
                {selectedKyc?.status != 6 && !isDownload && (
                  <Modal.Footer className='d-flex justify-content-center'>
                    <div>
                      <Button
                        className='btn btn-primary mx-4'
                        onClick={() => sendToFinja(formik.values)}
                      >
                        Send To Finja
                      </Button>
                    </div>
                  </Modal.Footer>
                )}
              </Form>
            )}
          </Formik>
        </Modal>
      </VzPageContainer>
    </>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(KYCFinalized)
