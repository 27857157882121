import axios from '../../../apis/Api'
import history, { logout } from '../../../history'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  ADD_MFI_DETAILS,
  GET_MFI_DETAILS,
  DELETE_MFI_DETAILS,
  UPDATE_MFI_DETAILS,
  GET_MFI_DETAILSLIST,
} from '../../../Types'

export const addMfiDetails = (formValues) => async (dispatch, getState) => {
  dispatch(showLoading())
  await axios
    .post('api/MfiMerchant', formValues)
    .then(({ data }) => {
      formValues.id = data.response
      formValues.mfiName = getState().MFIs?.find(
        (x) => x.id === formValues.mfiId
      )?.mfiName
      dispatch({
        type: ADD_MFI_DETAILS,
        payload: formValues,
      })

      dispatch(hideLoading())
      toast.success('Record added successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response)
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
    })
}

export const getMfiDetails = (id) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(`api/MfiMerchant/${id}`)
    .then(({ data }) => {
      if (data && data.length > 0)
        dispatch({
          type: GET_MFI_DETAILS,
          payload: data,
        })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const updateMfiDetails =
  (id, formValues) => async (dispatch, getState) => {
    dispatch(showLoading())
    await axios
      .put(`api/MfiMerchant/${id}`, formValues)
      .then(({ data }) => {
        formValues.mfiName = getState().MFIs?.find(
          (x) => x.id === formValues.mfiId
        )?.mfiName
        dispatch({
          type: UPDATE_MFI_DETAILS,
          payload: formValues,
        })

        dispatch(hideLoading())
        toast.success('Record updated successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }

export const deleteMfiDetails = (id) => async (dispatch) => {
  if (!id) return
  dispatch(showLoading())
  await axios
    .delete(`api/MfiMerchant/${id}`)
    .then(() => {
      dispatch({ type: DELETE_MFI_DETAILS, payload: id })
      dispatch(hideLoading())
      toast.success('Record deleted successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const getMfiDetailsList = (distributorId) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .get(
      `api/MfiMerchant/GetDistributionMfiDetailsByDistributor/${distributorId}`
    )
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_MFI_DETAILSLIST,
        payload: data.response,
      })
      dispatch(hideLoading())
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response)
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
    })
}
