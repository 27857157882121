import {
  GET_USERS,
  ADD_USERS,
  UPDATE_USERS,
  DELETE_USERS,
  RESET_USERS,
  UPDATE_USER_ROLE,
  UPDATE_USER_ACTIVE_STATUS,
} from '../../Types'
import _ from 'lodash'

const ManageUsersReducer = (state = [], action) => {
  switch (action.type) {
    case GET_USERS:
      return [...action.payload]
    case ADD_USERS:
      return [action.payload, ...state]
    case UPDATE_USERS:
      return state.splice(
        _.findIndex(state, { id: action.payload.id }),
        1,
        ...action.payload
      )
    case UPDATE_USER_ROLE:
      let users = [...state]
      let index = users.findIndex((x) => x.id == action.payload.userId)
      users[index].userRoleId = action.payload.roleId
      users[index].userRoleName = action.payload.roleName
      return users
    case UPDATE_USER_ACTIVE_STATUS:
      let userList = [...state]
      let statusIndex = userList.findIndex(
        (u) => u.userName == action.payload.username
      )
      userList[statusIndex].isActive = action.payload.status
      return userList
    case DELETE_USERS:
      return state.filter((e) => e.id !== action.payload)
    case RESET_USERS:
      return [...state]
    default:
      return state
  }
}
export default ManageUsersReducer
