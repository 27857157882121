import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'
import storage from '../../../../apis/Storage'
import {
  ADD_USER_ROLE,
  DELETE_USER_ROLE,
  GET_USER_ROLES_LIST,
  UPDATE_USER_ROLE_
} from '../../../../Types'
import { toast } from 'react-toastify'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

export const getUserRoles = () => async (dispatch) => {
  await axios
    .get(`api/UserRoles`)
    .then(({ data }) => {
      // if (data && data.length > 0)
      dispatch({
        type: GET_USER_ROLES_LIST,
        payload: data.response,
      })
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}

export const addUserRole = (formValues) => async (dispatch) => {
  dispatch(showLoading())
  await axios
    .post('api/UserRoles', formValues)
    .then(({ data }) => {
      if (data.response)
        dispatch({
          type: ADD_USER_ROLE,
          payload: data.response,
        })
      dispatch(hideLoading())
      toast.success('Role added successfully')
    })
    .catch(function (error) {
      dispatch(hideLoading())
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (
          error.response.status === 400 &&
          error.response.data.errors
        ) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data)
          toast.error(
            error.response.data.Message
              ? error.response.data.Message
              : error.response.data.message
          )
        else {
          toast.error('Error while processing the request')
        }
      } else toast.error('Error while processing the request')
    })
}


export const updateUserRole =
  (id, formValues) => async (dispatch, getState) => {
    dispatch(showLoading())
    await axios
      .put(`api/UserRoles/${id}`, formValues)
      .then(({ data }) => {
        formValues.principalName = getState().Principals?.find(
          (x) => x.id === formValues.principalId
        )?.principalName
        dispatch(hideLoading())
        toast.success('Record updated successfully')
      })
      .catch(function (error) {
        dispatch(hideLoading())
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }


export const deleteUserRole = (id) => async (dispatch) => {
  const config = {
    headers: { Authorization: `Bearer ${storage.get('_appToken')}` },
  }

  await axios
    .delete(`api/UserRoles/${id}`, config)
    .then(({ data }) => {
      dispatch(hideLoading())
      if (data.isSuccess) {
          toast.success(data.message)
          dispatch({ type: DELETE_USER_ROLE, payload: id })
      }
      else {
          toast.error(data.message)
      }
  })
    .catch(function (error) {
      dispatch(hideLoading())
      toast.error('Error: ' + error)
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    })
}
