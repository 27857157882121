import { GET_DISTRIBUTOR_PRODUCT_CONFIG } from '../../../Types'

const ProductConfigurationReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DISTRIBUTOR_PRODUCT_CONFIG:
      return [...action.payload]

    default:
      return state
  }
}
export default ProductConfigurationReducer
