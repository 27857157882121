import axios from "../../apis/Api";
import history, { logout } from "../../history";
import { ADD_PRINCIPALS, GET_PRINCIPALS } from "../../Types";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const addPrincipals = (formValues) => async (dispatch) => {
  dispatch(showLoading());
  await axios
    .post("api/Principal", formValues)
    .then(({ data }) => {
      dispatch({
        type: ADD_PRINCIPALS,
        payload: data.response,
      });
      dispatch(hideLoading());
      toast.success("Record added successfully");
    })
    .catch(function (error) {
      dispatch(hideLoading());
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    });
};

export const getPrincipals = () => async (dispatch) => {
  dispatch(showLoading());
  await axios
    .get(`api/Principal`)
    .then(({ data }) => {
      if (data.length > 0) {
        dispatch({
          type: GET_PRINCIPALS,
          payload: data,
        });
      }
      dispatch(hideLoading());
    })
    .catch(function (error) {
      dispatch(hideLoading());
      
      if (error.response) {
        if (error.response.status === 401) {
          logout()
          history.push('/login')
          history.go('/login')
        } else if (error.response.status === 400 && error.response.data.errors) {
          let errorMessage = ''
          var errors = error.response.data.errors
          var keys = Object.keys(errors)
          for (let j = 0; j < keys.length; j++) {
            errorMessage += errors[keys[j]] + ' , \n'
          }
          toast.error(errorMessage)
        } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
        else {
          toast.error('Error while processing the request')
        }
      }
    });
};
