import { combineReducers } from 'redux'
import authReducer from '../components/Login/AuthReducer'
import mainInformationsReducer from '../components/Distributors/MainInformations/MainInformationsReducer'
import distributorPrincipals from '../components/Distributors/ManagePrincipals/DistributorPrincipalReducer'
import bankDetailsReducer from '../components/Distributors/BankDetails/BankDetailsReducer'
import distributionCentersReducer from '../components/Distributors/DistributionCenters/DistributionCentersReducer'
import mfiDetailsReducer from '../components/Distributors/MFIDetails/MFIDetailsReducer'
import diUsersReducer from '../components/Distributors/ManageUsers/DIUsersReducer'
import QRorTillDetailsReducer from '../components/Distributors/QRorTillDetails/QRorTillDetailsReducer'
import JobsListReducer from '../components/VizlinkShops/JobsList/JobsListReducer'
import JobDetailsReducer from '../components/VizlinkShops/JobsList/JobDetails/JobDetailsReducer'
import managePrincipalsReducer from '../components/Principals/ManagePrincipalsReducer'
import listofDistributorReducer from '../components/Distributors/DistributorsList/ListofDistributorReducer'
import MFIReducer from '../components/MFIs/MFIReducer'
import ddlListReducer from '../reducers/ddlListReducer'
import ShopsListReducer from '../components/VizlinkShops/VizShopsList/VizShopsListReducer'
import OnBoardingShopsReducer from '../components/VizlinkShops/OnBoardingShops/OnBoardingShopsReducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
import AgentsListReducer from '../components/VizlinkAgents/AgentsList/AgentsListReducer'
import ManageUsersReducer from '../components/Users/ManageUsersReducer'
import NotificationSchedulerReducer from '../components/VizlinkShops/NotificationScheduler/NotificationSchedulerReducer'
import ManageDailyOrdersReducer from '../components/DailyOrders/ManageDailyOrders/ManageDailyOrdersReducer'
import PaymentStatusReportReducer from '../components/DailyOrders/PaymentStatusReport/PaymentStatusReportReducer'
import OrderShopDetailsReducer from '../components/DailyOrders/PaymentStatusReport/OrderShopDetails/OrderShopDetailsReducer'
import ManageOrderDetailsReducer from '../components/DailyOrders/ManageDailyOrders/ManageOrderDetails/ManageOrderDetailsReducer'
import OnBoardingAgentsListReducer from '../components/VizlinkAgents/OnBoardingAgentsList/OnBoardingAgentsListReducer'
import PagesReducer from '../components/Settings/Permissions/Pages/PagesReducer'
import UserRolesReducer from '../components/Settings/Permissions/UserRoles/UserRolesReducer'
import UserRolePermissionReducer from '../components/Settings/Permissions/UserRolePermissions/UserRolePermissionReducer'
import PageActionsReducer from '../components/Settings/Permissions/PageActions/PageActionsReducer'
import DayWiseDailyTransactionsSummaryReducer from '../components/Reports/DailyTransactionsSummary/DayWiseDailyTransactionsSummary/DayWiseDailyTransactionsSummaryReducer'
import MonthWiseDailyTransactionsSummaryReducer from '../components/Reports/DailyTransactionsSummary/MonthlyDailyTransactionsSummary/MonthlyDailyTransactionsSummaryReducer'
import CurrentShopwiseTransactionsDetailsReducer from '../components/Reports/ShopwiseTransactionsDetails/CurrentShopwiseTransactionsDetails/CurrentShopwiseTransactionsDetailsReducer'
import CurrentDigitalCollectionsReportReducer from '../components/Reports/DigitalCollectionsReport/CurrentDigitalCollectionsReport/CurrentDigitalCollectionsReportReducer'
import MonthlyDigitalCollectionsReportReducer from '../components/Reports/DigitalCollectionsReport/MonthlyDigitalCollectionsReport/MonthlyDigitalCollectionsReportReducer'
import RsoDetailsReportReducer from '../components/Reports/RsoDetailsReport/RsoDetailsReportReducer'
import RsoPerformanceReportReducer from '../components/Reports/RsoPerformanceReport/RsoPerformanceReportReducer'
import QrTillListReducer from '../components/QrTillDetails/QrTillList/QrTillListReducer'
import DeliverymanUserRegistrationReducer from '../components/QrTillDetails/DeliverymanUserRegistration/DeliverymanUserRegistrationReducer'
import TillAssignmentFormReducer from '../components/QrTillDetails/TillAssignmentForm/TillAssignmentFormReducer'
import VersionsReducer from '../components/Settings/Versions/VersionsReducer'
import UserActivityReducer from '../components/Settings/UserActivity/UserActivityReducer'
import SectorsReducer from '../components/VizlinkShops/Sectors/SectorsReducer'
import NotificationsReducer from '../components/Settings/Notifications/NotificationsReducer'
import SocketReducer from '../layouts/Socket/SocketReducer'
import DailyCollectionsReportReducer from '../components/QrTillDetails/DailyCollectionsReport/DailyCollectionsReportReducer'
import SchemesListReducer from '../components/VlLoyaltyProgram/SchemesList/SchemesListReducer'
import CoinsHistoryReportReducer from '../components/VlLoyaltyProgram/CoinsHistoryReport/CoinsHistoryReportReducer'
import ShopWisePulseReportReducer from '../components/Reports/AppPulse/ShopWisePulseReport/ShopWisePulseReportReducer'
import DateWiseShopSummaryReportReducer from '../components/Reports/AppPulse/DateWiseShopsSummaryReport/DateWiseShopsSummaryReportReducer'
import TillNumberDetailsReducer from '../components/QrTillDetails/TillNumberDetails/TillNumberDetailsReducer'
import DistCoinsDisbursementReportReducer from '../components/VlLoyaltyProgram/DistributorConisDisbursmentReport/DistributorConisDisbursmentReportReducer'
import DeliveryManCoinsPaymentReportReducer from '../components/VlLoyaltyProgram/DeliveryManCoinsPaymentReport/DeliveryManCoinsPaymentReportReducer'
import DistributorCoinsPaymentReportReducer from '../components/VlLoyaltyProgram/DistributorCoinsPaymentReport/DistributorCoinsPaymentReportReducer'
import LoanDisbursementReportReducer from '../components/LoanPayments/LoanDisbursementReport/LoanDisbursementReportReducer'
import FinjaLoanReport from '../components/Reports/FinjaLoanReport/FinjaLoanReportReducer'
import FinjaLoanReportReducer from '../components/Reports/FinjaLoanReport/FinjaLoanReportReducer'
import ProductConfigurationReducer from '../components/Distributors/ProductConfiguration/ProductConfigurationReducer'
import VLCCommunicationListingReducer from '../components/VLConnect/VLCShopkeeperTickets/VLCCommunicationListingReducer'
import ConnectConversationReducer from '../components/VLConnect/ConnectConversation/ConnectConversationReducer'
import DistributorInboxReducer from '../components/VLConnect/DistributorInbox/DistributorInboxReducer'
import DLMCollectionReportReducer from '../components/DailyOrders/DLMCollectionReport/DLMCollectionReportReducer'
import InvoiceWiseDailyCollectionDetailsReducer from '../components/M2DReorts/InvoiceWiseDailyCollectionDetails/InvoiceWiseDailyCollectionDetailsReducer'
import InvoiceWisePaymentBreakupReducer from '../components/M2DReorts/InvoiceWisePaymentBreakup/InvoiceWisePaymentBreakupReducer'
import AdvertisementsReducer from '../components/Advertisements/AdvertisementsReducer'
import SectorsBasedMapReducer from '../components/VizlinkShops/SectorBasedReport/SectorBasedReportReducer'

import MonthWiseM2DRportReducer from '../components/M2DReorts/monthWiseM2DReport/monthWiseM2DReportReducer'
import ShopsDetailReportReducer from '../components/Reports/ShopsDetailReport/ShopsDetailReportReducer'
import SCRDetailReportReducer from '../components/DailyOrders/SCRDetailReport/SCRDetailReportReducer'

export default combineReducers({
  loadingBar: loadingBarReducer,
  auth: authReducer,
  distributorMainInfo: mainInformationsReducer,
  distributorPrincipals: distributorPrincipals,
  bankDetails: bankDetailsReducer,
  distributionCenters: distributionCentersReducer,
  qrOrTillDetails: QRorTillDetailsReducer,
  mfiDetails: mfiDetailsReducer,
  diUsers: diUsersReducer,
  Principals: managePrincipalsReducer,
  MFIs: MFIReducer,
  listofDistributors: listofDistributorReducer,
  jobsList: JobsListReducer,
  jobDetails: JobDetailsReducer,
  ddlList: ddlListReducer,
  vizShopsList: ShopsListReducer,
  rsoJobsList: OnBoardingShopsReducer,
  AgentsList: AgentsListReducer,
  Users: ManageUsersReducer,
  NotificationScheduler: NotificationSchedulerReducer,
  ManageDailyOrders: ManageDailyOrdersReducer,
  PaymentStatusReport: PaymentStatusReportReducer,
  ShopOrder: OrderShopDetailsReducer,
  ManageOrderDetails: ManageOrderDetailsReducer,
  OnBoardingAgentsList: OnBoardingAgentsListReducer,
  Pages: PagesReducer,
  UserRoles: UserRolesReducer,
  UserRolePermission: UserRolePermissionReducer,
  PageActions: PageActionsReducer,
  DayWiseDailyTransactionsSummary: DayWiseDailyTransactionsSummaryReducer,
  MonthWiseDailyTransactionsSummary: MonthWiseDailyTransactionsSummaryReducer,
  CurrentShopwiseTransactionsDetails: CurrentShopwiseTransactionsDetailsReducer,
  CurrentDigitalCollectionsReport: CurrentDigitalCollectionsReportReducer,
  MonthlyDigitalCollectionsReport: MonthlyDigitalCollectionsReportReducer,
  RsoDetailsReport: RsoDetailsReportReducer,
  RsoPerformanceReport: RsoPerformanceReportReducer,
  QrTillList: QrTillListReducer,
  DeliverymanUserRegistration: DeliverymanUserRegistrationReducer,
  TillAssignmentForm: TillAssignmentFormReducer,
  Versions: VersionsReducer,
  UserActivity: UserActivityReducer,
  Sectors: SectorsReducer,
  Notifications: NotificationsReducer,
  Socket: SocketReducer,
  DailyCollectionsReport: DailyCollectionsReportReducer,
  SchemesList: SchemesListReducer,
  CoinsHistoryReport: CoinsHistoryReportReducer,
  ShopWisePulseReport: ShopWisePulseReportReducer,
  DateWiseShopSummaryReport: DateWiseShopSummaryReportReducer,
  TillNumberDetails: TillNumberDetailsReducer,
  DistCoinsDisbursementReport: DistCoinsDisbursementReportReducer,
  DeliverymanCoinsPaymentReport: DeliveryManCoinsPaymentReportReducer,
  DistributorCoinsPaymentReport: DistributorCoinsPaymentReportReducer,
  LoanDisbursementReport: LoanDisbursementReportReducer,
  FinjaLoanReport: FinjaLoanReportReducer,
  ProductConfiguration: ProductConfigurationReducer,
  VLCCommunicationListing: VLCCommunicationListingReducer,
  ConnectConversation: ConnectConversationReducer,
  DistributorInbox: DistributorInboxReducer,
  DLMCollectionReport: DLMCollectionReportReducer,
  InvoiceWiseDailyCollectionDetails: InvoiceWiseDailyCollectionDetailsReducer,
  Advertisements: AdvertisementsReducer,
  InvoiceWisePaymentBreakup: InvoiceWisePaymentBreakupReducer,
  SectorsBasedMap: SectorsBasedMapReducer,
  Advertisements: AdvertisementsReducer,
  MonthWiseM2DRport: MonthWiseM2DRportReducer,
  ShopsDetailReport: ShopsDetailReportReducer,
  SCRDetailReport: SCRDetailReportReducer
})
