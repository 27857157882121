import { connect } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { Col, Row, Overlay, Button, Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import FormikControl from '../../../../layouts/Input/FormikControl'
import {
  getMonthWiseDailyTransactionsSummary,
  resetMonthWiseDailyTransactionsSummary,
  exportReport,
} from './MonthlyDailyTransactionsSummaryActions'
import ServerSideDataTable from '../../../../layouts/DataTables/ServerSideDataTable/ServerSideDataTable'
import {
  GET_DISTRIBUTOR_DDL,
  GET_ALL_DISTRIBUTION_CENTERS_DDL,
} from '../../../../Types'
import { getDropdownList } from '../../../Shared/dropdownListActions'
import storage from '../../../../apis/Storage';
import { isPermittedPage } from '../../../../apis/Utility'

const MonthlyDailyTransactionsSummary = (props) => {
  //Pagination
  const userRights = JSON.parse(storage.get('_userRights'))
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(5)
  const [totalRecords, setTotalRecords] = useState(0)
  const [searchText, setSearchText] = useState('')

  const [filterFromDate, setFilterFromDate] = useState(new Date())
  const [filterDistCenter, setFilterDistCenter] = useState(null)
  const [filterPaymentStatus, setFilterPaymentStatus] = useState(null)
  const [filterDistributor, setFilterDistributor] = useState(null)
  const [distributorsOptions, setDistributorsOptions] = useState(null)
  const [centerOptions, setCenterOptions] = useState(null)
  const [reportList, setReportList] = useState([])

  const [filterValuesModal, setFilterValuesModal] = useState({
    fromDate: '',
    distCenter: '',
    paymentStatus: '',
    distributor: '',
  })

  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const Columns = [
    {
      Header: 'Distributor',
      accessor: 'distributorName',
    },
    {
      Header: 'Distribution ID',
      accessor: 'distributioId',
    },
    {
      Header: 'Total Shops Transacted',
      accessor: 'totalShopTransacted',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Total Transactions',
      accessor: 'totalTransactions',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Total Volume Transacted',
      accessor: 'totalVolumesTransacted',
      Cell: (row) => <>{Number(row.value).toLocaleString()}</>,
    },
    {
      Header: 'Month',
      accessor: 'month',
    },
  ]

  const statusColumns = [
    {
      label: 'Paid',
      value: 'Paid',
    },
    {
      label: 'Pending',
      value: 'Pending',
    },
    {
      label: 'In Progress',
      value: 'InProgress',
    },
  ]

  const openFilterModal = (event) => {
    setShow(!show)
    setTarget(event.target)
  }
  const handleResetFilters = (resetForm) => {
    resetForm()
    setFilterFromDate(null)
    setFilterDistCenter(null)
    setFilterPaymentStatus(null)
    setFilterDistributor(null)
    setCenterOptions([])
    setFilterValuesModal({
      fromDate: '',
      distCenter: '',
      paymentStatus: '',
      distributor: '',
    })
  }

  const getData = (page) => {
    setCurrentPageNo(page)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: page,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      distributor: filterDistributor == null ? null : filterDistributor.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (filterDistributor == '' || filterDistributor == null) return
    props.getMonthWiseDailyTransactionsSummary(requestData)
  }

  const pageSizeChanged = (pageSize) => {
    setCurrentPageNo(0)
    setCurrentPageSize(pageSize)
    let requestData = {
      pageSize: pageSize,
      pageNo: 0,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      distributor: filterDistributor == null ? null : filterDistributor.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (filterDistributor == '' || filterDistributor == null) return
    props.getMonthWiseDailyTransactionsSummary(requestData)
  }

  const searchChanged = (text) => {
    setCurrentPageNo(0)
    setSearchText(text)
  }

  const LoadDataByfilter = (event) => {
    setCurrentPageNo(0)
    let requestData = {
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      distributor: filterDistributor == null ? null : filterDistributor.value,
      paymentStatus:
        event.paymentStatus == '' || event.paymentStatus == null
          ? null
          : event.paymentStatus.value == null
            ? event.paymentStatus
            : event.paymentStatus.value,
      fromDate:
        event.fromDate == ''
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(event.fromDate)
            )
          )
            .toISOString()
            .split('T')[0],

      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
    }
    if (event.distributor == '' || event.distributor == null) {
      setReportList([])
      setTotalRecords(0)
      return
    }
    props.getMonthWiseDailyTransactionsSummary(requestData)
    setShow(false)
    setFilterFromDate(event.fromDate == '' ? null : event.fromDate)
  }

  const Download = () => {
    let requestData = {
      pageSize: currentPageSize,
      pageNo: currentPageNo,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      distributor: filterDistributor == null ? null : filterDistributor.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (filterDistributor == '' || filterDistributor == null) return
    props.exportReport(requestData)
  }

  useEffect(() => {
    setCurrentPageNo(0)
    let requestData = {
      pageSize: currentPageSize,
      pageNo: 0,
      searchText: searchText,
      distributionId: filterDistCenter == null ? null : filterDistCenter.value,
      distributor: filterDistributor == null ? null : filterDistributor.value,
      paymentStatus:
        filterPaymentStatus == null ? null : filterPaymentStatus.value,
      fromDate:
        filterFromDate == null
          ? null
          : new Date(
            Date.parse(
              new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).format(filterFromDate)
            )
          )
            .toISOString()
            .split('T')[0],
    }
    if (filterDistributor == '' || filterDistributor == null) return
    props.getMonthWiseDailyTransactionsSummary(requestData)
  }, [searchText])

  useEffect(() => {
    if (props.Reports && props.Reports.length > 0) {
      setTotalRecords(props.Reports[0].totalRecords)
    } else {
      setTotalRecords(0)
    }
    setReportList(props.Reports)
  }, [props.Reports])

  useEffect(() => {
    props.getDropdownList(
      `api/Distributor/GetDistributorsDDL`,
      GET_DISTRIBUTOR_DDL
    )
    return () => {
      props.resetMonthWiseDailyTransactionsSummary()
    }
  }, [])

  useEffect(() => {
    if (userRights != null && userRights?.userType == 'DistributorUser') {
      var filteredDistList = props.allDistributorList.filter(d => d.value === userRights?.distributorId.toUpperCase());
      setDistributorsOptions(filteredDistList);
    }
    else {
      setDistributorsOptions(
        props.allDistributorList.map(function (item) {
          return {
            value: item.value,
            label: item.label,
          }
        })
      )
    }
  }, [props.allDistributorList])


    useEffect(() => {
        if (userRights != null && userRights?.userType == 'DistributorUser') {
            var assignedDistcenterIds = userRights.distCenterIds.split(',');
            let filteredItems = props.DistCentersList.filter(item => assignedDistcenterIds.includes(item.value));
            setCenterOptions(
                filteredItems.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                        principalName: item.principalName,
                        cityName: item.cityName,
                    }
                })
            )
        }
        else {
            setCenterOptions(
                props.DistCentersList.map(function (item) {
                    return {
                        value: item.value,
                        label: item.label,
                        principalName: item.principalName,
                        cityName: item.cityName,
                    }
                })
            )
        }
        }, [props.DistCentersList])

  useEffect(() => {
    setCenterOptions(null)
    if (filterDistributor) {
      props.getDropdownList(
        `api/DistCenter/GetDistCentersDDL/${filterDistributor.value}`,
        GET_ALL_DISTRIBUTION_CENTERS_DDL
      )
    }
  }, [filterDistributor])

  return (
    <div>
      <Row>
        <Col md={10}>
          <div ref={ref}>
            <Button
              onClick={openFilterModal}
              className='m-3'
              variant='outline-primary'
            >
              <i className='fa fa-filter' />
              Filters
            </Button>

            <Overlay
              show={show}
              target={target}
              placement='bottom'
              container={ref}
              containerPadding={20}
              width='430px'
              style={{ width: '430px' }}
            >
              <Popover
                id='popover-contained'
                style={{ maxWidth: '360px', width: '360px' }}
              >
                <Popover.Header as='h3'>Filters</Popover.Header>
                <Popover.Body>
                  <Formik
                    enableReinitialize
                    initialValues={filterValuesModal}
                    validator={() => ({})}
                    onSubmit={LoadDataByfilter}
                  >
                    {(formik) => (
                      <Form autoComplete='off'>
                        <Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distributor'
                                name='distributor'
                                options={distributorsOptions ?? []}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.distributor = option
                                  setFilterDistributor(option)
                                }}
                                value={filterValuesModal.distributor}
                              />
                            </Col>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Distribution ID'
                                name='distID'
                                options={centerOptions ?? []}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.distCenter = option
                                  setFilterDistCenter(option)
                                }}
                                value={filterValuesModal.distCenter}
                              />
                            </Col>
                          </Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                // formik={formik}
                                showMonthYearPicker={true}
                                control='date'
                                label='Month'
                                name='fromDate'
                                handleOnChange={(value) => {
                                  filterValuesModal.fromDate = value
                                  setFilterFromDate(value)
                                }}
                                value={filterValuesModal.fromDate}
                              />
                            </Col>
                          </Row>
                          <Row style={{ paddingRight: 0 }}>
                            <Col xs={12} md={12}>
                              <FormikControl
                                formik={formik}
                                control='react-select'
                                label='Payment Status'
                                name='paymentStatus'
                                options={statusColumns}
                                isClearable={true}
                                isSearchable={false}
                                isMulti={false}
                                handleOnChange={(option) => {
                                  filterValuesModal.paymentStatus = option
                                  setFilterPaymentStatus(option)
                                }}
                                value={filterValuesModal.paymentStatus}
                              />
                            </Col>
                          </Row>
                        </Row>
                        <div className='row' style={{ marginTop: 20 }}>
                          <div className='form-group'>
                            <Link
                              onClick={handleResetFilters.bind(
                                null,
                                formik.resetForm
                              )}
                              to='#'
                              className='active'
                              style={{
                                padding: '0px',
                                marginTop: '10px',
                                float: 'left',
                              }}
                            >
                              Reset
                            </Link>
                            <Link
                              onClick={() => {
                                setShow(false)
                                setTarget(null)
                              }}
                              to='#'
                              className='active'
                              style={{
                                padding: '0px',
                                marginTop: '10px',
                                float: 'right',
                              }}
                            >
                              Cancel
                            </Link>
                            <div className='float-right'>
                              <Button
                                type='submit'
                                className='btn btn-primary'
                                style={{
                                  marginRight: '10px',
                                  float: 'right',
                                }}
                              >
                                Apply
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Popover.Body>
              </Popover>
            </Overlay>
          </div>
        </Col>
        {isPermittedPage('Export', props.actions, props.userRole) &&
        <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={Download} className='m-3' variant='outline-primary'>
            <i className='fa fa-download me-1' />
            Export
          </Button>
        </Col>
        }
      </Row>
      <Row>
        <Col xs={12}>
          <ServerSideDataTable
            data={reportList}
            columns={Columns}
            getTrProps={(event, data) => null}
            goToPreviousPage={(val) => getData(val)}
            goToNextPage={(val) => getData(val)}
            totalRecords={totalRecords}
            pageSizeChange={(size) => pageSizeChanged(size)}
            myPageSize={currentPageSize}
            myIndex={currentIndex}
            pageNo={currentPageNo}
            searchChanged={searchChanged}
          />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
      Reports: state.MonthWiseDailyTransactionsSummary,
    DistCentersList: state.ddlList.distCenters,
    allDistributorList: state.ddlList.allDistributorList,
  }
}

export default connect(mapStateToProps, {
  getMonthWiseDailyTransactionsSummary,
  resetMonthWiseDailyTransactionsSummary,
  getDropdownList,
  exportReport,
})(MonthlyDailyTransactionsSummary)
