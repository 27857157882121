import React from 'react'
import DatePicker from 'react-datepicker'
import { getIn, Field, ErrorMessage, useField, useFormikContext, formik } from 'formik'
import TextError from './TextError'
import 'react-datepicker/dist/react-datepicker.css'
import { FormGroup, Form } from 'react-bootstrap'

export const DatePickerField = ({
  label,
  value,
  showMonthYearPicker,
  handleOnChange,
  maxDate,
  minDate,
  formik,
  isDisabled = false,
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field] = useField(props)
  return (
    <div className='input-group'>
      <label style={{ marginTop: '5px', marginBottom: 0 }}>{label}</label>
      <DatePicker
        showMonthYearPicker={showMonthYearPicker}
        placeHolder={`Select ${label}`}
        className={
          formik &&
          getIn(formik.touched, field.name) &&
          getIn(formik.errors, field.name)
            ? 'form-control react-datepicker-error'
            : 'form-control'
        }
        {...field}
        {...props}
        maxDate={maxDate ?? null}
        minDate={minDate ?? null}
        selected={(value && new Date(value)) || null}
        onChange={(val) => {
          handleOnChange(val)
          setFieldValue(field.name, val)
        }}
        onChangeRaw={(e) => {
          setFieldTouched(field.name, true, true)
        }}
        disabled={isDisabled}
      />
      <ErrorMessage
        className='error invalid-field'
        component={TextError}
        name={field.name}
      />
    </div>
  )
}
