import axios from "../../apis/Api";
import history, { logout } from "../../history";
import { toast } from 'react-toastify'
import { GET_MFIS } from "../../Types";

export const getMfis = () => async (dispatch) => {
  await axios
    .get(`api/Mfi`)
    .then(({ data }) => {
      // if (data && data.length > 0)
        dispatch({
          type: GET_MFIS,
          payload: data,
        });
    })
    .catch(function (error) {
     if (error.response) {
       if (error.response.status === 401) {
         logout()
         history.push('/login')
         history.go('/login')
       } else if (error.response.status === 400 && error.response.data.errors) {
         let errorMessage = ''
         var errors = error.response.data.errors
         var keys = Object.keys(errors)
         for (let j = 0; j < keys.length; j++) {
           errorMessage += errors[keys[j]] + ' , \n'
         }
         toast.error(errorMessage)
       } else if (error.response.data) toast.error(error.response.data.Message ? error.response.data.Message : error.response.data.message)
       else {
         toast.error('Error while processing the request')
       }
     }
    });
};
