import AWS from 'aws-sdk'
import S3 from 'aws-sdk/clients/s3'

export const uploadFile = async (file) => {
  const S3_BUCKET = 'vizlinks3bucket'
  const REGION = 'eu-west-2'

  AWS.config.update({
    accessKeyId: 'AKIA5O3LXY623AAQTFQY',
    secretAccessKey: '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',
  })

  const s3 = new S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })

  const params = {
    Bucket: S3_BUCKET,
    Key: file.name,
    Body: file,
  }

  try {
    const upload = await s3.upload(params).promise()

    return upload.Location
  } catch (error) {
    console.error(error)
    throw error
  }
}
