import { ADD_PAGE, DELETE_PAGE, GET_PAGES } from '../../../../Types'

const PagesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PAGES:
      return action.payload;
    case ADD_PAGE:
      return [...state, action.payload]
    case DELETE_PAGE:
      return state.filter((e) => e.id !== action.payload)
    default:
      return state
  }
}
export default PagesReducer
