import { useState } from 'react';
import { Col, Container, Image, Modal, Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import backgroundImage from '../../assets/Images/Login_background_Image.gif';
import vizLogo from '../../assets/Images/Vizlink_logo.png';

import FormikControl from '../../layouts/Input/FormikControl';
import { toast } from 'react-toastify';
import axios from '../../apis/Api.js'
import { confirmAlert } from 'react-confirm-alert';

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const DeleteAccount = () => {

    const [cnic, setCnic] = useState("");

    const submitData = async () => {
        if (!isNumeric(cnic)) {
            alert("Please enter only numeric values");
            return;
        }
        await axios.post("api/Authenticate/getuserbycnic", null, { params: { cnic: cnic } })
            .then((result) => {
                if (!result.data.isSuccess) {
                    toast.error(result.data.message);
                    return;
                }
                else {
                    if (!result.data.response.isActive) {
                        toast.error("Your account already deleted");
                        return;
                    }
                    Delete(result.data.response.userName);
                }
            });
    }

    const Delete = (userName) => {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            title: 'Confirm to Delete',
            message: 'Your account will be deleted permanently, are you sure you want to proceed?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await axios.get(`api/Authenticate/ToggleUserActiveStatus/${userName}`)
                            .then((result) => {
                                if (!result.data.isSuccess) {
                                    toast.error(result.data.message);
                                    return;
                                }
                                else {
                                    toast.success('Account deleted successfully');
                                    setCnic("");
                                }
                            });
                    },
                },
                {
                    label: 'No',
                    onClick: () => { setCnic("") }
                },
            ],
        })
    }

    return (
        <>
            <div
                className='login-page'
                style=
                {{
                    backgroundSize: 'cover',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className='login-box elevation-4'>

                    <div className='card-header text-center'>
                        <Link
                            className='brand-link clearfix'
                            to={'/login'}
                        >
                            <Image
                                src={vizLogo}
                                alt={'VizLink App Logo'}
                            />
                        </Link>
                    </div>

                    <div className='card-body'>
                        <p className='login-box-msg'>
                            {'Delete User Account'}
                        </p>

                        <Formik
                            initialValues={{
                                cnic: '',
                            }}
                            validationSchema={Yup.object().shape({
                                cnic: Yup.string()
                                    .min(13, "Minimum length is 13")
                                    .max(13, "Maximum length is 13")
                                    .required('CNIC is required'),
                            })}
                            onSubmit={() => submitData()}
                        >
                            {
                                (formik) =>
                                (
                                    <Form autoComplete={'off'}>
                                        <Modal.Body>
                                            <Container>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <div className='form-group'>
                                                            <FormikControl
                                                                formik={formik}
                                                                control='input'
                                                                type='text'
                                                                label='CNIC'
                                                                name='cnic'
                                                                value={cnic}
                                                                handleOnChange={text => setCnic(text)}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Modal.Body>
                                        <Modal.Footer style={{ justifyContent: 'space-between' }}>
                                            <input
                                                className='btn btn-danger block btn-block'
                                                type='submit'
                                                value='Delete'
                                            />
                                        </Modal.Footer>
                                    </Form>
                                )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeleteAccount;
