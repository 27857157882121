import { useState } from 'react'
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from 'react-table'
import GlobalFilter from './GlobalFilter'

const CustomDataTable = ({
  columns,
  data,
  getTrProps,
  IsPagingEnable = true,
  IsSearchEnable = true,
  buttonRow = null,
  searchLabel = '',
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state, // new
    preGlobalFilteredRows, // new
    setGlobalFilter, // new
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters, // new
    useGlobalFilter, // new
    useSortBy,
    usePagination
  )
  return (
    <div
      style={{ overflow: 'hidden', overflowX: 'scroll', minHeight: '350px' }}
    >
      <div style={{ overflowX: 'auto' }}>
        {IsSearchEnable == true ? (
          <>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />

            <span style={{ float: 'right', margin: '8px' }}>{searchLabel}</span>
          </>
        ) : null}

        {/* new */}
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div key={column.id}>
                <label for={column.id}>{column.render('Header')}: </label>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
        <table
          className='table striped bordered hover'
          {...getTableProps()}
          style={{ minHeight: '330pxs' }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' ▼'
                          : ' ▲'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ Height: 'auto' }}>
            {page && page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr
                    style={{ cursor: 'pointer' }}
                    {...row.getRowProps()}
                    className='table-hover-class'
                    key={Math.random().toString(36).substring(2, 9)}
                    onDoubleClick={(event) => getTrProps(event, row?.original)}
                  >
                    {row.cells.map((cell, j) => {
                      return (
                        <>
                          <td
                            {...cell.getCellProps()}
                            key={Math.random().toString(36).substring(2, 9)}
                          >
                            {cell.render('Cell')}
                          </td>
                        </>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td
                  colSpan={headerGroups[0].headers.length}
                  style={{ textAlign: 'center' }}
                >
                  No record found
                </td>
              </tr>
            )}
            {buttonRow != null ? (
              <tr>
                <td colSpan={12}>{buttonRow}</td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
        {IsPagingEnable === true ? (
          <ul className='pagination float-right pagination-scroll'>
            <select
              className='form-control'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
              style={{ width: '120px', height: '38px' }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize, index) => (
                <option
                  key={Math.random().toString(36).substring(2, 9)}
                  value={pageSize}
                >
                  Show {pageSize}
                </option>
              ))}
            </select>
            <li
              className='page-item pointer'
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <a className='page-link'>&laquo;</a>
            </li>
            <li
              className='page-item pointer'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <a className='page-link'>&lt;</a>
            </li>
            <li
              className='page-item pointer'
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <a className='page-link'>&gt;</a>
            </li>
            <li
              className='page-item pointer'
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <a className='page-link'>&raquo;</a>
            </li>
            <li>
              <a className='page-link' style={{ width: '125px' }}>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </a>
            </li>
            <li>
              <a className='page-link'>
                <input
                  className='form-control'
                  type='number'
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                  }}
                  style={{ width: '100px', height: '20px' }}
                />
              </a>
            </li>
          </ul>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
export default CustomDataTable
