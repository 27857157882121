import {
  ADD_PAGE_ACTION,
  DELETE_PAGE_ACTION,
  GET_PAGE_ACTIONS,
} from '../../../../Types'

const PageActionsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PAGE_ACTIONS:
      return action.payload;
    case ADD_PAGE_ACTION:
      return [...state, action.payload]
    case DELETE_PAGE_ACTION:
      return state.filter((e) => e.id !== action.payload)
    default:
      return state
  }
}
export default PageActionsReducer
