import axios from '../../../../apis/Api'
import history, { logout } from '../../../../history'

import { connect } from 'react-redux'
import { VzPageContainer } from '../../../../layouts/VzPageContainer'
import { useState, useEffect } from 'react'

import {
  Modal,
  Col,
  Row,
  Overlay,
  Button,
  Popover,
  Container,
} from 'react-bootstrap'
import CustomDataTable from '../../../../layouts/DataTables/CustomDataTable'
import { Input } from 'reactstrap'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { addPage, deletePage, getPages } from './PagesActions'
import ReactSelect from 'react-select'
import { getPageAndActionsList } from '../PageActions/PageActionsActions'
const Pages = (props) => {
  const [newPage, setNewPage] = useState('')
  const [pageUrl, setPageUrl] = useState('')
  const [parentPage, setParentPage] = useState()
  const [parentsList, setParentsList] = useState([])

  const [searchParentsList, setSearchParentsList] = useState([])
  const [searchParent, setSearchParent] = useState()
  const [filteredPages, setFilteredPages] = useState([])

  const pagesColumns = [
    {
      Header: 'Page Name',
      accessor: 'pageName',
    },
    {
      Header: 'Page Url',
      accessor: 'pageUrl',
    },
    {
      Header: 'Parent Name',
      accessor: 'parentName',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (row) => (
        <div>
          <button
            onClick={() => Delete(row.value)}
            className='btn btn-danger'
            style={{ width: 30, height: 30, padding: 3, cursor: 'pointer' }}
          >
            <i className='fa fa-trash'></i>
          </button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getPages()
  }, [searchParent])

  useEffect(() => {
    setParentsList(
      props.pages.map((page) => {
        return {
          label: page.pageName,
          value: page.id,
        }
      })
    )
    setSearchParentsList(
      props.pages
        .filter((x) => x.parentId == null)
        .map((page) => {
          return {
            label: page.pageName,
            value: page.id,
          }
        })
    )
  }, [props.pages])

  const addPage = async () => {
    if (!newPage) {
      toast.error('Enter Page Name')
      return
    }
    // if (!pageUrl) {
    //   toast.error('Enter Page Url')
    //   return
    // }

    let checkIsExist = props.pages?.filter(
      (item) =>
        item.pageUrl.toUpperCase() === pageUrl.toUpperCase() ||
        item.pageUrl.replace(/ /g, '').trim().toUpperCase() ===
          pageUrl.replace(/ /g, '').trim().toUpperCase()
    )

    if (checkIsExist?.length > 0) {
      toast.warn('Page with the same URL already exist')
      return
    }

    let data = {
      PageName: newPage,
      PageUrl: pageUrl,
    }
    if (parentPage != null) {
      data = { ...data, ParentId: parentPage.value }
    }

    await props.addPage(data)
    getPages()

    setNewPage('')
    setPageUrl('')
    setParentPage(null)
    props.getPageAndActionsList()
  }
  const getParentFilteredPages = async (parentId) => {
    await axios
      .get(`api/Page/${parentId}`)
      .then(({ data }) => {
        setFilteredPages(data.response)
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            logout()
            history.push('/login')
            history.go('/login')
          } else if (
            error.response.status === 400 &&
            error.response.data.errors
          ) {
            let errorMessage = ''
            var errors = error.response.data.errors
            var keys = Object.keys(errors)
            for (let j = 0; j < keys.length; j++) {
              errorMessage += errors[keys[j]] + ' , \n'
            }
            toast.error(errorMessage)
          } else if (error.response.data)
            toast.error(
              error.response.data.Message
                ? error.response.data.Message
                : error.response.data.message
            )
          else {
            toast.error('Error while processing the request')
          }
        } else toast.error('Error while processing the request')
      })
  }
  const getPages = () => {
    if (searchParent == null) {
      setFilteredPages([])
      props.getPages()
    } else {
      getParentFilteredPages(searchParent.value)
    }
  }

  const Delete = (id) => {
    confirmAlert({
      closeOnEscape: true,
      closeOnClickOutside: true,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <Modal.Dialog>
              <Modal.Header id='customized-dialog-title'>
                <Modal.Title>Confirm to Delete</Modal.Title>
                <button
                  type='button'
                  onClick={() => onClose()}
                  className='btn-close'
                  aria-label='No'
                ></button>
              </Modal.Header>
              <Modal.Body dividers>
                <p>Are you sure you want to delete this?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    DeleteRecord(id)
                    onClose()
                  }}
                  variant='outline-primary'
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant='primary'
                  onClick={onClose}
                  className='btn btn-primary'
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </div>
        )
      },
    })
  }

  const DeleteRecord = async (id) => {
    await props.deletePage(id)
    setNewPage('')
    setPageUrl('')
    setParentPage(null)
    if (searchParent != null) {
      setFilteredPages(filteredPages.filter((x) => x.id != id))
    }
  }

  return (
    <div>
      <Row style={{ marginTop: '40px' }}>
        <Col xs={12} md={{ span: 4, offset: 1 }} style={{ marginTop: '80px' }}>
          <Col md={12}>
            <Col md={12}>
              <div className='form-group'>
                <ReactSelect
                  id='mfi'
                  name='mfi'
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Select Parent Page'
                  isMulti={false}
                  value={parentPage}
                  isClearable={true}
                  onChange={(res) => setParentPage(res)}
                  options={parentsList}
                ></ReactSelect>
              </div>
            </Col>
            <Col md={12}>
              <div className='form-group'>
                <Input
                  value={newPage}
                  onChange={(name) => setNewPage(name.target.value)}
                  type='text'
                  placeholder='Enter Page Name'
                />
              </div>
            </Col>
            <Col md={12}>
              <div className='form-group'>
                <Input
                  value={pageUrl}
                  onChange={(name) => setPageUrl(name.target.value)}
                  type='text'
                  placeholder='Enter Page Url'
                />
              </div>
            </Col>
            <Col md={12} style={{ marginTop: '10px', display: 'inline-block' }}>
              <div className='form-group'>
                <Button
                  className='form-btn'
                  variant='primary'
                  onClick={addPage}
                  style={{ fontSize: 13 }}
                >
                  Add Page
                </Button>
              </div>
            </Col>
          </Col>
        </Col>
        <Col xs={12} md={{ span: 6, offset: 1 }}>
          <Row>
            <Col md={5}>
              <div className='form-group'>
                <ReactSelect
                  id='parentSearch'
                  name='parentSearch'
                  className='basic-single'
                  classNamePrefix='select'
                  placeholder='Select Parent Page'
                  isMulti={false}
                  value={searchParent}
                  isClearable={true}
                  onChange={(res) => setSearchParent(res)}
                  options={searchParentsList}
                ></ReactSelect>
              </div>
            </Col>
          </Row>
          <Row>
            <CustomDataTable
              data={
                filteredPages.length > 0 ? filteredPages : props.pages ?? []
              }
              columns={pagesColumns}
              getTrProps={() => null}
            />
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    pages: state.Pages,
  }
}

export default connect(mapStateToProps, {
  addPage,
  deletePage,
  getPages,
  getPageAndActionsList,
})(Pages)
